import styles from "../../Help.module.scss";


function CustomerDirectory (){

    return(
        
      <div>
      <h3 style={{ fontWeight: '900', fontSize: '26px' }}> Customer Directory</h3>
         
         <div  id={styles.container}>

                  <div><img src={require('./assets/customer.png')} alt=""/></div>
                  
                  <p>Customers could be searched for by the location of the Salon, or by first name, last name, ZIP, city or state.</p>
                  
                  <p>There is also a box to search for by using any of the information. at the top right-hand corner of the page. You could also search by column, by clicking the filter icon, which is located next to the search box.</p>
                  
                  <p>You could also search by Status (Active / Inactive), Priority (VIP / Non-VIP), Pet’s name, Pets type (Bird / Cat / Dog / Ferret / Guinea Pig / Rabbit) and Breeds (only available for Cats and Dogs).</p>
                  
                  <p>If you have selected a salon location and if the customer’s name you are looking – or any other information you are searching for – does not appear, click on the Reset Filters button and search again.</p>
                  
                  <p> The list of customers could be viewed as a Grid or as a List. The above image is viewed as a List, while the image below is viewed as a Grid.</p>
                  
                  <div><img src={require('./assets/customer2.png')} alt=""/></div>
                  
                  
                  <p>The Directory information could be downloaded as an Excel file, or as the PDF document.</p>
                  
                  <p> More pages could be viewed by clicking the left and right arrows, found at the very bottom right-hand corner of the page, once you scroll down.</p>
                  
                  

                                                                                                              
                  
         </div>

   </div>
    );

}

export default CustomerDirectory;