import React, { useEffect, useState } from 'react';
import axios from '../../../../apis/axios';
import PropTypes from 'prop-types';
import { Chip, Box, TextField, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { Button, Modal } from '../../../components';
import { CloseButton } from '../../../CloseButton/CloseButton';
import { toast } from 'react-toastify';

function TipSetup(props: {
    data?: { numLocationID: number };
    labels?: any;
    type?: any;
    mode?: boolean;
}) {
    const [percentageList, setPercentageList] = useState([]) as any;
    const [isOpenAddPercentage, setIsOpenAddPercentage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newTipPercs, setNewTipPercs] = useState<string[]>(['']);
    const [groomerPercentage, setGroomerPercentage] = useState(0);
    const [batherPercentage, setBatherPercentage] = useState(0);

    const getLocationWiseTipPercentages = async (LocID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Tip/GetLocationWiseTipPercentages`;
      
        try {
            const response = await axios.get(apiUrl, {
                params: {
                    LocationID: LocID
                },
            });
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const getLocationWiseTipDetailsByLocationID = async (LocID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Tip/GetLocationWiseTipDetailsByLocationID`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
                LocationID: LocID
            },
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
      };

    useEffect(() => {
        fetchLocationWiseTipPercentages()
        fetchGlobalPrecentage()
    }, [props?.data?.numLocationID]);

    const fetchLocationWiseTipPercentages = () => {
        if (props?.data?.numLocationID) {
            setIsLoading(true);
            getLocationWiseTipPercentages(props.data.numLocationID)
            .then((data) => {
                console.log("PercentList", data);
                setPercentageList(data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
                setIsLoading(false);
            });
        }
    }

    const fetchGlobalPrecentage = () => {
        getLocationWiseTipDetailsByLocationID(props?.data?.numLocationID)
        .then((data) => {
          console.log("preSetPrecent", data)
          setBatherPercentage((data?.[0]?.BatherTipPerc === undefined)? 0 : data?.[0]?.BatherTipPerc)
          setGroomerPercentage((data?.[0]?.GroomerTipPerc === undefined)? 0 : data?.[0]?.GroomerTipPerc)
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    const handleDelete = (tip: any) => {
        setPercentageList((prevList: any) => prevList.filter((item: any) => item.TipPerc !== tip.TipPerc));
    };

    // const handleAddPercentage = () => {
    //     const parsedTipPercs = newTipPercs.map(Number).filter(perc => perc > 0);
    //     const existingPercs = percentageList.map((item: any) => item.TipPerc);
    //     const newPercs = parsedTipPercs.filter(perc => !existingPercs.includes(perc));

    //     if (newPercs.length !== parsedTipPercs.length) {
    //         toast.warning('One or more percentages already exist or are invalid');
    //     } else {
    //         const updatedList = [
    //             ...percentageList,
    //             ...newPercs.map(perc => ({
    //                 LocationID: props?.data?.numLocationID,
    //                 TipPerc: perc,
    //             }))
    //         ];
    //         setPercentageList(updatedList);
    //         saveTipPercentage(updatedList);
    //         setIsOpenAddPercentage(false);
    //         setNewTipPercs(['']);
    //     }
    // };

    // const handleAddPercentage = () => {
    //     const parsedTipPercs = newTipPercs.map(Number).filter(perc => perc > 0);
    //     const existingPercs = percentageList.map((item: any) => item.TipPerc);
        
    //     // Check for duplicates in the parsedTipPercs
    //     const hasDuplicates = parsedTipPercs.some((perc, index) => parsedTipPercs.indexOf(perc) !== index);
    
    //     // Check if any of the new percentages already exist in the existing list
    //     const newPercs = parsedTipPercs.filter(perc => !existingPercs.includes(perc));
    
    //     if (newPercs.length !== parsedTipPercs.length || hasDuplicates) {
    //         toast.warning('One or more percentages already exist or are invalid');
    //     } else {
    //         const updatedList = [
    //             ...percentageList,
    //             ...newPercs.map(perc => ({
    //                 LocationID: props?.data?.numLocationID,
    //                 TipPerc: perc,
    //             }))
    //         ];
    //         setPercentageList(updatedList);
    //         saveTipPercentage(updatedList);
    //         setIsOpenAddPercentage(false);
    //         setNewTipPercs(['']);
    //     }
    // };

    const handleAddPercentage = () => {
        const parsedTipPercs = newTipPercs.map(Number).filter(perc => perc > 0 && perc <= 100);
        const existingPercs = percentageList.map((item: any) => item.TipPerc);
        
        // Check for duplicates in the parsedTipPercs
        const hasDuplicates = parsedTipPercs.some((perc, index) => parsedTipPercs.indexOf(perc) !== index);
    
        // Check if any of the new percentages already exist in the existing list
        const newPercs = parsedTipPercs.filter(perc => !existingPercs.includes(perc));
    
        if (newPercs.length !== parsedTipPercs.length || hasDuplicates) {
            toast.warning('One or more percentages already exist, are invalid');
        } else {
            const updatedList = [
                ...percentageList,
                ...newPercs.map(perc => ({
                    LocationID: props?.data?.numLocationID,
                    TipPerc: perc,
                }))
            ];
            setPercentageList(updatedList);
            saveTipPercentage(updatedList);
            setIsOpenAddPercentage(false);
            setNewTipPercs(['']);
        }
    };
    

    // const handleInputChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    
    //     if (/^\d*\.?\d*$/.test(value)) {
    //         setNewTipPercs(prev => {
    //             const newPercs = [...prev];
    //             newPercs[index] = value;
    //             return newPercs;
    //         });
    //     }
    // };


    const handleInputChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        // Check if the input is a valid number
        if (/^\d*\.?\d*$/.test(value)) {
            setNewTipPercs(prev => {
                const newPercs = [...prev];
                newPercs[index] = value;

                // Check if the current field is the last one and not empty
                if (value !== '' && index === newPercs.length - 1) {
                    newPercs.push(''); // Add new empty field
                }

                return newPercs;
            });
        }
    };

     const addNewTipField = () => {
        setNewTipPercs(prev => [...prev, '']);
    };

    async function saveTipPercentage(data) {
        console.log("sent-data", data)
        await axios.post(`Tip/PopulateLocationWiseTipPercentages`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        .then((result) => {
            console.log("result", result)
            if (result.status === 200) {
                toast.success("Changes made to percentages saved successfully");
                setIsOpenAddPercentage(false);
                fetchLocationWiseTipPercentages();
            }
        })
        .catch((err) => {
            console.log(err);
            toast.error("Something went wrong!");
        });
    }

    async function saveGlobalTipPercentage(data) {
        console.log("sent-data", data)
        await axios.post(`Tip/PopulateLocationWiseTip`, JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        .then((result) => {
            console.log("result", result)
            if (result.status === 200) {
                toast.success("Global Percentages updated successfully");
                fetchGlobalPrecentage()
                fetchLocationWiseTipPercentages();
            }
        })
        .catch((err) => {
            console.log(err);
            toast.error("Something went wrong!");
        });
    }

    const handleSubmit = () => {
        if (percentageList.length > 0) {
            saveTipPercentage(percentageList);
        } else {
            saveTipPercentage([
                {
                    "locationID": props?.data?.numLocationID,
                    "tipPerc": null
                }
            ]);
        }
        console.log("glb", [
            {
              "locationID": props?.data?.numLocationID,
              "groomerTipPerc": groomerPercentage,
              "batherTipPerc": batherPercentage,
              "numUserID": 1
            }
          ])

          if (groomerPercentage < 0 || groomerPercentage > 100 || batherPercentage < 0 || batherPercentage > 100) {
            toast.warning('Invalid global percentage value. Percentage should be between 0 and 100.');
            return;
        }
    
        saveGlobalTipPercentage([
            {
              "locationID": props?.data?.numLocationID,
              "groomerTipPerc": (groomerPercentage === 0)? 0 : groomerPercentage,
              "batherTipPerc": (batherPercentage === 0)? 0 : batherPercentage,
              "numUserID": 1
            }
          ])
    }

    return (
        <Box>
            <p style={{ fontSize: "14px", fontWeight: "600" , marginBottom:"10px" }}>Global Tip percentages (%)</p>
            {
              (props?.mode)? 

                  <div style={{ display: "flex", gap: "10px" }}>
                    <TextField
                        style={{ width: '30%' }}
                        label={
                            <div style={{ display: 'flex', fontSize: '15px', fontWeight: "500" }}>
                                Groomer
                            </div>
                        }
                        type={'text'} 
                        size='small'
                        variant="outlined"
                        multiline={false}
                        value={groomerPercentage}
                        onChange={(e) => {
                            const value:any = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                                setGroomerPercentage(value);
                                setBatherPercentage(100 - value);
                            }
                        }}
                    />

                    <TextField
                        style={{ width: '30%' }}
                        label={
                            <div style={{ display: 'flex', fontSize: '15px', fontWeight: "500" }}>
                                Bather
                            </div>
                        }
                        type={'text'}
                        size='small'
                        variant="outlined"
                        multiline={false}
                        value={batherPercentage}
                        onChange={(e) => {
                            const value:any = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                                setBatherPercentage(value);
                                setGroomerPercentage(100 - value);
                            }
                        }}
                    />

             
                </div>

                :

                <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px", background: "#93ffad", borderRadius: "8px", width: "50px" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ fontWeight: "600" }}>
                      {groomerPercentage + "%"}
                    </div>
                    <label style={{ fontSize: "14px", fontWeight: "400", color: "gray" }}>Groomer</label>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px", background: "#93ffad", borderRadius: "8px", width: "50px" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ fontWeight: "600" }}>
                      {batherPercentage + "%"}
                    </div>
                    <label style={{ fontSize: "14px", fontWeight: "400", color: "gray" }}>Bather</label>
                  </div>
                </div>
              </div>
          
            }

            
            <p style={{ fontSize: "14px", fontWeight: "600", marginTop:"10px" }}>Tip percentages</p>
            {props?.mode ? (
                <div style={{ textAlign: "end" }}>
                    <p
                        style={{ margin: "0", color: "blue", fontWeight: "600", cursor: "pointer" }}
                        onClick={() => { setIsOpenAddPercentage(true); }}
                    >
                        Add New Percentage
                    </p>
                </div>
            ) : null}

            {(percentageList.length < 1)? <p style={{color:"gray"}}>No percentages added for the selected location</p> : percentageList
                .sort((a: any, b: any) => a.TipPerc - b.TipPerc)
                .map((tip: any, index: number) => (
                    <Chip
                        key={index}
                        label={`${tip.TipPerc}%`}
                        onDelete={props.mode ? () => handleDelete(tip) : undefined}
                        deleteIcon={props.mode ? <CancelIcon /> : undefined}
                        style={{ margin: '4px' }}
                        color='info'
                    />
                ))}

            <Modal open={isOpenAddPercentage} backgroundColor={"#F9FAFB"}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: "350px", alignItems: "center" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#192252" }}>New Percentage</p>
                        <CloseButton onClick={() => setIsOpenAddPercentage(false)} />
                    </div>
                    <div style={{ backgroundColor: "#fff", width: "100%", padding: "10px", display: "flex", flexDirection: "column", gap: "10px", height: "fit-content", overflowY: "scroll" }}>
                    {newTipPercs.map((tip, index) => (
                    <TextField
                        key={index}
                        label={`New Tip Percentage ${index + 1}`}
                        type="number"
                        value={tip}
                        onChange={handleInputChange(index)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={addNewTipField}>
                                    <AddIcon />
                                </IconButton>
                            )
                        }}
                    />
                ))}
                    </div>
                    <div style={{ display: 'flex', justifyContent: "end", width: '100%', backgroundColor: "#F3F4F6", padding: "1rem", borderRadius: "0 0 12px 12px" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <Button variant='contained' color='primary' disabled={isLoading} onClick={handleAddPercentage}>Save</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            {props?.mode ?
                <div style={{ textAlign: 'end', width: '100%', backgroundColor: "#EFF5FF", padding: "1rem", marginTop: "3rem" }}>
                    <Button variant='contained' color='primary' onClick={handleSubmit}>Save</Button>
                </div> : null
            }
        </Box>
    );
}

TipSetup.propTypes = {
    data: PropTypes.shape({
        numLocationID: PropTypes.number.isRequired,
    }).isRequired,
    labels: PropTypes.any,
    type: PropTypes.any,
    mode: PropTypes.bool,
};

export default TipSetup
