/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

export const Style: {} = {
    tableName: "1.6 - Style",
    hiddenColumns: ["numStyleID"],
    pdfTemplate: ["varStyleName", "varStyleDescription", "varBodyPart", "bitActive"],
    template: [
        {
            Header: "Name",
            accessor: "varStyleName",
        },
        {
            Header: "Description",
            accessor: "varStyleDescription",
        },
        {
            Header: "BodyPart",
            accessor: "varBodyPart",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numStyleID",
            accessor: "numStyleID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numStyleID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varStyleName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varBodyPart",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varStyleDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
