import styles from "./TopBar.module.scss"
import { AddIcCallOutlined, Circle, ContactSupportOutlined } from "@mui/icons-material"
import AdvancedDropDown from "../AdvancedDropDown/AdvancedDropDown"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import LocationContext from "../../utils/Context/LocationContext"
import { Avatar } from "../components"
import { Fab, IconButton, Tooltip } from "@mui/material"
import useAuth from "../../hooks/useAuth"

function TopBar(props: { locationOnChange?: CallableFunction }) {
    const locationRef = useRef()
    const [location, setLocation] = useState("1")

    const { auth } = useAuth() as any

    const navigate = useNavigate()
    const linkLocation = useLocation() as any
    const Location = useLocation() as any

    let helpLink = ""
    let helplocation = Location.pathname

    const handleLink = (page) => {
        const from = linkLocation.state?.from?.pathname || `${page}`
        navigate(from, { replace: true })
    }

    //Dashboard
    if (helplocation === "/") {
        helpLink = "/help/general"
    }

    //Schedule
    else if (helplocation === "/booking") {
        helpLink = "/help/general2"
    }

    // Directory topbar links
    else if (helplocation === "/directory/customer") {
        helpLink = "/help/directory1"
    } else if (helplocation === "/directory/pet") {
        helpLink = "/help/directory2"
    } else if (helplocation === "/directory/salon") {
        helpLink = "/help/directory3"
    } else if (helplocation === "/directory/employee") {
        helpLink = "/help/directory4"
    } else if (helplocation === "/directory/inquiry") {
        helpLink = "/help/directory5"
    }

    // Settings topbar links --> Lable Configuration
    else if (helplocation === "/admin/label-config/Location") {
        helpLink = "/help/administrator1"
    } else if (helplocation === "/admin/label-config/Pet") {
        helpLink = "/help/administrator1"
    } else if (helplocation === "/admin/label-config/Customer") {
        helpLink = "/help/administrator1"
    } else if (helplocation === "/admin/label-config/Employee") {
        helpLink = "/help/administrator1"
    }
    // Settings topbar links --> import-export
    else if (helplocation === "/admin/import-export") {
        helpLink = "/help/administrator1"
    }
    // Settings topbar links --> Library
    else if (helplocation === "/admin/library/AddressType") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Breed") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/PetBreed") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/PetColor") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/ContactPerson") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/ContactType") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Department") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/MedicalCondition") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Occupation") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Personality") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/PetSize") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/PetType") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Service") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Specialization") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/UserQuestion") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Vaccine") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/PetVet") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/ZipCode") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/SalesItem") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/PetVet") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Style") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Shampoo") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Brush") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Comb") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/Blade") {
        helpLink = "/help/administrator3"
    } else if (helplocation === "/admin/library/WellnessCheck") {
        helpLink = "/help/administrator3"
    }

    // Settings topbar links --> User-roles
    else if (helplocation === "/admin/user-roles") {
        helpLink = "/help/administrator4"
    }
    // Settings topbar links --> Scheduler
    else if (helplocation === "/admin/scheduler") {
        helpLink = "/help/administrator5"
    } else {
        helpLink = "/help"
    }

    return (
        <div className={styles.container}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "0 .8rem", height: "100%" }}>
                <div style={{ width: "fit-content" }}>
                    <div style={{ padding: "0.5rem", marginRight: "10px", display: "none" }}>
                        <AdvancedDropDown
                            data={[
                                {
                                    label: "Lucky Paws Pet Grooming Syosset",
                                    value: "1",
                                },
                                {
                                    label: "Lucky Paws Pet Grooming Bethpage",
                                    value: "7",
                                },
                                {
                                    label: "Lucky Paws Pet Grooming Bedford Hills",
                                    value: "12",
                                },
                                {
                                    label: "Lucky Paws Pet Grooming Washington St, Manhattan",
                                    value: "9",
                                },
                            ]}
                            onChange={(e) => {
                                props.locationOnChange?.(e.value)
                                setLocation(e.value)
                            }}
                            passByID={true}
                            value={location}
                            ref={locationRef}
                            placeHolder={"Current Location"}
                        />
                    </div>
                </div>

                <Tooltip
                    className={styles.tooltip}
                    title={
                        <div style={{ padding: "5px" }}>
                            <p style={{ color: "#fff", margin: 0, padding: 0, fontSize: "16px" }}>{auth?.username}</p>
                            <p style={{ color: "#fff", margin: 0, padding: 0, fontWeight: 500, marginBottom: "10px" }}>Current Location:</p>
                            <p style={{ color: "#fff", margin: 0, padding: 0 }}>{auth?.currentLocation?.label}</p>
                        </div>
                    }
                    placement="right"
                >
                    <div style={{ height: "100%", display: "flex", alignItems: "center", gap: "8px" }}>
                        <div className={styles.profileContainer}>
                            <Avatar
                                className={styles.avatarContainer}
                                img={auth?.employee?.varEmployeeImagePath === null ? "" : `${process.env.REACT_APP_MEDIA_URL}directories/employees/${auth?.employee?.varEmployeeImagePath}`}
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                            <p style={{ fontSize: "14px", margin: 0, display: "flex", alignItems: "center", padding: 0 }}>
                                Online
                                <Circle style={{ fontSize: "12px", color: "#49c149", marginLeft: "2px" }} />
                            </p>
                            <p style={{ fontWeight: 500, fontSize: "16px", margin: 0, color: "gray", padding: 0 }}>
                                {auth?.employee?.varEmployeeFirstName} {auth?.employee?.varEmployeeLastName}
                            </p>
                        </div>
                        <IconButton onClick={() => handleLink(`${helpLink}`)} style={{ color: "#fff" }} color="primary" aria-label="add">
                            <ContactSupportOutlined style={{ color: "#000" }} />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
        </div>
    )
}

export default TopBar
