import moment from "moment";
import { toast } from "react-toastify";

export function getStr(str: string) {
    if(str === null || str === undefined)
        return "-"

    if (!str) return '';
    return str;
}

export function getTrimmedStr(str: string, number) {
    if(str === null || str === undefined)
        return "-"

    return str?.substring(0, number)
}

export function Truncate(string, length){
    if(string === null || string === undefined)
        return "-"

    if (string.length > length)
        return string.substring(0, length) + '..';
    else
        return string;
};

export function CopyToClipboard(string){    
    navigator.clipboard
    .writeText(string)
    .then(() => {
        toast.info(`${string} Email copied to clipboard`)
    })
    .catch(() => {
        alert("something went wrong");
    });
}

export function PdfHeaders(string){    
    if(string === null || string === undefined){
        return 'N/A'
    }else{
        string = string.replace(/([A-Z])/g, ' $1').trim();
        string = string.split("var").pop()
        string = string.split("num").pop()
        string = string.split("bit").pop().trim()
        string = string.split("dt").pop().trim()
        string = string.split("bt").pop().trim()
        return string
    }

}

export function checkIfIncludes(str: string, checkText: string) {
    if(str === null || str === undefined){
        return false
    }else{
        let result = str.includes(checkText)
         return result
    }
}

export function formatCash(amount, decimalPlaces = 2) {
    // Convert the amount to a string with the desired decimal places
    const formattedAmount = amount.toFixed(decimalPlaces);
  
    // Split the amount into parts before and after the decimal point
    const [integerPart, decimalPart] = formattedAmount.split('.');
  
    // Add commas for thousands separators
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Join the integer part and decimal part back together
    if (decimalPart) {
      return `${integerWithCommas}.${decimalPart}`;
    } else {
      return integerWithCommas;
    }
  }

  export function daysPassedOrInFuture(dateString) {
    // Parse the given date string using Moment.js
    const givenDate = moment(dateString);
  
    // Get the current date using Moment.js
    const currentDate = moment();
  
    // Calculate the difference in days
    const daysDifference = givenDate.diff(currentDate, 'days');
  
    if (daysDifference < 0) {
      // Date is in the past
      return `${Math.abs(daysDifference)} days passed`;
    } else if (daysDifference === 0) {
      // Date is today
      return 'Today';
    } else {
      // Date is in the future
      return `In ${daysDifference} days`;
    }
  }
