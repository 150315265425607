import styles from "./TabLayout.module.scss"
import { ArrowBack, Assignment, ContentCut, DashboardOutlined, List, Logout, MenuBookOutlined, NotificationAdd, NotificationsNone, Today } from "@mui/icons-material"

import { Outlet } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import { useRef, useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"

import logo from "../../images/logo.svg"
import AdvancedDropDown from "../../components/AdvancedDropDown/AdvancedDropDown"
import { Avatar } from "../../components"
import useAuth from "../../hooks/useAuth"
import { ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material"
import useLogout from "../../hooks/useLogout"
import GroomerEarnings from "../../components/ExtModals/GroomerEarnings/GroomerEarnings"

const TabLayout = (props: { children? }) => {
    const locationRef = useRef()
    const [dd, setDD] = useState("7") as any
    const navigate = useNavigate()
    const location = useLocation() as any
    const { auth } = useAuth() as any
    const logout = useLogout() as any

    console.log("tab-Auth:", auth)

    const handleLink = (page) => {
        const from = location.state?.from?.pathname || `${page}`
        if (page === "working") {
            navigate(from, { state: { appData: 0 }, replace: true })
        } else {
            navigate(from, { replace: true })
        }
    }

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleBack = () => {
        localStorage.removeItem("hasVisitedWelcome")
        navigate("/")
        handleClose()
    }

    const handleLogout = async () => {
        await logout()
        localStorage.removeItem("hasVisitedWelcome")
        navigate("/")
        handleClose()
    }

    return (
        <div>
            <GroomerEarnings />
            <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                <div style={{ backgroundColor: "#fff", boxShadow: "0px 2px 16px rgba(0, 26, 66, 0.05)", padding: "25px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <img alt="logo-pettech" src={logo}></img>
                            <p style={{ margin: "0", fontWeight: 600, fontSize: "20px" }}>EsyPet</p>
                            {/* <AdvancedDropDown
                                data={[
                                    {
                                        label: 'Lucky Paws Pet Grooming Bethpage',
                                        value: '7'
                                    },
                                    {
                                        label: 'Lucky Paws Pet Grooming Bedford Hills',
                                        value: '12'
                                    },
                                    {
                                        label: 'Lucky Paws Pet Grooming Washington St, Manhattan',
                                        value: '9'
                                    },
                                ]}
                                onChange={(e) => {
                                    setDD(e.value)
                                }}
                                passByID={true}
                                value={location}
                                ref={locationRef}
                                placeHolder={'Current Location'}
                            /> */}
                        </div>

                        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            {/* <div style={{ display: 'flex', gap: '10px' }}>
                                <div style={{ position: 'relative' }}>
                                    <Assignment style={{ color: '#848FAC' }} />
                                    <i style={{ width: '10px', height: '10px', backgroundColor: '#ef4444', borderRadius: '50px', position: 'absolute', right: 0, zIndex: 99999, top: 0 }}></i>
                                </div>

                                <div style={{ position: 'relative' }}>
                                    <NotificationsNone style={{ color: '#848FAC' }} />
                                    <i style={{ width: '10px', height: '10px', backgroundColor: '#ef4444', borderRadius: '50px', position: 'absolute', right: 0, zIndex: 99999, top: 0 }}></i>
                                </div>
                            </div> */}

                            <div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                    <Tooltip
                                        title={
                                            <div style={{ padding: "5px" }}>
                                                <p style={{ color: "#fff", margin: 0, padding: 0, fontSize: "16px" }}>
                                                    <span style={{ textTransform: "capitalize" }}>
                                                        {auth?.employee?.varEmployeeFirstName} {auth?.employee?.varEmployeeLastName}
                                                    </span>{" "}
                                                    ({auth?.username})
                                                </p>
                                                <p style={{ color: "#fff", margin: 0, padding: 0, fontWeight: 500 }}>Current Location:</p>
                                                <p style={{ color: "#fff", margin: 0, padding: 0 }}>{auth?.currentLocation?.label}</p>
                                            </div>
                                        }
                                        placement="right"
                                    >
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }} onClick={handleClick}>
                                            <Avatar
                                                className={styles.avatarContainer}
                                                img={
                                                    auth?.employee?.varEmployeeImagePath === null
                                                        ? ""
                                                        : `${process.env.REACT_APP_MEDIA_URL}directories/employees/${auth?.employee?.varEmployeeImagePath}`
                                                }
                                            />
                                            <p style={{ fontWeight: 600, margin: 0, fontSize: "16px", color: "#002867" }}>{auth?.employee?.varEmployeeFirstName}</p>
                                        </div>
                                    </Tooltip>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                                        <MenuItem onClick={handleBack}>
                                            <ListItemIcon>
                                                <ArrowBack fontSize="small" />
                                            </ListItemIcon>
                                            Main-App
                                        </MenuItem>
                                        <MenuItem onClick={handleLogout}>
                                            <ListItemIcon>
                                                <Logout fontSize="small" />
                                            </ListItemIcon>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <Outlet />
                {props.children}

                <div style={{ bottom: 0, width: "100%" }}>
                    <div style={{ borderRadius: "12px 12px 0px 0px", backgroundColor: "#003A95" }}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className={styles.navTab} onClick={() => handleLink("/tablet")}>
                                <DashboardOutlined style={{ color: "#fff" }} />
                                <p style={{ margin: "0", color: "#fff" }}>Dashboard</p>
                            </div>
                            <div className={styles.navTab} onClick={() => handleLink("working")}>
                                <ContentCut style={{ color: "#fff" }} />
                                <p style={{ margin: "0", color: "#fff" }}>Working on</p>
                            </div>
                            {/* <div className={styles.navTab} onClick={() => handleLink('groomingCard')}>
                                <Today style={{ color: '#fff' }} />
                                <p style={{ margin: '0', color: '#fff' }}>Schedule</p>
                            </div> */}
                            {/* <div className={styles.navTab}>
                                <List style={{ color: '#fff' }} />
                                <p style={{ margin: '0', color: '#fff' }}>Services</p>
                            </div> */}
                            <div className={styles.navTab}>
                                <MenuBookOutlined style={{ color: "#fff" }} />
                                <p style={{ margin: "0", color: "#fff" }}>Notes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabLayout