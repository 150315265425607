export function removeUnnecessaryWhitespace(htmlContent) {
    // Remove line breaks and extra white spaces between tags
    htmlContent = htmlContent.replace(/\n/g, '');

    // Remove extra white spaces between tags
    htmlContent = htmlContent.replace(/>\s+</g, '><');

    // Remove consecutive <br> tags
    htmlContent = htmlContent.replace(/(<br\s*\/?>\s*)+/g, '<br>');

    // Remove <p> tags containing only <br> tags
    htmlContent = htmlContent.replace(/<p>(\s*<br\s*\/?>\s*)+<\/p>/g, '');

    // Trim white spaces at the beginning and end of the content
    htmlContent = htmlContent.trim();

    return htmlContent;
}