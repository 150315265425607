import { MainNav, Breadcrumb } from '../../components/components'

import styles from './SubLayout.module.scss'

import { Outlet } from "react-router-dom";

const SubLayout = (props: {
    children?
}) => {

    return (
        <div style={{display: 'flex'}}>
            <MainNav/>     
            <div className={styles.bodyContainer}>               
                <Outlet />
                { props.children }
            </div>            
        </div>
    );
}

export default SubLayout;