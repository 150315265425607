import { Link } from 'react-router-dom';
import styles from './Help.module.scss';
import {Search } from '@mui/icons-material';
import { useState } from 'react';
import { HelpLinks } from './HelpContent/HelpLinks';



const Help = () => {

  
  const [searchQuery, setSearchQuery] = useState('');
  

  
    return (
      <div id={styles.pages}>
        <div id={styles.landing}>
        <h1>Support Center</h1>

        <div id={styles.searchBox} >
          <Search color={'disabled'} />
          <input className={styles.searchInput}  placeholder={'Search'}  id={styles.search}
           onChange={(e) => setSearchQuery(e.target.value)}
          />
          </div>

          <hr />
          <div id={styles.helpMenu}>
           {HelpLinks.filter(user=>user.title.toLowerCase().includes(searchQuery)).map(item => (
            <div>
             <ul>
              <li><Link  to={item.to}>
                <div style={{ fontWeight: '900', fontSize: '20px',color:'black',marginBottom:'10px' }}>
                {item.title}
              </div>
              </Link>
              <ul>
                 {item.subNav.map(sub => ( 
                    <li><Link to={sub.to}>{sub.title}</Link></li>
                ))} 
              </ul>
              </li>
              </ul>
            </div>   
        ))}
        </div> 
          

    
        </div>
      </div>
    )
}

export default Help;