import styles from "../../Help.module.scss";


function ImportExportData (){

    return(
        
      <div>
      <h3 style={{ fontWeight: '900', fontSize: '26px' }}> Import / Export Data</h3>
        
         <div  id={styles.container}>
                  <p>This is a very important module that the Administrators will be working on.</p>
                 
                  <p> There should not be any errors whatsoever here, as this is the module, which feeds all the information required.</p>
                 
                  <p style={{fontWeight: '900', fontSize: '16px'}}>Please ensure that a backup of the files that are going to be replaced have been taken, before any changes are made, or files replaced. If no backup is taken, and if the parameters between the old and the new files are different, it will affect the entire database.</p>
                 
                  <div><img src={require('./assets/import.png')} alt=""/></div>
                 


                  <p> Location, Pet, Customer and Employee information is fed into the system through this module.</p>
                 
                  <p> Each one functions in the same manner.</p>
                 
                  <p>If you click on ‘Download template with headers’, the following screen will appear. Select the file that is required and click ‘Open’. Then, click on ‘Upload data file’.</p>
                 
                  
                  <div><img src={require('./assets/import2.png')} alt=""/></div>
                 
                  

                  <p>Uploading images is slightly different. The following screen appears, when you click on ‘Upload bulk images’:</p>
                 
                  <div><img src={require('./assets/import3.png')} alt=""/></div>
                 
                  <p> Images could be uploaded, either by dragging and dropping, or browsing to choose a file.</p>
                 
                  <div style={{width:'400px',height:'100px',margin:'auto'}}><img src={require('./assets/import4.png')} alt=""/></div>
                  
                  <div style={{marginTop:'10vh'}}>
                  <p>Once the images are loaded, there is a set format for it to be cropped into. This format is built-in, so all one has to do is select the area of the image that needs to be uploaded. The images could be zoomed in or zoomed out, and scrolled in any direction, within the given format. Please ensure to use High Resolution images, so that the images could be seen properly, once cropped.</p>
                 </div> 
                  <div style={{width:'400px',height:'100px',margin:'auto'}}><img src={require('./assets/import5.png')} alt=""/></div>
                 
                  <p>The above two icons could be seen at the top right-hand corner.</p>
                 
                  <p>Since there would be many images, this would help one identify what images have been cropped and what are still uncropped. A number will appear within each blue circle, which will indicate the number of images cropped and uncropped.</p>
                 
                  <p>The images could be seen, by clicking each of these icons.</p>
                 
                  <p>Cropped images could be re-cropped again by discarding changes and re-cropping them.</p>
                 
                  <p>Once each image is cropped, they have to be saved and published.</p>
                 
                  <p>Below icons are for these two functions:</p>
                 

                  <div style={{width:'400px',height:'100px',margin:'auto',marginBottom:'20vh'}}><img src={require('./assets/import6.png')} alt=""/></div>
 
         </div>

   </div>
    );

}

export default ImportExportData;