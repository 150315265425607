import React, { useEffect, useState } from "react"
import { Button, Modal } from "../components"
import CloseButton from "../Modal/CloseButton/CloseButton"
import { Checkbox, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from "@mui/material"
import { AdvancedInput } from "../AdvancedInput/AdvancedInput"
import axios, { axiosPrivate } from "../../apis/axios"
import { toast } from "react-toastify"
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition"
import { Mic } from "@mui/icons-material"
// import annyang from "annyang"
import StepperNotes from "./StepperNotes"
import useAuth from "../../hooks/useAuth"
import ImageMapper from "react-img-mapper"
import outline from "../../images/dog_outline.png"

function GroomerNote(props: { isOpenGroomerNoteModal; setIsOpenGroomerNoteModal: CallableFunction; bookingDetails; type; NoteDetails?; isSuccess: CallableFunction }) {
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition()

    useEffect(() => {
        let comment = overallComments
        comment = transcript
        setOverallComments(comment)
    }, [transcript])

    /* GROOMER NOTES */
    const [overallComments, setOverallComments] = useState("")
    const [isOpenGroomerNote, setIsOpenGroomerNote] = useState(false)

    const [firstTime, setFirstTime] = useState<any>("")
    const [serviceType, setServiceType] = useState<any>("")

    const [puppyCut, setPuppyCut] = useState(false)
    const [standerdCut, setStanderdCut] = useState(false)
    const [handStrip, setHandStrip] = useState(false)
    const [shaveDown, setShaveDown] = useState(false)

    const [headComb, setHeadComb] = useState("")
    const [headClade, setHeadBlade] = useState("")
    const [headStyle, setHeadStyle] = useState(0)
    const [headScissorUsed, setHeadScissorUsed] = useState(false)

    const [faceComb, setFaceComb] = useState("")
    const [faceBlade, setFaceBlade] = useState("")
    const [faceStyle, setFaceStyle] = useState(0)
    const [faceScissorUsed, setFaceScissorUsed] = useState(false)

    const [earComb, setEarComb] = useState("")
    const [earBlade, setEarBlade] = useState(0)
    const [earStyle, setEarStyle] = useState(0)
    const [earScissorUsed, setEarScissorUsed] = useState(false)

    const [bodyComb, setBodyComb] = useState("")
    const [bodyBlade, setBodyBlade] = useState(0)
    const [bodyStyle, setBodyStyle] = useState(0)
    const [bodyScissorUsed, setBodyScissorUsed] = useState(false)

    const [legsComb, setLegsComb] = useState("")
    const [legsBlade, setLegsBlade] = useState(0)
    const [legsStyle, setLegsStyle] = useState(0)
    const [legsScissorUsed, setLegsScissorUsed] = useState(false)

    const [tailComb, setTailComb] = useState("")
    const [tailBlade, setTailBlade] = useState(0)
    const [tailStyle, setTailStyle] = useState(0)
    const [tailScissorUsed, setTailScissorUsed] = useState(false)

    const [feetComb, setFeetComb] = useState("")
    const [feetBlade, setFeetBlade] = useState(0)
    const [feetStyle, setFeetStyle] = useState(0)
    const [feetScissorUsed, setFeetScissorUsed] = useState(false)

    const [dryingDiff, setDryingDiff] = useState<any>("")
    const [recShampoo, setRecShampoo] = useState(0)
    const { auth } = useAuth() as any

    const [noteData, setNoteData] = useState([]) as any

    const [appointmentServices, setAppointmentServices] = useState([]) as any
    const [appointmentProducts, setAppointmentProducts] = useState([]) as any

    const handleChangeActiveStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstTime((event.target as HTMLInputElement).value)
    }

    const handleServiceType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceType((event.target as HTMLInputElement).value)
    }

    const handleDryingStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDryingDiff((event.target as HTMLInputElement).value)
    }

    function getBladesInfo(blades: any) {
        const activeBlades = Object.values(blades).filter((blade: any) => blade.bitActive)
        const bladeDescriptions = activeBlades
            .map((blade: any) => {
                const description = blade.varBladeDescription ? ` - ${blade.varBladeDescription}` : ""
                const bodyPart = blade.varBodyPart ? ` for ${blade.varBodyPart}` : ""
                return `#${blade.numBladeID} ${blade.varBladeName}${bodyPart}${description}`
            })
            .join(" and ")
        return bladeDescriptions
    }

    function getCombsInfo(combs: any) {
        const activeCombs = Object.values(combs).filter((comb: any) => comb.bitActive)
        const combDescriptions = activeCombs
            .map((comb: any) => {
                const description = comb.varCombDescription ? ` - ${comb.varCombDescription}` : ""
                const bodyPart = comb.varBodyPart ? ` for ${comb.varBodyPart}` : ""
                return `#${comb.numCombID} ${comb.varCombName}${bodyPart}${description}`
            })
            .join(" and ")
        return combDescriptions
    }
    function generateGroomingSummary(note) {
        const groomerName = `${note.varCreatedEmployeeFirstName} ${note.varCreatedEmployeeLastName}`
        const location = `${note.varLocationName} in ${note.varLocationCity}, ${note.varLocationState}`

        // Additional information about blades and combs
        const bladesInfo = `In the grooming session, groomer ${groomerName} used various clipper blades like ${getBladesInfo(note.blades)}, ensuring precise hair lengths on different body parts. `
        const combs = note.combs
        const combsInfo = combs ? `Combs, such as ${getCombsInfo(combs)}, were also employed for consistent length. ` : ""

        const groomingDetails =
            bladesInfo +
            combsInfo +
            `These tools showcase the groomer's attention to detail, allowing for a tailored and well-groomed appearance. The use of specific blades and combs, indicated by boolean keys, reflects the groomer's expertise in achieving diverse grooming styles for the customer's pet.`

        return groomingDetails
    }

    const saveNote = () => {
        let obj = {
            groomerNote: {
                bitActive: true,
                numGroomerNoteID: 0,
                numAppointmentID: props.bookingDetails.numAppointmentID,
                numEmployeeID: auth?.employee?.numEmployeeID,
                numLocationID: props.bookingDetails.LocationID,
                bitFirstGroom: firstTime === "true" ? true : false,
                varServiceType: serviceType,
                bitFullGroomPuppyCut: puppyCut,
                bitFullGoomBreedStandardCut: standerdCut,
                bitFullGroomHandStrip: handStrip,
                bitFullGroomShaveDown: shaveDown,
                numHeadCombID: headComb,
                numHeadBladeID: headClade,
                numHeadStyleID: headStyle,
                bitHeadScissorUsed: headScissorUsed,
                numFaceCombID: faceComb,
                numFaceBladeID: faceBlade,
                numFaceStyleID: faceStyle,
                bitFaceScissorUsed: faceScissorUsed,
                numEarCombID: earComb,
                numEarBladeID: earBlade,
                numEarStyleID: earStyle,
                bitEarScissorUsed: earScissorUsed,
                numBodyComdID: bodyComb,
                numBodyBladeID: bodyBlade,
                bitBodyScissorUsed: bodyScissorUsed,
                numLegComdID: legsComb,
                numLegBladeID: legsBlade,
                bitLegScissorUsed: legsScissorUsed,
                numTailCombID: tailComb,
                numTailBladeID: tailBlade,
                numTailStyleID: tailStyle,
                bitTailScissorUsed: tailScissorUsed,
                numFeetCombID: feetComb,
                numFeetBladeID: feetBlade,
                numFeetStyleID: feetStyle,
                bitFeetScissorUsed: feetScissorUsed,
                bitDifficultDrying: dryingDiff === "true" ? true : false,
                numRecommendedShampooID: recShampoo,
                varGroomerComment: overallComments,
                GroomerNoteSummary: "test",
            },
        }
        console.log("sent", obj, props.bookingDetails)

        saveGroomerNote(obj)
    }

    async function saveGroomerNote(data) {
        await axiosPrivate
            .post(`GroomerBatherNote/PostGroomerNote`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                console.log(result)
                if (result.status == 200) {
                    toast.success("Groomer note successfully saved!")
                    props.isSuccess(true)
                    props.setIsOpenGroomerNoteModal(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        if (props.isOpenGroomerNoteModal === true) {
            if (props.type === "copy") {
                GetGroomerNotesByNoteID(props.NoteDetails.numGroomerNoteID)
            } else {
                console.log("add")
            }
        }
    }, [props.isOpenGroomerNoteModal])

    async function GetGroomerNotesByNoteID(noteID) {
        await axios
            .get(`${process.env.REACT_APP_API_URL}Appointment/GetGroomerNotesByNoteID?NoteID=${noteID}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("app-note", result.data)
                    setNoteData(result.data)
                    let noteInfo = generateGroomingSummary(result.data)
                    // console.log("summary", noteInfo)

                    setBodyScissorUsed(result.data.bitBodyScissorUsed)
                    setDryingDiff(result.data.bitDifficultDrying)
                    setEarScissorUsed(result.data.bitEarScissorUsed)
                    setFaceScissorUsed(result.data.bitFaceScissorUsed)
                    setFeetScissorUsed(result.data.bitFeetScissorUsed)
                    setFirstTime(result.data.bitFirstGroom)
                    setStanderdCut(result.data.bitFullGoomBreedStandardCut)
                    setHandStrip(result.data.bitFullGroomHandStrip)
                    setPuppyCut(result.data.bitFullGroomPuppyCut)
                    setShaveDown(result.data.bitFullGroomShaveDown)
                    setHeadScissorUsed(result.data.bitHeadScissorUsed)
                    setLegsScissorUsed(result.data.bitLegScissorUsed)
                    setTailScissorUsed(result.data.bitTailScissorUsed)
                    setRecShampoo(result.data.numRecommendedShampooID)
                    setOverallComments(result.data.varGroomerComment)
                    setServiceType(result.data.varServiceType)

                    setBodyBlade(result.data.blades.bodyBlade.numBladeID)
                    setEarBlade(result.data.blades.earBlade.numBladeID)
                    setFaceBlade(result.data.blades.faceBlade.numBladeID)
                    setFeetBlade(result.data.blades.feetBlade.numBladeID)
                    setHeadBlade(result.data.blades.headBlade.numBladeID)
                    setLegsBlade(result.data.blades.legBlade.numBladeID)
                    setTailBlade(result.data.blades.tailBlade.numBladeID)

                    setEarComb(result.data.combs.earComb.numCombID)
                    setFaceComb(result.data.combs.faceComb.numCombID)
                    setFeetComb(result.data.combs.feetComb.numCombID)
                    setHeadComb(result.data.combs.headComb.numCombID)
                    setTailComb(result.data.combs.tailComb.numCombID)

                    setEarStyle(result.data.styles.earStyle.numStyleID)
                    setFaceStyle(result.data.styles.faceStyle.numStyleID)
                    setFeetStyle(result.data.styles.feetStyle.numStyleID)
                    setHeadStyle(result.data.styles.headStyle.numStyleID)
                    setTailStyle(result.data.styles.tailStyle.numStyleID)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const [firstHighlight, setFirstHighlight] = useState(false)
    const [secondHighlight, setSecondHighlight] = useState(false)
    const [thirdHighlight, setThirdHighlight] = useState(false)

    // useEffect(() => {
    //     // Initialize annyang
    //     annyang.start()

    //     // Define voice commands
    //     const commands = {
    //         "set :q_number :answer": (q_number, answer) => {
    //             console.log(q_number, answer)

    //             if (q_number === "first groom" || q_number === "first") {
    //                 setFirstHighlight(true)
    //                 setSecondHighlight(false)
    //                 setThirdHighlight(false)

    //                 if (answer.toLowerCase() === "yes") {
    //                     setFirstTime(true)
    //                 } else if (answer.toLowerCase() === "no") {
    //                     setFirstTime(false)
    //                 } else {
    //                 }
    //             } else if (q_number === "service type" || q_number === "service") {
    //                 setFirstHighlight(false)
    //                 setSecondHighlight(true)
    //                 setThirdHighlight(false)

    //                 if (answer.toLowerCase() === "bath" || answer.toLowerCase() === "but") {
    //                     setServiceType("Bath")
    //                 } else if (answer.toLowerCase() === "full") {
    //                     setServiceType("Full")
    //                 } else if (answer.toLowerCase() === "mini" || answer.toLowerCase() === "meaning") {
    //                     setServiceType("Mini")
    //                 } else {
    //                 }
    //             } else if (q_number === "full" || q_number === "full groom") {
    //                 setFirstHighlight(false)
    //                 setSecondHighlight(false)
    //                 setThirdHighlight(true)

    //                 if (answer.toLowerCase() === "puppy" || answer.toLowerCase() === "bobby" || answer.toLowerCase() === "puppy") {
    //                     setPuppyCut(!puppyCut)
    //                 } else if (answer.toLowerCase() === "standard" || answer.toLowerCase() === "standard") {
    //                     setStanderdCut(!standerdCut)
    //                 } else if (answer.toLowerCase() === "hand strip") {
    //                     setHandStrip(!handStrip)
    //                 } else if (answer.toLowerCase() === "shavedown" || answer.toLowerCase() === "shaved down") {
    //                     setShaveDown(!shaveDown)
    //                 }
    //             }
    //         },
    //         "show message *message": (message) => {
    //             // Implement logic to display a message
    //             console.log(`Showing message: ${message}`)
    //         },
    //     }

    //     // Add the commands to annyang
    //     annyang.addCommands(commands)

    //     // Specify language (optional)
    //     annyang.setLanguage("en-US")

    //     // Specify additional configuration options (optional)
    //     annyang.debug(true)

    //     // Clean up on component unmount
    //     return () => {
    //         annyang.abort()
    //     }
    // }, [])

    const MAP = {
        name: "my-map",
        areas: [
            { id: "1", shape: "circle", coords: [90, 80, 20], fillColor: "rgba(255, 0, 0, 0.5)", preFillColor: "rgba(255, 0, 0, 0.5)", toggleHighlighted: true },
            { id: "2", shape: "circle", coords: [90, 30, 20], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(255, 255, 255, 0.5)", toggleHighlighted: true },
            { id: "3", shape: "circle", coords: [250, 220, 20], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(0, 255, 0, 0.5)", toggleHighlighted: true },
            { id: "4", shape: "circle", coords: [190, 350, 20], fillColor: "rgba(0, 255, 0, 0.5)", preFillColor: "rgba(0, 255, 0, 0.5)", toggleHighlighted: true },
        ],
    }

    // const handleClick = (area) => {
    //     console.log(`Clicked on area ${area.id}`)
    // }

    const handleClick = (area, _, event) => {
        const coords = {
            x: event.nativeEvent.offsetX,
            y: event.nativeEvent.offsetY,
        } as any
        console.log(`Clicked at coordinates:`, coords)
    }
    return (
        <div>
            <Modal open={props.isOpenGroomerNoteModal}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", flexDirection: "column", width: "700px" }}>
                    <div style={{ backgroundColor: "#fff", maxWidth: "800px", width: "100%", borderRadius: "10px" }}>
                        <div style={{ padding: "20px" }}>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <p style={{ fontWeight: 600, fontSize: "20px", width: "100%" }}>Groomer Note</p>

                                <CloseButton onClick={() => props.setIsOpenGroomerNoteModal(false)} />
                                {/* <IconButton>
                                    <Mic></Mic>
                                </IconButton> */}
                            </div>
                        </div>

                        <div style={{ padding: "20px", backgroundColor: "#F9FAFB", height: "500px", overflowY: "scroll" }}>
                            <StepperNotes noteData={noteData} type={props?.type} />
                            <div style={{ width: "100%", marginBottom: ".6rem", border: `${firstHighlight ? "4px solid red" : "0px solid red"}` }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>1. First groom at Lucky Paws</p>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={firstTime}
                                    onChange={handleChangeActiveStatus}
                                    style={{ flexDirection: "column" }}
                                >
                                    <FormControlLabel value={true} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Yes</p>} />
                                    <FormControlLabel value={false} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>No</p>} />
                                </RadioGroup>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem", border: `${secondHighlight ? "4px solid red" : "0px solid red"}` }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>2. Service Type</p>
                                <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={serviceType}
                                        onChange={handleServiceType}
                                        style={{ flexDirection: "column" }}
                                    >
                                        <FormControlLabel value={"Bath"} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Bath Only</p>} />
                                        <FormControlLabel value={"Full"} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Full Groom</p>} />
                                        <FormControlLabel value={"Mini"} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Mini Groom</p>} />
                                    </RadioGroup>
                                </RadioGroup>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem", border: `${thirdHighlight ? "4px solid red" : "0px solid red"}` }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>3. Full groom Type</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setPuppyCut(!puppyCut)} checked={puppyCut} />
                                    <p>Puppy Cut ( One Length All Over)</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setStanderdCut(!standerdCut)} checked={standerdCut} />
                                    <p>Breed Standard Cut</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setHandStrip(!handStrip)} checked={handStrip} />
                                    <p>Hand Strip</p>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setShaveDown(!shaveDown)} checked={shaveDown} />
                                    <p>Shave Down</p>
                                </div>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>4. Head</p>
                                <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select comb"}
                                        ObjectName={"Comb"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={headComb}
                                        onChange={(e) => {
                                            setHeadComb(e)
                                        }}
                                    />
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select blade"}
                                        ObjectName={"Blade"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={headClade}
                                        onChange={(e) => {
                                            setHeadBlade(e)
                                        }}
                                    />

                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select style"}
                                        ObjectName={"Style"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={headStyle}
                                        onChange={(e) => {
                                            setHeadStyle(e)
                                        }}
                                    />

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setHeadScissorUsed(!headScissorUsed)} checked={headScissorUsed} />
                                        <p>I used a Scissor</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>5. Face</p>
                                <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select comb"}
                                        ObjectName={"Comb"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={faceComb}
                                        onChange={(e) => {
                                            setFaceComb(e)
                                        }}
                                    />
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select blade"}
                                        ObjectName={"Blade"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={faceBlade}
                                        onChange={(e) => {
                                            setFaceBlade(e)
                                        }}
                                    />

                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select style"}
                                        ObjectName={"Style"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={faceStyle}
                                        onChange={(e) => {
                                            setFaceStyle(e)
                                        }}
                                    />

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setFaceScissorUsed(!faceScissorUsed)} checked={faceScissorUsed} />
                                        <p>I used a Scissor</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>6. Ears</p>
                                <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select comb"}
                                        ObjectName={"Comb"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={earComb}
                                        onChange={(e) => {
                                            setEarComb(e)
                                        }}
                                    />
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select blade"}
                                        ObjectName={"Blade"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={earBlade}
                                        onChange={(e) => {
                                            setEarBlade(e)
                                        }}
                                    />

                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select style"}
                                        ObjectName={"Style"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={earStyle}
                                        onChange={(e) => {
                                            setEarStyle(e)
                                        }}
                                    />

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setEarScissorUsed(!earScissorUsed)} checked={earScissorUsed} />
                                        <p>I used a Scissor</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>7. Body</p>
                                <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select comb"}
                                        ObjectName={"Comb"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={bodyComb}
                                        onChange={(e) => {
                                            setBodyComb(e)
                                        }}
                                    />
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select blade"}
                                        ObjectName={"Blade"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={bodyBlade}
                                        onChange={(e) => {
                                            setBodyBlade(e)
                                        }}
                                    />

                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select style"}
                                        ObjectName={"Style"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={bodyStyle}
                                        onChange={(e) => {
                                            setBodyStyle(e)
                                        }}
                                    />

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setBodyScissorUsed(!bodyScissorUsed)} checked={bodyScissorUsed} />
                                        <p>I used a Scissor</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>8. Legs</p>
                                <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select comb"}
                                        ObjectName={"Comb"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={legsComb}
                                        onChange={(e) => {
                                            setLegsComb(e)
                                        }}
                                    />
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select blade"}
                                        ObjectName={"Blade"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={legsBlade}
                                        onChange={(e) => {
                                            setLegsBlade(e)
                                        }}
                                    />

                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select style"}
                                        ObjectName={"Style"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={legsStyle}
                                        onChange={(e) => {
                                            setLegsStyle(e)
                                        }}
                                    />

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setLegsScissorUsed(!legsScissorUsed)} checked={legsScissorUsed} />
                                        <p>I used a Scissor</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>9. Tail</p>
                                <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select comb"}
                                        ObjectName={"Comb"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={tailComb}
                                        onChange={(e) => {
                                            setTailComb(e)
                                        }}
                                    />
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select blade"}
                                        ObjectName={"Blade"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={tailBlade}
                                        onChange={(e) => {
                                            setTailBlade(e)
                                        }}
                                    />

                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select style"}
                                        ObjectName={"Style"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={tailStyle}
                                        onChange={(e) => {
                                            setTailStyle(e)
                                        }}
                                    />

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setTailScissorUsed(!tailScissorUsed)} checked={tailScissorUsed} />
                                        <p>I used a Scissor</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>10. Feet</p>
                                <div style={{ width: "100%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1rem", rowGap: "1rem", marginLeft: "8px" }}>
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select comb"}
                                        ObjectName={"Comb"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={feetComb}
                                        onChange={(e) => {
                                            setFeetComb(e)
                                        }}
                                    />
                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select blade"}
                                        ObjectName={"Blade"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={feetBlade}
                                        onChange={(e) => {
                                            setFeetBlade(e)
                                        }}
                                    />

                                    <AdvancedInput
                                        required={false}
                                        isButton={false}
                                        label={"Select style"}
                                        ObjectName={"Style"}
                                        isCustom={false}
                                        Sequence={""}
                                        selectedValue={feetStyle}
                                        onChange={(e) => {
                                            setFeetStyle(e)
                                        }}
                                    />

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setFeetScissorUsed(!feetScissorUsed)} checked={feetScissorUsed} />
                                        <p>I used a Scissor</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>11. Difficult to dry?</p>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={dryingDiff}
                                    onChange={handleDryingStatus}
                                    style={{ flexDirection: "column" }}
                                >
                                    <FormControlLabel value={true} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Yes</p>} />
                                    <FormControlLabel value={false} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>No</p>} />
                                </RadioGroup>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>12. Recommendation for next groom</p>
                                <AdvancedInput
                                    required={false}
                                    isButton={false}
                                    label={"Select Shampoo"}
                                    ObjectName={"Shampoo"}
                                    isCustom={false}
                                    Sequence={""}
                                    onChange={(e) => {
                                        setRecShampoo(e)
                                    }}
                                    selectedValue={recShampoo}
                                />
                            </div>

                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <p>Microphone: {listening ? "on" : "off"}</p>
                                <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={() => SpeechRecognition.startListening({ continuous: true })}>
                                    Start
                                </button>
                                <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={SpeechRecognition.stopListening}>
                                    Stop
                                </button>
                                <button
                                    style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }}
                                    onClick={() => {
                                        resetTranscript()
                                        setOverallComments("")
                                    }}
                                >
                                    Reset
                                </button>
                            </div>

                            <div style={{ width: "100%", marginBottom: ".6rem" }}>
                                <p style={{ fontWeight: "600", fontSize: "14px" }}>Overall Groomer Comments</p>
                                <TextField
                                    style={{ width: "100%", marginTop: "1rem" }}
                                    label={<div style={{ display: "flex", fontSize: "14px" }}>{`Add your suggestions here.`}</div>}
                                    required={true}
                                    onChange={(e) => {
                                        setOverallComments(e.target.value)
                                    }}
                                    type={"text"}
                                    variant="outlined"
                                    multiline={true}
                                    value={overallComments}
                                />
                            </div>
                        </div>
                        {props.type === "add" ? (
                            <div style={{ padding: "20px", backgroundColor: "#fff", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        text="Save Note"
                                        onClick={() => {
                                            saveNote()
                                        }}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default GroomerNote
