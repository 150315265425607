import { ArrowBack, CameraAltOutlined, Check, Close, FileUploadOutlined, ImageOutlined, OpenInNewOutlined, UndoOutlined, Warning } from "@mui/icons-material"
import { Avatar, Button, Chip, CloseButton } from "../../../../components"
import Steps from "../Steps/Steps"
import { useEffect, useState } from "react"
import { Box, Checkbox, FormControlLabel, Grid, Modal, Radio, RadioGroup, Tab, Tabs, TextField, Button as MUIButton, CircularProgress } from "@mui/material"
import CheckInModal from "../../../../components/ExtModals/CheckInModal"
import axios, { axiosPrivate } from "../../../../apis/axios"
import { toast } from "react-toastify"
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition"
import styles from "./WorkingTab.module.scss"
import { fullDate, messagingDate, shortDate } from "../../../../utils/DateFormatter"
import { AdvancedInput } from "../../../../components/AdvancedInput/AdvancedInput"

import GroomerNote from "../../../../components/ExtModals/GroomerNote"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { formatDateDisplay, getMinutesDuration } from "../../../../utils/TimeHandler"
import { FileUploader } from "react-drag-drop-files"
import Compressor from "compressorjs"
import { sendAppointmentStaticMessage, sendOutNotification } from "../../../../utils/Messaging"
import generatePDF from "../../../../utils/generatePDF"
import AddGroomerNote from "../../../../components/ExtModals/AddGroomerNote"

const WorkingTab = (props: { bookingID? }) => {
    const navigate = useNavigate()
    const location = useLocation() as any

    const [summaryDetails, setSummaryDetails] = useState({}) as any
    const [checkinDetails, setcheckinDetails] = useState([]) as any

    const [msgBody, setMsgBody] = useState("")
    const [startGrooming, setStartGrooming] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition()

    useEffect(() => {
        setMsgBody(transcript)
    }, [transcript])

    const [tabIndex, setTabIndex] = useState(0)
    const [noteTabIndex, setNoteTabIndex] = useState(0)
    const [uniqueParam] = useState(new Date().getTime())
    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex)
    }

    const handleNoteTabChange = (event, newTabIndex) => {
        setNoteTabIndex(newTabIndex)
    }

    const [messages, setMessages] = useState([
        {
            transferTypeID: 1,
            message: "Hi",
            logDateTime: "2023-06-21T10:55:30.5",
        },
        {
            transferTypeID: 2,
            message: "Hello, Yes?",
            logDateTime: "2023-06-21T10:59:30.5",
        },
    ]) as any

    const encodeURL = (inputString) => {
        let encodedString = inputString.replace(/:/g, "%3A")
        encodedString = encodedString.replace(/\s/g, "%20")

        return encodedString
    }

    async function getMessages(number, type) {
        await axios
            .get(`${process.env.REACT_APP_TEXT_API_URL}UpdateMessages/GetChatHistory?CustomerNumber=%2B94${number}&CommunicationMethod=${type}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log(result?.data[0].messages)
                    setMessages(result?.data[0].messages)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function updateAppointmentTimes(isStart, appointmentID) {
        const currTimestamp = fullDate(new Date())

        console.log(
            `Appointment/UpdateStartEndTimebyAppointmentID?AppointmentID=${appointmentID}${isStart ? `&dtStartTime=${encodeURL(currTimestamp)}` : ""}${
                isStart === false ? `&dtEndTime=${encodeURL(currTimestamp)}` : ""
            }`
        )
        await axiosPrivate
            .put(
                `Appointment/UpdateStartEndTimebyAppointmentID?AppointmentID=${appointmentID}${isStart ? `&dtStartTime=${encodeURL(currTimestamp)}` : ""}${
                    isStart === false ? `&dtEndTime=${encodeURL(currTimestamp)}` : ""
                }`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            )
            .then((result) => {
                if (result.status === 200) {
                    if (isStart) {
                        toast.success("Start Time successfully updated!")
                    } else {
                        toast.success("End Time successfully updated!")
                        completeGrooming()
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function updateTaskTime(chargeID, status) {
        const currTimestamp = fullDate(new Date())

        await axiosPrivate
            .put(`Wellness/UpdateStartEndTimebyChargeID?ChargeID=${chargeID}&dtStartTime=${encodeURL(currTimestamp)}&IsTimerStarted=${status ? "false" : "true"}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    /* GROOMER NOTES */
    const [isOpenGroomerNote, setIsOpenGroomerNote] = useState(false)
    const [appointmentLoaded, setAppointmentLoaded] = useState(false)
    const [groomerNote, setGroomerNote] = useState<any>({})
    const [isOpenGroomerNoteShow, setIsOpenGroomerNoteShow] = useState(false)

    const [isOpenAddGroomerNoteShow, setIsOpenAddGroomerNoteShow] = useState(false)

    const updateToggleStatus = (chargeID, ind) => {
        let servLoop = { ...summaryDetails }
        servLoop.Services[ind].toggleStatus = !servLoop.Services[ind].toggleStatus
        updateTaskTime(chargeID, !servLoop.Services[ind].toggleStatus)
        setSummaryDetails(servLoop)
    }

    const updateToggleMainStatus = () => {
        let servLoop = { ...summaryDetails }
        servLoop.toggleMainStatus = !servLoop.toggleMainStatus
        setStartGrooming(servLoop.toggleMainStatus)
        updateAppointmentTimes(servLoop.toggleMainStatus, servLoop.numAppointmentID)

        setSummaryDetails(servLoop)
    }

    async function getAppointmentInfo(bookingID) {
        await axios
            .get(`${process.env.REACT_APP_API_URL}Appointment/GetAppointmnetByID?AppointmentID=${bookingID}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("appointment ---", result.data.Appointment)

                    let servLoop = result.data.Appointment

                    servLoop.toggleMainStatus = false

                    servLoop?.Services.map((serv, ind) => {
                        servLoop.Services[ind].toggleStatus = false
                    })

                    setSummaryDetails(servLoop)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function getAppointmentGroomerNotes(bookingID) {
        await axios
            .get(`${process.env.REACT_APP_API_URL}Appointment/GetAppointmnetByID?AppointmentID=${bookingID}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("notes", result.data.Appointment.GroomerNotes)
                    const summary = { ...summaryDetails }
                    summary.GroomerNotes = result.data.Appointment.GroomerNotes
                    setSummaryDetails(summary)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        console.log("ry", summaryDetails)
    }, [summaryDetails])

    async function updateStatus(data) {
        await axios
            .put(`${process.env.REACT_APP_API_URL}Appointment/UpdateAppointmentStatus`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`Grooming Complete!`)
                    setIsLoading(false)
                    const from = location.state?.from?.pathname || `/tablet`
                    navigate(from, { replace: true })
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function getCheckinDetails(bookingID) {
        await axios
            .get(`${process.env.REACT_APP_API_URL}CheckIn/getCheckInByAppointmentID?AppointmentID=${bookingID}`)
            .then((result) => {
                if (result.status === 200) {
                    console.log("checkIn", result.data.CheckIn)
                    setcheckinDetails(result.data.CheckIn)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function GetWellnessByPetTypeID(id) {
        const result: any = await axiosPrivate.get(`Wellness/GetWelnessByPetTypeID?PetTypeID=${id}`)
        return result
    }
    async function GetWellnessByCheckInID(id) {
        const result: any = await axiosPrivate.get(`Wellness/GetWellnessByWelnessCheckID?WellnessCheckInID=${id}`)
        return result
    }

    console.log("tab-working", summaryDetails)

    const completeGrooming = () => {
        let payload = {
            numAppointmentID: summaryDetails?.numAppointmentID,
            apptStatus: "PICKUP-READY",
            isApptCheckedInStatus: summaryDetails?.IsApptCheckedInStatus,
            isServiceArea: true,
            isReadyForPickUp: true,
            isPaymentMade: summaryDetails?.IsPaymentMade,
            isCheckedOut: summaryDetails?.IsCheckedOut,
            numEditedBy: 1,
        }

        updateStatus(payload)
    }

    const CompleteGroomSubmit = () => {
        setIsLoading(true)
        console.log("PetTypeID", summaryDetails?.Pet?.PetTypeID, checkinDetails?.CheckInID)
        GetWellnessByPetTypeID(summaryDetails?.Pet?.PetTypeID)
            .then((response) => {
                GetWellnessByCheckInID(checkinDetails?.CheckInID)
                    .then((response_app_wellness) => {
                        let setWellnessBaseData = {} as any
                        if (response_app_wellness.data.length > 0) {
                            console.log("well", response_app_wellness)
                            let firstArray = response.data.Wellness
                            let secondArray = response_app_wellness.data[0].Wellness

                            for (let i = 0; i < firstArray.length; i++) {
                                const concerns1 = firstArray[i].Concerns
                                const bodyPartID = firstArray[i].BodyPartID

                                const matchingObj = secondArray.find((item) => item.BodyPartID === bodyPartID)

                                if (matchingObj) {
                                    firstArray[i].isAbnormal = matchingObj.IsAbnormal

                                    for (let j = 0; j < concerns1.length; j++) {
                                        const concernID = concerns1[j].ConcernID

                                        const isMatchingConcern = matchingObj.Concerns.some((concern) => concern.ConcernID === concernID)
                                        concerns1[j].checked = isMatchingConcern
                                    }
                                } else {
                                    firstArray[i].isAbnormal = false

                                    for (let j = 0; j < concerns1.length; j++) {
                                        concerns1[j].checked = false
                                    }
                                }
                            }

                            console.log("sorted", firstArray)

                            const checkImageList = response_app_wellness.data[0].checkImageList.map((item) => {
                                return {
                                    varImagePath: item.WellnessCheckImagePath,
                                }
                            })

                            let wellObj = {
                                WellnessCheckID: response_app_wellness.data[0].WellnessCheckID,
                                NotesForClient: response_app_wellness.data[0].NotesForClient,
                                Suggestions: response_app_wellness.data[0].Suggestions,
                                EvaluatorID: response_app_wellness.data[0].EvaluatorID,
                                NumCreatedBy: response_app_wellness.data[0].NumCreatedBy,
                                dtCreatedDate: response_app_wellness.data[0].dtCreatedDate,
                                Wellness: firstArray,
                                checkImageList: checkImageList,
                                RecommendedServices: response_app_wellness.data[0].RecommendedServices,
                                RecommendedProducts: response_app_wellness.data[0].RecommendedProducts,
                                appointmentDetails: {
                                    appointmentID: checkinDetails?.Appointment?.numAppointmentID,
                                    location: checkinDetails?.Appointment?.varLocationName,
                                    startDate: checkinDetails?.Appointment?.dtApptStartTime,
                                    petName: checkinDetails?.Pet?.PetName,
                                },
                            }
                            setWellnessBaseData = wellObj
                        } else {
                            setWellnessBaseData = {
                                WellnessCheckID: null,
                                NotesForClient: null,
                                Suggestions: null,
                                EvaluatorID: null,
                                NumCreatedBy: null,
                                dtCreatedDate: null,
                                Wellness: response.data.Wellness,
                                checkImageList: [],
                                RecommendedServices: [],
                                RecommendedProducts: [],
                                appointmentDetails: {
                                    appointmentID: null,
                                    location: null,
                                    startDate: null,
                                    petName: null,
                                },
                            }
                        }

                        handleGeneratePDF(setWellnessBaseData)
                        console.log("wellnessdata", setWellnessBaseData)
                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                    })
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoading(false)
            })
    }

    const handleGeneratePDF = async (data) => {
        const pdfBlob = await generatePDF(data)

        const formData = new FormData()
        formData.append("folderpath", "email_attachments/wellness_report")
        formData.append("method", "N")
        formData.append("deletingFileName", "-")
        formData.append("renameFileNames", "0")

        const blobname = "WellnessReportREF" + checkinDetails?.CheckInID
        const renamedFile = new File([pdfBlob], `${blobname}.pdf`, { type: pdfBlob.type })
        formData.append("files", renamedFile)

        axios
            .post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
            .then((response) => {
                console.log("Upload-Res", response.data.files[0])
                //Send Out Message

                let Attachments = ``

                let sendFiles = [
                    {
                        messageID: 1,
                        fileName: response.data.files[0]?.renamedFileName + ".pdf",
                        filePath: `${process.env.REACT_APP_MEDIA_URL}email_attachments/wellness_report/${response.data.files[0]?.renamedFileName}.pdf`,
                    },
                ]

                if (summaryDetails?.varBeforeImgPath !== null && summaryDetails?.varAfterImgPath !== null) {
                    sendFiles.push(
                        {
                            messageID: 1,
                            fileName: summaryDetails?.varBeforeImgPath,
                            filePath: `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails?.varBeforeImgPath}`,
                        },
                        {
                            messageID: 1,
                            fileName: summaryDetails?.varAfterImgPath,
                            filePath: `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails?.varAfterImgPath}`,
                        }
                    )

                    Attachments = `<h3>Attachments:</h3>
                                        <ol>
                                            <li>
                                                <p>Wellness Report: <a href="${process.env.REACT_APP_MEDIA_URL}email_attachments/wellness_report/${response.data.files[0]?.renamedFileName}.pdf" download="appointment.ics"  target="_blank" rel="noopener noreferrer">Download</a></p>
                                            </li>
                                            <li>
                                                <p>Before Image: <a href="${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails?.varBeforeImgPath}" download="before_image.jpg"  target="_blank" rel="noopener noreferrer">Download</a></p>
                                            </li>
                                            <li>
                                                <p>After Image: <a href="${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails?.varAfterImgPath}" download="After_image.jpg"  target="_blank" rel="noopener noreferrer">Download</a></p>
                                            </li>
                                        </ol>`
                } else if (summaryDetails?.varBeforeImgPath !== null) {
                    sendFiles.push({
                        messageID: 1,
                        fileName: summaryDetails?.varBeforeImgPath,
                        filePath: `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails?.varBeforeImgPath}`,
                    })

                    Attachments = `<h3>Attachments:</h3>
                        <ol>
                            <li>
                                <p>Wellness Report: <a href="${process.env.REACT_APP_MEDIA_URL}email_attachments/wellness_report/${response.data.files[0]?.renamedFileName}.pdf" download="appointment.ics"  target="_blank" rel="noopener noreferrer">Download</a></p>
                            </li>
                            <li>
                                <p>Before Image: <a href="${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails?.varBeforeImgPath}" download="before_image.jpg"  target="_blank" rel="noopener noreferrer">Download</a></p>
                            </li>
                        </ol>`
                } else if (summaryDetails?.varAfterImgPath !== null) {
                    sendFiles.push({
                        messageID: 1,
                        fileName: summaryDetails?.varAfterImgPath,
                        filePath: `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails?.varAfterImgPath}`,
                    })
                    Attachments = `<h3>Attachments:</h3>
                        <ol>
                            <li>
                                <p>Wellness Report: <a href="${process.env.REACT_APP_MEDIA_URL}email_attachments/wellness_report/${response.data.files[0]?.renamedFileName}.pdf" download="appointment.ics"  target="_blank" rel="noopener noreferrer">Download</a></p>
                            </li>
                            <li>
                                <p>After Image: <a href="${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${summaryDetails?.varAfterImgPath}" download="After_image.jpg"  target="_blank" rel="noopener noreferrer">Download</a></p>
                            </li>
                        </ol>`
                }
                completeGrooming()
                // MESSAGEREADYFORPICKUP
                sendAppointmentStaticMessage(summaryDetails?.numAppointmentID, "Appointment", 3, "E", sendFiles)
                let Description = `<p>Grooming service for your beloved pet, <strong>${summaryDetails?.Pet?.PetName}</strong> has been successfully completed at our <strong>${summaryDetails?.varLocationName}</strong> facility. Your pet is now looking fresh, happy, and ready to be reunited with you.</p>`

                let notificationPostBody = {
                    notificationType: "success",
                    subject: "Service is Complete and Ready for Pick-Up Ref#:" + summaryDetails?.numAppointmentID,
                    notificationMessage: Description + Attachments,
                    locationID: 1,
                    locationName: summaryDetails?.varLocationName,
                    recipientType: "C",
                    recipientID: summaryDetails?.Customer?.numCustomerID,
                    recipientName: summaryDetails?.Customer?.varCustomerFirstName + " " + summaryDetails?.Customer?.varCustomerLastName,
                    isSeen: false,
                }
                sendOutNotification(notificationPostBody)
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })

        // Create a temporary anchor element
        // const downloadLink = document.createElement('a');
        // downloadLink.href = URL.createObjectURL(pdfBlob);
        // downloadLink.download = 'petList.pdf'; // Specify the file name
        // document.body.appendChild(downloadLink);

        // // Trigger the download
        // downloadLink.click();

        // // Clean up
        // document.body.removeChild(downloadLink);
        // URL.revokeObjectURL(downloadLink.href);

        // Handle the PDF blob, e.g., download or further processing
    }

    useEffect(() => {
        const appData = location.state && location.state?.appData
        console.log("asdasds", appData)

        if (parseInt(appData) === 0) {
            setAppointmentLoaded(false)
        } else {
            setAppointmentLoaded(true)
            getAppointmentInfo(appData)
            getBeforeAfterImages(appData)
            // getMessages()
        }
        console.log("asdasds", appData)

        getCheckinDetails(appData)
    }, [props?.bookingID])

    const openGroomerNote = (index) => {
        console.log("note", summaryDetails.GroomerNotes[index])
        setGroomerNote(summaryDetails.GroomerNotes[index])
        setIsOpenGroomerNoteShow(true)
    }

    /* IMAGE UPLOAD - BEFORE and AFTER */
    const fileTypes = ["JPEG", "jpg", "WEBP", "PNG", "png"]
    const [importedDataBefore, setImportedDataBefore] = useState<any>("")
    const [beforeAfterPayload, setBeforeAfterPayload] = useState<any>({})
    const [fileSelectedBefore, setFileSelectedBefore] = useState(false)

    const [loadingBefore, setLoadingBefore] = useState(false)
    const [loadingAfter, setLoadingAfter] = useState(false)

    const [importedDataAfter, setImportedDataAfter] = useState<any>("")
    const [fileSelectedAfter, setFileSelectedAfter] = useState(false)

    const [imageUploadLoading, setimageUploadLoading] = useState(false)

    const [imageNameBefore, setImageNameBefore] = useState<any>("")
    const [imageNameAfter, setImageNameAfter] = useState<any>("")

    async function getBeforeAfterImages(bookingID) {
        await axios
            .get(`${process.env.REACT_APP_API_URL}Appointment/GetAppintmentImagesByAppointmentID?AppointmentID=${bookingID}`)

            .then((result) => {
                if (result.status === 200) {
                    console.log("images", result.data)
                    if (result?.data?.length > 0) {
                        setBeforeAfterPayload(result.data[0])
                    } else {
                        setBeforeAfterPayload({
                            ID: 0,
                            numAppointmentID: bookingID,
                            beforeImg: "",
                            afterImg: "",
                        })
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function updateBeforeAfterImage(data) {
        await axios
            .post(`${process.env.REACT_APP_API_URL}Appointment/PopulateAppointmentImages`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`Image Uploaded!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Image failed to upload")
            })
    }

    const readFileBefore = (files) => {
        if (files.size > 1000000) {
            // Compress the image if it's too large
            const options = {
                maxWidth: 800,
                maxHeight: 800,
                quality: 0.8,
                mimeType: files.type,
            }

            new Compressor(files, {
                ...options,
                success(result) {
                    let res = result as any
                    console.info(result)
                    setImportedDataBefore(res)
                    setFileSelectedBefore(true)
                    setImageNameBefore(res.name)
                },
                error(err) {
                    console.error("Error compressing image:", err)
                    alert("Error compressing image")
                },
            })
        } else {
            console.info(files)
            setImportedDataBefore(files)
            setFileSelectedBefore(true)
            setImageNameBefore(files.name)
        }
    }

    const readFileAfter = (files) => {
        if (files.size > 1000000) {
            // Compress the image if it's too large
            const options = {
                maxWidth: 800,
                maxHeight: 800,
                quality: 0.8,
                mimeType: files.type,
            }

            new Compressor(files, {
                ...options,
                success(result) {
                    let res = result as any
                    console.info(result)
                    setImportedDataAfter(res)
                    setFileSelectedAfter(true)
                    setImageNameAfter(res.name)
                },
                error(err) {
                    console.error("Error compressing image:", err)
                    alert("Error compressing image")
                },
            })
        } else {
            console.info(files)
            setImportedDataAfter(files)
            setFileSelectedAfter(true)
            setImageNameAfter(files.name)
        }
    }

    const uploadBeforeAfter = (type) => {
        setimageUploadLoading(true)

        let imgType = ""

        if (type === "before") {
            imgType = "_before"

            if (!fileSelectedBefore) {
            } else {
                const formData = new FormData()
                formData.append("folderpath", `app_before_after_images`)
                formData.append("method", "N")
                formData.append("deletingFileName", "-")
                formData.append("renameFileNames", "0")

                const file = importedDataBefore
                const fileExtension = file.name.split(".").pop()
                const renamedFile = new File([file], `${summaryDetails?.numAppointmentID}${imgType}.${fileExtension}`, {
                    type: file.type,
                })
                formData.append("files", renamedFile)

                axios
                    .post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
                    .then((response) => {
                        console.log(response)

                        if (response.data.files.length > 0) {
                            let newValue = { ...beforeAfterPayload }
                            newValue.beforeImg = `${summaryDetails?.numAppointmentID}${imgType}.${fileExtension}`
                            setBeforeAfterPayload(newValue)
                            let addBeforeImage = { ...summaryDetails }
                            addBeforeImage.varBeforeImgPath = `${summaryDetails?.numAppointmentID}${imgType}.${fileExtension}`
                            setSummaryDetails(addBeforeImage)
                            updateBeforeAfterImage(newValue)
                        }

                        setimageUploadLoading(false)
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        } else {
            imgType = "_after"

            if (!fileSelectedAfter) {
            } else {
                const formData = new FormData()
                formData.append("folderpath", `app_before_after_images`)
                formData.append("method", "N")
                formData.append("deletingFileName", "-")
                formData.append("renameFileNames", "0")

                const file = importedDataAfter
                const fileExtension = file.name.split(".").pop()
                const renamedFile = new File([file], `${summaryDetails?.numAppointmentID}${imgType}.${fileExtension}`, {
                    type: file.type,
                })
                formData.append("files", renamedFile)

                axios
                    .post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
                    .then((response) => {
                        console.log(response)

                        if (response.data.files.length > 0) {
                            let newValue = { ...beforeAfterPayload }
                            newValue.afterImg = `${summaryDetails?.numAppointmentID}${imgType}.${fileExtension}`
                            let addAfterImage = { ...summaryDetails }
                            addAfterImage.varAfterImgPath = `${summaryDetails?.numAppointmentID}${imgType}.${fileExtension}`
                            setSummaryDetails(addAfterImage)

                            setBeforeAfterPayload(newValue)

                            updateBeforeAfterImage(newValue)
                        }

                        setimageUploadLoading(false)
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }
    }

    useEffect(() => {
        console.log("summ", summaryDetails)
    }, [summaryDetails])

    return (
        <>
            {appointmentLoaded ? (
                <>
                    <h1 style={{ margin: "20px 0 15px 20px ", padding: "0", fontSize: "24px" }}>Working - Grooming</h1>
                    <div style={{ flex: 1, overflowY: "scroll", margin: "0 0 20px 0", scrollbarGutter: "stable both-ends" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <div style={{ width: "100%", margin: "0 20px 20px 20px" }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                                    <div className={styles.appointment}>
                                        <Steps statusSummary={summaryDetails} generalType={false} />
                                    </div>

                                    <div style={{ display: "flex", width: "100%" }}>
                                        <div style={{ flex: 8, gap: "3rem", border: "1px solid #F3F4F6", padding: "1rem", borderRadius: "8px 0 0 8px", backgroundColor: "#FFFFFF" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", marginRight: "10px" }}>
                                                        <Avatar
                                                            shape="rounded"
                                                            img={`${process.env.REACT_APP_MEDIA_URL}directories/pets/${summaryDetails.Pet != undefined ? summaryDetails.Pet.PetImagePath : null}`}
                                                            isVip={false}
                                                            className={"auto"}
                                                        />
                                                    </div>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                                        <div style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>{summaryDetails.Pet != undefined ? summaryDetails.Pet.PetName : null}</div>
                                                        <div style={{ fontWeight: 400, fontSize: 14, color: "#848FAC" }}>{summaryDetails.Pet != undefined ? summaryDetails.Pet.PetBreed : null}</div>

                                                        <div style={{ display: "flex", marginTop: "15px", gap: ".3rem", alignItems: "center" }}>
                                                            <Chip
                                                                size="small"
                                                                customBackgroundColor={"#EFF3FA"}
                                                                customBorder={"#EFF3FA"}
                                                                customBorderRadius={"16px"}
                                                                fontColor={"#192252"}
                                                                label={summaryDetails.Pet != undefined ? summaryDetails.Pet.varPetSizeName : null}
                                                            />
                                                            <Chip
                                                                size="small"
                                                                customBackgroundColor={"#EFF3FA"}
                                                                customBorder={"#EFF3FA"}
                                                                customBorderRadius={"16px"}
                                                                fontColor={"#192252"}
                                                                label={summaryDetails.Pet != undefined ? (summaryDetails.Pet.PetColor != "" ? summaryDetails.Pet.PetColor : "-") : "-"}
                                                            />
                                                            <Chip
                                                                size="small"
                                                                customBackgroundColor={"#EFF3FA"}
                                                                customBorder={"#EFF3FA"}
                                                                customBorderRadius={"16px"}
                                                                fontColor={"#192252"}
                                                                label={summaryDetails.Pet != undefined ? summaryDetails.Pet.PetSex : null}
                                                            />
                                                            <Chip
                                                                size="small"
                                                                customBackgroundColor={"#EFF3FA"}
                                                                customBorder={"#EFF3FA"}
                                                                customBorderRadius={"16px"}
                                                                fontColor={"#192252"}
                                                                label={summaryDetails.Pet != undefined ? summaryDetails.Pet.PetType : null}
                                                            />
                                                            {/* <Warning style={{ color: "#EB1A1A", fontSize: "20px" }} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 1,
                                                borderRadius: "0 8px 8px 0",
                                                backgroundColor: "#DFE8F6",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "10px 20px 10px 20px",
                                            }}
                                        >
                                            <p style={{ color: "#606D93", fontSize: "14px", fontWeight: "600" }}>CAGE</p>
                                            <p style={{ color: "#192252", fontWeight: "600", fontSize: "32px" }}>{summaryDetails?.CageID === 0 ? "-" : `#${summaryDetails?.CageID}`}</p>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", gap: "15px", width: "100%", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%", backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #d6d6d7", padding: "0 10px 10px 10px" }}>
                                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>Pet Images</p>
                                            </div>
                                            <Box>
                                                <Box>
                                                    <Tabs value={tabIndex} onChange={handleTabChange}>
                                                        <Tab label="Before" />
                                                        <Tab label="After" />
                                                    </Tabs>
                                                </Box>
                                                <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                                    {tabIndex === 0 && (
                                                        <div
                                                            style={{
                                                                backgroundColor: "#F3F4F6",
                                                                borderRadius: "8px",
                                                                height: "200px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "20px",
                                                            }}
                                                        >
                                                            {beforeAfterPayload?.beforeImg === "" ? (
                                                                <ImageOutlined style={{ color: "#FFFF", fontSize: "100px" }} />
                                                            ) : (
                                                                <img
                                                                    style={{ height: "200px", width: "200px", objectFit: "cover", borderRadius: "8px" }}
                                                                    src={
                                                                        fileSelectedBefore
                                                                            ? URL.createObjectURL(importedDataBefore)
                                                                            : `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${beforeAfterPayload?.beforeImg}?${uniqueParam}`
                                                                    }
                                                                ></img>
                                                            )}
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    padding: "20px",
                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                <FileUploader
                                                                    handleChange={readFileBefore}
                                                                    onDrop={readFileBefore}
                                                                    disabled={loadingBefore ? true : false}
                                                                    name="filepetbefore"
                                                                    types={fileTypes}
                                                                    multiple={false}
                                                                    label=""
                                                                    children={
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                            }}
                                                                        >
                                                                            <FileUploadOutlined
                                                                                style={{
                                                                                    color: "#2076FF",
                                                                                }}
                                                                            />
                                                                            <p className={styles.uploadText}>Upload Image</p>
                                                                        </div>
                                                                    }
                                                                />
                                                                <Button color="primary" variant="contained" onClick={() => uploadBeforeAfter("before")}>
                                                                    Upload Before Image
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {tabIndex === 1 && (
                                                        <div
                                                            style={{
                                                                backgroundColor: "#F3F4F6",
                                                                borderRadius: "8px",
                                                                height: "200px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "20px",
                                                            }}
                                                        >
                                                            {beforeAfterPayload?.afterImg === "" ? (
                                                                <ImageOutlined style={{ color: "#FFFF", fontSize: "100px" }} />
                                                            ) : (
                                                                <img
                                                                    style={{ height: "200px", width: "200px", objectFit: "cover", borderRadius: "8px" }}
                                                                    src={
                                                                        fileSelectedAfter
                                                                            ? URL.createObjectURL(importedDataAfter)
                                                                            : `${process.env.REACT_APP_MEDIA_URL}app_before_after_images/${beforeAfterPayload?.afterImg}?${uniqueParam}`
                                                                    }
                                                                ></img>
                                                            )}
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    padding: "20px",
                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                <FileUploader
                                                                    handleChange={readFileAfter}
                                                                    onDrop={readFileAfter}
                                                                    disabled={loadingAfter ? true : false}
                                                                    name="filepetafter"
                                                                    types={fileTypes}
                                                                    multiple={false}
                                                                    label=""
                                                                    children={
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                            }}
                                                                        >
                                                                            <FileUploadOutlined
                                                                                style={{
                                                                                    color: "#2076FF",
                                                                                }}
                                                                            />
                                                                            <p className={styles.uploadText}>Upload Image</p>
                                                                        </div>
                                                                    }
                                                                />
                                                                <Button color="primary" variant="contained" onClick={() => uploadBeforeAfter("after")}>
                                                                    Upload After Image
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Box>
                                            </Box>
                                            {Object.keys(summaryDetails).length !== 0 ? (
                                                summaryDetails?.IsReadyForPickUp === false ? (
                                                    <></>
                                                ) : // <div style={{ display: "flex", gap: "10px", width: "100%", justifyContent: "space-between" }}>
                                                //     <Button color="default" variant="outlined" text="Upload Images" iconLeft={<FileUploadOutlined style={{ color: "#2076FF" }} />} />
                                                //     <Button color="primary" variant="contained" text="Take Pictures" iconLeft={<CameraAltOutlined style={{ color: "#FFFF" }} />} />
                                                // </div>
                                                null
                                            ) : null}
                                        </div>

                                        <div style={{ width: "100%", backgroundColor: "#EFF3FA", border: "1px solid #EFF3FA", borderRadius: "8px", padding: "10px" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #d6d6d7", padding: "0 10px 10px 10px" }}>
                                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>
                                                    Booking Details - ID #{summaryDetails?.numAppointmentID}
                                                </p>
                                            </div>

                                            <div>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px", width: "100%" }}>
                                                        <div>
                                                            <p style={{ fontWeight: "500", fontSize: "14px", color: "#192252" }}>Main service</p>
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "10px", overflowY: "scroll", width: "100%" }}>
                                                                {Object.keys(summaryDetails).length !== 0
                                                                    ? summaryDetails?.Services.map((serv, ind) => {
                                                                          if (serv?.bitIsAdditional === false) {
                                                                              return (
                                                                                  <div
                                                                                      style={{
                                                                                          display: "flex",
                                                                                          justifyContent: "space-between",
                                                                                          backgroundColor: "#FFFF",
                                                                                          borderRadius: "8px",
                                                                                          padding: "10px",
                                                                                          alignItems: "center",
                                                                                      }}
                                                                                  >
                                                                                      <div>
                                                                                          <p style={{ fontWeight: "400", fontSize: "14px", color: "#424F7B" }}>{serv?.varServiceItemName}</p>
                                                                                      </div>
                                                                                      <div style={{ display: "flex", gap: "10px" }}>
                                                                                          {startGrooming ? (
                                                                                              <Checkbox
                                                                                                  checked={serv?.toggleStatus}
                                                                                                  onChange={() => updateToggleStatus(serv?.numChargeID, ind)}
                                                                                              ></Checkbox>
                                                                                          ) : summaryDetails?.IsReadyForPickUp === true ? (
                                                                                              <Checkbox checked={serv?.IsTimerStarted} disabled={true}></Checkbox>
                                                                                          ) : null}
                                                                                      </div>
                                                                                  </div>
                                                                              )
                                                                          }
                                                                      })
                                                                    : null}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p style={{ fontWeight: "500", fontSize: "14px", color: "#192252" }}>Additional services</p>
                                                            <div style={{ display: "flex", flexDirection: "column", gap: "10px", overflowY: "scroll", width: "100%" }}>
                                                                {Object.keys(summaryDetails).length !== 0
                                                                    ? summaryDetails?.Services.map((serv, ind) => {
                                                                          if (serv?.bitIsAdditional === true) {
                                                                              return (
                                                                                  <div
                                                                                      style={{
                                                                                          display: "flex",
                                                                                          justifyContent: "space-between",
                                                                                          backgroundColor: "#FFFF",
                                                                                          borderRadius: "8px",
                                                                                          padding: "10px",
                                                                                          alignItems: "center",
                                                                                      }}
                                                                                  >
                                                                                      <div>
                                                                                          <p style={{ fontWeight: "400", fontSize: "14px", color: "#424F7B" }}>{serv?.varServiceItemName}</p>
                                                                                      </div>
                                                                                      <div style={{ display: "flex", gap: "10px" }}>
                                                                                          {startGrooming ? (
                                                                                              <Checkbox
                                                                                                  checked={serv?.toggleStatus}
                                                                                                  onChange={() => updateToggleStatus(serv?.numChargeID, ind)}
                                                                                              ></Checkbox>
                                                                                          ) : summaryDetails?.IsReadyForPickUp === true ? (
                                                                                              <Checkbox checked={serv?.IsTimerStarted} disabled={true}></Checkbox>
                                                                                          ) : null}
                                                                                      </div>
                                                                                  </div>
                                                                              )
                                                                          }
                                                                      })
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ borderTop: "1px solid #DFE8F6", width: "100%" }}>
                                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", flexDirection: "column" }}>
                                                            <div>
                                                                <p style={{ fontWeight: "500", fontSize: "14px", color: "#424F7B" }}>Total estimated time</p>
                                                                <p style={{ fontWeight: "500", fontSize: "14px", color: "#424F7B" }}>60 minutes</p>
                                                            </div>

                                                            {Object.keys(summaryDetails).length !== 0 ? (
                                                                summaryDetails?.IsReadyForPickUp === true ? (
                                                                    <>
                                                                        <p style={{ fontWeight: "500", fontSize: "14px", color: "#424F7B" }}>Total time taken to complete</p>
                                                                        <p style={{ fontWeight: "500", fontSize: "14px", color: "#424F7B" }}>
                                                                            {Object.keys(summaryDetails).length !== 0
                                                                                ? `${Math.round(getMinutesDuration(summaryDetails?.dtUpdatedStartTime, summaryDetails?.dtUpdatedEndTime))} min`
                                                                                : "-"}
                                                                        </p>
                                                                    </>
                                                                ) : null
                                                            ) : null}
                                                        </div>
                                                        <div style={{ display: "flex", gap: "10px", flexDirection: "column", padding: "10px" }}>
                                                            {Object.keys(summaryDetails).length !== 0 ? (
                                                                summaryDetails?.IsReadyForPickUp === false ? (
                                                                    summaryDetails?.toggleMainStatus ? (
                                                                        <>
                                                                            <MUIButton
                                                                                onClick={() => CompleteGroomSubmit()} //updateToggleMainStatus()
                                                                                style={{
                                                                                    borderRadius: 4,
                                                                                    backgroundColor: "#ef4444",
                                                                                    fontSize: "14px",
                                                                                    padding: "15px",
                                                                                }}
                                                                                variant="contained"
                                                                                disabled={isLoading} // Disable button when loading
                                                                                startIcon={isLoading && <CircularProgress size={20} />}
                                                                            >
                                                                                Finish Grooming
                                                                            </MUIButton>
                                                                            <MUIButton
                                                                                style={{
                                                                                    borderRadius: 4,
                                                                                    backgroundColor: "#EFF3FA",
                                                                                    fontSize: "14px",
                                                                                    color: "#606D93",
                                                                                    border: "1px solid #606D93",
                                                                                    padding: "15px",
                                                                                }}
                                                                                variant="outlined"
                                                                                disabled={isLoading} // Disable button when loading
                                                                                startIcon={isLoading && <CircularProgress size={20} />}
                                                                            >
                                                                                Service Delayed
                                                                            </MUIButton>
                                                                        </>
                                                                    ) : (
                                                                        <MUIButton
                                                                            onClick={() => updateToggleMainStatus()}
                                                                            style={{
                                                                                borderRadius: 4,
                                                                                backgroundColor: "#22C55E",
                                                                                fontSize: "14px",
                                                                                padding: "15px",
                                                                            }}
                                                                            variant="contained"
                                                                            disabled={isLoading} // Disable button when loading
                                                                            startIcon={isLoading && <CircularProgress size={20} />}
                                                                        >
                                                                            Start Grooming
                                                                        </MUIButton>
                                                                    )
                                                                ) : null
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", gap: "15px", width: "100%", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%", backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                                            <div style={{ padding: "0 10px 10px 10px", borderBottom: "1px solid #d6d6d7", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>Notes</p>
                                                <Button
                                                    color="info"
                                                    variant="text"
                                                    onClick={() => {
                                                        setIsOpenGroomerNote(true)
                                                    }}
                                                >
                                                    Add New
                                                </Button>
                                                <Button
                                                    color="info"
                                                    variant="text"
                                                    onClick={() => {
                                                        setIsOpenAddGroomerNoteShow(true)
                                                    }}
                                                >
                                                    Groomer Note Templates
                                                </Button>
                                            </div>

                                            {Object.keys(summaryDetails).length !== 0 ? (
                                                <AddGroomerNote
                                                    bookingDetails={summaryDetails}
                                                    isOpenAddGroomerNoteModal={isOpenAddGroomerNoteShow}
                                                    setIsOpenAddGroomerNoteModal={(value) => setIsOpenAddGroomerNoteShow(value)}
                                                    type={"add"}
                                                    isSuccess={(e) => {
                                                        if (e === true) {
                                                            getAppointmentGroomerNotes(summaryDetails.numAppointmentID)
                                                        }
                                                    }}
                                                />
                                            ) : null}

                                            {Object.keys(summaryDetails).length !== 0 ? (
                                                <GroomerNote
                                                    bookingDetails={summaryDetails}
                                                    isOpenGroomerNoteModal={isOpenGroomerNote}
                                                    setIsOpenGroomerNoteModal={(value) => setIsOpenGroomerNote(value)}
                                                    type={"add"}
                                                    isSuccess={(e) => {
                                                        if (e === true) {
                                                            getAppointmentGroomerNotes(summaryDetails.numAppointmentID)
                                                        }
                                                    }}
                                                />
                                            ) : null}

                                            {Object.keys(summaryDetails).length !== 0 ? (
                                                <GroomerNote
                                                    bookingDetails={summaryDetails}
                                                    isOpenGroomerNoteModal={isOpenGroomerNoteShow}
                                                    setIsOpenGroomerNoteModal={(value) => setIsOpenGroomerNoteShow(value)}
                                                    type={"show"}
                                                    NoteDetails={groomerNote}
                                                    isSuccess={() => {}}
                                                />
                                            ) : null}

                                            <div>
                                                <Box>
                                                    <Box>
                                                        <Tabs value={noteTabIndex} onChange={handleNoteTabChange}>
                                                            <Tab label="Grooming" />
                                                            <Tab label="General" />
                                                        </Tabs>
                                                    </Box>
                                                    <Box sx={{ paddingTop: 1 }}>
                                                        {noteTabIndex === 0 && (
                                                            <>
                                                                <p style={{ fontWeight: "600", fontSize: "14px" }}>Groomer Notes</p>
                                                                <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "200px", overflowY: "scroll" }}>
                                                                    {summaryDetails?.GroomerNotes !== undefined ? (
                                                                        summaryDetails?.GroomerNotes.length > 0 ? (
                                                                            summaryDetails?.GroomerNotes.map((note, index) => {
                                                                                if (note.numGroomerNoteID !== 0) {
                                                                                    return (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                backgroundColor: "#F9FAFB",
                                                                                                border: "1px solid #EFF3FA",
                                                                                                borderRadius: "8px",
                                                                                                padding: "10px",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                <p style={{ fontWeight: "600", fontSize: "14px", color: "#192252" }}>{shortDate(note.dtCreatedDate)}</p>
                                                                                                <p style={{ fontWeight: "500", fontSize: "14px", color: "#606D93" }}>{note.varGroomerComment}</p>
                                                                                                <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>
                                                                                                    {note.varCreatedEmployeeFirstName} {note.varCreatedEmployeeLastName}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div>
                                                                                                <Button
                                                                                                    color="transparent"
                                                                                                    variant="text"
                                                                                                    onClick={() => {
                                                                                                        openGroomerNote(index)
                                                                                                    }}
                                                                                                >
                                                                                                    <OpenInNewOutlined style={{ color: "#2076FF", fontSize: "30px" }} />
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                } else {
                                                                                    return <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>No groomer notes found</p>
                                                                                }
                                                                            })
                                                                        ) : (
                                                                            <p style={{ fontWeight: "500", fontSize: "12px", color: "#606D93" }}>No groomer notes found</p>
                                                                        )
                                                                    ) : null}
                                                                </div>
                                                            </>
                                                        )}
                                                        {noteTabIndex === 1 && <div style={{ border: "1px solid #EFF3FA", borderRadius: "8px" }}></div>}
                                                    </Box>
                                                </Box>
                                            </div>
                                        </div>

                                        <div style={{ width: "100%", backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                                            <div style={{ padding: "0 10px 10px 10px", borderBottom: "1px solid #d6d6d7", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>Pet Owner Details</p>
                                            </div>

                                            <div style={{ padding: "15px 10px 20px 10px" }}>
                                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                    <Avatar
                                                        isVip={false}
                                                        img={`${process.env.REACT_APP_MEDIA_URL}directories/customers/${
                                                            summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerImagePath : null
                                                        }`}
                                                        className={"Medium"}
                                                    />
                                                    <div>
                                                        <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                            {summaryDetails.Customer != undefined
                                                                ? summaryDetails.Customer.varCustomerFirstName + " " + summaryDetails.Customer.varCustomerLastName
                                                                : null}
                                                        </p>
                                                    </div>
                                                </div>
                                                <Grid container spacing={1} style={{ marginTop: "1px" }}>
                                                    <Grid item xs={4}>
                                                        <p style={{ color: "#848FAC", fontSize: "14px" }}>Contact #</p>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p style={{ color: "#192252", fontSize: "14px" }}>
                                                            {summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerMobilePhone : null} (Mobile)
                                                        </p>
                                                        <p style={{ color: "#192252", fontSize: "14px" }}>
                                                            {summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerWorkPhone : null} (Work)
                                                        </p>
                                                        <p style={{ color: "#192252", fontSize: "14px" }}>
                                                            {summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerHomePhone : null} (Home)
                                                        </p>
                                                        {/* <p style={{color:"#192252"}}>516-333-5897 (Work)</p> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={1} style={{ marginTop: "1px" }}>
                                                    <Grid item xs={4}>
                                                        <p style={{ color: "#848FAC", fontSize: "14px" }}>Email</p>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p style={{ color: "#192252", fontSize: "14px" }}>{summaryDetails.Customer != undefined ? summaryDetails.Customer.varCustomerEmail : null}</p>
                                                    </Grid>
                                                </Grid>

                                                {/* <Grid container spacing={1} style={{marginTop:"5px"}}>
                                <Grid item xs={4}>
                                    <p style={{color:"#848FAC", fontSize: "14px"}}>Warning</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <Chip size='small' customBackgroundColor={"#EB1A1A"} customBorderRadius={"12px"} fontColor={"#FFFF"} label={"Outstanding Bill"} />
                                </Grid>
                            </Grid> */}
                                            </div>

                                            {/* <div style={{ padding: "20px", backgroundColor: "#EFF3FA", borderRadius: "8px" }}>
                                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", margin: 0 }}>Assigned staff</p>

                                                <div style={{ display: "flex", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                    <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Groomer</p>

                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                        <Avatar
                                                            isVip={false}
                                                            img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                summaryDetails.Employee != undefined ? summaryDetails.Employee.varEmployeeImagePath : null
                                                            }`}
                                                            className={"Medium"}
                                                        />
                                                        <div>
                                                            <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                {summaryDetails.Employee != undefined
                                                                    ? summaryDetails.Employee.varEmployeeFirstName + " " + summaryDetails.Employee.varEmployeeLastName
                                                                    : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <Box
                                                sx={{
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <div style={{ padding: "20px", backgroundColor: "#EFF3FA", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                                                    <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", margin: 0 }}>Assigned staff</p>
                                                    {summaryDetails?.Employee?.EmployeeID === summaryDetails?.Employee?.DealerID ? (
                                                        <div>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                                <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Groomer</p>

                                                                <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                                    <Avatar
                                                                        isVip={false}
                                                                        img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                            summaryDetails.Employee != undefined ? summaryDetails.Employee.varEmployeeImagePath : null
                                                                        }`}
                                                                        className={"Medium"}
                                                                    />
                                                                    <div>
                                                                        <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                            {summaryDetails.Employee != undefined
                                                                                ? summaryDetails?.Employee?.varEmployeeFirstName + " " + summaryDetails.Employee.varEmployeeLastName
                                                                                : null}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                                    <p style={{ fontSize: "13px", fontWeight: "500" }}>
                                                                        Start : {formatDateDisplay(summaryDetails?.Employee?.DealerStartTime)} - End:{" "}
                                                                        {formatDateDisplay(summaryDetails?.Employee?.EmployeeEndTime)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                                <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Bather</p>

                                                                <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                                    <Avatar
                                                                        isVip={false}
                                                                        img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                            summaryDetails.Employee != undefined ? summaryDetails.Employee.DealerImagePath : null
                                                                        }`}
                                                                        className={"Medium"}
                                                                    />
                                                                    <div>
                                                                        <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                            {summaryDetails.Employee != undefined
                                                                                ? summaryDetails?.Employee?.varBatherFirstName + " " + summaryDetails.Employee.varBatherLastName
                                                                                : null}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                                    <p style={{ fontSize: "13px", fontWeight: "500" }}>
                                                                        Start : {formatDateDisplay(summaryDetails?.Employee?.DealerStartTime)} - End:{" "}
                                                                        {formatDateDisplay(summaryDetails?.Employee?.DealerEndTime)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "20px", padding: "15px 0 0 0" }}>
                                                                <p style={{ color: "#6B7280", fontSize: "14px", margin: "0" }}>Groomer</p>

                                                                <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                                    <Avatar
                                                                        isVip={false}
                                                                        img={`${process.env.REACT_APP_MEDIA_URL}directories/employees/${
                                                                            summaryDetails.Employee != undefined ? summaryDetails.Employee.varEmployeeImagePath : null
                                                                        }`}
                                                                        className={"Medium"}
                                                                    />
                                                                    <div>
                                                                        <p style={{ color: "#192252", fontWeight: "600", fontSize: "14px" }}>
                                                                            {summaryDetails.Employee != undefined
                                                                                ? summaryDetails?.Employee?.varEmployeeFirstName + " " + summaryDetails.Employee.varEmployeeLastName
                                                                                : null}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", gap: "10px", alignItems: "center", backgroundColor: "#fff", padding: "10px", borderRadius: "8px" }}>
                                                                    <p style={{ fontSize: "13px", fontWeight: "500" }}>
                                                                        Start : {formatDateDisplay(summaryDetails?.Employee?.EmployeeStartTime)} - End:{" "}
                                                                        {formatDateDisplay(summaryDetails?.Employee?.EmployeeEndTime)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Box>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", gap: "15px", width: "100%", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%", backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                                            <div style={{ padding: "0 10px 10px 10px", borderBottom: "1px solid #d6d6d7", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>Wellness Evaluation</p>
                                            </div>

                                            <div>
                                                <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                        <div>
                                                            <p style={{ fontWeight: "500", fontSize: "14px", color: "#848FAC" }}>Date</p>
                                                            <p style={{ fontWeight: "400", fontSize: "14px", color: "#192252" }}>SEP 15, 2022</p>
                                                        </div>
                                                        <div>
                                                            <p style={{ fontWeight: "500", fontSize: "14px", color: "#848FAC" }}>Evaluator</p>
                                                            <p style={{ fontWeight: "600", fontSize: "14px", color: "#2076FF" }}>Kristine Jo</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button color="info" variant="text" text="View Report" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ width: "100%", backgroundColor: "#FFFF", border: "1px solid #F3F4F6", borderRadius: "8px", padding: "10px" }}>
                                            <div style={{ padding: "0 10px 10px 10px", borderBottom: "1px solid #d6d6d7", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <p style={{ fontWeight: "600", fontSize: "16px", color: "#192252", padding: "10px 0", margin: 0 }}>Messaging</p>
                                            </div>

                                            <div style={{ padding: "15px 10px 20px 10px" }}>
                                                <div
                                                    style={{
                                                        border: "1px solid #DDD",
                                                        padding: "10px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        height: "300px",
                                                        overflowY: "scroll",
                                                        marginBottom: "20px",
                                                    }}
                                                >
                                                    {messages.map((message) => {
                                                        return message.transferTypeID === 1 ? (
                                                            <div className={styles.from}>
                                                                <p style={{ fontSize: "14px" }}>{message.message}</p>
                                                                <span>{messagingDate(message.logDateTime)}</span>
                                                            </div>
                                                        ) : (
                                                            <div className={styles.to}>
                                                                <p style={{ fontSize: "14px" }}>{message.message}</p>
                                                                <span>{messagingDate(message.logDateTime)}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                    <p>Microphone: {listening ? "on" : "off"}</p>
                                                    <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={SpeechRecognition.startListening}>
                                                        Start
                                                    </button>
                                                    <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={SpeechRecognition.stopListening}>
                                                        Stop
                                                    </button>
                                                    <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={resetTranscript}>
                                                        Reset
                                                    </button>
                                                </div>

                                                <div style={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "space-between" }}>
                                                    <TextField
                                                        label="New Message"
                                                        placeholder="Type Message"
                                                        style={{ width: "100%", margin: "10px 0" }}
                                                        onChange={(e) => {
                                                            setMsgBody(e.target.value)
                                                        }}
                                                        value={msgBody}
                                                        variant={"outlined"}
                                                    />
                                                    <Button color="primary" variant="contained" text="Send" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h1 style={{ margin: "20px 0 15px 20px ", padding: "0", fontSize: "24px" }}>Working - Grooming</h1>
                    <div style={{ flex: 1, overflowY: "scroll", margin: "0 0 20px 0", scrollbarGutter: "stable both-ends" }}>
                        <p style={{ fontWeight: "600", fontSize: "18px", textAlign: "center" }}>You have no ongoing appointments!</p>
                    </div>
                </>
            )}
        </>
    )
}

export default WorkingTab
