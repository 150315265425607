import { Add } from '@mui/icons-material';
import { Button as MaterialButton, Skeleton } from '@mui/material';
import { Button } from '../../../../components/Button/Button';
import styles from '../../../../pages/Directories/Directory.module.scss';
import tableStyles from '../../../../components/Table/Table.module.scss';
import { useEffect } from 'react';


const Loading = (props: {
    rowHeight?: number;
    activateGrid?;
    newAction?;
    objectName?;
    downloadPDF?;
    hideSort?;
    headerGroups?;
    isGrid;
}) => {

useEffect(() => {
    console.log(props.objectName)
}, [])
    return  <>
        <div className={tableStyles.headerContainer}>            
            <div className={tableStyles.actionContainer}>
                <div className={tableStyles.optionsContainer}>
                    {
                        props?.newAction ? 
                        <Button
                            color="default"
                            variant='outlined' 
                        >
                            <Add style={{fontSize: '20px'}}/>
                            Add New {props?.objectName} 
                        </Button> : null
                    }
                
                    {
                        props?.activateGrid ?
                        <div className={tableStyles.toggleContainer}>

                            {
                                (props.objectName === "Salons Directory") ?
                                    <Skeleton                                                     
                                        style={{ borderRadius: '3px', backgroundColor: '#005df112' }} 
                                        animation="wave" 
                                        variant="rectangular" 
                                        width={'82px'} 
                                        height={36.5} 
                                    />  
                                : null
                            }                        

                            <Skeleton                                                     
                                style={{ borderRadius: '3px', backgroundColor: '#005df112' }} 
                                animation="wave" 
                                variant="rectangular" 
                                width={'82px'} 
                                height={36.5} 
                            />  

                            <Skeleton                                                     
                                style={{ borderRadius: '3px', backgroundColor: '#005df112' }} 
                                animation="wave" 
                                variant="rectangular" 
                                width={'82px'} 
                                height={36.5} 
                            />  

                        </div> 
                        : null
                    }           
            
                        <Skeleton                                                     
                            style={{ borderRadius: '3px', backgroundColor: '#005df112' }} 
                            animation="wave" 
                            variant="rectangular" 
                            width={'145px'} 
                            height={36.5} 
                        />  
                    {
                        (props?.downloadPDF) ? 
                            <Skeleton                                                     
                                style={{ borderRadius: '3px', backgroundColor: '#005df112' }} 
                                animation="wave" 
                                variant="rectangular" 
                                width={'135px'} 
                                height={36.5} 
                            />  
                        : null
                    }
                    
                </div>

                <div className={tableStyles.filtersContainer}>
                    <div>                                
                        <Skeleton                                                     
                            style={{ borderRadius: '3px', backgroundColor: '#ababab12' }} 
                            animation="wave" 
                            variant="rectangular" 
                            width={'200px'} 
                            height={20} 
                        />  
                    </div>
                    <div className={tableStyles.searchContainer}>        
                        <Skeleton                                                     
                            style={{ borderRadius: '3px', backgroundColor: '#005df112' }} 
                            animation="wave" 
                            variant="rectangular" 
                            width={'257px'} 
                            height={42.4} 
                        />  
                        {
                            props?.isGrid ? null
                            :   <Skeleton                                                     
                                    style={{ borderRadius: '3px', backgroundColor: '#005df112' }} 
                                    animation="wave" 
                                    variant="rectangular" 
                                    width={'64px'} 
                                    height={42.4} 
                                />  
                        }                                
                    </div>   
                </div>                                                                     
            </div>
        </div>  

        <table className={`${tableStyles.table} ${props?.hideSort ? tableStyles.hideSort : null}`}>
            <thead>
                {props?.headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                            <th>
                                <div style={{display: 'flex'}}>                                   
                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                        {column.render('Header')}                                                                          
                                    </div>                                            
                                </div>                                                                                                                                                                                                                                                                                            
                            </th>                        
                        ))}
                    </tr>
                ))}
            </thead>                    

            <tbody>
                {
                    [0,0,0,0,0,0,0,0,0,0].map(rend => {                                
                        return <tr>
                            <td className={styles.fullWidthRow} colSpan={10}>
                                <Skeleton                                                  
                                    style={{ borderRadius: 'px', backgroundColor: '#ababab12', padding: '0px 0' }} 
                                    animation="wave" 
                                    variant="rectangular" 
                                    width={'100%'} 
                                    height={props?.rowHeight} 
                                />      
                            </td>
                        
                        </tr>                                                                    
                    })
                }                                               
            </tbody>
        </table> 
    </>
   
}

export default Loading;