import { useEffect, useMemo, useRef, useState } from "react"
import { Modal, Profile, SearchBox, Table } from "../../../components/components"
import styles from "../Directory.module.scss"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Employee } from "../../../components/Table/templates/Directory/Employee"
import { Checkbox, InputAdornment, Slider, TextField } from "@mui/material"
import { axiosPrivate } from "../../../apis/axios"
import { useTable, usePagination, useAsyncDebounce, useGlobalFilter, useFilters, useSortBy } from "react-table"

import {
    Add,
    AppsOutlined,
    FormatListBulletedOutlined,
    ArrowRightAlt,
    ChevronLeft,
    ChevronRight,
    FilterAltOutlined,
    SortByAlpha,
    LocationOnOutlined,
    Search,
    FileUploadOutlined,
} from "@mui/icons-material"
import { Button, Text, Input } from "../../../components/components"
import { downloadWithDataDirectories } from "../../../utils/ExcelHandler"
import { PDFHandler } from "../../../utils/PDFHandler"
import tableStyles from "../../../components/Table/Table.module.scss"
import GridLayout from "../../../components/Table/components/GridLayout/GridLayout"
import Loading from "../../../components/Table/components/Loading/Loading"
import AdvancedDropDown from "../../../components/AdvancedDropDown/AdvancedDropDown"
import loadingImage from "../../../images/loading.gif"
import { checkPermission } from "../../../utils/permissions"
import { Avatar, CloseButton } from "../../../components"
import { Cropper } from "react-cropper"
import { FileUploader } from "react-drag-drop-files"
import { AdvancedInput } from "../../../components/AdvancedInput/AdvancedInput"
import NewEmployee from "./NewEmployee"
import ValidatePermission from "../../../components/ValidatePermission/ValidatePermission"

const StaffDirectory = (props: {}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [reset, setReset] = useState(false)

    const [viewProfile, setViewProfile] = useState(false)
    const [selectedStaffID, setSelectedStaffID] = useState("")

    const [data, setData] = useState<any>([])
    const [dataUnfiltered, setDataUnfiltered] = useState<any>([])
    const [searchVal, setSearchVal] = useState("")
    const [location, setLocation] = useState("")
    const [occupationData, setOccupationData] = useState<any>([])
    const [occupationSelected, setOccupationSelected] = useState<any>([])
    const [specializationData, setSpecializationData] = useState<any>([])
    const [specializationSelected, setSpecializationSelected] = useState<any>([])
    const [ExperienceValue, setExperienceValue] = useState<number[]>([0, 100])
    const [AgeValue, setAgeValue] = useState<number[]>([0, 100])
    const [allLocations, setAllLocations] = useState<any>([])
    const myRef = useRef<any>()

    useEffect(() => {
        const controller = new AbortController()

        fetchData(controller.signal)

        return () => {
            controller.abort()
        }
    }, [])

    async function getDataOccupation(signal) {
        const result = await axiosPrivate.get(`Occupation/GetAll`, {
            signal: signal,
        })
        return result.data.occupation
    }
    async function getDataSpecialization(signal) {
        const result = await axiosPrivate.get(`Specialization/GetAll`, {
            signal: signal,
        })
        return result.data.specialization
    }
    async function getData(signal) {
        const result = await axiosPrivate.get(`Employee/GetAll`, {
            signal: signal,
        })
        return result.data.employee
    }
    async function getLocations(signal) {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, {
            signal: signal,
        })
        return result.data.location
    }

    const handleExperienceChange = (event: Event, newValue: number | number[]) => {
        setExperienceValue(newValue as number[])
    }
    const handleAgeChange = (event: Event, newValue: number | number[]) => {
        setAgeValue(newValue as number[])
    }

    let ExperienceRangeMin = +ExperienceValue.slice(0, 1)
    let ExperienceRangeMax = +ExperienceValue.slice(1)

    const fetchData = (signal) => {
        setIsLoading(true)

        getData(signal)
            .then((res) => {
                setData(res)
                setDataUnfiltered(res)
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                    setIsLoading(false)
                    //toast.error("Something went wrong!");
                }
            })

        getDataOccupation(signal)
            .then((res) => {
                let newArr = res.map((v) => ({ ...v, checked: false }))
                setOccupationData(newArr)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                    setIsLoading(false)
                    toast.error("Something went wrong!")
                }
            })

        getDataSpecialization(signal)
            .then((res) => {
                let newArr = res.map((v) => ({ ...v, checked: false }))
                setSpecializationData(newArr)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                    setIsLoading(false)
                    toast.error("Something went wrong!")
                }
            })
        getLocations(signal)
            .then((res) => {
                setAllLocations(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })
    }

    const resetFilters = () => {
        setReset(true)
        setData(dataUnfiltered)
        setLocation("")
        setAgeValue([0, 100])
        setExperienceValue([0, 100])
        setReset(false)
        setOccupationSelected([])
        setSpecializationSelected([])
        let occArr = occupationData.map((v) => ({ ...v, checked: false }))
        setOccupationData(occArr)
        let spArr = specializationData.map((v) => ({ ...v, checked: false }))
        setSpecializationData(spArr)
        myRef.current?.handleClear()
    }

    const validateString = (input) => {
        return input === "" ? null : input
    }

    async function getAdvancedData() {
        setIsLoading(true)
        let payload = {
            varLocation: validateString(location),
            occupation: occupationSelected.length < 1 ? [null] : occupationSelected,
            specialization: specializationSelected.length < 1 ? [null] : specializationSelected,
            numExpStRange: ExperienceRangeMin,
            numExpEndRange: ExperienceRangeMax,
            numAgeStRange: AgeValue.slice(0, 1)[0],
            numAgeEndRange: AgeValue.slice(1)[0],
            varFirstName: null,
            varLastName: null,
            numEmplooyeeID: null,
        }

        console.log("sent - payload", payload)

        const result = await axiosPrivate.post("Employee/AdvanceSearch", JSON.stringify(payload), {
            headers: { "Content-Type": "application/json" },
        })
        return result.data.employee
    }

    useEffect(() => {
        if (
            location == "" &&
            occupationSelected.length < 1 &&
            specializationSelected.length < 1 &&
            ExperienceRangeMin == 0 &&
            ExperienceRangeMax == 100 &&
            AgeValue.slice(0, 1)[0] == 0 &&
            AgeValue.slice(1)[0] == 100
        ) {
            setData(dataUnfiltered)
        } else {
            setViewProfile(false)
            const delayDebounceFn = setTimeout(() => {
                getAdvancedData()
                    .then((res) => {
                        console.log("res - payload", res)
                        setData(res)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        console.log("res - error", err)
                        setIsLoading(false)
                        setData([])
                        toast.error("Something went wrong!")
                    })
            }, 900)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [location, occupationSelected, specializationSelected, ExperienceValue, AgeValue])

    const viewStaffProfile = (id) => {
        setSelectedStaffID(id)
        setViewProfile(true)
    }

    const RenderTable = ({ columns, data, activateGrid, tableName, hiddenColumns, addNew, downloadPDF, newAction, loadingData, objectName, rowClicked, searchFilter, hideSort, pdfColumns }) => {
        const [isGrid, setIsGrid] = useState(false)
        const [showFiltering, setShowFiltering] = useState(false)
        const [pageList, setPageList] = useState([10, 20, 30, 40, 50])
        const [searchVal, setSearchVal] = useState("")
        const [filteredRows, setFilteredRows] = useState([])
        let exportData: any = []

        const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
            return (
                <input
                    className={tableStyles.searchFilter}
                    value={filterValue || ""}
                    onChange={(e) => {
                        setFilter(e.target.value || undefined)
                    }}
                    placeholder={`Search`}
                />
            )
        }

        const defaultColumn = useMemo(
            () => ({
                Filter: DefaultColumnFilter,
            }),
            []
        )

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            nextPage,
            rows,
            previousPage,
            setPageSize,
            setFilter,
            state: { pageIndex, pageSize },
            setGlobalFilter,
            setAllFilters,
        } = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: {
                    pageIndex: 0,
                    hiddenColumns: hiddenColumns,
                    pageSize: 10,
                },
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )

        useEffect(() => {
            if (isGrid) {
                setPageSize(9)
                setPageList([9, 18, 27, 36, 45])
            } else {
                setPageSize(10)
                setPageList([10, 20, 30, 40, 50])
            }
        }, [isGrid])

        useEffect(() => {
            if (!showFiltering) {
                setAllFilters([])
            }
        }, [showFiltering])

        useEffect(() => {
            filterGlobalData(searchVal)
        }, [searchVal])

        const filterData = useAsyncDebounce((column, filter) => {
            setFilter(column, filter)
        }, 200)

        useEffect(() => {
            setFilteredRows(rows)
        }, [rows])

        useEffect(() => {
            filterGlobalData(searchFilter)
        }, [searchFilter])

        const filterGlobalData = useAsyncDebounce((filter) => {
            setGlobalFilter(filter)
        }, 200)

        const [showNewEmployeeModal, setShowNewEmployeeModal] = useState(false)

        const appendForExport = () => {
            exportData = []
            let appended = new Promise((resolve, reject) => {
                filteredRows.map((row: any) => {
                    let obj = {}
                    Object.keys(row.original).map((header) => {
                        if (pdfColumns.includes(header.toString())) {
                            obj[header] = row.original[header]
                        }
                    })

                    exportData.push(obj)
                })
                resolve(true)
            })

            appended
                .then((result) => {
                    if (result) {
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        const exportExcel = () => {
            appendForExport()
            downloadWithDataDirectories(tableName, exportData)
        }

        const exportPDF = () => {
            appendForExport()
            PDFHandler(exportData, tableName)
        }

        return loadingData ? (
            <Loading
                isGrid={isGrid}
                activateGrid={activateGrid}
                downloadPDF={downloadPDF}
                headerGroups={headerGroups}
                hideSort={hideSort}
                newAction={newAction}
                objectName={objectName}
                rowHeight={50}
            />
        ) : (
            // <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
            //     <img alt="loading" src={loadingImage} />
            // </div>
            <>
                <div className={tableStyles.headerContainer}>
                    <div className={tableStyles.actionContainer}>
                        <div className={tableStyles.tableHeadingContain}>
                            <p style={{ fontSize: "22px", fontWeight: 600, color: "rgb(0, 40, 103)" }}>{objectName}</p>
                            <div className={tableStyles.optionsContainer}>
                                {newAction ? (
                                    <Button color="default" variant="outlined">
                                        <Add style={{ fontSize: "20px" }} />
                                        Add New {objectName}
                                    </Button>
                                ) : null}
                                {activateGrid ? (
                                    <div className={tableStyles.toggleContainer}>
                                        <Button
                                            color="primary"
                                            iconLeft={<AppsOutlined className={tableStyles.buttonIcon} />}
                                            variant="outlined"
                                            onClick={() => setIsGrid(true)}
                                            className={isGrid ? tableStyles.btnActive : tableStyles.btnToggle}
                                        >
                                            Grid
                                        </Button>

                                        <Button
                                            iconLeft={<FormatListBulletedOutlined className={tableStyles.buttonIcon} />}
                                            variant="outlined"
                                            onClick={() => setIsGrid(false)}
                                            className={!isGrid ? tableStyles.btnActive : tableStyles.btnToggle}
                                            color="primary"
                                        >
                                            List
                                        </Button>
                                    </div>
                                ) : null}
                                {
                                    <ValidatePermission allowedModules={["03.04 - Directory / Employee"]} allowedSubRoutes={["Download XLSX"]}>
                                        <Button color="default" variant="outlined" onClick={() => exportExcel()}>
                                            Download XLSX
                                        </Button>
                                    </ValidatePermission>
                                }
                                {downloadPDF ? (
                                    <ValidatePermission allowedModules={["03.04 - Directory / Employee"]} allowedSubRoutes={["Download PDF"]}>
                                        <Button color="default" variant="outlined" onClick={() => exportPDF()}>
                                            Download PDF
                                        </Button>
                                    </ValidatePermission>
                                ) : null}
                                {addNew ? (
                                    <ValidatePermission allowedModules={["03.04 - Directory / Employee"]} allowedSubRoutes={["New Employee"]}>
                                        <Button color="primary" variant="contained" onClick={() => setShowNewEmployeeModal(!showNewEmployeeModal)}>
                                            New Employee
                                        </Button>
                                    </ValidatePermission>
                                ) : null}
                                {showNewEmployeeModal ? <NewEmployee setShowNewEmployeeModal={(res) => setShowNewEmployeeModal(res)} isSuccess={(e)=>{
                                    if(e === true){
                                        setShowNewEmployeeModal(false)
                                    }
                                }} /> : null}
                            </div>
                        </div>

                        <div className={tableStyles.filtersContainer}>
                            <div>
                                <Text color="p_900" weight="fw_400">
                                    {`${rows.length} ${rows.length > 1 ? "results" : "result"}`} found
                                </Text>
                            </div>
                            <div className={tableStyles.searchContainer}>
                                <SearchBox onChange={(e) => setSearchVal(e)} value={searchVal} />
                                {isGrid ? null : (
                                    <Button color="primary" variant="contained" onClick={() => setShowFiltering(!showFiltering)}>
                                        <FilterAltOutlined />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {isGrid ? (
                    <GridLayout onSelect={(clickedCard) => rowClicked(clickedCard)} template={tableName} page={page} prepareRow={prepareRow} />
                ) : (
                    <table className={`${tableStyles.table} ${hideSort ? tableStyles.hideSort : null}`} {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    <td>
                                        <Checkbox
                                        // checked={selectAll}
                                        // onChange={() => setSelectAll(!selectAll)}
                                        />
                                    </td>
                                    {headerGroup.headers.map((column) => (
                                        <th>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                    {column.render("Header")}
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <SortByAlpha {...column.getHeaderProps(column.getSortByToggleProps())} style={{ height: "16px", cursor: "pointer", color: "#005df1" }} />
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <ArrowRightAlt className={tableStyles.sortDesc} />
                                                            ) : (
                                                                <ArrowRightAlt className={tableStyles.sortAsc} />
                                                            )
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {showFiltering ? <div style={{ padding: ".5rem 0" }}>{column.canFilter ? column.render("Filter") : null}</div> : null}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)

                                return (
                                    <tr className={tableStyles.tableRow} {...row.getRowProps()}>
                                        <td>
                                            <Checkbox
                                            // checked={}
                                            // onClick={() => }
                                            />
                                        </td>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <>
                                                    {row.cells.length === index + 1 ? (
                                                        <td onClick={() => rowClicked(row?.allCells[row?.allCells.length - 1]?.value)} className={tableStyles.tableData} {...cell.getCellProps()}>
                                                            {cell.render("Cell")}{" "}
                                                        </td>
                                                    ) : (
                                                        <td className={tableStyles.tableData} {...cell.getCellProps()}>
                                                            {cell.render("Cell")}
                                                        </td>
                                                    )}
                                                </>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}

                <div className={tableStyles.pagination}>
                    <div className={tableStyles.rowPages}>
                        <Text color="n_500" weight="fw_400">
                            Rows per page:
                        </Text>
                        <Input
                            items={pageList.map((pageSize) => ({
                                value: pageSize.toString(),
                                text: pageSize,
                            }))}
                            itemSize={12}
                            value={pageSize}
                            onChange={(pageRows) => {
                                setPageSize(Number(pageRows))
                            }}
                        />
                    </div>

                    <span>
                        <Text color="n_500" weight="fw_400">
                            Viewing Page: {pageIndex + 1} of {pageOptions.length}
                        </Text>
                    </span>

                    <div style={{ display: "flex", gap: ".5rem" }}>
                        <Button
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            className={tableStyles.paginationButton}
                            iconLeft={<ChevronLeft />}
                            color="pagination"
                            variant="contained"
                        ></Button>

                        <Button onClick={() => nextPage()} disabled={!canNextPage} className={tableStyles.paginationButton} iconLeft={<ChevronRight />} color="pagination" variant="contained"></Button>
                    </div>
                </div>
            </>
        )
    }

    const tableData = useMemo(() => data, [data])

    return (
        <div style={{ display: "flex" }}>
            <div style={{}}>
                <div className={styles.tabContainer}>
                    <>
                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                            <div>
                                <Search style={{ color: "#6B7280", height: "22px" }} />
                            </div>
                            <div style={{ color: "#6B7280", fontSize: "16px" }}>Search</div>
                        </div>
                        <p style={{ color: "#111827", fontWeight: "600" }}>Salon</p>
                        <div style={{ width: "237px" }}>
                            {allLocations !== undefined && allLocations !== null ? (
                                <AdvancedDropDown
                                    data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                    onChange={(e) => {
                                        setLocation(e?.label)
                                    }}
                                    ref={myRef}
                                    placeHolder={"Locations"}
                                />
                            ) : null}
                        </div>
                        <p style={{ color: "#111827", fontWeight: "600" }}>Job Title</p>
                        <div className={styles.centerCheckboxes}>
                            {occupationData.map((item, index) => {
                                return (
                                    <div key={item.numOccupationID} style={{ display: "flex" }}>
                                        <Checkbox
                                            id={item.varOccupationName}
                                            style={{ padding: "0 .2rem 0 0" }}
                                            checked={item.checked}
                                            onChange={(e) => {
                                                const triggerCheck = [...occupationData]
                                                triggerCheck[index].checked = !triggerCheck[index].checked
                                                setOccupationData(triggerCheck)

                                                if (e.target.checked == true) {
                                                    const occArr = [...occupationSelected]
                                                    occArr.push(e.target.id)
                                                    setOccupationSelected(occArr)
                                                } else {
                                                    const afterRemove = [...occupationSelected]
                                                    let index = occupationSelected.indexOf(e.target.id)
                                                    afterRemove.splice(index, 1)
                                                    setOccupationSelected(afterRemove)
                                                }
                                            }}
                                        />
                                        <p>{item.varOccupationName}</p>
                                    </div>
                                )
                            })}
                        </div>

                        <p style={{ color: "#111827", fontWeight: "600" }}>Employee Specialization</p>
                        <div className={styles.centerCheckboxes}>
                            {specializationData.map((item, index) => {
                                return (
                                    <div key={item.numSpecializationID} style={{ display: "flex" }}>
                                        <Checkbox
                                            id={item.varSpecializationName}
                                            style={{ padding: "0 .2rem 0 0" }}
                                            checked={item.checked}
                                            onChange={(e) => {
                                                const triggerCheck = [...specializationData]
                                                triggerCheck[index].checked = !triggerCheck[index].checked
                                                setSpecializationData(triggerCheck)

                                                if (e.target.checked == true) {
                                                    const occArr = [...specializationSelected]
                                                    occArr.push(e.target.id)
                                                    setSpecializationSelected(occArr)
                                                } else {
                                                    const afterRemove = [...specializationSelected]
                                                    let index = specializationSelected.indexOf(e.target.id)
                                                    afterRemove.splice(index, 1)
                                                    setSpecializationSelected(afterRemove)
                                                }
                                            }}
                                        />
                                        <p>{item.varSpecializationName}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ color: "#111827", fontWeight: "600" }}>Experience</p>
                                <p style={{ fontWeight: "500" }}>
                                    <span style={{ color: "#2076FF", fontSize: "14px", fontWeight: "600" }}>
                                        ({ExperienceRangeMin} - {ExperienceRangeMax})
                                    </span>{" "}
                                    Years
                                </p>
                            </div>
                            <div style={{ padding: ".5rem 1rem" }}>
                                <Slider getAriaLabel={() => "Experience"} value={ExperienceValue} onChange={handleExperienceChange} valueLabelDisplay="auto" />
                            </div>
                        </div>
                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ color: "#111827", fontWeight: "600" }}>Age</p>
                                <p style={{ fontWeight: "500" }}>
                                    <span style={{ color: "#2076FF", fontSize: "14px", fontWeight: "600" }}>
                                        ({AgeValue.slice(0, 1)} - {AgeValue.slice(1)})
                                    </span>{" "}
                                    Years
                                </p>
                            </div>
                            <div style={{ padding: ".5rem 1rem" }}>
                                <Slider getAriaLabel={() => "Age range"} value={AgeValue} onChange={handleAgeChange} valueLabelDisplay="auto" />
                            </div>
                        </div>
                        <Button className={styles.resetButton} variant="outlined" color="info" onClick={resetFilters} loading={isLoading}>
                            Reset Filters
                        </Button>
                    </>
                </div>
            </div>

            <div style={{ width: "100%", padding: "10px" }}>
                {viewProfile ? (
                    <Profile goBack={(value) => setViewProfile(value)} type={"staff"} id={selectedStaffID} />
                ) : (
                    <RenderTable
                        addNew={true}
                        downloadPDF={true}
                        tableName={"Employee"}
                        hiddenColumns={Employee?.hiddenColumns}
                        activateGrid={true}
                        columns={Employee?.template}
                        data={tableData}
                        loadingData={isLoading}
                        newAction={""}
                        objectName={"Staff Directory"}
                        rowClicked={(clickedId) => viewStaffProfile(clickedId)}
                        searchFilter={searchVal}
                        hideSort={Employee?.hideSort}
                        pdfColumns={Employee.pdfTemplate}
                    />
                )}
            </div>
        </div>
    )
}

export default StaffDirectory
