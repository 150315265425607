import { useEffect, useState, useMemo, useRef } from "react"
import { Modal, Profile, SearchBox } from "../../../components/components"
import styles from "../Directory.module.scss"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "../../../apis/axios"

import { useTable, usePagination, useAsyncDebounce, useGlobalFilter, useFilters, useSortBy } from "react-table"
import { Add, AppsOutlined, FormatListBulletedOutlined, ArrowRightAlt, ChevronLeft, ChevronRight, FilterAltOutlined, SortByAlpha, Search, LocationOnOutlined, Send, Message } from "@mui/icons-material"
import { Button, Text, Input } from "../../../components/components"

import { downloadWithDataDirectories } from "../../../utils/ExcelHandler"
import { PDFHandler } from "../../../utils/PDFHandler"

import tableStyles from "../../../components/Table/Table.module.scss"
import GridLayout from "../../../components/Table/components/GridLayout/GridLayout"

import { Customer } from "../../../components/Table/templates/Directory/Customer"
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Skeleton, TextField } from "@mui/material"
import { axiosPrivate } from "../../../apis/axios"
import Loading from "../../../components/Table/components/Loading/Loading"
import AdvancedDropDown from "../../../components/AdvancedDropDown/AdvancedDropDown"
import loadingImage from "../../../images/loading.gif"
import { checkPermission } from "../../../utils/permissions"
import CloseButton from "../../../components/Modal/CloseButton/CloseButton"

import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition"
import ValidatePermission from "../../../components/ValidatePermission/ValidatePermission"
import useAuth from "../../../hooks/useAuth"

const CustomerDirectory = (props: {}) => {
    const { auth } = useAuth() as any
    const [defaultLocation, setDefaultLocation] = useState([{ label: auth?.currentLocation?.label ,id: auth?.currentLocation?.value }]) as any

    const [isLoading, setIsLoading] = useState(false)
    const [selectedCustomerID, setSelectedCustomerID] = useState("")
    const [viewProfile, setViewProfile] = useState(false)
    const [data, setData] = useState<any>([])
    const [dataUnfiltered, setDataUnfiltered] = useState<any>([])
    const [zipVal, setZipVal] = useState("")
    const [searchVal, setSearchVal] = useState("")
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [petName, setPetName] = useState("")
    const [location, setLocation] = useState([{id:auth?.currentLocation?.value}]) as any
    const [activeStatus, setActiveStatus] = useState<any>(true)
    const [inactiveStatus, setInactiveStatus] = useState<any>(false)
    const [vipStatus, setVipStatus] = useState<any>("")
    const [petTypes, setPetTypes] = useState<any>([])
    const [petBreeds, setPetBreeds] = useState<any>([])
    const [petType, setPetType] = useState<any>("")
    const [petBreed, setPetBreed] = useState<any>("")
    const [allLocations, setAllLocations] = useState<any>([])
    const myRef = useRef<any>()
    const methodRef = useRef<any>()
    const breedRef = useRef<any>()
    const [parameterList, setParameterList] = useState([]) as any

    const [allIdList, setAllIdList] = useState([]) as any
    const [validateLocations, setValidateLocations] = useState(false)

    useEffect(() => {
        const controller = new AbortController()

        fetchData(controller.signal)

        return () => {
            controller.abort()
        }
    }, [])

    async function getParameters() {
        await axios
            .get(`${process.env.REACT_APP_TEXT_API_URL}Template/GetTemplatesByEntityName?EntityName=Customer`)
            .then((result) => {
                if (result.status === 200) {
                    console.log(result)
                    setParameterList(result?.data)
                }
            })
            .catch((err) => {
                console.log(err)
                // toast.error('Something went wrong!');
            })
    }

    async function getData(signal) {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Customer/GetAll`, {
            signal: signal,
        })
        return result.data.customer
    }

    async function getLocations(signal) {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, {
            signal: signal,
        })
        return result.data.location
    }

    async function getPetTypes(signal) {
        const result = await axiosPrivate.get(`PetType/GetAll`, {
            signal: signal,
        })
        return result.data.petType
    }

    async function getBreedByPetType(id) {
        const result = await axiosPrivate.get(`PetBreed/GetAllByPetTypeID/${id}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    const extractAllIds = (dataset) => {
        if (dataset != null) {
            let extractIdList = [...allIdList]

            dataset?.map((row) => {
                extractIdList.push(row.numCustomerID)
            })

            setAllIdList(extractIdList)
        }
    }

    const fetchData = (signal) => {
        // getData(signal)
        // .then(res => {
        //     // setData(res)
        //     // setDataUnfiltered(res)
        //     setIsLoading(false)
        //     getParameters()

        //     extractAllIds(res)
        //     // console.log('console data', res)
        // })
        // .catch(err => {
        //     if(err.message != 'canceled'){
        //         console.log(err)
        //         setIsLoading(false)
        //     }
        // })

        getParameters()

        getPetTypes(signal)
            .then((res) => {
                setPetTypes(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })

        getLocations(signal)
            .then((res) => {
                setAllLocations(res)
                })
                .catch((err) => {
                    if (err.message != "canceled") {
                        console.log(err)
                        }
                        })
                        }

    const getBreedData = (id) => {
        getBreedByPetType(id)
            .then((res) => {
                setPetBreeds(res?.petBreed)
            })
            .catch((err) => {
                console.log(err)
                //toast.error("Something went wrong!");
            })
    }

    const viewCustomerProfile = (id) => {
        setSelectedCustomerID(id)
        setViewProfile(true)
    }

    const validateString = (input) => {
        return input === "" ? null : input
    }

    const getBitActiveStatus = () => {
        if (activeStatus && inactiveStatus) return null;
        return activeStatus ? true : inactiveStatus ? false : null;
    };

    async function getAdvancedData() {
        setIsLoading(true)
        console.log("locationL", location)

        let payload = {
            typeLocationMessaging: location.length === 0 ? [{ id: 1 }] : location,
            varFirstName: validateString(fName),
            varLastName: validateString(lName),
            varCity: validateString(city),
            varState: validateString(state),
            varZip: validateString(zipVal),
            bitActiveStatus: getBitActiveStatus(),
            bitPriorityVIP: validateString(vipStatus) == null ? null : validateString(vipStatus) == "true" ? true : false,
            varPetName: validateString(petName),
            numPetTypeID: validateString(petType),
            varPetBreed: validateString(petBreed),
        }

        console.log("payload", payload)

        await axios
            .post(`${process.env.REACT_APP_API_URL}AdvancedSearch/GetSearchCustomerByLocationList`, JSON.stringify(payload), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log(result.data)

                    setData(result.data)
                    console.log("adv-response", result.data)
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                console.log(err)
                setIsLoading(false)
                setData([])
                //toast.error("Something went wrong!");
            })

        // const result = await axiosPrivate.post('Customer/AdvanceSearch', JSON.stringify(payload), {
        //     headers: { 'Content-Type': 'application/json' }
        // })

        // return result.data.customer;
        return []
    }

    const resetFilters = () => {
        setData(dataUnfiltered)
        setFName("")
        setLName("")
        setCity("")
        setPetName("")
        setPetBreed("")
        setZipVal("")
        setCity("")
        setState("")
        setActiveStatus(true)
        setInactiveStatus("")
        setVipStatus("")
        setPetType("")
        setLocation([{ id: auth?.currentLocation?.value }])
        setDefaultLocation([{ label: auth?.currentLocation?.label ,id: auth?.currentLocation?.value }])
        myRef.current?.handleClear()
        breedRef.current?.handleClear()
        setPetBreeds([])
        setPetType("")
    }

    useEffect(() => {
        // if(fName == "" && lName == "" && city == "" && state == "" && zipVal == "" && petName == "" && petBreed == "" && location.length < 1 && petType == "" && activeStatus == "" && vipStatus == ""){
        //     setData(dataUnfiltered)
        // }else{
        //     const delayDebounceFn = setTimeout(() => {
        //         if(fName != "" || lName != "" || city != "" || state != "" || zipVal != "" || petName != "" || petBreed != "" || location.length > 0 || petType != "" || activeStatus != "" || vipStatus != ""){
        //             getAdvancedData()
        //             // .then(res => {
        //             //     setData(res)
        //             //     console.log('adv-response', res)
        //             //     setIsLoading(false)

        //             // })
        //             // .catch(err => {
        //             //     console.log(err)
        //             //     setIsLoading(false)
        //             //     setData([])
        //             //     toast.error("Something went wrong!");
        //             // })
        //         }
        //     }, 900)
        //     return () => clearTimeout(delayDebounceFn)
        // }

        const delayDebounceFn = setTimeout(() => {
            getAdvancedData()
        }, 900)

        return () => clearTimeout(delayDebounceFn)
    }, [fName, lName, city, state, zipVal, petName, petBreed, location, petType, activeStatus, vipStatus, inactiveStatus])

    // const handleChangeActiveStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setActiveStatus((event.target as HTMLInputElement).value)
    // }

    const handleChangeActiveStatus = (event) => {
        setActiveStatus(event.target.checked);
    };
    const handleChangeInActiveStatus = (event) => {
        setInactiveStatus(event.target.checked);
    };

    const handleChangeVipStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVipStatus((event.target as HTMLInputElement).value)
    }

    const RenderTable = ({ columns, data, activateGrid, tableName, hiddenColumns, downloadPDF, newAction, loadingData, objectName, rowClicked, searchFilter, hideSort, pdfColumns }) => {
        /* MSG */
        const [checkedList, setCheckedList] = useState([]) as any
        const [selectAll, setSelectAll] = useState(false)

        const [sendTemplateType, setSendTemplateType] = useState(0)

        const checkIfSelected = (id) => {
            let checkedListTemp = [...checkedList]
            const index = checkedListTemp.indexOf(id)

            if (index > -1) {
                return true
            } else {
                return false
            }
        }

        const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition()

        const updateCheckedList = (id) => {
            let checkedListTemp = [...checkedList]
            const index = checkedListTemp.indexOf(id)

            if (index > -1) {
                checkedListTemp.splice(index, 1)
            } else {
                checkedListTemp.push(id)
            }

            setCheckedList(checkedListTemp)
        }

        useEffect(() => {
            if (selectAll) {
                let tempAllArr = [] as any

                filteredRows.map((row) => {
                    tempAllArr.push(parseInt(row?.original?.numCustomerID))
                })

                setCheckedList(tempAllArr)
            } else {
                setCheckedList([])
            }
        }, [selectAll])

        useEffect(() => {
            console.log(checkedList)
        }, [checkedList])

        const [isGrid, setIsGrid] = useState(false)
        const [openImportExportModal, setOpenImportExportModal] = useState(false)
        const [showFiltering, setShowFiltering] = useState(false)
        const [pageList, setPageList] = useState([10, 20, 30, 40, 50])
        const [searchVal, setSearchVal] = useState("")
        const [filteredRows, setFilteredRows] = useState<any[]>([])
        let exportData: any = []
        const [msgBody, setMsgBody] = useState("")
        const [msgBodySms, setMsgBodySms] = useState("")

        const [selectParameter, setSelectParameter] = useState("")
        const [selectMethod, setSelectMethod] = useState("")
        const [sendSubject, setSendSubject] = useState("")
        const [selectDate, setSelectDate] = useState(new Date().toLocaleDateString("en-CA"))

        const templateRef = useRef<any>()

        useEffect(() => {
            let tempMessage = msgBody
            tempMessage = tempMessage + transcript
            setMsgBody(tempMessage)
        }, [transcript])

        const [showMsgModal, setShowMsgModal] = useState(false)

        const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
            return (
                <input
                    className={tableStyles.searchFilter}
                    value={filterValue || ""}
                    onChange={(e) => {
                        setFilter(e.target.value || undefined)
                    }}
                    placeholder={`Search`}
                />
            )
        }

        async function sendMessagePost(data) {
            await axios
                .post(`${process.env.REACT_APP_TEXT_API_URL}UpdateMessages/UpdateMessagesPool`, JSON.stringify(data), {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        toast.success(`Messages Sent Successfully!`)

                        setMsgBody("")
                        setSendTemplateType(0)
                        setSendSubject("")
                        setSelectMethod("")
                        setSelectParameter("")
                    }
                })
                .catch((err) => {
                    console.log(err)
                    //toast.error('Something went wrong!');
                })
        }

        const quickMessage = () => {
            let finalMessageBody = buildMessage(msgBodySms, msgBody, checkedList)
            console.log(finalMessageBody)
            sendMessagePost(finalMessageBody)
        }

        const buildMessage = (smsTemplate, template, selectedList) => {
            let msgObject = {
                messageBody: [] as any,
            }

            // console.log(template, selectedList)

            filteredRows.map((row) => {
                if (selectedList.includes(row.original.numCustomerID)) {
                    const updatedMessage = template
                        .replace("{{Customer.CustomerFirstName}}", row.original.varCustomerFirstName)
                        .replace("{{Customer.CustomerLastName}}", row.original.varCustomerLastName)
                        .replace("{{Customer.CustomerCity}}", row.original.varCustomerCity)
                        .replace("{{Customer.CustomerEmail}}", row.original.varCustomerEmail)
                        .replace("{{Customer.CustomerMobilePhone}}", row.original.varCustomerMobilePhone)
                        .replace("{{Customer.CustomerAddress1}}", row.original.varCustomerAddress1)
                        .replace("{{Customer.CustomerAddress2}}", row.original.varCustomerAddress2)
                        .replace("{{Customer.CustomerState}}", row.original.varCustomerState)

                    const updatedMessageSMS = smsTemplate
                        .replace("{{Customer.CustomerFirstName}}", row.original.varCustomerFirstName)
                        .replace("{{Customer.CustomerLastName}}", row.original.varCustomerLastName)
                        .replace("{{Customer.CustomerCity}}", row.original.varCustomerCity)
                        .replace("{{Customer.CustomerEmail}}", row.original.varCustomerEmail)
                        .replace("{{Customer.CustomerMobilePhone}}", row.original.varCustomerMobilePhone)
                        .replace("{{Customer.CustomerAddress1}}", row.original.varCustomerAddress1)
                        .replace("{{Customer.CustomerAddress2}}", row.original.varCustomerAddress2)
                        .replace("{{Customer.CustomerState}}", row.original.varCustomerState)

                    msgObject.messageBody.push({
                        recipientID: row.original.numCustomerID,
                        recipientType: "C",
                        senderID: 1,
                        senderLocationID: 7,
                        subject: sendSubject,
                        smsBody: updatedMessageSMS,
                        emailBody: updatedMessage,
                        communicationMethod: selectMethod,
                        sender: "Admin",
                        senderLocation: "Bethpage",
                        contactNumber: row.original.varCustomerMobilePhone,
                        emailAddress: row.original.varCustomerEmail,
                        appointmentID: "0",
                        option: row.original.varCustomerEmail === null ? 1 : 2,
                        sendDate: selectDate,
                        messageType: "B",
                        WorkPhoneNumber: data[0].varCustomerWorkPhone,
                        HomePhoneNumber: data[0].varCustomerHomePhone,
                        bitCustomerNotificationEmail: data[0].bitCustomerNotificationEmail,
                        numCustomerNotificationEmailSequence: data[0].numCustomerNotificationEmailSequence,
                        bitCustomerNotificationHomePhone: data[0].bitCustomerNotificationHomePhone,
                        numCustomerNotificationHomePhoneSequence: data[0].numCustomerNotificationHomePhoneSequence,
                        bitCustomerNotificationMobilePhoneCall: data[0].bitCustomerNotificationMobilePhoneCall,
                        numCustomerNotificationMobilePhoneCallSequence: data[0].numCustomerNotificationMobilePhoneCallSequence,
                        bitCustomerNotificationMobilePhoneSMS: data[0].bitCustomerNotificationMobilePhoneSMS,
                        numCustomerNotificationMobilePhoneSMSSequence: data[0].numCustomerNotificationMobilePhoneSMSSequence,
                        bitCustomerNotificationWorkPhone: data[0].bitCustomerNotificationWorkPhone,
                        numCustomerNotificationWorkPhoneSequence: data[0].numCustomerNotificationWorkPhoneSequence,
                    })
                }
            })

            return msgObject
        }

        const defaultColumn = useMemo(
            () => ({
                Filter: DefaultColumnFilter,
            }),
            []
        )

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            nextPage,
            rows,
            previousPage,
            setPageSize,
            setFilter,
            state: { pageIndex, pageSize },
            setGlobalFilter,
            setAllFilters,
        } = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: {
                    pageIndex: 0,
                    hiddenColumns: hiddenColumns,
                    pageSize: 10,
                },
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )

        useEffect(() => {
            if (isGrid) {
                setPageSize(9)
                setPageList([9, 18, 27, 36, 45])
            } else {
                setPageSize(10)
                setPageList([10, 20, 30, 40, 50])
            }
        }, [isGrid])

        useEffect(() => {
            filterGlobalData(searchVal)
        }, [searchVal])

        useEffect(() => {
            setFilteredRows(rows)
        }, [rows])

        const filterData = useAsyncDebounce((column, filter) => {
            setFilter(column, filter)
        }, 200)

        useEffect(() => {
            filterGlobalData(searchFilter)
        }, [searchFilter])

        useEffect(() => {
            if (!showFiltering) {
                setAllFilters([])
            }
        }, [showFiltering])

        const filterGlobalData = useAsyncDebounce((filter) => {
            setGlobalFilter(filter)
        }, 200)

        const appendForExport = () => {
            exportData = []
            let appended = new Promise((resolve, reject) => {
                filteredRows.map((row: any) => {
                    let obj = {}
                    Object.keys(row.original).map((header) => {
                        if (pdfColumns.includes(header.toString())) {
                            obj[header] = row.original[header]
                        }
                    })

                    exportData.push(obj)
                })
                resolve(true)
            })

            appended
                .then((result) => {
                    if (result) {
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        const exportExcel = () => {
            appendForExport()
            downloadWithDataDirectories(tableName, exportData)
        }

        const exportPDF = () => {
            appendForExport()
            PDFHandler(exportData, tableName)
        }

        return loadingData ? (
            <Loading
                isGrid={isGrid}
                activateGrid={activateGrid}
                downloadPDF={downloadPDF}
                headerGroups={headerGroups}
                hideSort={hideSort}
                newAction={newAction}
                objectName={objectName}
                rowHeight={50}
            />
        ) : (
            // <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
            //     <img alt="loading" src={loadingImage} />
            // </div>
            <>
                {showMsgModal ? (
                    <Modal open={showMsgModal}>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", padding: "10px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>Quick Message</p>
                                <CloseButton onClick={() => setShowMsgModal(false)} />
                            </div>
                            <div style={{ backgroundColor: "#fff", width: "600px" }}>
                                <div style={{ display: "flex", gap: "15px", flexDirection: "column", padding: "0 0 .8rem 0" }}>
                                    <div style={{ display: "flex", gap: "10px", justifyContent: "space-between", flexDirection: "column" }}>
                                        <div>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                                                <TextField
                                                    onChange={(e) => {
                                                        setSelectParameter(e.target.value)

                                                        let tempObj = parameterList[e.target.value]
                                                        console.log(tempObj)

                                                        setSendSubject(tempObj.subject)
                                                        setSendTemplateType(tempObj.templateTypeID)
                                                        setMsgBody(tempObj.emailMessage)
                                                        setMsgBodySms(tempObj.smsMessage)
                                                    }}
                                                    select={true}
                                                    variant={"filled"}
                                                    fullWidth
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            Select Template
                                                            {true ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={true}
                                                    inputProps={{
                                                        pattern: "^[a-zA-Z ]*$",
                                                    }}
                                                    value={selectParameter}
                                                >
                                                    {parameterList.map((i, index) => {
                                                        return (
                                                            <MenuItem value={index}>
                                                                {i.templateReference} - {i.templateTypeID === 1 ? "Static" : "Dynamic"}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </TextField>

                                                <TextField
                                                    onChange={(e) => setSelectMethod(e.target.value)}
                                                    select={true}
                                                    variant={"filled"}
                                                    fullWidth
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            Method
                                                            {true ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    required={true}
                                                    inputProps={{
                                                        pattern: "^[a-zA-Z ]*$",
                                                    }}
                                                    value={selectMethod}
                                                >
                                                    <MenuItem value={"S"}>SMS</MenuItem>
                                                    <MenuItem value={"E"}>Email</MenuItem>
                                                    <MenuItem value={"C"}>Call</MenuItem>
                                                    <MenuItem value={"A"}>SMS + EMail + Call</MenuItem>
                                                </TextField>

                                                <TextField
                                                    fullWidth
                                                    required={true}
                                                    label="Date"
                                                    type="date"
                                                    variant={"filled"}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => setSelectDate(e.target.value)}
                                                    value={selectDate}
                                                />
                                            </div>
                                            {sendTemplateType === 1 ? (
                                                <>
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <p>Microphone: {listening ? "on" : "off"}</p>
                                                        <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={SpeechRecognition.startListening}>
                                                            Start
                                                        </button>
                                                        <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={SpeechRecognition.stopListening}>
                                                            Stop
                                                        </button>
                                                        <button style={{ borderRadius: "10px", border: "none", outline: "none", padding: "10px" }} onClick={resetTranscript}>
                                                            Reset
                                                        </button>
                                                    </div>

                                                    <div style={{ width: "100%" }}>
                                                        <TextField
                                                            value={msgBody}
                                                            multiline={true}
                                                            label="Message Body"
                                                            placeholder="Message Body"
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                setMsgBody(e.target.value)
                                                            }}
                                                            variant={"outlined"}
                                                        />
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ textAlign: "end", width: "100%" }}>
                                <Button variant="contained" onClick={() => quickMessage()} color="primary">
                                    Send Message
                                </Button>
                            </div>
                        </div>
                    </Modal>
                ) : null}
                <div className={tableStyles.headerContainer}>
                    <div className={tableStyles.actionContainer}>
                        <div className={tableStyles.tableHeadingContain}>
                            <p style={{ fontSize: "22px", fontWeight: 600, color: "rgb(0, 40, 103)" }}>{objectName}</p>
                            <div className={tableStyles.optionsContainer}>
                                {newAction ? (
                                    <Button color="default" variant="outlined">
                                        <Add style={{ fontSize: "20px" }} />
                                        Add New {objectName}
                                    </Button>
                                ) : null}

                                {activateGrid ? (
                                    <div className={tableStyles.toggleContainer}>
                                        <Button
                                            color="primary"
                                            iconLeft={<AppsOutlined className={tableStyles.buttonIcon} />}
                                            variant="outlined"
                                            onClick={() => setIsGrid(true)}
                                            className={isGrid ? tableStyles.btnActive : tableStyles.btnToggle}
                                        >
                                            Grid
                                        </Button>

                                        <Button
                                            iconLeft={<FormatListBulletedOutlined className={tableStyles.buttonIcon} />}
                                            variant="outlined"
                                            onClick={() => setIsGrid(false)}
                                            className={!isGrid ? tableStyles.btnActive : tableStyles.btnToggle}
                                            color="primary"
                                        >
                                            List
                                        </Button>
                                    </div>
                                ) : null}
                                <ValidatePermission allowedModules={["03.01 - Directory / Customer"]} allowedSubRoutes={["Download XLSX"]}>
                                    <Button color="default" variant="outlined" onClick={() => exportExcel()}>
                                        Download XLSX
                                    </Button>
                                </ValidatePermission>

                                {downloadPDF ? (
                                    <ValidatePermission allowedModules={["03.01 - Directory / Customer"]} allowedSubRoutes={["Download PDF"]}>
                                        <Button color="default" variant="outlined" onClick={() => exportPDF()}>
                                            Download PDF
                                        </Button>
                                        ,
                                    </ValidatePermission>
                                ) : null}
                                <ValidatePermission allowedModules={["03.01 - Directory / Customer"]} allowedSubRoutes={["Quick Message"]}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            if (checkedList.length > 0) {
                                                setShowMsgModal(true)
                                            } else {
                                                toast.warning("Please select one or more rows to continue.")
                                            }
                                        }}
                                    >
                                        <Message />
                                        &nbsp;Quick Message
                                    </Button>
                                </ValidatePermission>
                            </div>
                        </div>

                        <div className={tableStyles.filtersContainer}>
                            <div>
                                <Text color="p_900" weight="fw_400">
                                    {`${rows.length} ${rows.length > 1 ? "results" : "result"}`} found
                                </Text>
                            </div>
                            <div className={tableStyles.searchContainer}>
                                <SearchBox onChange={(e) => setSearchVal(e)} value={searchVal} />
                                {isGrid ? null : (
                                    <Button color="primary" variant="contained" onClick={() => setShowFiltering(!showFiltering)}>
                                        <FilterAltOutlined />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {isGrid ? (
                    <GridLayout onSelect={(clickedCard) => rowClicked(clickedCard)} template={tableName} page={page} prepareRow={prepareRow} />
                ) : (
                    <table className={`${tableStyles.table} ${hideSort ? tableStyles.hideSort : null}`} {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    <td>
                                        <Checkbox checked={selectAll} onChange={() => setSelectAll(!selectAll)} />
                                    </td>

                                    {headerGroup.headers.map((column) => (
                                        <th>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                    {column.render("Header")}
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <SortByAlpha {...column.getHeaderProps(column.getSortByToggleProps())} style={{ height: "16px", cursor: "pointer", color: "#005df1" }} />
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <ArrowRightAlt className={tableStyles.sortDesc} />
                                                            ) : (
                                                                <ArrowRightAlt className={tableStyles.sortAsc} />
                                                            )
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {showFiltering ? <div style={{ padding: ".5rem 0" }}>{column.canFilter ? column.render("Filter") : null}</div> : null}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)

                                return (
                                    <tr className={tableStyles.tableRow} {...row.getRowProps()}>
                                        <td>
                                            <Checkbox
                                                checked={checkIfSelected(row?.allCells[row?.allCells.length - 1]?.value)}
                                                onClick={() => updateCheckedList(row?.allCells[row?.allCells.length - 1]?.value)}
                                            />
                                        </td>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <>
                                                    {row.cells.length === index + 1 ? (
                                                        <td onClick={() => rowClicked(row?.allCells[row?.allCells.length - 1]?.value)} className={tableStyles.tableData} {...cell.getCellProps()}>
                                                            {cell.render("Cell")}{" "}
                                                        </td>
                                                    ) : (
                                                        <td className={tableStyles.tableData} {...cell.getCellProps()}>
                                                            {cell.render("Cell")}
                                                        </td>
                                                    )}
                                                </>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}

                <div className={tableStyles.pagination}>
                    <div className={tableStyles.rowPages}>
                        <Text color="n_500" weight="fw_400">
                            Rows per page:
                        </Text>
                        <Input
                            items={pageList.map((pageSize) => ({
                                value: pageSize.toString(),
                                text: pageSize,
                            }))}
                            itemSize={12}
                            value={pageSize}
                            onChange={(pageRows) => {
                                setPageSize(Number(pageRows))
                            }}
                        />
                    </div>

                    <span>
                        <Text color="n_500" weight="fw_400">
                            Viewing Page: {pageIndex + 1} of {pageOptions.length}
                        </Text>
                    </span>

                    <div style={{ display: "flex", gap: ".5rem" }}>
                        <Button
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            className={tableStyles.paginationButton}
                            iconLeft={<ChevronLeft />}
                            color="pagination"
                            variant="contained"
                        ></Button>

                        <Button onClick={() => nextPage()} disabled={!canNextPage} className={tableStyles.paginationButton} iconLeft={<ChevronRight />} color="pagination" variant="contained"></Button>
                    </div>
                </div>
            </>
        )
    }

    const tableData = useMemo(() => data, [data])
    return (
        <div style={{ display: "flex" }}>
            <div>
                <div className={styles.tabContainer}>
                    <>
                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                            <div>
                                <Search style={{ color: "#6B7280", height: "22px" }} />
                            </div>
                            <div style={{ color: "#6B7280", fontSize: "16px" }}>Search</div>
                        </div>

                        <p style={{ margin: "5px 0", fontWeight: "600", color: "#111827" }}>Salon</p>
                        <div style={{ width: "237px" }}>
                            {allLocations !== undefined && allLocations !== null ? (
                                <AdvancedDropDown
                                    passByID={true}
                                    isMultiple={true}
                                    data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                    onChange={(e) => {
                                        let tempLocaArr = [] as any

                                        e.map((loc) => {
                                            tempLocaArr.push({ id: loc.value })
                                        })
                                        if (tempLocaArr.length) {
                                            setValidateLocations(false)
                                        } else {
                                            setValidateLocations(true)
                                        }

                                        setLocation(tempLocaArr)

                                    }} 
                                    ref={myRef}
                                    placeHolder={"Locations"}
                                    value={location}
                                    defaultValue={defaultLocation}
                                />
                            ) : null}
                        </div>
                        
                        {/* 
                        <p style={{margin: '5px 0', fontWeight: '600', color: '#111827'}}>Salon</p>
                        <div style={{ width: '237px' }}>
                            {
                                (allLocations !== undefined && allLocations !==null)? 
                                <AdvancedDropDown                                    
                                    passByID={true}                                    
                                    isMultiple={true}
                                    data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID}))} 
                                    onChange={(e)=>{ 
                                        let tempLocaArr = [] as any
                                        
                                        e.map(loc => {
                                            tempLocaArr.push({id: loc.value})
                                        })
                                        if(tempLocaArr.length){
                                            setValidateLocations(false)
                                        }
                                        else{
                                            setValidateLocations(true)
                                        }

                                        setLocation(tempLocaArr);                                     
                                    }}
                                    ref={myRef}
                                    placeHolder={"Locations"}                                    
                                /> : null
                            }                                     
                        </div> */}

                        <div style={{ borderBottom: "1px solid #d4d4d4", width: "100%", marginTop: ".2rem" }}></div>

                        <p style={{ margin: "5px 0", fontWeight: "600", color: "#111827" }}>Customer</p>
                        <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
                            <Input size="small" onChange={(e) => setFName(e)} value={fName} variant="outlined" backgroundColor={"#F9FAFB"} className={styles.input} label={"First name"} />
                            <Input size="small" onChange={(e) => setLName(e)} value={lName} variant="outlined" backgroundColor={"#F9FAFB"} className={styles.input} label={"Last name"} />
                            <Input type="number" onChange={(e) => setZipVal(e)} variant="outlined" value={zipVal} backgroundColor={"#F9FAFB"} className={styles.input} size="small" label={"ZIP"} />
                            <Input size="small" onChange={(e) => setCity(e)} value={city} variant="outlined" backgroundColor={"#F9FAFB"} className={styles.input} label={"City"} />
                            <Input size="small" onChange={(e) => setState(e)} value={state} variant="outlined" backgroundColor={"#F9FAFB"} className={styles.input} label={"State"} />
                        </div>

                        {/* <p style={{ margin: "5px 0", fontWeight: "600", color: "#111827" }}>Status</p>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={activeStatus}
                            onChange={handleChangeActiveStatus}
                            className={styles.centerRadio}
                        >
                            <FormControlLabel value={true} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Active</p>} />
                            <FormControlLabel value={false} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Inactive</p>} />
                        </RadioGroup> */}

                        <div>
                            <p style={{ margin: "5px 0", fontWeight: "600", color: "#111827" }}>Status</p>
                            <div className={styles.centerCheckbox} style={{marginLeft:"10px"}}>
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={activeStatus} 
                                            onChange={handleChangeActiveStatus} 
                                            style={{ padding: "0 5px 0 0" }} 
                                            size="small" 
                                        />
                                    } 
                                    label={<p style={{ fontSize: "12px" }}>Active</p>} 
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={inactiveStatus} 
                                            onChange={handleChangeInActiveStatus} 
                                            style={{ padding: "0 5px 0 0" }} 
                                            size="small" 
                                        />
                                    } 
                                    label={<p style={{ fontSize: "12px" }}>Inactive</p>} 
                                />
                            </div>
                        </div>

                        <p style={{ margin: "5px 0", fontWeight: "600", color: "#111827" }}>VIP Customer</p>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={vipStatus}
                            onChange={handleChangeVipStatus}
                            className={styles.centerRadio}
                        >
                            <FormControlLabel value={true} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>Yes</p>} />
                            <FormControlLabel value={false} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "12px" }}>No</p>} />
                        </RadioGroup>

                        {/* <div>
                            <p style={{ margin: "5px 0", fontWeight: "600", color: "#111827" }}>Priority</p>
                            <div className={styles.centerCheckbox} style={{marginLeft:"10px"}}>
                                <FormControlLabel 
                                    control={
                                        <Checkbox 
                                            checked={vipStatus} 
                                            onChange={handleChangeVipStatus} 
                                            style={{ padding: "0 5px 0 0" }} 
                                            size="small" 
                                        />
                                    } 
                                    label={<p style={{ fontSize: "12px" }}>VIP</p>} 
                                />
                            </div>
                        </div> */}
                        <div style={{ borderBottom: "1px solid #d4d4d4", width: "100%", marginTop: ".6rem" }}></div>

                        <p style={{ margin: "5px 0", fontWeight: "600", color: "#111827" }}>Pet</p>
                        <Input onChange={(e) => setPetName(e)} value={petName} size="small" variant="outlined" backgroundColor={"#F9FAFB"} className={styles.input} label={"Pet's name"} />

                        <div style={{ width: "100%" }}>
                            {petTypes !== undefined && petTypes !== null ? (
                                <Input
                                    value={petType}
                                    label={"Pets Type"}
                                    items={petTypes.map((i) => ({ text: i.varPetTypeName, value: i.numPetTypeID }))}
                                    onChange={(e) => {
                                        {
                                            setPetType(e)
                                        }
                                        {
                                            getBreedData(e)
                                        }
                                    }}
                                />
                            ) : (
                                "Loading.."
                            )}
                        </div>

                        <div style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }}>
                            {petBreeds !== undefined && petBreeds !== null ? (
                                <AdvancedDropDown
                                    data={petBreeds.map((i) => ({ label: i.varPetBreedName, value: i.varPetBreedName }))}
                                    onChange={(e) => {
                                        setPetBreed(e?.label)
                                    }}
                                    ref={breedRef}
                                    placeHolder={"Breeds"}
                                    isDisabled={petBreeds.length > 0 ? undefined : true}
                                />
                            ) : null}
                        </div>

                        <Button className={styles.resetButton} variant="outlined" color="info" onClick={resetFilters} loading={isLoading}>
                            Reset Filters
                        </Button>
                    </>
                </div>
            </div>

            <div style={{ width: "100%", padding: "10px" }}>
                {viewProfile ? (
                    <Profile goBack={(value) => setViewProfile(value)} type={"customer"} id={selectedCustomerID} />
                ) : (
                    <div style={{ width: "100%", overflowX: "scroll", margin: "1rem 0" }}>
                        <RenderTable
                            downloadPDF={true}
                            tableName={"Customers"}
                            hiddenColumns={Customer?.hiddenColumns}
                            activateGrid={true}
                            columns={Customer?.template}
                            data={tableData}
                            loadingData={isLoading}
                            newAction={""}
                            objectName={"Customer Directory"}
                            rowClicked={(clickedId) => viewCustomerProfile(clickedId)}
                            searchFilter={searchVal}
                            hideSort={Customer?.hideSort}
                            pdfColumns={Customer.pdfTemplate}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default CustomerDirectory
