import {Chip as MaterialChip} from '@mui/material';

import styles from './ChipGroup.module.scss';

//    color?; // default | primary | secondary | error | info | succeses | warning

export const ChipGroup = (props: {
    variant?; // filled | outlined
    size?; // medium | small
    icon?; // icon
    values;
    alignment?;
}) => {
    return (
        <div style={{display: 'flex', gap: '.2rem', textAlign: 'center', minWidth:"100px", maxWidth: '200px', flexWrap:"wrap" }}>
            {   
                props?.values.map(value => {
                    if(value?.value){
                        return <MaterialChip
                        label={value?.label} 
                        color={'info'}
                        size={(props.size == undefined) ? `small` : props.size} 
                        variant={(props.variant == undefined) ? `outlined` : props.variant} 
                        icon={props.icon}
                        className={`${styles[value?.color]} ${styles[props?.alignment]}`}
                    />
                    }
                   
                })
            } 
        </div>
      
    );
}
