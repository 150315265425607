import CancelIcon from "@mui/icons-material/Cancel";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import WarningIcon from "@mui/icons-material/Warning";

import styles from './PetMiniCard.module.scss';
import { Truncate } from "../../../../utils/Validation";

const PetMiniCard = (props: {
    index: number;
    isSelected: number;
    name: string;
    breed: string;
    size: string;
    selectPet: CallableFunction;
    icon?;
}) => {
    return (
        <div className={ `${styles.container} ${(props.isSelected === props.index) ? styles.active : 'no'}` } onClick={() => props.selectPet(props?.index)}>
            <div className={ (props.isSelected === props.index) ? styles.activeImg : styles.img }>
                <p className={styles.sizeText}>{props?.size}</p>
            </div>
            <div>
                <p>{Truncate(props?.name,10)}</p>
                <p className={styles.petBreed}>{Truncate(props?.breed,10)}</p>
            </div>       
            <div>
                {
                    (props?.icon === 'warning') ?
                        <WarningIcon />
                    : (props?.icon === 'alert') ?
                        <AddAlertIcon />
                    : (props?.icon === 'vaccine') ?
                        <VaccinesIcon />
                    : (props?.icon === 'cencel') ?
                        <CancelIcon />
                    : null
                }               
            </div>       
        </div>
    );
}

export default PetMiniCard;