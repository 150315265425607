import { useState, useEffect } from "react"

import styles from "./Tabs.module.scss"

export const Tabs = (props: { tabs }) => {
    const [activeTab, setActiveTab] = useState(0)
    const [displayTabs, setDisplayTabs] = useState<any>([])

    const handleChange = (index) => {
        setActiveTab(index)
    }

    useEffect(() => {
        let addTabs: any = []
        props.tabs
            .filter((x) => x.isDisabled === true)
            .map((tab, index) => {
                addTabs.push(tab)
            })
        setDisplayTabs(addTabs)
    }, [props.tabs])

    return (
        <div style={{ display: "flex", gap: "1rem", backgroundColor: "#F9FAFB" }}>
            <div style={{ backgroundColor: "#fff", width: "300px", height: "fit-content" }}>
                {displayTabs.map((tab, index) => {
                    return (
                        <div onClick={() => handleChange(index)} key={index} className={`${activeTab === index ? styles.activeTab : styles.tab}`}>
                            {tab.name}
                        </div>
                    )
                })}
            </div>
            <div style={{ padding: "3rem 4.5rem", backgroundColor: "#fff", width: "100%" }}>
                <div>{displayTabs?.[activeTab]?.component}</div>
            </div>
        </div>
    )
}
