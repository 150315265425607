import { Chip } from "../../components/Chip/Chip"
import { Chip as CustomChip} from "../../../components"
/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

/*
    "varPetTypeName": "Dog",
    "varPetTypeDescription": null,
    "bitPetTypeActive": true
*/

export const ServiceItems:{} = {
    tableName: "Service Items",
    hiddenColumns: ["numLocationServiceItemID", "numSalesCommissionAmount", "btSalesCommissionAmount", "numSalesCommissionPercent", "btSalesCommissionPercent", "numDateSpecificAdjustmentAmount", "btDateSpecificAdjustmentAmount", "numDateSpecificAdjustmentPercent", "btDateSpecificAdjustmentPercent", "dtDateSpecificAdjustmentExpiryDate", "dtDateSpecificAdjustmentEffectiveDate", "btDateSpecificAdjustment", "numLocationSpecificAdjustmentAmount", "btLocationSpecificAdjustmentAmount", "numLocationSpecificAdjustmentPercent", "btLocationSpecificAdjustmentPercent", "dtLocationSpecificAdjustmentExpiryDate", "dtLocationSpecificAdjustmentEffectiveDate", "bitLocationSpecificAdjustment", "varPromotionalMessage", "varMessageToEmployee", "varLinkToGlobalCoupon", "varLinkToPromotionMaterial", "dtExpiryDate", "dtEffectiveDate", "locID", "numServiceItemID", "numLocationServiceItemID"],
    pdfTemplate: ['varLocation', "varServiceItemBarCode", "varService", "numServiceItemCostPrice", "numServiceItemSalesPrice", "bitTaxableItem", "bitActivePromotionFlag", "bitIsAdditional"],
    template: [
        {
            Header: 'Location',
            accessor: 'varLocation'
        },
        {
            Header: 'BarCode',
            accessor: 'varServiceItemBarCode'
        },
        {
            Header: 'Service',
            accessor: 'varService'
        },
        {
            Header: 'Purchase Price',
            accessor: 'numServiceItemCostPrice'
        },
        {
            Header: 'Sales Price',
            accessor: 'numServiceItemSalesPrice'
        },
        {
            Header: 'Taxable Item',
            accessor: 'bitTaxableItem',
            Cell: props => (
                <CustomChip size='small' customBackgroundColor={"#2076ff"} customBorderRadius={"12px"} fontColor={"#FFFF"} label={(props?.cell?.value)? "Yes" : "No"} />             
            )  
        },
        {
            Header: 'Promotional Item',
            accessor: 'bitActivePromotionFlag',
            Cell: props => (
                <CustomChip size='small' customBackgroundColor={"#2076ff"} customBorderRadius={"12px"} fontColor={"#FFFF"} label={(props?.cell?.value)? "Yes" : "No"} />             
            )  
        },
        {
            Header: 'Is Additional',
            accessor: 'bitIsAdditional',
            Cell: props => (
                <CustomChip size='small' customBackgroundColor={"#2076ff"} customBorderRadius={"12px"} fontColor={"#FFFF"} label={(props?.cell?.value)? "Yes" : "No"} />             
            )  
        },
        {
            Header: 'Status',
            accessor: 'bitActive',
            Cell: props => (
                <Chip label={props?.cell?.value} size={'small'}/>              
            )  
        },
        {
            Header: 'numSalesCommissionAmount',
            accessor: 'numSalesCommissionAmount',
            disableFilters: true
        },
        {
            Header: 'btSalesCommissionAmount',
            accessor: 'btSalesCommissionAmount',
            disableFilters: true
        },
        {
            Header: 'numSalesCommissionPercent',
            accessor: 'numSalesCommissionPercent',
            disableFilters: true
        },
        {
            Header: 'btSalesCommissionPercent',
            accessor: 'btSalesCommissionPercent',
            disableFilters: true
        },
        {
            Header: 'numDateSpecificAdjustmentAmount',
            accessor: 'numDateSpecificAdjustmentAmount',
            disableFilters: true
        },
        {
            Header: 'btDateSpecificAdjustmentAmount',
            accessor: 'btDateSpecificAdjustmentAmount',
            disableFilters: true
        },
        {
            Header: 'numDateSpecificAdjustmentPercent',
            accessor: 'numDateSpecificAdjustmentPercent',
            disableFilters: true
        },
        {
            Header: 'btDateSpecificAdjustmentPercent',
            accessor: 'btDateSpecificAdjustmentPercent',
            disableFilters: true
        },
        {
            Header: 'dtDateSpecificAdjustmentExpiryDate',
            accessor: 'dtDateSpecificAdjustmentExpiryDate',
            disableFilters: true
        },
        {
            Header: 'dtDateSpecificAdjustmentEffectiveDate',
            accessor: 'dtDateSpecificAdjustmentEffectiveDate',
            disableFilters: true
        },
        {
            Header: 'btDateSpecificAdjustment',
            accessor: 'btDateSpecificAdjustment',
            disableFilters: true
        },
        {
            Header: 'numLocationSpecificAdjustmentAmount',
            accessor: 'numLocationSpecificAdjustmentAmount',
            disableFilters: true
        },
        {
            Header: 'btLocationSpecificAdjustmentAmount',
            accessor: 'btLocationSpecificAdjustmentAmount',
            disableFilters: true
        },
        {
            Header: 'numLocationSpecificAdjustmentPercent',
            accessor: 'numLocationSpecificAdjustmentPercent',
            disableFilters: true
        },
        {
            Header: 'btLocationSpecificAdjustmentPercent',
            accessor: 'btLocationSpecificAdjustmentPercent',
            disableFilters: true
        },
        {
            Header: 'dtLocationSpecificAdjustmentExpiryDate',
            accessor: 'dtLocationSpecificAdjustmentExpiryDate',
            disableFilters: true
        },
        {
            Header: 'dtLocationSpecificAdjustmentEffectiveDate',
            accessor: 'dtLocationSpecificAdjustmentEffectiveDate',
            disableFilters: true
        },
        {
            Header: 'bitLocationSpecificAdjustment',
            accessor: 'bitLocationSpecificAdjustment',
            disableFilters: true
        },
        {
            Header: 'varPromotionalMessage',
            accessor: 'varPromotionalMessage',
            disableFilters: true
        },
        {
            Header: 'varMessageToEmployee',
            accessor: 'varMessageToEmployee',
            disableFilters: true
        },
        {
            Header: 'varLinkToGlobalCoupon',
            accessor: 'varLinkToGlobalCoupon',
            disableFilters: true
        },
        {
            Header: 'varLinkToPromotionMaterial',
            accessor: 'varLinkToPromotionMaterial',
            disableFilters: true
        },
        {
            Header: 'dtExpiryDate',
            accessor: 'dtExpiryDate',
            disableFilters: true
        },
        {
            Header: 'dtEffectiveDate',
            accessor: 'dtEffectiveDate',
            disableFilters: true
        },
        {
            Header: 'locID',
            accessor: 'locID',
            disableFilters: true
        },
        {
            Header: 'numServiceItemID',
            accessor: 'numServiceItemID',
            disableFilters: true
        },
        {
            Header: 'numLocationServiceItemID',
            accessor: 'numLocationServiceItemID',
            disableFilters: true
        }
    ],
    postFields:[
        {
            fieldName: "numLocationServiceItemID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0
        },
        {
            fieldName: "varServiceItemBarCode",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "numServiceItemID",
            type: "advanced",
            inputType: "text",
            objectName: "ServiceItemsLibrary",
            required: true,
            value: "",
            altName:"numService",
            isCustomFields: true,
            customFieldID: "numServiceItemID",
            customFieldName:"varServiceItemName"
        },
        {
            fieldName: "locID",
            type: "advanced",
            inputType: "text",
            objectName: "Location",
            required: true,
            value: "",
            altName:"numLocation"
        },
        {
            fieldName: "dtEffectiveDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "dtExpiryDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "numServiceItemCostPrice",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "numServiceItemSalesPrice",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "bitTaxableItem",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value:false
        },
        {
            fieldName: "bitActivePromotionFlag",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value:false
        },
        {
            fieldName: "varLinkToPromotionMaterial",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "varLinkToGlobalCoupon",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "varMessageToEmployee",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "varPromotionalMessage",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "bitLocationSpecificAdjustment",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value:false
        },
        {
            fieldName: "dtLocationSpecificAdjustmentEffectiveDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "dtLocationSpecificAdjustmentExpiryDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "btLocationSpecificAdjustmentPercent",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value:false,
            subFields: {
                    fieldName: "numLocationSpecificAdjustmentPercent",
                    type: "text",
                    inputType: "number",
                    mainTable: "",
                    required: true,
                    value: "" 
            }
        },
        {
            fieldName: "btLocationSpecificAdjustmentAmount",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value:false,
            subFields: {
                    fieldName: "numLocationSpecificAdjustmentAmount",
                    type: "text",
                    inputType: "number",
                    mainTable: "",
                    required: true,
                    value: "" 
            }
        },
        {
            fieldName: "btDateSpecificAdjustment",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value:false
        },
        {
            fieldName: "dtDateSpecificAdjustmentEffectiveDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "dtDateSpecificAdjustmentExpiryDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "btDateSpecificAdjustmentPercent",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value:false,
            subFields: {
                    fieldName: "numDateSpecificAdjustmentPercent",
                    type: "text",
                    inputType: "number",
                    mainTable: "",
                    required: true,
                    value: "" 
            }
        },
        {
            fieldName: "btDateSpecificAdjustmentAmount",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value:false,
            subFields: {
                    fieldName: "numDateSpecificAdjustmentAmount",
                    type: "text",
                    inputType: "number",
                    mainTable: "",
                    required: true,
                    value: "" 
            }
        },
        {
            fieldName: "btSalesCommissionPercent",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value:false,
            subFields: {
                    fieldName: "numSalesCommissionPercent",
                    type: "text",
                    inputType: "number",
                    mainTable: "",
                    required: true,
                    value: "" 
            }
        },
        {
            fieldName: "btSalesCommissionAmount",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value:false,
            subFields: {
                    fieldName: "numSalesCommissionAmount",
                    type: "text",
                    inputType: "number",
                    mainTable: "",
                    required: true,
                    value: "" 
            }
        },
        {
            fieldName: "bitIsAdditional",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value:false
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true
        }
    ]
}
