import Salons from "./Location/Salons";
import Customer from "./Customer/Customer";
import Employee from "./Employee/Employee";
import Pet from "./Pet/Pet";

const GridLayout = (props: {
    template?: string;
    page;
    prepareRow;
    onSelect: CallableFunction;
}) => {
    if(props.template === "Salons"){
        return (
            <Salons onSelect={(value) => props.onSelect(value)} page={props.page}  prepareRow={props.prepareRow}/>
        )
    }
    else if(props.template === "Customers"){
        return (
            <Customer onSelect={(value) => props.onSelect(value)} page={props.page}  prepareRow={props.prepareRow}/>
        )
    }
    else if(props.template === "Pet"){
        return (
            <Pet onSelect={(value) => props.onSelect(value)} page={props.page}  prepareRow={props.prepareRow}/>
        )
    }
    else if(props.template === "Employee"){
        return (
            <Employee onSelect={(value) => props.onSelect(value)} page={props.page}  prepareRow={props.prepareRow}/>
        )
    }
    else if(props.template === "MyTask"){
        return (
            <Employee onSelect={(value) => props.onSelect(value)} page={props.page}  prepareRow={props.prepareRow}/>
        )
    }
    else{
        return null
    }
   
}

export default GridLayout;