import LabelConfiguration from "./pages/AdminPanel/LabelConfiguration/LabelConfiguration"
import ImportExport from "./pages/ImportExport/ImportExport"
import { useJsApiLoader } from "@react-google-maps/api"

import "./app.scss"
import Library from "./pages/AdminPanel/Library/Library"
import Directory from "./pages/Directories/Directory"
import PetProfileScreen from "./pages/NewBooking/PetProfile/PetProfileScreen"
import Login from "./pages/Login/Login"

import RequireAuth from "./pages/RequireAuth"
import { Routes, Route } from "react-router-dom"

import NotFound from "./pages/NotFound/NotFound"
import Dashboard from "./pages/Dashboard/Dashboard"

import DefaultLayout from "./layouts/DefaultLayout/DefaultLayout"
import MainLayout from "./layouts/MainLayout/MainLayout"
import Unauthorized from "./pages/Unauthorized/Unauthorized"
import AdminPanel from "./pages/AdminPanel/AdminPanel"

import PersistLogin from "./pages/PersistLogin"

import Customer from "./pages/Directories/Customer/Customer"
import Salon from "./pages/Directories/Salon/Salon"
import Staff from "./pages/Directories/Staff/Staff"
import Pet from "./pages/Directories/Pet/Pet"
import UserRoles from "./pages/AdminPanel/UserRoles/UserRoles"
import SubLayout from "./layouts/SubLayout/SubLayout"
import Booking from "./pages/Booking/Booking"
import Help from "./pages/Help/Help"
import HelpContent from "./pages/Help/HelpContent/HelpContent"
import Scheduler from "./pages/AdminPanel/Scheduler/Scheduler"
import Inquiry from "./pages/Directories/Inquiry/Inquiry"
import NewBookingInquiry from "./pages/Directories/Inquiry/NewBookingInquiry"
import Basics_to_the_System from "./pages/Help/HelpContent/HelpPages/BasicsToTheSystem"
import MakingASchedule from "./pages/Help/HelpContent/HelpPages/MakingASchedule"
import LableConfiguration from "./pages/Help/HelpContent/HelpPages/LableConfiguration"
import ImportExportData from "./pages/Help/HelpContent/HelpPages/ImportExportData"
import HelpLibrary from "./pages/Help/HelpContent/HelpPages/HelpLibrary"
import HelpUserRoles from "./pages/Help/HelpContent/HelpPages/HelpUserRoles"
import HelpScheduler from "./pages/Help/HelpContent/HelpPages/HelpScheduler"
import Administrator from "./pages/Help/HelpContent/HelpPages/Administrator"
import CallCenter from "./pages/Help/HelpContent/HelpPages/CallCenter"
import CustomerDirectory from "./pages/Help/HelpContent/HelpPages/CustomerDirectory"
import PetDirectory from "./pages/Help/HelpContent/HelpPages/PetDirectory"
import SalonDirectory from "./pages/Help/HelpContent/HelpPages/SalonDirectory"
import EmployeeDirectory from "./pages/Help/HelpContent/HelpPages/EmployeeDirectory"
import BookingInquiry from "./pages/Help/HelpContent/HelpPages/BookingInquiry"
import TabLayout from "./layouts/TabLayout/TabLayout"
import TabDashboard from "./TabletView/pages/TabDashboard/TabDashboard"
import TabWorking from "./TabletView/pages/TabWorking/TabWorking"
import GroomingCard from "./TabletView/pages/GroomingCard/GroomingCard"
import CustomerHelp from "./pages/Help/HelpContent/HelpPages/CustomerHelp"
import GeneralHelp from "./pages/Help/HelpContent/HelpPages/GeneralHelp"
import LocationHelp from "./pages/Help/HelpContent/HelpPages/LocationHelp"
import CreateCustomerProfile from "./pages/Help/HelpContent/HelpPages/CreateCustomerProfile"
import AddAPet from "./pages/Help/HelpContent/HelpPages/AddAPet"
import EditCustomer from "./pages/Help/HelpContent/HelpPages/EditCustomer"
import DeactivateCustomer from "./pages/Help/HelpContent/HelpPages/DeactivateCustomer"
import Location1 from "./pages/Help/HelpContent/HelpPages/Location1"
import HelpUserrole from "./pages/Help/HelpContent/HelpPages/HelpUserrole"
import Messaging from "./pages/Messaging/Messaging"
import BulkMessaging from "./pages/BulkMessaging/BulkMessaging"
import MessagingTemplates from "./pages/MessagingTemplates/MessagingTemplates"
import AppointmentsDirectory from "./pages/Directories/Appointments/Appointments"
import WorkingTab from "./TabletView/pages/Components/WorkingTab/WorkingTab"
import ImportExportReset from "./pages/ImportExportReset/ImportExportReset"
import NewCalendar from "./pages/AdminPanel/Scheduler/NewCalendar/NewCalendar"
import Welcome from "./pages/Login/Welcome/Welcome"
import ResetPassword from "./pages/Login/ResetPassword/ResetPassword"
import CreatePassword from "./pages/Login/CreatePassword/CreatePassword"

function App() {
    const GOOGLE_MAP_KEY: any = process.env.REACT_APP_GMAP_API_KEY
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAP_KEY,
        libraries: ["places"],
    })

    return (
        <Routes>
            <Route path="/" element={<DefaultLayout />}>
                {/* PUBLIC ROUTES */}
                <Route path="login" element={<Login />} />
                <Route path="welcome" element={<Welcome />} />
                <Route path="forgot-password/:userId" element={<ResetPassword />} />
                <Route path="create-password/:userId" element={<CreatePassword />} />

                <Route path="unauthorized" element={<Unauthorized />} />

                {/* PROTECTED ROUTES */}
                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth allowedRoles={["01 - Dashboard"]} />}>
                        <Route path="tablet" element={<TabLayout />}>
                            <Route path="/tablet" element={<TabDashboard />} />
                            <Route path="working" element={<WorkingTab />} />
                            <Route path="groomingCard" element={<GroomingCard />} />
                        </Route>

                        <Route path="/" element={<MainLayout />}>
                            <Route path="/" element={<Dashboard />} />
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={["01 - Dashboard"]} />}>
                        <Route path="/" element={<MainLayout />}>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="admin" element={<AdminPanel />}>
                                <Route path="label-config/:name" element={<LabelConfiguration />} />
                                <Route path="library/:name" element={<Library />} />
                                <Route path="import-export" element={<ImportExport />} />
                                <Route path="import-export-reset" element={<ImportExportReset />} />

                                <Route path="scheduler" element={<Scheduler />} />
                                <Route path="messaging" element={<Messaging />} />
                                <Route path="bulk-messaging" element={<BulkMessaging />} />
                                <Route path="messaging-templates" element={<MessagingTemplates />} />
                                <Route path="user-roles" element={<UserRoles />}></Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={["01 - Dashboard"]} />}>
                        <Route path="/" element={<MainLayout />}>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="directory" element={<Directory />}>
                                <Route path="customer" element={<Customer />} />
                                <Route path="salon" element={<Salon />} />
                                <Route path="pet" element={<Pet />} />
                                <Route path="employee" element={<Staff />} />
                                <Route path="appointments" element={<AppointmentsDirectory />} />
                                <Route path="inquiry" element={<Inquiry />} />
                                <Route path="new-booking-inquiry" element={<NewBookingInquiry />} />
                            </Route>
                        </Route>
                        <Route path="/" element={<SubLayout />}>
                            <Route path="booking" element={<Booking />}></Route>
                            <Route path="new-calendar" element={<NewCalendar timeInterval={30} locationID={1} employeeID={18} />} />
                        </Route>
                        <Route path="/" element={<MainLayout />}>
                            <Route path="help" element={<Help />} />
                            <Route path="help" element={<HelpContent />}>
                                <Route path="general" element={<GeneralHelp />} />
                                <Route path="general1" element={<Basics_to_the_System />} />
                                <Route path="general2" element={<MakingASchedule />} />

                                <Route path="customer" element={<CustomerHelp />} />
                                <Route path="customer1" element={<CreateCustomerProfile />} />
                                <Route path="customer2" element={<AddAPet />} />
                                <Route path="customer3" element={<EditCustomer />} />
                                <Route path="customer4" element={<DeactivateCustomer />} />

                                <Route path="administrator" element={<Administrator />} />
                                <Route path="administrator1" element={<LableConfiguration />} />
                                <Route path="administrator2" element={<ImportExportData />} />
                                <Route path="administrator3" element={<HelpLibrary />} />
                                <Route path="administrator4" element={<HelpUserrole />} />
                                <Route path="administrator5" element={<HelpScheduler />} />

                                <Route path="location" element={<LocationHelp />} />
                                <Route path="location1" element={<Location1 />} />
                                <Route path="location2" element={<Location1 />} />
                                <Route path="location3" element={<Location1 />} />
                                <Route path="location4" element={<Location1 />} />

                                <Route path="directory" element={<CallCenter />} />
                                <Route path="directory1" element={<CustomerDirectory />} />
                                <Route path="directory2" element={<PetDirectory />} />
                                <Route path="directory3" element={<SalonDirectory />} />
                                <Route path="directory4" element={<EmployeeDirectory />} />
                                <Route path="directory5" element={<BookingInquiry />} />
                            </Route>
                        </Route>
                    </Route>

                    {/* MISSING ROUTES */}
                    <Route path="/" element={<MainLayout />}>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
        // <>
        // <PetProfileScreen/>
        // </>
    )
}

export default App
