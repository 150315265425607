import { Grid, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, CloseButton } from '../../components';
import { Modal } from '../../components/components';
import AdvancedDropDown from '../AdvancedDropDown/AdvancedDropDown';
import { toast } from "react-toastify";

const Payment = (props: {
    isOpenPaymentsModal;
    setIsOpenPaymentsModal: CallableFunction;
    data?;
    customerID?;
    clearSelected: CallableFunction;
    onSuccess: CallableFunction;
    appointment?
    passedByInv?
    isSuccessFullPayment?
}) => {
     const [total, setTotal] = useState<any>(0)
     const [type, setType] = useState<any>("")
     const [title, setTitle] = useState<any>("")
     const [isLoading, setIsLoading] = useState<any>(false)
     const [isEnablePay, setIsEnablePay] = useState<any>(false)
     const [hideBalance, setHideBalance] = useState<any>(false)
     const [cusCCList, setCusCCList] = useState<any>([])
     const [cusCC, setCusCC] = useState<any>("")
     const CCRef = useRef<any>();
     const [luckyBuckList, setLuckyBuckList] = useState<any>([])
     const [luckyBuck, setLuckyBuck] = useState<any>("")
     const luckyBuckRef = useRef<any>();
     const [payMethodList, setPayMethodsList] = useState<any>([
        {
            id: 1,
            type: "Cash"
        },
        {
            id: 2,
            type: "Check"
        },
        {
            id: 3,
            type: "ECheck"
        },
        {
            id: 4,
            type: "Credit Card"
        },
        {
            id: 5,
            type: "Lucky Bucks"
        },
    ])
    const [paymentMethod, setPaymentMethod] = useState<any>(1)
    const payMethodRef = useRef<any>();
    const [cashAmount, setCashAmount] = useState<any>("")
    const [checkAmount, setCheckAmount] = useState<any>("")
    const [eCheckAmount, setEcheckAmount] = useState<any>("")
    const [creditCardAmount, setCreditCardAmount] = useState<any>("")
    const [luckBucksAmount, setLuckBucksAmount] = useState<any>("")
    const [luckBucksAvailAmount, setLuckBucksAvailAmount] = useState<any>("")
    const [balanceAmount, setBalanceAmount] = useState<any>(0)
    
    useEffect(()=>{
        if(props.isOpenPaymentsModal === true){
            if(props.data[0].type === "Order"){
                setTotal(props?.data[0]?.remaining)
                setType(props?.data[0]?.type)
                setTitle(props?.data[0]?.varTitle)
                setCashAmount(props?.data[0]?.remaining)
            }else{
                let amt = 0;
                props.data.map((item, index)=>{
                    amt = amt + parseFloat(item.remaining)
                    setType("Invoice")
                })
                setTitle(formatVarTitle(props.data))
                setTotal(amt.toFixed(2))
                setCashAmount(amt.toFixed(2))
            }

            GetAllCreditCardsByCustomerID(props.customerID)
            .then((data) => {
                if(data !== undefined && data.length > 0){
                     setCusCCList(data)
                }
            })
            .catch((error) => {
                console.log("error", error);
            });

            GetLuckyBucks(props.customerID)
            .then((data) => {
                if(data !== undefined && data.length > 0){
                     setLuckyBuckList(data)
                     setIsLoading(false)
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
              
              setPaymentMethod(1)
              setBalanceAmount(0)
         }
    },[props.isOpenPaymentsModal])

    function formatVarTitle(arr) {
        const counts = {};
        for (const obj of arr) {
          const varTitle = obj.varTitle;
          counts[varTitle] = (counts[varTitle] || 0) + 1;
        }
      
        let firstUniqueVarTitle = null;
        for (const obj of arr) {
          const varTitle = obj.varTitle;
          if (counts[varTitle] === 1) {
            firstUniqueVarTitle = varTitle;
            break;
          }
        }
      
        if (!firstUniqueVarTitle) {
          return null; 
        }
      
        let totalDifferentVarTitles = 0;
        for (const key in counts) {
          if (counts.hasOwnProperty(key) && key !== firstUniqueVarTitle) {
            totalDifferentVarTitles += 1;
          }
        }
      
        const formattedVarTitle = totalDifferentVarTitles === 0 ? firstUniqueVarTitle : `${firstUniqueVarTitle} (+${totalDifferentVarTitles})`;
      
        return formattedVarTitle;
      }
      


    const GetAllCreditCardsByCustomerID = async (cusID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetAllCreditCardsByCustomerID`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
              numCustomerID: cusID
            },
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
    };

    const GetLuckyBucks = async (cusID) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetLuckyBucks`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
              numCustomerID: cusID,
              numLuckyBucksBarcodeID: 0,
              numLuckyBucksID: 0
            },
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
    };

    useEffect(()=>{
            CCRef.current?.handleClear();
            luckyBuckRef.current?.handleClear();
            setIsEnablePay(false)
            setCashAmount("")
            setCheckAmount("")
            setEcheckAmount("")
            setCreditCardAmount("")
            setLuckBucksAmount("")
            if(paymentMethod !== 1){
                setHideBalance(true)
            }else{
                setHideBalance(false)
            }
            setCashAmount(parseFloat(total).toFixed(2))
            setCheckAmount(parseFloat(total).toFixed(2))
            setEcheckAmount(parseFloat(total).toFixed(2))
            setCreditCardAmount(parseFloat(total).toFixed(2))
            setLuckBucksAmount(parseFloat(total).toFixed(2))

            if(paymentMethod === 5){
                if(luckyBuck === ""){
                    setIsEnablePay(true)
                }else{
                    setIsEnablePay(false)
                }
            }     
    },[paymentMethod])

    const resetAll = () => {
        CCRef.current?.handleClear();
        luckyBuckRef.current?.handleClear();
        setIsEnablePay(false)
        setCashAmount("")
        setCheckAmount("")
        setEcheckAmount("")
        setCreditCardAmount("")
        setLuckBucksAmount("")
        setPaymentMethod(1)
    }

    useEffect(()=>{

        let finalAmount = 0;
        if(paymentMethod === 1){
            finalAmount = cashAmount;
        }
        else if(paymentMethod === 2){
            finalAmount = checkAmount
        }
        else if(paymentMethod === 3){
            finalAmount = eCheckAmount
        }
        else if(paymentMethod === 4){
            finalAmount = creditCardAmount
        }
        else if(paymentMethod === 5){
            finalAmount = luckBucksAmount
        }

        setBalanceAmount(parseFloat(total)- finalAmount)
        

    },[cashAmount, checkAmount, eCheckAmount, creditCardAmount, luckBucksAmount])

    useEffect(()=>{
        if(paymentMethod === 5){
            if(luckyBuck === ""){
                setIsEnablePay(true)
            }else{
                if(luckBucksAvailAmount < luckBucksAmount){
                    toast.info("Sorry, not enough Lucky bucks!");
                    setIsEnablePay(true)
                }else{
                    setIsEnablePay(false)
                }
            }
        }
    },[luckBucksAvailAmount, luckBucksAmount])
    
    const makePayment = () =>{

        setIsLoading(true)
        if(props.data[0].type === "Order"){

            let finalAmount:any = 0;
            let payType = ""
            if(paymentMethod === 1){
                finalAmount = parseFloat(cashAmount)
                payType = "Cash";
            }
            else if(paymentMethod === 2){
                finalAmount = parseFloat(checkAmount)
                payType = "Check";
            }
            else if(paymentMethod === 3){
                finalAmount = parseFloat(eCheckAmount)
                payType = "ECheck";
            }
            else if(paymentMethod === 4){
                finalAmount = parseFloat(creditCardAmount)
                payType = "Card";
            }
            else if(paymentMethod === 5){
                finalAmount = parseFloat(luckBucksAmount)
                payType = "Lucky";
            }

            let order_payload = {
                "paymentMethod": payType,
                "isOrder": true,
                "amountEntered": finalAmount,
                "numEmployeeID": 1,
                "varEmployeeName": "Ravin",
                "numLuckyBucksID": (payType === "Lucky")? luckyBuck : null ,
                "numPaymentLocationID": 1,
                "numCustomerCreditCardID": (payType === "Card")? cusCC : null,
                "charges": props.data
              }
            if(finalAmount > 0){
                if(paymentMethod === 4 && cusCC === ""){
                    toast.info("Please select a card to proceed!");
                    setIsLoading(false)
                }else{
                        console.log("order-payload", order_payload)
                            axios.post(`${process.env.REACT_APP_API_URL}Payment/PopulateMakePayment`, order_payload)
                            .then(function (response) {
                            console.log("app_success", response.data);
                            if(response.status === 200){

                                toast.success("Payment successful!");
                                if(props.passedByInv !== true){
                                    if(finalAmount >= parseFloat(props?.data[0]?.remTotal)){
                                        console.log("Update payment -status")
                                        completePayment()

                                    }else{
                                        console.log("App-status not updated - payments are still pending")
                                        props.onSuccess(true)
                                    }
                                }else{
                                    if(finalAmount >= parseFloat(props?.data[0]?.remTotal)){
                                        console.log("Update payment -status")
                                        props.isSuccessFullPayment(true)
                                    }else{
                                        console.log("App-status not updated - payments are still pending")
                                        props.onSuccess(true)
                                    }
                                }

                                resetAll()
                                props.setIsOpenPaymentsModal(false)
                                props.clearSelected(true)
                                setIsLoading(false)
                            }
                            })
                            .catch(function (error) {
                                console.log(error);
                                setIsLoading(false)
                            });
                }
            }else{
                toast.info("Please enter an amount to make payment");
                setIsLoading(false)
            }
        }else{
            let finalAmount:any = 0;
            let payType = ""
            if(paymentMethod === 1){
                finalAmount = parseFloat(cashAmount)
                payType = "Cash";
            }
            else if(paymentMethod === 2){
                finalAmount = parseFloat(checkAmount)
                payType = "Check";
            }
            else if(paymentMethod === 3){
                finalAmount = parseFloat(eCheckAmount)
                payType = "ECheck";
            }
            else if(paymentMethod === 4){
                finalAmount = parseFloat(creditCardAmount)
                payType = "Card";
            }
            else if(paymentMethod === 5){
                finalAmount = parseFloat(luckBucksAmount)
                payType = "Lucky";
            }
            if(finalAmount > 0){
                if(paymentMethod === 4 && cusCC === ""){
                    toast.info("Please select a card to proceed!");
                    setIsLoading(false)
                }else{

                    let tvp_payload = {
                        "paymentMethod": payType,
                        "isOrder": false,
                        "amountEntered": finalAmount,
                        "numEmployeeID": 1,
                        "varEmployeeName": "Ravin",
                        "numLuckyBucksID": (payType === "Lucky")? luckyBuck : null ,
                        "numPaymentLocationID": 1,
                        "numCustomerCreditCardID": (payType === "Card")? cusCC : null,
                        "charges": props.data
                      }
                      console.log("tvp-payload", tvp_payload)
                      if(finalAmount >= parseFloat(props?.data[0]?.remTotal)){
                        console.log("update payment")
                        }else{
                            console.log("nop")
                        }
                      axios.post(`${process.env.REACT_APP_API_URL}Payment/PopulateMakePayment`, tvp_payload)
                      .then(function (response) {
                        console.log("app_success", response.data);
                        if(response.status === 200){
                            toast.success("Payment successful!");
                            if(props.passedByInv !== true){
                                if(finalAmount >= parseFloat(props?.data[0]?.remTotal)){
                                    console.log("Update payment -status")
                                    completePayment()
                                }else{
                                    console.log("App-status not updated - payments are still pending")
                                    props.onSuccess(true)
                                }
                            }else{
                                if(finalAmount >= parseFloat(props?.data[0]?.remTotal)){
                                    console.log("Update payment -status")
                                    props.isSuccessFullPayment(true)
                                }else{
                                    console.log("App-status not updated - payments are still pending")
                                    props.onSuccess(true)
                                }
                            }
                            resetAll()
                            props.setIsOpenPaymentsModal(false)
                            props.clearSelected(true)
                            setIsLoading(false)
                        }
                      })
                        .catch(function (error) {
                            console.log(error);
                            setIsLoading(false)
                        });
                }

            }else{
                toast.info("Please enter an amount to make payment");
                setIsLoading(false)
            }
        }
    }

    async function updateStatus(data) {
        await axios.put(`${process.env.REACT_APP_API_URL}Appointment/UpdateAppointmentStatus`,
        JSON.stringify(data),
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',                
            },
        }).then((result) => {
            if (result.status === 200) {
                console.log("APPOINTMENT STATUS - UPDATED : PAYMENT COMPLETE")
                props.isSuccessFullPayment(true)
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
        })
    }


    const completePayment = () => {
        let payload = {
            "numAppointmentID": props.appointment?.numAppointmentID,
            "apptStatus": props?.appointment?.apptStatus,
            "isApptCheckedInStatus": props.appointment?.IsApptCheckedInStatus,
            "isServiceArea": props.appointment?.IsServiceArea,
            "isReadyForPickUp": props.appointment?.IsReadyForPickUp,
            "isPaymentMade": true,
            "isCheckedOut": props.appointment?.IsCheckedOut,
            "numEditedBy": 1
        }
        console.log("update-status", payload)
        updateStatus(payload)
    }

    useEffect(()=>{
        if(luckyBuck !== ""){
            let selectedBuck = luckyBuckList.findIndex(x => x.numLuckyBucksID === parseInt(luckyBuck));
            setLuckBucksAvailAmount(luckyBuckList[selectedBuck].numAvailablePriceValue)
        }
    },[luckyBuck])

    return (
        <div>
            <Modal open={props.isOpenPaymentsModal} >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '600px' }}>
                    <div style={{ display: "flex", justifyContent: 'space-between', backgroundColor: '#f3f4f6', margin: '-15px -15px 0 -15px', padding: '1rem', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                        <div>
                            <div style={{ height: "40px", display: "flex", alignItems: "center"}}>
                                <div style={{ color: "#002867", fontSize: "24", fontWeight: "600" }}>Payments</div>
                            </div>
                        </div>
                        <div style={{ color: "#F9FAFB", borderRadius: "8px" }}>
                            <div style={{ height: "40px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <CloseButton onClick={() => props.setIsOpenPaymentsModal(false)} />
                            </div>
                        </div>
                    </div>

                    <div style={{padding: '0 20px'}}>
                        <p style={{ color: "#002867", fontSize: "18", fontWeight: "600"}}><span style={{ color: "#002867", fontSize: "22", fontWeight: "600"}}>{type}: </span> {title}</p>
                    </div>                    

                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding: '0 20px'}}>
                        
                        <div style={{display:"flex", gap:"10px", justifyContent: 'space-between', width: '100%'}}>
                            <div style={{display:"flex", gap:"5px", backgroundColor: '#f3f4f6', borderRadius:"8px", padding:"10px", flex: '1', flexGrow: '1' }}>
                                <p style={{ color: "#002867", fontSize: "22", fontWeight: "600" }}>Total:</p>
                                <p style={{ color: "#002867", fontSize: "18", fontWeight: "600" }}>${parseFloat(total).toFixed(2)}</p>
                            </div>
                     
                            {
                                (hideBalance)? null 
                                :
                                <div style={{display:"flex", gap:"5px", backgroundColor: '#f3f4f6', borderRadius:"8px", padding:"10px", flex: '1', flexGrow: '1' }}>
                                <p style={{ color: "#002867", fontSize: "22", fontWeight: "600" }}>Balance:</p>
                                <p style={{ color: "#002867", fontSize: "18", fontWeight: "600" }}>${parseFloat(balanceAmount).toFixed(2)}</p>
                            </div>
                            }
                        </div>
                    </div>

                    <div style={{display:"flex", flexDirection:"column", gap:"10px", padding: '0 20px'}}>

                    <Grid container spacing={2} style={{alignItems:"center"}}>
                            <Grid item xs={4}>
                                <div>
                                    <p style={{ color: '#192252', fontSize: "14px", fontWeight: "600" }}>Payment Method</p>
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                            <div style={{ width: '100%'}}>
                                <AdvancedDropDown 
                                    data={payMethodList.map((i) => ({ label: i.type, value: i.id}))} 
                                    onChange={(e)=>{ setPaymentMethod(e?.value) }}
                                    ref={payMethodRef}
                                    placeHolder={"Select Payment Method"}
                                    passByID={true}
                                    value={paymentMethod}
                                />                                                                                            
                            </div>
                            </Grid>
                        </Grid>

                        {
                            (paymentMethod === 1)?
                                <Grid container spacing={2} style={{alignItems:"center"}}>
                                <Grid item xs={4}>
                                    <div>
                                        <p style={{ color: '#192252', fontSize: "14px", fontWeight: "600" }}>Cash</p>
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <div>
                                        {
                                            <TextField                                                
                                                style={{ width: '100%' }}
                                                label={
                                                    <div style={{ display: 'flex', fontSize: '14px' }}>
                                                        Amount
                                                    </div>
                                                }
                                                type={'number'}
                                                size='small'
                                                variant="outlined"
                                                multiline={false}
                                                onChange={(e)=>{setCashAmount(e.target.value)}}
                                                value={cashAmount}
                                            />
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            :
                            (paymentMethod === 2)?
                                <Grid container spacing={2} style={{alignItems:"center"}}>
                                <Grid item xs={4}>
                                    <div>
                                        <p style={{ color: '#192252', fontSize: "14px", fontWeight: "600" }}>Check</p>
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <div>
                                        {
                                            <TextField
                                                style={{ width: '100%' }}
                                                label={
                                                    <div style={{ display: 'flex', fontSize: '14px' }}>
                                                        Amount
                                                    </div>
                                                }
                                                type={'number'}
                                                size='small'
                                                variant="outlined"
                                                multiline={false}
                                                onChange={(e)=>{setCheckAmount(e.target.value)}}
                                                value={checkAmount}
                                            />
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            :
                            (paymentMethod === 3)?
                                <Grid container spacing={2} style={{alignItems:"center"}}>
                                <Grid item xs={4}>
                                    <div>
                                        <p style={{ color: '#192252', fontSize: "14px", fontWeight: "600" }}>E-Check</p>
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <div>
                                        {
                                            <TextField
                                                style={{ width: '100%' }}
                                                label={
                                                    <div style={{ display: 'flex', fontSize: '14px' }}>
                                                        Amount
                                                    </div>
                                                }
                                                type={'number'}
                                                size='small'
                                                variant="outlined"
                                                multiline={false}
                                                onChange={(e)=>{setEcheckAmount(e.target.value)}}
                                                value={eCheckAmount}
                                            />
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            :
                            (paymentMethod === 4)?
                                <Grid container spacing={2} style={{alignItems:"center"}}>
                                <Grid item xs={4}>
                                <div style={{ width: '100%'}}>
                                    <AdvancedDropDown 
                                        data={cusCCList.map((i) => ({ label: i.varCreditCardNo +", "+ (i.varCardType).toUpperCase(), value: i.numCustomerCreditCardID}))} 
                                        onChange={(e)=>{ setCusCC(e?.value) }}
                                        ref={CCRef}
                                        placeHolder={"Select Card"}
                                        passByID={true}
                                        value={cusCC}
                                    />                                                                                            
                                </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <div>
                                        {
                                            <TextField
                                                style={{ width: '100%' }}
                                                label={
                                                    <div style={{ display: 'flex', fontSize: '14px' }}>
                                                        Amount
                                                    </div>
                                                }
                                                type={'number'}
                                                size='small'
                                                variant="outlined"
                                                multiline={false}
                                                onChange={(e)=>{setCreditCardAmount(e.target.value)}}
                                                value={creditCardAmount}
                                            />
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            :
                            (paymentMethod === 5)?
                            <Grid container spacing={2} style={{alignItems:"center"}}>
                            <Grid item xs={4}>
                            <div style={{ width: '100%'}}>
                                <AdvancedDropDown 
                                    data={luckyBuckList.map((i) => ({ label: i.numLuckyBucksBarcodeID +", "+ i.varLuckyBucksNotes+", Amount: "+ i.numAvailablePriceValue, value: i.numLuckyBucksID}))} 
                                    onChange={(e)=>{ setLuckyBuck(e?.value)}}
                                    ref={luckyBuckRef}
                                    placeHolder={"Select Lucky Buck"}
                                    passByID={true}
                                    value={luckyBuck}
                                />                                                                                            
                            </div>
                            </Grid>
                            <Grid item xs={8}>
                                <div>
                                    {
                                        <TextField
                                            style={{ width: '100%' }}
                                            label={
                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                    Amount
                                                </div>
                                            }
                                            type={'number'}
                                            size='small'
                                            variant="outlined"
                                            multiline={false}
                                            onChange={(e)=>{setLuckBucksAmount(e.target.value)}}
                                            value={luckBucksAmount}
                                        />
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        : null
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0 10px 0' }}>                                
                            <Button color='primary' variant='contained' onClick={() => makePayment()} disabled={isLoading}>Complete Payment</Button>
                            {/* disabled={isEnablePay} */}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Payment;