/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/
import { Chip } from "../../components/Chip/Chip";

/*
    "varPetTypeName": "Dog",
    "varPetTypeDescription": null,
    "bitPetTypeActive": true
*/

export const ServiceGroup:{} = {
    tableName: "Service Group Library",
    hiddenColumns: ["numSCHServiceGroupID"],
    pdfTemplate: ["numSCHServiceGroupID", "varSCHServiceGroupName", "varSCHServiceGroupDescription", "varColour", "bitActive"],
    template: [
        {
            Header: 'Service Group Name',
            accessor: 'varSCHServiceGroupName'
        },
        {
            Header: 'Service Group Description',
            accessor: 'varSCHServiceGroupDescription'
        },
        {
            Header: 'Service Group Color',
            accessor: 'varColour',
            Cell: props => (
                <div style={{display:"flex", justifyContent:"center"}}>
                    <div style={{backgroundColor:`${props?.cell?.value}`, width:"25px", height:"20px", borderRadius:"10px"}}></div> 
                </div>
                           
            )      
        },
        {
            Header: 'Status',
            accessor: 'bitActive',
            Cell: props => (
                <Chip label={props?.cell?.value} size={'small'} />              
            )  
        }, 
        {
            Header: 'numSCHServiceGroupID',
            accessor: 'numSCHServiceGroupID',
            disableFilters: true
        } 

    ],
    postFields:[
        {
            fieldName: "numSCHServiceGroupID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0
        },
        {
            fieldName: "varSCHServiceGroupName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "varSCHServiceGroupDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value:""
        },
        {
            fieldName: "varColour",
            type: "colorPicker",
            inputType: "colorPicker",
            mainTable: "",
            required: true,
            value: ""
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true
        }
    ]
}
