/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "varPetTypeName": "Dog",
    "varPetTypeDescription": null,
    "bitPetTypeActive": true
*/

export const PetVet: {} = {
    tableName: "6.2 - Vets",
    hiddenColumns: ["numPetVetID", "varPetVetAddress2", "varPetVetCity", "varPetVetState", "varPetVetZip", "varPetVetNotes", "varPetVetFax"],
    pdfTemplate: ["varPetVetName", "varPetVetClinicName", "varPetVetContact", "varPetVetPhone1", "varPetVetPhone2", "petVetEmail", "varPetVetAddress1", "bitActive"],
    template: [
        {
            Header: "Name",
            accessor: "varPetVetName",
        },
        {
            Header: "Clinic Name",
            accessor: "varPetVetClinicName",
        },
        {
            Header: "Contact",
            accessor: "varPetVetContact",
        },
        {
            Header: "Phone 1",
            accessor: "varPetVetPhone1",
        },
        {
            Header: "Phone 2",
            accessor: "varPetVetPhone2",
        },
        {
            Header: "fax",
            accessor: "varPetVetFax",
        },
        {
            Header: "Email",
            accessor: "varPetVetEmail",
        },
        {
            Header: "Address",
            accessor: "varPetVetAddress1",
        },
        {
            Header: "Address2",
            accessor: "varPetVetAddress2",
            disableFilters: true,
        },
        {
            Header: "city",
            accessor: "varPetVetCity",
            disableFilters: true,
        },
        {
            Header: "state",
            accessor: "varPetVetState",
            disableFilters: true,
        },
        {
            Header: "zip",
            accessor: "varPetVetZip",
            disableFilters: true,
        },
        {
            Header: "notes",
            accessor: "varPetVetNotes",
            disableFilters: true,
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numPetVetID",
            accessor: "numPetVetID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numPetVetID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varPetVetName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetClinicName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetContact",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: null,
        },
        {
            fieldName: "varPetVetPhone1",
            type: "tel",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetPhone2",
            type: "tel",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetFax",
            type: "tel",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetEmail",
            type: "text",
            inputType: "email",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetAddress1",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetAddress2",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetCity",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetState",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetZip",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPetVetNotes",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
