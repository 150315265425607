const Unauthorized = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100vh", justifyContent: "center" }}>
            <img src={require("./crybaby.png")}></img>
            <h1>Unauthorized</h1>
            <p style={{ fontSize: "20px", fontWeight: 600, marginBottom: "20px" }}>You don't have access to this page</p>

            <a href="/">Take me home</a>
        </div>
    )
}

export default Unauthorized
