

export const HelpLinks = [
  {
    title: "General",
    to: "general",
    navId: "1",
    subNav: [
      {
        title: "Basics to the System",
        to: "general1",
        
      },
      {
        title: "Making a Schedule",
        to: "general2",
        
        
      },
    ],
  },
  {
    title: "Customer",
    to: "customer",
    navId: "2",
    subNav: [
      {
        title: "Create Customer Profile",
        to: "Customer1",
        
      },
      {
        title: "Add a Pet",
        to: "Customer2",
        
      },
      {
        title: "Edit Existing Customer",
        to: "Customer3",
        
      },
      {
        title: "Deactivate a Customer",
        to: "Customer4",
        
      },
    ],
  },
  {
    title: "Administrator",
    to: "administrator",
    navId: "3",
    subNav: [
      {
        title: "Label Configuration",
        to: "administrator1",
        
      },
      {
        title: "Import / Export Data",
        to: "administrator2",
        
      },
      {
        title: "Library",
        to: "administrator3",
        
      },
      {
        title: "User Roles",
        to: "administrator4",
        
      },
      {
        title: "Scheduler",
        to: "administrator5",
        
      },
    ],
  },
  {
    title: "Locations",
    to: "location",
    navId: "4",
    subNav: [
      {
        title: "Location 1",
        to: "location1",
        
      },
      {
        title: "Location 2",
        to: "location2",
        
      },
      {
        title: "Location 3",
        to: "location3",
        
      },
      {
        title: "Location 4",
        to: "location4",
        
      },
    ],
  },
  {
    title: "Call Center ",
    to: "directory",
    navId: "5",

    subNav: [
      {
        title: "Customer Directory",
        to: "directory1",
        
      },
      {
        title: "Pet Directory",
        to: "directory2",
        
      },
      {
        title: "Salon Directory",
        to: "directory3",
        
      },
      {
        title: "Employee Directory",
        to: "directory4",
        
      },
      {
        title: "Booking Inquiry",
        to: "directory5",
        
      },
    ],
  },
];
