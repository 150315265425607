import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import MobileStepper from "@mui/material/MobileStepper"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { useEffect, useState } from "react"
import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import ViewListIcon from "@mui/icons-material/ViewList"
import ViewModuleIcon from "@mui/icons-material/ViewModule"
import { axiosPrivate } from "../../apis/axios"

const StepperNotes = (props: { noteData?; type? }) => {
    console.log("noteData", props.noteData, props?.type)
    const [firstTime, setFirstTime] = useState<any>("")
    const [serviceType, setServiceType] = useState<any>("")

    const [headComb, setHeadComb] = useState<any>("")
    const [headBlade, setHeadBlade] = useState<any>("")
    const [headStyle, setHeadStyle] = useState<any>("")
    const [headScissor, setHeadScissor] = useState(false)

    const [faceComb, setFaceComb] = useState<any>("")
    const [faceBlade, setFaceBlade] = useState<any>("")
    const [faceStyle, setFaceStyle] = useState<any>("")
    const [faceScissor, setFaceScissor] = useState(false)

    const [earsComb, setEarsComb] = useState<any>("")
    const [earsBlade, setEarsBlade] = useState<any>("")
    const [earsStyle, setEarsStyle] = useState<any>("")
    const [earsScissor, setEarsScissor] = useState(false)

    const [bodyComb, setBodyComb] = useState<any>("")
    const [bodyBlade, setBodyBlade] = useState<any>("")
    const [bodyStyle, setBodyStyle] = useState<any>("")
    const [bodyScissor, setBodyScissor] = useState(false)

    const [legsComb, setLegsComb] = useState<any>("")
    const [legsBlade, setLegsBlade] = useState<any>("")
    const [legsStyle, setLegsStyle] = useState<any>("")
    const [legsScissor, setLegsScissor] = useState(false)

    const [tailComb, setTailComb] = useState<any>("")
    const [tailBlade, setTailBlade] = useState<any>("")
    const [tailStyle, setTailStyle] = useState<any>("")
    const [tailScissor, setTailScissor] = useState(false)

    const [feetComb, setFeetComb] = useState<any>("")
    const [feetBlade, setFeetBlade] = useState<any>("")
    const [feetStyle, setFeetStyle] = useState<any>("")
    const [feetScissor, setFeetScissor] = useState(false)

    const [isDifficult, setIsDifficult] = useState(false) as any
    const [shampoo, setShampoo] = useState<any>("")
    const [comment, setComment] = useState<any>("")

    const [puppyCut, setPuppyCut] = useState(false)
    const [standerdCut, setStanderdCut] = useState(false)
    const [handStrip, setHandStrip] = useState(false)
    const [shaveDown, setShaveDown] = useState(false)

    const [combList, setCombList] = useState([]) as any
    const [bladeList, setBladeList] = useState([]) as any
    const [styleList, setStyleList] = useState([]) as any
    const [shampooList, setShampooList] = useState([]) as any

    const handleChangeActiveStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstTime((event.target as HTMLInputElement).value)
    }

    const handleServiceType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceType((event.target as HTMLInputElement).value)
    }

    const handleHeadComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHeadComb((event.target as HTMLInputElement).value)
    }

    const handleHeadBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHeadBlade((event.target as HTMLInputElement).value)
    }

    const handleHeadStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHeadStyle((event.target as HTMLInputElement).value)
    }

    const handleFaceComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFaceComb((event.target as HTMLInputElement).value)
    }

    const handleFaceBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFaceBlade((event.target as HTMLInputElement).value)
    }

    const handleFaceStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFaceStyle((event.target as HTMLInputElement).value)
    }

    const handleEarsComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEarsComb((event.target as HTMLInputElement).value)
    }

    const handleEarsBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEarsBlade((event.target as HTMLInputElement).value)
    }

    const handleEarsStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEarsStyle((event.target as HTMLInputElement).value)
    }

    const handleBodyComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBodyComb((event.target as HTMLInputElement).value)
    }

    const handleBodyBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBodyBlade((event.target as HTMLInputElement).value)
    }

    const handleBodyStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBodyStyle((event.target as HTMLInputElement).value)
    }

    const handleLegsComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLegsComb((event.target as HTMLInputElement).value)
    }

    const handleLegsBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLegsBlade((event.target as HTMLInputElement).value)
    }

    const handleLegsStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLegsStyle((event.target as HTMLInputElement).value)
    }

    const handleTailComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTailComb((event.target as HTMLInputElement).value)
    }

    const handleTailBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTailBlade((event.target as HTMLInputElement).value)
    }

    const handleTailStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTailStyle((event.target as HTMLInputElement).value)
    }

    const handleFeetComb = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeetComb((event.target as HTMLInputElement).value)
    }

    const handleFeetBlade = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeetBlade((event.target as HTMLInputElement).value)
    }

    const handleFeetStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeetStyle((event.target as HTMLInputElement).value)
    }

    const handleChangeIsDifficult = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDifficult((event.target as HTMLInputElement).value)
    }

    const handleShampoo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShampoo((event.target as HTMLInputElement).value)
    }

    async function getData(objectName) {
        const result = await axiosPrivate.get(`${objectName}/GetAll`)
        return result.data
    }

    useEffect(() => {
        if (props.type === "copy") {
            setBodyScissor(props?.noteData?.bitBodyScissorUsed)
            setIsDifficult(props?.noteData?.bitDifficultDrying)
            setEarsScissor(props?.noteData?.bitEarScissorUsed)
            setFaceScissor(props?.noteData?.bitFaceScissorUsed)
            setFeetScissor(props?.noteData?.bitFeetScissorUsed)
            setFirstTime(props?.noteData?.bitFirstGroom)
            setStanderdCut(props?.noteData?.bitFullGoomBreedStandardCut)
            setHandStrip(props?.noteData?.bitFullGroomHandStrip)
            setPuppyCut(props?.noteData?.bitFullGroomPuppyCut)
            setShaveDown(props?.noteData?.bitFullGroomShaveDown)
            setHeadScissor(props?.noteData?.bitHeadScissorUsed)
            setLegsScissor(props?.noteData?.bitLegScissorUsed)
            setTailScissor(props?.noteData?.bitTailScissorUsed)
            setShampoo(props?.noteData?.numRecommendedShampooID)
            setComment(props?.noteData?.varGroomerComment)
            setServiceType(props?.noteData?.varServiceType)
            // setLegsStyle()

            setBodyBlade(props?.noteData?.blades?.bodyBlade?.numBladeID)
            setEarsBlade(props?.noteData?.blades?.earBlade?.numBladeID)
            setFaceBlade(props?.noteData?.blades?.faceBlade?.numBladeID)
            setFeetBlade(props?.noteData?.blades?.feetBlade?.numBladeID)
            setHeadBlade(props?.noteData?.blades?.headBlade?.numBladeID)
            setLegsBlade(props?.noteData?.blades?.legBlade?.numBladeID)
            setTailBlade(props?.noteData?.blades?.tailBlade?.numBladeID)

            setEarsComb(props?.noteData?.combs?.earComb?.numCombID)
            setFaceComb(props?.noteData?.combs?.faceComb?.numCombID)
            setFeetComb(props?.noteData?.combs?.feetComb?.numCombID)
            setHeadComb(props?.noteData?.combs?.headComb?.numCombID)
            setTailComb(props?.noteData?.combs?.tailComb?.numCombID)

            setEarsStyle(props?.noteData?.styles?.earStyle?.numStyleID)
            setFaceStyle(props?.noteData?.styles?.faceStyle?.numStyleID)
            setFeetStyle(props?.noteData?.styles?.feetStyle?.numStyleID)
            setHeadStyle(props?.noteData?.styles?.headStyle?.numStyleID)
            setTailStyle(props?.noteData?.styles?.tailStyle?.numStyleID)
        }
    }, [props.type])

    useEffect(() => {
        getData("Comb")
            .then((res) => {
                console.log("getdata", res)
                setCombList(res?.comb)
            })
            .catch((err) => {
                console.log("errr", err)
            })
        getData("Blade")
            .then((res) => {
                console.log("getdata", res)
                setBladeList(res?.blade)
            })
            .catch((err) => {
                console.log("errr", err)
            })
        getData("Style")
            .then((res) => {
                console.log("getdata", res)
                setStyleList(res?.style)
            })
            .catch((err) => {
                console.log("errr", err)
            })
        getData("Shampoo")
            .then((res) => {
                console.log("getdataShampoo", res)
                setShampooList(res?.shampoo)
            })
            .catch((err) => {
                console.log("errr", err)
            })
    }, [])

    const steps = [
        {
            label: "1. First groom at Lucky Paws",
            description: (
                <>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={firstTime}
                        onChange={handleChangeActiveStatus}
                        style={{ flexDirection: "column" }}
                    >
                        <FormControlLabel value={true} control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} />} label={<p style={{ fontSize: "15px" }}>Yes</p>} />
                        <FormControlLabel value={false} control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} />} label={<p style={{ fontSize: "15px" }}>No</p>} />
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "2. Service Type",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={serviceType}
                            onChange={handleServiceType}
                            style={{ flexDirection: "column" }}
                        >
                            <FormControlLabel
                                value={"Bath"}
                                control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                label={<p style={{ fontSize: "15px" }}>Bath Only</p>}
                            />
                            <FormControlLabel
                                value={"Full"}
                                control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                label={<p style={{ fontSize: "15px" }}>Full Groom</p>}
                            />
                            <FormControlLabel
                                value={"Mini"}
                                control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                label={<p style={{ fontSize: "15px" }}>Mini Groom</p>}
                            />
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "3. Full groom Type",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setPuppyCut(!puppyCut)} checked={puppyCut} />
                        <p style={{ fontSize: "15px" }}>Puppy Cut ( One Length All Over)</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setStanderdCut(!standerdCut)} checked={standerdCut} />
                        <p style={{ fontSize: "15px" }}>Breed Standard Cut</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setHandStrip(!handStrip)} checked={handStrip} />
                        <p style={{ fontSize: "15px" }}>Hand Strip</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setShaveDown(!shaveDown)} checked={shaveDown} />
                        <p style={{ fontSize: "15px" }}>Shave Down</p>
                    </div>
                </>
            ),
        },
        {
            label: "4.1 HEAD - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={headComb}
                            onChange={handleHeadComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "4.2 HEAD - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={headBlade}
                            onChange={handleHeadBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "4.3 HEAD - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={headStyle}
                            onChange={handleHeadStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "4.4 HEAD - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setHeadScissor(!headScissor)} checked={headScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
        },
        {
            label: "5.1 FACE - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={faceComb}
                            onChange={handleFaceComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "5.2 FACE - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={faceBlade}
                            onChange={handleFaceBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "5.3 FACE - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={faceStyle}
                            onChange={handleFaceStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "5.4 FACE - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setFaceScissor(!faceScissor)} checked={faceScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
        },
        {
            label: "6.1 EARS - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={earsComb}
                            onChange={handleEarsComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "6.2 EARS - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={earsBlade}
                            onChange={handleEarsBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "6.3 EARS - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={earsStyle}
                            onChange={handleEarsStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "6.4 EARS - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setEarsScissor(!earsScissor)} checked={earsScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
        },
        {
            label: "7.1 BODY - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={bodyComb}
                            onChange={handleBodyComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "7.2 BODY - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={bodyBlade}
                            onChange={handleBodyBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "7.3 BODY - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={bodyStyle}
                            onChange={handleBodyStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "7.4 BODY - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setBodyScissor(!bodyScissor)} checked={bodyScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
        },
        {
            label: "8.1 LEGS - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={legsComb}
                            onChange={handleLegsComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "8.2 LEGS - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={legsBlade}
                            onChange={handleLegsBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "8.3 LEGS - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={legsStyle}
                            onChange={handleLegsStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "8.4 LEGS - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setLegsScissor(!legsScissor)} checked={legsScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
        },
        {
            label: "9.1 TAIL - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={tailComb}
                            onChange={handleTailComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "9.2 TAIL - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={tailBlade}
                            onChange={handleTailBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "9.3 TAIL - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={tailStyle}
                            onChange={handleTailStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "9.4 TAIL - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setTailScissor(!tailScissor)} checked={tailScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
        },
        {
            label: "10.1 FEET - Comb",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={feetComb}
                            onChange={handleFeetComb}
                            style={{ flexDirection: "column" }}
                        >
                            {combList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numCombID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varCombName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "10.2 FEET - Blade",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={feetBlade}
                            onChange={handleFeetBlade}
                            style={{ flexDirection: "column" }}
                        >
                            {bladeList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numBladeID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varBladeName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "10.3 FEET - Style",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={feetStyle}
                            onChange={handleFeetStyle}
                            style={{ flexDirection: "column" }}
                        >
                            {styleList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numStyleID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varStyleName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "10.4 FEET - I used a Scissor",
            description: (
                <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ padding: "0 5px 0 0", fontSize: "100px" }} color="primary" onChange={(e) => setFeetScissor(!feetScissor)} checked={feetScissor} />
                        <p style={{ fontSize: "15px" }}>Yes</p>
                    </div>
                </>
            ),
        },
        {
            label: "11. Difficult to dry?",
            description: (
                <>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={isDifficult}
                        onChange={handleChangeIsDifficult}
                        style={{ flexDirection: "column" }}
                    >
                        <FormControlLabel value={true} control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} />} label={<p style={{ fontSize: "15px" }}>Yes</p>} />
                        <FormControlLabel value={false} control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} />} label={<p style={{ fontSize: "15px" }}>No</p>} />
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "12. Recommendation for next groom",
            description: (
                <>
                    <RadioGroup aria-labelledby="type" defaultValue="yes" name="type">
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={shampoo}
                            onChange={handleShampoo}
                            style={{ flexDirection: "column" }}
                        >
                            {shampooList?.map((radioList, index) => (
                                <FormControlLabel
                                    value={radioList?.numShampooID}
                                    control={<Radio style={{ padding: "0 5px 0 0", fontSize: "100px" }} size={"small"} />}
                                    label={
                                        <p style={{ fontSize: "15px" }}>
                                            {index + 1} -- {radioList?.varShampooName}
                                        </p>
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </RadioGroup>
                </>
            ),
        },
        {
            label: "Overall Groomer Comments",
            description: (
                <TextField
                    label="Add your suggestions here."
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value)
                    }}
                />
            ),
        },
    ]

    const [activeStep, setActiveStep] = useState(0)
    const [singleStepMode, setSingleStepMode] = useState(true)
    const maxSteps = steps.length

    useEffect(() => {
        console.log("activetab", activeStep)
    }, [activeStep])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    const toggleMode = () => {
        setSingleStepMode((prevMode) => !prevMode)
    }

    return (
        <>
            <div style={{ textAlign: "end" }}>
                <Button onClick={toggleMode} variant="outlined">
                    {singleStepMode ? <ViewListIcon /> : <ViewModuleIcon />}
                </Button>
            </div>
            <Box sx={{ width: "100%", flexGrow: 1 }}>
                {singleStepMode ? (
                    <>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: 50,
                                pl: 2,
                                bgcolor: "background.default",
                            }}
                        >
                            <Typography style={{ fontWeight: "600" }}>{steps[activeStep].label}</Typography>
                        </Paper>
                        <Box sx={{ height: "300px", width: "100%", p: 2, overflowX: "scroll" }}>{steps[activeStep].description}</Box>
                        <MobileStepper
                            variant="progress"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                    Next
                                    <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    <KeyboardArrowLeft />
                                    Back
                                </Button>
                            }
                        />
                    </>
                ) : (
                    steps.map((step, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Paper
                                square
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: 50,
                                    pl: 2,
                                    bgcolor: "background.default",
                                }}
                            >
                                <Typography style={{ fontWeight: "600" }}>{step.label}</Typography>
                            </Paper>
                            <Box sx={{ height: "auto", width: "100%", p: 2 }}>{step.description}</Box>
                        </Box>
                    ))
                )}
            </Box>
        </>
    )
}

export default StepperNotes
