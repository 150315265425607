export const validatePermission = (auth, allowedModules: string[], allowedSubRoutes?: string[]) => {
    const subRoutes = allowedSubRoutes?.length ?? 0

    if (subRoutes > 0) {
        let checkIfIncludes = Object.keys(auth?.permissions[allowedModules[0]])?.find((role: any) => allowedSubRoutes?.includes(role))
        if (checkIfIncludes) {
            return auth?.permissions[allowedModules[0]][checkIfIncludes]?.IsView ? true : false
        } else {
            return false
        }
    } else {
        if (allowedModules?.length > 0) {
            return Object.keys(auth?.permissions)?.find((role: any) => allowedModules?.includes(role)) ? true : false
        } else {
            return false
        }
    }
}
