/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { Chip } from "../../components/Chip/Chip"

/*
    "varPetTypeName": "Dog",
    "varPetTypeDescription": null,
    "bitPetTypeActive": true
*/

export const salesItemsLibrary: {} = {
    tableName: "5.1 - Sales Items Library",
    hiddenColumns: [
        "numProductID",
        "numLocationProductID",
        "btSalesCommissionPercent",
        "numSalesCommissionPercent",
        "numSalesCommissionAmount",
        "dtExpiryDate",
        "dtEffectiveDate",
        "numSalesPrice",
        "numPurchasePrice",
        "btSalesCommissionAmount",
    ],
    pdfTemplate: [
        "varProductName",
        "varProductDescription",
        "numPurchasePrice",
        "numSalesPrice",
        "varProductVendorID",
        "varProductBarCode",
        "dtEffectiveDate",
        "dtExpiryDate",
        "varLocation",
        "bitActive",
    ],
    template: [
        {
            Header: "Vendor ID",
            accessor: "varProductVendorID",
        },
        {
            Header: "BarCode",
            accessor: "varProductBarCode",
        },
        {
            Header: "ProductName",
            accessor: "varProductName",
        },
        {
            Header: "Description",
            accessor: "varProductDescription",
        },
        {
            Header: "Commission Percentage",
            accessor: "numSalesCommissionPercent",
        },
        {
            Header: "Commission Amount",
            accessor: "numSalesCommissionAmount",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numPurchasePrice",
            accessor: "numPurchasePrice",
        },
        {
            Header: "numSalesPrice",
            accessor: "numSalesPrice",
        },
        {
            Header: "btSalesCommissionPercent",
            accessor: "btSalesCommissionPercent",
        },
        {
            Header: "btSalesCommissionAmount",
            accessor: "btSalesCommissionAmount",
        },
        {
            Header: "dtExpiryDate",
            accessor: "dtExpiryDate",
        },
        {
            Header: "dtEffectiveDate",
            accessor: "dtEffectiveDate",
        },
        {
            Header: "numProductID",
            accessor: "numProductID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numProductID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varProductVendorID",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varProductBarCode",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varProductName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varProductDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numPurchasePrice",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numSalesPrice",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "btSalesCommissionPercent",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numSalesCommissionPercent",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "btSalesCommissionAmount",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numSalesCommissionAmount",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "dtEffectiveDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "dtExpiryDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
