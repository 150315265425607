/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "varPetTypeName": "Dog",
    "varPetTypeDescription": null,
    "bitPetTypeActive": true
*/

export const UserQuestion: {} = {
    tableName: "2.5 - User Questions",
    hiddenColumns: ["numUserQuestionID"],
    pdfTemplate: ["varQuestion", "bitActive"],
    template: [
        {
            Header: "Question",
            accessor: "varQuestion",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numUserQuestionID",
            accessor: "numUserQuestionID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numUserQuestionID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varQuestion",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
