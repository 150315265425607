export const generateSince = (CreatedDate) => {
    const d = new Date(CreatedDate)
    const today = new Date()

    let month = d.toLocaleString('default', { month: 'short' })
    let year = d.getFullYear()

    let difference = calcDate(today, d)

    return `${month} ${year} ${(difference.result === "") ? "" : "(" + difference.result + ")"}`
}

export const generateAge = (CreatedDate) => {
    const dd = new Date(CreatedDate)
    const today = new Date()
    let difference = calcDate(today, dd)
    let seconds = Number(difference.total_seconds);
    let y = Math.floor(seconds / 31536000);
    let mo = Math.floor((seconds % 31536000) / 2628000);
    let yDisplay = y > 0 ? y + (y === 1 ? "y" : "y") : "";
    let moDisplay = mo > 0 ? mo + (mo === 1 ? "m" : "m") : "";
    return yDisplay +" - "+ moDisplay ;
}

export const generateYear = (CreatedDate) => {
    const dd = new Date(CreatedDate)
    const today = new Date()
    let difference = calcDate(today, dd)
    let seconds = Number(difference.total_seconds);
    let y = Math.floor(seconds / 31536000);
    let yDisplay = y > 0 ? y + (y === 1 ? "y" : "y") : "";
    return yDisplay;
}

export const generateYearAge = (CreatedDate) => {
    const dd = new Date(CreatedDate)
    const today = new Date()
     let month =     today.getMonth() - dd.getMonth() + 12 * (today.getFullYear() - dd.getFullYear())
    return  month ;
}


const calcDate = (date1, date2) => {
    //new date instance
    const dt_date1 = new Date(date1);
    const dt_date2 = new Date(date2);

    //Get the Timestamp
    const date1_time_stamp = dt_date1.getTime();
    const date2_time_stamp = dt_date2.getTime();

    let calc;

    //Check which timestamp is greater
    if (date1_time_stamp > date2_time_stamp) {
        calc = new Date(date1_time_stamp - date2_time_stamp);
    } else {
        calc = new Date(date2_time_stamp - date1_time_stamp);
    }

    //Retrieve the date, month and year
    const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();

    //Convert to an array and store
    const calcFormat:any = calcFormatTmp.split("-");

    //Subtract each member of our array from the default date
    const days_passed = Number(Math.abs(calcFormat[0]) - 1);
    const months_passed = Number(Math.abs(calcFormat[1]) - 1);
    const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

    //Set up custom text
    const yrsTxt = ["year", "years"];
    const mnthsTxt = ["month", "months"];
    const daysTxt = ["day", "days"];

    //Convert to days and sum together
    const total_days = (years_passed * 365) + (months_passed * 30.417) + days_passed;
    const total_secs = total_days * 24 * 60 * 60;
    const total_mins = total_days * 24 * 60;
    const total_hours = total_days * 24;
    const total_weeks = total_days / 7;

    //display result with custom text
    const result = ((years_passed == 1) ? years_passed + ' ' + yrsTxt[0] + ' ' : (years_passed > 1) ?
        years_passed + ' ' + yrsTxt[1] + ' ' : '') +
        ((months_passed == 1) ? months_passed + ' ' + mnthsTxt[0] : (months_passed > 1) ?
            months_passed + ' ' + mnthsTxt[1] + ' ' : '') +
        ((days_passed == 1) ? days_passed + ' ' + daysTxt[0] : (days_passed > 1) ?
            days_passed + ' ' + daysTxt[1] : '');

    //return the result
    return {
        "total_days": Math.round(total_days),
        "total_weeks": Math.round(total_weeks),
        "total_hours" : Math.round(total_hours),
        "total_minutes" : Math.round(total_mins),
        "total_seconds": Math.round(total_secs),
        "result": result.trim()
    }
}