/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel";
import { OpenInNew } from '@mui/icons-material'
import { Button } from "../../../components";
import { Chip } from "../../components/Chip/Chip";
import { formatPhone } from "../../../../utils/PhoneFormatter";
import { Chip as LocationChip } from "../../../../components/";


export const Customer = {
    tableName: "Customers",
    hiddenColumns: ["varCustomerLastName", "varCustomerZip", "dtCreatedDate", "varCustomerImagePath"],
    pdfTemplate: ['varCustomerFirstName','varCustomerLastName','varCustomerCity', 'varCustomerZip', 'varCustomerState', 'varCustomerMobilePhone', 'varCustomerHomePhone', 'varCustomerDefaultLocation', 'bitActive'],
    hideSort: true,
    template: [
        {
            Header: 'Customer',
            accessor: 'varCustomerFirstName',  
            width: 10,
            disableSortBy: false,
            Cell: props => (
                <AvatarLabel 
                    isVip={props.row.original.bitIsVip} 
                    img={(props.row.original.varCustomerImagePath == null || props.row.original.varCustomerImagePath == "")? "" : `${process.env.REACT_APP_MEDIA_URL}directories/customers/` + props.row.original.varCustomerImagePath} 
                    name={props.row.original.varCustomerFirstName + " " + props.row.original.varCustomerLastName} 
                />        
            )            
        },
        {
            Header: 'varCustomerLastName',
            accessor: 'varCustomerLastName'
        },       
        {
            Header: 'City',
            accessor: 'varCustomerCity'
        },
        // {
        //     Header: 'Priority',
        //     accessor: 'bitIsVip',
        //     disableFilters: true,
        //     Cell: props => (
        //         <LocationChip 
        //         label={(props?.cell?.value == true)? "VIP" : "Non-VIP"}
        //         shape="round"
        //         fontColor={"#002867"}
        //         customStyle="BlueOutlined"
        //         fontWeight={600}
        //         fontSize={"12px"}
        //     />         
        //     )  
            
        // },
        {
            Header: 'Zip',
            accessor: 'varCustomerZip'
        },
        {
            Header: 'State',
            accessor: 'varCustomerState'
        },
        {
            Header: 'Mobile',
            accessor: 'varCustomerMobilePhone',
            Cell: props => (
                <p style={{ fontSize: '14px' }}>{formatPhone(props?.cell?.value)}</p>              
            )   
        },
        {
            Header: 'Home',
            accessor: 'varCustomerHomePhone',
            Cell: props => (
                <p style={{ fontSize: '14px' }}>{formatPhone(props?.cell?.value)}</p>              
            )   
        },  
        {
            Header: 'Default Location',
            accessor: 'varLocationName'
        }, 
        {
            Header: 'Status',
            accessor: 'bitActive',
            Cell: props => (
                <LocationChip 
                label={(props?.cell?.value == true)? "Active" : "Inactive"}
                shape="round"
                fontColor={"#14532D"}
                customStyle={(props?.cell?.value == true)? "Success" : "Error"}
                fontWeight={400}
                fontSize={"12px"}
            />             
            )      
        },
        {
            Header: 'dtCreatedDate',
            accessor: 'dtCreatedDate'
        },
        {
            Header: 'img',
            accessor: 'varCustomerImagePath',
            disableFilters: true,
        },
         
        {
            Header: '',
            accessor: 'numCustomerID',
            disableFilters: true,
            Cell: props => (
                <Button color="transparent" variant="text" onClick={() => console.log(props.row.original.numCustomerID)}><OpenInNew style={{ color: '#005df1' }}/></Button>                
            )      
        }
    ]
}
