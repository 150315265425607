import { useState } from "react"
import { ImportExportHandler } from "../../components/components"

import BulkImageUpload from "./BulkImageUpload/BulkImageUpload"
import UploadToggler from "./BulkImageUpload/UploadToggler/UploadToggler"

import styles from "./ImportExport.module.scss"

const ImportExport = () => {
    const [bulkUpload, setBulkUpload] = useState(false)
    const [bulkUploadType, setBulkUploadType] = useState("")

    return (
        <div>
            {bulkUpload ? (
                <BulkImageUpload goBack={(value) => setBulkUpload(value)} type={bulkUploadType} />
            ) : (
                <div style={{ margin: "0.8rem 4rem" }}>
                    <div style={{ marginTop: "1.5rem", backgroundColor: "#fff", padding: "1.5rem", boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)", borderRadius: "4px" }}>
                        <p style={{ margin: "0 0 15px 0", fontWeight: 600, fontSize: "15px" }}>Salon</p>

                        <ImportExportHandler
                            objectName={"Location"}
                            requestType="ImportExport"
                            uploadComponent={
                                <UploadToggler
                                    onRedirect={(evt) => {
                                        {
                                            setBulkUpload(evt)
                                        }
                                        {
                                            setBulkUploadType("Location")
                                        }
                                    }}
                                />
                            }
                        />
                    </div>
                    <div style={{ marginTop: "1.5rem", backgroundColor: "#fff", padding: "1.5rem", boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)", borderRadius: "4px" }}>
                        <p style={{ margin: "0 0 15px 0", fontWeight: 600, fontSize: "15px" }}>Customer</p>

                        <ImportExportHandler
                            objectName={"Customer"}
                            requestType="ImportExport"
                            uploadComponent={
                                <UploadToggler
                                    onRedirect={(evt) => {
                                        {
                                            setBulkUpload(evt)
                                        }
                                        {
                                            setBulkUploadType("Customer")
                                        }
                                    }}
                                />
                            }
                        />
                    </div>

                    <div style={{ marginTop: "1.5rem", backgroundColor: "#fff", padding: "1.5rem", boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)", borderRadius: "4px" }}>
                        <p style={{ margin: "0 0 15px 0", fontWeight: 600, fontSize: "15px" }}>Pet</p>

                        <ImportExportHandler
                            objectName={"Pet"}
                            requestType="ImportExport"
                            uploadComponent={
                                <UploadToggler
                                    onRedirect={(evt) => {
                                        {
                                            setBulkUpload(evt)
                                        }
                                        {
                                            setBulkUploadType("Pet")
                                        }
                                    }}
                                />
                            }
                        />
                    </div>

                    <div style={{ marginTop: "1.5rem", backgroundColor: "#fff", padding: "1.5rem", boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.1)", borderRadius: "4px" }}>
                        <p style={{ margin: "0 0 15px 0", fontWeight: 600, fontSize: "15px" }}>Employee</p>

                        <ImportExportHandler
                            objectName={"Employee"}
                            requestType="ImportExport"
                            uploadComponent={
                                <UploadToggler
                                    onRedirect={(evt) => {
                                        {
                                            setBulkUpload(evt)
                                        }
                                        {
                                            setBulkUploadType("Employee")
                                        }
                                    }}
                                />
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ImportExport
