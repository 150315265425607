import React, { useEffect, useRef, useState } from "react"
import AdvancedDropDown from "../../../../components/AdvancedDropDown/AdvancedDropDown"
import { axiosPrivate } from "../../../../apis/axios"
import { toast } from "react-toastify"
import { Checkbox, IconButton, TextField, Tooltip } from "@mui/material"
import { Avatar, Button } from "../../../../components"
import { Close, Edit, InfoOutlined } from "@mui/icons-material"
import CloseButton from "../../../../components/Modal/CloseButton/CloseButton"
import { Modal } from "../../../../components/components"
import ColorPicker from "../../Scheduler/Tabs/EventTab/ColorPicker/ColorPicker"
import styles from "./RolesUsers.module.scss"

const RolesUsers = (props) => {
    const [locationList, setLocationList] = useState([]) as any
    const locationRef = useRef()

    const [location, setLocation] = useState(0)

    const [roleGroupName, setRoleGroupName] = useState("")
    const [roleGroupDescription, setRoleGroupDescription] = useState("")
    const [eventColor, setEventColor] = useState("")

    const [roleName, setRoleName] = useState("")
    const [roleDescription, setRoleDescription] = useState("")

    const [numLocationID, setNumLocationID] = useState(0)

    const [showNewRoleGroup, setShowNewRoleGroup] = useState(false)
    const [showNewRole, setShowNewRole] = useState(false)

    const [roleGroupList, setRoleGroupList] = useState([]) as any
    const [rolesList, setRolesList] = useState([]) as any

    const [moduleList, setModuleList] = useState([]) as any
    const [moduleSettingList, setModuleSettingList] = useState([]) as any

    const [moduleName, setModuleName] = useState("")
    const [moduleDescription, setModuleDescription] = useState("")

    const [showNewSystemModule, setShowNewSystemModule] = useState(false)

    const [selectedRoleGroup, setSelectedRoleGroup] = useState({}) as any
    const [selectedRole, setSelectedRole] = useState({}) as any
    const [selectedModule, setSelectedModule] = useState({}) as any

    async function getLocations() {
        console.log("getLocations")

        const result = await axiosPrivate.get(`Location/GetAll`, {})
        return result.data.location
    }

    const addNewRoleGroup = () => {
        createNewRoleGroup()
        console.log("new role group")
    }

    const addNewRole = () => {
        createNewRole()
        console.log("new role")
    }

    async function getRoleGroups(location) {
        const result = await axiosPrivate.get(`User/GetAllUserRoleGroups`)
        return result
    }

    async function getSettingModuleList(roleModuleMappingID, moduleID) {
        const result = await axiosPrivate.get(`User/GetAllpermissionByRoleModuleMappingIDAndModuleID?RoleModuleMappingID=${roleModuleMappingID}&ModuleID=${moduleID}`)
        return result
    }

    async function getRolesList(group_id) {
        const result = await axiosPrivate.get(`User/GetAllUserRolesByGroupID?RoleGroupID=${group_id}`)
        return result
    }

    const getRoles = (location) => {
        getRoleGroups(location)
            .then((response) => {
                setRoleGroupList(response.data)
                setFilteredItemsRoleGroups(response.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const getAllRoles = (group_id) => {
        getRolesList(group_id)
            .then((response) => {
                setRolesList(response.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const getAllSettings = (moduleMapping, moduleID) => {
        if (moduleMapping != null) {
            getSettingModuleList(moduleMapping, moduleID)
                .then((response) => {
                    setModuleSettingList(response.data)
                    console.log(response.data)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        }
    }

    const sortByProperty = (arr, prop) => {
        return arr.sort((a, b) => a[prop].localeCompare(b[prop]))
    }

    async function createNewRoleGroup() {
        let payloadData = {
            numUserRoleGroupID: 0,
            varUserRoleGroupName: roleGroupName,
            varUserRoleGroupDescription: roleGroupDescription,
            numLocationID: location,
            varColor: eventColor,
            bitActive: true,
            numUserID: 1,
        }

        await axiosPrivate
            .post(`User/PopulateUserRoleGroup`, JSON.stringify(payloadData), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`New Role Group Created!`)
                    setRoleGroupName("")
                    setRoleGroupDescription("")
                    setEventColor("")
                    setShowNewRoleGroup(false)
                    getRoles("")

                    setRolesList([])
                    setModuleList([])
                    setModuleSettingList([])
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function getSystemModulesByMapping(mappingID) {
        const result = await axiosPrivate.get(`User/GetAllSystemModulesByRoleGroupMappingID?RoleGroupMappingID=${mappingID === null ? 0 : mappingID}`)
        return result
    }

    async function createNewRole() {
        let payloadData = {
            numUserRoleID: 0,
            varUserRoleName: roleName,
            varUserRoleDescription: roleDescription,
            bitActive: true,
            numUserID: 0,
        }

        await axiosPrivate
            .post(`User/PopulateUserRole`, JSON.stringify(payloadData), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`New Role Created!`)
                    setRoleName("")
                    setRoleDescription("")
                    setShowNewRole(false)
                    getAllRoles("")
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const getModules = (mappingID) => {
        getSystemModulesByMapping(mappingID)
            .then((response) => {
                setModuleList(response.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    async function addNewModule() {
        let payloadData = {
            numModuleID: 0,
            moduleName: moduleName,
            moduleDescription: moduleDescription,
            isActive: true,
            numUserID: 0,
        }

        await axiosPrivate
            .post(`User/PopulateSystemModules`, JSON.stringify(payloadData), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`New Module Created!`)
                    setModuleName("")
                    setModuleDescription("")
                    setShowNewSystemModule(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const updateRolesList = () => {
        const updatedRolesList = rolesList
            .filter((role) => (role?.RoleGroupMappingID !== null && role?.IsSelected === false) || (role?.RoleGroupMappingID === null && role?.IsSelected === true))
            .map((role) => {
                console.log("asa", role)
                return {
                    roleGroupMappingID: role?.RoleGroupMappingID,
                    numUserRoleGroupID: selectedRoleGroup?.numUserRoleGroupID,
                    numUserRoleID: role?.numUserRoleID,
                    isSelected: role?.IsSelected,
                    userID: 1,
                }
            })

        if (updatedRolesList.length > 0) {
            postRolesList(updatedRolesList)
            console.log("roles-sent-payload", updatedRolesList)
        } else {
            toast.info("No changes to update!")
        }
    }

    async function postRolesList(payloadData) {
        await axiosPrivate
            .post(`User/PopulateRoleGroupMapping`, JSON.stringify(payloadData), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    setModuleList([])
                    setModuleSettingList([])
                    toast.success(`Changes updated successfully!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const updateModulesList = () => {
        console.log("full mod", moduleList)
        const updatedModuleList = moduleList
            .filter((mod) => (mod?.RoleModuleMappingID === null && mod?.IsSelected === true) || (mod?.RoleModuleMappingID !== null && mod?.IsSelected === false))
            .map((mod) => {
                console.log("mod", mod)

                return {
                    roleModuleMappingID: mod?.RoleModuleMappingID === null ? 0 : mod?.RoleModuleMappingID,
                    roleGroupMappingID: selectedRole?.RoleGroupMappingID,
                    moduleID: mod?.ModuleID,
                    isSelected: mod?.IsSelected,
                    userID: 1,
                }
            })

        if (updatedModuleList.length > 0) {
            postModulesList(updatedModuleList)
            console.log("modules-sent-payload", updatedModuleList)
        } else {
            toast.info("No changes to update!")
        }
    }

    async function postModulesList(payloadData) {
        await axiosPrivate
            .post(`User/PopulateRoleModuleMappings`, JSON.stringify(payloadData), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    setModuleSettingList([])

                    toast.success(`Changes updated successfully!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const [filterRoleGroups, setFilterRoleGroups] = useState("")
    const [filteredItemsRoleGroups, setFilteredItemsRoleGroups] = useState([]) as any

    const filterItemsRoleGroups = (filterText) => {
        const filteredItems = roleGroupList.filter((item) => item.varUserRoleGroupName.toLowerCase().includes(filterText.toLowerCase()))
        setFilteredItemsRoleGroups(filteredItems)
    }

    const handleFilterChangeRoleGroups = (event) => {
        const filterText = event.target.value
        setFilterRoleGroups(filterText)
        filterItemsRoleGroups(filterText)
    }

    const resetRoleGroups = () => {
        setFilterRoleGroups("")
        setFilteredItemsRoleGroups(roleGroupList)
    }

    const updateModuleListSettings = async () => {
        let finalModList = [] as any

        console.log(moduleSettingList)

        moduleSettingList.map((mods) => {
            finalModList.push({
                id: mods?.ID,
                systemModuleListID: mods?.SystemModuleListID,
                isView: mods?.IsView,
                isUpdate: mods?.IsUpdate,
                isDelete: mods?.IsDelete,
            })
        })

        let payloadData = {
            id: 0,
            roleModuleMappingID: selectedModule?.RoleModuleMappingID,
            numUserID: 1,
            modulelist: finalModList,
        }

        console.log(payloadData)

        await axiosPrivate
            .post(`User/PopulatePermissionSetting`, JSON.stringify(payloadData), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    toast.success(`Permissions Assigned!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        getLocations()
            .then((response) => {
                let res = response
                res.unshift({
                    varLocationName: "All Locations",
                    numLocationID: 0,
                })
                setLocationList(response)

                console.info(response)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
        console.log("location inital")

        getRoles("")
    }, [])

    /* PERMISSION USER FLOW */
    /* 
        RESETS 
        - Reset Role Groups = setRoleGroupList([])
        - Reset Roles = setRolesList([])
        - Reset Modules = setModuleList([])
        - Reset Settings = setModuleSettingList([])
    */

    useEffect(() => {
        if (Object.keys(selectedRoleGroup).length > 0) {
            getAllRoles(selectedRoleGroup?.numUserRoleGroupID)
            setModuleList([])
            setModuleSettingList([])
        }
    }, [selectedRoleGroup])

    useEffect(() => {
        if (Object.keys(selectedRole).length > 0) {
            console.log("test", selectedRole?.RoleGroupMappingID)
            getModules(selectedRole?.RoleGroupMappingID)
            setModuleSettingList([])
        }
    }, [selectedRole])

    useEffect(() => {
        if (Object.keys(selectedModule).length > 0) {
            getAllSettings(selectedModule?.RoleModuleMappingID, selectedModule?.ModuleID)
        }
    }, [selectedModule])

    return (
        <div style={{ width: "100%", paddingBottom: "10px" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <p style={{ fontWeight: "600", fontSize: "20px", color: "#5e5e5e" }}>Roles + Modules</p>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "15px",
                    marginTop: "20px",
                    height: "564px",
                }}
            >
                <div
                    style={{
                        border: "1px solid #8eb4f1",
                        borderRadius: "8px",
                        padding: "8px 15px",
                        width: "100%",
                        height: "100%",
                        boxShadow: "rgb(0 99 255 / 21%) 0px 0 8px 0px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #d5d5d5",
                            paddingBottom: "8px",
                            marginBottom: "4px",
                        }}
                    >
                        <p style={{ fontSize: "15px", fontWeight: "500" }}>Departments</p>
                        <Button className={styles.actionBtn} variant="contained" color="primary" text="New" onClick={() => setShowNewRoleGroup(true)} />
                    </div>

                    <div>
                        <div style={{ width: "100%", marginBottom: "8px" }}>
                            <TextField
                                InputProps={{
                                    endAdornment:
                                        filterRoleGroups.length > 0 ? (
                                            <IconButton
                                                style={{ borderRadius: "5px" }}
                                                onClick={(e) => {
                                                    resetRoleGroups()
                                                }}
                                            >
                                                <Close style={{ fontSize: "16px", color: "#ff6464" }} />
                                            </IconButton>
                                        ) : (
                                            <IconButton disabled style={{ borderRadius: "5px" }}>
                                                <Close style={{ fontSize: "16px", color: "#fff" }} />
                                            </IconButton>
                                        ),
                                }}
                                value={filterRoleGroups}
                                onChange={handleFilterChangeRoleGroups}
                                inputProps={{ style: { fontSize: 14 } }}
                                placeholder="Filter here"
                                size="small"
                                variant="standard"
                                style={{ width: "100%", fontSize: "14px" }}
                            />
                        </div>

                        <div style={{ overflowY: "auto", height: "440px" }}>
                            {sortByProperty(filteredItemsRoleGroups, "varUserRoleGroupName")?.map((roleGroup) => (
                                <div
                                    style={{ padding: "3px 0 3px 4px" }}
                                    onClick={() => {
                                        setSelectedRoleGroup({
                                            numUserRoleGroupID: roleGroup?.numUserRoleGroupID,
                                            varUserRoleGroupName: roleGroup?.varUserRoleGroupName,
                                            varUserRoleGroupDescription: roleGroup?.varUserRoleGroupDescription,
                                        })
                                    }}
                                    className={`styles.listItem ${selectedRoleGroup?.numUserRoleGroupID === roleGroup?.numUserRoleGroupID ? styles.selectedHighlight : styles.listItem}`}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                <div>
                                                    <p style={{ color: "#fff", margin: 0 }}>
                                                        <b>Description: </b>
                                                        {roleGroup?.varUserRoleGroupDescription}
                                                    </p>
                                                    <p style={{ color: "#fff", margin: 0 }}>
                                                        <b>Is Active: </b>
                                                        {roleGroup?.bitActive ? "Active" : "Inactive"}
                                                    </p>
                                                </div>
                                            }
                                        >
                                            <InfoOutlined style={{ fontSize: "20px", color: "gray" }} />
                                        </Tooltip>
                                        <p
                                            style={{
                                                background: `${roleGroup?.varColor === "" ? "gray" : roleGroup?.varColor}`,
                                                height: "12px",
                                                width: "12px",
                                                borderRadius: "50px",
                                            }}
                                        ></p>
                                        <p
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                userSelect: "none",
                                            }}
                                        >
                                            {roleGroup?.varUserRoleGroupName}
                                        </p>
                                        <IconButton style={{ borderRadius: "5px" }} onClick={(e) => {}}>
                                            {/* <Edit style={{ fontSize: "16px", color: "#6B7280" }} /> */}
                                        </IconButton>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <Modal open={showNewRole}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                                backgroundColor: "#f5f5f5",
                                padding: "16px",
                                marginTop: "-16px",
                                borderTopRightRadius: "12px",
                                borderTopLeftRadius: "12px",
                            }}
                        >
                            <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>New Role </p>
                            <CloseButton onClick={() => setShowNewRole(false)} />
                        </div>
                        <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                            <div style={{ backgroundColor: "#fff", padding: "0", width: "450px" }}>
                                <p style={{ fontWeight: "500", color: "#898989" }}>Enter Name</p>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            Role Name
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>Required</span>
                                        </div>
                                    }
                                    required={true}
                                    focused={true}
                                    type={"text"}
                                    variant="filled"
                                    onChange={(e) => {
                                        setRoleName(e.target.value)
                                    }}
                                    value={roleName}
                                />
                                <div style={{ marginBottom: "20px" }}></div>
                                <p style={{ fontWeight: "500", color: "#898989" }}>Enter Description</p>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            Role Description
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>Required</span>
                                        </div>
                                    }
                                    required={true}
                                    focused={true}
                                    type={"text"}
                                    variant="filled"
                                    onChange={(e) => {
                                        setRoleDescription(e.target.value)
                                    }}
                                    value={roleDescription}
                                />
                            </div>

                            <div
                                style={{
                                    textAlign: "end",
                                    width: "100%",
                                    backgroundColor: "#EFF5FF",
                                    marginTop: "1rem",
                                    padding: "1rem",
                                    marginBottom: "-16px",
                                    marginLeft: "-16px",
                                    borderBottomRightRadius: "12px",
                                    borderBottomLeftRadius: "12px",
                                    display: "flex",
                                    gap: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button variant="contained" color="primary" type="submit" onClick={() => addNewRole()}>
                                    Save Changes
                                </Button>
                            </div>
                        </form>
                    </div>
                </Modal>

                <Modal open={showNewRoleGroup}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                                backgroundColor: "#f5f5f5",
                                padding: "16px",
                                marginTop: "-16px",
                                borderTopRightRadius: "12px",
                                borderTopLeftRadius: "12px",
                            }}
                        >
                            <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>New Role Group</p>
                            <CloseButton onClick={() => setShowNewRoleGroup(false)} />
                        </div>
                        <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                            <div style={{ backgroundColor: "#fff", padding: "0", width: "450px" }}>
                                <p style={{ fontWeight: "500", color: "#898989" }}>Enter Name</p>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            Role Group Name
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>Required</span>
                                        </div>
                                    }
                                    required={true}
                                    focused={true}
                                    type={"text"}
                                    variant="filled"
                                    onChange={(e) => {
                                        setRoleGroupName(e.target.value)
                                    }}
                                    value={roleGroupName}
                                />
                                <div style={{ marginBottom: "20px" }}></div>
                                <p style={{ fontWeight: "500", color: "#898989" }}>Enter Description</p>
                                <TextField
                                    style={{ width: "100%" }}
                                    label={
                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                            Role Group Description
                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>Required</span>
                                        </div>
                                    }
                                    required={true}
                                    focused={true}
                                    type={"text"}
                                    variant="filled"
                                    onChange={(e) => {
                                        setRoleGroupDescription(e.target.value)
                                    }}
                                    value={roleGroupDescription}
                                />
                                <div style={{ marginBottom: "20px" }}></div>
                                <div>
                                    <p style={{ fontWeight: "500", color: "#898989" }}>Select Color</p>
                                    <ColorPicker onColorChange={(e) => setEventColor(e)} value={eventColor} />
                                </div>
                            </div>

                            <div
                                style={{
                                    textAlign: "end",
                                    width: "100%",
                                    backgroundColor: "#EFF5FF",
                                    marginTop: "1rem",
                                    padding: "1rem",
                                    marginBottom: "-16px",
                                    marginLeft: "-16px",
                                    borderBottomRightRadius: "12px",
                                    borderBottomLeftRadius: "12px",
                                    display: "flex",
                                    gap: "1rem",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button variant="contained" color="primary" type="submit" onClick={() => addNewRoleGroup()}>
                                    Save Changes
                                </Button>
                            </div>
                        </form>
                    </div>
                </Modal>

                <div
                    style={{
                        border: "1px solid #8eb4f1",
                        borderRadius: "8px",
                        padding: "8px 15px",
                        width: "100%",
                        height: "100%",
                        boxShadow: "rgb(0 99 255 / 21%) 0px 0 8px 0px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #d5d5d5",
                            paddingBottom: "8px",
                            marginBottom: "4px",
                        }}
                    >
                        <p style={{ fontSize: "15px", fontWeight: "500" }}>Roles (Job Title)</p>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <Button className={styles.actionBtn} variant="contained" color="primary" text="New" onClick={() => setShowNewRole(true)} />
                            <Button className={styles.actionBtn} variant="outlined" color="info" text="Update" onClick={() => updateRolesList()} />
                        </div>
                    </div>

                    <div>
                        <div style={{ width: "100%", marginBottom: "8px" }}>
                            <div style={{ width: "100%", marginBottom: "2px", display: "flex" }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton disabled style={{ borderRadius: "5px" }}>
                                                <Close style={{ fontSize: "16px", color: "#fff" }} />
                                            </IconButton>
                                        ),
                                    }}
                                    value={""}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>
                        </div>

                        <div style={{ overflowY: "auto", height: "440px" }}>
                            {sortByProperty(rolesList, "varUserRoleName")?.map((roles, index) => (
                                <div
                                    onClick={() => {
                                        setSelectedRole({
                                            RoleGroupMappingID: roles?.RoleGroupMappingID,
                                            numUserRoleID: roles?.numUserRoleID,
                                            varUserRoleName: roles?.varUserRoleName,
                                        })
                                    }}
                                    className={`${
                                        selectedRole?.RoleGroupMappingID === roles?.RoleGroupMappingID && selectedRole?.RoleGroupMappingID !== null ? styles.selectedHighlight : styles.listItem
                                    }`}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                    >
                                        <Checkbox
                                            onChange={(e) => {
                                                e.stopPropagation()
                                                const tempRolesList = [...rolesList]
                                                tempRolesList[index].IsSelected = !tempRolesList[index]?.IsSelected
                                                setRolesList(tempRolesList)
                                            }}
                                            size="small"
                                            checked={roles?.IsSelected}
                                        />
                                        <p>{roles?.varUserRoleName}</p>
                                        <IconButton style={{ borderRadius: "5px" }} onClick={(e) => {}}>
                                            {/* <Edit style={{ fontSize: "16px", color: "#6B7280" }} /> */}
                                        </IconButton>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        border: "1px solid #8eb4f1",
                        borderRadius: "8px",
                        padding: "8px 15px",
                        width: "100%",
                        height: "100%",
                        boxShadow: "rgb(0 99 255 / 21%) 0px 0 8px 0px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #d5d5d5",
                            paddingBottom: "8px",
                            marginBottom: "4px",
                        }}
                    >
                        <p style={{ fontSize: "15px", fontWeight: "500" }}>System Modules</p>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
                            <Button className={styles.actionBtn} variant="outlined" color="info" text="Update" onClick={() => updateModulesList()} />
                        </div>
                    </div>

                    <Modal open={showNewSystemModule}>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    alignItems: "center",
                                    backgroundColor: "#f5f5f5",
                                    padding: "16px",
                                    marginTop: "-16px",
                                    borderTopRightRadius: "12px",
                                    borderTopLeftRadius: "12px",
                                }}
                            >
                                <p style={{ color: "#002867", fontSize: "18px", fontWeight: "600", width: "100%" }}>New Module </p>
                                <CloseButton onClick={() => setShowNewSystemModule(false)} />
                            </div>
                            <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                                <div style={{ backgroundColor: "#fff", padding: "0", width: "450px" }}>
                                    <p style={{ fontWeight: "500", color: "#898989" }}>Enter Name</p>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label={
                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                System Module Name
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>Required</span>
                                            </div>
                                        }
                                        required={true}
                                        focused={true}
                                        type={"text"}
                                        variant="filled"
                                        onChange={(e) => {
                                            setModuleName(e.target.value)
                                        }}
                                        value={moduleName}
                                    />
                                    <p style={{ fontWeight: "500", color: "#898989" }}>Enter Description</p>
                                    <TextField
                                        style={{ width: "100%" }}
                                        label={
                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                System Module Description
                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>Required</span>
                                            </div>
                                        }
                                        required={true}
                                        focused={true}
                                        type={"text"}
                                        variant="filled"
                                        onChange={(e) => {
                                            setModuleDescription(e.target.value)
                                        }}
                                        value={moduleDescription}
                                    />
                                </div>

                                <div
                                    style={{
                                        textAlign: "end",
                                        width: "100%",
                                        backgroundColor: "#EFF5FF",
                                        marginTop: "1rem",
                                        padding: "1rem",
                                        marginBottom: "-16px",
                                        marginLeft: "-16px",
                                        borderBottomRightRadius: "12px",
                                        borderBottomLeftRadius: "12px",
                                        display: "flex",
                                        gap: "1rem",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button variant="contained" color="primary" type="submit" onClick={() => addNewModule()}>
                                        Save Changes
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Modal>

                    <div>
                        <div style={{ width: "100%", marginBottom: "8px" }}>
                            <div style={{ width: "100%", marginBottom: "2px", display: "flex" }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton disabled style={{ borderRadius: "5px" }}>
                                                <Close style={{ fontSize: "16px", color: "#fff" }} />
                                            </IconButton>
                                        ),
                                    }}
                                    value={""}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>
                        </div>

                        <div style={{ overflowY: "auto", height: "440px" }}>
                            {moduleList.length > 0
                                ? sortByProperty(moduleList, "ModuleName")?.map((module, index) => (
                                      <div
                                          style={{ display: "flex" }}
                                          onClick={() => {
                                              setSelectedModule({
                                                  RoleModuleMappingID: module?.RoleModuleMappingID,
                                                  RoleGroupMappingID: module?.RoleGroupMappingID,
                                                  ModuleName: module?.ModuleName,
                                                  ModuleID: module?.ModuleID,
                                              })
                                          }}
                                          className={`styles.listItem ${
                                              selectedModule?.RoleModuleMappingID === module?.RoleModuleMappingID && selectedModule?.RoleModuleMappingID !== null
                                                  ? styles.selectedHighlight
                                                  : styles.listItem
                                          }`}
                                      >
                                          <Checkbox
                                              onChange={(e) => {
                                                  e.stopPropagation()
                                                  const tempModuleList = [...moduleList]
                                                  tempModuleList[index].IsSelected = !tempModuleList[index]?.IsSelected
                                                  setModuleList(tempModuleList)
                                              }}
                                              size="small"
                                              checked={module?.IsSelected}
                                          />
                                          <div
                                              style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  gap: "5px",
                                              }}
                                          >
                                              <p>{module?.ModuleName}</p>
                                          </div>
                                      </div>
                                  ))
                                : ""}
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        border: "1px solid #8eb4f1",
                        borderRadius: "8px",
                        padding: "8px 15px",
                        width: "100%",
                        height: "100%",
                        boxShadow: "rgb(0 99 255 / 21%) 0px 0 8px 0px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #d5d5d5",
                            paddingBottom: "8px",
                            marginBottom: "4px",
                        }}
                    >
                        <p style={{ fontSize: "15px", fontWeight: "500" }}>Permission Settings</p>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Button className={styles.actionBtn} variant="outlined" color="info" text="Update" onClick={() => updateModuleListSettings()} />
                        </div>
                    </div>

                    <div>
                        <div style={{ width: "100%", marginBottom: "8px" }}>
                            <div style={{ width: "100%", marginBottom: "2px", display: "flex" }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton disabled style={{ borderRadius: "5px" }}>
                                                <Close style={{ fontSize: "16px", color: "#fff" }} />
                                            </IconButton>
                                        ),
                                    }}
                                    value={""}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>
                        </div>

                        <div style={{ overflowY: "auto", height: "440px" }}>
                            {sortByProperty(moduleSettingList, "SystemModuleListName")?.map((setting, index) => {
                                return (
                                    <div style={{ display: "flex" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                alignItems: "center",
                                                gap: "5px",
                                                padding: "4px 0",
                                                justifyContent: "space-between",
                                                borderTop: `${index > 0 ? "1px solid #d7d7d7" : ""}`,
                                            }}
                                        >
                                            <p>{setting?.SystemModuleListName}</p>
                                            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", flexDirection: "column" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Checkbox
                                                        style={{ padding: "2px 4px 2px 0" }}
                                                        onChange={(e) => {
                                                            e.stopPropagation()
                                                            const tempSettingsList = [...moduleSettingList]
                                                            tempSettingsList[index].IsView = !tempSettingsList[index]?.IsView
                                                            setModuleSettingList(tempSettingsList)
                                                        }}
                                                        checked={setting?.IsView}
                                                    ></Checkbox>
                                                    View
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Checkbox
                                                        style={{ padding: "2px 4px 2px 0" }}
                                                        onChange={(e) => {
                                                            e.stopPropagation()
                                                            const tempSettingsList = [...moduleSettingList]
                                                            tempSettingsList[index].IsUpdate = !tempSettingsList[index]?.IsUpdate
                                                            setModuleSettingList(tempSettingsList)
                                                        }}
                                                        checked={setting?.IsUpdate}
                                                    ></Checkbox>
                                                    Update
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Checkbox
                                                        style={{ padding: "2px 4px 2px 0" }}
                                                        onChange={(e) => {
                                                            e.stopPropagation()
                                                            const tempSettingsList = [...moduleSettingList]
                                                            tempSettingsList[index].IsDelete = !tempSettingsList[index]?.IsDelete
                                                            setModuleSettingList(tempSettingsList)
                                                        }}
                                                        checked={setting?.IsDelete}
                                                    ></Checkbox>
                                                    DeActivate
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RolesUsers
