import { WarningAmber } from "@mui/icons-material";
import { CloseButton } from "../CloseButton/CloseButton";
import { Avatar, Chip, Modal } from "../components";

const PetAlerts = (props: {
    isOpenPetAlertsModal;
    setIsOpenPetAlertsModal: CallableFunction;
    data;
}) => {
    const PetPersonalities = () => {

        const sortedData = props.data?.slice().sort((a, b) => b.bitIsWarning - a.bitIsWarning);

        return (
            <div style={{ display: "flex", flexDirection:"column", gap:"10px", minHeight: "100px", maxHeight:"200px", overflowY: "scroll" }}>
            {sortedData?.map((personality, index) => (
                <div key={personality.numPetPersonalityID} style={{display:"flex", gap:"10px", alignItems:"center"}}>
                    <p style={{color:"#686868", fontSize:"14px", fontWeight:"500"}}>{personality.varPersonality}</p>
                    {
                        (personality?.bitIsWarning === true)? <WarningAmber style={{color:"red", fontSize:"20px"}}/> : null
                    }
                   
                </div>
            ))}
        </div>
        
        );
    };

    return (
        <Modal open={props.isOpenPetAlertsModal} >
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column',  gap: '1rem', padding: "0 1rem 0 1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <p style={{ fontSize: "24px", fontWeight: "600", color: "#192252" }}>Pet Personality</p>
                    <CloseButton onClick={() => props.setIsOpenPetAlertsModal(false)} />
                </div>
                <div style={{width:"400px"}}>
                    <PetPersonalities />
                </div>
            </div>
        </Modal>
    );
}

export default PetAlerts;