import styles from "../../Help.module.scss"

function Customer() {
    return (
        <div>
            <h3 style={{ fontWeight: "900", fontSize: "26px" }}> EsyPet Call Customer Help File </h3>

            <div id={styles.container}>
                {/* <p>Label Configuration has four menus – Salon, Pet, Customer and Employee. The functions of all four of the menus are the same.</p>
                  
                  <p> Each menu has “Predefined fields and “Custom fields”. Under each are labels, which can be changed manually, if required. For example, Job Title could be changed to Pet Groomer, etc.</p>
                  
                  <h5>Once this change is done by the Admin, it need not be changed, unless required to do so. These changes affects the entire system, and as required by the Admin.</h5>
                  
                  <p> Alongside each label is a checkbox, which could be clicked to enable, or de-clicked to disable a function.</p>
                  
                  <p> When you click a check box, it shows the field as mandatory. The word “Required” pops up alongside the field in <span style={{color: 'red'}}>red</span>.</p>
                  
                  <p> When you scroll down below, you come to “Custom fields”:</p>
                  
                   */}
                {/* <div><img src={require('./assets/role.png')}/></div>
                 */}

                {/* <p> “Custom fields” allow the Admin to enter new fields, which are not listed under Predefined fields. Each field has two check boxes – one for Preview, and the other as a “Required” field. Either one, or both, of the check boxes could be selected.</p>
                  
                  <p> “Predefined fields” and “Custom fields” could be expanded or retracted by clicking on these titles.</p>
                  
                  <p>Once the required changes have been made, click on the “Save” button.</p>
                   */}
            </div>
        </div>
    )
}

export default Customer
