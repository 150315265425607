import styles from "./Menu.module.scss"
import { useEffect, useState } from "react"
import { Button } from "../components"
import { useNavigate } from "react-router-dom"
import ValidatePermission from "../ValidatePermission/ValidatePermission"

export const Menu = (props: { linkList; scrollToTop?: CallableFunction | any }) => {
    const [activeTab, setActiveTab] = useState("")
    const [activeSubTab, setActiveSubTab] = useState("")
    const [subRoute, setSubRoute] = useState<any>([])
    const [currentIndex, setCurrentIndex] = useState(0)

    const navigate = useNavigate()

    // useEffect(() => {
    //     gotoLink(activeTab, 0)
    // }, [])

    const gotoLink = (to, index) => {
        setActiveTab(to)
        console.log("clicked to", to)
        setCurrentIndex(index)

        if (props.linkList?.[index]?.subRoutes != undefined) {
            showSubRoutes(to, index)
            navigate("")
        } else {
            setSubRoute([])
            setActiveSubTab("")
            navigate(to)
        }
    }

    const gotoSubLink = (to, mainLink) => {
        navigate(`${mainLink}/${to}`)
        setActiveSubTab(to)

        props?.scrollToTop(true)
    }

    const showSubRoutes = (mainLink, routeIndex) => {
        setSubRoute(props.linkList?.[routeIndex]?.subRoutes)
        // gotoSubLink(props.linkList?.[routeIndex]?.subRoutes?.[0]?.subTo, mainLink)
    }

    // const sortedData = subRoute.sort((a, b) => a.subName.localeCompare(b.subName));

    return (
        <div style={{ backgroundColor: "#FCFCFD", height: "100%", display: "flex" }}>
            <div style={{ width: "250px", borderRight: "1px solid #F3F4F6" }}>
                <p style={{ color: "#6B7280", padding: ".3rem 1.5rem" }}>Main Menu</p>
                <div className={styles.tabContainer}>
                    {props.linkList.map((link, index) => {
                        return (
                            <ValidatePermission allowedModules={[link?.permissionModule]}>
                                <Button variant="text" onClick={() => gotoLink(link.to, index)} color="default" className={activeTab === link.to ? styles.tabActive : styles.tab}>
                                    {link.name}
                                </Button>
                            </ValidatePermission>
                        )
                    })}
                </div>
            </div>

            {subRoute.length > 0 ? (
                <div style={{ width: "250px", borderRight: "1px solid #F3F4F6" }}>
                    <p style={{ color: "#6B7280", padding: ".3rem 1.5rem" }}>Sub Menu</p>
                    <div className={styles.tabContainer}>
                        {subRoute.map((subLink, subIndex) => {
                            return subLink?.isMain ? (
                                <ValidatePermission allowedModules={[subLink?.permissionModule]}>
                                    {subLink?.subName === "divider" ? (
                                        <div style={{ borderBottom: "2px solid #e5e5e5", width: "100%" }}></div>
                                    ) : (
                                        <Button
                                            variant="text"
                                            onClick={() => gotoSubLink(subLink?.subTo, activeTab)}
                                            color="default"
                                            className={activeSubTab === subLink?.subTo ? styles.tabActive : styles.tab}
                                        >
                                            {subLink?.subName}
                                        </Button>
                                    )}
                                </ValidatePermission>
                            ) : (
                                <ValidatePermission allowedModules={[props.linkList?.[currentIndex]?.permissionModule]} allowedSubRoutes={[subLink?.permissionModule]}>
                                    {subLink?.subName === "divider" ? (
                                        <div style={{ borderBottom: "2px solid #e5e5e5", width: "100%" }}></div>
                                    ) : (
                                        <Button
                                            variant="text"
                                            onClick={() => gotoSubLink(subLink?.subTo, activeTab)}
                                            color="default"
                                            className={activeSubTab === subLink?.subTo ? styles.tabActive : styles.tab}
                                        >
                                            {subLink?.subName}
                                        </Button>
                                    )}
                                </ValidatePermission>
                            )
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    )
}
