/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

export const Salutation: {} = {
    tableName: "2.3 - Salutations",
    hiddenColumns: ["numSalutationID"],
    pdfTemplate: ["varSalutationName", "varSalutationDescription", "bitActive"],
    template: [
        {
            Header: "Name",
            accessor: "varSalutationName",
        },
        {
            Header: "Description",
            accessor: "varSalutationDescription",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numSalutationID",
            accessor: "numSalutationID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numSalutationID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varSalutationName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varSalutationDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
