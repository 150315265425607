/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel";
import { CopyAll, OpenInNew } from '@mui/icons-material'
import { Button } from "../../../components";
import { CopyToClipboard, Truncate } from "../../../../utils/Validation";
import styles from '../../Table.module.scss';
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { formatPhone } from "../../../../utils/PhoneFormatter";
import { generateYear } from "../../../../utils/GenerateJoinedDate";

export const Employee = {
    tableName: "Employee",
    hiddenColumns: ["varEmployeeImagePath","varEmployeeLastName", "bitActive", "varEmployeePerformanceCategory", "varEmployeeRating"],
    pdfTemplate: ['varEmployeeFirstName','varEmployeeLocation','varEmployeeJobTitle', 'varEmployeePhone', 'varEmployeeEmail','dtCreatedDate'],
    hideSort: true,
    template: [
        {
            Header: 'Employee',
            accessor: 'varEmployeeFirstName',  
            width: 10,
            disableSortBy: false,
            Cell: props => (
                <AvatarLabel 
                    isVip={props.row.original.bitIsVip} 
                    img={(props.row.original.varEmployeeImagePath == null || props.row.original.varEmployeeImagePath == "") ? "" : `${process.env.REACT_APP_MEDIA_URL}directories/employees/` + props.row.original.varEmployeeImagePath} 
                    name={props.row.original.varEmployeeFirstName + " " + props.row.original.varEmployeeLastName} 
                />        
            )            
        },
        {
            Header: 'Salon',
            accessor: 'varEmployeeDefultLocation'
        },
        {
            Header: 'Job Title',
            accessor: 'varOccupation'
        },
        {
            Header: 'varEmployeePerformanceCategory',
            accessor: 'varEmployeePerformanceCategory'
        },
        {
            Header: 'varEmployeeRating',
            accessor: 'varEmployeeRating'
        },
        {
            Header: 'Mobile',
            accessor: 'varEmployeeMobilePhone',
            Cell: props => (
                <p style={{ fontSize: '14px' }}>{formatPhone(props?.cell?.value)}</p>              
            )   
        },
        {
            Header: 'Email',
            accessor: 'varEmployeeEmail',
            Cell: props => (
                <div style={{display:"flex", gap:"1rem", justifyContent:"space-between"}}>
                    {(props.row.original.varEmployeeEmail === null || props.row.original.varEmployeeEmail === undefined)?
                        "N/A" :
                        <>
                            <p>{Truncate(props.row.original.varEmployeeEmail, 20)}</p>
                            <IconButton onClick={() =>{CopyToClipboard(props.row.original.varEmployeeEmail)}}><CopyAll className={styles.copyIcon} style={{color:"#000"}}/></IconButton>
                        </>
                }
                </div>                 
            ) 
        },
        {
            Header: 'Experience',
            accessor: 'varEmployeeHighestExprierence'
        },
        {
            Header: 'img',
            accessor: 'varEmployeeImagePath',
            disableFilters: true,
        },
        {
            Header: 'lName',
            accessor: 'varEmployeeLastName',
            disableFilters: true,
        },
        {
            Header: 'active',
            accessor: 'bitActive',
        },
        {
            Header: 'Age',
            accessor: 'dtEmployeeDOB',
            Cell: props => (
                <p style={{ fontSize: '14px' }}>{(props.row.original.dtEmployeeDOB === null || props.row.original.dtEmployeeDOB === undefined) ? 'N/A' : generateYear(props.row.original.dtEmployeeDOB)}</p>              
            )   
        },
        {
            Header: '',
            accessor: 'numEmployeeID',
            disableFilters: true,
            Cell: props => (
                <div id="moreOpt">
                    <Button  color="transparent" variant="text" onClick={() => console.log(props.row.original.numEmployeeID)}><OpenInNew style={{color:"#005df1"}}/></Button> 
                </div>
                            
            )      
        },
    ]
}
