import { OpenInNew } from "@mui/icons-material"
import { ChipGroup } from "../../ChipGroup/ChipGroup"

import styles from "./Salons.module.scss"

import placeholder from "../../../../../images/location_placeholder.png"
import { Chip } from "../../Chip/Chip"
import { IconButton } from "@mui/material"
import { formatPhone } from "../../../../../utils/PhoneFormatter"

const Location = (props: { page; prepareRow; onSelect: CallableFunction }) => {
    let test = false
    return (
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", marginTop: "0.8rem" }}>
            {props.page.map((row, i) => {
                props.prepareRow(row)
                console.log(row)

                return (
                    <div style={{ margin: "0 .5rem .5rem 0" }}>
                        <div className={styles.location_card}>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
                                    <img
                                        style={{ maxWidth: "120px", borderRadius: "10px" }}
                                        src={
                                            row.allCells[1].value == null || row.allCells[1].value == ""
                                                ? placeholder
                                                : `${process.env.REACT_APP_MEDIA_URL}/directories/locations/${row.allCells[1].value}`
                                        }
                                    />
                                    <Chip
                                        color={row.allCells[17].value == true ? undefined : "error"}
                                        customLabel={true}
                                        label={row.allCells[17].value == true ? "Active" : "Inactive"}
                                        size={"small"}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div className={styles.clientContainer}>
                                            <div className={styles.avatarContainer} style={{ borderBottom: "1px solid #F3F4F6", paddingBottom: "8px" }}>
                                                <div style={{ fontWeight: 500 }}>{row.allCells[0].value}</div>
                                            </div>

                                            <div className={styles.avatarContainer} style={{ borderBottom: "1px solid #F3F4F6", padding: "8px 0" }}>
                                                <ChipGroup
                                                    values={[
                                                        {
                                                            label: "Gro",
                                                            value: row.allCells[6].value,
                                                            color: "success",
                                                        },
                                                        {
                                                            label: "Day",
                                                            value: row.allCells[8].value,
                                                            color: "success",
                                                        },
                                                        {
                                                            label: "Bod",
                                                            value: row.allCells[7].value,
                                                            color: "success",
                                                        },
                                                        {
                                                            label: "Sit",
                                                            value: row.allCells[9].value,
                                                            color: "success",
                                                        },
                                                        {
                                                            label: "Tra",
                                                            value: row.allCells[10].value,
                                                            color: "success",
                                                        },
                                                        {
                                                            label: "Sto",
                                                            value: row.allCells[12].value,
                                                            color: "success",
                                                        },
                                                    ]}
                                                    size={"small"}
                                                />
                                            </div>
                                        </div>
                                        <div onClick={() => props.onSelect(row?.allCells[row?.allCells.length - 1]?.value)} className={styles.viewButton}>
                                            <OpenInNew style={{ color: "#005df1" }} />
                                        </div>
                                    </div>

                                    <div>
                                        <div style={{ padding: "5px 0 5px 0" }}>
                                            <p style={{ fontWeight: 500, color: "#6B7280", margin: "0" }}>Phone</p>
                                            <p style={{ fontWeight: 600, color: "#000", margin: "0" }}>{formatPhone(row.allCells[5].value)}</p>
                                        </div>
                                        <div style={{ padding: "0 0 5px 0" }}>
                                            <p style={{ fontWeight: 500, color: "#6B7280", margin: "0" }}>Address</p>
                                            <p style={{ fontWeight: 600, color: "#000", margin: "0" }}>{row.allCells[13].value}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Location
