import styles from '../PersonalDetails.module.scss';
import { Chip } from "../../../../components";

const DisplayEmployee = (props: {
    employeeTemplate;
}) => {
    
    return (
        props?.employeeTemplate.map(section => {
            return <>
                <p className={styles.heading}>{section.heading}</p>
                
                {
                    (section.layout == 'text') ?     
                        <table cellSpacing="0" className={styles.table}>  
                        {
                            section.data.map(pairs => {
                                return <tr>
                                    {
                                        (pairs.key === '') ? 
                                            null
                                        :
                                            <td>{pairs.key}</td>
                                    }
                                    <td>{pairs.value}</td>
                                </tr>
                                    
                            }) 
                        }                                                                                           
                        </table>                                                                     
                    : (section.layout == 'address') ? 
                        <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch"}}>
                        {                
                            <div>
                                <div style={{backgroundColor: '#EFF5FF', padding: '1rem 2rem'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <p style={{margin: 0, color: 'gray'}}>{(section.data?.addressType  != undefined || section.data?.addressType  != null) ? section.data?.addressType : ""}</p>
                                        <p style={{margin: 0}}><Chip size="small" color='success' label="Default" /></p>                                    
                                    </div>

                                    <div>
                                        <p style={{margin: 0, color: '#002867'}}>
                                            {
                                                (section.data?.addressLineOne  != undefined || section.data?.addressLineOne  != null) ? section.data?.addressLineOne : ""
                                            }
                                        </p>
                                        <p style={{margin: 0, color: '#002867'}}>
                                            {(section.data?.addressLineTwo  != undefined || section.data?.addressLineTwo  != null) ? section.data?.addressLineTwo : ""}
                                            </p>
                                        <p style={{margin: 0, color: '#002867'}}>
                                            {`${(section.data?.addressCity  != undefined || section.data?.addressCity  != null) ? section.data?.addressCity : ""}, 
                                            ${(section.data?.addressState  != undefined || section.data?.addressState  != null) ? section.data?.addressState : ""} ${(section.data?.addressZip  != undefined || section.data?.addressZip  != null) ? section.data?.addressZip : ""}`}
                                        </p>
                                    </div>                                            
                                </div> 
                                <p>{section.data?.addressNotes}</p>
                            </div>
                        }
                        </div>
                    : null
                }    
               
                <div style={{ paddingTop: '1rem', marginBottom: '1.5rem', borderBottom: '1px solid #F3F4F6' }}></div>     
            </>                                                
        })
    );
}

export default DisplayEmployee;