import { MoreHoriz, OpenInNew, Place } from "@mui/icons-material"

import styles from "./Customer.module.scss"

import placeholder from "../../../../../images/customer_placeholder.png"
import { Chip as LocationChip, Avatar } from "../../../../../components/"
import { Chip } from "../../Chip/Chip"

import { generateSince } from "../../../../../utils/GenerateJoinedDate"
import { IconButton } from "@mui/material"
import { formatPhone } from "../../../../../utils/PhoneFormatter"

const Customer = (props: { page; prepareRow; onSelect: CallableFunction }) => {
    return (
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", marginTop: "0.8rem" }}>
            {props.page.map((row, i) => {
                props.prepareRow(row)
                {
                    console.log("rows", row)
                }

                return (
                    <div className={styles.customer_card}>
                        <div style={{ border: "1px solid #E5E7EB", borderRadius: 8, padding: "12px", backgroundColor: "#ffffff" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Avatar isVip={false} img={`${process.env.REACT_APP_MEDIA_URL}directories/customers/` + row.allCells[10].value} className={"medium"} />
                                    </div>

                                    <div style={{ gap: "5px", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                        <div style={{ fontWeight: 500 }}>
                                            {row.allCells[0].value} {row.allCells[1].value}
                                        </div>
                                        <LocationChip label={row.allCells[2].value} shape="square" icon={<Place style={{ fontSize: "15px" }} />} customStyle="BlueFilled" />
                                    </div>
                                </div>

                                <div style={{ display: "flex", gap: ".2rem" }}>
                                    <Chip label={row.allCells[8].value} size={"small"} color="success" />
                                    <div onClick={() => props.onSelect(row?.allCells[row?.allCells.length - 1]?.value)} className={styles.viewButton}>
                                        <OpenInNew style={{ color: "#005df1" }} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <div
                                    style={{
                                        padding: "15px 0",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderTop: "1px solid #F3F4F6",
                                        borderBottom: "1px solid #F3F4F6",
                                        marginBottom: "1rem",
                                        marginTop: "1rem",
                                    }}
                                >
                                    <div>
                                        <div style={{ fontWeight: 400, fontSize: 14, color: "#9CA3AF" }}>Mobile</div>
                                        <div style={{ fontWeight: 600, fontSize: 14, color: "#111827" }}>{formatPhone(row.allCells[5].value)}</div>
                                    </div>
                                    <div style={{ borderRight: "2px solid #F3F4F6" }}></div>
                                    <div>
                                        <div style={{ fontWeight: 400, fontSize: 14, color: "#9CA3AF" }}>Home</div>
                                        <div style={{ fontWeight: 600, fontSize: 14, color: "#111827" }}>{formatPhone(row.allCells[6].value)}</div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ padding: "0 0 3px 0" }}>
                                        <p style={{ fontWeight: 500, color: "#6B7280", margin: "0" }}>Member Since</p>
                                        <p style={{ fontWeight: 600, color: "#000", margin: "0" }}>{generateSince(row.allCells[9].value)}</p>
                                    </div>

                                    <div style={{ padding: "0 0 0 0" }}>
                                        <p style={{ fontWeight: 500, color: "#6B7280", margin: "0" }}>Customer ID</p>
                                        <p style={{ fontWeight: 600, color: "#000", margin: "0" }}>{row.allCells[row?.allCells.length - 1].value}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Customer
