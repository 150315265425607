import { useState } from "react"
import { Input, Button } from "../../../components"
import { toast } from "react-toastify"
import { checkPermission } from "../../../../utils/permissions"
import { axiosPrivate } from "../../../../apis/axios"
function AccountSettings(props: { data?; type? }) {
    const [isCreateUser, setIsCreateUser] = useState(false)
    console.log(props.data)

    const sendMessage = (typeNumber) => {
        let obj = {
            templateReference: "",
            bookingreference: 0,
            customerID: props?.data.numCustomerID,
            employeeID: 0,
            subject: "Password Reset",
            emailBody: "Please follow the following link to reset your password: https://customer.pet.esyntaxis.com/forgot-password",
            smsBody: "Please follow the following link to reset your password: https://customer.pet.esyntaxis.com/forgot-password",
            communicationMethod: typeNumber,
        }
        sendMessagePost(obj)
    }

    async function sendMessagePost(data) {
        await axiosPrivate
            .post(`Message/SendInstantMessage`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status == 200) {
                    toast.success(`Message Sent Successfully!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const Msg: any = ({ closeToast, toastProps }) => (
        <div>
            <p>Are you sure you want to deactivate this user?</p>
            <div style={{ display: "flex", gap: "10px" }}>
                <Button color="primary" text="Yes" variant="contained" />
                <Button color="default" text="No" variant="outlined" />
            </div>
        </div>
    )

    const handleDeactivate = () => {
        toast(Msg)
    }

    const [username, setUsername] = useState("username")
    return (
        <div>
            <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px" }}>Username</p>
            <div>
                <div style={{ display: "flex", gap: "1.5rem", marginBottom: "1rem" }}>
                    <div style={{ width: "320px" }}>
                        <Input
                            label={"Username"}
                            variant={"filled"}
                            value={props.type == "staff" ? props.data.varEmployeeEmail : props.data.varCustomerEmail}
                            size={"small"}
                            disabled
                            onChange={(e) => setUsername(e)}
                        />
                    </div>
                </div>
            </div>

            <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px", marginTop: "3rem" }}>Send Password Reset Link</p>
            <p style={{ color: "#777E90", fontWeight: "400", fontSize: "12px", width: "600px" }}>
                Send a link to the customer enabling the ability to change the password or create a new password if the customer is going to log in for the first time.
            </p>
            <div>
                <div style={{ display: "flex", gap: "1.5rem", marginBottom: "1rem", alignItems: "center" }}>
                    <div style={{ width: "320px" }}>
                        <Input variant={"filled"} size={"small"} label={"Email"} value={props.type == "staff" ? props.data.varEmployeeEmail : props.data.varCustomerEmail} />
                    </div>
                    <div>
                        <Button color="primary" variant="contained" onClick={() => sendMessage(1)}>
                            Send Via Email
                        </Button>
                    </div>
                </div>
                <div style={{ display: "flex", gap: "1.5rem", marginBottom: "1rem", alignItems: "center" }}>
                    <div style={{ width: "320px" }}>
                        <Input variant={"filled"} size={"small"} label={"Phone"} value={props.type == "staff" ? props.data.varEmployeeMobilePhone : props.data.varCustomerMobilePhone} />
                    </div>
                    <div>
                        <Button color="primary" variant="contained" onClick={() => sendMessage(2)}>
                            Send Via SMS &nbsp;
                        </Button>
                    </div>
                </div>
            </div>
            {checkPermission(
                <>
                    <p style={{ color: "#23262F", fontWeight: "600", fontSize: "14px", marginTop: "3rem" }}>Deactivate Account</p>
                    <p style={{ color: "#777E90", fontWeight: "400", fontSize: "12px", width: "600px" }}>
                        Deactivating an account means temporarily disabling the account, so this account will not be displayed on search results or anywhere else but can be enabled anytime later.
                    </p>
                    <div>
                        <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem", marginTop: "10px" }}>
                            <Button
                                color="danger"
                                variant="contained"
                                onClick={() => {
                                    handleDeactivate()
                                }}
                            >
                                Deactivate Account
                            </Button>
                        </div>
                    </div>
                </>,
                26
            )}
        </div>
    )
}

export default AccountSettings
