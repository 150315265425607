import { CloseButton } from "../CloseButton/CloseButton";
import { Avatar, Chip, Modal } from "../components";

const CustomerAlerts = (props: {
    isOpenAlertsModal;
    setIsAlertsModal: CallableFunction;
    data
}) => {
    return (
        <Modal open={props.isOpenAlertsModal} >
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column',  gap: '1rem', padding: "0 1rem 0 1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <p style={{ fontSize: "24px", fontWeight: "600", color: "#192252" }}>Alerts and comments</p>
                    <CloseButton onClick={() => props.setIsAlertsModal(false)} />
                </div>
                <div style={{display:"flex", gap:"10px", borderRadius:"8px", padding:"30px 10px 30px 10px", width:"300px"}}>
                <div>
                    <p style={{color:"#23262F", fontWeight:"600", fontSize:"14px"}}>Customer alerts</p> 
                
                    <div style={{display: 'flex', gap:"0.8rem", flexWrap: "wrap", paddingTop:"10px"}}>
                    {
                        (props?.data?.bitCustomerAlertAlwaysLate)? 
                            <div>            
                            <Chip size="small" color={'error'} label={'Always Late'} />
                            </div>: null    
                    }
                    {
                        (props?.data?.bitCustomerAlertDifficultToHandle)? 
                            <div>            
                            <Chip size="small" color={'error'} label={'Difficult To Handle'} />
                            </div>: null    
                    }
                    {
                        (props?.data?.bitCustomerAlertDiscountOnFile)? 
                            <div>            
                            <Chip size="small" color={'error'} label={'Discount On File'} />
                            </div>: null    
                    }
                    {
                        (props?.data?.bitCustomerAlertNoShow)? 
                            <div>            
                            <Chip size="small" color={'error'} label={'No Shows'} />
                            </div>: null    
                    }
                    {
                        (props?.data?.bitCustomerAlertOutstandingBill)? 
                            <div>            
                            <Chip size="small" color={'error'} label={'Outstanding Bill'} />
                            </div>: null    
                    }
                    {
                        (props?.data?.bitCustomerAlertVerbalConfirmationNeeded)? 
                            <div>            
                            <Chip size="small" color={'error'} label={'Verbal Confirmation Needed'} />
                            </div>: null    
                    }
                    </div>

                    <div style={{ paddingTop: '1rem', marginBottom: '1.5rem', borderBottom: '1px solid #F3F4F6' }}></div>   

                    <p style={{color:"#23262F", fontWeight:"600", fontSize:"14px"}}>Customer comments</p> 

                    {
                        (props?.data?.varCustomerComments === null) ? 
                            <p>No comments found.</p>
                        : 
                            <div style={{ borderRadius: '4px', gap: '.5rem', display: 'flex', flexDirection: 'column' }}>
                                <p style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px' }}>{props?.data?.varCustomerComments}</p>
                            </div>
                    }

                </div>
                
                </div>
            </div>
        </Modal>
    );
}

export default CustomerAlerts;