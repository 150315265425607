const permissions: any = {
    1: "11101", //Dashboard - Pending 
    2: "11011", //New Booking - Pending 
    3: "11101", //Add New Customer Button
    4: "11010", //New Booking Menu
    5: "11010", //Customer Profile Menu
    6: "11010", //Pet Profile Menu
    7: "11010", //Customer Profile Personal Detail Tab
    8: "11010", //Customer Profile Alerts and Comments Tab
    9: "11010", //Customer Profile Linked Accounts Tab
    10: "11010", //Customer Profile Payment Details Tab
    11: "11010", //Customer Profile Account Settings Tab 
    12: "11010", //Customer Profile Notification Tab 
    13: "11010", //Customer Profile Preview/Edit Button 
    14: "11010", //Customer Profile Detail Salutation Plus Button
    15: "11010", //Customer Profile Detail Relationship Plus Button
    16: "11010", //Customer Profile Detail Type Plus Button
    17: "11010", //Customer Profile Detail  Add New Address Button
    18: "11010", //Customer Profile Detail  Save Button
    19: "11010", //Customer Profile Linked Accounts Tab Unlink Button
    20: "11010", //Customer Profile Payment Tab Default Button
    21: "11010", //Customer Profile Payment Tab Active Button
    22: "11010", //Customer Profile Payment Tab Billing drop down
    23: "11010", //Customer Profile Payment Tab Add New Card Button
    24: "11010", //Customer Profile Payment Tab Save Button
    26: "11010", //Customer Profile Accounts Tab Deactivate Button
    27: "11010", //Add New Pet Button
    28: "11010", //Pet Profile Personal Detail Tab
    29: "11010", //Pet Profile Medical/ Vaccine Tab 
    30: "11010", //Pet Profile Ownership Tab 
    31: "11010", //Pet Profile Preferences Tab 
    32: "11010", //Pet Profile Preview/Edit Button 
    33: "11010", //Pet Profile Details Pet Type  Plus Button
    34: "11010", //Pet Profile Details Pet Color Plus Button
    35: "11010", //Pet Profile Details Pet Size Plus Button
    36: "11010", //Pet Profile Details Add New Personality Button
    37: "11010", //Pet Profile Details Vet Plus Button
    38: "11010", //Pet Profile Details Save Button
    39: "11010", //Pet Profile Medical/Vaccination Tab Add Medical Condition Button
    40: "11010", //Pet Profile Medical/Vaccination Tab Add New Record Button
    41: "11010", //Pet Profile Medical/Vaccination Tab Edit Button
    42: "11010", //Pet Profile Medical/Vaccination Tab Save Button
    43: "11010", //Pet Profile Preferences Grooming Add Location Button
    44: "11010", //Pet Profile Preferences Boarding  Add Location Button
    45: "11010", //Pet Profile Preferences Daycare Add Location Button
    46: "11010", //Pet Profile Preferences Sitting Add Location Button
    47: "11010", //Pet Profile Preferences Training Add Location Button
    48: "11010", //Pet Profile Preferences Add New Groomer Button
    49: "11010", //Pet Profile Preferences Add New Bather Button
    50: "11010", //Pet Profile Preferences Add New Bather Button
    51: "11010", //Directories - Main - Pending 
    52: "11010", //Customer Directory
    53: "11010", //Pet Directory
    54: "11010", //Salon Directory
    55: "11010", //Employee Directory
    56: "11010", //Customer Directory Excel Download Button
    57: "11010", //Customer Directory PDF Download Button
    59: "11010", //Pet Directory Excel Download Button
    60: "11010", //Pet Directory PDF Download Button
    62: "11010", //Salon Directory Excel Download Button
    63: "11010", //Salon Directory PDF Download Button
    64: "11010", //Salon Profile Preview/Edit Button
    65: "11010", //Employee Directory Excel Download Button
    66: "11010", //Employee Directory PDF Download Button
    67: "11010", //Employee Profile Preview/Edit Button
    68: "11010", //Settings - Pending 
    69: "11010", //New booking Inquiry
    70: "11010", //Customer Invoices
}

export const checkPermission = (component, id) => {

    if (id === undefined) {
        return component
    } else {
        if (isActive(id)) {
            return component
        }
        else {
            return <></>
        }
    }

}

export const checkPermissionTabs = (component, id) => {
    if (isActive(id)) {
        return component
    }
    else {
        return;
    }
}

export const isActive = (id) => {
    if (id === undefined) {
        return true
    } else {
        if (parseInt(permissions[id][0]))
            return true
        else
            return false
    }
}

export const isView = (id) => {

    if (parseInt(permissions[id][1]))
        return true
    else
        return false
}

export const isCreate = (id) => {

    if (parseInt(permissions[id][2]))
        return true
    else
        return false
}

export const isUpdate = (id) => {

    if (parseInt(permissions[id][3]))
        return true
    else
        return false
}

export const isDelete = (id) => {

    if (parseInt(permissions[id][4]))
        return true
    else
        return false
}

export const MainNavView = () => {

    for (let i = 0; i < Object.keys(permissions).length; i++) {
        if (parseInt(Object.keys(permissions)[i]) === 1) {
            if (parseInt(permissions[Object.keys(permissions)[i]][0])) {
                return "/";
            }
        } else {
            if (parseInt(Object.keys(permissions)[i]) === 2) {
                if (parseInt(permissions[Object.keys(permissions)[i]][0])) {
                    return "/booking";
                }
            } else {
                if (parseInt(Object.keys(permissions)[i]) === 51) {
                    if (parseInt(permissions[Object.keys(permissions)[i]][0])) {
                        return "/directory";
                    }
                } else {
                    if (parseInt(Object.keys(permissions)[i]) === 68) {
                        if (parseInt(permissions[Object.keys(permissions)[i]][0])) {
                            return "/admin";
                        }
                    }
                }
            }
        }
    }
}



