
import { Document, Page, Text, View, pdf, StyleSheet, Image } from '@react-pdf/renderer';
const generatePDF = async (wellness) => {

    const styles = StyleSheet.create({
        page: { backgroundColor: 'white' },
        section: { color: 'gray', textAlign: 'center', margin: 10 },
        normal_text_heading: { color: 'black', textAlign: 'left', margin: 30, fontSize:"13px" },
        normal_text_content: { color: 'black', textAlign: 'left', fontSize:"12px", margin: 4 },
        normal_text_desc: { color: 'gray', textAlign: 'left', fontSize: 8, margin: 4 },
        section_container: { display:"flex", justifyContent:"space-between" },
        table: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#d9d9d9', alignItems: 'center', padding: 5 },
        tableCell: { flexGrow: 1, textAlign: 'center' },
        columnHeader: { fontSize: 14 },
        bodyPartText: { fontSize: 8, color: 'black' },
        concernsText: { fontSize: 8, color: 'black' },
        image: { width: 200, height: 200, marginBottom: 10, marginLeft:5, borderRadius:8, objectFit:"cover" },
        heading: { fontSize: 12, marginBottom: 5, color: 'black', textAlign: 'center' },
    
        Logo_container: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },//Logo
        blueText: { color: '#2076ff', fontSize:"24px" },
        blackText: { color: '#002867', fontSize:"24px" },
      });
    
      const service_and_product_styles = StyleSheet.create({
        section: { margin: 5 },
        heading: { fontSize: 12, marginBottom: 5, color: 'black', textAlign: 'left' },
        item: { fontSize: 9, color: 'black', marginBottom: 2, textAlign: 'left' },
        description: { fontSize: 8, color: 'gray', textAlign: 'left' },
        cardView: { marginBottom:5 }
      });

      const renderWellnessData = () => (
        wellness.Wellness.map(bodyPart => (
          <View key={bodyPart.BodyPartID} style={styles.table}>
            <Text style={[styles.tableCell, styles.bodyPartText]}>{bodyPart.WellnessCheckBodyPartName}</Text>
            {bodyPart.Concerns.length === 0 ? (
              <Text style={[styles.tableCell, styles.concernsText]}>--</Text>
            ) : (
              <Text style={[styles.tableCell, styles.concernsText]}>
                {bodyPart.Concerns.filter(concern => concern.checked).map(concern => concern.ConcernName).join(', ')}
              </Text>
            )}
          </View>
        ))
      );
    
      const renderTable = () => (
        <View style={styles.table}>
          <Text style={[styles.tableCell, styles.columnHeader]}>Body Part</Text>
          <Text style={[styles.tableCell, styles.columnHeader]}>Concerns</Text>
        </View>
      );
    
      const renderRecommendedServices = () => (
        <View style={service_and_product_styles.section}>
          <Text style={service_and_product_styles.heading}>Recommended Services:</Text>
          {wellness.RecommendedServices.length === 0 ? (
            <Text style={service_and_product_styles.item}>No services recommended</Text>
          ) : (
            wellness.RecommendedServices.map(service => (
              <View key={service.ID} style={service_and_product_styles.cardView}>
                <Text style={service_and_product_styles.item}>{service.varServiceItemName}</Text>
                <Text style={service_and_product_styles.description}>{service.varServiceItemDescription}</Text>
              </View>
            ))
          )}
        </View>
      );
    
      const renderRecommendedProducts = () => (
        <View style={service_and_product_styles.section}>
          <Text style={service_and_product_styles.heading}>Recommended Products:</Text>
          {wellness.RecommendedProducts.length === 0 ? (
            <Text style={service_and_product_styles.item}>No products recommended</Text>
          ) : (
            wellness.RecommendedProducts.map(product => (
              <View key={product.ID} style={service_and_product_styles.cardView}>
                <Text style={service_and_product_styles.item}>{product.varProductName}</Text>
                <Text style={service_and_product_styles.description}>{product.varProductDescription}</Text>
              </View>
            ))
          )}
        </View>
      );
    
      const NotesForClient = () => (
        <View style={service_and_product_styles.section}>
          <Text style={service_and_product_styles.heading}>Notes for client:</Text>
          {wellness.NotesForClient === null || wellness.NotesForClient === "" ? (
            <Text style={service_and_product_styles.item}>--</Text>
          ) : (
              <View  style={service_and_product_styles.cardView}>
                <Text style={service_and_product_styles.item}>{wellness.NotesForClient}</Text>
              </View>
          )}
        </View>
      );
    
      const SuggestionsForTheHome = () => (
        <View style={service_and_product_styles.section}>
          <Text style={service_and_product_styles.heading}>Suggestions for the home:</Text>
          {wellness.Suggestions === null || wellness.Suggestions === "" ? (
            <Text style={service_and_product_styles.item}>--</Text>
          ) : (
              <View  style={service_and_product_styles.cardView}>
                <Text style={service_and_product_styles.item}>{wellness.Suggestions}</Text>
              </View>
          )}
        </View>
      );
    
      const renderImages = () => (
        <View style={styles.section}>
          <Text style={styles.heading}>Problem area pictures</Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
    
          {wellness.checkImageList.length === 0 ? (
            <Text style={service_and_product_styles.item}>No images attached</Text>
          ) : (
            wellness.checkImageList.map((image, index) => (
              <Image key={index} style={styles.image} src={`${process.env.REACT_APP_MEDIA_URL}wellness/${image.varImagePath}`} />
            ))
          )}
          </View>
        </View>
      );

  const pdfContent = (
    <Document>
    <Page size="A4" style={styles.page}>
    <View style={styles.Logo_container}>
        <Text style={styles.blueText}>ESy</Text>
        <Text style={styles.blackText}>Pet</Text>
      </View>
      <View style={styles.section}>
        <Text>Wellness Report</Text>
      </View>
      <View>
        <View style={styles.section}>
          <Text style={styles.normal_text_content}>Appointment ID: {wellness?.appointmentDetails?.appointmentID}</Text>
          <Text style={styles.normal_text_content}>Location: {wellness?.appointmentDetails?.location}</Text>
          <Text style={styles.normal_text_content}>Date: {wellness?.appointmentDetails?.startDate}</Text>
          <Text style={styles.normal_text_content}>Pet Name: {wellness?.appointmentDetails?.petName}</Text>
          {renderTable()}
          {renderWellnessData()}
          {renderRecommendedServices()}
          {renderRecommendedProducts()}
          {NotesForClient()}
          {SuggestionsForTheHome()}
          {renderImages()}
        </View>
      </View>
    </Page>
  </Document>
  );

  const blob = await pdf(pdfContent).toBlob();
  return blob;
};

export default generatePDF;