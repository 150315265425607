import { useEffect, useState } from "react";
import axios from "axios";

function Summery(props:{
    isPassedInvoice
    appDetails
    setRefresh?
}) {

    const [appointmentCharges, setAppointmentCharges] = useState([]) as any
    const [appointmentServices, setAppointmentServices] = useState([]) as any
    const [appointmentProducts, setAppointmentProducts] = useState([]) as any
    const [isLoading, setIsLoading] = useState(false)
    const [totPaid, setTotPaid] = useState<any>(0)
    const [totTax, setTotTax] = useState(0) as any
    const [totRemain, setTotRemain] = useState<any>(0)
    const [remainingTotal, setRemainingTotal] = useState(0)


    const GetInvoiceDetails = async (id, type) => {
        let para:any = {};
        if(type === "inv"){
            para = {
                numInvoiceID: id
            }
        }else{
            para = {
                numAppointmentID: id
            }
        }
        console.log("sent params to fetch-", para)
        const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetInvoiceDetails`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: para,
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
    };

    const fetchInvoiceData = (id , type) => {
        setIsLoading(true)
        if(id !== null && id !== undefined){
            GetInvoiceDetails(id , type)
            .then((data) => {
                console.log("res - summary", data);
                setAppointmentCharges(data)
                setTotPaid(data[0].totalPaid)
                setTotTax(data[0].taxTotal)
                setIsLoading(false)
                const appointmentServices:any = [];
                const appointmentProducts:any = [];

                data.forEach(item => {
                    if (item.varServiceOrProduct === "S") {
                        appointmentServices.push(item);
                    } else if (item.varServiceOrProduct === "P") {
                        appointmentProducts.push(item);
                    }
                });
                setAppointmentProducts(appointmentProducts)
                setAppointmentServices(appointmentServices)
            })
            .catch((error) => {
                console.log("error", error);
            });
        }else{
            console.log("cancelled")
        }
    }

    useEffect(()=>{
        if (props.isPassedInvoice === true) {
            fetchInvoiceData(props.appDetails.numInvoiceID, "inv");
        
        } else {
                fetchInvoiceData(props.appDetails.numAppointmentID, "app");
        }
    }, [])

    useEffect(()=>{
        if(props.setRefresh !== undefined && props.setRefresh !== "" && props.setRefresh !== null){
            if (props.isPassedInvoice === true) {
                fetchInvoiceData(props.appDetails.numInvoiceID, "inv");
            
            } else {
                    fetchInvoiceData(props.appDetails.numAppointmentID, "app");
            }
        }
    },[props.setRefresh])

    useEffect(()=>{
        if(appointmentCharges.length > 0) {
            let amt = 0
            let totRemainAmt = 0;
            appointmentCharges.map((charge)=>{
                amt = amt + parseFloat(charge.remaining)
                totRemainAmt = totRemainAmt + parseFloat(charge.remaining)
            })
            setRemainingTotal(amt)
            setTotRemain(totRemainAmt)
        }
    },[appointmentCharges])

    return (
        <div style={{ padding: '0 15px' }}>
            <p style={{ fontWeight: '600', fontSize: '14px' }}>Services</p>
            <table style={{ fontSize: '14px', borderBottom: '1px solid #E5E7EB', paddingBottom: '.25rem', marginBottom: '.25rem', width: "100%" }}>
                {       
                    (appointmentServices !== undefined)?
                    (appointmentServices.length > 0)?
                    appointmentServices.map(items => {
                        return  <tr>
                            <td style={{ width: '100%' }}>{items?.varServiceOrProductName}</td>
                            <td style={{ textAlign: 'right' }}>${items?.decChargeAmount}</td>
                        </tr>
                    }) : <p>--</p>
                    : <></>
                }                                                           
            </table>
            <p style={{ fontWeight: '600', fontSize: '14px', marginTop:"10px" }}>Products</p>
            <table style={{ fontSize: '14px', borderBottom: '1px solid #E5E7EB', paddingBottom: '.25rem', marginBottom: '.25rem', width: "100%" }}>
                {        
                    (appointmentProducts !== undefined)?  
                    (appointmentProducts.length > 0)?                                                                          
                    appointmentProducts.map(items => {
                        return  <tr>
                            <td style={{ width: '100%', display:"flex", alignItems:"center" }}>{items?.varServiceOrProductName} <p style={{fontWeight: 500, color: '#6B7280', margin: '0'}}>&nbsp;({items?.numQuantity}x)</p></td>
                            <td style={{ textAlign: 'right' }}>${items?.decChargeAmt}</td>
                        </tr>
                    }) : <p>--</p>
                    : <></>
                }                                                           
            </table>
            <table style={{ fontSize: '14px', width:"100%" }}>
                <tr>
                    <td style={{ width:"100%", fontWeight:"500" }}>Tax</td>
                    <td style={{ width: '100%', fontSize: '14px', textAlign: 'left', color: '#002867', fontWeight:"600" }}>${parseFloat(totTax.toString()).toFixed(2)}</td>
                </tr>
                <tr>
                    <td style={{ width:"100%", fontWeight:"500" }}>Total Amount</td>
                    <td style={{ width: '100%', fontSize: '14px', textAlign: 'left', color: '#002867', fontWeight:"600" }}>${parseFloat((totPaid + totRemain).toString()).toFixed(2)}</td>
                </tr>
                <tr>
                    <td style={{ width:"100%", fontWeight:"500" }}>Paid Amount</td>
                    <td style={{ width: '100%', fontSize: '14px', textAlign: 'left', color: '#002867', fontWeight:"600" }}>${parseFloat(totPaid.toString()).toFixed(2)}</td>
                </tr>
                <tr>
                    <td style={{ width:"100%", fontWeight:"500" }}>Balance Amount</td>
                    <td style={{ width: '100%', fontSize: '14px', textAlign: 'left', color: '#002867', fontWeight:"600" }}>${parseFloat(totRemain.toString()).toFixed(2)}</td>
                </tr>
                
            </table>                                                        
    </div>
    );
}

export default Summery;