import { useRef, useState, useEffect } from "react"
import { Button, Input } from "../../components"
import useAuth from "../../hooks/useAuth"
import styles from "./Login.module.scss"
import { ToastContainer, toast } from "react-toastify"
import { Link, useNavigate, useLocation } from "react-router-dom"
import bImage from "./login-background.webp"
import axios from "../../apis/axios"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import esypetlogo from "./esypet-logo.png"

const Login = () => {
    const { auth, setAuth } = useAuth() as any

    const navigate = useNavigate()
    const location = useLocation() as any

    const [loggingIn, setLoggingIn] = useState(false)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const loginRef = useRef<HTMLInputElement | null>(null)

    const [type, setType] = useState(false)

    useEffect(() => {
        if (loginRef.current) {
            loginRef.current.focus()
        }
    }, [])

    const handleLink = (page) => {
        navigate(page)
    }

    useEffect(() => {
        setErrorMessage("")
    }, [username, password])

    const handleToggle = () => {
        setType((prevState) => !prevState)
    }

    async function loginAccount() {
        const response = await axios.post("/Auth", JSON.stringify({ username, password }), {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })

        return response
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoggingIn(true)

        if (localStorage.getItem("hasVisitedWelcome")) {
            localStorage.removeItem("hasVisitedWelcome")
        }

        loginAccount()
            .then((res: any) => {
                console.log("login", res)
                let accessToken = res?.data?.varJwtToken

                const roles = [123, 456, 789]

                setAuth({ username, roles, accessToken, loggedIn: true, employee: res?.data?.employee })

                setUsername("")
                setPassword("")

                setLoggingIn(false)
                navigate("/welcome", { replace: true })
            })
            .catch((err) => {
                console.log("login", err)
                setLoggingIn(false)

                toast.error(err?.response?.data?.error?.errorMessage)
            })
    }

    return (
        // <BookingLocation/>
        <div className={styles.loginContainer}>
            <div className={styles.header}>
              {/* //  <span className={styles.lightBlue}>ESy</span>
              //  <span className={styles.darkBlue}>Pet</span> */}
                <img src={esypetlogo} alt="EsyPet Logo"></img>
            </div>
            <ToastContainer />
            <div className={styles.box}>
                <form onSubmit={handleSubmit}>
                    <div className={styles.loginBox}>
                        <h4>Staff Login</h4>
                        <p>Please enter your credentials to sign in.</p>
                        <div className={styles.spacer}></div>
                        <div className={styles.spacer}></div>
                        <Input
                            onChange={(val) => {
                                setUsername(val)
                            }}
                            label={"Username"}
                            value={username}
                            ref={loginRef}
                        />
                        <div className={styles.spacer}></div>
                        <Input
                            onChange={(val) => {
                                setPassword(val)
                            }}
                            value={password}
                            label={"Password"}
                            type={type ? "text" : "password"}
                        />
                        <div className={styles.eyeIcon} onClick={handleToggle}>
                            {type ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                        </div>
                        <Button color="primary" type="submit" loading={loggingIn} className={styles.loginBtn} variant={"contained"}>
                            Login
                        </Button>

                        <p onClick={() => handleLink("/forgot-password")} className={styles.forgotPassword}>
                            Forgot Password
                        </p>
                    </div>
                    <div className={styles.footer}>
                        Copyright©{" "}
                        <a href="https://www.esyntaxis.com/" target="_blank">
                            ESyntaxis Corporation
                        </a>
                        . ESyPet Version {process.env.REACT_APP_VERSION}
                    </div>
                </form>
            </div>
            <div className={styles.graphic}></div>
        </div>
    )
}

export default Login
