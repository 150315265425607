import { Chip } from "../../components/Chip/Chip"
import { Chip as CustomChip } from "../../../components"
/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

/*
    "varPetTypeName": "Dog",
    "varPetTypeDescription": null,
    "bitPetTypeActive": true
*/

export const SalesItems: {} = {
    tableName: "5.2 - Sales Items",
    hiddenColumns: [
        "numLocationProductID",
        "numProductID",
        "locID",
        "dtEffectiveDate",
        "dtExpiryDate",
        "varLinkToPromotionMaterial",
        "varLinkToGlobalCoupon",
        "varMessageToEmployee",
        "varPromotionalMessage",
        "bitLocationSpecificAdjustment",
        "dtLocationSpecificAdjustmentEffectiveDate",
        "dtLocationSpecificAdjustmentExpiryDate",
        "btLocationSpecificAdjustmentPercent",
        "numLocationSpecificAdjustmentPercent",
        "btLocationSpecificAdjustmentAmount",
        "numLocationSpecificAdjustmentAmount",
        "btDateSpecificAdjustment",
        "dtDateSpecificAdjustmentEffectiveDate",
        "dtDateSpecificAdjustmentExpiryDate",
        "btDateSpecificAdjustmentPercent",
        "numDateSpecificAdjustmentPercent",
        "btDateSpecificAdjustmentAmount",
        "numDateSpecificAdjustmentAmount",
        "btSalesCommissionPercent",
        "numSalesCommissionPercent",
        "btSalesCommissionAmount",
        "numSalesCommissionAmount",
        "bitAutoReOrder",
        "numMinimumQuantityReOrderLevel",
        "numMaximumLocationQuantity",
    ],
    pdfTemplate: ["varLocation", "varProductBarCode", "varProduct"],
    template: [
        {
            Header: "Location",
            accessor: "varLocation",
        },
        {
            Header: "BarCode",
            accessor: "varProductBarCode",
        },
        {
            Header: "ProductName",
            accessor: "varProduct",
        },
        {
            Header: "Purchase Price",
            accessor: "numPurchasePrice",
        },
        {
            Header: "Sales Price",
            accessor: "numSalesPrice",
        },
        {
            Header: "Available Quantity",
            accessor: "numAvailableQuantity",
        },
        {
            Header: "Taxable Item",
            accessor: "bitTaxableItem",
            Cell: (props) => <CustomChip size="small" customBackgroundColor={"#2076ff"} customBorderRadius={"12px"} fontColor={"#FFFF"} label={props?.cell?.value ? "Yes" : "No"} />,
        },
        {
            Header: "Promotional Item",
            accessor: "bitActivePromotionFlag",
            Cell: (props) => <CustomChip size="small" customBackgroundColor={"#2076ff"} customBorderRadius={"12px"} fontColor={"#FFFF"} label={props?.cell?.value ? "Yes" : "No"} />,
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numProductID",
            accessor: "numProductID",
        },
        {
            Header: "locID",
            accessor: "locID",
        },
        {
            Header: "dtEffectiveDate",
            accessor: "dtEffectiveDate",
        },
        {
            Header: "dtExpiryDate",
            accessor: "dtExpiryDate",
        },
        {
            Header: "varLinkToPromotionMaterial",
            accessor: "varLinkToPromotionMaterial",
        },
        {
            Header: "varLinkToGlobalCoupon",
            accessor: "varLinkToGlobalCoupon",
        },
        {
            Header: "varMessageToEmployee",
            accessor: "varMessageToEmployee",
        },
        {
            Header: "varPromotionalMessage",
            accessor: "varPromotionalMessage",
        },
        {
            Header: "bitLocationSpecificAdjustment",
            accessor: "bitLocationSpecificAdjustment",
        },
        {
            Header: "dtLocationSpecificAdjustmentEffectiveDate",
            accessor: "dtLocationSpecificAdjustmentEffectiveDate",
        },
        {
            Header: "dtLocationSpecificAdjustmentExpiryDate",
            accessor: "dtLocationSpecificAdjustmentExpiryDate",
        },
        {
            Header: "btLocationSpecificAdjustmentPercent",
            accessor: "btLocationSpecificAdjustmentPercent",
        },
        {
            Header: "numLocationSpecificAdjustmentPercent",
            accessor: "numLocationSpecificAdjustmentPercent",
        },
        {
            Header: "btLocationSpecificAdjustmentAmount",
            accessor: "btLocationSpecificAdjustmentAmount",
        },
        {
            Header: "numLocationSpecificAdjustmentAmount",
            accessor: "numLocationSpecificAdjustmentAmount",
        },
        {
            Header: "btDateSpecificAdjustment",
            accessor: "btDateSpecificAdjustment",
        },
        {
            Header: "dtDateSpecificAdjustmentEffectiveDate",
            accessor: "dtDateSpecificAdjustmentEffectiveDate",
        },
        {
            Header: "dtDateSpecificAdjustmentExpiryDate",
            accessor: "dtDateSpecificAdjustmentExpiryDate",
        },
        {
            Header: "btDateSpecificAdjustmentPercent",
            accessor: "btDateSpecificAdjustmentPercent",
        },
        {
            Header: "numDateSpecificAdjustmentPercent",
            accessor: "numDateSpecificAdjustmentPercent",
        },
        {
            Header: "btDateSpecificAdjustmentAmount",
            accessor: "btDateSpecificAdjustmentAmount",
        },
        {
            Header: "numDateSpecificAdjustmentAmount",
            accessor: "numDateSpecificAdjustmentAmount",
        },
        {
            Header: "btSalesCommissionPercent",
            accessor: "btSalesCommissionPercent",
        },
        {
            Header: "numSalesCommissionPercent",
            accessor: "numSalesCommissionPercent",
        },
        {
            Header: "btSalesCommissionAmount",
            accessor: "btSalesCommissionAmount",
        },
        {
            Header: "numSalesCommissionAmount",
            accessor: "numSalesCommissionAmount",
        },
        {
            Header: "bitAutoReOrder",
            accessor: "bitAutoReOrder",
        },
        {
            Header: "numMinimumQuantityReOrderLevel",
            accessor: "numMinimumQuantityReOrderLevel",
        },
        {
            Header: "numMaximumLocationQuantity",
            accessor: "numMaximumLocationQuantity",
        },

        {
            Header: "numLocationProductID",
            accessor: "numLocationProductID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numLocationProductID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varProductBarCode",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numProductID",
            type: "advanced",
            inputType: "text",
            objectName: "SalesItemsLibrary",
            required: true,
            value: "",
            altName: "numProduct",
            isCustomFields: true,
            customFieldID: "numProductID",
            customFieldName: "varProductName",
        },
        {
            fieldName: "locID",
            type: "advanced",
            inputType: "text",
            objectName: "Location",
            required: true,
            value: "",
            altName: "numLocation",
        },
        {
            fieldName: "dtEffectiveDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "dtExpiryDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numPurchasePrice",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numSalesPrice",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitTaxableItem",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value: false,
        },
        {
            fieldName: "numAvailableQuantity",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActivePromotionFlag",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value: false,
        },
        {
            fieldName: "varLinkToPromotionMaterial",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varLinkToGlobalCoupon",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varMessageToEmployee",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varPromotionalMessage",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitLocationSpecificAdjustment",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value: false,
        },
        {
            fieldName: "dtLocationSpecificAdjustmentEffectiveDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "dtLocationSpecificAdjustmentExpiryDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "btLocationSpecificAdjustmentPercent",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numLocationSpecificAdjustmentPercent",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "btLocationSpecificAdjustmentAmount",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numLocationSpecificAdjustmentAmount",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "btDateSpecificAdjustment",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value: false,
        },
        {
            fieldName: "dtDateSpecificAdjustmentEffectiveDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "dtDateSpecificAdjustmentExpiryDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "btDateSpecificAdjustmentPercent",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numDateSpecificAdjustmentPercent",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "btDateSpecificAdjustmentAmount",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numDateSpecificAdjustmentAmount",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "btSalesCommissionPercent",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numSalesCommissionPercent",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "btSalesCommissionAmount",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numSalesCommissionAmount",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "bitAutoReOrder",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value: false,
        },
        {
            fieldName: "numMinimumQuantityReOrderLevel",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numMaximumLocationQuantity",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
    ],
}
