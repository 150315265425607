import styles from "../../Help.module.scss";


function HelpLibrary (){

    return(
        
      <div>
      <h3 style={{ fontWeight: '900', fontSize: '26px' }}> Library</h3>
         <br />
         <div  id={styles.container}>
                  <p>The below information is available under this module:</p>
                  <br />
                  <div><img src={require('./assets/library1.png')} alt=""/></div>
                  <br />

                  <p>The information here is imported by clicking on the ‘Import/Export’ icon on the right-hand corner of this module. The data imported is what was uploaded through the ‘Import / Export Data’ module.</p>
                  <br />
                  <div><img src={require('./assets/library2.png')} alt=""/></div>
                  <br />
                  <p> The other two are the ‘Download PDF’ and the ‘Filter’ icons. The search box is for specific words.</p>
                  <div style={{width:'400px',height:'100px',margin:'auto'}}><img src={require('./assets/library3.png')} alt=""/></div>
                  <br />
                  <br />
                  <p> Clicking on each Menu header label will list out information available in the database, which has been uploaded through the ‘Import / Export Data’ module.</p>
                  <br /><br />
                  
                  
         </div>

   </div>
    );

}

export default HelpLibrary;