import moment from "moment";

export function formatTemplate(template, data) {
  const regex = /{{\s*([a-zA-Z0-9.]+)\s*}}/g;
  const formattedTemplate = template.replace(regex, (match, path) => {
    const keys = path.split('.');
    let value = data;
    for (const key of keys) {
      if (Array.isArray(value) && value.length > 0) {
        // If the value is an array and not empty
        if (key === 'AllVaccines' || key === 'ExpiredVaccines' || key === 'Unexpired') {
          const filteredVaccines = key === 'ExpiredVaccines'
            ? value.filter(vaccine => vaccine.IsExpired === 1)
            : key === 'Unexpired'
              ? value.filter(vaccine => vaccine.IsExpired === 0)
              : value;
          
          if (filteredVaccines.length > 0) {
            const heading = key === 'AllVaccines'
              ? 'All Vaccine Records'
              : key === 'ExpiredVaccines'
                ? 'Expired Vaccine Records'
                : 'Unexpired Vaccine Records';
              
            const vaccines = filteredVaccines.map(vaccine => {

              const vaccineGivenDate = moment(vaccine.VaccineGivenDate).format('YYYY-MM-DD');
              const vaccineExpireDate = moment(vaccine.VaccineExpireDate).format('YYYY-MM-DD');

              return `<tr><td style="font-size:12px; padding: 8px; border: 1px solid #dddddd; width: auto;">${vaccine.VaccineName}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd; width: auto;">${vaccineGivenDate}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd; width: auto;">${vaccineExpireDate}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd; width: auto;"><a href="${process.env.REACT_APP_MEDIA_URL}vaccination_records/${vaccine.DocumentPath}" target="_blank">${vaccine.DocumentDisplayName}</a></td></tr>`;
            });
            return `<h5>${heading}</h5><table style="border-collapse: collapse; width: 100%;"><tr style="background-color: #f2f2f2;"><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd; width: auto;">Vaccine Name</th><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd; width: auto;">Given Date</th><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd; width: auto;">Expire Date</th><th style="font-size:14px; padding: 8px; border: 1px solid #dddddd; width: auto;">Document</th></tr>${vaccines.join('')}</table>`;
          } else {
            return ''; // No vaccines to display
          }
        }
      } else if (value && typeof value === 'object' && value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        return ''; // Property not found or invalid type
      }
    }
    return value === null ? '' : value;
  });
  return formattedTemplate;
}
// export function formatTemplate(template, data) {
//     const regex = /{{\s*([a-zA-Z0-9.]+)\s*}}/g;
//     const formattedTemplate = template.replace(regex, (match, path) => {
//       const keys = path.split('.');
//       let value = data;
//       for (const key of keys) {
//         if (Array.isArray(value) && value.length > 0) {
//           // If the value is an array and not empty
//           if (key === 'AllVaccines' || key === 'ExpiredVaccines' || key === 'Unexpired') {
//             const filteredVaccines = key === 'ExpiredVaccines'
//               ? value.filter(vaccine => vaccine.IsExpired === 1)
//               : key === 'Unexpired'
//                 ? value.filter(vaccine => vaccine.IsExpired === 0)
//                 : value;
            
//             if (filteredVaccines.length > 0) {
//               const heading = key === 'AllVaccines'
//                 ? 'All Vaccine Records'
//                 : key === 'ExpiredVaccines'
//                   ? 'Expired Vaccine Records'
//                   : 'Unexpired Vaccine Records';
                
//               const vaccines = filteredVaccines.map(vaccine => {

//                 const vaccineGivenDate = moment(vaccine.VaccineGivenDate).format('YYYY-MM-DD');
//                 const vaccineExpireDate = moment(vaccine.VaccineExpireDate).format('YYYY-MM-DD');
  
//                 return `<tr><td style="font-size:12px; padding: 8px; border: 1px solid #dddddd;">${vaccine.VaccineName}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd;">${vaccineGivenDate}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd;">${vaccineExpireDate}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd;"><a href="${process.env.REACT_APP_MEDIA_URL}vaccination_records/${vaccine.DocumentPath}" target="_blank">${vaccine.DocumentDisplayName}</a></td></tr>`;
//               });
//               return `<h5>${heading}</h5><table style="border-collapse: collapse; width: 100%;"><tr style="background-color: #f2f2f2;"><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd;">Vaccine Name</th><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd;">Given Date</th><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd;">Expire Date</th><th style="font-size:14px; padding: 8px; border: 1px solid #dddddd;">Document</th></tr>${vaccines.join('')}</table>`;
//             } else {
//               return ''; // No vaccines to display
//             }
//           }
//         } else if (value && typeof value === 'object' && value.hasOwnProperty(key)) {
//           value = value[key];
//         } else {
//           return ''; // Property not found or invalid type
//         }
//       }
//       return value;
//     });
//     return formattedTemplate;
//   }



// export function formatTemplate(template, data) {
//     const regex = /{{\s*([a-zA-Z0-9.]+)\s*}}/g;
//     const formattedTemplate = template.replace(regex, (match, path) => {
//         const keys = path.split('.');
//         let value = data;
//         for (const key of keys) {
//             if (Array.isArray(value) && value.length > 0) {
//                 if (key === 'AllVaccines' || key === 'ExpiredVaccines' || key === 'Unexpired') {
//                     const filteredVaccines = key === 'ExpiredVaccines'
//                         ? value.filter(vaccine => vaccine.IsExpired === 1)
//                         : key === 'Unexpired'
//                             ? value.filter(vaccine => vaccine.IsExpired === 0)
//                             : value;

//                     if (filteredVaccines.length > 0) {
//                         const heading = key === 'AllVaccines'
//                             ? 'All Vaccine Records'
//                             : key === 'ExpiredVaccines'
//                                 ? 'Expired Vaccine Records'
//                                 : 'Unexpired Vaccine Records';

//                         const vaccines = filteredVaccines.map(vaccine => {
//                             const vaccineGivenDate = moment(vaccine.VaccineGivenDate).format('YYYY-MM-DD');
//                             const vaccineExpireDate = moment(vaccine.VaccineExpireDate).format('YYYY-MM-DD');
//                             return `<tr><td style="font-size:12px; padding: 8px; border: 1px solid #dddddd;">${vaccine.VaccineName}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd;">${vaccineGivenDate}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd;">${vaccineExpireDate}</td><td style="font-size:12px; padding: 4px; border: 1px solid #dddddd;"><a href="${process.env.REACT_APP_MEDIA_URL}vaccination_records/${vaccine.DocumentPath}" target="_blank">${vaccine.DocumentDisplayName}</a></td></tr>`;
//                         });
//                         return `<h5>${heading}</h5><table style="border-collapse: collapse; width: 100%;"><tr style="background-color: #f2f2f2;"><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd;">Vaccine Name</th><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd;">Given Date</th><th style="font-size:14px; padding: 4px; border: 1px solid #dddddd;">Expire Date</th><th style="font-size:14px; padding: 8px; border: 1px solid #dddddd;">Document</th></tr>${vaccines.join('')}</table>`;
//                     } else {
//                         return '';
//                     }
//                 }
//             } else if (value && typeof value === 'object' && value.hasOwnProperty(key)) {
//                 value = value[key];
//             } else {
//                 return '';
//             }
//         }
//         return value;
//     });

//     // Function to format phone numbers as clickable links
//     const formatPhoneNumber = phoneNumber => {
//         return `<a href="tel:${phoneNumber.replace(/\D/g, '')}">${phoneNumber}</a>`;
//     };

//     // Replace Phone or phone in template with clickable phone number links
//     const formattedWithPhoneLinks = formattedTemplate.replace(/Phone|phone/g, match => {
//         for (const key in data) {
//             if (data.hasOwnProperty(key) && key.toLowerCase().includes('phone')) {
//                 return formatPhoneNumber(data[key]);
//             }
//         }
//         return match;
//     });

//     return formattedWithPhoneLinks;
// }



  // function formatTemplate(template, data) {
//   const regex = /{{\s*([a-zA-Z0-9.]+)\s*}}/g;
//   const formattedTemplate = template.replace(regex, (match, path) => {
//       const keys = path.split('.');
//       let value = data;
//       for (const key of keys) {
//           if (value.hasOwnProperty(key)) {
//               value = value[key];
//           } else {
//               return '';
//           }
//       }
//       return value;
//   });
//   return formattedTemplate;
// }

// function formatTemplate(template, data) {
//     const regex = /{{\s*([a-zA-Z0-9.]+)\s*}}/g;
//     const formattedTemplate = template.replace(regex, (match, path) => {
//       const keys = path.split('.');
//       let value = data;
//       for (const key of keys) {
//         if (value.hasOwnProperty(key)) {
//           if (key === 'Vaccines' && Array.isArray(value[key]) && value[key].length > 0) {
//             const vaccines = value[key].map(vaccine => {
//               return `<tr><td>${vaccine.varVaccineName}</td><td>${vaccine.varVaccineDescription}</td></tr>`;
//             });
//             return `<table border="1"><tr><th>Vaccine Name</th><th>Vaccine Description</th></tr>${vaccines.join('')}</table>`;
//           } else {
//             value = value[key];
//           }
//         } else {
//           return '';
//         }
//       }
//       return value;
//     });
//     return formattedTemplate;
//   }

//SUB ash value

// function formatTemplate(template, data) {
//     const regex = /{{\s*([a-zA-Z0-9.]+)\s*}}/g;
//     const formattedTemplate = template.replace(regex, (match, path) => {
//       const keys = path.split('.');
//       let value = data;
//       for (const key of keys) {
//         if (value.hasOwnProperty(key)) {
//           if (key === 'Vaccines' && Array.isArray(value[key]) && value[key].length > 0) {
//             const vaccines = value[key].map(vaccine => {
//               return `<tr><td>${vaccine.varVaccineName}</td><td>${vaccine.varVaccineDescription}</td></tr>`;
//             });
//             return `<table border="1"><tr><th>Vaccine Name</th><th>Vaccine Description</th></tr>${vaccines.join('')}</table>`;
//           } else if (key === 'ExpiredVaccines' && Array.isArray(value[key]) && value[key].length > 0) {
//             const expiredVaccines = value[key].filter(vaccine => vaccine.IsExpired === 1).map(vaccine => {
//               return `<tr><td>${vaccine.varVaccineName}</td><td>${vaccine.varVaccineDescription}</td></tr>`;
//             });
//             return `<table border="1"><tr><th>Vaccine Name</th><th>Vaccine Description</th></tr>${expiredVaccines.join('')}</table>`;
//           } else {
//             value = value[key];
//           }
//         } else {
//           return '';
//         }
//       }
//       return value;
//     });
//     return formattedTemplate;
//   }

// function formatTemplate(template, data) {
//     const regex = /{{\s*([a-zA-Z0-9.]+)\s*}}/g;
//     const formattedTemplate = template.replace(regex, (match, path) => {
//       const keys = path.split('.');
//       let value = data;
//       for (const key of keys) {
//         if (Array.isArray(value) && value.length > 0) {
//           // If the value is an array and not empty
//           if (key === 'AllVaccines' || key === 'ExpiredVaccines' || key === 'Unexpired') {
//             const filteredVaccines = key === 'ExpiredVaccines'
//               ? value.filter(vaccine => vaccine.IsExpired === 1)
//               : key === 'Unexpired'
//                 ? value.filter(vaccine => vaccine.IsExpired === 0)
//                 : value;
            
//             if (filteredVaccines.length > 0) {
//               const heading = key === 'AllVaccines'
//                 ? 'All Vaccine Records'
//                 : key === 'ExpiredVaccines'
//                   ? 'Expired Vaccine Records'
//                   : 'Unexpired Vaccine Records';
                
//               const vaccines = filteredVaccines.map(vaccine => {
//                 return `<tr><td>${vaccine.VaccineName}</td><td>${vaccine.VaccineDescription}</td></tr>`;
//               });
//               return `<h5>${heading}</h5><table border="1"><tr><th>Vaccine Name</th><th>Vaccine Description</th></tr>${vaccines.join('')}</table>`;
//             } else {
//               return ''; // No vaccines to display
//             }
//           }
//         } else if (value && typeof value === 'object' && value.hasOwnProperty(key)) {
//           value = value[key];
//         } else {
//           return ''; // Property not found or invalid type
//         }
//       }
//       return value;
//     });
//     return formattedTemplate;
//   }

//without date path
// function formatTemplate(template, data) {
//     const regex = /{{\s*([a-zA-Z0-9.]+)\s*}}/g;
//     const formattedTemplate = template.replace(regex, (match, path) => {
//       const keys = path.split('.');
//       let value = data;
//       for (const key of keys) {
//         if (Array.isArray(value) && value.length > 0) {
//           // If the value is an array and not empty
//           if (key === 'AllVaccines' || key === 'ExpiredVaccines' || key === 'Unexpired') {
//             const filteredVaccines = key === 'ExpiredVaccines'
//               ? value.filter(vaccine => vaccine.IsExpired === 1)
//               : key === 'Unexpired'
//                 ? value.filter(vaccine => vaccine.IsExpired === 0)
//                 : value;
            
//             if (filteredVaccines.length > 0) {
//               const heading = key === 'AllVaccines'
//                 ? 'All Vaccine Records'
//                 : key === 'ExpiredVaccines'
//                   ? 'Expired Vaccine Records'
//                   : 'Unexpired Vaccine Records';
                
//               const vaccines = filteredVaccines.map(vaccine => {
//                 return `<tr><td style="padding: 8px; border: 1px solid #dddddd;">${vaccine.VaccineName}</td><td style="padding: 8px; border: 1px solid #dddddd;">${vaccine.VaccineDescription}</td></tr>`;
//               });
//               return `<h5>${heading}</h5><table style="border-collapse: collapse; width: 100%;"><tr style="background-color: #f2f2f2;"><th style="padding: 8px; border: 1px solid #dddddd;">Vaccine Name</th><th style="padding: 8px; border: 1px solid #dddddd;">Vaccine Description</th></tr>${vaccines.join('')}</table>`;
//             } else {
//               return ''; // No vaccines to display
//             }
//           }
//         } else if (value && typeof value === 'object' && value.hasOwnProperty(key)) {
//           value = value[key];
//         } else {
//           return ''; // Property not found or invalid type
//         }
//       }
//       return value;
//     });
//     return formattedTemplate;
//   }
