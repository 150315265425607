import styles from "../../Help.module.scss"

function CallCenter() {
    return (
        <div>
            <h3 style={{ fontWeight: "900", fontSize: "26px" }}>EsyPet Call Center Help Files</h3>

            <div id={styles.container}>
                <div>
                    <p>This document describes the functions of the Call Center Staff.</p>
                    <p>Access to this module will be by means of a User Name and Password.</p>
                </div>

                <div>
                    <img src={require("./assets/login.png")} alt="" />
                </div>

                <p>The Dashboard Directory screen will appear as follows:</p>

                <div>
                    <img src={require("./assets/directory.png")} alt="" />
                </div>

                <p> The Menu header labels appearing under the Directory are Customer, Pet, Salon and Employee.</p>
            </div>
        </div>
    )
}

export default CallCenter
