import { useState, useEffect, useMemo, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  LinearProgress,
  Tab,
  TablePagination,
  Tabs,
  Typography,
} from "@mui/material";
import axios, { axiosPrivate } from "../../../../../apis/axios";
import tableStyles from "../../../../../components/Table/Table.module.scss";
import {
  useTable,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from "react-table";
import {
  Button,
  Modal,
  SearchBox,
  Text,
} from "../../../../components";
import {
  ArrowRightAlt,
  FilterAltOutlined,
  RestartAlt,
  SearchOutlined,
  SortByAlpha,
} from "@mui/icons-material";
import { SpecialPriceListTemplate } from "./SpecialPriceListTemplate";
import { PDFHandler } from "../../../../../utils/PDFHandler";
import { downloadWithDataDirectories } from "../../../../../utils/ExcelHandler";
import AdvancedDropDown from "../../../../AdvancedDropDown/AdvancedDropDown";
import CloseButton from "../../../../Modal/CloseButton/CloseButton";
import Loading from "../../../components/Loading/Loading";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import FileUpload from "../../../../FileUpload/FileUpload";
import AsyncSelect from "react-select/async";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import useAuth from "../../../../../hooks/useAuth";

const SpecialPriceList = () => {
  const [data, setData] = useState([]) as any;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingType, setIsLoadingType] = useState(false);
  const [isOpenAdv, setIsOpenAdv] = useState(false);
  const [isLoadingSize, setIsLoadingSize] = useState(false);
  const [isLoadingBreed, setIsLoadingBreed] = useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [allLocations, setAllLocations] = useState<any>([]);
  const [totalRecordsPerLocation, setTotalRecordsPerLocation] = useState<any>(0);
  const [openImportExportModal, setOpenImportExportModal] = useState(false);
  const [groomingLocation, setGroomingLocation] = useState([]) as any;
  const [employeeList, setEmployeeList] = useState([]) as any;
  const [employeeListSelected, setEmployeeListSelected] = useState("") as any;
  const employeeRef = useRef<any>();
  const [serviceList, setServiceList] = useState([]) as any;
  const [serviceListSelected, setServiceListSelected] = useState([]) as any;

  const [typeList, setTypeList] = useState([]) as any;
  const [typeListSelected, setTypeListSelected] = useState([]) as any;

  const [breedList, setBreedList] = useState([]) as any;
  const [breedListSelected, setBreedListSelected] = useState([]) as any;

  const [sizeList, setSizeList] = useState([]) as any;
  const [sizeListSelected, setSizeListSelected] = useState([]) as any;

  const [locationListAll, setLocationListAll] = useState([]) as any;
  const [typeListAll, setTypeListAll] = useState([]) as any;
  const [serviceListAll, setServiceListAll] = useState([]) as any;

  const [tabIndex, setTabIndex] = useState(0);

  const [selectAllLocations, setSelectAllLocations] = useState(false);
  const [selectAllServices, setSelectAllServices] = useState(false);
  const [selectAllTypes, setSelectAllTypes] = useState(false);
  const [selectAllSizes, setSelectAllSizes] = useState(false);
  const [selectAllBreeds, setSelectAllBreeds] = useState(false);

  const [downloadingData, setDownloadingData] = useState(false);

  const { auth } = useAuth() as any

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(()=>{

    setGroomingLocation([{
      value: auth?.currentLocation?.value,
      label: auth?.currentLocation?.label,
    }])
  },[])

  async function getLocations(signal) {
    setIsLoading(true);
    const result = await axiosPrivate.get(`Location/GetAll`, {
      signal: signal,
    });
    return result.data.location;
  }

  async function getEmployees(data) {
    await axiosPrivate
      .post(`SpecialPriceList/GetEmployeeListByLocID`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        if (result.status == 200) {
          setEmployeeList(result?.data);
        }
      })
      .catch((error: any) => {
        console.log("Error:", error);
      });
  }

  async function getServicesAll(data) {
    await axiosPrivate
      .post(`SpecialPriceList/GetServiceListByLocID`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        if (result.status == 200) {
         // console.log("Serv-all", result);
          let arr = [] as any;
          result?.data.map((item, index) => {
            arr.push({
              serviceID: item?.numServiceItemID,
            });
          });
          setServiceListAll(arr);
        }
      })
      .catch((error: any) => {
        console.log("Error:", error);
      });
  }

  async function getServices(data) {
    setIsLoading(true);
    await axiosPrivate
      .post(`SpecialPriceList/GetServiceListByLocID`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        if (result.status === 200) {
          //console.log("Serv", result);
          setServiceList(result?.data);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function getTypesAll(data) {
    await axiosPrivate
      .post(`SpecialPriceList/GetPetTypeListByLocID`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        if (result.status == 200) {
          //console.log("Type-all", result);
          let arr = [] as any;
          result?.data.map((item, index) => {
            arr.push({
              typeID: item?.numPetTypeID,
            });
          });
          setTypeListAll(arr);
        }
      })
      .catch((error: any) => {
        console.log("Error:", error);
      });
  }

  async function getTypes(data) {
    setIsLoadingType(true);
    await axiosPrivate
      .post(`SpecialPriceList/GetPetTypeListByLocID`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        if (result.status == 200) {
          //console.log("Type", result);
          setTypeList(result?.data);
        }
      })
      .catch((error: any) => {
        console.log("Error:", error);
      })
      .finally(() => {
        setIsLoadingType(false);
      });
  }

  async function getSize(data) {
    setIsLoadingSize(true);
    console.log("passedSizeData", data)
    await axiosPrivate
      .post(`SpecialPriceList/GetPetSizeListByLocID`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        if (result.status == 200) {
          //console.log("Size", result);
          setSizeList(result?.data);
        }
      })
      .catch((error: any) => {
        console.log("Error:", error);
      })
      .finally(() => {
        setIsLoadingSize(false);
      });
  }

  async function getBreed(data) {
    //console.log("bD", data);
    setIsLoadingBreed(true);
    await axiosPrivate
      .post(`SpecialPriceList/GetPetBreedListByLocID`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        if (result.status == 200) {
          //console.log("Breed", result);
          setBreedList(result?.data);
        }
      })
      .catch((error: any) => {
        console.log("Error:", error);
      })
      .finally(() => {
        setIsLoadingBreed(false);
      });
  }

  useEffect(() => {
    if(groomingLocation.length > 0){
      getAdSearchData()
    }

  }, [page, rowsPerPage, groomingLocation]);

  useEffect(() => {
    const controller = new AbortController();
    fetchLocationData(controller.signal);
  }, []);

  const fetchLocationData = (signal) => {
    setIsLoadingLocation(true)
    getLocations(signal)
      .then((res) => {
        setAllLocations(res);
        //console.log("loca", res);
        let arr = [] as any;
        res.map((item, index) => {
          arr.push({
            locationID: item?.numLocationID,
          });
        });
       // console.log("locAll", arr);
        setLocationListAll(arr);
        let payload = {
          locationIds: arr,
        };
        getServicesAll(payload);
        getTypesAll(payload);
      })
      .catch((err) => {
        if (err.message != "canceled") {
          console.log(err);
        }
      }).finally(() => {
        setIsLoadingLocation(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const RenderTable = ({
    columns,
    data,
    tableName,
    hiddenColumns,
    addNew,
    downloadPDF,
    newAction,
    loadingData,
    objectName,
    rowClicked,
    searchFilter,
    hideSort,
    pdfColumns,
    pageNumber,
    pageSize,
  }) => {
    const [showFiltering, setShowFiltering] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);

    let exportData: any = [];

    const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
      return (
        <input
          className={tableStyles.searchFilter}
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder={`Search`}
        />
      );
    };

    const defaultColumn = useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      rows,
      setGlobalFilter,
      setAllFilters,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          hiddenColumns: hiddenColumns,
          pageSize: pageSize,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    useEffect(() => {
      if (!showFiltering) {
        setAllFilters([]);
      }
    }, [showFiltering]);

    useEffect(() => {
      filterGlobalData(searchVal);
    }, [searchVal]);

    useEffect(() => {
      setFilteredRows(rows);
    }, [rows]);

    useEffect(() => {
      filterGlobalData(searchFilter);
    }, [searchFilter]);

    const filterGlobalData = useAsyncDebounce((filter) => {
      setGlobalFilter(filter);
    }, 200);

    const appendForExport = () => {
      exportData = [];
      let appended = new Promise((resolve, reject) => {
        filteredRows.map((row: any) => {
          let obj = {};
          Object.keys(row.original).map((header) => {
            if (pdfColumns.includes(header.toString())) {
              obj[header] = row.original[header];
            }
          });
          exportData.push(obj);
        });
        console.log("EX", exportData);
        resolve(true);
      });

      appended
        .then((result) => {
          if (result) {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const exportExcel = () => {
      appendForExport();
      downloadWithDataDirectories(tableName, exportData);
    };

    const exportPDF = () => {
      appendForExport();
      PDFHandler(exportData, tableName);
    };


    const [isGrid, setIsGrid] = useState(false);

    return loadingData ? (
      <Loading
        isGrid={isGrid}
        activateGrid={false}
        downloadPDF={downloadPDF}
        headerGroups={headerGroups}
        hideSort={hideSort}
        newAction={newAction}
        objectName={objectName}
        rowHeight={50}
      />
    ) : (
      <>
        <div className={tableStyles.headerContainer}>
          <div className={tableStyles.actionContainer}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
              </div>
              <div className={tableStyles.optionsContainer}>
                <Button
                  color="default"
                  variant="outlined"
                  onClick={() => exportExcel()}
                >
                  Download XLSX
                </Button>
                {downloadPDF ? (
                  <Button
                    color="default"
                    variant="outlined"
                    onClick={() => exportPDF()}
                  >
                    Download PDF
                  </Button>
                ) : null}
                <Button
                  color="primary"
                  variant="contained"
                  // onClick={() => exportExcel()}
                  onClick={() => setOpenImportExportModal(true)}
                >
                  Import/Export
                </Button>
              </div>
            </div>

            <div className={tableStyles.filtersContainer}>
              <div>
                <Text color="p_900" weight="fw_400">
                  {`${rows.length} ${rows.length > 1 ? "results" : "result"}`}{" "}
                  found
                </Text>
              </div>
              <div className={tableStyles.searchContainer}>
                <SearchBox
                  onChange={(e) => setSearchVal(e)}
                  value={searchVal}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setShowFiltering(!showFiltering)}
                >
                  <FilterAltOutlined />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <table
          className={`${tableStyles.table} ${
            hideSort ? tableStyles.hideSort : null
          }`}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        {column.render("Header")}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <SortByAlpha
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              height: "16px",
                              cursor: "pointer",
                              color: "#005df1",
                            }}
                          />
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowRightAlt className={tableStyles.sortDesc} />
                            ) : (
                              <ArrowRightAlt className={tableStyles.sortAsc} />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {showFiltering ? (
                      <div style={{ padding: ".5rem 0" }}>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className={tableStyles.tableRow} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <>
                        {row.cells.length === index + 1 ? (
                          <td
                            onClick={() => {}}
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}{" "}
                          </td>
                        ) : (
                          <td
                            className={tableStyles.tableData}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        )}
                      </>
                    );
                  })}
                  {/* <td>
                    <Button
                      color="transparent"
                      variant="outlined"
                      onClick={() => {
                        rowClicked(i);
                      }}
                      type={"submit"}
                    >
                      Edit
                    </Button>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40]}
          component="div"
          count={totalRecordsPerLocation}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  const convertToLocationIDFormat = (data) => {
   // console.log("CD", data)
    return data.map((item) => ({ locationID: item.value }));
  };

  const convertToServiceIDFormat = (data) => {
    return data.map((item) => ({ serviceID: item.value }));
  };
  const convertToTypeIDFormat = (data) => {
    return data.map((item) => ({ typeID: item.value }));
  };
  const convertToSizeIDFormat = (data) => {
    return data.map((item) => ({ sizeID: item.value }));
  };
  const convertToBreedIDFormat = (data) => {
    return data.map((item) => ({ breedID: item.value }));
  };
  useEffect(() => {
    if (groomingLocation.length > 0) {
      let payload = {
        locationIds: convertToLocationIDFormat(groomingLocation),
      };


      let sizeBreedPayload = {
        locationIds: locationListAll,
        servicesIds: serviceListAll,
        typeIds: typeListAll,
      };

      // if(groomingLocation.length < 1){
      //     setServiceList([])
      //     setEmployeeList([])
      //     setTypeList([])
      // }

      getEmployees(payload);
      getServices(payload);
      getTypes(payload);
      if(locationListAll.length > 0 && serviceListAll.length > 0 && typeListAll.length > 0){
        getSize(sizeBreedPayload);
        getBreed(sizeBreedPayload);
      }
    }
  }, [groomingLocation, locationListAll, serviceListAll, typeListAll]);

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });

  async function getFile(data) {
    try {
      const result = await axiosPrivate.post(
        `SpecialPriceList/ExportSpecialPriceListCombinations`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          responseType: "blob",
        }
      );

      return result.data;
    } catch (error) {
      console.log("Error:", error);
    }
  }

  async function getHeaderFile() {
    try {
      const result = await axiosPrivate.post(
        `SpecialPriceList/ExportSpecialPriceListHeaders`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          responseType: "blob",
        }
      );

      return result.data;
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const triggerDataDownloadBaseData = () => {
    setDownloadingData(true);
    if (employeeListSelected === "") {
      toast.warning("Please select an employee to export the data");
      setDownloadingData(false);
    } else {
      let payload = {
        employeeID: employeeListSelected,
        locationsList: convertToLocationIDFormat(groomingLocation),
        servicesList: convertToServiceIDFormat(serviceListSelected),
        petTypesList: convertToTypeIDFormat(typeListSelected),
        petBreedsList: convertToBreedIDFormat(breedListSelected),
        petSizesList: convertToSizeIDFormat(sizeListSelected),
      };
      getFile(payload)
        .then((res) => {
          const fileName = `EsyPet Admin Export - ${formattedDate} - 5.6 - Special Price List Export.xlsx`;
          saveAs(res, fileName);
          setDownloadingData(false);
        })
        .catch((err) => {
          toast.error("Something went wrong!");
          setDownloadingData(false);
          console.log(err);
        });
    }
  };

  const triggerDataDownloadHeaders = () => {
    setDownloadingData(true);

    getHeaderFile()
      .then((res) => {
        const fileName = `EsyPet Admin Export - ${formattedDate} - 5.6 - Special Price List - Headers Export.xlsx`;
        saveAs(res, fileName);
        setDownloadingData(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        setDownloadingData(false);
        console.log(err);
      });
  };

  const filterLocations = (inputValue) => {
    return allLocations.filter((i) =>
      i.varLocationName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptionsLocation = (inputValue, callback) => {
    setTimeout(() => {
      callback(
        filterLocations(inputValue).map((loc) => ({
          label: loc.varLocationName,
          value: loc.numLocationID,
        }))
      );
    }, 1000);
  };

  const handleLocationChange = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((loc) => ({
      value: loc.value,
      label: loc.label,
    }));
    setGroomingLocation(formattedOptions);
  };

  const handleSelectAllLocationChange = (event) => {
    const selectAll = event.target.checked;
    setSelectAllLocations(selectAll);

    if (selectAll) {
      const allOptions = allLocations.map((loc) => ({
        value: loc.numLocationID,
        label: loc.varLocationName,
      }));
      setGroomingLocation(allOptions);
    } else {
      setGroomingLocation([]);
    }
  };

  //////////////////////////Services////////////////////////////////

  const filterServices = (inputValue) => {
    return serviceList.filter((i) =>
      i.varServiceItemName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptionsServices = (inputValue, callback) => {
    setTimeout(() => {
      callback(
        filterServices(inputValue).map((loc) => ({
          label: loc.varServiceItemName,
          value: loc.numServiceItemID,
        }))
      );
    }, 1000);
  };

  const handleServiceChange = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((loc) => ({
      value: loc.value,
      label: loc.label,
    }));
    setServiceListSelected(formattedOptions);
  };

  const handleSelectAllServiceChange = (event) => {
    const selectAll = event.target.checked;
    setSelectAllServices(selectAll);

    if (selectAll) {
      const allOptions = serviceList.map((loc) => ({
        value: loc.numServiceItemID,
        label: loc.varServiceItemName,
      }));
      setServiceListSelected(allOptions);
    } else {
      setServiceListSelected([]);
    }
  };
  ///////////////////////Type//////////////////////////////////////

  const filterTypes = (inputValue) => {
    return typeList.filter((i) =>
      i.varPetTypeName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptionsTypes = (inputValue, callback) => {
    setTimeout(() => {
      callback(
        filterTypes(inputValue).map((loc) => ({
          label: loc.varPetTypeName,
          value: loc.numPetTypeID,
        }))
      );
    }, 1000);
  };

  const handleTypeChange = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((loc) => ({
      value: loc.value,
      label: loc.label,
    }));
    setTypeListSelected(formattedOptions);
  };

  const handleSelectAllTypeeChange = (event) => {
    const selectAll = event.target.checked;
    setSelectAllTypes(selectAll);

    if (selectAll) {
      const allOptions = typeList.map((loc) => ({
        value: loc.numPetTypeID,
        label: loc.varPetTypeName,
      }));
      setTypeListSelected(allOptions);
    } else {
      setTypeListSelected([]);
    }
  };

  /////////////////////SIZE////////////////////////////
  const filterSize = (inputValue) => {
    return sizeList.filter((i) =>
      i.varPetSizeName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptionsSize = (inputValue, callback) => {
    setTimeout(() => {
      callback(
        filterSize(inputValue).map((loc) => ({
          label: loc.varPetSizeName,
          value: loc.numPetSizeID,
        }))
      );
    }, 1000);
  };

  const handleSizeChange = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((loc) => ({
      value: loc.value,
      label: loc.label,
    }));
    setSizeListSelected(formattedOptions);
  };

  const handleSelectAllSizeChange = (event) => {
    const selectAll = event.target.checked;
    setSelectAllSizes(selectAll);

    if (selectAll) {
      const allOptions = sizeList.map((loc) => ({
        value: loc.numPetSizeID,
        label: loc.varPetSizeName,
      }));
      setSizeListSelected(allOptions);
    } else {
      setSizeListSelected([]);
    }
  };

  /////////////////////Breed////////////////////////////
  const filterBreed = (inputValue) => {
    return breedList.filter((i) =>
      i.varPetBreedName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptionsBreed = (inputValue, callback) => {
    setTimeout(() => {
      callback(
        filterBreed(inputValue).map((loc) => ({
          label: loc.varPetBreedName,
          value: loc.BreedID,
        }))
      );
    }, 1000);
  };

  const handleBreedChange = (selectedOptions) => {
    const formattedOptions = selectedOptions.map((loc) => ({
      value: loc.value,
      label: loc.label,
    }));
    setBreedListSelected(formattedOptions);
  };

  const handleSelectAllBreedChange = (event) => {
    const selectAll = event.target.checked;
    setSelectAllBreeds(selectAll);

    if (selectAll) {
      const allOptions = breedList.map((loc) => ({
        value: loc.BreedID,
        label: loc.varPetBreedName,
      }));
      setBreedListSelected(allOptions);
    } else {
      setBreedListSelected([]);
    }
  };

  const resetFilters = () => {
    setSelectAllBreeds(false);
    setBreedListSelected([]);
    setSelectAllSizes(false);
    setSizeListSelected([]);
    setEmployeeListSelected("");
    setSelectAllTypes(false);
    setTypeListSelected([]);
    setSelectAllServices(false);
    setServiceListSelected([]);
    setSelectAllLocations(false);
    setGroomingLocation([]);
    setGroomingLocation([{
      value: auth?.currentLocation?.value,
      label: auth?.currentLocation?.label,
    }])
    getAdSearchData()
  };

  async function GetSpecialPriceListCombinationsPaginated(data) {
    console.log("passedData", data)
    await axios.post(`${process.env.REACT_APP_API_URL}SpecialPriceList/GetSpecialPriceListCombinationsPaginated?pageNumber=${ page + 1}&pageSize=${rowsPerPage}`, JSON.stringify(data), {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
    .then((result) => {
        if (result.status === 200) {
          if (Array.isArray(result?.data) && result?.data?.length === 0) {
            console.log("servicesList is empty");
            setData([])
            setTotalCount(0);
            setTotalRecordsPerLocation(0);
        } else {
            console.log("servicesList is not empty", result?.data?.data);
            setData(result?.data?.data)
            setTotalCount(result?.data?.data?.length);
            setTotalRecordsPerLocation(result?.data?.totalRecords);
          
        }
       
            setIsLoading(false);
        }
    })
    .catch((error) => {
        console.log("Error:", error.message);
    });
}

  const getAdSearchData = () => {
      const data = {
        employeeID: (employeeListSelected === "")? null : employeeListSelected,
        locationsList: convertToLocationIDFormat(groomingLocation),
        servicesList: convertToServiceIDFormat(serviceListSelected),
        petTypesList: convertToTypeIDFormat(typeListSelected),
        petBreedsList: convertToBreedIDFormat(breedListSelected),
        petSizesList: convertToSizeIDFormat(sizeListSelected),
    };

  GetSpecialPriceListCombinationsPaginated(data);
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: '14px',
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '14px',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '14px',
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: '14px',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px',
    }),
  };

  return (
    <div>
      <Modal open={openImportExportModal}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            flexDirection: "column",
            width: "1600px",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <CloseButton onClick={() => setOpenImportExportModal(false)} />
              </div>
            </div>
            <div
              style={{
                padding: "20px",
                backgroundColor: "#F9FAFB",
                maxHeight: "750px",
                minHeight: "400px",
                overflowY: "scroll",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={tabIndex}
                  onChange={handleChange}
                  aria-label="export import tabs"
                >
                  <Tab label="Export" />
                  <Tab label="Import" />
                </Tabs>
                {tabIndex === 0 && (
                  <Box sx={{ p: 3 }}>
                    <p
                      style={{
                        color: "#050576",
                        fontWeight: "600",
                        fontSize: "16px",
                        marginBottom: "20px",
                        borderBottom: "1px solid #cdcdcd",
                      }}
                    >
                      {" "}
                      Download template with data
                    </p>

                    <div style={{display:"flex"}}>
                      <p style={{marginLeft:"673px", fontWeight:"600"}}>Select all</p>
                      <p style={{marginLeft:"696px", fontWeight:"600"}}>Select all</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        {allLocations !== undefined && allLocations !== null ? (
                          <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          {isLoadingLocation ? (
                              <div>...</div>
                          ) : (
                            <div style={{ width: "90%" }}>
                              <AsyncSelect
                                isMulti
                                cacheOptions
                                loadOptions={loadOptionsLocation}
                                onChange={handleLocationChange}
                                defaultOptions
                                value={groomingLocation}
                                placeholder={"Select Locations"}
                                styles={customStyles}
                              />
                            </div>
                          )}
                          <Checkbox
                            checked={selectAllLocations}
                            onChange={handleSelectAllLocationChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </div>
                        ) : null}
                      </div>
                      <div style={{ width: "50%" }}>
                        {serviceList !== undefined && serviceList !== null ? (
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            {isLoading ? (
                                <div>...</div>
                            ) : (
                              <div style={{ width: "90%" }}>
                                <AsyncSelect
                                  isMulti
                                  cacheOptions
                                  loadOptions={loadOptionsServices}
                                  onChange={handleServiceChange}
                                  defaultOptions
                                  value={serviceListSelected}
                                  placeholder={"Select Services"}
                                  styles={customStyles}
                                />
                              </div>
                            )}
                            <Checkbox
                              checked={selectAllServices}
                              onChange={handleSelectAllServiceChange}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        {typeList !== undefined && typeList !== null ? (
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            {isLoadingType ? (
                                <div>...</div>
                            ) : (
                              <div style={{ width: "90%" }}>
                                <AsyncSelect
                                  isMulti
                                  cacheOptions
                                  loadOptions={loadOptionsTypes}
                                  onChange={handleTypeChange}
                                  defaultOptions
                                  value={typeListSelected}
                                  placeholder={"Select Types"}
                                  styles={customStyles}
                                />
                              </div>
                            )}
                            <Checkbox
                              checked={selectAllTypes}
                              onChange={handleSelectAllTypeeChange}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div style={{ width: "50%" }}>
                        {breedList !== undefined && breedList !== null ? (
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            {isLoadingBreed ? (
                                <div>...</div>
                            ) : (
                              <div style={{ width: "90%" }}>
                                <AsyncSelect
                                  isMulti
                                  cacheOptions
                                  loadOptions={loadOptionsBreed}
                                  onChange={handleBreedChange}
                                  defaultOptions
                                  value={breedListSelected}
                                  placeholder={"Select Breeds"}
                                  styles={customStyles}
                                />
                              </div>
                            )}
                            <Checkbox
                              checked={selectAllBreeds}
                              onChange={handleSelectAllBreedChange}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        {sizeList !== undefined && sizeList !== null ? (
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            {isLoadingSize ? (
                                <div>...</div>
                            ) : (
                              <div style={{ width: "90%" }}>
                                <AsyncSelect
                                  isMulti
                                  cacheOptions
                                  loadOptions={loadOptionsSize}
                                  onChange={handleSizeChange}
                                  defaultOptions
                                  value={sizeListSelected}
                                  placeholder={"Select Sizes"}
                                  styles={customStyles}
                                />
                              </div>
                            )}
                            <Checkbox
                              checked={selectAllSizes}
                              onChange={handleSelectAllSizeChange}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div style={{ width: "50%" }}>
                        {employeeList !== undefined && employeeList !== null ? (
                          <div style={{ width: "85%" }}>
                            <AdvancedDropDown
                              passByID={true}
                              isMultiple={false}
                              data={employeeList.map((i) => ({
                                label:
                                  i.varEmployeeFirstName +
                                  " " +
                                  i.varEmployeeLastName,
                                value: i.numEmployeeID,
                              }))}
                              onChange={(e) => {
                                setEmployeeListSelected(e.value);
                              }}
                              ref={employeeRef}
                              placeHolder={"Select Employee"}
                              value={employeeListSelected}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        loading={downloadingData}
                        onClick={() => resetFilters()}
                        variant="outlined"
                        color="default"
                      >
                        Reset Filters
                      </Button>
                      <Button
                        loading={downloadingData}
                        onClick={() => triggerDataDownloadBaseData()}
                        variant="contained"
                        color="secondary"
                      >
                        Download file
                      </Button>
                    </div>

                    <p
                      style={{
                        color: "#050576",
                        fontWeight: "600",
                        fontSize: "16px",
                        marginBottom: "20px",
                        borderBottom: "1px solid #cdcdcd",
                      }}
                    >
                      {" "}
                      Download Template with Headers
                    </p>

                    <div>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          triggerDataDownloadHeaders();
                        }}
                        loading={downloadingData}
                      >
                        Download file
                      </Button>
                    </div>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box sx={{ p: 3 }}>
                    <div
                      style={{
                       height:"300px"
                      }}
                    >
                      <FileUpload
                        requestType={"Custom"}
                        type="upload"
                        objectType={"SpecialPriceList"}
                        text="Upload Data File"
                        isSuccess={(e) => {
                          if (e === true) {
                            //props.isSuccess(true)
                          }
                        }}
                      />
                    </div>
                  </Box>
                )}
              </Box>
            </div>
          </div>
        </div>
      </Modal>


      <Accordion expanded={true} style={{ marginBottom: "10px", background: "#f3f3f3", borderRadius: "8px" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                    <Typography style={{ fontWeight: "600" }}>Advanced Search</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div style={{display:"flex"}}>
                      <p style={{marginLeft:"752px", fontWeight:"600"}}>Select all</p>
                      <p style={{marginLeft:"783px", fontWeight:"600"}}>Select all</p>
                    </div>
                    <div style={{ display: "flex", gap: "10px"}}>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        {allLocations !== undefined && allLocations !== null ? (
                        <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {isLoadingLocation ? (
                            <div>...</div>
                        ) : (
                          <div style={{ width: "90%" }}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              loadOptions={loadOptionsLocation}
                              onChange={handleLocationChange}
                              defaultOptions
                              value={groomingLocation}
                              placeholder={"Select Locations"}
                            />
                          </div>
                        )}
                        <Checkbox
                          checked={selectAllLocations}
                          onChange={handleSelectAllLocationChange}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                        ) : null}
                      </div>
                      <div style={{ width: "50%" }}>
                        {serviceList !== undefined && serviceList !== null ? (
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            {isLoading ? (
                               <div>...</div>
                            ) : (
                              <div style={{ width: "90%" }}>
                                <AsyncSelect
                                  isMulti
                                  cacheOptions
                                  loadOptions={loadOptionsServices}
                                  onChange={handleServiceChange}
                                  defaultOptions
                                  value={serviceListSelected}
                                  placeholder={"Select Services"}
                                />
                              </div>
                            )}
                            <Checkbox
                              checked={selectAllServices}
                              onChange={handleSelectAllServiceChange}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    </div>

                    <div style={{ display: "flex", gap: "10px"}}>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          {typeList !== undefined && typeList !== null ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {isLoadingType ? (
                                  <div>...</div>
                              ) : (
                                <div style={{ width: "90%" }}>
                                  <AsyncSelect
                                    isMulti
                                    cacheOptions
                                    loadOptions={loadOptionsTypes}
                                    onChange={handleTypeChange}
                                    defaultOptions
                                    value={typeListSelected}
                                    placeholder={"Select Types"}
                                  />
                                </div>
                              )}
                              <Checkbox
                                checked={selectAllTypes}
                                onChange={handleSelectAllTypeeChange}
                                inputProps={{ "aria-label": "primary checkbox" }}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div style={{ width: "50%" }}>
                          {breedList !== undefined && breedList !== null ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {isLoadingBreed ? (
                                  <div>...</div>
                              ) : (
                                <div style={{ width: "90%" }}>
                                  <AsyncSelect
                                    isMulti
                                    cacheOptions
                                    loadOptions={loadOptionsBreed}
                                    onChange={handleBreedChange}
                                    defaultOptions
                                    value={breedListSelected}
                                    placeholder={"Select Breeds"}
                                  />
                                </div>
                              )}
                              <Checkbox
                                checked={selectAllBreeds}
                                onChange={handleSelectAllBreedChange}
                                inputProps={{ "aria-label": "primary checkbox" }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", gap: "10px"}}>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          {sizeList !== undefined && sizeList !== null ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {isLoadingSize ? (
                                <div>...</div>
                              ) : (
                                <div style={{ width: "90%" }}>
                                  <AsyncSelect
                                    isMulti
                                    cacheOptions
                                    loadOptions={loadOptionsSize}
                                    onChange={handleSizeChange}
                                    defaultOptions
                                    value={sizeListSelected}
                                    placeholder={"Select Sizes"}
                                  />
                                </div>
                              )}
                              <Checkbox
                                checked={selectAllSizes}
                                onChange={handleSelectAllSizeChange}
                                inputProps={{ "aria-label": "primary checkbox" }}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div style={{ width: "50%" }}>
                          {employeeList !== undefined && employeeList !== null ? (
                            <div style={{ width: "85%" }}>
                              <AdvancedDropDown
                                passByID={true}
                                isMultiple={false}
                                data={employeeList.map((i) => ({
                                  label:
                                    i.varEmployeeFirstName +
                                    " " +
                                    i.varEmployeeLastName,
                                  value: i.numEmployeeID,
                                }))}
                                onChange={(e) => {
                                  setEmployeeListSelected(e.value);
                                }}
                                ref={employeeRef}
                                placeHolder={"Select Employee"}
                                value={employeeListSelected}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", gap: "10px" }}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                getAdSearchData()
                            }}
                            text="Search"
                            iconRight={<SearchOutlined />}
                        />
                        <Button variant="outlined" color="default" onClick={() => resetFilters()} text="Reset Filters" iconRight={<RestartAlt />} />
                    </div>
                </AccordionDetails>
            </Accordion>

            <RenderTable
              addNew={true}
              downloadPDF={true}
              tableName={SpecialPriceListTemplate?.tableName}
              hiddenColumns={SpecialPriceListTemplate?.hiddenColumns}
              columns={SpecialPriceListTemplate?.template}
              data={data}
              loadingData={isLoading}
              newAction={""}
              objectName={"General Price List"}
              rowClicked={(clickedId) => {
                //editItemTrigger(clickedId)
              }}
              searchFilter={searchVal}
              hideSort={SpecialPriceListTemplate?.hideSort}
              pdfColumns={SpecialPriceListTemplate.pdfTemplate}
              pageNumber={page}
              pageSize={rowsPerPage}
            />
          </div>
  );
};

export default SpecialPriceList;
