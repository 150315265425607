import React, { useEffect, useState } from "react"
import { Button } from "../../../components"
import { Input } from "../../../components"
import axios, { axiosPrivate } from "../../../apis/axios"
import { useNavigate, useParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { Checkbox } from "@mui/material"

const CreatePassword = () => {
    const [password, setPassword] = useState("")
    const [retypePassword, setRetypePassword] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const { userId } = useParams()

    const handlePasswordChange = (e) => {
        setPassword(e)
    }

    const handleRetypePasswordChange = (e) => {
        setRetypePassword(e)
    }

    async function getAllQuestions() {
        const result = await axiosPrivate.get(`UserQuestion/GetAll`)
        return result
    }

    async function loginAccountReset() {
        const response = await axios.post(
            "/Auth/Reset",
            JSON.stringify({
                numUserID: userId,
                varUserPassword: password,
            }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        )

        return response
    }

    async function postAnswers(payloadData) {
        await axiosPrivate
            .post(`UserQuestion/PopulateSecurityAnsweres`, JSON.stringify(payloadData), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.log(result?.data)
                    toast.success("Account Verification Successful!")
                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setLoading(false)
            })
    }

    useEffect(() => {
        getAllQuestions()
            .then((response) => {
                console.log(response?.data?.userQuestion)
                setQuestions(response?.data?.userQuestion)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }, [])

    const handleSubmit = () => {
        if (password !== "" || retypePassword !== "") {
            if (password === retypePassword) {
                if (Object.keys(answers).length === 3 && selectedQuestions.length === 3) {
                    setLoading(true)
                    loginAccountReset()
                        .then((res) => {
                            if (res?.data?.bitSuccess) {
                                handleSubmitQuestion()
                            }
                        })
                        .catch((err) => {
                            setLoading(false)
                            console.log(err)
                            toast.error("Something went wrong!")
                        })
                } else {
                    setLoading(false)
                    toast.warning("Please answer at least 3 questions to continue!")
                }
            } else {
                setLoading(false)
                toast.warning("Passwords don't match!")
            }
        } else {
            setLoading(false)
            toast.warning("Invalid Password!")
        }
    }

    useEffect(() => {
        if (password === retypePassword) {
            setError("")
        } else {
            setError("Passwords do not match.")
        }
    }, [password, retypePassword])

    /* Questions */
    const [questions, setQuestions] = useState([]) as any
    const [answers, setAnswers] = useState({}) as any
    const [selectedQuestions, setSelectedQuestions] = useState([]) as any

    const handleSubmitQuestion = () => {
        console.log("answers", answers)
        let payload = [] as any

        Object.keys(answers)?.map((ans) => {
            payload.push({
                id: 0,
                numUserID: userId,
                numQuestionID: ans,
                varAnswer: answers[ans],
            })
        })

        postAnswers(payload)
    }

    const handleCheckBox = (questionID, value) => {
        let getValue = value.target.checked
        const tempSelectedQuestions = [...selectedQuestions]

        if (getValue && selectedQuestions?.length < 3) {
            tempSelectedQuestions.push(questionID)
        } else {
            if (tempSelectedQuestions.includes(questionID)) {
                let index = tempSelectedQuestions.indexOf(questionID)
                if (index !== -1) {
                    tempSelectedQuestions.splice(index, 1)

                    const tempAnswers = { ...answers }
                    delete tempAnswers[questionID]
                    setAnswers(tempAnswers)
                }
            }
        }

        setSelectedQuestions(tempSelectedQuestions)
    }

    const handleAnswerChange = (questionID, answer) => {
        const tempAnswers = { ...answers }
        tempAnswers[questionID] = answer
        setAnswers(tempAnswers)
    }

    return (
        <>
            <ToastContainer />

            <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <div className="App">
                    <p>Select 3 Questions and Answer</p>
                    <ul>
                        {questions.map((q) => (
                            <div key={q?.numUserQuestionID}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox checked={selectedQuestions.includes(q?.numUserQuestionID) ? true : false} onChange={(e) => handleCheckBox(q?.numUserQuestionID, e)}></Checkbox>
                                    <p>{q?.varQuestion}</p>
                                </div>
                                {selectedQuestions.includes(q?.numUserQuestionID) ? (
                                    <Input value={answers[q?.numUserQuestionID]} onChange={(e) => handleAnswerChange(q?.numUserQuestionID, e)} label={"Enter Answer"} type="text" />
                                ) : null}
                            </div>
                        ))}
                    </ul>
                </div>
                <div>
                    <Input label={"Enter Password"} type="password" value={password} onChange={handlePasswordChange} />
                </div>
                <div>
                    <Input label={"Retype Password"} type="password" value={retypePassword} onChange={handleRetypePasswordChange} />
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
                <div style={{ marginTop: "25px" }}>
                    <Button loading={loading} variant="contained" color="primary" onClick={handleSubmit}>
                        Verify My Account
                    </Button>
                </div>
            </div>
        </>
    )
}

export default CreatePassword
