/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "numAddressTypeID": 1,
    "varAddressTypeName": "Home",
    "varAddressTypeDescription": null,
    "bitActive": true,
    "numCreatedBy": 1,
    "varCreatedBy": null,
    "dtCreatedDate": "2022-07-05T08:44:11.7933333",
    "numEditedBy": 1,
    "varEditedBy": null,
    "dtEditedDate": "2022-07-07T09:41:46.8933333",
    "numDeletedBy": 0,
    "varDeletedBy": null,
    "dtDeletedDate": "0001-01-01T00:00:00"
*/

export const AddressType: {} = {
    tableName: "2.1 - Address Types",
    hiddenColumns: ["numAddressTypeID"],
    pdfTemplate: ["varAddressTypeName", "varAddressTypeDescription", "bitActive"],
    template: [
        {
            Header: "Name",
            accessor: "varAddressTypeName",
        },
        {
            Header: "Description",
            accessor: "varAddressTypeDescription",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numAddressTypeID",
            accessor: "numAddressTypeID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numAddressTypeID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varAddressTypeName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varAddressTypeDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
