import {Chip as MaterialChip} from '@mui/material';

import styles from './Chip.module.scss';

export const Chip = (props: {
    label?; // text
    variant?; // filled | outlined
    size?; // medium | small
    color?; // default | primary | secondary | error | info | succeses | warning
    icon?; // icon
    className?; // custom CSS
    customLabel? : boolean;
}) => {
    return (
        <MaterialChip
            label={(props.customLabel)? props.label :(props.label) ? "Active" : "Inactive"} 
            size={(props.size == undefined) ? `medium` : props.size} 
            variant={(props.variant == undefined) ? `filled` : props.variant} 
            color={(props.color == undefined) ? 'default' : props.color } 
            icon={props.icon}
            className={`${props.className} ${(props.label) ? styles.chipActive : styles.chipInactive}`}
        />
    );
}
