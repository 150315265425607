import { Button } from "../../../components/components"
import { useEffect, useState } from "react"
import styles from "./NavLinks.module.scss"
import {
    DashboardOutlined,
    Settings,
    ManageSearch,
    Logout,
    Today,
    Quiz,
    ContentCutOutlined,
    HelpOutlineOutlined,
    Assessment,
    CalendarViewDayRounded,
    DateRange,
    Create,
    SpaceDashboard,
    SpaceDashboardOutlined,
} from "@mui/icons-material"
import { Link, useNavigate, useLocation, Outlet } from "react-router-dom"

import useLogout from "../../../hooks/useLogout"
import { checkPermission, MainNavView } from "../../../utils/permissions"
import ValidatePermission from "../../ValidatePermission/ValidatePermission"

const NavLinks = (props) => {
    const [activeItem, setActiveItem] = useState("/")

    const navigate = useNavigate()
    const location = useLocation() as any
    const logout = useLogout() as any

    useEffect(() => {
        const currentPage = location.pathname.split("/").filter((val) => val != "")[0]
        setActiveItem(`/${currentPage === undefined ? "" : currentPage}`)
    }, [])

    const handleLink = (page) => {
        const from = location.state?.from?.pathname || `${page}`
        setActiveItem(page)
        navigate(from, { replace: true })
    }

    const signOut = async () => {
        await logout()
        localStorage.removeItem("hasVisitedWelcome")
        navigate("/")
    }

    return (
        <div className={styles.navLinksContainer}>
            <div>
                <ValidatePermission allowedModules={["01 - Dashboard"]}>
                    <Button color="default" onClick={() => handleLink("/")} variant="text" className={`${styles.navItem} ${activeItem === "/" ? styles.active : null}`}>
                        <DashboardOutlined />
                        <div>Dashboard</div>
                    </Button>
                </ValidatePermission>

                <ValidatePermission allowedModules={["02 - Schedule"]}>
                    <Button color="default" onClick={() => handleLink("/booking")} variant="text" className={`${styles.navItem} ${activeItem === "/booking" ? styles.active : null}`}>
                        <Today />
                        <div>Schedule</div>
                    </Button>
                </ValidatePermission>

                <ValidatePermission allowedModules={["03 - Directory"]}>
                    <Button color="default" onClick={() => handleLink("/directory")} variant="text" className={`${styles.navItem} ${activeItem === "/directory" ? styles.active : null}`}>
                        <ManageSearch />
                        <div>Directory</div>
                    </Button>
                </ValidatePermission>

                <ValidatePermission allowedModules={["05 - Groomer"]}>
                    <Button color="default" onClick={() => handleLink("/tablet")} variant="text" className={`${styles.navItem} ${activeItem === "/tablet" ? styles.active : null}`}>
                        <ContentCutOutlined />
                        <div>Groomer</div>
                    </Button>
                </ValidatePermission>

                <ValidatePermission allowedModules={["04 - Inquiry"]}>
                    <Button color="default" onClick={() => handleLink("/new-calendar")} variant="text" className={`${styles.navItem} ${activeItem === "/new-calendar" ? styles.active : null}`}>
                        <SpaceDashboardOutlined />
                        <div>Inquiry</div>
                    </Button>
                </ValidatePermission>
            </div>
            <div>
                <ValidatePermission allowedModules={["06 - Reporting"]}>
                    <Button
                        color="default"
                        onClick={() => window.open(process.env.REACT_APP_REPORTING_LINK, "_blank")}
                        variant="text"
                        className={`${styles.navItem} ${activeItem === "/reports" ? styles.active : null}`}
                    >
                        <Assessment />
                        <div>Reports</div>
                    </Button>
                </ValidatePermission>

                <ValidatePermission allowedModules={["01 - Dashboard"]}>
                    <Button color="default" onClick={() => handleLink("/help")} variant="text" className={`${styles.navItem} ${activeItem === "/help" ? styles.active : null}`}>
                        <HelpOutlineOutlined />
                        <div>Help</div>
                    </Button>
                </ValidatePermission>

                <ValidatePermission allowedModules={["07 - Setting"]}>
                    <Button color="default" onClick={() => handleLink("/admin")} variant="text" className={`${styles.navItem} ${activeItem === "/admin" ? styles.active : null}`}>
                        <Settings />
                        <div>Settings</div>
                    </Button>
                </ValidatePermission>

                <Button color="default" onClick={() => signOut()} variant="text" className={`${styles.navItem}`}>
                    <Logout />
                    <div>Logout</div>
                </Button>
            </div>
        </div>
    )
}

export default NavLinks
