import { Button } from "../components";
import styles from "./BottomBar.module.scss";

const BottomBar = (props: { onClick: CallableFunction; loading: boolean }) => {
  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => props.onClick("discard")}
          variant="text"
          color="discard"
          text="Cancel"
        />
        <Button
          loading={props.loading}
          onClick={() => props.onClick("submit")}
          variant="contained"
          color="primary"
          text={props.loading ? "Uploading" : "Save"}
        />
      </div>
    </div>
  );
};

export default BottomBar;
