import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, CloseButton, } from '../../components';
import { Modal } from '../../components/components';
import axios, { axiosPrivate } from '../../apis/axios';
import AdvancedDropDown from '../AdvancedDropDown/AdvancedDropDown';
import { Tooltip, debounce } from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import AssignBreedsToTemplates from './AssignBreedsToTemplates';
import Swal from "sweetalert2";

const BreedPrices = (props: {
    isOpenBreedPricesModal;
    setIsBreedPricesModal: CallableFunction;
    breedPriceID
}) => {


    const [headerList, setHeaderList] = useState([
        "ID",
        "Service Item ID",
        "General Price List ID",
        "General PriceList Name",
        "Service Type",
        "PetType ID",
        "Pet Type Name",
        "Pet SizeID",
        "Pet Size Name",
        "Pet Breed ID",
        "Breed Name",
        "Service Item Cost Price",
        "Service Item Sales Price",
        "Duration",
        "Service Item BarCode",
        "EffectiveDate",
        "ExpiryDate",
        "Service Item Previous Sales Price",
        "Sales Commission Percent * (TRUE or FALSE)",
        "Sales Commission Percent",
        "Sales Commission Amount * (TRUE or FALSE)",
        "Sales Commission Amount",
        "Active Promotion Flag * (TRUE or FALSE)",
        "Link To Promotion Material",
        "Link To Global Coupon",
        "Message To Employee",
        "Promotional Message",
        "Location Specific Adjustment * (TRUE or FALSE)",
        "Location Specific Adjustment EffectiveDate",
        "Location Specific Adjustment ExpiryDate",
        "Location Specific Adjustment Percent * (TRUE or FALSE)",
        "Location Specific Adjustment Percent",
        "Location Specific Adjustment Amount * (TRUE or FALSE)",
        "Location Specific AdjustmentAmount",
        "Date Specific Adjustment * (TRUE or FALSE)",
        "Date Specific Adjustment EffectiveDate",
        "Date Specific Adjustment ExpiryDate",
        "Date Specific Adjustment Percent * (TRUE or FALSE)",
        "Date Specific Adjustment Percent",
        "Date Specific AdjustmentAmount * (TRUE or FALSE)",
        "Date Specific AdjustmentAmount",
        "Is Additional * (TRUE or FALSE)",
        "Is Taxable Item * (TRUE or FALSE)",
        "Is Active * (TRUE or FALSE)"

    ]) as any


    const [allLocations, setAllLocations] = useState<any>([])
    const [filterLocation, setFilterLocation] = useState<any>(1)
    const filterLocationRef = useRef<any>()
    const [tableData, setTableData] = useState([]) as any;
    const [isLoading, setIsLoading] = useState(false)
    const [selectedServiceItem, setSelectedServiceItem] = useState("")
    const [isLoadingSave, setIsLoadingSave] = useState(false)
    const [isOpenAssignBreedsToTemplatesModal, setIsAssignBreedsToTemplatesModal] = useState(false)
    const [editingRow, setEditingRow] = useState("")
    const [eventBreedPayload, setEventBreedPayload] = useState<any>({})


    async function getLocations(signal) {
        const result = await axiosPrivate.get(`Location/GetAll`, {
            signal: signal,
        })
        return result.data.location
    }

    const fetchCombination = async (location) => {
        setIsLoading(true)
        const apiUrl = `${process.env.REACT_APP_API_URL}GeneralPrice/GeneralPriceListCombiantionsByBreedIDandLocationID`;
      
        try {
          const response = await axios.get(apiUrl, {
            params: {
                LocID: location,
                PetBreedID: props.breedPriceID
            },
          });
          return response.data;
        } catch (error) {
          console.error(error);
          throw error;
        }
    };

    useEffect(() => {
        if (props.isOpenBreedPricesModal === true) {
            setTableData([])
            fetchCombination(1)
                .then((data) => {
                    console.log("orgData", data)
                    const newArray = transformData(data);
                    setTableData(newArray);
                    const petSizes: string[] = [...new Set<string>(newArray.map(item => item.varPetSizeName))];
                    setSelectedPetSizes(petSizes)
                    console.log("trans res - combinations",newArray);
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log("error", error);
                    setIsLoading(false)
                });
                const controller = new AbortController()
                fetchLocationData(controller.signal)
                setEditingRow("")
        }
        setSelectedPetSizes([])
        setSelectedServiceItem("")
    }, [props.isOpenBreedPricesModal]);

    const fetchLocationData = (signal) => {
        getLocations(signal)
        .then((res) => {
            setAllLocations(res)
        })
        .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
        })
    }

    const transformData = (data) => {
        const newData:any = [];
        data.forEach(item => {
            const {
                ID,
                ServiceItemID,
                GeneralPriceListID,
                GeneralPriceListName,
                ServiceType,
                GeneralPriceListPetTypeID,
                varPetTypeName,
                GeneralPriceListPetSizeID,
                varPetSizeName,
                GeneralPriceListPetBreedID,
                varBreedName,
                numServiceItemCostPrice,
                numServiceItemSalesPrice,
                GeneralPriceListTime,
                varServiceItemBarCode,
                dtEffectiveDate,
                dtExpiryDate,
                numServiceItemPreviousSalesPrice,
                btSalesCommissionPercent,
                numSalesCommissionPercent,
                btSalesCommissionAmount,
                numSalesCommissionAmount,
                bitActivePromotionFlag,
                varLinkToPromotionMaterial,
                varLinkToGlobalCoupon,
                varMessageToEmployee,
                varPromotionalMessage,
                bitLocationSpecificAdjustment,
                dtLocationSpecificAdjustmentEffectiveDate,
                dtLocationSpecificAdjustmentExpiryDate,
                btLocationSpecificAdjustmentPercent,
                numLocationSpecificAdjustmentPercent,
                btLocationSpecificAdjustmentAmount,
                numLocationSpecificAdjustmentAmount,
                btDateSpecificAdjustment,
                dtDateSpecificAdjustmentEffectiveDate,
                dtDateSpecificAdjustmentExpiryDate,
                btDateSpecificAdjustmentPercent,
                numDateSpecificAdjustmentPercent,
                btDateSpecificAdjustmentAmount,
                numDateSpecificAdjustmentAmount,
                bitIsAdditional,
                bitTaxableItem,
                bitActive
            } = item;
            newData.push({
                ID,
                ServiceItemID,
                GeneralPriceListID,
                GeneralPriceListName,
                ServiceType,
                GeneralPriceListPetTypeID,
                varPetTypeName,
                GeneralPriceListPetSizeID,
                varPetSizeName,
                GeneralPriceListPetBreedID,
                varBreedName,
                numServiceItemCostPrice,
                numServiceItemSalesPrice,
                GeneralPriceListTime,
                varServiceItemBarCode,
                dtEffectiveDate,
                dtExpiryDate,
                numServiceItemPreviousSalesPrice,
                btSalesCommissionPercent,
                numSalesCommissionPercent,
                btSalesCommissionAmount,
                numSalesCommissionAmount,
                bitActivePromotionFlag,
                varLinkToPromotionMaterial,
                varLinkToGlobalCoupon,
                varMessageToEmployee,
                varPromotionalMessage,
                bitLocationSpecificAdjustment,
                dtLocationSpecificAdjustmentEffectiveDate,
                dtLocationSpecificAdjustmentExpiryDate,
                btLocationSpecificAdjustmentPercent,
                numLocationSpecificAdjustmentPercent,
                btLocationSpecificAdjustmentAmount,
                numLocationSpecificAdjustmentAmount,
                btDateSpecificAdjustment,
                dtDateSpecificAdjustmentEffectiveDate,
                dtDateSpecificAdjustmentExpiryDate,
                btDateSpecificAdjustmentPercent,
                numDateSpecificAdjustmentPercent,
                btDateSpecificAdjustmentAmount,
                numDateSpecificAdjustmentAmount,
                bitIsAdditional,
                bitTaxableItem,
                bitActive
            });
        });
        return newData;
    };

    const getCombinations = (loc) => {
        fetchCombination(loc)
        .then((data) => {
            const newArray = transformData(data);
            setTableData(newArray);
            const petSizes: string[] = [...new Set<string>(newArray.map(item => item.varPetSizeName))];
            setSelectedPetSizes(petSizes)
            console.log("trans res - combinations- location change",newArray);
            setIsLoading(false)
        })
        .catch((error) => {
            console.log("error", error);
            setIsLoading(false)
        });
    }
    const debouncedHandleCellEdit = useMemo(() => debounce(
        (newValue, rowIndex, columnName) => {
            setTableData(prevData =>
                prevData.map((row, idx) =>
                    idx === rowIndex ? { ...row, [columnName]: newValue } : row
                )
            );
        }, 10 
    ), []);

    const handleCellEdit = (e, rowIndex, columnName, inputType) => {
        const index = tableData.findIndex(item => item.ID === rowIndex);
        let newValue;

        if (inputType === "boolean") {
            newValue = e.target.value === "true";
        } else {
            newValue = e.target.value;
        }

        debouncedHandleCellEdit(newValue, index, columnName);
    };
    
    const handleKeyDown = (e, rowIndex, columnIndex) => {
        if (e.key === 'ArrowDown') {
            const nextRowIndex = Math.min(rowIndex + 1, tableData.length - 1);
            const nextInput = document.getElementById(`input-${nextRowIndex}-${columnIndex}`) as HTMLInputElement;
            if (nextInput) {
                nextInput.focus();
                nextInput.select();
            }
        } else if (e.key === 'ArrowUp') {
            const prevRowIndex = Math.max(rowIndex - 1, 0);
            const prevInput = document.getElementById(`input-${prevRowIndex}-${columnIndex}`) as HTMLInputElement;
            if (prevInput) {
                prevInput.focus();
                prevInput.select();
            }
        }
    };

    const disabledColumns = [
        "ServiceItemID", 
        "generalPriceListID",
        "GeneralPriceListName", 
        "GeneralPriceListPetBreedID", 
        "varBreedName", 
        "GeneralPriceListPetSizeID", 
        "varPetSizeName", 
        "GeneralPriceListPetTypeID", 
        "varPetTypeName", 
        "ID", 
        "GeneralPriceListID", 
        "serviceItemID", 
        "LocID", 
        "numCreatedBy", 
        "dtCreatedDate", 
        "numEditedBy", 
        "dtEditedDate", 
        "numDeletedBy", 
        "dtDeletedDate", 
        "GeneralPriceListName", 
        "GeneralPriceListPetBreedID", 
        "varBreedName", 
        "varBreedDescription", 
        "BreedIsActive", 
        "GeneralPriceListPetSizeID", 
        "varPetSizeName", 
        "varPetSizeDescription", 
        "GeneralPriceListPetTypeID", 
        "varPetTypeName", 
        "varServiceItemDescription",
        "ServiceType"
    ];

      const booleanColumns = [
        "btSalesCommissionPercent",
        "btSalesCommissionAmount",
        "bitActivePromotionFlag",
        "bitLocationSpecificAdjustment",
        "btLocationSpecificAdjustmentPercent",
        "btLocationSpecificAdjustmentAmount",
        "btDateSpecificAdjustment",
        "btDateSpecificAdjustmentPercent",
        "btDateSpecificAdjustmentAmount",
        "bitActive",
        "bitTaxableItem",
        "bitIsAdditional"
    ];
    const dateColumns = [
        "dtEffectiveDate",
        "dtExpiryDate",
        "dtLocationSpecificAdjustmentEffectiveDate",
        "dtLocationSpecificAdjustmentExpiryDate",
        "dtDateSpecificAdjustmentEffectiveDate",
        "dtDateSpecificAdjustmentExpiryDate"
    ];

    const numberColumns = [
        "numServiceItemCostPrice",
        "numServiceItemSalesPrice",
        "GeneralPriceListTime",
        "numSalesCommissionPercent",
        "numSalesCommissionAmount",
        "numLocationSpecificAdjustmentPercent",
        "numLocationSpecificAdjustmentAmount",
        "numDateSpecificAdjustmentPercent",
        "numDateSpecificAdjustmentAmount"
      ];

    const savePriceList = () => {
        setIsLoadingSave(true)
        let arrPost:any = [];
        filteredData.map((item,index)=> {

            console.log("isadd",item?.bitIsAdditional)
            let obj =   {
                "id": index+1,
                "generalPriceListID": item?.GeneralPriceListID,
                "varServiceItemBarCode": item?.varServiceItemBarCode,
                "serviceItemID": item?.ServiceItemID,
                "locID": filterLocation,
                "dtEffectiveDate": item?.dtEffectiveDate,
                "dtExpiryDate": item?.dtExpiryDate,
                "numServiceItemPreviousSalesPrice": (item?.numServiceItemPreviousSalesPrice ==="")? 0 : parseFloat(item?.numServiceItemPreviousSalesPrice),
                "numServiceItemCostPrice": (item?.numServiceItemCostPrice === "")? 0 : parseFloat(item?.numServiceItemCostPrice),
                "numServiceItemSalesPrice": (item?.numServiceItemSalesPrice === "")? 0 : parseFloat(item?.numServiceItemSalesPrice),
                "bitTaxableItem": item?.bitTaxableItem,
                "bitActivePromotionFlag": !!item?.varLinkToPromotionMaterial || !!item?.varLinkToGlobalCoupon || !!item?.varMessageToEmployee || !!item?.varPromotionalMessage,
                "varLinkToPromotionMaterial": item?.varLinkToPromotionMaterial,
                "varLinkToGlobalCoupon": item?.varLinkToGlobalCoupon,
                "varMessageToEmployee": item?.varMessageToEmployee,
                "varPromotionalMessage": item?.varPromotionalMessage,
                // "bitLocationSpecificAdjustment": item?.bitLocationSpecificAdjustment,
                // "dtLocationSpecificAdjustmentEffectiveDate": (item?.bitLocationSpecificAdjustment)? moment(item?.dtLocationSpecificAdjustmentEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
                // "dtLocationSpecificAdjustmentExpiryDate": (item?.bitLocationSpecificAdjustment)? moment(item?.dtLocationSpecificAdjustmentExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
                // "btLocationSpecificAdjustmentPercent": (item?.bitLocationSpecificAdjustment)? (parseFloat(item?.numLocationSpecificAdjustmentPercent)> 0 && item?.numLocationSpecificAdjustmentPercent !== "")? true : false : false,
                // "numLocationSpecificAdjustmentPercent": (item?.bitLocationSpecificAdjustment)? (item?.numLocationSpecificAdjustmentPercent !== "")? parseFloat(item?.numLocationSpecificAdjustmentPercent) : 0 : 0,
                // "btLocationSpecificAdjustmentAmount": (item?.bitLocationSpecificAdjustment)? (parseFloat(item?.numLocationSpecificAdjustmentAmount)> 0 && item?.numLocationSpecificAdjustmentAmount !== "")? true : false : false,
                // "numLocationSpecificAdjustmentAmount": (item?.bitLocationSpecificAdjustment)? (item?.numLocationSpecificAdjustmentAmount !== "")? parseFloat(item?.numLocationSpecificAdjustmentAmount) : 0 : 0,
                "bitLocationSpecificAdjustment": !!item?.numLocationSpecificAdjustmentAmount || !!item?.numLocationSpecificAdjustmentPercent,
                "dtLocationSpecificAdjustmentEffectiveDate": (!!item?.numLocationSpecificAdjustmentAmount || !!item?.numLocationSpecificAdjustmentPercent) ? moment(item?.dtLocationSpecificAdjustmentEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
                "dtLocationSpecificAdjustmentExpiryDate": (!!item?.numLocationSpecificAdjustmentAmount || !!item?.numLocationSpecificAdjustmentPercent) ? moment(item?.dtLocationSpecificAdjustmentExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
                "btLocationSpecificAdjustmentPercent": (!!item?.numLocationSpecificAdjustmentAmount || !!item?.numLocationSpecificAdjustmentPercent),
                "numLocationSpecificAdjustmentPercent": (!!item?.numLocationSpecificAdjustmentAmount || !!item?.numLocationSpecificAdjustmentPercent) ? (item?.numLocationSpecificAdjustmentPercent !== "") ? parseFloat(item?.numLocationSpecificAdjustmentPercent) : 0 : 0,
                "btLocationSpecificAdjustmentAmount": (!!item?.numLocationSpecificAdjustmentAmount || !!item?.numLocationSpecificAdjustmentPercent),
                "numLocationSpecificAdjustmentAmount": (!!item?.numLocationSpecificAdjustmentAmount || !!item?.numLocationSpecificAdjustmentPercent) ? (item?.numLocationSpecificAdjustmentAmount !== "") ? parseFloat(item?.numLocationSpecificAdjustmentAmount) : 0 : 0,
                // "btDateSpecificAdjustment": item?.btDateSpecificAdjustment,
                // "dtDateSpecificAdjustmentEffectiveDate": (item?.btDateSpecificAdjustment)? moment(item?.dtDateSpecificAdjustmentEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
                // "dtDateSpecificAdjustmentExpiryDate": (item?.btDateSpecificAdjustment)? moment(item?.dtDateSpecificAdjustmentExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
                // "btDateSpecificAdjustmentPercent": (item?.btDateSpecificAdjustment)? (parseFloat(item?.numDateSpecificAdjustmentPercent)> 0 && item?.numDateSpecificAdjustmentPercent !== "")? true : false : false,
                // "numDateSpecificAdjustmentPercent": (item?.btDateSpecificAdjustment)? (item?.numDateSpecificAdjustmentPercent !== "")? parseFloat(item?.numDateSpecificAdjustmentPercent) : 0 : 0,
                // "btDateSpecificAdjustmentAmount": (item?.btDateSpecificAdjustment)? (parseFloat(item?.numDateSpecificAdjustmentAmount)> 0 && item?.numDateSpecificAdjustmentAmount !== "")? true : false : false,
                // "numDateSpecificAdjustmentAmount": (item?.btDateSpecificAdjustment)? (item?.numDateSpecificAdjustmentAmount !== "")? parseFloat(item?.numDateSpecificAdjustmentAmount) : 0 : 0,
                "btDateSpecificAdjustment": (!!item?.numDateSpecificAdjustmentAmount || !!item?.numDateSpecificAdjustmentPercent),
                "dtDateSpecificAdjustmentEffectiveDate": (!!item?.numDateSpecificAdjustmentAmount || !!item?.numDateSpecificAdjustmentPercent) ? moment(item?.dtDateSpecificAdjustmentEffectiveDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
                "dtDateSpecificAdjustmentExpiryDate": (!!item?.numDateSpecificAdjustmentAmount || !!item?.numDateSpecificAdjustmentPercent) ? moment(item?.dtDateSpecificAdjustmentExpiryDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
                "btDateSpecificAdjustmentPercent": (!!item?.numDateSpecificAdjustmentAmount && parseFloat(item?.numDateSpecificAdjustmentPercent) > 0),
                "numDateSpecificAdjustmentPercent": (!!item?.numDateSpecificAdjustmentAmount || !!item?.numDateSpecificAdjustmentPercent) ? (item?.numDateSpecificAdjustmentPercent !== "") ? parseFloat(item?.numDateSpecificAdjustmentPercent) : 0 : 0,
                "btDateSpecificAdjustmentAmount": (!!item?.numDateSpecificAdjustmentAmount && parseFloat(item?.numDateSpecificAdjustmentAmount) > 0),
                "numDateSpecificAdjustmentAmount": (!!item?.numDateSpecificAdjustmentAmount || !!item?.numDateSpecificAdjustmentPercent) ? (item?.numDateSpecificAdjustmentAmount !== "") ? parseFloat(item?.numDateSpecificAdjustmentAmount) : 0 : 0,

                "btSalesCommissionPercent": (parseFloat(item?.numSalesCommissionPercent)> 0 && item?.numSalesCommissionPercent !== "")? true : false,
                "numSalesCommissionPercent": (item?.numSalesCommissionPercent !== "")? parseFloat(item?.numSalesCommissionPercent) : 0,
                "btSalesCommissionAmount": (parseFloat(item?.numSalesCommissionAmount)> 0 && item?.numSalesCommissionAmount !== "")? true : false,
                "numSalesCommissionAmount": (item?.numSalesCommissionAmount !== "")? parseFloat(item?.numSalesCommissionAmount) : 0,
                "bitActive": (item?.bitActive === "")? false : (item?.bitActive === "1")? true : (item?.bitActive === true)? true : false,
                "numCreatedBy": 0,
                "dtCreatedDate": null,
                "numEditedBy": 0,
                "dtEditedDate": null,
                "numDeletedBy": 0,
                "dtDeletedDate": null,
                "bitIsAdditional": item?.bitIsAdditional,
                "generalPriceListName": item?.GeneralPriceListName,
                "generalPriceListPetBreedID": item?.GeneralPriceListPetBreedID,
                "varBreedName": item?.varBreedName,
                "varBreedDescription": "string",
                "breedIsActive": true,
                "generalPriceListPetSizeID": item?.GeneralPriceListPetSizeID,
                "varPetSizeName": item?.varPetSizeName,
                "varPetSizeDescription": "string",
                "generalPriceListTime": (item?.GeneralPriceListTime === "")? 0 : item?.GeneralPriceListTime,
                "varServiceItemDescription": "string",
                "generalPriceListPetTypeID": item?.GeneralPriceListPetTypeID,
                "varPetTypeName": item?.varPetTypeName
              }
              arrPost.push(obj)
        })
        console.log("sent payload", arrPost)
        postPriceList(arrPost)
    }

    async function postPriceList(data) {
        await axiosPrivate.post(`GeneralPrice/PopulateGeneralPriceByList`, 
        JSON.stringify(data), 
        {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        ).then((result) => {
            if(result.status == 200){        
                console.log(result)
                setTableData([])
                setIsLoadingSave(false)
                toast.success(`Changes to the breed prices saved successfully!`)


                const petTypeIDArray:any = [];
                const petSizeIDArray:any = [];
                const serviceItemIDArray:any = [];
                
                // Sets to hold unique values
                const uniquePetTypeIDs = new Set();
                const uniquePetSizeIDs = new Set();
                const uniqueServiceItemIDs = new Set();
                
                // Loop through the data array and extract the specified fields
                data.forEach(item => {
                    if (!uniquePetTypeIDs.has(item.generalPriceListPetTypeID)) {
                        uniquePetTypeIDs.add(item.generalPriceListPetTypeID);
                        petTypeIDArray.push({ petTypeID: item.generalPriceListPetTypeID });
                    }
                    if (!uniquePetSizeIDs.has(item.generalPriceListPetSizeID)) {
                        uniquePetSizeIDs.add(item.generalPriceListPetSizeID);
                        petSizeIDArray.push({ petSizeID: item.generalPriceListPetSizeID });
                    }
                    if (!uniqueServiceItemIDs.has(item.serviceItemID)) {
                        uniqueServiceItemIDs.add(item.serviceItemID);
                        serviceItemIDArray.push({ serviceID: item.serviceItemID });
                    }
                });
        
                let obj = {
                    "locationID": filterLocation,
                    "numPetBreedID": props.breedPriceID,
                    "eventPetTypes": petTypeIDArray,
                    "eventPetSizes": petSizeIDArray,
                    "eventServices": serviceItemIDArray
                  }
                  setEventBreedPayload(obj)
                
                // Output the results
                setIsAssignBreedsToTemplatesModal(true)
                //.setIsBreedPricesModal(false)
            }
        }).catch((error: any) => {
            console.log(error)
            setIsLoadingSave(false)
        });     
    }



    const numberPattern = /^\d*$/; 

    const uniquePetSizes: string[] = [...new Set<string>(tableData.map(item => item.varPetSizeName))];
    const uniqueServiceItemNames:any = [...new Set(tableData.map(item => item.GeneralPriceListName))];


    const [selectedPetSizes, setSelectedPetSizes] = useState([]) as any;

    const handleCheckboxChange = (size) => {
        if (selectedPetSizes.includes(size)) {
            setSelectedPetSizes(selectedPetSizes.filter(item => item !== size));
        } else {
            setSelectedPetSizes([...selectedPetSizes, size]);
        }
    };

    const handleServiceItemChange = (serviceName) => {
        if(serviceName === ""){
            setSelectedServiceItem("")
        }else{
            setSelectedServiceItem(serviceName);
        }
      };
    
//const filteredData = tableData.filter(item => selectedPetSizes.length === 0 || selectedPetSizes.includes(item.varPetSizeName));

const filteredData = tableData.filter(item => {
    const matchesPetSize = selectedPetSizes.includes(item.varPetSizeName);
    const matchesServiceItem = selectedServiceItem === "" || item.GeneralPriceListName === selectedServiceItem;
    return matchesPetSize && matchesServiceItem;
});

const clearFilters = () => {
    setSelectedServiceItem("")
    //setSelectedPetSizes([])
    const petSizes: string[] = [...new Set<string>(tableData.map(item => item.varPetSizeName))];
    setSelectedPetSizes(petSizes)
}

const showEditingRow = (index) => {
    const rowIndex = tableData.findIndex(item => item.ID === index);
    setEditingRow(`ID: ${tableData[rowIndex]?.GeneralPriceListID} | Item Name: ${tableData[rowIndex]?.GeneralPriceListName} | Service Type: ${tableData[rowIndex]?.ServiceType} | Pet Size: ${tableData[rowIndex]?.varPetSizeName}`)
}

    const resetChanges = () => {

    Swal.fire({
    title: 'Are you sure?',
    text: 'Your current changes will be lost!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, revert it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.isConfirmed) {

        setTableData([])
        fetchCombination(1)
            .then((data) => {
                console.log("orgData", data)
                const newArray = transformData(data);
                setTableData(newArray);
                const petSizes: string[] = [...new Set<string>(newArray.map(item => item.varPetSizeName))];
                setSelectedPetSizes(petSizes)
                console.log("trans res - combinations",newArray);
                setFilterLocation(1)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("error", error);
                setIsLoading(false)
            });
            const controller = new AbortController()
            fetchLocationData(controller.signal)
            setEditingRow("")

      // Handle delete action here
    } else if (result.dismiss === Swal.DismissReason.cancel) {

    }
  });

    }

const columnsToHide = ["ID", "PetType ID", "Pet SizeID", "Pet Breed ID", "Service Item ID", "Sales Commission Amount * (TRUE or FALSE)", "SalesCommissionPercent * (TRUE or FALSE)", "Location Specific Adjustment Percent * (TRUE or FALSE)", "Location Specific Adjustment Amount * (TRUE or FALSE)", "Date Specific Adjustment Percent * (TRUE or FALSE)", "Date Specific AdjustmentAmount * (TRUE or FALSE)", "Active Promotion Flag * (TRUE or FALSE)", "Location Specific Adjustment * (TRUE or FALSE)", "Date Specific Adjustment * (TRUE or FALSE)", "Is Additional * (TRUE or FALSE)", "PetType ID","Pet Type Name", "Pet Breed ID", "Breed Name"];
    return (
        <Modal open={props.isOpenBreedPricesModal} >
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column',  gap: '1rem', padding: "0 1rem 0 1rem" }}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <p style={{ fontSize: "24px", fontWeight: "600", color: "#192252" }}>Price List</p>
                        <div style={{display:"flex", justifyContent:"center", position: "sticky", top: 3, zIndex: 4,}}>
                                    <div style={{background:"#2d639d", padding:"0 10px 0", borderRadius:"8px", width:"fit-content"}}>
                                        <p style={{color:"white", fontWeight:"500"}}>Breed Name : {filteredData[0]?.varBreedName} | Pet Type: {filteredData[0]?.varPetTypeName}</p>
                                    </div>
                        </div>
                        <CloseButton onClick={() => props.setIsBreedPricesModal(false)} />
                    </div>

                    <div style={{display:"flex", gap:"10px", borderRadius:"8px", background:"#d1e5ff", padding:"30px 10px 30px 10px",boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 4px"}}>
                    {allLocations !== undefined && allLocations !== null ? (
                                    <div style={{ width: "20%" }}>
                                        <AdvancedDropDown
                                            data={allLocations.sort((a, b) => a.numLocationID - b.numLocationID).map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                            onChange={(e) => {
                                                setFilterLocation(e?.value)
                                                getCombinations(e?.value)
                                            }}
                                            ref={filterLocationRef}
                                            placeHolder={"Location"}
                                            passByID={true}
                                            value={filterLocation}
                                        />
                                    </div>
                                ) : null}

                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    {uniquePetSizes.map((size, index) => {
                                        return (
                                            <label key={index} style={{ display: "flex", alignItems: "center" }}>
                                                <input
                                                    type="checkbox"
                                                    value={size}
                                                    checked={selectedPetSizes.includes(size)}
                                                    onChange={() => handleCheckboxChange(size)}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                <span style={{ fontSize: "14px" }}>{size}</span>
                                            </label>
                                        );
                                    })}
                                </div>

                            <select onChange={(e) => handleServiceItemChange(e.target.value)} value={selectedServiceItem} style={{
                                            width:"fit-content",
                                            padding: '8px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            backgroundColor: '#fff',}}>
                                    
                                <option value="">Select Service Item</option>
                                {uniqueServiceItemNames.map((name, index) => (
                                    <option key={index} value={name} >
                                    {name}
                                    </option>
                                ))}
                            </select>

                            <Button color="default" variant="outlined" onClick={() => clearFilters()}>
                                Reset Filters
                            </Button>

                            <Button color="default" variant="outlined" onClick={() => resetChanges()}>
                                Reset Changes
                            </Button>
                    </div>
                    <div style={{ overflowX: "auto",width: "1600px", height:"600px" , overflowY:"scroll" }}>
                    {
                    (isLoading)? <p style={{fontSize:"12px", color:"gray", fontWeight:"500"}}>Loading...</p> :
                            (filteredData.length < 1)? <p style={{color:"gray"}}>No data found, Please add services for the selected breed's pet type to see the price list</p> :
                            <div>
                   
                                
                                <table style={{ width: "100%" }}>
                            <thead style={{ position: "sticky", top: 0, zIndex: 1, background: "#fff" }}>
                                    <tr>
                                        {headerList.map((key, index) => (
                                            // Check if the column should be hidden
                                            !columnsToHide.includes(key) && (
                                                <th key={index} style={{ fontSize: "13px", color: "black", background:"#f5f5f5", fontWeight:"500" }}>{key}</th>
                                            )
                                        ))}
                                    </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {Object.keys(row).map((column, colIndex) => {
                                            // Check if the column should be hidden
                                            if (!columnsToHide.includes(headerList[colIndex])) {
                                                const isDisabled = disabledColumns.includes(column);
                                                if (booleanColumns.includes(column)) {
                                                    return (
                                                        <td key={colIndex} style={{ padding: "10px 10px 0 0" }}>
                                                            <select
                                                                id={`input-${rowIndex}-${colIndex}`}
                                                                value={row[column] ? "true" : "false"}
                                                                onChange={(e) => handleCellEdit(e, row.ID, column, "boolean")}
                                                                onFocus={()=>{showEditingRow(row.ID)}}
                                                                style={{
                                                                    width:"fit-content",
                                                                    padding: '8px',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #ccc',
                                                                    backgroundColor: '#fff',
                                                                }}
                                                            >
                                                                <option value="true">True</option>
                                                                <option value="false">False</option>
                                                            </select>
                                                        </td>
                                                    );   
                                                } else if (dateColumns.includes(column)) {
                                                    return (
                                                        <td key={colIndex} style={{ padding: "10px 10px 0 0" }}>
                                                            <input
                                                                id={`input-${rowIndex}-${colIndex}`}
                                                                type="date"
                                                                value={(moment(row[column]).format("YYYY-MM-DD")=== "Invalid date")? "" : moment(row[column]).format("YYYY-MM-DD")}
                                                                onChange={(e) => handleCellEdit(e, row.ID, column, "")}
                                                                onFocus={()=>{showEditingRow(row.ID)}}
                                                                style={{
                                                                    padding: '8px',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #ccc',
                                                                    backgroundColor: '#fff',
                                                                    fontSize: '14px',
                                                                }}
                                                            />
                                                        </td>
                                                    );
                                                } else if (numberColumns.includes(column)) {
                                                    return (
                                                        <td key={colIndex} style={{ padding: "10px 10px 0 0" }}>
                                                            <input
                                                                id={`input-${rowIndex}-${colIndex}`}
                                                                type="text"
                                                                value={row[column]}
                                                                disabled={isDisabled}
                                                                onChange={(e) => handleCellEdit(e, row.ID, column, "")}
                                                                onFocus={()=>{showEditingRow(row.ID)}}
                                                                onKeyDown={(e) => {
                                                                    if (!e.key.match(numberPattern) && e.key.length === 1 && !e.ctrlKey) {
                                                                        e.preventDefault();
                                                                    } else {
                                                                        handleKeyDown(e, rowIndex, colIndex);
                                                                    }
                                                                }}
                                                                style={(isDisabled)? {
                                                                    backgroundColor:"#efefef",
                                                                    padding: '8px',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #ccc',
                                                                    fontSize: '14px'
                                                                    
                                                                } : {
                                                                    padding: '8px',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #ccc',
                                                                    backgroundColor: '#fff',
                                                                    fontSize: '14px'
                                                                    
                                                                }}
                                                            />
                                                        </td>
                                                    );
                                                } else {
                                                    return (
                                                        <td key={colIndex} style={{ padding: "10px 10px 0 0" }}>
                                                        {isDisabled ? (
                                                            <Tooltip title={row[column]}>
                                                                <input
                                                                id={`input-${rowIndex}-${colIndex}`}
                                                                type="text"
                                                                value={row[column]}
                                                                disabled={isDisabled}
                                                                onChange={(e) => handleCellEdit(e, row.ID, column, "")}
                                                                onFocus={()=>{showEditingRow(row.ID)}}
                                                                onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
                                                                style={{
                                                                    backgroundColor:"#efefef",
                                                                    padding: '8px',
                                                                    borderRadius: '4px',
                                                                    border: '1px solid #ccc',
                                                                    fontSize: '14px'
                                                                }}
                                                                />
                                                            </Tooltip>
                                                            ) : (
                                                            <input
                                                                id={`input-${rowIndex}-${colIndex}`}
                                                                type="text"
                                                                value={row[column]}
                                                                disabled={isDisabled}
                                                                onChange={(e) => handleCellEdit(e, row.ID, column, "")}
                                                                onFocus={()=>{showEditingRow(row.ID)}}
                                                                onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
                                                                style={{
                                                                padding: '8px',
                                                                borderRadius: '4px',
                                                                border: '1px solid #ccc',
                                                                backgroundColor: '#fff',
                                                                fontSize: '14px'
                                                                }}
                                                            />
                                                            )}
                                                        </td>
                                                    );
                                                }
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                                </table>
                            </div>
                    }
                    </div>

                    <AssignBreedsToTemplates isOpenAssignBreedsToTemplatesModal={isOpenAssignBreedsToTemplatesModal} setIsAssignBreedsToTemplatesModal={(value) => setIsAssignBreedsToTemplatesModal(value)} payload={eventBreedPayload} setIsBreedPriceParentModal={(e) => {
                        if(e === true){
                            props.setIsBreedPricesModal(false)
                        }
                    }}/> 

                <div style={{ display: 'flex', justifyContent:"space-between", width: '100%', backgroundColor: "#F3F4F6", padding: "1rem", borderRadius: "0 0 12px 12px" }}>
                    
                        <div style={{background:"white", padding:"0 10px 0", borderRadius:"8px", width:"fit-content", border:"1px solid #2554a7"}}>
                            <p style={{color:"#2554a7", fontWeight:"500", fontSize:"14px", margin:"8px"}}>{editingRow}</p>
                        </div>
                 
                        <Button variant='contained' color='primary' loading={isLoadingSave} disabled={isLoadingSave} onClick={()=>{savePriceList()}}>Save & Continue</Button>
                </div>
            </div>
        </Modal>
    );
}

export default BreedPrices;