import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"

const DialogBox = ({ open, onClose, title, content, actions }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <div style={{ padding: "18px 0" }}>{content}</div>
            </DialogContent>
            <DialogActions>
                {actions.map((action, index) => (
                    <Button key={index} onClick={action.onClick} color={action.color} variant={action.variant}>
                        {action.label}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    )
}

export default DialogBox
