import styles from "../Help.module.scss";
import { Outlet } from "react-router-dom";
import { HelpLinks } from "./HelpLinks";
import HelpItems from "./HelpItems";




const HelpContent = () => {



  return (
    <div id={styles.pages}>
      <div id={styles.inner}>
        <h1>Support Center</h1>


        <hr />

        <div id={styles.sidebar}>
          {HelpLinks.map((item,index) => {
            return <HelpItems item={item} key={index} />;
          })}
        </div>
      </div>

      <div id={styles.content}>
        <div style={{ width: '100%', height: 'calc(100vh - 90px)', overflowY: 'scroll'}}>
          <Outlet />              
        </div>
      </div>
    </div>
  );
};

export default HelpContent;
