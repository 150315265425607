import { MoreHoriz } from "@mui/icons-material";
import { ChipGroup } from "../../../ChipGroup/ChipGroup";
import styles from './MapLayout.module.scss';
import placeholder from '../../../../../../images/location_placeholder.png';
import MapLoad from '../../../../../../images/Mapload.jpg';
import { Chip } from "../../../Chip/Chip";
import { Map } from "../../../../../Map/Map";
import { useEffect, useState } from "react";
import { Button } from "../../../../../components";
import { toast } from "react-toastify";
import { formatPhone } from "../../../../../../utils/PhoneFormatter";
const Location = (props: {
    page?;
    prepareRow?;
    filterChanged?
}) => {


    useEffect(()=>{ 
            setShowMap(false)
            setStores([])
    },[props.filterChanged])

      const [showMap, setShowMap] = useState(false);
      const [stores, setStores] = useState<any>([]);
      const [center, setCenter] = useState<any>({});

const selectAccount = (loc) => {
    //setShowMap(false)
    const locations = [...stores]
    let matching = locations.some(x => x.id === loc.allCells[18].value)
    if(!matching){
        let obj = { 
            id: loc.allCells[18].value,
            longitude: parseFloat(loc.allCells[15].value),
            latitude: parseFloat(loc.allCells[16].value),
            title: loc.allCells[0].value,
            distance: "",
            duration: "",
            value: 0,
            img: loc.allCells[2].value
           }
           console.log(obj)
        locations.push(obj)
        setStores(locations) 
    }else{
       let index = locations.findIndex(x => x.id ===loc.allCells[18].value);
       locations.splice(index,1)
       setStores(locations)  
    }
    console.log(locations)
}


useEffect(()=>{
    if(stores.length>0){
        let baseLoc = {
            id: stores[0].id,  
            lng: stores[0].longitude,
            lat:  stores[0].latitude,
            name:  stores[0].title,
            img: stores[0].img
        }
        setCenter(baseLoc)
    }
    if(stores.length<1){
        setShowMap(false)
    }
},[stores])

const showOnMap = () =>{
    console.log(Object.keys(stores))
    if(Object.keys(stores).length < 1){
        toast.info("Please select a location to view on map")
    }else{   
        setShowMap(true)
        console.log(stores[0].id) 
    }
}
useEffect(()=>{
    console.log(center)
},[center])

    return (
        <div style={{display:"flex", flexDirection: "row", gap:"1rem"}}>
        {
            (props.page !== undefined)?
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', marginTop: '0.8rem', height:"720px", overflowX: "scroll"}}>
            {props.page.map((row, i) => {
                props.prepareRow(row)
                let matching = stores.some(x => x.id === row.allCells[18].value)
                return (
                    <div key={i} onClick={()=>{selectAccount(row)}} style={{ margin: "0 .5rem .5rem 0", width:"390px"}}>
                        <div className={(matching)? styles.locationCardChecked : styles.locationCard}>
                            
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: '1rem'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
                                    <img style={{maxWidth: '120px', borderRadius: '10px'}} src={ ((row.allCells[1].value == null || row.allCells[1].value == "") ? placeholder : `${process.env.REACT_APP_S3_URL}/directories/Location/${row.allCells[1].value}`) } />
                                    <Chip color={(row.allCells[17].value == true )? undefined : "error"} customLabel={true} label={(row.allCells[17].value == true )? "Active" : "Inactive"} size={'small'}/>  
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <div className={styles.clientContainer}>
                                            <div className={styles.avatarContainer} style={{borderBottom: '1px solid #e2e2e2', paddingBottom: '8px'}}>                                                           
                                                <div style={{fontWeight: 500}}>{row.allCells[0].value}</div>
                                            </div>   

                                            <div className={styles.avatarContainer} style={{borderBottom: '1px solid #e2e2e2', padding: '8px 0'}}>                                                           
                                                <ChipGroup 
                                                    values={[
                                                        {
                                                            label: "Gro",
                                                            value: row.allCells[6].value,
                                                            color: "success"
                                                        },
                                                        {
                                                            label: "Day",
                                                            value: row.allCells[8].value,
                                                            color: "success"
                                                        },
                                                        {
                                                            label: "Bod",
                                                            value: row.allCells[7].value,
                                                            color: "success"
                                                        },
                                                        {
                                                            label: "Sit",
                                                            value: row.allCells[9].value,
                                                            color: "success"
                                                        },
                                                        {
                                                            label: "Tra",
                                                            value: row.allCells[10].value,
                                                            color: "success"
                                                        },
                                                        {
                                                            label: "Sto",
                                                            value: row.allCells[12].value,
                                                            color: "success"
                                                        }
                                                    ]}
                                                    size={'small'}
                                                />
                                            </div>                                                                                                                         
                                        </div> 
                                    </div>
                                    <div>
                                        <div style={{padding: '5px 0 5px 0' }}>
                                            <p style={{fontWeight: 500, color: '#6B7280', margin: '0'}}>Phone</p> 
                                            <p style={{fontWeight: 600, color: '#000', margin: '0'}}>{formatPhone(row.allCells[5].value)}</p>  
                                        </div>
                                        <div style={{padding: '0 0 5px 0' }}>
                                            <p style={{fontWeight: 500, color: '#6B7280', margin: '0'}}>Address</p>  
                                            <p style={{fontWeight: 600, color: '#000', margin: '0'}}>{row.allCells[13].value}</p>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       
                )
            })}
        </div> : null
        }

        <div style={(props.page !== undefined)?{height:"720px", width:"760px", marginTop:"1rem",  backgroundImage: `url(${MapLoad})`, position:"relative"} : {height:"720px", width:"760px",  backgroundImage: `url(${MapLoad})`, position:"relative"}}>  
            {
                (showMap)?
                <Map location={center} salons={stores} directions={true} getDirection={false} getRoutes={false} isPickLocation={false} showBaseMarker={false} zoom={10} isSub={false}></Map>:
                <div style={{position:"absolute", margin:"0", top:"50%", left:"50%", transform: "translate(-50%, -50%)"}}>
                <Button color="primary" variant="contained" onClick={()=>{showOnMap()}}>Load Map View</Button>
            </div>
            }
        </div>
        </div>
    );
}

export default Location;