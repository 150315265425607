import { useEffect, useMemo, useRef, useState } from "react"
import { Profile, SearchBox } from "../../../components/components"
import styles from "../Directory.module.scss"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Location } from "../../../components/Table/templates/Directory/Location"
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { axiosPrivate } from "../../../apis/axios"
import { useTable, usePagination, useAsyncDebounce, useGlobalFilter, useFilters, useSortBy } from "react-table"
import { Add, AppsOutlined, FormatListBulletedOutlined, ArrowRightAlt, ChevronLeft, ChevronRight, FilterAltOutlined, SortByAlpha, Search, Map } from "@mui/icons-material"
import { Button, Text, Input } from "../../../components/components"
import { downloadWithDataDirectories } from "../../../utils/ExcelHandler"
import { PDFHandler } from "../../../utils/PDFHandler"
import tableStyles from "../../../components/Table/Table.module.scss"
import GridLayout from "../../../components/Table/components/GridLayout/GridLayout"
import MapLayout from "../../../components/Table/components/GridLayout/Location/MapLayout/MapLayout"
import Loading from "../../../components/Table/components/Loading/Loading"
import AdvancedDropDown from "../../../components/AdvancedDropDown/AdvancedDropDown"
import loadingImage from "../../../images/loading.gif"
import { checkPermission } from "../../../utils/permissions"
import ValidatePermission from "../../../components/ValidatePermission/ValidatePermission"
import NewSalon from "./NewSalon"

const Salon = (props: {}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<any>([])
    const [dataUnfiltered, setDataUnfiltered] = useState<any>([])
    const [viewProfile, setViewProfile] = useState(false)
    const [selectedSalonID, setSelectedSalonID] = useState("")
    const [searchVal, setSearchVal] = useState("")
    const [isInactive, setIsInactive] = useState(false)
    const [location, setLocation] = useState("")
    const [neighborhood, setNeighborhood] = useState("")
    const [city, setCity] = useState("")
    const [grooming, setGrooming] = useState(false)
    const [Boarding, setBoarding] = useState(false)
    const [Daycare, setDaycare] = useState(false)
    const [Sitting, setSitting] = useState(false)
    const [Training, setTraining] = useState(false)
    const [MobileGrooming, setMobileGrooming] = useState(false)
    const [StoreProduct, setStoreProduct] = useState(false)
    const [activeStatus, setActiveStatus] = useState<any>("")
    const [allLocations, setAllLocations] = useState<any>([])
    const myRef = useRef<any>()

    useEffect(() => {
        const controller = new AbortController()

        fetchData(controller.signal)

        return () => {
            controller.abort()
        }
    }, [])

    async function getData(signal) {
        const result = await axiosPrivate.get(`Location/GetAll`, {
            signal: signal,
        })
        return result.data.location
    }

    const handleChangeActiveStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActiveStatus((event.target as HTMLInputElement).value)
    }

    const [showNewSalonModal, setShowNewSalonModal] = useState(false)

    const fetchData = (signal) => {
        setIsLoading(true)

        getData(signal)
            .then((res) => {
                setData(res)
                setDataUnfiltered(res)
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                    setIsLoading(false)
                }
            })
        getLocations(signal)
            .then((res) => {
                setAllLocations(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })
    }

    const viewSalonProfile = (id) => {
        setSelectedSalonID(id)
        setViewProfile(true)
    }

    const resetFilters = () => {
        setActiveStatus("")
        setIsInactive(false)
        setData(dataUnfiltered)
        setLocation("")
        setNeighborhood("")
        setCity("")
        setGrooming(false)
        setDaycare(false)
        setBoarding(false)
        setSitting(false)
        setTraining(false)
        setStoreProduct(false)
        setMobileGrooming(false)
        myRef.current?.handleClear()
    }

    const validateString = (input) => {
        return input === "" ? null : input
    }
    const validateCheckbox = (input) => {
        return input === false ? null : input
    }

    async function getAdvancedData() {
        setIsLoading(true)

        let payload = {
            varLocation: validateString(location),
            varNeighborhood: validateString(neighborhood),
            varCity: validateString(city),
            bitGroomingService: validateCheckbox(grooming),
            bitBoardingService: validateCheckbox(Boarding),
            bitDaycareService: validateCheckbox(Daycare),
            bitSittingService: validateCheckbox(Sitting),
            bitTrainingService: validateCheckbox(Training),
            bitMobileGroomingService: validateCheckbox(MobileGrooming),
            bitStoreProductService: validateCheckbox(StoreProduct),
            bitActiveStatus: validateString(activeStatus),
        }
        console.log("sent - payload", payload)
        const result = await axiosPrivate.post("Location/AdvanceSearch", JSON.stringify(payload), {
            headers: { "Content-Type": "application/json" },
        })
        return result.data.location
    }

    async function getLocations(signal) {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, {
            signal: signal,
        })
        return result.data.location
    }

    useEffect(() => {
        if (
            location == "" &&
            city == "" &&
            neighborhood == "" &&
            grooming == false &&
            Daycare == false &&
            Boarding == false &&
            Sitting == false &&
            Training == false &&
            MobileGrooming == false &&
            StoreProduct == false &&
            activeStatus == ""
        ) {
            setData(dataUnfiltered)
        } else {
            setViewProfile(false)
            const delayDebounceFn = setTimeout(() => {
                if (
                    location != "" ||
                    city != "" ||
                    neighborhood != "" ||
                    grooming != false ||
                    Daycare != false ||
                    Boarding != false ||
                    Sitting != false ||
                    Training != false ||
                    MobileGrooming != false ||
                    StoreProduct != false ||
                    activeStatus != ""
                ) {
                    getAdvancedData()
                        .then((res) => {
                            console.log("res - payload")
                            setData(res)
                            setIsLoading(false)
                        })
                        .catch((err) => {
                            console.log("res - error", err)
                            setIsLoading(false)
                            setData([])
                            toast.error("Something went wrong!")
                        })
                }
            }, 900)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [location, city, neighborhood, grooming, Daycare, Boarding, Sitting, Training, MobileGrooming, StoreProduct, activeStatus])

    const RenderTable = ({ columns, data, activateGrid, tableName, hiddenColumns, downloadPDF, newAction, loadingData, objectName, rowClicked, searchFilter, hideSort, pdfColumns, activateMap }) => {
        const [isGrid, setIsGrid] = useState(false)
        const [isMap, setIsMap] = useState(false)
        const [isList, setIsList] = useState(true)
        const [openImportExportModal, setOpenImportExportModal] = useState(false)
        const [showFiltering, setShowFiltering] = useState(false)
        const [pageList, setPageList] = useState([10, 20, 30, 40, 50])
        const [searchVal, setSearchVal] = useState("")
        const [filteredRows, setFilteredRows] = useState([])
        let exportData: any = []

        const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
            return (
                <input
                    className={tableStyles.searchFilter}
                    value={filterValue || ""}
                    onChange={(e) => {
                        setFilter(e.target.value || undefined)
                    }}
                    placeholder={`Search`}
                />
            )
        }

        const defaultColumn = useMemo(
            () => ({
                Filter: DefaultColumnFilter,
            }),
            []
        )

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            nextPage,
            rows,
            previousPage,
            setPageSize,
            setFilter,
            state: { pageIndex, pageSize },
            setGlobalFilter,
            setAllFilters,
        } = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: {
                    pageIndex: 0,
                    hiddenColumns: hiddenColumns,
                    pageSize: 10,
                },
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        )

        useEffect(() => {
            if (isGrid) {
                setPageSize(9)
                setPageList([9, 18, 27, 36, 45])
            }

            if (isList) {
                setPageSize(10)
                setPageList([10, 20, 30, 40, 50])
            }
            if (isMap) {
                setPageSize(10)
                setPageList([10, 20, 30, 40, 50])
            }
        }, [isGrid, isList, isGrid])

        useEffect(() => {
            filterGlobalData(searchVal)
        }, [searchVal])

        useEffect(() => {
            setFilteredRows(rows)
        }, [rows])

        useEffect(() => {
            if (!showFiltering) {
                setAllFilters([])
            }
        }, [showFiltering])

        useEffect(() => {
            filterGlobalData(searchFilter)
        }, [searchFilter])

        const filterGlobalData = useAsyncDebounce((filter) => {
            setGlobalFilter(filter)
        }, 200)

        const appendForExport = () => {
            exportData = []
            let appended = new Promise((resolve, reject) => {
                filteredRows.map((row: any) => {
                    let obj = {}
                    Object.keys(row.original).map((header) => {
                        if (pdfColumns.includes(header.toString())) {
                            obj[header] = row.original[header]
                        }
                    })

                    exportData.push(obj)
                })
                resolve(true)
            })

            appended
                .then((result) => {
                    if (result) {
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        const exportExcel = () => {
            appendForExport()
            downloadWithDataDirectories(tableName, exportData)
        }

        const exportPDF = () => {
            appendForExport()
            PDFHandler(exportData, tableName)
        }
        return loadingData ? (
            <Loading
                isGrid={isGrid}
                activateGrid={activateGrid}
                downloadPDF={downloadPDF}
                headerGroups={headerGroups}
                hideSort={hideSort}
                newAction={newAction}
                objectName={objectName}
                rowHeight={50}
            />
        ) : (
            // <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
            //     <img alt="loading" src={loadingImage} />
            // </div>
            <>
                <div className={tableStyles.headerContainer}>
                    <div className={tableStyles.actionContainer}>
                        <div className={tableStyles.tableHeadingContain}>
                            <p style={{ fontSize: "22px", fontWeight: 600, color: "rgb(0, 40, 103)" }}>{objectName}</p>
                            <div className={tableStyles.optionsContainer}>
                                {newAction ? (
                                    <Button color="default" variant="outlined">
                                        <Add style={{ fontSize: "20px" }} />
                                        Add New {objectName}
                                    </Button>
                                ) : null}
                                {activateGrid ? (
                                    <div className={tableStyles.toggleContainer}>
                                        {activateMap ? (
                                            <ValidatePermission allowedModules={["03.03 - Directory / Salon"]} allowedSubRoutes={["Map View"]}>
                                                <Button
                                                    color="primary"
                                                    iconLeft={<Map className={tableStyles.buttonIcon} />}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        {
                                                            setIsMap(true)
                                                        }
                                                        {
                                                            setIsList(false)
                                                        }
                                                        {
                                                            setIsGrid(false)
                                                        }
                                                    }}
                                                    className={isMap ? tableStyles.btnActive : tableStyles.btnToggle}
                                                >
                                                    Map
                                                </Button>
                                            </ValidatePermission>
                                        ) : null}

                                        <Button
                                            color="primary"
                                            iconLeft={<AppsOutlined className={tableStyles.buttonIcon} />}
                                            variant="outlined"
                                            onClick={() => {
                                                {
                                                    setIsGrid(true)
                                                }
                                                {
                                                    setIsList(false)
                                                }
                                                {
                                                    setIsMap(false)
                                                }
                                            }}
                                            className={isGrid ? tableStyles.btnActive : tableStyles.btnToggle}
                                        >
                                            Grid
                                        </Button>

                                        <Button
                                            iconLeft={<FormatListBulletedOutlined className={tableStyles.buttonIcon} />}
                                            variant="outlined"
                                            onClick={() => {
                                                {
                                                    setIsList(true)
                                                }
                                                {
                                                    setIsMap(false)
                                                }
                                                {
                                                    setIsGrid(false)
                                                }
                                            }}
                                            className={isList ? tableStyles.btnActive : tableStyles.btnToggle}
                                            color="primary"
                                        >
                                            List
                                        </Button>
                                    </div>
                                ) : null}
                                <ValidatePermission allowedModules={["03.03 - Directory / Salon"]} allowedSubRoutes={["Download XLSX"]}>
                                    <Button color="default" variant="outlined" onClick={() => exportExcel()}>
                                        Download XLSX
                                    </Button>
                                </ValidatePermission>

                                {downloadPDF ? (
                                    <ValidatePermission allowedModules={["03.03 - Directory / Salon"]} allowedSubRoutes={["Download PDF"]}>
                                        <Button color="default" variant="outlined" onClick={() => exportPDF()}>
                                            Download PDF
                                        </Button>
                                    </ValidatePermission>
                                ) : null}
                                <ValidatePermission allowedModules={["03.03 - Directory / Salon"]} allowedSubRoutes={["New Salon"]}>
                                    <Button color="primary" variant="contained" onClick={() => setShowNewSalonModal(!showNewSalonModal)}>
                                        New Salon
                                    </Button>
                                </ValidatePermission>
                                {showNewSalonModal ? (
                                    <NewSalon
                                        setShowNewSalonModal={(res) => setShowNewSalonModal(res)}
                                        isSuccess={(e) => {
                                            if (e === true) {
                                                setShowNewSalonModal(false)
                                            }
                                        }}
                                    />
                                ) : null}
                            </div>
                        </div>

                        <div className={tableStyles.filtersContainer}>
                            <div>
                                <Text color="p_900" weight="fw_400">
                                    {`${rows.length} ${rows.length > 1 ? "results" : "result"}`} found
                                </Text>
                            </div>
                            <div className={tableStyles.searchContainer}>
                                <SearchBox
                                    onChange={(e) => {
                                        {
                                            setSearchVal(e)
                                        }
                                    }}
                                    value={searchVal}
                                />
                                {isGrid ? null : (
                                    <Button color="primary" variant="contained" onClick={() => setShowFiltering(!showFiltering)}>
                                        <FilterAltOutlined />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {isGrid ? <GridLayout onSelect={(clickedCard) => rowClicked(clickedCard)} template={tableName} page={page} prepareRow={prepareRow} /> : null}
                {isList ? (
                    <table className={`${tableStyles.table} ${hideSort ? tableStyles.hideSort : null}`} {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                    {column.render("Header")}
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <SortByAlpha {...column.getHeaderProps(column.getSortByToggleProps())} style={{ height: "16px", cursor: "pointer", color: "#005df1" }} />
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <ArrowRightAlt className={tableStyles.sortDesc} />
                                                            ) : (
                                                                <ArrowRightAlt className={tableStyles.sortAsc} />
                                                            )
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {showFiltering ? <div style={{ padding: ".5rem 0" }}>{column.canFilter ? column.render("Filter") : null}</div> : null}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)

                                return (
                                    <tr className={tableStyles.tableRow} {...row.getRowProps()}>
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <>
                                                    {row.cells.length === index + 1 ? (
                                                        <td onClick={() => rowClicked(row?.allCells[row?.allCells.length - 1]?.value)} className={tableStyles.tableData} {...cell.getCellProps()}>
                                                            {cell.render("Cell")}{" "}
                                                        </td>
                                                    ) : (
                                                        <td className={tableStyles.tableData} {...cell.getCellProps()}>
                                                            {cell.render("Cell")}
                                                        </td>
                                                    )}
                                                </>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : null}
                {isMap ? <MapLayout filterChanged={searchVal} page={page} prepareRow={prepareRow} /> : null}

                <div className={tableStyles.pagination}>
                    <div className={tableStyles.rowPages}>
                        <Text color="n_500" weight="fw_400">
                            Rows per page:
                        </Text>
                        <Input
                            items={pageList.map((pageSize) => ({
                                value: pageSize.toString(),
                                text: pageSize,
                            }))}
                            itemSize={12}
                            value={pageSize}
                            onChange={(pageRows) => {
                                setPageSize(Number(pageRows))
                            }}
                        />
                    </div>

                    <span>
                        <Text color="n_500" weight="fw_400">
                            Viewing Page: {pageIndex + 1} of {pageOptions.length}
                        </Text>
                    </span>

                    <div style={{ display: "flex", gap: ".5rem" }}>
                        <Button
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            className={tableStyles.paginationButton}
                            iconLeft={<ChevronLeft />}
                            color="pagination"
                            variant="contained"
                        ></Button>

                        <Button onClick={() => nextPage()} disabled={!canNextPage} className={tableStyles.paginationButton} iconLeft={<ChevronRight />} color="pagination" variant="contained"></Button>
                    </div>
                </div>
            </>
        )
    }

    const tableData = useMemo(() => data, [data])

    return (
        <div style={{ display: "flex" }}>
            <div>
                <div className={styles.tabContainer}>
                    <>
                        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                            <div>
                                <Search style={{ color: "#6B7280", height: "22px" }} />
                            </div>
                            <div style={{ color: "#6B7280", fontSize: "16px" }}>Search</div>
                        </div>
                        <p>
                            <b>Advanced filters</b>
                        </p>
                        <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
                            <div style={{ width: "237px" }}>
                                {allLocations !== undefined && allLocations !== null ? (
                                    <AdvancedDropDown
                                        data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                        onChange={(e) => {
                                            setLocation(e?.label)
                                        }}
                                        ref={myRef}
                                        placeHolder={"Salon Name"}
                                    />
                                ) : null}
                            </div>
                            <Input
                                size="small"
                                variant="outlined"
                                className={styles.input}
                                backgroundColor={"#F9FAFB"}
                                onChange={(e) => {
                                    setNeighborhood(e)
                                }}
                                value={neighborhood}
                                label={"Neighborhood"}
                            />
                            <Input
                                variant="outlined"
                                className={styles.input}
                                size="small"
                                backgroundColor={"#F9FAFB"}
                                onChange={(e) => {
                                    setCity(e)
                                }}
                                value={city}
                                label={"City"}
                            />
                        </div>

                        <p style={{ color: "#111827", fontWeight: "600" }}>Service</p>
                        <div className={styles.centerCheckboxes}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setGrooming(!grooming)} checked={grooming} />
                                <p>Grooming</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setBoarding(!Boarding)} checked={Boarding} />
                                <p>Boarding</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setDaycare(!Daycare)} checked={Daycare} />
                                <p>Daycare</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setSitting(!Sitting)} checked={Sitting} />
                                <p>Sitting</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setTraining(!Training)} checked={Training} />
                                <p>Training</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setMobileGrooming(!MobileGrooming)} checked={MobileGrooming} />
                                <p>Mobile Grooming</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox style={{ padding: "0 5px 0 0" }} color="primary" onChange={(e) => setStoreProduct(!StoreProduct)} checked={StoreProduct} />
                                <p>Store Product</p>
                            </div>
                        </div>
                        <p style={{ margin: "5px 0", fontWeight: "600", color: "#111827" }}>Status</p>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={activeStatus}
                            onChange={handleChangeActiveStatus}
                            className={styles.centerRadio}
                        >
                            <FormControlLabel value={true} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "13px" }}>Active</p>} />
                            <FormControlLabel value={false} control={<Radio style={{ padding: "0 5px 0 0" }} size={"small"} />} label={<p style={{ fontSize: "13px" }}>Inactive</p>} />
                        </RadioGroup>
                        <div style={{ borderBottom: "1px solid #d4d4d4", width: "100%", marginTop: ".6rem", marginBottom: ".6rem" }}></div>
                        <Button className={styles.resetButton} variant="outlined" color="info" loading={isLoading} onClick={resetFilters}>
                            Reset Filters
                        </Button>
                    </>
                </div>
            </div>

            <div style={{ width: "100%", padding: "10px" }}>
                {viewProfile ? (
                    <Profile goBack={(value) => setViewProfile(value)} type={"salon"} id={selectedSalonID} />
                ) : (
                    <RenderTable
                        downloadPDF={true}
                        searchFilter={searchVal}
                        tableName={"Salons"}
                        hiddenColumns={Location?.hiddenColumns}
                        activateGrid={true}
                        activateMap={true}
                        columns={Location?.template}
                        data={tableData}
                        loadingData={isLoading}
                        newAction={""}
                        objectName={"Salon Directory"}
                        rowClicked={(clickedId) => viewSalonProfile(clickedId)}
                        hideSort={Location?.hideSort}
                        pdfColumns={Location.pdfTemplate}
                    />
                )}
            </div>
        </div>
    )
}

export default Salon
