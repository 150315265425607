import React, { useEffect, useState } from 'react';
import './GroomerEarnings.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '../../components';
import { Box, TextField } from '@mui/material';
import { AttachMoney, Paid } from '@mui/icons-material';
import axios from '../../../apis/axios';
import useAuth from '../../../hooks/useAuth';
import moment from 'moment';

function GroomerEarnings() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const { auth } = useAuth() as any
  const [tip, setTip] = useState(0);
  const [earning, setEarning] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };
  const getEarnings = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}Payment/GetTotalTipAmount`;
    try {
        const response = await axios.get(apiUrl, {
            params: {
              EmployeeID: auth?.employee?.numEmployeeID,
              LocationID: auth?.currentLocation?.value,
              FromDate: (startDate !== "")? startDate : null,
              ToDate: (endDate !== null)? endDate : null
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

useEffect(()=>{
  if(isExpanded === true){
    fetchEarning()
  }
},[isExpanded])

const fetchEarning = () => {
  setIsLoading(true)
  getEarnings()
  .then((data) => {
      console.log("PercentList", data);
     setTip(data[0]?.TotalTipAmount)
     setIsLoading(false)
  })
  .catch((error) => {
      console.log("error", error);
      setIsLoading(false)
  });
}


  return (
    <div className="GroomerEarnings">
      <div className={`floating-menu ${isExpanded ? 'expanded' : ''}`}>
        <div className="menu-button" onClick={toggleMenu}>
          {isExpanded ? <ArrowForwardIcon style={{ fontSize: 25, color: "#3fbb5d", fontWeight:"600" }} /> : <Paid style={{ fontSize: 25, color: "#3fbb5d", fontWeight:"600" }} />}
        </div>
        {isExpanded && (
          <div>
            {(isLoading)?
            <p style={{fontSize:"12px", color:"green", fontWeight:"600"}}>Loading...</p>
             : 
             <>
                <p style={{fontSize:"20px", textAlign:"start", fontWeight:"600", marginBottom:"20px"}}>💵 Earnings</p>
                <div style={{display:"flex", gap:"10px", marginBottom:"20px"}}>
                  <div className="tips-summary">
                    <div className="tips-amount">
                      {tip + " $"}
                    </div>
                    <label className="tips-label">Tips</label>
                  </div>
                  <div className="tips-summary">
                    <div className="tips-amount">
                      {0 + " $"}
                    </div>
                    <label className="tips-label">Earnings</label>
                  </div>
                </div>

                <div style={{display:"flex", gap:"10px"}}>
                  <TextField
                    label="From"
                    type="date"
                    value={startDate}
                    size="small"
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="To"
                    type="date"
                    size="small"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{marginTop:"10px", textAlign:"end"}}>
                    <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      fetchEarning()
                    }}
                  >
                    Search
                  </Button>
                </div>
             </>
            }


          </div>
        )}
      </div>
    </div>
  );
}

export default GroomerEarnings;
