import styles from "../../Help.module.scss";


function MakingASchedule (){

    return(
     

      <div>
         <h3 style={{ fontWeight: '600', fontSize: '26px' }}> Making a Schedule</h3>
            <div id={styles.container}>
                     <p>This document describes the functions of the Administrators. Access to this module will be by means of a User Name and Password.</p>
                     <p> Access to this module will be by means of a User Name and Password.</p>

                     <img src={require('./assets/schedule.png')} alt=""/>
                     
            </div>

      </div>
    );

}

export default MakingASchedule;