/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "varPetTypeName": "Dog",
    "varPetTypeDescription": null,
    "bitPetTypeActive": true
*/

export const ZipCode: {} = {
    tableName: "2.6 - Zip Codes",
    hiddenColumns: ["numZipCodeID"],
    pdfTemplate: [
        "varZipCode",
        "varZipCodeType",
        "varZipCodeCity",
        "varZipCodeState",
        "varZipCodeCounty",
        "varZipCodeTimezone",
        "varZipCodeCountry",
        "numZipCodelatitude",
        "numZipCodelongitude",
        "bitActive",
    ],
    template: [
        {
            Header: "Zip Code",
            accessor: "varZipCode",
        },
        {
            Header: "Type",
            accessor: "varZipCodeType",
        },
        {
            Header: "City",
            accessor: "varZipCodeCity",
        },
        {
            Header: "State",
            accessor: "varZipCodeState",
        },
        {
            Header: "County",
            accessor: "varZipCodeCounty",
        },
        {
            Header: "Timezone",
            accessor: "varZipCodeTimezone",
        },
        {
            Header: "Country",
            accessor: "varZipCodeCountry",
        },
        {
            Header: "Latitude",
            accessor: "numZipCodelatitude",
        },
        {
            Header: "Longitude",
            accessor: "numZipCodelongitude",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numZipCodeID",
            accessor: "numZipCodeID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "varZipCode",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varZipCodeType",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varZipCodeCity",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varZipCodeState",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varZipCodeCounty",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varZipCodeTimezone",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varZipCodeCountry",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numZipCodelatitude",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: null,
        },
        {
            fieldName: "numZipCodelongitude",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: null,
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
