import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import { shortDate } from '../../../../utils/DateFormatter';
import { Truncate, daysPassedOrInFuture, formatCash } from '../../../../utils/Validation';
import { OpenInNew } from '@mui/icons-material';
import { Button, Chip } from '../../../components';
import { IconButton } from '@mui/material';
import CustomerInvoicesModal from '../../../ExtModals/CustomerInvoices';
import CustomerInvoiceList from '../../../ExtModals/CustomerInvoiceList';

function CustomerInvoices(props) {
  const { data, filterText, onFilterTextChange, page, rowsPerPage, onPageChange, onRowsPerPageChange, sendDataToParent, customerID, isSuccess  } = props;

  const filterData = () => {
    return data.filter((item) =>
      Object.values(item).some((value) =>
        typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
      )
    );
  };


  const filteredData = filterData();
  const [invIdSelected, setInvIdSelected] = useState(0);
  const [isOpenCustomerInvoicesModal, setIsOpenCustomerInvoicesModal] = useState(false);
  const [isOpenCustomerInvoicesDevModal, setIsOpenCustomerInvoicesDevModal] = useState(false);

  const triggerInvoice = (id) => {
    setInvIdSelected(id);
    setIsOpenCustomerInvoicesModal(true);
  };



  const handleSuccess = () => {
    const dataToSend = true;
    sendDataToParent(dataToSend);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <div style={{display:"flex", gap:"10px"}}>
        <TextField
          style={{ width: '30%' }}
          label={
            <div style={{ display: 'flex', fontSize: '14px' }}>
              Filter invoices
            </div>
          }
          type={'text'}
          size='small'
          variant="outlined"
          multiline={false}
          onChange={(e) => onFilterTextChange(e.target.value)}
          value={filterText}
        />
        <Button variant="outlined" color="default"  onClick={() => setIsOpenCustomerInvoicesDevModal(true)}>Bulk Payment</Button>
        <CustomerInvoiceList isOpenCustomerInvoicesModal={isOpenCustomerInvoicesDevModal} setIsOpenCustomerInvoicesModal={(value) => setIsOpenCustomerInvoicesDevModal(value)} customerID={customerID} isSuccess={(e)=>{
          if(e === true){
            isSuccess(true)
          }
        }}/>  

      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#298def" }}>INV#</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Payments</TableCell>
              <TableCell>Remain Amt</TableCell>
              <TableCell>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <TableRow key={item.numInvoiceID}>
                  <TableCell style={{ fontSize: "13px", fontWeight: "500" }}>{item.numMainInvoiceID}</TableCell>
                  <TableCell style={{ fontSize: "13px" }}>{Truncate(item.varLocationName, 20)}</TableCell>
                  <TableCell style={{ fontSize: "13px" }}>{shortDate(item.dtDueDate) +" ("+ daysPassedOrInFuture(item.dtDueDate)+")"}</TableCell>
                  <TableCell style={{ fontSize: "13px" }}>${formatCash(item.numInvoiceTotalCharges)}</TableCell>
                  <TableCell style={{ fontSize: "13px" }}>${formatCash(item.numInvoiceTotalPayments)}</TableCell>
                  <TableCell style={{ fontSize: "13px" }}>
                    <Chip size='small' customBackgroundColor={"#f56e6e"} customBorder={"#dd4848"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"$"+formatCash(item.numInvoiceTotalRemaining)} />
                  </TableCell>
                  <TableCell style={{ fontSize: "13px" }}>
                    <IconButton onClick={() => { triggerInvoice(item.numInvoiceID) }}>
                      <OpenInNew style={{ color: "#298def" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <CustomerInvoicesModal
        appointmentDetails={{ numInvoiceID: invIdSelected }}
        isOpenCustomerInvoicesModal={isOpenCustomerInvoicesModal}
        setIsOpenCustomerInvoicesModal={(value) => setIsOpenCustomerInvoicesModal(value)}
        isPassedInvoice={true}
        isSuccess={(e) => {
          if (e === true) {
            console.log("payment made");
            isSuccess(true)
            //setIsOpenCustomerInvoicesModal(false);
            handleSuccess()
          }
        }}
        isSuccessFullPayment={(e)=>{
          if (e === true) {
            console.log("full payment made");
            isSuccess(true)
            //setIsOpenCustomerInvoicesModal(false);
            handleSuccess()
          }
        }}
      />
    </div>
  );
}

export default CustomerInvoices;