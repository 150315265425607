import jsPDF from "jspdf";
export const PDFListExport = (exportData, tableName) => {
    const unit = "pt";
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    let doc:any = new jsPDF(orientation, unit, size);
    doc.setFontSize(12);
    doc.setTextColor(0,0,255);
    doc.setFont("helvetica");
    doc.text("Mismatched images", marginLeft, 10);
    doc.text("---------------------------",marginLeft, 15);
    doc.setTextColor(100);
    doc.text(exportData,marginLeft,30)
    doc.save(tableName.replace(/\s/g, '')+".pdf")
}