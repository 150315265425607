/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel";
import { OpenInNew } from '@mui/icons-material'
import { Button } from "../../../components";
import { Chip } from "../../components/Chip/Chip";
import { formatPhone } from "../../../../utils/PhoneFormatter";
import { Chip as LocationChip } from "../../../../components/";


export const Appointments = {
    tableName: "Appointments",
    hiddenColumns: ["varCustomerLastName", "varCustomerZip", "dtCreatedDate", "varCustomerImagePath"],
    pdfTemplate: ['varCustomerFirstName','varCustomerLastName','varCustomerCity', 'varCustomerZip', 'varCustomerState', 'varCustomerMobilePhone', 'varCustomerHomePhone', 'varCustomerDefaultLocation', 'bitActive'],
    hideSort: true,
    template: [
        {
            Header: 'Customer',
            accessor: 'varCustomerFirstName',  
            width: 10,
            disableSortBy: false,
            Cell: props => (
                <AvatarLabel 
                    isVip={props.row.original.bitIsVip} 
                    img={(props.row.original.varCustomerImagePath == null || props.row.original.varCustomerImagePath == "")? "" : `${process.env.REACT_APP_MEDIA_URL}directories/customer/` + props.row.original.varCustomerImagePath} 
                    name={props.row.original.varCustomerFirstName + " " + props.row.original.varCustomerLastName} 
                />        
            )            
        },
        {
            Header: 'varCustomerLastName',
            accessor: 'varCustomerLastName'
        },       
        {
            Header: 'Pet Name',
            accessor: 'varPetName'
        },
        {
            Header: 'Employee Name',
            accessor: 'groomerFullName',           
        },
        {
            Header: 'Zip',
            accessor: 'varCustomerZip'
        },
        {
            Header: 'Start Time',
            accessor: 'dtApptStartTime'
        },
        {
            Header: 'Est. End Time',
            accessor: 'dtDateTimeTo',
        }, 
        {
            Header: 'Location',
            accessor: 'varLocationName'
        },        
        {
            Header: 'dtCreatedDate',
            accessor: 'dtCreatedDate'
        },
        {
            Header: 'img',
            accessor: 'varCustomerImagePath',
            disableFilters: true,
        },
         
        {
            Header: '',
            accessor: 'numAppointmentID',
            disableFilters: true,
            Cell: props => (
                <Button color="transparent" variant="text" onClick={() => console.log(props.row.original.numAppointmentID)}><OpenInNew style={{ color: '#005df1' }}/></Button>                
            )      
        }
    ]
}
