import styles from './Modal.module.scss'
import { Button, Modal as MaterialModal } from '@mui/material'
import { Close } from '@mui/icons-material';
import { useEffect, useState } from 'react';

export const Modal = (props: {
    children?;
    onClose?: CallableFunction;
    open: boolean;
    isFullWidth?: boolean;
    borderless?: boolean
    backgroundColor?;
}) => {
    useEffect(() => {
        setIsOpen(props.open)
    }, [props.open])

    const [isOpen, setIsOpen] = useState(false);

    return (
        <MaterialModal
            open={isOpen}
            onClose={props.onClose?.()}
        >
            <div className={`${(props.isFullWidth) ? styles.modalFW : null} ${styles.modal}`}>
                <div className={`${(props.isFullWidth) ? styles.modalBodyFull : styles.modalBodyContained} ${(props.borderless) ? styles.borderless : null}`} style={{ backgroundColor: `${(props.backgroundColor !== undefined) ? props.backgroundColor : undefined}` }}>
                    {props.children}
                </div>
            </div>

        </MaterialModal>
    );
}
