import { Warning } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import styles from '../PersonalDetails.module.scss';

const DisplayPetProfile = (props: {
    petProfileTemplate;
}) => {
    return props.petProfileTemplate.map(section => {
        return <>
            
            
            {
                (section.layout == 'text') ?     
                    <>
                        <p className={styles.heading}>{section.heading}</p>

                        <table cellSpacing="0" className={styles.table}>  
                        {
                            section.data.map(pairs => {
                                return <tr>
                                    {
                                        (pairs.key === '') ? 
                                            null
                                        :
                                            <td>{pairs.key}</td>
                                    }
                                    <td>{pairs.value}</td>
                                </tr>
                                    
                            }) 
                        }                                                                                           
                        </table>  
                        <div style={{ paddingTop: '1rem', marginBottom: '1.5rem', borderBottom: '1px solid #F3F4F6' }}></div>     
                    </>
                : (section.layout === 'personality') ? 
                    <>
                        {
                            console.log('pererer',section.data)
                        }
                        <p className={styles.heading}>{section.heading}</p>

                        <div>
                            {                                
                                (section.data != null) ? 
                                    section.data.map(d => {
                                        return <div key={d.numPetPersonalityID} style={{display:"flex", alignItems: 'center' ,gap: '.5rem'}}>
                                            <p>{d.varPersonality}</p>  
                                            <Warning style={{ fontSize: '15px', color: '#EB1A1A' }}/>
                                        </div> 
                                    })
                                : <p>No personalities set</p>
                            }
                            
                        </div>   
                        <div style={{ paddingTop: '1rem', marginBottom: '1.5rem', borderBottom: '1px solid #F3F4F6' }}></div>     
                    </>
                : (section.layout == 'custom') ?   
                    (section.data.length > 0) ?
                        <>
                            <p className={styles.heading}>{section.heading}</p>

                            <table cellSpacing="0" className={styles.table}>  
                            {
                                section.data.map(pairs => {
                                    return <tr>
                                        {
                                            (pairs.key === '') ? 
                                                null
                                            :
                                                <td>{pairs.key}</td>
                                        }
                                        <td>{pairs.value}</td>
                                    </tr>
                                        
                                }) 
                            }                                                                                           
                            </table>  
                            <div style={{ paddingTop: '1rem', marginBottom: '1.5rem', borderBottom: '1px solid #F3F4F6' }}></div>     
                        </>
                    : null
                : null
            }    
        
            
        </>                                                
    })
}

export default DisplayPetProfile;