/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/
import { shortDate } from "../../../../../utils/DateFormatter";

export const SpecialPriceListTemplate = {
    tableName: "5.6 - SpecialPriceList",
    hiddenColumns: [],
    pdfTemplate: ["GeneralPriceListID", "varLocationName", "EmployeeName", "varServiceItemName", "varPetTypeName", "varPetBreedName", "varPetSizeName", "DurationTime", "dtEffectiveDate", "dtExpiryDate", "bitActive"],
    hideSort: true,
    template: [
        // {
        //     Header: 'ID',
        //     accessor: 'GeneralPriceListID'
        // },
        {
            Header: 'Location',
            accessor: 'varLocationName'
        },
        {
            Header: 'Employee',
            accessor: 'EmployeeName'
        },
        {
            Header: 'Service Name',
            accessor: 'varServiceItemName'
        },
        {
            Header: 'Pet Type',
            accessor: 'varPetTypeName'
        },
        {
            Header: 'Breed',
            accessor: 'varPetBreedName'
        },
        {
            Header: 'Size',
            accessor: 'varPetSizeName'  
        },
        {
            Header: 'Duration (M)',
            accessor: 'DurationTime'  
        },
        {
            Header: 'Effective Date',
            accessor: 'dtEffectiveDate',
            Cell: (props) => <p>{shortDate(props?.cell?.value)}</p>,
        },
        {
            Header: 'Expiry Date',
            accessor: 'dtExpiryDate',
            Cell: (props) => <p>{shortDate(props?.cell?.value)}</p>,
        },
        {
            Header: "Status",
            accessor: "bitActive"
           
        },
    ]
}
