import { Close } from "@mui/icons-material";
import "./CloseButton.sass";
import IconButton from "@mui/material/IconButton";

export function CloseButton(props: {
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  onClick?: CallableFunction | any;
  size?: "small" | "medium" | "large";
}) {
  let sx;
  switch (props.size) {
    case "small":
      sx = { height: "30px", width: "30px" };
      break;
    case "medium":
      sx = { height: "40px", width: "40px" };
      break;
    case "large":
      sx = { height: "60px", width: "60px" };
      break;
    default:
      sx = {
        height: "40px",
        width: "40px",
      };
      break;
  }
  let style = {
    ...sx,
    backgroundColor: "#4B5563",
    color: "#fff",
    transition: "1000ms",
    "&:hover": {
      transition: "1000ms",
      transform: "rotate(90deg)",
      color: "#4B5563",
      backgroundColor: "white",
      boxShadow: "2px 2px 16px rgba(0, 40, 103, 0.1)",
    },
  };
  return (
    <IconButton
      aria-label="delete"
      size={props.size}
      sx={style}
      onClick={props.onClick}
      color={props.color}
    >
      <Close />
    </IconButton>
  );
}
