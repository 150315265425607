
import { Eventcalendar, setOptions } from '@mobiscroll/react';
import { useEffect, useState, useCallback } from "react";
import moment from 'moment';
import { Star } from '@mui/icons-material';

const SlotSelector = (props: {
    resultObj: any;
}) => {

    setOptions({
        theme: 'ios',
        themeVariant: 'light'
    });

    const [staffMembers, setStaffMembers] = useState([]) as any
    const [shifts, setShifts] = useState<any>();
    const tempSlots = [] as any
    
    const calculateSlots = (fromTime, toTime, taskDuration, employeeID, scheduleID) => {

        let from = moment(fromTime, "YYYY-MM-DDTHH:mm")
        let to = moment(toTime, "YYYY-MM-DDTHH:mm")
        let duration = taskDuration
    
        let fullTime = moment.duration(to.diff(from)).asMinutes()
        let lastStart = from as any     
        
        for(let i=0; i<(fullTime/duration); i++){
            let newTime = moment(lastStart, "YYYY-MM-DDTHH:mm").add(duration, 'minutes').format("YYYY-MM-DDTHH:mm")
          
            if(moment.duration(to.diff(newTime)).asMinutes() < 0)
                break;
                       
            tempSlots.push({
                start: moment(lastStart),//.format("YYYY-MM-DDHH:mm"),
                end: newTime,                
                title: `${moment(lastStart).format("HH:mm A")} to ${moment(newTime).format("HH:mm A")}`,
                resource: employeeID,
                slot: i,
                duration: taskDuration,
                scheduleID: scheduleID
            })    

            lastStart = newTime
        }
        setShifts(tempSlots)
    }

    const processSlotInfo = (resObject) => {        
        let staff = [] as any

        resObject?.map((slot) => {

            let StaffExist = staff.some(x => x.id === slot.numEmployeeID)
            /* EMPLOYEE LIST */
            if(StaffExist !== true){
                staff.push({
                    id: slot.numEmployeeID,
                    name: slot.varEmployeeFirstName + ' ' + slot.varEmployeeLastName,
                    color: slot.varColour,
                    title: slot.numOccupationID,
                    img: slot.varEmployeeImagePath
                })
            }
            /* STAFF SLOTS LIST */
            calculateSlots(slot.dtDateTimeFrom, slot.dtDateTimeTo, slot.taskDuration, slot.numEmployeeID, slot.numSCHScheduleID)
        })        

        setStaffMembers(staff)        
    }

    useEffect(() => {
        if(props?.resultObj.length > 0){
            processSlotInfo(props?.resultObj)
        }else{
            setShifts([])
            setStaffMembers([])
        }


    }, [props?.resultObj])
    

    const numberOfDays = 1;

    function getStartDay() {
        const today = moment().isoWeekday();
        switch (today) {
            case 1: // Monday
                return 1;
            case 2: // Tuesday
                return 2;
            case 3: // Wednesday
                return 3;
            case 4: // Thursday
                return 4;
            case 5: // Friday
                return 5;
            default:
                return 1; // Default to Monday
        }
    }
    

        const viewSettings:any = {
            timeline: {
                type: "week",
                eventList: true,
                startDay: getStartDay(),
                endDay: getStartDay(),
                skipWeekend: true,
                start: moment().startOf('day'),
                end: moment().startOf('day').add(numberOfDays - 1, 'days'),
            },
        };


    const renderMyResource = (resource: any) => {
        return (props.resultObj.length > 0)?<div style={{ display: 'flex', gap: '5px', width: 'auto' }}>
            <img style={{ height: '50px', borderRadius:"50px", border:"2px solid #fff", boxShadow: "3px 3px 10px 3px #ddd" }} src={resource.img} alt="Avatar" />

            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <div style={{ fontSize: '16px' }}>{resource.name}</div>
                <div>
                    <div style={{ fontSize: '12px', color:"#7a7a7a" }}>{resource.title}</div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '.1rem' }}><Star style={{ color: '#F7AE32', fontSize:"20px" }}/>
                            <p style={{ margin: '0', fontWeight: '500' }}>
                            {'4.1'}
                            </p>
                    </div>
                </div>
            </div>                       
        </div> : <></>
    }

    const onEventClick = useCallback((args) => {
        const event = args.event;     
    }, []);



    return (
        <div style={{ width: '100%', maxHeight:"635px", overflowY:"visible", scrollbarWidth:"none", msOverflowStyle:"none", minHeight:"635px"  }}>                            
            <Eventcalendar
                width={"100%"}                
                view={viewSettings}
                data={shifts}
                resources={staffMembers}                
                dragToCreate={false}
                dragToResize={false}
                dragToMove={false}
                onEventClick={onEventClick}
                clickToCreate={false}
                renderResource={renderMyResource}
                cssClass="md-employee-shifts"
            />
        </div>
    );
}

export default SlotSelector;