import React, { useEffect, useState } from "react"
import { Button, Input } from "../../../components"
import styles from "../Login.module.scss"
import { useParams } from "react-router-dom"
import axios, { axiosPrivate } from "../../../apis/axios"
import { ToastContainer, toast } from "react-toastify"

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState("")
    const [retypeNewPassword, setRetypeNewPassword] = useState("")
    const [error, setError] = useState("")
    const [securityAnswer, setSecurityAnswer] = useState("")
    const { userId } = useParams()
    const [loading, setLoading] = useState(false)

    const [usersQuestion, setUsersQuestion] = useState([]) as any

    async function loginAccountReset() {
        const response = await axios.post(
            "/Auth/Reset",
            JSON.stringify({
                numUserID: userId,
                varUserPassword: newPassword,
            }),
            {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            }
        )

        return response
    }

    async function validateAnswer() {
        let payload = {
            numUserID: userId,
            questionsAndAnswers: [
                {
                    numQuestionID: usersQuestion?.numQuestionID,
                    varAnswer: securityAnswer,
                },
            ],
        }

        await axiosPrivate
            .post(`UserQuestion/ValidateSecurityQuestions`, JSON.stringify(payload), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result?.data) {
                    if (newPassword !== "" || retypeNewPassword !== "") {
                        if (newPassword === retypeNewPassword) {
                            setLoading(true)
                            loginAccountReset()
                                .then((res) => {
                                    toast.success(`Password changed successfully!`)
                                    setLoading(false)
                                })
                                .catch((err) => {
                                    setLoading(false)
                                    console.log(err)
                                    toast.error("Something went wrong!")
                                })
                        } else {
                            setLoading(false)
                            toast.warning("Passwords don't match!")
                        }
                    } else {
                        setLoading(false)
                        toast.warning("Invalid Password!")
                    }
                } else {
                    toast.warning(`Security Answer Incorrect!`)
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong when validating security question!")
            })
    }

    const handleNewPasswordChange = (e) => {
        setNewPassword(e)
    }

    const handleRetypeNewPasswordChange = (e) => {
        setRetypeNewPassword(e)
    }

    const handleSubmit = () => {
        validateAnswer()
    }

    useEffect(() => {
        if (newPassword === retypeNewPassword) {
            setError("")
        } else {
            setError("Passwords do not match.")
        }
    }, [newPassword, retypeNewPassword])

    async function getUserQuestions(userId) {
        const result = await axiosPrivate.get(`UserQuestion/GetSecurityQuestionsByUserID?numUserID=${userId}`)
        return result
    }

    const genRandom = (min, max) => {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    useEffect(() => {
        getUserQuestions(userId)
            .then((response) => {
                let allQs = response?.data
                if (allQs.length > 0) {
                    const ids = new Set() as any

                    allQs.map((qs) => {
                        ids.add(qs?.numQuestionID)
                    })

                    let idsArr = Array.from(ids)
                    console.log(allQs[genRandom(0, idsArr.length - 1)])
                    setUsersQuestion(allQs[genRandom(0, idsArr.length - 1)])
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }, [])

    return (
        <>
            <ToastContainer />
            <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <div style={{ width: "500px" }}>
                    <h3>Security Question</h3>
                    <p>{usersQuestion?.varQuestion}</p>
                    <Input type="text" value={securityAnswer} onChange={(e) => setSecurityAnswer(e)} />
                </div>
                <div style={{ marginTop: "15px", width: "500px" }}>
                    <p>New Password</p>
                    <Input label={"Enter Password"} type="password" value={newPassword} onChange={handleNewPasswordChange} />
                </div>
                <div style={{ width: "500px" }}>
                    <p>Retype New Password</p>
                    <Input label={"Enter Password"} type="password" value={retypeNewPassword} onChange={handleRetypeNewPasswordChange} />
                </div>
                {error && <p className={styles.error}>{error}</p>}
                <div style={{ marginTop: "25px" }}>
                    <Button loading={loading} className={styles.loginBtn} variant="contained" color="primary" onClick={handleSubmit}>
                        Change Password
                    </Button>
                </div>
            </div>
        </>
    )
}

export default ResetPassword
