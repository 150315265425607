import React, { useState, useCallback, useRef, useEffect, useMemo } from "react"
import { Avatar, Chip } from "../../../../components"
import "@mobiscroll/react/dist/css/mobiscroll.min.css"
import debouce from "lodash.debounce"
import InputMask from "react-input-mask"
import {
    Eventcalendar,
    getJson,
    Popup,
    momentTimezone,
    Select,
    formatDate,
    CalendarNav,
    SegmentedGroup,
    SegmentedItem,
    CalendarPrev,
    CalendarNext,
    MbscCalendarEvent,
    MbscCalendarEventData,
} from "@mobiscroll/react"

/* BOOKING INPORTS */
import { TextField, Button as MaterialButton, MenuItem, Accordion, AccordionSummary } from "@mui/material"

import { Button as CustomButton, Chip as LocationChip, Input } from "../../../../components"
import { Add, Close, ContentCut, Pets, Place, StickyNote2Outlined, WarningAmber } from "@mui/icons-material"
import { Truncate } from "../../../../utils/Validation"
import { formatPhone, removeFormatPhone } from "../../../../utils/PhoneFormatter"
import { checkPermission } from "../../../../utils/permissions"
import placeholderImage from "../../../../images/pet_placeholder.png"

import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined"
import ValidatePermission from "../../../../components/ValidatePermission/ValidatePermission"
import CustomerAlerts from "../../../../components/ExtModals/CustomerAlerts"
import PetAlerts from "../../../../components/ExtModals/PetAlerts"
/* END BOOKING INPORTS */

import styles from "./Calendar.module.scss"
import moment from "moment-timezone"
import { axiosPrivate } from "../../../../apis/axios"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AdvancedDropDown from "../../../../components/AdvancedDropDown/AdvancedDropDown"
import { Button, CircularProgress, Collapse, IconButton, Switch, Tooltip } from "@mui/material"
import { ArrowBack, ArrowBackIos, ArrowForward, ArrowForwardIos, CalendarToday, ColorLensOutlined, InfoOutlined, Search, ZoomIn } from "@mui/icons-material"
import { SketchPicker } from "react-color"
import { addMinutes, calculateWeeklyTiming, formatDateTime, getMinutesDuration, formatDateTimeScheduler } from "../../../../utils/TimeHandler"
import "@mobiscroll/react/dist/css/mobiscroll.min.css"
import AllCalendar from "./AllCalendar/AllCalendar"
import useAuth from "../../../../hooks/useAuth"
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators"
import { ProfileAppointment } from "../../../../components/ProfileAppointment/ProfileAppointment"
import AllCalendarBooking from "./AllCalendarBooking/AllCalendarBooking"
momentTimezone.moment = moment

function NewCalendar(props: { timeInterval; locationID; employeeID }) {
    const [myEvents] = useState<MbscCalendarEvent[]>([
        {
            start: "2024-04-03T06:00",
            end: "2024-04-03T08:00",
            title: "Full Groom",
            color: "#ff6d42",
        },

        {
            start: "2024-04-03T09:00",
            end: "2024-04-03T09:45",
            title: "Nail Clipping",
            color: "#ff6d42",
        },

        {
            start: "2024-04-03T11:00",
            end: "2024-04-03T13:15",
            title: "Full Groom",
            color: "#ff6d42",
        },

        {
            start: "2024-04-02T06:00",
            end: "2024-04-02T08:00",
            title: "Bath",
            color: "#e7ffe280",
        },

        {
            start: "2024-05-02T09:00",
            end: "2024-05-02T09:45",
            title: "Full Groom",
            color: "#e7ffe280",
        },

        {
            start: "2024-05-02T10:15",
            end: "2024-05-02T10:45",
            title: "Mini Groom + Bath",
            color: "#e7ffe280",
        },
        {
            start: "2024-05-02T11:15",
            end: "2024-05-02T13:30",
            title: "Full Groom",
            color: "#e7ffe280",
        },
    ]) as any

    const { auth } = useAuth() as any

    // useEffect(() => {
    //     console.log(myEvents)
    // }, [myEvents])

    const [allCustomers, setAllCustomers] = useState<any>([])
    const [employeeList, setEmployeeList] = useState<any>([])
    const [employees, setEmployees] = useState<any>([])
    const [templateList, setTemplateList] = useState<any>([])
    const [allPet, setAllPet] = useState<any>([])
    const [loadUserState, setLoadUserState] = useState<any>([])
    const [availableSlots, setAvailableSlots] = useState<any>([])

    const [visibleSidebar, setVisibleSidebar] = useState(true)

    const filterLocationRef = useRef<any>()

    const toggleSidebar = () => {
        setVisibleSidebar(!visibleSidebar)
    }

    const fetchLocationData = () => {
        getLocations()
            .then((res) => {
                setAllLocations(res)
                setSelectedLocation(auth?.currentLocation?.value)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })
    }

    async function getCustomersAll(locationid) {
        const result = await axiosPrivate.get(`Customer/GetCustomersByLocationID?LocationID=${locationid}`)
        return result.data
    }

    async function getAppointmentState(originalLocationID, employeeID) {
        const result = await axiosPrivate.get(`Appointment/GetAppointmentState?EmployeeID=${employeeID}&OriginalLocationID=${originalLocationID}`)
        return result.data
    }

    const fetchCustomers = (locationid) => {
        getCustomersAll(locationid)
            .then((res) => {
                console.log("cusss", res)
                setAllCustomers(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })
    }

    const [timezone, setTimezone] = useState("utc")
    const [groupEventsLoading, setGroupEventsLoading] = useState(false)
    const [isNewBooking, setIsNewBooking] = useState(false)
    const [selectedEventGroup, setSelectedEventGroup] = useState("")

    const [employeePrecedingChanged, setEmployeePrecedingChanged] = useState(0)
    const [employeeSucceedingChanged, setEmployeeSucceedingChanged] = useState(0)

    const [eventGroupChanged, setEventGroupChanged] = useState(0)
    const [selectedLocation, setSelectedLocation] = useState(0) as any
    const [selectedCustomer, setSelectedCustomer] = useState(0) as any
    const [selectedPet, setSelectedPet] = useState(0) as any
    const [selectedEmployee, setSelectedEmployee] = useState(0) as any
    const [selectedTemplate, setSelectedTemplate] = useState(0) as any

    const [groupEvents, setGroupEvents] = useState([]) as any
    const [allEvents, setAllEvents] = useState([]) as any
    const [allEventsByGroup, setAllEventsByGroup] = useState([]) as any

    const [slotIntervals] = useState([
        {
            slotIntervalId: 1,
            slotInterval: 15,
        },
        {
            slotIntervalId: 2,
            slotInterval: 30,
        },
        {
            slotIntervalId: 3,
            slotInterval: 60,
        },
    ]) as any
    const [selectedSlotInterval, setSelectedSlotInterval] = useState(0) as any

    const [filteredEvents, setFilteredEvents] = useState([]) as any
    const [selectedTemplateList, setSelectedTemplateList] = useState([]) as any
    const [templatesList, setTemplatesList] = useState([]) as any
    const [showColorPicker, setShowColorPicker] = useState(false)

    const [precedingList, setPrecedingList] = useState({}) as any

    const [originalStartTime, setOriginalStartTime] = useState("")
    const [originalEndTime, setOriginalEndTime] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [maxEventDuration, setMaxEventDuration] = useState("") as any

    async function getPets(customerId) {
        const result = await axiosPrivate.get(`Customer/GetByID/${customerId}`)
        return result
    }

    useEffect(() => {
        getPets(selectedCustomer)
            .then((response) => {
                console.log("petss", response?.data?.customer?.customerPet)
                setAllPet(response?.data?.customer?.customerPet)
            })
            .catch((err) => {
                console.log(err)
                //toast.error("Something went wrong!");
                setGroupEventsLoading(false)
            })
    }, [selectedCustomer])

    /* GET ALL EVENT GROUPS */
    async function getGroupEvents() {
        setGroupEventsLoading(true)
        const result = await axiosPrivate.get(`ServiceGroup/GetAll`)
        return result
    }

    /* GET ALL EVENTS */
    async function getAllEvents() {
        const result = await axiosPrivate.get(`Event/GetAll`)
        return result
    }

    /* GET ALL EVENTS BY EVENT GROUP ID */
    async function getAllEventsByGroupID(eventGroupID) {
        const result = await axiosPrivate.get(`EventGroup/GetEventsByID/${eventGroupID}`)
        return result
    }

    /* GET ALL TEMPLATES BY SCHEDULE ID */
    async function getAllTemplatesBySchedule(scheduleID) {
        const result = await axiosPrivate.get(`Schedule/GetScheduleTemplateAppointmentByScheduleID/${scheduleID}`)
        return result
    }

    /* GET ALL EMPLOYEE */
    async function getAllEmployees() {
        const result = await axiosPrivate.get(`Employee/GetAll`)
        return result
    }

    /* GET SCHEDULE BY EMPLOYEE */
    async function getSchedulesByEmployeeID(employeeID) {
        const result = await axiosPrivate.get(`Schedule/GetScheduleByEmployeeID/${employeeID}`)
        return result
    }

    /* DELETE EVENT BY APPOINTMENT ID */
    async function deleteEventByAppointmentID(appID) {
        const result = await axiosPrivate.delete(`ServiceSchedule/DeleteServiceSchedule/${appID}`)
        return result
    }

    /* UPDATE EVENT BY APPOINTMENT ID */
    async function updateEventByAppointmentID(appID, fromTime, toTime) {
        const result = await axiosPrivate.put(`ServiceSchedule/UpdateServiceScheduleDuration/${appID}/${fromTime}/${toTime}`)
        return result
    }

    /* ADD NEW SCHEDULE APPOINTMENT ITEM */
    async function addNewEventGroup(payload) {
        console.log("payloadf", payload)

        const result = await axiosPrivate.post("ServiceSchedule", JSON.stringify(payload))
        return result
    }

    /* GET ALL SERVICE GROUPS BY EMP ID AND LOC ID */
    async function getAllGroupsList() {
        const result = await axiosPrivate.get(`ServiceSchedule/GetAllServiceGroupsWithServicesByID/${props?.employeeID}/${props?.locationID}`)
        return result
    }

    async function getEmployeesByLocationEdit() {
        const result = await axiosPrivate.get(`Schedule/GetEmployeesByLocationID?LocationID=${selectedLocation === 0 ? "" : selectedLocation}`)
        return result.data
    }

    async function getTemplateList() {
        const result = await axiosPrivate.get(
            `Schedule/GetTimeSlotsByEmployeeID?LocationID=${selectedLocation === 0 ? "" : selectedLocation}&EmployeeID=${selectedEmployee === 0 ? "" : selectedEmployee}`
        )
        console.log("tempaltes list", result.data)
        return result.data
    }

    async function saveCurrentFilterState(payload) {
        console.log("saveCurrentFilterState", payload)

        const result = await axiosPrivate.post("Appointment/PopulateAppointmentState", JSON.stringify(payload))
        return result
    }

    useEffect(() => {
        if (eventGroupChanged != 0) {
            getAllEventsByGroup(eventGroupChanged)
        }
    }, [eventGroupChanged])

    const newBooking = () => {
        setIsNewBooking(!isNewBooking)
    }

    useEffect(() => {
        getTemplateList()
            .then((response) => {
                let res = response
                console.log("temp", res)
                setTemplateList(res)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }, [selectedEmployee])

    useEffect(() => {
        fetchCustomers(selectedLocation)
        getEmployeesByLocationEdit()
            .then((response) => {
                let resEdit = response
                console.log("employy", resEdit)
                setEmployeeList(resEdit)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }, [selectedLocation])

    useEffect(() => {
        getAppointmentState(auth?.currentLocation?.value, auth?.employee?.numEmployeeID)
            .then((res) => {
                console.log("load state", res)

                if (res?.AppointmentStateDetail?.length > 0) {
                    setLoadUserState(res?.AppointmentStateDetail)
                } else {
                    setLoadUserState([])
                }
            })
            .catch((err) => {})
    }, [employeeList])

    useEffect(() => {
        getSchedulesByEmployeeID(employeePrecedingChanged)
            .then((response) => {
                let schID = response.data.schedulesList[0].numSchID
                console.log(schID)
                getTemplateDetails(schID)
            })
            .catch((err) => {
                console.log(err)
                //toast.error("Something went wrong!");
                setGroupEventsLoading(false)
            })
    }, [employeePrecedingChanged])

    // const invalids = [{
    //     start: '12:00',
    //     end: '13:00',
    //     title: 'Lunch break',
    //     recurring: {
    //         repeat: 'weekly',
    //         weekDays: 'MO,TU,WE,TH,FR'
    //     }
    // }];

    const getTemplateDetails = (schID) => {
        getAllTemplatesBySchedule(schID)
            .then((response) => {
                console.log(response)
                let resTemplate = response.data.templateAppointment
                setPrecedingList({
                    scheduleID: resTemplate.numPrecedingScheduleID,
                    scheduleName: resTemplate.numPrecedingSchedule,
                    eventID: resTemplate.numPrecedingEventID,
                    eventName: resTemplate.numPrecedingEvent,
                })
            })
            .catch((err) => {
                console.log(err)
                //toast.error("Something went wrong!");
                setGroupEventsLoading(false)
            })
    }

    const timezones = React.useMemo(() => {
        return [
            {
                text: "America/Los Angeles",
                value: "America/Los_Angeles",
            },
            {
                text: "America/Chicago",
                value: "America/Chicago",
            },
            {
                text: "America/New York",
                value: "America/New_York",
            },
            {
                text: "UTC",
                value: "utc",
            },
            {
                text: "Europe/London",
                value: "Europe/London",
            },
            {
                text: "Europe/Berlin",
                value: "Europe/Berlin",
            },
            {
                text: "Europe/Bucharest",
                value: "Europe/Bucharest",
            },
            {
                text: "Asia/Shanghai",
                value: "Asia/Shanghai",
            },
            {
                text: "Asia/Tokyo",
                value: "Asia/Tokyo",
            },
        ]
    }, [])

    useEffect(() => {
        // getJson('https://trial.mobiscroll.com//workday-events/?vers=5', (events) => {
        //     setEvents(events);
        // }, 'jsonp');
        // getEventGroups()
        // getEmployees()

        fetchLocationData()
    }, [])

    useEffect(() => {
        // getJson('https://trial.mobiscroll.com//workday-events/?vers=5', (events) => {
        //     setEvents(events);
        // }, 'jsonp');
        // getEventGroups()
        // getEmployees()

        getAllGroups()
    }, [props?.locationID, props?.employeeID])

    const onChange = useCallback((ev) => {
        setTimezone(ev.value)
    }, [])

    const myHeader = () => {
        return (
            <>
                <div className={styles.mdTimezoneHeader}>
                    <p style={{ margin: 0, fontWeight: 600 }}>Select Timezone</p>
                    <Select data={timezones} inputStyle="box" touchUi={false} display="anchored" value={timezone} onChange={onChange} />
                </div>
            </>
        )
    }

    const renderDay = (args) => {
        const date = args.date
        return (
            <div className="header-template-container">
                <p style={{ fontWeight: 600, textTransform: "uppercase", fontSize: "18px" }}>{formatDate("DDD", date)}</p>
            </div>
        )
    }

    const getEventGroups = () => {
        getGroupEvents()
            .then((response) => {
                setGroupEvents(response.data.serviceGroup)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setGroupEventsLoading(false)
            })
    }

    // const [serviceGroups, setServiceGroups] = useState([])

    const getAllGroups = () => {
        setIsLoading(true)
        getAllGroupsList()
            .then((response) => {
                let events = [] as any

                response.data.serviceGroupServices.map((evt) => {
                    let startTming = formatDateTime(evt.dtDateTimeFrom)
                    let endTming = formatDateTime(evt.dtDateTimeTo)

                    let eventObject = {
                        start: startTming,
                        end: endTming,
                        title: evt.varSCHServiceGroupName,
                        color: evt.varSCHServiceGroupColour != null ? evt.varSCHServiceGroupColour.trim() : "gray",
                        id: evt.numSCHScheduleID,
                        misc: evt.serviceItems,
                    }

                    events.push(eventObject)
                })

                // setEvents(events)
                setIsLoading(false)

                // console.log('eventss', response.data.serviceGroupServices)
            })
            .catch((err) => {
                console.log(err)
                // toast.error("Something went wrong!");
                setGroupEventsLoading(false)
                setIsLoading(false)
            })
    }

    // const getEmployees = () => {
    //     getAllEmployees()
    //         .then((response) => {
    //             setEmployeesLiss(response.data.employee)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //             toast.error("Something went wrong!")
    //             setGroupEventsLoading(false)
    //         })
    // }

    const getAllTemplates = (templateID) => {
        getAllTemplatesBySchedule(templateID)
            .then((response) => {
                setTemplatesList(response.data.templateAppointment)
                console.log(response.data.templateAppointment)

                let events = [] as any

                response.data.templateAppointment.map((evt) => {
                    let startTming = formatDateTime(evt.dtApptStartTime)
                    let endTming = addMinutes(startTming, evt.numApptDuration)

                    let eventObject = {
                        start: startTming,
                        end: endTming,
                        title: evt.varEventGroup,
                        color: evt.varSchTemplateAppointmentColor != null ? evt.varSchTemplateAppointmentColor.trim() : "gray",
                        id: evt.numSchTemplateAppointmentID,
                    }

                    events.push(eventObject)
                })

                // setEvents(events)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setGroupEventsLoading(false)
            })
    }

    const getEvents = () => {
        getAllEvents()
            .then((response) => {
                setAllEvents(response.data.schEvent)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setGroupEventsLoading(false)
            })
    }

    const getMaxDuration = (eventsList) => {
        let maxDuration = 0

        eventsList.map((events) => {
            if (events.numSchEventDuration > maxDuration) {
                maxDuration = events.numSchEventDuration
            }
        })

        return maxDuration
    }

    const getAllEventsByGroup = (eventGroupID) => {
        getAllEventsByGroupID(eventGroupID)
            .then((response) => {
                console.log(response.data.eventGroup[0].event)
                let maxD = getMaxDuration(response.data.eventGroup[0].event)
                setMaxEventDuration(maxD)

                let endingTime = addMinutes(startTime, maxD)

                console.log("lasttime", endingTime)

                setEndTime(endingTime)
            })
            .catch((err) => {
                console.log(err)
                // toast.error("Something went wrong!");
                setGroupEventsLoading(false)
            })
    }

    const [title, setTitle] = React.useState("New event")
    const [color, setColor] = React.useState("teal")
    const [startTime, setStartTime] = React.useState("00:00")
    const [endTime, setEndTime] = React.useState("00:00")

    const [tempEvent, setTempEvent] = useState(null) as any
    const [isNewEvent, setIsNewEvent] = React.useState(false) as any
    const [isOpen, setOpen] = React.useState(false)
    const [anchor, setAnchor] = React.useState(null) as any

    const [scheduleSelectedColor, setScheduleSelectedColor] = useState("")

    // const onClose = useCallback(() => {
    //     if (isNewEvent) {
    //         setEvents(myEvents.filter((item) => item.id !== tempEvent.id))
    //     }
    //     setOpen(false)
    // }, [isNewEvent, myEvents, tempEvent])

    const showPopup = useCallback((args) => {
        const event = args.event
        // store temporary event
        setTempEvent(args.event)

        // set anchor for the popup
        setAnchor(args.target ? args.target : args.domEvent.target)
        setOpen(true)
    }, [])

    /* Event Actions */
    const onEventCreated = useCallback(
        (args) => {
            setIsNewEvent(true)
            showPopup(args)
        },
        [showPopup]
    )

    const [endingTime, setEndingTime] = useState("")

    const onEventCreate = useCallback(
        (args) => {
            let startingTime = args.event.start
            let endingTime = args.event.end

            setOriginalStartTime(startingTime)
            setOriginalEndTime(endingTime)

            // console.log('startttt', startingTime.substring(0, startingTime.length - 10))
            // console.log('endddd', endingTime.substring(0, endingTime.length - 10))

            setStartTime(startingTime.substring(0, startingTime.length - 10))
            setEndingTime(endingTime.substring(0, endingTime.length - 10))
        },
        [showPopup]
    )

    const onEventResize = useCallback(
        (args) => {
            setIsNewEvent(false)
            let dataOut = args.event
            console.log(dataOut)

            // let durationNew = getMinutesDuration(dataOut.start, dataOut.end)
            // console.log('dddif', durationNew)

            updateEventByAppointmentID(args.event.id, formatDateTimeScheduler(dataOut.start), formatDateTimeScheduler(dataOut.end))
                .then((response) => {
                    console.log(response)
                    // toast.success("Item Updated!");
                })
                .catch((err) => {
                    // toast.error("Item update failed!");
                })
        },
        [myEvents]
    )

    const onEventDeleted = useCallback(
        (args) => {
            // setEvents(myEvents.filter((item) => item.id !== args.event.id))

            deleteEventByAppointmentID(args.event.id)
                .then((response) => {
                    console.log(response)
                    // toast.success("Item deleted!")
                })
                .catch((err) => {
                    // toast.error("Item deletion failed!")
                })

            console.log(args.event)
        },
        [myEvents]
    )

    const onEventDoubleClick = useCallback(
        (args) => {
            setIsNewEvent(false)

            showPopup(args)
        },
        [showPopup]
    )

    const updateEventCard = (title, id) => {
        allEvents
            .filter((x) => x.numSchEventID === id)
            .map((selectedEvent) => {
                console.log(selectedEvent)
                setTitle(selectedEvent.varSchEventName)
                setColor(selectedEvent.varSchEventColor)

                let duration = selectedEvent.numSchEventDuration
                let endingTime = addMinutes(startTime, duration)

                console.log("lasttime", endingTime)

                setEndTime(endingTime)
            })
    }

    // const updateFilteredEvents = (eventGroupID) => {
    //     console.log(eventGroupID)
    //     groupEvents.filter(x => x.numEventGroupID === eventGroupID).map(allEvents => {
    //         console.log(allEvents)
    //         allEvents.event.map(events => {
    //             let updatedEvents = filteredEvents
    //             updatedEvents.push(events)
    //             setFilteredEvents(updatedEvents)
    //         })
    //     })
    // }

    useEffect(() => {
        let getTemplate = templateList.find((template) => template.TemplateID === selectedTemplate)
        setSelectedTemplateList({
            TemplateLocationID: getTemplate?.TemplateLocationID,
            TemplateEmployeeID: getTemplate?.TemplateEmployeeID,
            TemplateID: getTemplate?.TemplateID,
            selectedName: getTemplate?.TemplateName,
            timeInterval: getTemplate?.TemplateTimeInterval,
            varEmployeeFirstName: getTemplate?.varEmployeeFirstName,
            varEmployeeLastName: getTemplate?.varEmployeeLastName,
            varLocationName: getTemplate?.varLocationName,
            EmployeeType: getTemplate?.EmployeeType,
        })
    }, [selectedTemplate])

    const [calView, setCalView] = React.useState({
        calendar: { labels: true },
    })

    const [view, setView] = React.useState("month")

    const changeView = (event) => {
        let calView

        switch (event.target.value) {
            case "year":
                calView = {
                    calendar: {
                        type: "year",
                        timeCellStep: props?.timeInterval,
                        timeLabelStep: props?.timeInterval,
                    },
                    schedule: {
                        allDay: false,
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
            case "month":
                calView = {
                    calendar: {
                        type: "month",
                        labels: true,
                        timeCellStep: props?.timeInterval,
                        timeLabelStep: props?.timeInterval,
                    },
                    schedule: {
                        allDay: false,
                    },
                }
                break
            case "week":
                calView = {
                    schedule: {
                        allDay: false,
                        type: "week",
                        timeCellStep: props?.timeInterval,
                        timeLabelStep: props?.timeInterval,
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
            case "day":
                calView = {
                    schedule: {
                        allDay: false,
                        type: "day",
                        timeCellStep: props?.timeInterval,
                        timeLabelStep: props?.timeInterval,
                        startTime: "06:00",
                        endTime: "19:00",
                    },
                }
                break
        }

        setView(event.target.value)
        setCalView(calView)
    }

    // const renderScheduleEvent = React.useCallback<(data: MbscCalendarEventData) => any>((data: MbscCalendarEventData) => {
    //     console.log('dddd',data)
    //     const original = data.original!;
    //         return <div className="md-custom-event-cont" style={{ borderLeft: '5px solid ' + data.color, background: data.color }}>
    //             <div className="md-custom-event-wrapper">
    //                 <div style={{ background: data.color }} className="md-custom-event-category">{data.color}</div>
    //                 <div className="md-custom-event-details">
    //                     <div className="md-custom-event-title" style={{ color: '#fff' }}>{data.title}</div>
    //                     <div className="md-custom-event-time" style={{ color: '#fff' }}>{data.start} - {data.end}</div>
    //                     <Tooltip title={'asdsdfsdf'}><InfoOutlined style={{ fontSize: "20px", color: "gray" }} /></Tooltip>
    //                     {

    //                     }
    //                 </div>
    //             </div>
    //         </div>
    // }, []);

    const customWithNavButtons = () => {
        return (
            <React.Fragment>
                <CalendarNav className="cal-header-nav" />
                <div className="cal-header-picker">
                    <SegmentedGroup value={view} onChange={changeView}>
                        <SegmentedItem value="month">Month</SegmentedItem>
                        <SegmentedItem value="week">Week</SegmentedItem>
                        <SegmentedItem value="day">Day</SegmentedItem>
                    </SegmentedGroup>
                </div>
                <CalendarPrev className="cal-header-prev" />
                <CalendarToday className="cal-header-today" />
                <CalendarNext className="cal-header-next" />
            </React.Fragment>
        )
    }

    const doctors = [
        {
            id: 1,
            name: "Marc R",
            color: "#b33d3d",
        },
        {
            id: 2,
            name: "Sashimal R",
            color: "#309346",
        },
        // {
        //     id: 3,
        //     name: "Kabilan V",
        //     color: "#c77c0a",
        // },
    ]

    const invalids = [
        {
            start: "12:00",
            end: "13:00",
            title: "Lunch break",
            recurring: {
                repeat: "weekly",
                weekDays: "MO,TU,WE,TH,FR,SA,SU",
            },
            cssClass: "md-lunch-break-class mbsc-flex",
        },
    ]

    /* BOOKING CODE START */
    const [currentLocation, setCurrentLocation] = useState("")

    const [searchValue, setSearchValue] = useState("")

    const [newCustomer, setNewCustomer] = useState(false)

    const [createdCustomer, setCreatedCustomer] = useState({} as any)

    const [currentCustomer, setCurrentCustomer] = useState("")
    const [currentCustomerIndex, setCurrentCustomerIndex] = useState(0)
    const [addPetView, setAddPetView] = useState(false)
    const [addCustomerView, setCustomerView] = useState(false)

    const [searchData, setSearchData] = useState([] as any)

    const [MixBreedData, setMixBreedData] = useState([] as any)
    const [PureBreedData, setPureBreedData] = useState([] as any)
    const [sizeData, setSizeData] = useState([] as any)
    const [typeData, setTypeData] = useState([] as any)

    const [petList, setPetList] = useState([] as any)

    const [petLoading, setPetLoading] = useState(false)
    const [customerLoading, setCustomerLoading] = useState(false)
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false)

    const [petName, setPetName] = useState("")
    const [petType, setPetType] = useState("")
    const [petBreed, setPetBreed] = useState("")
    const [petWeight, setPetWeight] = useState("")
    const [petSize, setPetSize] = useState("")
    const [petGender, setPetGender] = useState("")
    const [petIsMix, setPetIsMix] = useState(false)

    const [cusFName, setCusFName] = useState("")
    const [cusLName, setCusLName] = useState("")
    const [cusMobile, setCusMobile] = useState("")
    const [cusHome, setCusHome] = useState("")
    const [cusEmail, setCusEmail] = useState("")
    const [cusZip, setCusZip] = useState("")
    const [cusCity, setCusCity] = useState("")
    const [cusState, setCusState] = useState("")

    const [seFName, setSeFName] = useState("")
    const [seLName, setSeLName] = useState("")
    const [sePetName, setSePetName] = useState("")
    const [seLocation, setSeLocation] = useState("")
    const [seContact, setSeContact] = useState("")
    const [allLocations, setAllLocations] = useState<any>([])
    const myRef = useRef<any>()

    const searchBoxRef = useRef<HTMLInputElement | null>(null)
    const addNewCustomerRef = useRef<HTMLInputElement | null>(null)
    const addNewPetRef = useRef<HTMLInputElement | null>(null)

    const [selectedPastAppointment, setSelectedPastAppointment] = useState("") as any
    const [pastAppointmentList, setPastAppointmentList] = useState([]) as any
    const [uniqueParam] = useState(new Date().getTime())

    const [petPer, setPetPer] = useState<any>([])
    const [isOpenPetAlertsModal, setIsOpenPetAlertsModal] = useState(false)

    const [isOpenAlertsModal, setIsAlertsModal] = useState(false)

    useEffect(() => {
        if (addCustomerView === true) {
            if (addNewCustomerRef.current) {
                addNewCustomerRef.current.focus()
            }
        } else {
            if (searchBoxRef.current) {
                searchBoxRef.current.focus()
            }
        }
    }, [addCustomerView])

    async function getSizeByWeight(weight, type) {
        let num = parseInt(weight.replace(/\D/g, ""), 10)
        console.log("payy", num, type)
        const result = await axiosPrivate.get(`PetSize/GetPetSizeByWeightAndPetTypeID?Weight=${num}&PetTypeID=${type}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    useEffect(() => {
        console.log("petWeight", petWeight)
        if (petWeight === "__lbs" || petWeight === "") {
        } else {
            getSizeByWeight(petWeight, petType)
                .then((res) => {
                    console.log("sizeeee", res)
                    setPetSize(res?.[0]?.numPetSizeID)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        }
    }, [petWeight])

    useEffect(() => {
        if (addPetView === true) {
            if (addNewPetRef.current) {
                addNewPetRef.current.focus()
            }
        }
    }, [addPetView])
    /* START BREED CODE */

    const primaryNameRef = useRef()
    const secondaryNameRef = useRef()
    const BreedRef = useRef()

    const [noBreedData, setNoBreedData] = useState(false)

    const [primaryName, setPrimaryName] = useState("")
    const [primaryNameDesc, setPrimaryNameDesc] = useState("")
    const [secondName, setSecondName] = useState("")

    const [primaryBreed, setPrimaryBreed] = useState("")
    const [secondaryBreed, setSecondaryBreed] = useState("")

    const [primaryBreedNameX, setPrimaryBreedNameX] = useState("")
    const [secondaryBreedNameX, setSecondaryBreedNameX] = useState("")

    const [newMixId, setNewMixId] = useState(0)

    const [fromDate, setFromDate] = useState(0)
    const [toDate, setToDate] = useState(0)

    const [breedMainData, setBreedMainData] = useState([] as any)

    const [newMissingBreed, setNewMissingBreed] = useState({
        bitActive: true,
        numCreatedBy: 0,
        varCreatedBy: "",
        dtCreatedDate: "2022-10-26T10:41:38.949Z",
        numEditedBy: 0,
        varEditedBy: "",
        dtEditedDate: "2022-10-26T10:41:38.949Z",
        numDeletedBy: 0,
        varDeletedBy: "",
        dtDeletedDate: "2022-10-26T10:41:38.949Z",
        numPetBreedID: 0,
        varPetBreedType: "MIX",
        varPetBreedName: "",
        varPetBreedDescription: "",
        numPrimaryPetBreedID: 0,
        varPrimaryPetBreed: "",
        numSecondaryPetBreedID: 0,
        varSecondaryPetBreed: "",
        numPetTypeID: 0,
        varPetTypeName: "string",
    })

    const [petDescription, setPetDescription] = useState("")

    const selectPetDescription = () => {
        if (!petIsMix) {
            PureBreedData.some((breed) => {
                if (breed.numPetBreedID === parseInt(petBreed)) {
                    setPetDescription(breed.varPetBreedDescription)
                }
            })
        } else {
            MixBreedData.some((breed) => {
                if (breed.numPetBreedID === parseInt(petBreed)) {
                    setPetDescription(breed.varPetBreedDescription)
                    setPrimaryBreed(breed.numPrimaryPetBreedID)
                    setSecondaryBreed(breed.numSecondaryPetBreedID)
                }
            })
        }
    }

    useEffect(() => {
        selectPetDescription()
    }, [MixBreedData])

    useEffect(() => {
        selectPetDescription()
    }, [petBreed])

    async function getBreedsMainList() {
        const result = await axiosPrivate.get("Breed/GetAll", {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    async function getPetBreedByMix() {
        console.log("pet ids", primaryBreed, secondaryBreed)
        const result = await axiosPrivate.get(`PetBreed/GetByID/${primaryBreed}/${secondaryBreed}`)
        return result
    }

    useEffect(() => {
        getPetBreedByMix()
            .then((res) => {
                console.log(res.data, "debug")

                if (res.data.petBreed === null) {
                    setNewMissingBreed({
                        ...newMissingBreed,
                        varPrimaryPetBreed: primaryName,
                        numPrimaryPetBreedID: parseInt(primaryBreed),
                        numSecondaryPetBreedID: parseInt(secondaryBreed),
                        numPetTypeID: parseInt(petType),
                        varPetBreedName: primaryName,
                        varSecondaryPetBreed: secondName,
                        varPetBreedDescription: primaryName,
                    })

                    setPrimaryName(primaryBreedNameX + " + " + secondaryBreedNameX)
                    setPrimaryNameDesc(res?.data?.petBreed?.varPetBreedDescription)

                    setNoBreedData(true)
                } else {
                    setPrimaryName(res?.data?.petBreed?.varPetBreedName)
                    setPrimaryNameDesc(res?.data?.petBreed?.varPetBreedDescription)
                    setPetBreed(res?.data?.petBreed?.numPetBreedID)
                    setNoBreedData(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [primaryBreed, secondaryBreed])

    const getBreedMainData = () => {
        getBreedsMainList()
            .then((res) => {
                let allBreeds = [] as any

                res?.breed.map((breed) => {
                    let objPure = {
                        numBreedID: breed?.numBreedID,
                        varBreedName: breed?.varBreedName,
                    }

                    allBreeds.push(objPure)
                })

                setBreedMainData(allBreeds)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    /* END BREED CODE */

    const removePetFromList = (petID) => {
        const list: any = [...petList]
        console.log("asasas", petID)

        list.some((petA, index) => {
            if (petA.id === petID) {
                list.splice(index, 1)
                return index
            }
        })

        setPetList(list)
    }

    async function getData(searchQuery) {
        const result = await axiosPrivate.post(
            "Customer/Search",
            JSON.stringify({
                page: 0,
                pageSize: 0,
                searchText: searchQuery,
            }),
            {
                headers: { "Content-Type": "application/json" },
            }
        )
        return result.data
    }

    async function getTypes() {
        const result = await axiosPrivate.get("PetType/GetAll", {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    async function getAppointments(locationID, employeeID, fromDate, toDate) {
        const result = await axiosPrivate.get(`Appointment/GetBookingsDetailsForCalendar?FromDate=${fromDate}&ToDate=${toDate}&LocationID=${locationID}&EmployeeID=${employeeID}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    async function getBreeds() {
        const result = await axiosPrivate.get(`PetBreed/GetAllByPetTypeID/${petType}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    async function getSizes(id) {
        const result = await axiosPrivate.get(`PetSize/GetAllByPetTypeID/${id}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }

    async function getLocations() {
        const result = await axiosPrivate.get(`Location/GetAll`, {})
        return result.data.location
    }

    const fetchData = (searchQuery) => {
        setIsLoading(true)

        getData(searchQuery)
            .then((res) => {
                setSearchData(res.customer)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)

                toast.error("Something went wrong!")
                setSearchData([])
            })
    }

    async function getStateCity(zipCode) {
        const result = await axiosPrivate.get(`ZipCode/GetCityStateByZipCode/${zipCode}`)
        return result
    }

    const handleZipSearch = (value, index) => {
        getStateCity(value)
            .then((responseCustomer) => {
                const resultZip = responseCustomer?.data

                setCusCity(resultZip?.varCity)
                setCusState(resultZip?.varState)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const cancelForm = () => {
        setCusState("")
        setCusCity("")
        setCusZip("")
        setCusMobile("")
        setCusHome("")
        setCusEmail("")
        setCusLName("")
        setCusFName("")

        setCustomerView(false)
    }

    const debouceZipSearch = useMemo(() => {
        return debouce(handleZipSearch, 500)
    }, [])

    const getTypesData = () => {
        getTypes()
            .then((res) => {
                setTypeData(res?.petType)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const getSizeData = (id) => {
        getSizes(id)
            .then((res) => {
                setSizeData(res?.petSize)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    useEffect(() => {
        async function getData() {
            const result = await axiosPrivate.post(
                "Pet",
                JSON.stringify({
                    pet: {
                        numPetID: 0,
                        varPetNumber: "",
                        varPetBC: "",
                        varPetRFID: "",
                        numCustomerID: currentCustomer,
                        numPetDefaultGroomLocationID: 0,
                        numPetDefaultBoardLocationID: 0,
                        numPetDefaultDaycareLocationID: 0,
                        numPetDefaultSittingLocationID: 0,
                        numPetDefaultTrainingLocationID: 0,
                        numPetPreferredGroomerID: 0,
                        numPetPreferredBatherID: 0,
                        varPetName: petName,
                        varPetDescription: "",
                        numPetTypeID: parseInt(petType),
                        numPetBreedID: newMixId,
                        numPetColorID: 0,
                        bitIsFilledByCatalog: true,
                        numPetCatalogID: 0,
                        numPetWeight: parseInt(petWeight),
                        numPetSizeID: parseInt(petSize),
                        varPetSex: petGender,
                        varPetBirthDate: "",
                        varPetAge: "",
                        varPetSpay: "",
                        varPetImagePath: "",
                        varPetLicenseNo: "",
                        varPetChipID: "",
                        varPetChipMaker: "",
                        varPetWarningAlert: "",
                        varPetWarningAlertGroom: "",
                        varPetWarningAlertBoard: "",
                        varPetWarningAlertDaycare: "",
                        varPetWarningAlertSitting: "",
                        varPetWarningAlertTraining: "",
                        varPetWarningAlertDriver: "",
                        numActiveLocationID: 0,
                        varPetLocationStatus: "",
                        dtPetLocationLastScanDateTime: "2022-09-15T13:17:07.693Z",
                        numPetVetID: 0,
                        varPetComments: "",
                        dtPurgeDate: "2022-09-15T13:17:07.693Z",
                        varTemperamentTestStatus: "",
                        varTemperamentTestedLocation: "",
                        dtTemperamentTestedDate: "2022-09-15T13:17:07.693Z",
                        bitDaycareApproved: true,
                        dtDayCareApprovedDate: "2022-09-15T13:17:07.693Z",
                        numDayCareApprovedBy: 0,
                        varPetCustomText1: "",
                        varPetCustomText2: "",
                        varPetCustomText3: "",
                        varPetCustomText4: "",
                        varPetCustomText5: "",
                        dtPetCustomDate1: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate2: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate3: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate4: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate5: "2022-09-15T13:17:07.693Z",
                        numPetCustomNumber1: 0,
                        numPetCustomNumber2: 0,
                        numPetCustomNumber3: 0,
                        numPetCustomNumber4: 0,
                        numPetCustomNumber5: 0,
                        numPetCustomFloatNumber1: 0,
                        numPetCustomFloatNumber2: 0,
                        numPetCustomFloatNumber3: 0,
                        numPetCustomFloatNumber4: 0,
                        numPetCustomFloatNumber5: 0,
                        numPetCustomList1ID: 0,
                        numPetCustomList2ID: 0,
                        numPetCustomList3ID: 0,
                        numPetCustomList4ID: 0,
                        numPetCustomList5ID: 0,
                        numPetCustomList6ID: 0,
                        numPetCustomList7ID: 0,
                        numPetCustomList8ID: 0,
                        numPetCustomList9ID: 0,
                        numPetCustomList10ID: 0,
                        bitPetCustomBit1: true,
                        bitPetCustomBit2: true,
                        bitPetCustomBit3: true,
                        bitPetCustomBit4: true,
                        bitPetCustomBit5: true,
                        bitActive: true,
                        numCreatedBy: 0,
                        varCreatedBy: "",
                        dtCreatedDate: "2022-09-15T13:17:07.693Z",
                        numEditedBy: 0,
                        varEditedBy: "",
                        dtEditedDate: "2022-09-15T13:17:07.693Z",
                        numDeletedBy: 0,
                        varDeletedBy: "",
                        dtDeletedDate: "2022-09-15T13:17:07.693Z",
                        varPetDeletedReason: "",
                    },
                })
            )
            return result.data
        }

        if (newMixId != 0) {
            //setIsLoading(true)
            getData()
                .then((res) => {
                    toast.success("Pet saved successfully!")

                    getNewCustomer(currentCustomer)
                        .then((res) => {
                            console.log(res.data.customer)
                            setCreatedCustomer(res.data.customer)
                            setIsLoading(false)
                            setPetName("")
                            setPetType("")
                            setPetBreed("")
                            setPetWeight("")
                            setPetSize("")
                            setPetGender("")
                            setPetIsMix(false)

                            setAddPetView(false)
                        })
                        .catch((err) => {
                            console.log("cannot get customer", err)
                        })

                    setPetLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setPetLoading(false)

                    toast.error("Something went wrong when saving new pet!")
                })
        }
    }, [newMixId])

    const getBreedData = () => {
        getBreeds()
            .then((res) => {
                console.log("res", res)
                let pureBreeds = [] as any
                let mixBreeds = [] as any

                res?.petBreed.map((breed) => {
                    let objPure = {
                        numPetBreedID: breed?.numPetBreedID,
                        varPetBreedType: breed?.varPetBreedType,
                        varPetBreedName: breed?.varPetBreedName,
                        varPetBreedDescription: breed?.varPetBreedDescription,
                        numPrimaryPetBreedID: breed?.numPrimaryPetBreedID,
                        varPrimaryPetBreed: breed?.varPrimaryPetBreed,
                        numSecondaryPetBreedID: breed?.numSecondaryPetBreedID,
                        varSecondaryPetBreed: breed?.varSecondaryPetBreed,
                        bitActive: breed?.bitActive,
                    }

                    if (breed.varPetBreedType === "PURE") {
                        pureBreeds.push(objPure)
                    } else {
                        mixBreeds.push(objPure)
                    }
                })

                setPureBreedData(pureBreeds)
                setMixBreedData(mixBreeds)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const validateString = (input) => {
        return input === "" ? null : input
    }

    async function getAdvancedData() {
        setIsLoading(true)

        const result = await axiosPrivate.post(
            "Customer/AdvanceSearch",
            JSON.stringify({
                varLocation: validateString(seLocation),
                varFirstName: validateString(seFName),
                varLastName: validateString(seLName),
                varCity: null,
                varState: null,
                varZip: null,
                bitActiveStatus: null,
                bitPriorityVIP: null,
                varPetName: validateString(sePetName),
                numPetTypeID: null,
                varPetBreed: null,
                varContactNumber: validateString(seContact) == null ? null : removeFormatPhone(validateString(seContact)),
            }),
            {
                headers: { "Content-Type": "application/json" },
            }
        )

        return result.data
    }

    async function getUpdatedHeaders(objectName) {
        const result = await axiosPrivate.get(`Label/${objectName}`)
        return result
    }

    const [customerLabels, setCustomerLabels] = useState({})
    const [petLabels, setPetLabels] = useState({})
    const [customerReady, setCustomerReady] = useState(false)

    useEffect(() => {
        setCurrentLocation("1")

        getUpdatedHeaders("Customer")
            .then((responseCustomer) => {
                // console.log(responseCustomer?.data?.standard)
                setCustomerLabels(responseCustomer?.data?.standard)

                getUpdatedHeaders("Pet")
                    .then((responsePet) => {
                        // console.log(responsePet?.data?.standard)
                        setPetLabels(responsePet?.data?.standard)
                        setCustomerReady(true)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
            .catch((err) => {
                console.log(err)
            })

        getBreedMainData()

        getLocations()
            .then((res) => {
                setAllLocations(res)
            })
            .catch((err) => {
                if (err.message != "canceled") {
                    console.log(err)
                }
            })

        if (searchBoxRef.current) {
            searchBoxRef.current.focus()
        }
    }, [])

    useEffect(() => {
        console.log("curLo", currentLocation)
    }, [currentLocation])

    useEffect(() => {
        setPetType("1")
        getSizeData("1")
    }, [typeData])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (seFName != "" || seLName != "" || seLocation != "" || sePetName != "" || seContact != "") {
                getAdvancedData()
                    .then((res) => {
                        console.log("adv", res)
                        setSearchData([])
                        setSearchData(res.customer)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                        setSearchData([])
                        toast.error("Something went wrong!")
                    })
            }
        }, 900)
        return () => clearTimeout(delayDebounceFn)
    }, [seFName, seLName, seLocation, sePetName, seContact])

    const handleSearch = (value) => {
        setSearchValue(value)
    }

    const goBack = () => {
        setCurrentCustomer("")
    }

    useEffect(() => {
        if (searchValue != "") {
            fetchData(searchValue)
        } else {
            setSearchData([])
        }
    }, [searchValue])

    const debouceSearch = useMemo(() => {
        return debouce(handleSearch, 500)
    }, [])

    const selectCustomer = (id, index) => {
        setCurrentCustomer(id)
        setCurrentCustomerIndex(index)
        console.log(id)
    }

    async function getNewCustomer(customerID) {
        const result = await axiosPrivate.get(`Customer/GetByID/${customerID}`)
        return result
    }

    useEffect(() => {
        // setIsLoading(true)
        if (currentCustomer != "") {
            console.log("current customer", currentCustomer)

            getNewCustomer(currentCustomer)
                .then((res) => {
                    console.log(res.data.customer)
                    setCreatedCustomer(res.data.customer)
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log("cannot get customer", err)
                })
        } else {
            setPetList([])
        }
    }, [currentCustomer])

    const addNewCustomer = () => {
        setCustomerLoading(true)

        async function getData() {
            const result = await axiosPrivate.post(
                "Customer",
                JSON.stringify({
                    customer: {
                        bitActive: true,
                        numCreatedBy: 0,
                        varCreatedBy: "",
                        dtCreatedDate: "2022-08-18T11:23:17.463Z",
                        numEditedBy: 0,
                        varEditedBy: "",
                        dtEditedDate: "2022-08-18T11:23:17.463Z",
                        numDeletedBy: 0,
                        varDeletedBy: "",
                        dtDeletedDate: "2022-08-18T11:23:17.463Z",
                        numCustomerID: 0,
                        varAltCustomerID: "",
                        numCustomerDefaultLocationID: currentLocation,
                        numCustomerLastLocationID: 0,
                        varCustomerBC: "",
                        varCustomerFirstName: cusFName,
                        varCustomerLastName: cusLName,
                        varCustomerSalutation: "",
                        bitPushNotificationEnabled: true,
                        varCustomerEmail: cusEmail,
                        bitCustomerNotificationEmail: true,
                        varCustomerHomePhone: cusHome,
                        bitCustomerNotificationHomePhone: true,
                        varCustomerMobilePhone: cusMobile,
                        bitCustomerNotificationMobilePhone: true,
                        varCustomerWorkPhone: "",
                        bitCustomerNotificationWorkPhone: true,
                        varCustomerStatus: "",
                        varCustomerComments: null,
                        varCustomerWarningAlert: "",
                        varCustomerFinancialAlert: "",
                        numCustomerPreferredGroomerID: 0,
                        varCustomerUsername: "",
                        varCustomerPassword: "",
                        varCustomerUsernameResetQ1: "",
                        varCustomerUsernameResetA1: "",
                        varCustomerUsernameResetQ2: "",
                        varCustomerUsernameResetA2: "",
                        varCustomerUsernameResetQ3: "",
                        varCustomerUsernameResetA3: "",
                        varEmergContactFirstName: "",
                        varEmergContactLastName: "",
                        varEmergContactType: "",
                        varEmergContactNumber: "",
                        bitIsVip: false,
                        bitCustomerAlertNoShow: false,
                        bitCustomerAlertOutstandingBill: false,
                        bitCustomerAlertDiscountOnFile: false,
                        bitCustomerAlertAlwaysLate: false,
                        bitCustomerAlertVerbalConfirmationNeeded: false,
                        bitCustomerAlertDifficultToHandle: false,
                        varCustomerImagePath: "",
                        varCustomerCustomText1: "",
                        varCustomerCustomText2: "",
                        dtCustomerCustomDate1: "",
                        dtCustomerCustomDate2: "",
                        numCustomerCustomerList1ID: 0,
                        numCustomerCustomerList2ID: 0,
                        bitCustomerCustomBit1: false,
                        bitCustomerCustomBit2: false,
                    },
                    customerAddress: [
                        {
                            bitActive: true,
                            numCustomerAddressID: 0,
                            numAddressTypeID: 1,
                            varCustomerAddress1: "",
                            varCustomerAddress2: "",
                            varCustomerCity: cusCity,
                            varCustomerState: cusState,
                            varCustomerZip: cusZip,
                            varCustomerAddressNotes: "",
                            bitIsPrimaryDefault: true,
                            bitIsPrimary: true,
                            bitIsBillingDefault: true,
                            bitIsBilling: true,
                        },
                    ],
                })
            )
            return result.data
        }

        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            getData()
                .then((res) => {
                    console.log(res)

                    toast.success("Customer saved successfully!")

                    /* Reset Input Values*/
                    setCusState("")
                    setCusCity("")
                    setCusZip("")
                    setCusMobile("")
                    setCusHome("")
                    setCusEmail("")
                    setCusLName("")
                    setCusFName("")

                    setCustomerLoading(false)
                    setCustomerView(false)

                    setCurrentCustomer(res.numResponseID)
                })
                .catch((err) => {
                    console.log(err)
                    setCustomerLoading(false)

                    toast.error("Something went wrong when saving new customer!")
                })
        }
    }

    const resetFilters = () => {
        setSeContact("")
        setSeFName("")
        setSeLName("")
        setSeLocation("")
        setSePetName("")
        setSearchData([])
        myRef.current?.handleClear()
    }

    const createPet = () => {
        setPetLoading(true)

        async function saveMixBreed(data) {
            await axiosPrivate
                .post(`PetBreed`, JSON.stringify(data), {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        setNewMixId(result.data.numResponseID)
                        console.log("petssssssssssss", data)

                        toast.success(`New Mixed Breed successfully saved!`)
                        console.log("result saved", result)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
        }

        async function getData() {
            const result = await axiosPrivate.post(
                "Pet",
                JSON.stringify({
                    pet: {
                        numPetID: 0,
                        varPetNumber: "",
                        varPetBC: "",
                        varPetRFID: "",
                        numCustomerID: currentCustomer,
                        numPetDefaultGroomLocationID: 0,
                        numPetDefaultBoardLocationID: 0,
                        numPetDefaultDaycareLocationID: 0,
                        numPetDefaultSittingLocationID: 0,
                        numPetDefaultTrainingLocationID: 0,
                        numPetPreferredGroomerID: null,
                        numPetPreferredBatherID: null,
                        varPetName: petName,
                        varPetDescription: "",
                        numPetTypeID: parseInt(petType),
                        numPetBreedID: parseInt(petBreed),
                        numPetColorID: 0,
                        bitIsFilledByCatalog: true,
                        numPetCatalogID: 0,
                        numPetWeight: parseInt(petWeight),
                        numPetSizeID: parseInt(petSize),
                        varPetSex: petGender,
                        varPetBirthDate: "",
                        varPetAge: "",
                        varPetSpay: "",
                        varPetImagePath: "",
                        varPetLicenseNo: "",
                        varPetChipID: "",
                        varPetChipMaker: "",
                        varPetWarningAlert: "",
                        varPetWarningAlertGroom: "",
                        varPetWarningAlertBoard: "",
                        varPetWarningAlertDaycare: "",
                        varPetWarningAlertSitting: "",
                        varPetWarningAlertTraining: "",
                        varPetWarningAlertDriver: "",
                        numActiveLocationID: 0,
                        varPetLocationStatus: "",
                        dtPetLocationLastScanDateTime: "2022-09-15T13:17:07.693Z",
                        numPetVetID: 0,
                        varPetComments: "",
                        dtPurgeDate: "2022-09-15T13:17:07.693Z",
                        varTemperamentTestStatus: "",
                        varTemperamentTestedLocation: "",
                        dtTemperamentTestedDate: "2022-09-15T13:17:07.693Z",
                        bitDaycareApproved: true,
                        dtDayCareApprovedDate: "2022-09-15T13:17:07.693Z",
                        numDayCareApprovedBy: 0,
                        varPetCustomText1: "",
                        varPetCustomText2: "",
                        varPetCustomText3: "",
                        varPetCustomText4: "",
                        varPetCustomText5: "",
                        dtPetCustomDate1: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate2: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate3: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate4: "2022-09-15T13:17:07.693Z",
                        dtPetCustomDate5: "2022-09-15T13:17:07.693Z",
                        numPetCustomNumber1: 0,
                        numPetCustomNumber2: 0,
                        numPetCustomNumber3: 0,
                        numPetCustomNumber4: 0,
                        numPetCustomNumber5: 0,
                        numPetCustomFloatNumber1: 0,
                        numPetCustomFloatNumber2: 0,
                        numPetCustomFloatNumber3: 0,
                        numPetCustomFloatNumber4: 0,
                        numPetCustomFloatNumber5: 0,
                        numPetCustomList1ID: 0,
                        numPetCustomList2ID: 0,
                        numPetCustomList3ID: 0,
                        numPetCustomList4ID: 0,
                        numPetCustomList5ID: 0,
                        numPetCustomList6ID: 0,
                        numPetCustomList7ID: 0,
                        numPetCustomList8ID: 0,
                        numPetCustomList9ID: 0,
                        numPetCustomList10ID: 0,
                        bitPetCustomBit1: true,
                        bitPetCustomBit2: true,
                        bitPetCustomBit3: true,
                        bitPetCustomBit4: true,
                        bitPetCustomBit5: true,
                        bitActive: true,
                        numCreatedBy: 0,
                        varCreatedBy: "",
                        dtCreatedDate: "2022-09-15T13:17:07.693Z",
                        numEditedBy: 0,
                        varEditedBy: "",
                        dtEditedDate: "2022-09-15T13:17:07.693Z",
                        numDeletedBy: 0,
                        varDeletedBy: "",
                        dtDeletedDate: "2022-09-15T13:17:07.693Z",
                        varPetDeletedReason: "",
                    },
                })
            )
            return result.data
        }

        var FormPet: any = document.getElementById("editFormPet")

        if (FormPet.checkValidity() === false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            if (noBreedData) {
                let objectBreed = {
                    petBreed: newMissingBreed,
                }

                saveMixBreed(objectBreed)
            } else {
                //setIsLoading(true)
                getData()
                    .then((res) => {
                        toast.success("Pet saved successfully!")

                        getNewCustomer(currentCustomer)
                            .then((res) => {
                                console.log(res.data.customer)
                                setCreatedCustomer(res.data.customer)
                                setIsLoading(false)
                                setPetName("")
                                setPetType("")
                                setPetBreed("")
                                setPetWeight("")
                                setPetSize("")
                                setPetGender("")
                                setPetIsMix(false)

                                setAddPetView(false)
                            })
                            .catch((err) => {
                                console.log("cannot get customer", err)
                            })

                        setPetLoading(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setPetLoading(false)

                        toast.error("Something went wrong when saving new pet!")
                    })
            }
        }
    }

    useEffect(() => {
        if (addPetView) {
            getTypesData()
        }
    }, [addPetView])

    const searchWithFilters = () => {
        console.log("filters", selectedSlotInterval, employees, selectedLocation)
        console.log("auth", auth)

        // let subList = [] as any

        // let tempAppStateID = null

        // if (loadUserState?.length > 0) {
        //     tempAppStateID = loadUserState[0]?.AppStateID
        // } else {
        //     tempAppStateID = null
        // }

        // employees?.map((empp) => {
        //     subList.push({
        //         appStateID: tempAppStateID,
        //         employeeID: empp?.id,
        //     })
        // })

        // let payload = {
        //     appStateID: tempAppStateID,
        //     originalLocationID: auth?.currentLocation?.value,
        //     employeeID: auth?.employee?.numEmployeeID,
        //     selectedLocationID: selectedLocation,
        //     slotIntervalID: selectedSlotInterval,
        //     numUserID: auth?.employee?.numEmployeeID,
        //     stateSubListData: subList,
        // }
        // console.log("payyload", payload)

        // saveCurrentFilterState(payload)
        //     .then((response) => {
        //         console.log("saveCurrentFilterState", response)
        //     })
        //     .catch((err) => {
        //         toast.error("New state failed!")
        //     })
    }

    useEffect(() => {
        console.log("change")
        if (petType != "") getBreedData()
    }, [petType])

    useEffect(() => {
        console.log(addCustomerView)
    }, [addCustomerView])

    const handleInput = (event) => {
        // Remove numbers and special characters from the input value
        const newValue = event.target.value.replace(/[^A-Za-z]/g, "")
        // Capitalize the value and restrict to 2 characters
        const capitalizedValue = newValue.slice(0, 2).toUpperCase()
        event.target.value = capitalizedValue
    }

    const handleCityInput = (event) => {
        // Remove numbers and special characters from the input value
        const newValue = event.target.value.replace(/[^A-Za-z]/g, "")
        event.target.value = newValue
    }

    function checkCustomerAlerts(createdCustomer) {
        const data = createdCustomer
        const {
            bitCustomerAlertAlwaysLate,
            bitCustomerAlertDifficultToHandle,
            bitCustomerAlertDiscountOnFile,
            bitCustomerAlertNoShow,
            bitCustomerAlertOutstandingBill,
            bitCustomerAlertVerbalConfirmationNeeded,
            varCustomerComments,
        } = data

        const anyAlertsTrue =
            bitCustomerAlertAlwaysLate ||
            bitCustomerAlertDifficultToHandle ||
            bitCustomerAlertDiscountOnFile ||
            bitCustomerAlertNoShow ||
            bitCustomerAlertOutstandingBill ||
            bitCustomerAlertVerbalConfirmationNeeded

        const commentsNotNull = varCustomerComments !== null

        // Simplified condition logic
        if (anyAlertsTrue || commentsNotNull) {
            return true
        }

        return false
    }

    const showAlert = checkCustomerAlerts(createdCustomer)

    const showPetAlert = (per) => {
        if (per.length > 0) {
            setPetPer(per)
            setIsOpenPetAlertsModal(true)
        }
    }

    /* BOOKING CODE END */

    return isLoading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
            <CircularProgress />
        </div>
    ) : (
        <div style={{ height: "700px" }}>
            <Popup
                display="anchored"
                contentPadding={false}
                touchUi={false}
                buttons={[
                    "cancel",
                    {
                        text: "OK",
                        keyCode: "enter",
                        handler: () => {
                            tempEvent.title = title
                            tempEvent.color = color
                            tempEvent.start = originalStartTime
                            tempEvent.end = originalEndTime

                            if (isNewEvent) {
                                const payload = {
                                    serviceSchedule: {
                                        bitActive: true,
                                        numApptDuration: maxEventDuration,
                                        numSCHScheduleID: 0,
                                        numEmployeeID: props?.employeeID,
                                        numLocationID: props?.locationID,
                                        numSCHServiceGroupID: eventGroupChanged,
                                        numAppointmentID: null,
                                        dtDateTimeFrom: startTime,
                                        dtDateTimeTo: endingTime,
                                    },
                                }

                                addNewEventGroup(payload)
                                    .then((response) => {
                                        console.log(response)

                                        if (response.data.bitSuccess) {
                                            tempEvent.id = response.data.numResponseID
                                        }
                                        // setEvents([...myEvents, tempEvent])

                                        // toast.success("New entry added!")
                                        setColor("teal")
                                        setMaxEventDuration("")
                                    })
                                    .catch((err) => {
                                        // toast.error("New entry failed!")
                                    })
                            } else {
                                // setEvents([...myEvents])
                            }

                            // update event with the new properties on OK button click
                            setIsNewEvent(false)

                            setOpen(false)
                        },
                        cssClass: "mbsc-popup-button-primary",
                    },
                ]}
                // onClose={onClose}
                isOpen={isOpen}
                anchor={anchor}
            >
                <div style={{ padding: "0 0 1rem 1rem" }}>
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", borderBottom: "1px solid #fff", marginRight: "20px", marginBottom: ".5rem", flexDirection: "column" }}>
                            <div>
                                {groupEvents !== undefined && groupEvents !== null ? (
                                    <Select
                                        onChange={(e) => {
                                            setEventGroupChanged(e.value)
                                            setTitle(e.valueText)
                                        }}
                                        filter={true}
                                        data={groupEvents.map((i) => ({ text: i.varSCHServiceGroupName, value: i.numSCHServiceGroupID }))}
                                        label="Pick Service Group"
                                    />
                                ) : null}
                            </div>
                        </div>

                        {/* <div>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 2fr)' }}>
                                <div className={styles.eventContainer}>
                                    <p style={{ color: '#fff', fontSize: '15px', fontWeight: '600' }}>Preceding Events</p>
                                    {
                                        (groupEvents !== undefined && groupEvents !== null) ?
                                            <Select
                                                onChange={(e) => setEmployeePrecedingChanged(e.value)}
                                                filter={true}
                                                data={employees.map((i) => ({ text: i.varEmployeeFirstName + ' ' + i.varEmployeeLastName, value: i.numEmployeeID }))}
                                                label="Select Employee"
                                            />
                                            : null
                                    }

                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '.5rem' }}>
                                            <p style={{ color: '#fff', fontSize: '.875em', fontWeight: '600', margin: '0' }}>Schedule Name</p>
                                            <p style={{ color: '#fdea00', fontSize: '.875em', margin: '0' }}>{precedingList?.scheduleName}</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <p style={{ color: '#fff', fontSize: '.875em', fontWeight: '600', margin: '0' }}>Event Name</p>
                                            <p style={{ color: '#fdea00', fontSize: '.875em', margin: '0' }}>{precedingList?.eventName}</p>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ color: '#fff', fontWeight: '600' }}>Assign Preceding Event</p>
                                        <Switch
                                            color='warning'
                                            onChange={(e) => {

                                            }}
                                            checked={true}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>

                                </div>

                                <div className={styles.eventContainer}>
                                    <p style={{ color: '#fff', fontSize: '15px', fontWeight: '600' }}>Succeeding Events</p>
                                    {
                                        (groupEvents !== undefined && groupEvents !== null) ?
                                            <Select
                                                onChange={(e) => setEmployeePrecedingChanged(e.value)}
                                                filter={true}
                                                data={employees.map((i) => ({ text: i.varEmployeeFirstName + ' ' + i.varEmployeeLastName, value: i.numEmployeeID }))}
                                                label="Select Employee"
                                            />
                                            : null
                                    }

                                    {
                                        (groupEvents !== undefined && groupEvents !== null) ?
                                            <Select
                                                onChange={(e) => setEventGroupChanged(e.value)}
                                                filter={true}
                                                data={groupEvents.map((i) => ({ text: i.varSchEventGroupName, value: i.numEventGroupID }))}
                                                label="Select Template"
                                            />
                                            : null
                                    }

                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '.5rem' }}>
                                            <p style={{ color: '#fff', fontSize: '.875em', fontWeight: '600', margin: '0' }}>Schedule Name</p>
                                            <p style={{ color: '#fdea00', fontSize: '.875em', margin: '0' }}>sadasdas</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <p style={{ color: '#fff', fontSize: '.875em', fontWeight: '600', margin: '0' }}>Event Name</p>
                                            <p style={{ color: '#fdea00', fontSize: '.875em', margin: '0' }}>Kasdasdashdis</p>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ color: '#fff', fontWeight: '600' }}>Assign Succeeding Event</p>
                                        <Switch
                                            color='warning'
                                            onChange={(e) => {

                                            }}
                                            checked={true}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>

                                </div>
                            </div>


                        </div> */}
                    </div>

                    <div>
                        {/* {
                            (allEventsByGroup !== undefined && allEventsByGroup !== null) ?
                                <Select
                                    onChange={(e) => updateEventCard(e.valueText, e.value)}
                                    filter={true}
                                    data={allEventsByGroup.map((i) => ({
                                        value: i.numSchEventID,
                                        text: i.varSchEventName + "(" + i.varSchEventDescription + ")"
                                    }))}
                                    label="Pick Event"
                                /> : null
                        } */}
                    </div>
                </div>
            </Popup>
            <div style={{ width: "100%", height: "100%", display: "flex" }}>
                <Collapse orientation="horizontal" in={visibleSidebar}>
                    <div style={{ flex: 1, width: "315px", height: "100%" }}>
                        <div style={{ padding: "0 15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h2 style={{ color: "#005df1" }}>Filters</h2>
                                <Button variant="contained" onClick={() => newBooking()}>
                                    {isNewBooking ? "New" : "Existing"}
                                </Button>
                            </div>
                        </div>
                        {isNewBooking ? (
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5px",
                                    flexDirection: "column",
                                }}
                            >
                                <div>
                                    <div style={{ display: "flex" }}>
                                        {currentCustomer === "" ? (
                                            <>
                                                <div style={{ width: "314px" }}>
                                                    <div style={{ background: "#F9FAFB" }}>
                                                        {/* <SearchBox onChange={(e) => debouceSearch(e)}  isButton={true} onAddClick={()=>setCustomerView(true)}  /> */}
                                                        {/* <ValidatePermission allowedModules={["02 - Schedule"]} allowedSubRoutes={["Add New Customer"]}>
                                                            <div
                                                                style={{
                                                                    height: "48px",
                                                                    borderBottom: "1px solid #F3F4F6",
                                                                    backgroundColor: "#fff",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    padding: "0 .5rem",
                                                                }}
                                                            >
                                                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <p style={{ margin: "0", color: "#002867", fontSize: "14px", fontWeight: "600" }}>Add New Customer</p>
                                                                    </div>

                                                                    {customerReady ? (
                                                                        !addCustomerView ? (
                                                                            <Button color="primary" variant="contained" onClick={() => setCustomerView(true)}>
                                                                                <Add />
                                                                            </Button>
                                                                        ) : null
                                                                    ) : (
                                                                        <CustomButton loading={true} color="primary" variant="contained" onClick={() => console.log("")}></CustomButton>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </ValidatePermission> */}
                                                        {addCustomerView ? null : addPetView ? null : (
                                                            <div style={{ height: "100%", display: "flex", alignItems: "center", gap: "0.3rem", padding: "2px .5rem", background: "#fff" }}>
                                                                <Search color={"disabled"} />
                                                                <input
                                                                    className={styles.searchInput}
                                                                    onChange={(e) => {
                                                                        debouceSearch(e.target.value)
                                                                    }}
                                                                    placeholder={"Search"}
                                                                    style={{ width: "100%" }}
                                                                    ref={searchBoxRef}
                                                                />
                                                            </div>
                                                        )}

                                                        {addCustomerView ? null : addPetView ? null : (
                                                            <div style={{ display: "flex", justifyContent: "space-between", margin: ".2rem .6rem" }}>
                                                                <p style={{ color: "#002867", fontSize: "14px", fontWeight: "600" }}>Advanced Search</p>
                                                                <a
                                                                    onClick={() => {
                                                                        setShowAdvanceSearch(!showAdvanceSearch)
                                                                    }}
                                                                >
                                                                    <p style={{ textDecoration: "underline", fontSize: "14px", fontWeight: "400", color: "#2076FF", cursor: "pointer" }}>
                                                                        {showAdvanceSearch ? "Hide" : "Show"}
                                                                    </p>
                                                                </a>
                                                            </div>
                                                        )}

                                                        <div>
                                                            {addCustomerView ? (
                                                                <div style={{ backgroundColor: "#fff", padding: "0 1rem 1rem 1rem" }}>
                                                                    <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                                                                        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                                                            <TextField
                                                                                inputProps={{
                                                                                    pattern: "^[a-zA-Z ]*$",
                                                                                }}
                                                                                required={customerLabels[5].bitRequired === undefined ? false : customerLabels[5].bitRequired}
                                                                                label={
                                                                                    <p>
                                                                                        {customerLabels[5].varLabelValue === undefined ? "" : customerLabels[5].varLabelValue}
                                                                                        {(customerLabels[5].bitRequired === undefined ? false : customerLabels[5].bitRequired) ? (
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    backgroundColor: "#FDEDED",
                                                                                                    color: "#EF4444",
                                                                                                    fontSize: "12px",
                                                                                                    padding: "0 5px",
                                                                                                    marginLeft: "5px",
                                                                                                }}
                                                                                            >
                                                                                                Required
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </p>
                                                                                }
                                                                                value={cusFName}
                                                                                onChange={(e) => setCusFName(e.target.value)}
                                                                                variant="standard"
                                                                                inputRef={addNewCustomerRef}
                                                                            />

                                                                            <TextField
                                                                                inputProps={{
                                                                                    pattern: "^[a-zA-Z ]*$",
                                                                                }}
                                                                                required={customerLabels[6].bitRequired === undefined ? false : customerLabels[6].bitRequired}
                                                                                label={
                                                                                    <p>
                                                                                        {customerLabels[6].varLabelValue === undefined ? "" : customerLabels[6].varLabelValue}
                                                                                        {(customerLabels[6].bitRequired === undefined ? false : customerLabels[6].bitRequired) ? (
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    backgroundColor: "#FDEDED",
                                                                                                    color: "#EF4444",
                                                                                                    fontSize: "12px",
                                                                                                    padding: "0 5px",
                                                                                                    marginLeft: "5px",
                                                                                                }}
                                                                                            >
                                                                                                Required
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </p>
                                                                                }
                                                                                value={cusLName}
                                                                                onChange={(e) => setCusLName(e.target.value)}
                                                                                variant="standard"
                                                                            />

                                                                            <InputMask
                                                                                alwaysShowMask="true"
                                                                                mask="\(999\)999\-9999"
                                                                                maskChar="_"
                                                                                value={cusMobile}
                                                                                onChange={(e) => setCusMobile(e.target.value)}
                                                                            >
                                                                                {(inputProps) => (
                                                                                    <TextField
                                                                                        inputProps={{
                                                                                            pattern: (customerLabels[14].bitRequired === undefined ? false : customerLabels[14].bitRequired)
                                                                                                ? "^[0-9()-]+$"
                                                                                                : cusMobile === "" || cusMobile === "(___)___-____"
                                                                                                ? null
                                                                                                : "^[0-9()-]+$",
                                                                                        }}
                                                                                        {...inputProps}
                                                                                        type="tel"
                                                                                        required={customerLabels[14].bitRequired === undefined ? false : customerLabels[14].bitRequired}
                                                                                        label={
                                                                                            <p>
                                                                                                {customerLabels[14].varLabelValue === undefined ? "" : customerLabels[14].varLabelValue}
                                                                                                {(customerLabels[14].bitRequired === undefined ? false : customerLabels[14].bitRequired) ? (
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontWeight: 500,
                                                                                                            backgroundColor: "#FDEDED",
                                                                                                            color: "#EF4444",
                                                                                                            fontSize: "12px",
                                                                                                            padding: "0 5px",
                                                                                                            marginLeft: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Required
                                                                                                    </span>
                                                                                                ) : null}
                                                                                            </p>
                                                                                        }
                                                                                        variant="standard"
                                                                                    />
                                                                                )}
                                                                            </InputMask>

                                                                            <InputMask
                                                                                alwaysShowMask="true"
                                                                                mask="\(999\)999\-9999"
                                                                                maskChar="_"
                                                                                value={cusHome}
                                                                                onChange={(e) => setCusHome(e.target.value)}
                                                                            >
                                                                                {(inputProps) => (
                                                                                    <TextField
                                                                                        inputProps={{
                                                                                            pattern: (customerLabels[11].bitRequired === undefined ? false : customerLabels[11].bitRequired)
                                                                                                ? "^[0-9()-]+$"
                                                                                                : cusHome === "" || cusHome === "(___)___-____"
                                                                                                ? null
                                                                                                : "^[0-9()-]+$",
                                                                                        }}
                                                                                        {...inputProps}
                                                                                        type="tel"
                                                                                        required={customerLabels[11].bitRequired === undefined ? false : customerLabels[11].bitRequired}
                                                                                        label={
                                                                                            <p>
                                                                                                {customerLabels[11].varLabelValue === undefined ? "" : customerLabels[11].varLabelValue}
                                                                                                {(customerLabels[11].bitRequired === undefined ? false : customerLabels[11].bitRequired) ? (
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontWeight: 500,
                                                                                                            backgroundColor: "#FDEDED",
                                                                                                            color: "#EF4444",
                                                                                                            fontSize: "12px",
                                                                                                            padding: "0 5px",
                                                                                                            marginLeft: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        Required
                                                                                                    </span>
                                                                                                ) : null}
                                                                                            </p>
                                                                                        }
                                                                                        variant="standard"
                                                                                    />
                                                                                )}
                                                                            </InputMask>

                                                                            <TextField
                                                                                type="email"
                                                                                required={customerLabels[8].bitRequired === undefined ? false : customerLabels[8].bitRequired}
                                                                                label={
                                                                                    <p>
                                                                                        {customerLabels[8].varLabelValue === undefined ? "" : customerLabels[8].varLabelValue}
                                                                                        {(customerLabels[8].bitRequired === undefined ? false : customerLabels[8].bitRequired) ? (
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    backgroundColor: "#FDEDED",
                                                                                                    color: "#EF4444",
                                                                                                    fontSize: "12px",
                                                                                                    padding: "0 5px",
                                                                                                    marginLeft: "5px",
                                                                                                }}
                                                                                            >
                                                                                                Required
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </p>
                                                                                }
                                                                                value={cusEmail}
                                                                                onChange={(e) => setCusEmail(e.target.value)}
                                                                                variant="standard"
                                                                            />

                                                                            <InputMask
                                                                                alwaysShowMask="true"
                                                                                mask="99999"
                                                                                maskChar="_"
                                                                                value={cusZip}
                                                                                onChange={(e) => {
                                                                                    debouceZipSearch(e.target.value)
                                                                                    setCusZip(e.target.value)
                                                                                }}
                                                                            >
                                                                                {(inputProps) => (
                                                                                    <TextField
                                                                                        inputProps={{
                                                                                            pattern: "^[0-9]+$",
                                                                                        }}
                                                                                        {...inputProps}
                                                                                        type="tel"
                                                                                        required={true}
                                                                                        label={
                                                                                            <p>
                                                                                                Zip Code
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontWeight: 500,
                                                                                                        backgroundColor: "#FDEDED",
                                                                                                        color: "#EF4444",
                                                                                                        fontSize: "12px",
                                                                                                        padding: "0 5px",
                                                                                                        marginLeft: "5px",
                                                                                                    }}
                                                                                                >
                                                                                                    Required
                                                                                                </span>
                                                                                            </p>
                                                                                        }
                                                                                        variant="standard"
                                                                                    />
                                                                                )}
                                                                            </InputMask>

                                                                            <TextField
                                                                                inputProps={{
                                                                                    pattern: "^[a-zA-Z ]*$",
                                                                                }}
                                                                                required={true}
                                                                                variant="standard"
                                                                                onInput={handleCityInput}
                                                                                label={
                                                                                    <p>
                                                                                        City
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight: 500,
                                                                                                backgroundColor: "#FDEDED",
                                                                                                color: "#EF4444",
                                                                                                fontSize: "12px",
                                                                                                padding: "0 5px",
                                                                                                marginLeft: "5px",
                                                                                            }}
                                                                                        >
                                                                                            Required
                                                                                        </span>
                                                                                    </p>
                                                                                }
                                                                                value={cusCity}
                                                                                onChange={(e) => setCusCity(e.target.value)}
                                                                            />

                                                                            <TextField
                                                                                type={"text"}
                                                                                required={true}
                                                                                onInput={handleInput}
                                                                                variant="standard"
                                                                                label={
                                                                                    <p>
                                                                                        State
                                                                                        <span
                                                                                            style={{
                                                                                                fontWeight: 500,
                                                                                                backgroundColor: "#FDEDED",
                                                                                                color: "#EF4444",
                                                                                                fontSize: "12px",
                                                                                                padding: "0 5px",
                                                                                                marginLeft: "5px",
                                                                                            }}
                                                                                        >
                                                                                            Required
                                                                                        </span>
                                                                                    </p>
                                                                                }
                                                                                value={cusState}
                                                                                onChange={(e) => setCusState(e.target.value)}
                                                                            />
                                                                        </div>

                                                                        <div style={{ paddingTop: "2rem", display: "flex", gap: "5px", flexDirection: "column" }}>
                                                                            <CustomButton
                                                                                color="primary"
                                                                                variant="contained"
                                                                                text="Create Customer Profile"
                                                                                loading={customerLoading}
                                                                                onClick={() => addNewCustomer()}
                                                                                type={"submit"}
                                                                            />
                                                                            <CustomButton color="default" variant="text" text="Cancel" onClick={() => cancelForm()} />
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        <>
                                                            {addCustomerView ? null : showAdvanceSearch ? (
                                                                <div style={{ backgroundColor: "#fff", padding: "1rem 1rem 2rem 1rem", display: "flex", gap: ".5rem", flexDirection: "column" }}>
                                                                    <TextField label={<p>First Name</p>} value={seFName} onChange={(e) => setSeFName(e.target.value)} variant="standard" />

                                                                    <TextField label={<p>Last Name</p>} value={seLName} onChange={(e) => setSeLName(e.target.value)} variant="standard" />

                                                                    <TextField label={<p>Pet's Name</p>} value={sePetName} onChange={(e) => setSePetName(e.target.value)} variant="standard" />

                                                                    {allLocations !== undefined && allLocations !== null ? (
                                                                        <AdvancedDropDown
                                                                            data={allLocations.map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                                                            onChange={(e) => {
                                                                                setSeLocation(e?.label)
                                                                            }}
                                                                            ref={myRef}
                                                                            placeHolder={"Locations"}
                                                                        />
                                                                    ) : null}

                                                                    <InputMask
                                                                        alwaysShowMask="true"
                                                                        mask="\(999\)999\-9999"
                                                                        maskChar="_"
                                                                        value={seContact}
                                                                        onChange={(e) => setSeContact(e.target.value)}
                                                                    >
                                                                        {(inputProps) => <TextField {...inputProps} type="tel" required={false} label={<p>Mobile</p>} variant="standard" />}
                                                                    </InputMask>
                                                                    <div style={{ paddingTop: "1rem", display: "flex", gap: "5px", flexDirection: "column" }}>
                                                                        <CustomButton
                                                                            color="primary"
                                                                            variant="contained"
                                                                            text="Reset"
                                                                            loading={customerLoading}
                                                                            onClick={() => resetFilters()}
                                                                            type={"submit"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </>

                                                        <div>
                                                            {isLoading ? (
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: ".5rem", gap: ".5rem" }}>
                                                                    <CircularProgress style={{ width: "15px", height: "15px" }} />
                                                                    <p style={{ fontWeight: 600 }}>Loading</p>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {searchData.length > 0 ? (
                                                                        <div style={{ display: "flex", justifyContent: "space-between", margin: ".5rem .5rem 0 .5rem" }}>
                                                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                                                <StickyNote2Outlined style={{ color: "#002867", fontSize: "15px" }} />
                                                                                <p style={{ fontWeight: "600", fontSize: "14px", color: "#000000" }}>Search Results</p>
                                                                            </div>

                                                                            <div>
                                                                                <p style={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}>
                                                                                    <span style={{ color: "#002867", fontWeight: "600", fontSize: "14px" }}>{searchData.length}</span>{" "}
                                                                                    {searchData.length > 1 ? "results found" : "result found"}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}

                                                                    <div style={{ overflow: "scroll", height: "calc(100vh - 210px)", padding: "0 0.2rem" }}>
                                                                        {searchData.length > 0 ? (
                                                                            searchData.map((customer, index) => {
                                                                                return (
                                                                                    <div key={index} onClick={() => selectCustomer(customer?.numCustomerID, index)} className={styles.card}>
                                                                                        <div
                                                                                            style={{
                                                                                                padding: "0 0 .5rem 0",
                                                                                                borderBottom: "1px solid #F3F4F6",
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                alignItems: "center",
                                                                                                gap: "1rem",
                                                                                            }}
                                                                                        >
                                                                                            <Avatar
                                                                                                className={"medium"}
                                                                                                img={
                                                                                                    customer?.varCustomerImagePath === null
                                                                                                        ? ""
                                                                                                        : `${process.env.REACT_APP_MEDIA_URL}directories/customers/${customer?.varCustomerImagePath}`
                                                                                                }
                                                                                                isVip={customer?.bitIsVip}
                                                                                            />
                                                                                            <div
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    display: "flex",
                                                                                                    flexDirection: "column",
                                                                                                    justifyContent: "space-between",
                                                                                                    gap: "0.2rem",
                                                                                                }}
                                                                                            >
                                                                                                <div>
                                                                                                    <p
                                                                                                        style={{ margin: "0px", fontWeight: "600", fontSize: "14px", textTransform: "capitalize" }}
                                                                                                    >{`${customer?.varCustomerFirstName} ${customer?.varCustomerLastName}`}</p>
                                                                                                </div>

                                                                                                <div>
                                                                                                    <LocationChip
                                                                                                        label={
                                                                                                            customer?.varCustomerDefaultLocation === undefined
                                                                                                                ? ""
                                                                                                                : Truncate(customer?.varCustomerDefaultLocation, 19)
                                                                                                        }
                                                                                                        shape="square"
                                                                                                        icon={<Place style={{ fontSize: "13px" }} />}
                                                                                                        customStyle="BlueFilled"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{
                                                                                                borderBottom: "1px solid #F3F4F6",
                                                                                                display: "flex",
                                                                                                justifyContent: "space-between",
                                                                                                padding: "0.5rem 0",
                                                                                            }}
                                                                                        >
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Mobile</p>
                                                                                                <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>
                                                                                                    {formatPhone(customer?.varCustomerMobilePhone)}{" "}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div style={{ width: "100%" }}>
                                                                                                <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Home</p>
                                                                                                <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>
                                                                                                    {formatPhone(customer?.varCustomerHomePhone)}{" "}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        {customer?.customerPet.length > 0
                                                                                            ? customer?.customerPet.map((pet) => {
                                                                                                  return (
                                                                                                      <div>
                                                                                                          <div>
                                                                                                              <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "0.5rem 0" }}>
                                                                                                                  <div
                                                                                                                      style={{
                                                                                                                          padding: "0.5rem 0.8rem",
                                                                                                                          backgroundColor: "#EFF5FF",
                                                                                                                          borderRadius: "5px",
                                                                                                                          fontWeight: "600",
                                                                                                                      }}
                                                                                                                  >
                                                                                                                      S
                                                                                                                  </div>
                                                                                                                  <div>
                                                                                                                      <p style={{ margin: "0", fontWeight: "600" }}>{pet?.varPetName}</p>
                                                                                                                      <p style={{ color: "gray", margin: "0", fontSize: "11px" }}>{pet?.varPetBreed}</p>
                                                                                                                  </div>
                                                                                                              </div>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  )
                                                                                              })
                                                                                            : null}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        ) : searchValue != "" ? (
                                                                            <div style={{ width: "100%", textAlign: "center" }}>
                                                                                <p>No results found</p>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div style={{ width: "314px" }}>
                                                <div style={{ width: "100%", backgroundColor: "#fff", height: "48px", display: "flex", alignItems: "center", borderBottom: "1px solid #F3F4F6" }}>
                                                    <CustomButton variant="text" color="default" className={styles.backButton} onClick={() => goBack()}>
                                                        <ArrowBack style={{ color: "#000", fontSize: "20px" }} />
                                                        Back
                                                    </CustomButton>
                                                </div>

                                                <ValidatePermission allowedModules={["02 - Schedule"]} allowedSubRoutes={["Add New Pet"]}>
                                                    {addPetView ? (
                                                        <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
                                                            <form id="editFormPet" onSubmit={(e) => e.preventDefault()}>
                                                                <p style={{ color: "#002867", fontSize: "14px", fontWeight: "600" }}>Add New Pet</p>
                                                                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                                                    <TextField
                                                                        variant="standard"
                                                                        required={petLabels[12].bitRequired}
                                                                        label={
                                                                            <p>
                                                                                {petLabels[12].varLabelValue}
                                                                                {petLabels[12].bitRequired ? (
                                                                                    <span
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            backgroundColor: "#FDEDED",
                                                                                            color: "#EF4444",
                                                                                            fontSize: "12px",
                                                                                            padding: "0 5px",
                                                                                            marginLeft: "5px",
                                                                                        }}
                                                                                    >
                                                                                        Required
                                                                                    </span>
                                                                                ) : null}
                                                                            </p>
                                                                        }
                                                                        value={petName}
                                                                        onChange={(e) => setPetName(e.target.value)}
                                                                        inputRef={addNewPetRef}
                                                                    />

                                                                    <TextField
                                                                        fullWidth
                                                                        variant="standard"
                                                                        select={!!typeData}
                                                                        required={petLabels[14].bitRequired}
                                                                        label={
                                                                            <p>
                                                                                {petLabels[14].varLabelValue}
                                                                                {petLabels[14].bitRequired ? (
                                                                                    <span
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            backgroundColor: "#FDEDED",
                                                                                            color: "#EF4444",
                                                                                            fontSize: "12px",
                                                                                            padding: "0 5px",
                                                                                            marginLeft: "5px",
                                                                                        }}
                                                                                    >
                                                                                        Required
                                                                                    </span>
                                                                                ) : null}
                                                                            </p>
                                                                        }
                                                                        value={petType}
                                                                        onChange={(e) => {
                                                                            setPetType(e.target.value)
                                                                            getSizeData(e.target.value)
                                                                        }}
                                                                    >
                                                                        {typeData?.map((item, i) => {
                                                                            return (
                                                                                <MenuItem key={i} value={item.numPetTypeID} style={{ fontSize: `medium` }}>
                                                                                    {item.varPetTypeName}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </TextField>

                                                                    <div>
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                            <p>Mixed Breed</p>
                                                                            <Switch
                                                                                onChange={(e) => {
                                                                                    setPetIsMix(!petIsMix)
                                                                                }}
                                                                                checked={petIsMix}
                                                                                inputProps={{ "aria-label": "controlled" }}
                                                                            />
                                                                        </div>

                                                                        {petIsMix ? (
                                                                            <>
                                                                                {noBreedData ? (
                                                                                    <>
                                                                                        <TextField
                                                                                            fullWidth
                                                                                            disabled={true}
                                                                                            variant="standard"
                                                                                            required={petLabels[15].bitRequired}
                                                                                            label={
                                                                                                <p>
                                                                                                    {petLabels[15].varLabelValue}
                                                                                                    {petLabels[15].bitRequired ? (
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontWeight: 500,
                                                                                                                backgroundColor: "#FDEDED",
                                                                                                                color: "#EF4444",
                                                                                                                fontSize: "12px",
                                                                                                                padding: "0 5px",
                                                                                                                marginLeft: "5px",
                                                                                                            }}
                                                                                                        >
                                                                                                            Required
                                                                                                        </span>
                                                                                                    ) : null}
                                                                                                </p>
                                                                                            }
                                                                                            value={primaryName}
                                                                                            onChange={(e) => setPetBreed(e.target.value)}
                                                                                        ></TextField>
                                                                                    </>
                                                                                ) : (
                                                                                    // <TextField
                                                                                    //     fullWidth
                                                                                    //     variant='standard'
                                                                                    //     select={!!MixBreedData}
                                                                                    //     required={petLabels[15].bitRequired}
                                                                                    //     label={
                                                                                    //         <p>
                                                                                    //             {petLabels[15].varLabelValue}
                                                                                    //             {
                                                                                    //                 (petLabels[15].bitRequired) ?
                                                                                    //                     <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                    //                 : null
                                                                                    //             }
                                                                                    //         </p>
                                                                                    //     }
                                                                                    //     value={ petBreed }
                                                                                    //     onChange={(e) => setPetBreed(e.target.value) }
                                                                                    // >
                                                                                    //     {MixBreedData?.map((item, i) => {
                                                                                    //         return (
                                                                                    //             <MenuItem key={i} value={item.numPetBreedID} style={{fontSize:  `medium`}} >
                                                                                    //                 {item.varPetBreedName}
                                                                                    //             </MenuItem>
                                                                                    //         );
                                                                                    //     })}
                                                                                    // </TextField>

                                                                                    <div style={{ padding: ".5rem 0 0 0", display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                <div style={{ backgroundColor: "#fff4c2", padding: "0px" }}>
                                                                                                    <p style={{ fontSize: "14px", color: "#000", fontWeight: "600" }}>Description</p>
                                                                                                    <p style={{ fontSize: "12px", color: "#000" }}>{primaryNameDesc}</p>
                                                                                                </div>
                                                                                            }
                                                                                        >
                                                                                            <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                                                        </Tooltip>
                                                                                        <div style={{ width: "100%" }}>
                                                                                            <AdvancedDropDown
                                                                                                data={MixBreedData.map((i) => ({
                                                                                                    label: `${i.varPetBreedName} (${i.varPetBreedDescription})`,
                                                                                                    value: i.numPetBreedID,
                                                                                                }))}
                                                                                                onChange={(e) => {
                                                                                                    setPetBreed(e?.value)
                                                                                                }}
                                                                                                passByID={true}
                                                                                                value={petBreed}
                                                                                                ref={BreedRef}
                                                                                                placeHolder={petLabels[15].varLabelValue}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                                <div style={{ padding: "1rem 0 0 0" }}>
                                                                                    <p style={{ margin: "0" }}>Please select Primary Breed</p>
                                                                                    <div style={{ padding: ".5rem 0 0 0", display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                <div style={{ backgroundColor: "#fff4c2", padding: "0px" }}>
                                                                                                    <p style={{ fontSize: "14px", color: "#000", fontWeight: "600" }}>Primary Breed</p>
                                                                                                </div>
                                                                                            }
                                                                                        >
                                                                                            <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                                                        </Tooltip>
                                                                                        <div style={{ width: "100%" }}>
                                                                                            <AdvancedDropDown
                                                                                                data={breedMainData.map((i) => ({ label: i.varBreedName, value: i.numBreedID }))}
                                                                                                onChange={(e) => {
                                                                                                    setPrimaryBreed(e?.value)
                                                                                                    setPrimaryBreedNameX(e?.label)
                                                                                                }}
                                                                                                passByID={true}
                                                                                                value={primaryBreed}
                                                                                                ref={primaryNameRef}
                                                                                                placeHolder={<p>Primary Breed</p>}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <p style={{ margin: "10px 0 0 0" }}>Please select Secondary Breed</p>
                                                                                    <div style={{ padding: ".5rem 0 0 0", display: "flex", gap: "5px", alignItems: "center", width: "100%" }}>
                                                                                        <Tooltip
                                                                                            title={
                                                                                                <div style={{ backgroundColor: "#fff4c2", padding: "0px" }}>
                                                                                                    <p style={{ fontSize: "14px", color: "#000", fontWeight: "600" }}>Secondary Breed</p>
                                                                                                </div>
                                                                                            }
                                                                                        >
                                                                                            <InfoOutlined style={{ fontSize: "17px", color: "#979797" }} />
                                                                                        </Tooltip>

                                                                                        <div style={{ width: "100%" }}>
                                                                                            <AdvancedDropDown
                                                                                                data={breedMainData.map((i) => ({ label: i.varBreedName, value: i.numBreedID }))}
                                                                                                onChange={(e) => {
                                                                                                    setSecondaryBreed(e?.value)
                                                                                                    setSecondaryBreedNameX(e?.label)
                                                                                                    setSecondName(e?.label)
                                                                                                }}
                                                                                                passByID={true}
                                                                                                value={secondaryBreed}
                                                                                                ref={secondaryNameRef}
                                                                                                placeHolder={<p>Secondary Breed</p>}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div style={{ padding: ".5rem 0 0 0" }}></div>

                                                                                {/* 
                                                                        <TextField                                                                            
                                                                            variant={'standard'} 
                                                                            select={!!breedMainData}    
                                                                            fullWidth
                                                                            multiline={true}     
                                                                            InputLabelProps={{ shrink: true }}                                                                                        
                                                                            label={<p>Secondary Breed</p>} 
                                                                            value={ secondaryBreed } 
                                                                            onChange={(e) => {
                                                                                setSecondaryBreed(e.target.value)                                             
                                                                            }} 
                                                                        >
                                                                            {
                                                                                breedMainData?.map((item, i) => {
                                                                                    return (
                                                                                        <MenuItem onClick={() => setSecondName(item.varBreedName)} key={item.numBreedID} value={item.numBreedID}>
                                                                                            {item.varBreedName}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })
                                                                            }                                                            
                                                                        </TextField> 
                                                                    */}
                                                                            </>
                                                                        ) : (
                                                                            // <TextField
                                                                            //     fullWidth
                                                                            //     variant='standard'
                                                                            //     select={!!PureBreedData}
                                                                            //     required={petLabels[15].bitRequired}
                                                                            //     label={
                                                                            //         <p>
                                                                            //             {petLabels[15].varLabelValue}
                                                                            //             {
                                                                            //                 (petLabels[15].bitRequired) ?
                                                                            //                     <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                            //                 : null
                                                                            //             }
                                                                            //         </p>
                                                                            //     }
                                                                            //     value={ petBreed }
                                                                            //     onChange={(e) => setPetBreed(e.target.value) }
                                                                            // >
                                                                            //     {PureBreedData?.map((item, i) => {
                                                                            //         return (
                                                                            //             <MenuItem key={i} value={item.numPetBreedID} style={{fontSize:  `medium`}} >
                                                                            //                 {item.varPetBreedName}
                                                                            //             </MenuItem>
                                                                            //         );
                                                                            //     })}
                                                                            // </TextField>

                                                                            <div style={{ padding: ".5rem 0 0 0" }}>
                                                                                <AdvancedDropDown
                                                                                    data={PureBreedData.map((i) => ({
                                                                                        label: `${i.varPetBreedName} (${i.varPetBreedDescription})`,
                                                                                        value: i.numPetBreedID,
                                                                                    }))}
                                                                                    onChange={(e) => {
                                                                                        setPetBreed(e?.value)
                                                                                    }}
                                                                                    passByID={true}
                                                                                    value={petBreed}
                                                                                    ref={BreedRef}
                                                                                    placeHolder={petLabels[15].varLabelValue}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <InputMask
                                                                        alwaysShowMask="true"
                                                                        mask="99\lbs"
                                                                        maskChar="_"
                                                                        value={petWeight}
                                                                        onChange={(e) => {
                                                                            setPetWeight(e.target.value)
                                                                        }}
                                                                    >
                                                                        {(inputProps) => (
                                                                            <TextField
                                                                                {...inputProps}
                                                                                type="tel"
                                                                                required={petLabels[19].bitRequired}
                                                                                label={
                                                                                    <p>
                                                                                        {petLabels[19].varLabelValue}
                                                                                        {petLabels[19].bitRequired ? (
                                                                                            <span
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    backgroundColor: "#FDEDED",
                                                                                                    color: "#EF4444",
                                                                                                    fontSize: "12px",
                                                                                                    padding: "0 5px",
                                                                                                    marginLeft: "5px",
                                                                                                }}
                                                                                            >
                                                                                                Required
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </p>
                                                                                }
                                                                                variant="standard"
                                                                            />
                                                                        )}
                                                                    </InputMask>

                                                                    <TextField
                                                                        fullWidth
                                                                        variant="standard"
                                                                        select={!!sizeData}
                                                                        required={petLabels[20].bitRequired}
                                                                        label={
                                                                            <p>
                                                                                {petLabels[20].varLabelValue}
                                                                                {petLabels[20].bitRequired ? (
                                                                                    <span
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            backgroundColor: "#FDEDED",
                                                                                            color: "#EF4444",
                                                                                            fontSize: "12px",
                                                                                            padding: "0 5px",
                                                                                            marginLeft: "5px",
                                                                                        }}
                                                                                    >
                                                                                        Required
                                                                                    </span>
                                                                                ) : null}
                                                                            </p>
                                                                        }
                                                                        value={petSize}
                                                                        onChange={(e) => setPetSize(e.target.value)}
                                                                    >
                                                                        {sizeData?.map((item, i) => {
                                                                            return (
                                                                                <MenuItem key={i} value={item.numPetSizeID} style={{ fontSize: `medium` }}>
                                                                                    {item.varPetSizeName}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </TextField>

                                                                    <TextField
                                                                        fullWidth
                                                                        variant="standard"
                                                                        select={!!true}
                                                                        required={petLabels[21].bitRequired}
                                                                        label={
                                                                            <p>
                                                                                {petLabels[21].varLabelValue}
                                                                                {petLabels[21].bitRequired ? (
                                                                                    <span
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            backgroundColor: "#FDEDED",
                                                                                            color: "#EF4444",
                                                                                            fontSize: "12px",
                                                                                            padding: "0 5px",
                                                                                            marginLeft: "5px",
                                                                                        }}
                                                                                    >
                                                                                        Required
                                                                                    </span>
                                                                                ) : null}
                                                                            </p>
                                                                        }
                                                                        value={petGender}
                                                                        onChange={(e) => setPetGender(e.target.value)}
                                                                    >
                                                                        <MenuItem key={1} value={"M"} style={{ fontSize: `medium` }}>
                                                                            Male
                                                                        </MenuItem>

                                                                        <MenuItem key={2} value={"F"} style={{ fontSize: `medium` }}>
                                                                            Female
                                                                        </MenuItem>
                                                                    </TextField>
                                                                </div>

                                                                <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: ".5rem", marginTop: "2rem" }}>
                                                                    <CustomButton type={"submit"} onClick={() => createPet()} color="primary" variant="contained" text={"Create pet profile"} />
                                                                    <CustomButton onClick={() => setAddPetView(false)} color="default" variant="text" text={"Cancel"} />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    ) : null}
                                                </ValidatePermission>

                                                <div style={{ padding: ".8rem" }}>
                                                    {newCustomer ? (
                                                        <div>
                                                            <p style={{ color: "#002867", fontSize: "14px", fontWeight: "600" }}>Selected Customer</p>
                                                            <div className={styles.card}>
                                                                <div
                                                                    style={{
                                                                        padding: "0 0 .5rem 0",
                                                                        borderBottom: "1px solid #F3F4F6",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        gap: "1rem",
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        className={"medium"}
                                                                        img={
                                                                            createdCustomer?.varCustomerImagePath === null
                                                                                ? ""
                                                                                : `${process.env.REACT_APP_MEDIA_URL}directories/customers/${createdCustomer?.varCustomerImagePath}`
                                                                        }
                                                                        isVip={createdCustomer?.bitIsVip}
                                                                    />
                                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "0.2rem" }}>
                                                                        <div>
                                                                            <p
                                                                                style={{ margin: "0px", fontWeight: "600", fontSize: "14px", textTransform: "capitalize" }}
                                                                            >{`${searchData[currentCustomerIndex]?.varCustomerFirstName} ${searchData[currentCustomerIndex]?.varCustomerLastName}`}</p>
                                                                        </div>

                                                                        <div>
                                                                            <LocationChip
                                                                                label={
                                                                                    createdCustomer?.varCustomerDefaultLocation === undefined
                                                                                        ? ""
                                                                                        : Truncate(createdCustomer?.varCustomerDefaultLocation, 19)
                                                                                }
                                                                                shape="square"
                                                                                icon={<Place style={{ fontSize: "13px" }} />}
                                                                                customStyle="BlueFilled"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div style={{ borderBottom: "1px solid #F3F4F6", display: "flex", justifyContent: "space-between", padding: "0.5rem 0" }}>
                                                                    <div style={{ width: "100%" }}>
                                                                        <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Mobile</p>
                                                                        <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(createdCustomer?.varCustomerMobilePhone)} </p>
                                                                    </div>
                                                                    <div style={{ width: "100%" }}>
                                                                        <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Home</p>
                                                                        <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(createdCustomer?.varCustomerHomePhone)} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <p style={{ color: "#002867", fontSize: "14px", fontWeight: "600" }}>Selected Customer</p>
                                                            <div className={styles.card}>
                                                                <div
                                                                    style={{
                                                                        padding: "0 0 .5rem 0",
                                                                        borderBottom: "1px solid #F3F4F6",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        gap: "1rem",
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        className={"medium"}
                                                                        img={
                                                                            createdCustomer?.varCustomerImagePath === null
                                                                                ? ""
                                                                                : `${process.env.REACT_APP_MEDIA_URL}directories/customers/${createdCustomer?.varCustomerImagePath}`
                                                                        }
                                                                        isVip={createdCustomer?.bitIsVip}
                                                                    />
                                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "0.2rem" }}>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <p
                                                                                style={{ margin: "0px", fontWeight: "600", fontSize: "14px", textTransform: "capitalize" }}
                                                                            >{`${createdCustomer?.varCustomerFirstName} ${createdCustomer?.varCustomerLastName}`}</p>
                                                                            {showAlert ? (
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        setIsAlertsModal(true)
                                                                                    }}
                                                                                    style={{ margin: "0" }}
                                                                                >
                                                                                    <WarningAmber style={{ color: "red", fontSize: "20px" }} />
                                                                                </IconButton>
                                                                            ) : null}
                                                                        </div>
                                                                        <CustomerAlerts
                                                                            isOpenAlertsModal={isOpenAlertsModal}
                                                                            setIsAlertsModal={(value) => setIsAlertsModal(value)}
                                                                            data={createdCustomer}
                                                                        />
                                                                        <div>
                                                                            <LocationChip
                                                                                label={
                                                                                    createdCustomer?.varCustomerDefaultLocation === undefined
                                                                                        ? ""
                                                                                        : Truncate(createdCustomer?.varCustomerDefaultLocation, 19)
                                                                                }
                                                                                shape="square"
                                                                                icon={<Place style={{ fontSize: "13px" }} />}
                                                                                customStyle="BlueFilled"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div style={{ borderBottom: "1px solid #F3F4F6", display: "flex", justifyContent: "space-between", padding: "0.5rem 0" }}>
                                                                    <div style={{ width: "100%" }}>
                                                                        <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Mobile</p>
                                                                        <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(createdCustomer?.varCustomerMobilePhone)} </p>
                                                                    </div>
                                                                    <div style={{ width: "100%" }}>
                                                                        <p style={{ color: "gray", margin: "0", fontSize: "12px", fontWeight: "600" }}>Home</p>
                                                                        <p style={{ margin: "0", color: "#002867", fontWeight: "600" }}>{formatPhone(createdCustomer?.varCustomerHomePhone)} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: ".5rem", margin: ".5rem 0 .8rem 0" }}>
                                                        <ValidatePermission allowedModules={["02 - Schedule"]} allowedSubRoutes={["Add New Pet"]}>
                                                            <CustomButton onClick={() => setAddPetView(true)} color="primary" variant="contained" text={"Add New Pet +"} />
                                                        </ValidatePermission>
                                                    </div>

                                                    <div style={{ overflowY: "scroll", height: "calc(100vh - 400px)" }}>
                                                        {isLoading ? (
                                                            <p>Loading...</p>
                                                        ) : createdCustomer?.customerPet != undefined && createdCustomer?.customerPet.length > 0 ? (
                                                            createdCustomer?.customerPet.map((pet) => {
                                                                return (
                                                                    <div className={styles.card}>
                                                                        <div>
                                                                            <div style={{ display: "flex", alignItems: "center", gap: "1rem", padding: "0.2rem 0", justifyContent: "space-between" }}>
                                                                                <div>
                                                                                    <p style={{ fontSize: "16px", margin: "0", fontWeight: "600", textTransform: "capitalize" }}>{pet?.varPetName}</p>
                                                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                        <p style={{ color: "gray", margin: "0", fontSize: "11px" }}>
                                                                                            {pet?.varPetBreed === null ? "N/A" : pet?.varPetBreed}
                                                                                        </p>
                                                                                        {pet?.isBathOnlyPet ? (
                                                                                            <Tooltip
                                                                                                title={
                                                                                                    <>
                                                                                                        <p style={{ fontSize: "12px", color: "#fff" }}>{"Bath type dog"}</p>
                                                                                                    </>
                                                                                                }
                                                                                            >
                                                                                                <ShowerOutlinedIcon style={{ fontSize: "20px" }} />
                                                                                            </Tooltip>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                                {pet?.varPetImagePath == null ? (
                                                                                    <div
                                                                                        style={{
                                                                                            padding: ".8rem",
                                                                                            backgroundColor: "#005df1db",
                                                                                            display: "flex",
                                                                                            borderRadius: "5px",
                                                                                            color: "#fff",
                                                                                        }}
                                                                                    >
                                                                                        <Pets />
                                                                                    </div>
                                                                                ) : (
                                                                                    <Avatar
                                                                                        className={"medium"}
                                                                                        shape="rounded"
                                                                                        img={
                                                                                            pet?.varPetImagePath == "" || pet?.varPetImagePath == null
                                                                                                ? placeholderImage
                                                                                                : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + pet?.varPetImagePath
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: ".5rem" }}>
                                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: ".8rem" }}>
                                                                                {/* <MaterialButton
                                                                style={{
                                                                    width: '100%',
                                                                    textTransform: 'initial',
                                                                    fontSize: '12px'
                                                                }}
                                                                onClick={() => null}
                                                                variant='outlined'
                                                                fullWidth={true}
                                                            >
                                                                Pet Profile
                                                            </MaterialButton> */}
                                                                                {/* <div  style={{
                                                                    width: '100%'
                                                                }}></div> */}

                                                                                <MaterialButton
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        textTransform: "initial",
                                                                                        fontSize: "12px",
                                                                                    }}
                                                                                    fullWidth={true}
                                                                                    onClick={() => {
                                                                                        const list: any = [...petList]
                                                                                        let isAlreadyAdded = list.some((petA) => {
                                                                                            return petA.id === pet?.numPetID
                                                                                        })
                                                                                        if (!isAlreadyAdded) {
                                                                                            console.log("pettt", pet)
                                                                                            list.push({
                                                                                                id: pet?.numPetID,
                                                                                                name: pet?.varPetName,
                                                                                                breed: pet?.varPetBreed,
                                                                                                size: pet?.varPetSize,
                                                                                                img: pet?.varPetImagePath,
                                                                                                preferredGroomer: pet?.numPetPreferredGroomerID,
                                                                                                mainService: {
                                                                                                    id: null,
                                                                                                    name: null,
                                                                                                    price: null,
                                                                                                    varServiceProductDescription: null,
                                                                                                    decUnit_Price: null,
                                                                                                    taxAmount: null,
                                                                                                    orderID: null,
                                                                                                    bitActive: null,
                                                                                                    numLocationServiceProductItemID: null,
                                                                                                    numSalesTaxPercentage: null,
                                                                                                },
                                                                                                additionalService: [],
                                                                                                selectedProducts: [],
                                                                                                petType: pet?.numPetTypeID,
                                                                                                customerID: currentCustomer,
                                                                                                weight: pet?.numPetWeight,
                                                                                                breedID: pet?.numPetBreedID,
                                                                                                sizeID: pet?.numPetSizeID,
                                                                                                customer: createdCustomer,
                                                                                                petPersonality: pet?.petPersonality,
                                                                                            })
                                                                                        }
                                                                                        setPetList(list)
                                                                                        showPetAlert(pet?.petPersonality)
                                                                                    }}
                                                                                    variant="outlined"
                                                                                >
                                                                                    Add
                                                                                </MaterialButton>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        ) : (
                                                            <p>No pets found!</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "5px",
                                    flexDirection: "column",
                                }}
                            >
                                <div style={{ padding: "0 15px" }}>
                                    <div>
                                        <p style={{ margin: 0 }}>Select From Date</p>
                                        <Input value={fromDate} onChange={(e) => setFromDate(e)} type="date" variant={"outlined"}></Input>
                                    </div>
                                    <div>
                                        <p style={{ margin: 0 }}>Select To Date</p>
                                        <Input value={toDate} onChange={(e) => setToDate(e)} type="date" variant={"outlined"}></Input>
                                    </div>
                                </div>
                                <div style={{ padding: "0 15px" }}>
                                    <p>Select Location</p>
                                    {allLocations !== undefined && allLocations !== null ? (
                                        <div style={{ width: "100%" }}>
                                            <AdvancedDropDown
                                                data={allLocations.sort((a, b) => a.numLocationID - b.numLocationID).map((i) => ({ label: i.varLocationName, value: i.numLocationID }))}
                                                onChange={(e) => {
                                                    setSelectedLocation(e?.value)
                                                }}
                                                ref={filterLocationRef}
                                                placeHolder={"Location"}
                                                passByID={true}
                                                value={selectedLocation}
                                            />
                                        </div>
                                    ) : null}
                                </div>

                                <div style={{ padding: "0 15px" }}>
                                    <p>Select Customer</p>
                                    {allCustomers !== undefined && allCustomers !== null ? (
                                        <div style={{ width: "100%" }}>
                                            <AdvancedDropDown
                                                data={allCustomers
                                                    .sort((a, b) => a.numCustomerID - b.numCustomerID)
                                                    .map((i) => ({ label: i.varCustomerFirstName + " " + i.varCustomerLastName, value: i.numCustomerID }))}
                                                onChange={(e) => {
                                                    setSelectedCustomer(e?.value)
                                                }}
                                                ref={filterLocationRef}
                                                placeHolder={"Customer"}
                                                passByID={true}
                                                value={selectedCustomer}
                                            />
                                        </div>
                                    ) : null}
                                </div>

                                <div style={{ padding: "0 15px" }}>
                                    <p>Select Pet</p>
                                    {allPet !== undefined && allPet !== null ? (
                                        <div style={{ width: "100%" }}>
                                            <AdvancedDropDown
                                                data={allPet.sort((a, b) => a.numPetID - b.numPetID).map((i) => ({ label: i.varPetName, value: i.numPetID }))}
                                                onChange={(e) => {
                                                    setSelectedPet(e?.value)
                                                }}
                                                ref={filterLocationRef}
                                                placeHolder={"Pet"}
                                                passByID={true}
                                                value={selectedPet}
                                            />
                                        </div>
                                    ) : null}
                                </div>

                                <div style={{ padding: "0 15px" }}>
                                    <p>Employees</p>

                                    {employeeList !== undefined && employeeList !== null ? (
                                        <AdvancedDropDown
                                            passByID={true}
                                            isMultiple={true}
                                            data={employeeList
                                                .sort((a, b) => a.numEmployeeID - b.numEmployeeID)
                                                .map((i) => ({
                                                    component: (
                                                        <Chip
                                                            icon={
                                                                i.EmployeeType === "Groomer" ? (
                                                                    <ContentCut style={{ fontSize: "15px", padding: "0 0 0 4px" }} />
                                                                ) : (
                                                                    <ShowerOutlinedIcon style={{ fontSize: "20px" }} />
                                                                )
                                                            }
                                                            size="small"
                                                            variant="filled"
                                                            color={i.EmployeeType === "Groomer" ? "info" : "secondary"}
                                                            label={i.varEmployeeFirstName + " " + i.varEmployeeLastName + " - " + i.EmployeeType}
                                                        />
                                                    ),
                                                    label: i.varEmployeeFirstName + " " + i.varEmployeeLastName + " (" + i.EmployeeType + ")",
                                                    value: i.numEmployeeID,
                                                    type: i.EmployeeType,
                                                }))}
                                            onChange={(e) => {
                                                let tempLocaArr = [] as any

                                                e.map((emp) => {
                                                    tempLocaArr.push({ id: emp.value, value: emp.label, component: emp.component })
                                                })

                                                setEmployees(tempLocaArr)
                                            }}
                                            placeHolder={"Employee"}
                                        />
                                    ) : null}
                                </div>

                                <div style={{ padding: "0 15px" }}>
                                    <p>Slot Time Intervals</p>
                                    {slotIntervals !== undefined && slotIntervals !== null ? (
                                        <div style={{ width: "100%" }}>
                                            <AdvancedDropDown
                                                data={slotIntervals.sort((a, b) => a.slotIntervalID - b.slotIntervalID).map((i) => ({ label: i.slotInterval, value: i.slotInterval }))}
                                                onChange={(e) => {
                                                    setSelectedSlotInterval(e?.value)
                                                }}
                                                ref={filterLocationRef}
                                                placeHolder={"Slot Time Interval"}
                                                passByID={true}
                                                value={selectedSlotInterval}
                                            />
                                        </div>
                                    ) : null}
                                </div>

                                <div style={{ padding: "0 15px" }}>
                                    <Button style={{ width: "100%" }} variant="contained" color="primary" onClick={() => searchWithFilters()}>
                                        Search
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </Collapse>
                {isNewBooking ? (
                    <div style={{ flex: 5, borderLeft: "1px solid gray" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                            <div style={{ backgroundColor: "#005df1", borderRadius: "0 50px 50px 0", padding: "0 4px" }}>
                                <IconButton onClick={() => toggleSidebar()} aria-label="delete" size="small">
                                    {visibleSidebar ? <ArrowBack style={{ fontSize: "28px", color: "#fff" }} /> : <ArrowForward style={{ fontSize: "28px", color: "#fff" }} />}
                                </IconButton>
                            </div>
                            <h2>Appointment Calendar</h2>
                        </div>
                        <div style={{ height: "80px" }}>
                            <div>
                                {currentCustomer === "" ? (
                                    <ProfileAppointment id={createdCustomer.numCustomerID} petList={petList} type={"bookingBlank"} />
                                ) : (
                                    <ProfileAppointment
                                        allSlots={(slots) => setAvailableSlots(slots)}
                                        id={createdCustomer.numCustomerID}
                                        customerBooking={createdCustomer}
                                        petList={petList}
                                        removeFromList={(petID) => removePetFromList(petID)}
                                        type={"booking"}
                                        currentLoc={currentLocation}
                                        pastSelectedAppointment={(e) => {
                                            setSelectedPastAppointment(e)
                                        }}
                                        pastAppList={(e) => {
                                            setPastAppointmentList(e)
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <AllCalendarBooking
                            slotList={availableSlots}
                            fromDate={fromDate}
                            toDate={toDate}
                            intervals={30}
                            customer={selectedCustomer}
                            location={selectedLocation}
                            pet={selectedPet}
                            employee={employees}
                            viewSummary={(e) => {}}
                        />
                    </div>
                ) : (
                    <div style={{ flex: 5, borderLeft: "1px solid gray" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                            <div style={{ backgroundColor: "#005df1", borderRadius: "0 50px 50px 0", padding: "0 4px" }}>
                                <IconButton onClick={() => toggleSidebar()} aria-label="delete" size="small">
                                    {visibleSidebar ? <ArrowBack style={{ fontSize: "28px", color: "#fff" }} /> : <ArrowForward style={{ fontSize: "28px", color: "#fff" }} />}
                                </IconButton>
                            </div>
                            <h2>Appointment Calendar</h2>
                        </div>
                        <AllCalendar
                            fromDate={fromDate}
                            toDate={toDate}
                            intervals={selectedSlotInterval}
                            customer={selectedCustomer}
                            location={selectedLocation}
                            pet={selectedPet}
                            employee={employees}
                            viewSummary={(e) => {}}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default NewCalendar
