import styles from "../../Help.module.scss"

function Administrator() {
    return (
        <div>
            <h3 style={{ fontWeight: "600", fontSize: "26px" }}> EsyPet Admin Help Files</h3>
            <div id={styles.container}>
                <p>This document describes the functions of the Administrators.</p>
                <p> Access to this module will be by means of a User Name and Password.</p>

                <div>
                    <img src={require("./assets/login.png")} alt="" />
                </div>

                <p>
                    {" "}
                    The Dashboard screen will appear, and at the left bottom of it will be a Settings icon , which gives access to the module that the Administrators are going to work on. The
                    following screen will appear, once the Settings icon has been clicked:
                </p>

                <div>
                    <img src={require("./assets/basic2.png")} alt="" />
                </div>

                <p>This is the area, which covers the functions of the Administrators.</p>
                <h4>
                    Administrators, please note that it is very important to take a backup of any of the files that are going to be replaced, before any changes are made, or files replaced. This is to
                    prevent any loss of vital information that the old files had, which the newer files might not have, or is not set within the correct parameters.
                </h4>
            </div>
        </div>
    )
}

export default Administrator
