import { useContext, useEffect, useRef, useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import axios from "../../../apis/axios"
import moment from "moment"
import { Button, Input } from "../../../components"
import { axiosPrivate } from "../../../apis/axios"
import AdvancedDropDown from "../../../components/AdvancedDropDown/AdvancedDropDown"
import { Grid, TextField, Tabs as MaterialTabs, Tab, Checkbox } from "@mui/material"
import { shortDate } from "../../../utils/DateFormatter"
import SlotSelector from "../../../components/SlotSelector/SlotSelector"
import LocationContext from "../../../utils/Context/LocationContext"
import { Map } from "../../../components/Map/Map"
import MapLoad from "../../../images/Mapload.jpg"
import { toast } from "react-toastify"
import styles from "./Inquiry.module.scss"
import ScheduleTab from "../../AdminPanel/Scheduler/Tabs/ScheduleTab/ScheduleTab"
import { Modal } from "../../../components/components"
import CloseButton from "../../../components/Modal/CloseButton/CloseButton"
import { Loop, Search } from "@mui/icons-material"
import CheckInModal from "../../../components/ExtModals/CheckInModal"
import ValidatePermission from "../../../components/ValidatePermission/ValidatePermission"

const Inquiry = (props: {}) => {
    const { selectedTopBarLocation, setSelectedTopBarLocation } = useContext<any>(LocationContext)

    const [slotList, setSlotList] = useState<any>([])
    const [currDate, setCurrDate] = useState<any>(moment().format("MM/DD/YYYY"))
    const [isLoading, setIsLoading] = useState(false)
    const [petTypeList, setPetTypeList] = useState<any>([])
    const [petType, setPetType] = useState<any>("")
    const [petBreed, setPetBreed] = useState<any>("")
    const [petBreedList, setPetBreedList] = useState<any>([])
    const [petSize, setPetSize] = useState<any>("")
    const [petSizeList, setPetSizeList] = useState<any>([])
    const [timeOfDayList, setTimeOfDayList] = useState<any>([])
    const [timeOfDay, setTimeOfDay] = useState<any>("")
    const [fromDate, setFromDate] = useState<any>("")
    const [toDate, setToDate] = useState<any>("")
    const [serviceList, setServiceList] = useState<any>([])
    const [employeeList, setEmployeeList] = useState<any>([])
    const [mapLocationList, setMapLocationList] = useState<any>([])
    const [employee, setEmployee] = useState<any>("")
    const [service, setService] = useState<any>("")
    const [center, setCenter] = useState<any>({})
    const [showMap, setShowMap] = useState(false)
    const myRefTimeOFDay = useRef<any>()
    const breedRef = useRef<any>()
    const ServicesRef = useRef<any>()
    const employeeRef = useRef<any>()
    const petTypeRef = useRef<any>()
    const petSizeRef = useRef<any>()

    const [openModal, setOpenModal] = useState(false)
    const [lng, setLng] = useState<any>("")
    const [lat, setLat] = useState<any>("")
    const [store, setStores] = useState<any>([])
    const [currentLocationIndex, setCurrentLocationIndex] = useState<any>("")
    const [storeDisplay, setStoresDisplay] = useState<any>([])
    const [bookingLocation, setBookingLocation] = useState<any>("")
    const [selectedLocation, setSelectedLocation] = useState()
    const [customerCurrentLocation, setCustomerCurrentLocation] = useState("8 Monroe Ave, Brentwood, NY 11717, USA")
    const [value, setValue] = useState(0)

    //Input output formats
    let inputObject = {
        petTypeID: 0,
        petBreedID: 0,
        petSizeID: 0,
        employeeID: 0,
        locationID: 0,
        timeOfDayID: 0,
        serviceItemID: 0,
        fromDate: "2023-05-11T16:04",
        toDate: "2023-06-11T16:04",
    }
    let outputObject = [
        {
            dtDateTimeFrom: "2023-05-11T06:20:16.645Z",
            dtDateTimeTo: "2023-05-11T07:00:16.645Z",
            numEmployeeID: 2,
            varEmployeeFirstName: "Fiona",
            varEmployeeLastName: "Floral",
            varColour: "#4287f5",
            numOccupationID: "Bather",
            varEmployeeImagePath: "1.jpg",
            duration: 20,
            numSCHScheduleID: 2,
        },
        {
            dtDateTimeFrom: "2023-05-11T06:10:16.645Z",
            dtDateTimeTo: "2023-05-11T09:20:16.645Z",
            numEmployeeID: 1,
            varEmployeeFirstName: "Sean",
            varEmployeeLastName: "Sakket",
            varColour: "#94eb13",
            numOccupationID: "Groomer",
            varEmployeeImagePath: "2.jpg",
            duration: 50,
            numSCHScheduleID: 1,
        },
    ]

    async function getPetTypes() {
        const result = await axiosPrivate.get(`PetType/GetAll`, {})
        return result.data.petType
    }
    async function getBreedByPetType(id) {
        const result = await axiosPrivate.get(`PetBreed/GetAllByPetTypeID/${id}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }
    async function getSizes(id) {
        const result = await axiosPrivate.get(`PetSize/GetAllByPetTypeID/${id}`, {
            headers: { "Content-Type": "application/json" },
        })
        return result.data
    }
    async function getAllScheduleTimeOfDay() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`TimeOfDay/GetAll`, {})
        return result.data.timeOfDay
    }
    async function getAllEmployee() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Employee/GetAll`)
        return result
    }
    async function getLocations() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Location/GetAll`, {})
        return result.data.location
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props

        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <p>{children}</p>}
            </div>
        )
    }
    useEffect(() => {
        if (petBreed !== "" && petSize !== "") {
            axios
                .post(`${process.env.REACT_APP_NODE_API_URL}GetLocationServiceItemsPriceList`, {
                    numLocationID: 1,
                    dtDate_of_service: currDate,
                    numPetBreedID: petBreed,
                    numPetSizeID: petSize,
                    numEmployeeID: 1,
                    varEmployeeName: "Ravin",
                    numPetID: null,
                })
                .then(function (response) {
                    setServiceList(response.data.msg[0])
                    setIsLoading(false)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }, [petBreed, petSize])

    useEffect(() => {
        getPetTypes()
            .then((res) => {
                setPetTypeList(res)
            })
            .catch((err) => {
                console.log(err)
            })
        getAllScheduleTimeOfDay()
            .then((response) => {
                setTimeOfDayList(response)
            })
            .catch((err) => {
                console.log(err)
            })
        getAllEmployee()
            .then((response) => {
                setEmployeeList(response.data.employee)
            })
            .catch((err) => {
                console.log(err)
            })

        //MAP Locations

        getLocations()
            .then((res) => {
                const allLocations = [...store]
                res.map((location) => {
                    allLocations.push({
                        longitude: parseFloat(location.varLocationLongitude),
                        latitude: parseFloat(location.varLocationLatitude),
                        title: location.varLocationName,
                        distance: "",
                        duration: "",
                        value: 0,
                        img: location.varLocationImagePath,
                        id: location.numLocationID,
                    })
                })
                setStores(allLocations)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (slotList.length > 0) {
            let baseLoc = {
                id: slotList[0].id,
                lng: slotList[0].longitude,
                lat: slotList[0].latitude,
                name: slotList[0].title,
                img: slotList[0].img,
            }
            setCenter(baseLoc)
            setMapLocationList(uniqueEmployeeLocations)
        }
        if (slotList.length < 1) {
        }
    }, [slotList])

    const getBreedSizeData = (id) => {
        getBreedByPetType(id)
            .then((res) => {
                setPetBreedList(res?.petBreed)
            })
            .catch((err) => {
                console.log(err)
            })
        getSizes(id)
            .then((res) => {
                setPetSizeList(res?.petSize)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const clearAll = () => {
        setPetType("")
        setPetSize("")
        setFromDate("")
        setToDate("")
        setPetBreed("")
        setEmployee("")
        setService("")
        setTimeOfDay("")
        myRefTimeOFDay.current?.handleClear()
        employeeRef.current?.handleClear()
        ServicesRef.current?.handleClear()
        breedRef.current?.handleClear()
        petSizeRef.current?.handleClear()
        petTypeRef.current?.handleClear()
        setShowMap(false)
        setServiceList([])
        setPetBreedList([])
        setPetSizeList([])
        setSlotList([])
    }

    function updateMorningDatesAndTimesInArray(inputArray) {
        function getRandomMorningDate(minHours, maxHours) {
            const currentDate = new Date()
            const randomHours = Math.floor(Math.random() * (maxHours - minHours + 1)) + minHours
            const randomMinutes = Math.floor(Math.random() * 180)

            currentDate.setHours(randomHours, randomMinutes, 0, 0)

            return currentDate
        }

        function updateMorningDatesAndTimes(obj) {
            const newObj = { ...obj }

            newObj.dtDateTimeFrom = getRandomMorningDate(8, 11)

            const minTimeDifference = 1
            const fromTime = new Date(newObj.dtDateTimeFrom)
            const randomMinutes = Math.floor(Math.random() * (180 - minTimeDifference + 1)) + minTimeDifference
            fromTime.setMinutes(fromTime.getMinutes() + randomMinutes)

            if (fromTime.getHours() >= 11) {
                fromTime.setHours(11, 59, 0, 0)
            }

            newObj.dtDateTimeTo = fromTime

            return newObj
        }
        const updatedArray = inputArray.map(updateMorningDatesAndTimes)

        return updatedArray
    }

    const fetchAvailableGroomers = () => {
        console.log(
            "petType:",
            petType,
            "petSize:",
            petSize,
            "petBreed:",
            petBreed,
            "Employee:",
            employee,
            "from Date:",
            fromDate,
            "to date:",
            toDate,
            "time of day:",
            timeOfDay,
            "Service:",
            service,
            "Location",
            selectedTopBarLocation
        )
        let inputArray = [
            {
                id: 2,
                longitude: -74.41271311689589,
                latitude: 40.55470398170224,
                title: "Lucky Paws Pet Grooming Huntington Village",
                distance: "",
                duration: "",
                value: 0,
                img: "11743",
                dtDateTimeFrom: "2023-06-12T07:00:00Z",
                dtDateTimeTo: "2023-06-12T09:00:00Z",
                numEmployeeID: 1,
                varEmployeeFirstName: "Sean",
                varEmployeeLastName: "Kingston",
                varColour: "#007aff",
                numOccupationID: "Groomer",
                varEmployeeImagePath: "https://xsgames.co/randomusers/avatar.php?g=male",
                taskDuration: 35,
                numSCHScheduleID: 1,
            },
            {
                id: 2,
                longitude: -74.41271311689589,
                latitude: 40.55470398170224,
                title: "Lucky Paws Pet Grooming Huntington Village",
                distance: "",
                duration: "",
                value: 0,
                img: "10.jpg",
                dtDateTimeFrom: "2023-06-13T08:00:00Z",
                dtDateTimeTo: "2023-06-13T09:00:00Z",
                numEmployeeID: 1,
                varEmployeeFirstName: "Sean",
                varEmployeeLastName: "Kingston",
                varColour: "#007aff",
                numOccupationID: "Groomer",
                varEmployeeImagePath: "https://xsgames.co/randomusers/avatar.php?g=male",
                taskDuration: 30,
                numSCHScheduleID: 10,
            },
            {
                id: 2,
                longitude: -73.7413509,
                latitude: 40.6000119,
                title: "Lucky Paws Pet Grooming Bethpage",
                distance: "",
                duration: "",
                value: 0,
                img: "10.jpg",
                dtDateTimeFrom: "2023-06-12T18:20:16.645Z",
                dtDateTimeTo: "2023-06-12T19:00:16.645Z",
                numEmployeeID: 2,
                varEmployeeFirstName: "Fiona",
                varEmployeeLastName: "Floral",
                varColour: "#007aff", //#fff04b
                numOccupationID: "Bather",
                varEmployeeImagePath: "https://xsgames.co/randomusers/avatar.php?g=female",
                taskDuration: 20,
                numSCHScheduleID: 2,
            },
            {
                id: 3,
                longitude: -73.7413509,
                latitude: 40.6000119,
                title: "Lucky Paws Pet Grooming Bethpage",
                distance: "",
                duration: "",
                value: 0,
                img: "1.jpg",
                dtDateTimeFrom: "2023-06-14T05:21:16.645Z",
                dtDateTimeTo: "2023-06-14T08:00:16.645Z",
                numEmployeeID: 4,
                varEmployeeFirstName: "Rick",
                varEmployeeLastName: "sanchez",
                varColour: "#007aff",
                numOccupationID: "Groomer",
                varEmployeeImagePath: "https://xsgames.co/randomusers/avatar.php?g=male",
                taskDuration: 30,
                numSCHScheduleID: 4,
            },
            {
                id: 3,
                longitude: -73.7413509,
                latitude: 40.6000119,
                title: "Lucky Paws Pet Grooming Bethpage",
                distance: "",
                duration: "",
                value: 0,
                img: "1.jpg",
                dtDateTimeFrom: "2023-06-15T16:21:16.645Z",
                dtDateTimeTo: "2023-06-15T20:00:16.645Z",
                numEmployeeID: 6,
                varEmployeeFirstName: "Dixie",
                varEmployeeLastName: "Bloom",
                varColour: "#007aff",
                numOccupationID: "Groomer",
                varEmployeeImagePath: "https://xsgames.co/randomusers/avatar.php?g=male",
                taskDuration: 50,
                numSCHScheduleID: 6,
            },
            {
                id: 3,
                longitude: -73.7413509,
                latitude: 40.6000119,
                title: "Lucky Paws Pet Grooming Bethpage",
                distance: "",
                duration: "",
                value: 0,
                img: "1.jpg",
                dtDateTimeFrom: "2023-06-15T05:21:16.645Z",
                dtDateTimeTo: "2023-06-15T08:00:16.645Z",
                numEmployeeID: 8,
                varEmployeeFirstName: "Martine",
                varEmployeeLastName: "Peris",
                varColour: "#007aff",
                numOccupationID: "Groomer",
                varEmployeeImagePath: "https://xsgames.co/randomusers/avatar.php?g=male",
                taskDuration: 40,
                numSCHScheduleID: 8,
            },
        ]
        const updatedArray = updateMorningDatesAndTimesInArray(inputArray)

        setSlotList(updatedArray)
    }

    const uniqueEmployeeLocations = Array.from(new Set(slotList.map((obj) => obj.numEmployeeID))).map((id) => {
        return slotList.find((obj) => obj.numEmployeeID === id)
    })

    const showOnMap = () => {
        if (slotList.length < 1) {
            toast.info("No search results found!")
        } else {
            setShowMap(true)
        }
    }

    const viewOnMap = () => {
        setOpenModal(true)
        if (store.length > 0) {
            let current_location_index = store.findIndex((x) => x.id === parseInt(selectedTopBarLocation))
            setCurrentLocationIndex(current_location_index)
        }
    }

    const searchLatAndLngByStreet = (street) => {
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ address: street }, (res: any, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                setLat(JSON.stringify(res[0]?.geometry.location.lat()))
                setLng(JSON.stringify(res[0]?.geometry.location.lng()))
                getDistance(JSON.stringify(res[0]?.geometry.location.lat()), JSON.stringify(res[0]?.geometry.location.lng()))
            }
        })
    }

    function getDistance(lati, long) {
        var address = new google.maps.LatLng(lati, long)
        let arr: any = []

        store.map((location, index) => {
            if (!isNaN(location.latitude) && !isNaN(location.longitude)) {
                var locAddress = new google.maps.LatLng(location.latitude, location.longitude)
                arr.push(locAddress)
            }
        })

        let distanceService = new google.maps.DistanceMatrixService()
        distanceService.getDistanceMatrix(
            {
                origins: [address],
                destinations: arr,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                drivingOptions: {
                    departureTime: new Date(),
                    trafficModel: google.maps.TrafficModel.BEST_GUESS,
                },
                avoidHighways: false,
                avoidTolls: false,
            },
            function (response, status) {
                if (status !== google.maps.DistanceMatrixStatus.OK) {
                    console.log("Error:", status)
                } else {
                    response?.rows[0].elements.map((item, index) => {
                        const editLoc = [...store]
                        let miles = item?.distance.value * 0.000621371192
                        let Display_distance = miles.toFixed(2) + " Miles"
                        editLoc[index].distance = Display_distance
                        editLoc[index].duration = item?.duration.text
                        editLoc[index].value = item?.distance.value
                        setStores(editLoc)
                    })
                }
            }
        )
    }

    useEffect(() => {
        if (store.length > 0) {
            let current_location_index = store.findIndex((x) => x.id === parseInt(selectedTopBarLocation))
            setStoresDisplay([])
            setCurrentLocationIndex(current_location_index)
            const currentSalonDataCleanup = [...store]
            currentSalonDataCleanup[current_location_index].distance = ""
            currentSalonDataCleanup[current_location_index].duration = ""
            setStores(currentSalonDataCleanup)
        }
        if (!isNaN(selectedTopBarLocation)) {
            setBookingLocation(parseInt(selectedTopBarLocation))
        }
    }, [selectedTopBarLocation])

    const BookingLocationSelect = (id) => {
        setBookingLocation(id)
    }

    const getDirections = (salon) => {
        let arr: any = []
        let obj: any = {}
        obj["lat"] = salon.latitude
        obj["lng"] = salon.longitude
        obj["title"] = salon.title
        obj["duration"] = salon.duration
        obj["distance"] = salon.distance
        obj["img"] = salon.img
        arr.push(obj)
        setSelectedLocation(arr)
    }

    const handleSelectLocation = () => {
        if (bookingLocation == "") {
            toast.info("Please select a location!")
        } else {
            setSelectedTopBarLocation(bookingLocation)
            setOpenModal(false)
        }
    }

    const fetchLocationInfo = () => {
        searchLatAndLngByStreet(customerCurrentLocation)
        if (store.length > 0) {
            let list_without_current_location = store.filter((x) => x.id !== parseInt(selectedTopBarLocation))
            let current_location_index = store.findIndex((x) => x.id === parseInt(selectedTopBarLocation))
            setStoresDisplay(list_without_current_location)
            setCurrentLocationIndex(current_location_index)
        }

        if (!isNaN(selectedTopBarLocation)) {
            setBookingLocation(parseInt(selectedTopBarLocation))
        }
    }

    const MainMapCenter = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        name: customerCurrentLocation,
    }

    function isValueNaN(obj, key) {
        return isNaN(obj[key])
    }

    return (
        <div style={{ margin: "4px 1.5rem 0" }}>
            <div style={{ padding: "3px 0 3px 0", display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px" }}>
                <div style={{ boxShadow: "rgba(0, 40, 103, 0.1) 2px 2px 16px", borderRadius: "8px" }}>
                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center", padding: "10px 10px 10px 10px" }}>
                        <Grid item md={2}>
                            {petTypeList !== undefined && petTypeList !== null ? (
                                <AdvancedDropDown
                                    data={petTypeList.map((i) => ({ label: i.varPetTypeName, value: i.numPetTypeID }))}
                                    onChange={(e) => {
                                        {
                                            setPetType(e.value)
                                        }
                                        {
                                            getBreedSizeData(e.value)
                                        }
                                    }}
                                    ref={petTypeRef}
                                    placeHolder={"Select Pets Type"}
                                    isDisabled={petTypeList.length > 0 ? undefined : true}
                                    passByID={true}
                                    value={petType}
                                />
                            ) : (
                                "Loading.."
                            )}
                        </Grid>
                        <Grid item md={2}>
                            {petSizeList !== undefined && petSizeList !== null ? (
                                <AdvancedDropDown
                                    data={petSizeList.map((i) => ({ label: i.varPetSizeName, value: i.varPetSizeName }))}
                                    onChange={(e) => {
                                        setPetSize(e?.value)
                                    }}
                                    ref={petSizeRef}
                                    placeHolder={"Pets Size"}
                                    isDisabled={petSizeList.length > 0 ? undefined : true}
                                    passByID={true}
                                    value={petSize}
                                />
                            ) : (
                                "Loading.."
                            )}
                        </Grid>
                        <Grid item md={2}>
                            {petBreedList !== undefined && petBreedList !== null ? (
                                <AdvancedDropDown
                                    data={petBreedList.map((i) => ({ label: i.varPetBreedName, value: i.numPetBreedID }))}
                                    onChange={(e) => {
                                        setPetBreed(e?.value)
                                    }}
                                    ref={breedRef}
                                    placeHolder={"Select Breed"}
                                    isDisabled={petBreedList.length > 0 ? undefined : true}
                                    passByID={true}
                                    value={petBreed}
                                />
                            ) : (
                                "Loading.."
                            )}
                        </Grid>
                        <Grid item md={2}>
                            {serviceList !== undefined && serviceList !== null ? (
                                <AdvancedDropDown
                                    data={serviceList.map((i) => ({
                                        label: i.ServiceType === "Main Service" ? `(M) ${i.varServiceItemName}` : `(A) ${i.varServiceItemName}`,
                                        value: i.numServiceItemID,
                                    }))}
                                    ref={ServicesRef}
                                    placeHolder={"Select a service"}
                                    onChange={(e) => {
                                        setService(e.value)
                                    }}
                                    isDisabled={serviceList.length > 0 ? undefined : true}
                                    passByID={true}
                                    value={service}
                                />
                            ) : null}
                        </Grid>
                        <Grid item md={2}>
                            {employeeList !== undefined && employeeList !== null ? (
                                <AdvancedDropDown
                                    data={employeeList.map((i) => ({ label: i.varEmployeeFirstName + " " + i.varEmployeeLastName, value: i.numEmployeeID }))}
                                    onChange={(e) => {
                                        setEmployee(e?.value)
                                    }}
                                    ref={employeeRef}
                                    placeHolder={"Select Employee"}
                                    isDisabled={employeeList.length > 0 ? undefined : true}
                                    passByID={true}
                                    value={employee}
                                />
                            ) : null}
                        </Grid>
                        <Grid item md={2}>
                            {timeOfDayList !== undefined && timeOfDayList !== null ? (
                                <AdvancedDropDown
                                    data={timeOfDayList.map((i) => ({ label: i.varTimeOfDay, value: i.numID }))}
                                    ref={myRefTimeOFDay}
                                    placeHolder={"Select Time Of Day"}
                                    onChange={(e) => {
                                        setTimeOfDay(e.value)
                                    }}
                                    passByID={true}
                                    value={timeOfDay}
                                />
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                        <Grid item md={2}>
                            <TextField
                                size="small"
                                fullWidth
                                required={true}
                                type={"datetime-local"}
                                variant="outlined"
                                onChange={(e) => {
                                    setFromDate(e.target.value)
                                }}
                                value={fromDate}
                                label={"From Date"}
                                inputProps={{
                                    min: shortDate(new Date()),
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <TextField
                                size="small"
                                fullWidth
                                required={true}
                                type={"datetime-local"}
                                variant="outlined"
                                onChange={(e) => {
                                    setToDate(e.target.value)
                                }}
                                value={toDate}
                                label={"To Date"}
                                inputProps={{
                                    min: shortDate(new Date()),
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={8}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    onClick={() => {
                                        viewOnMap()
                                    }}
                                    text={"Change Location"}
                                    iconRight={<Loop />}
                                ></Button>
                                <Button variant="contained" color="primary" type="submit" onClick={() => fetchAvailableGroomers()} text="&nbsp;Search&nbsp;" />
                                <Button
                                    variant="text"
                                    color="default"
                                    onClick={() => {
                                        clearAll()
                                    }}
                                    text={"Clear"}
                                ></Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div style={{ background: "#fff", boxShadow: "2px 2px 16px rgba(0, 40, 103, 0.1)", borderRadius: "8px", padding: "0 10px 5px 10px", marginBottom: "10px" }}>
                <MaterialTabs value={value} onChange={handleChange}>
                    <Tab className={styles.tabStyle} label="New Booking Inquiry" />
                    <Tab className={styles.tabStyle} label="Schedule" />
                </MaterialTabs>
            </div>
            <div style={{ background: "#fff", boxShadow: "rgba(0, 40, 103, 0.1) 2px 2px 16px", borderRadius: "8px" }}>
                <TabPanel value={value} index={0}>
                    <div style={{ display: "flex" }}>
                        <SlotSelector resultObj={slotList} />
                        <div style={{ height: "635px", width: "60%", backgroundImage: `url(${MapLoad})`, position: "relative" }}>
                            {showMap ? (
                                <Map
                                    location={center}
                                    salons={mapLocationList}
                                    directions={true}
                                    getDirection={false}
                                    getRoutes={false}
                                    isPickLocation={false}
                                    showBaseMarker={false}
                                    zoom={10}
                                    isSub={true}
                                ></Map>
                            ) : (
                                <div style={{ position: "absolute", margin: "0", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                    <ValidatePermission allowedModules={["03.07 - Directory / New Booking Inquiry"]} allowedSubRoutes={["Map View"]}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                showOnMap()
                                            }}
                                        >
                                            Load Map View
                                        </Button>
                                    </ValidatePermission>
                                    {/* <Switch checked={showMap} color="warning" onClick={()=>{showOnMap()}}/> */}
                                </div>
                            )}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel style={{ height: "70vh" }} value={value} index={1}>
                    <ScheduleTab
                        employeeList={employeeList}
                        employee={employee}
                        employeeChange={(e) => {
                            setEmployee(e)
                        }}
                    />
                </TabPanel>
            </div>
            <Modal open={openModal} borderless={true}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                    <div style={{ backgroundColor: "#fff", borderRadius: "12px" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "267px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <div>
                                    <div style={{ padding: "1rem", display: "flex", justifyContent: "flex-start", flexDirection: "column", gap: "0.5rem" }}>
                                        <p style={{ margin: "0", fontWeight: "500", fontSize: "12px", lineHeight: "20px", color: "#777E90" }}>Customer Location</p>
                                        <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }}>
                                            <Input
                                                value={customerCurrentLocation}
                                                size={"small"}
                                                outline={true}
                                                variant={"outlined"}
                                                onChange={(e) => {
                                                    setCustomerCurrentLocation(e)
                                                }}
                                            />
                                            <Button
                                                variant="outlined"
                                                color="default"
                                                onClick={() => {
                                                    fetchLocationInfo()
                                                }}
                                                children={<Search />}
                                            ></Button>
                                        </div>

                                        {currentLocationIndex !== undefined && currentLocationIndex !== null && currentLocationIndex !== "" && currentLocationIndex >= 0 ? (
                                            <>
                                                <p
                                                    style={{
                                                        margin: "0",
                                                        fontWeight: "500",
                                                        fontSize: "12px",
                                                        lineHeight: "20px",
                                                        color: "#777E90",
                                                        borderBottom: "1px solid #E5E7EB",
                                                        paddingBottom: "5px",
                                                    }}
                                                >
                                                    Current location
                                                </p>
                                                <div
                                                    className={store[currentLocationIndex].id === bookingLocation ? styles.locationContainerSelected : styles.locationContainer}
                                                    style={{ border: "1px solid #E5E7EB", borderRadius: "8px", width: "194px", height: "100%", background: "#F9FAFB", textAlign: "center" }}
                                                    onClick={() => {
                                                        {
                                                            getDirections(store[currentLocationIndex])
                                                        }
                                                        {
                                                            BookingLocationSelect(store[currentLocationIndex].id)
                                                        }
                                                    }}
                                                >
                                                    <p style={{ margin: "0", fontWeight: "600", fontSize: "14px", lineHeight: "20px", color: "#000000" }}>{store[currentLocationIndex].title}</p>
                                                    <p style={{ margin: "0", fontWeight: "400", fontSize: "14px", lineHeight: "20px", color: "#9CA3AF" }}>{store[currentLocationIndex].distance}</p>
                                                    <p style={{ margin: "0", fontWeight: "400", fontSize: "14px", lineHeight: "20px", color: "#9CA3AF" }}>{store[currentLocationIndex].duration}</p>
                                                </div>
                                            </>
                                        ) : null}
                                        <p style={{ margin: "0", fontWeight: "500", fontSize: "12px", lineHeight: "20px", color: "#777E90", borderBottom: "1px solid #E5E7EB", paddingBottom: "5px" }}>
                                            Closest Salons
                                        </p>
                                    </div>
                                    <div></div>
                                    <div className={styles.scrollViewCardContainer}>
                                        {storeDisplay
                                            .sort(({ value: a }, { value: b }) => a - b)
                                            .map((salon, index) => {
                                                return (
                                                    <div
                                                        className={salon.id === bookingLocation ? styles.locationContainerSelected : styles.locationContainer}
                                                        key={index}
                                                        style={{ border: "1px solid #E5E7EB", borderRadius: "8px", width: "194px", height: "100%", background: "#F9FAFB", textAlign: "center" }}
                                                        onClick={() => {
                                                            {
                                                                getDirections(salon)
                                                            }
                                                            {
                                                                BookingLocationSelect(salon.id)
                                                            }
                                                        }}
                                                    >
                                                        <p style={{ margin: "0", fontWeight: "600", fontSize: "14px", lineHeight: "20px", color: "#000000" }}>{salon.title}</p>
                                                        <p style={{ margin: "0", fontWeight: "400", fontSize: "14px", lineHeight: "20px", color: "#9CA3AF" }}>{salon.distance}</p>
                                                        <p style={{ margin: "0", fontWeight: "400", fontSize: "14px", lineHeight: "20px", color: "#9CA3AF" }}>{salon.duration}</p>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                                <div style={{ padding: "2rem 1.5rem", height: "fit-content", background: "#F3F4F6", borderRadius: "0 0 0 12px" }}>
                                    <Button
                                        className={styles.btnWidth}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            handleSelectLocation()
                                        }}
                                    >
                                        Select Location
                                    </Button>
                                </div>
                            </div>
                            <div style={{ height: "700px", width: "778px" }}>
                                {isValueNaN(MainMapCenter, "lat") ? (
                                    <div style={{ height: "700px", width: "100%", backgroundImage: `url(${MapLoad})`, position: "relative", borderRadius: "0 12px 12px 0" }}>
                                        <div className={styles.closeButton}>
                                            <CloseButton onClick={() => setOpenModal(!openModal)} />
                                        </div>
                                    </div>
                                ) : (
                                    <Map
                                        location={MainMapCenter}
                                        salons={store}
                                        directions={true}
                                        getRoutes={true}
                                        border={true}
                                        showBaseMarker={true}
                                        getDirection={selectedLocation}
                                        zoom={20}
                                        isSub={false}
                                    >
                                        <div className={styles.closeButton}>
                                            <CloseButton onClick={() => setOpenModal(!openModal)} />
                                        </div>
                                    </Map>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Inquiry
