import { Button, MenuItem, TextField } from "@mui/material"
import { useEffect, useState, useRef, useCallback } from "react"
import { EditorState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from "draftjs-to-html"
import { toast } from "react-toastify"
import BookingLocation from "../../components/Profile/BookingLocation"
import generatePDF from "../../utils/generatePDF"

const BulkMessaging = (props) => {
    const [queryType, setQueryType] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const [templateID, setTemplateId] = useState("")
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [data, setData] = useState({
        WellnessCheckID: 16,
        NotesForClient: "note for client",
        Suggestions: "for home",
        EvaluatorID: null,
        dtCreatedDate: "2024-02-22T07:43:49.5",
        path: "	http://test.esypet.com/media/directories/pets/",
        Wellness: [
            {
                BodyPartID: 1,
                WellnessCheckBodyPartName: "Eyes",
                WellnessCheckBodyPartDescription: "Eyes",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: "2023-06-27T05:21:20.13",
                Concerns: [],
                isAbnormal: true,
            },
            {
                BodyPartID: 2,
                WellnessCheckBodyPartName: "Ears",
                WellnessCheckBodyPartDescription: "Ears",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 5,
                        ConcernName: "Crusting or scabs",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 7,
                        ConcernName: "Dark discharge",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 14,
                        ConcernName: "Excessive Wax",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 19,
                        ConcernName: "Odor",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 24,
                        ConcernName: "Red",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 30,
                        ConcernName: "Swollen",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 3,
                WellnessCheckBodyPartName: "Mouth",
                WellnessCheckBodyPartDescription: "Mouth",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 1,
                        ConcernName: "Bad Odor",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 12,
                        ConcernName: "Excessive Tarter/Plaque on Teeth",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 27,
                        ConcernName: "Red Inflamed Gums",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 4,
                WellnessCheckBodyPartName: "Nose",
                WellnessCheckBodyPartDescription: "Nose",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 9,
                        ConcernName: "Dry Cracking",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 23,
                        ConcernName: "RDiscolored Discharge",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 28,
                        ConcernName: "Runny",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 5,
                WellnessCheckBodyPartName: "Feet - Paws",
                WellnessCheckBodyPartDescription: "Feet - Paws",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 11,
                        ConcernName: "Excessive Length",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 17,
                        ConcernName: "Nail Into Pad",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 18,
                        ConcernName: "NAILS - Broken Nail",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 6,
                WellnessCheckBodyPartName: "Feet - Nails",
                WellnessCheckBodyPartDescription: "Feet - Nails",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 2,
                        ConcernName: "Bad Odor",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 22,
                        ConcernName: "Raw from Licking",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 26,
                        ConcernName: "Red Discolored",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 7,
                WellnessCheckBodyPartName: "Coat / Hair",
                WellnessCheckBodyPartDescription: "Coat / Hair",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 4,
                        ConcernName: "Balding",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 8,
                        ConcernName: "Dry",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 16,
                        ConcernName: "Matted",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 21,
                        ConcernName: "Oily",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 8,
                WellnessCheckBodyPartName: "Skin",
                WellnessCheckBodyPartDescription: "Skin",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 3,
                        ConcernName: "Bad Odor",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 10,
                        ConcernName: "Dry Flaky",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 15,
                        ConcernName: "Hot Spots",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 20,
                        ConcernName: "Oily",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 29,
                        ConcernName: "Scabby",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                ],
                isAbnormal: true,
            },
        ],
        checkImageList: [
            {
                varImagePath: "2088.jpg",
            },
            {
                varImagePath: "1.jpg",
            },
            {
                varImagePath: "2.jpg",
            },
        ],
        RecommendedServices: [
            {
                ID: 1041,
                ServiceID: 1,
                varServiceItemName: "Full Groom",
                varServiceItemDescription: "Shampoo, Conditioner, Blueberry Facial, Nail Grinding, Bows or Bandana, Teeth Brushing",
            },
        ],
        RecommendedProducts: [
            {
                ID: 1040,
                ProductID: 5,
                varProductName: "ATESON Pet Grooming Hammock for XL Large Dogs",
                varProductDescription: "ATESON Pet Grooming Hammock for XL Large Dogs with Nail Clippers/Nail Trimmers/Grooming Scissors, Dog Grooming Harness for Nail Trimmin",
            },
            {
                ID: 14540,
                ProductID: 55,
                varProductName: "ATESON e Dogs",
                varProductDescription: "ATESON Pet Grooming l Trimmers/Grooming Scissors, Dog Grooming Harness for Nail Trimmin",
            },
        ],
        appointmentDetails: {
            appointmentID: 1042,
            location: "Syosset - ESyPet Pet Grooming",
            startDate: "2024-02-22T07:00:00",
            petName: "Besterr",
        },
    }) as any

    const [wellnessData, setWellnessData] = useState({
        WellnessCheckID: 16,
        NotesForClient: "note for client",
        Suggestions: "for home",
        EvaluatorID: null,
        dtCreatedDate: "2024-02-22T07:43:49.5",
        path: "	http://test.esypet.com/media/directories/pets/",
        Wellness: [
            {
                BodyPartID: 1,
                WellnessCheckBodyPartName: "Eyes",
                WellnessCheckBodyPartDescription: "Eyes",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: "2023-06-27T05:21:20.13",
                Concerns: [],
                isAbnormal: true,
            },
            {
                BodyPartID: 2,
                WellnessCheckBodyPartName: "Ears",
                WellnessCheckBodyPartDescription: "Ears",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 5,
                        ConcernName: "Crusting or scabs",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 7,
                        ConcernName: "Dark discharge",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 14,
                        ConcernName: "Excessive Wax",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 19,
                        ConcernName: "Odor",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 24,
                        ConcernName: "Red",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 30,
                        ConcernName: "Swollen",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 3,
                WellnessCheckBodyPartName: "Mouth",
                WellnessCheckBodyPartDescription: "Mouth",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 1,
                        ConcernName: "Bad Odor",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 12,
                        ConcernName: "Excessive Tarter/Plaque on Teeth",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 27,
                        ConcernName: "Red Inflamed Gums",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 4,
                WellnessCheckBodyPartName: "Nose",
                WellnessCheckBodyPartDescription: "Nose",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 9,
                        ConcernName: "Dry Cracking",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 23,
                        ConcernName: "RDiscolored Discharge",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 28,
                        ConcernName: "Runny",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 5,
                WellnessCheckBodyPartName: "Feet - Paws",
                WellnessCheckBodyPartDescription: "Feet - Paws",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 11,
                        ConcernName: "Excessive Length",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 17,
                        ConcernName: "Nail Into Pad",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 18,
                        ConcernName: "NAILS - Broken Nail",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 6,
                WellnessCheckBodyPartName: "Feet - Nails",
                WellnessCheckBodyPartDescription: "Feet - Nails",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 2,
                        ConcernName: "Bad Odor",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 22,
                        ConcernName: "Raw from Licking",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 26,
                        ConcernName: "Red Discolored",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 7,
                WellnessCheckBodyPartName: "Coat / Hair",
                WellnessCheckBodyPartDescription: "Coat / Hair",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 4,
                        ConcernName: "Balding",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 8,
                        ConcernName: "Dry",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 16,
                        ConcernName: "Matted",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 21,
                        ConcernName: "Oily",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                ],
                isAbnormal: true,
            },
            {
                BodyPartID: 8,
                WellnessCheckBodyPartName: "Skin",
                WellnessCheckBodyPartDescription: "Skin",
                bitWellnessCheckBodyPartsActive: true,
                numWellnessCheckBodyPartsCreatedBy: 1,
                dtWellnessCheckBodyPartsCreatedDate: null,
                Concerns: [
                    {
                        ConcernID: 3,
                        ConcernName: "Bad Odor",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 10,
                        ConcernName: "Dry Flaky",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                    {
                        ConcernID: 15,
                        ConcernName: "Hot Spots",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 20,
                        ConcernName: "Oily",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: false,
                    },
                    {
                        ConcernID: 29,
                        ConcernName: "Scabby",
                        bitWellnessCheckConcernsActive: true,
                        dtWellnessCheckConcernsCreatedDate: null,
                        numWellnessCheckConcernsCreatedBy: 1,
                        checked: true,
                    },
                ],
                isAbnormal: true,
            },
        ],
        checkImageList: [
            {
                varImagePath: "2088.jpg",
            },
            {
                varImagePath: "1.jpg",
            },
            {
                varImagePath: "2.jpg",
            },
        ],
        RecommendedServices: [
            {
                ID: 1041,
                ServiceID: 1,
                varServiceItemName: "Full Groom",
                varServiceItemDescription: "Shampoo, Conditioner, Blueberry Facial, Nail Grinding, Bows or Bandana, Teeth Brushing",
            },
        ],
        RecommendedProducts: [
            {
                ID: 1040,
                ProductID: 5,
                varProductName: "ATESON Pet Grooming Hammock for XL Large Dogs",
                varProductDescription: "ATESON Pet Grooming Hammock for XL Large Dogs with Nail Clippers/Nail Trimmers/Grooming Scissors, Dog Grooming Harness for Nail Trimmin",
            },
            {
                ID: 14540,
                ProductID: 55,
                varProductName: "ATESON e Dogs",
                varProductDescription: "ATESON Pet Grooming l Trimmers/Grooming Scissors, Dog Grooming Harness for Nail Trimmin",
            },
        ],
        appointmentDetails: {
            appointmentID: 1042,
            location: "Syosset - ESyPet Pet Grooming",
            startDate: "2024-02-22T07:00:00",
            petName: "Besterr",
        },
    })

    const handleGeneratePDF = async () => {
        const pdfBlob = await generatePDF(data)

        // Create a temporary anchor element
        const downloadLink = document.createElement("a")
        downloadLink.href = URL.createObjectURL(pdfBlob)
        downloadLink.download = "petList.pdf" // Specify the file name
        document.body.appendChild(downloadLink)

        // Trigger the download
        downloadLink.click()

        // Clean up
        document.body.removeChild(downloadLink)
        URL.revokeObjectURL(downloadLink.href)

        // Handle the PDF blob, e.g., download or further processing
    }

    return (
        <>
            {/* <div style={{ padding: '0 1.5rem' }}>
                <h2>Bulk Messaging</h2>           

                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch", marginTop: "0.6rem", paddingBottom:"1rem"}}>
                    <div style={{ display: 'flex', flexDirection: 'column' ,gap: '10px' }}>
                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Template ID
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>1 - Template Name one</MenuItem>
                            <MenuItem value={2}>2 - Template Name two</MenuItem>
                            <MenuItem value={3}>3 - Template Name three</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Customer (From)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        
                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Pet (From)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Booking Reference (From)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Location Reference (From)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Groomer (From)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Date (From)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                            InputLabelProps={{ shrink: true }}
                            type={'datetime-local'}
                        >
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Send Date
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                            type={'datetime-local'}
                        >
                        </TextField>
                    </div>
                    
                    <div>
    <div></div>
                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Customer (To)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Pet (To)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Booking Reference (To)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Location (To)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Groomer (To)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Example 1</MenuItem>
                            <MenuItem value={2}>Example 2</MenuItem>
                        </TextField>

                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Date (To)
                                    {(true) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={true}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                         
                            InputLabelProps={{ shrink: true }}   
                            value={queryType}
                            type={'datetime-local'}
                        >
                        </TextField>
                    </div>
                </div>

                <div>
                    <Button variant="contained" >Save Changes</Button>
                </div>
            </div>  */}

            {/* <BookingLocation/> */}
            <Button onClick={() => handleGeneratePDF()}>GEN</Button>
        </>
    )
}

export default BulkMessaging
