/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "numDepartmentID": 1,
    "varDepartmentName": "Accounting Department",
    "varDepartmentDescription": null,
    "bitDepartmentActive": true
*/

export const Department: {} = {
    tableName: "3.1 - Departments",
    hiddenColumns: ["numDepartmentID", "numPetTypeID"],
    pdfTemplate: ["varDepartmentName", "varDepartmentDescription", "", "bitActive"],
    template: [
        {
            Header: "Department Name",
            accessor: "varDepartmentName",
        },
        {
            Header: "Description",
            accessor: "varDepartmentDescription",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numDepartmentID",
            accessor: "numDepartmentID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numDepartmentID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varDepartmentName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varDepartmentDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
