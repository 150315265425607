import styles from "../../Help.module.scss";


function SalonDirectory (){

    return(
        
      <div>
      <h3 style={{ fontWeight: '900', fontSize: '26px' }}>  Salon Directory</h3>
         
         <div  id={styles.container}>
                  <div><p>The following features are available in this module:</p></div>
                  
                  <div><img src={require('./assets/salon.png')} alt=""/></div>
                  
                  <div>
                  <p>Salons and their locations could be seen in a drop-down list under Advanced filters.</p>
                  
                  <p>You could search for the salons that are specialized in each of the Services provided.</p>
                  </div>
                  <div style={{width:'400px',height:'100px',margin:'auto'}}><img src={require('./assets/salon2.png')} alt=""/></div>
                  
                  <div style={{marginTop:'50vh'}}>
                  <p> The functionality of this module is very similar to Customer Directory and Pet Directory. If you are unable to view any of the information you are searching for, click on the Reset Filters button and search again.</p>
                  
                  <p> The Map icon gives you a list of salons and their locations on Google Maps. This list of customers could be viewed as a Grid or as a List as well.</p>
                  
                  <p> The Directory information could be downloaded as an Excel file, or as the PDF document.</p>
                  
                  <p> More pages could be viewed by clicking the left and right arrows, found at the very bottom right-hand corner of the page.</p>
                  </div>
                  
                  <div style={{width:'400px',height:'100px',margin:'auto'}}><img src={require('./assets/salon3.png')} alt=""/></div>
                  
                  
                 
         </div>

   </div>
    );

}

export default SalonDirectory;