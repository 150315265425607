import React, { useState, useEffect } from "react"
import { SketchPicker } from "react-color"
import { Button } from "../../../../../../components"

function ColorPicker({ onColorChange, value }) {
    const [color, setColor] = useState(value || "#ff0000")
    const [showPicker, setShowPicker] = useState(false)

    const handleChange = (newColor) => {
        setColor(newColor.hex)
        onColorChange(newColor.hex)
    }

    useEffect(() => {
        setColor(value || "#ffffff")
    }, [value])

    useEffect(() => {
        if (showPicker) {
        }
    }, [showPicker])

    return (
        <div style={{ position: "relative" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div
                    style={{ backgroundColor: color, width: "100%", height: "36px", cursor: "pointer", border: "1px solid rgb(196 196 196 / 53%)", borderRadius: "4px" }}
                    onClick={() => setShowPicker(!showPicker)}
                ></div>
                <Button color="info" variant="outlined" text="Pick" onClick={() => setShowPicker(!showPicker)} />
            </div>

            {showPicker && (
                <div style={{ position: "absolute", zIndex: "1", top: "40px", left: 0 }}>
                    <SketchPicker color={color} onChange={handleChange} />
                </div>
            )}
        </div>
    )
}

export default ColorPicker
