import styles from './Breadcrumb.module.scss';
import { Text } from '../../components/components';

import { Link, useNavigate, useLocation } from 'react-router-dom';

export const Breadcrumb = () => {
    const location = useLocation() as any;
    let currentLink = ""

    let breadcrumbs = location.pathname.split('/').filter(val => val != '')
    
    return (
        <div className={styles.breadcrumbContainer}>            
            {                
                (breadcrumbs.length === 0) 
                ?
                    <Link className={styles.link} to={`/${currentLink}`}>
                        <Text weight='fw_600' color='p_700'>
                            Dashboard
                        </Text>
                    </Link>                                                       
                : 
                breadcrumbs.map((breadcrumb, index) => {
                    currentLink += `${breadcrumb}/`
                    return (
                        <div style={{display: 'flex'}} key={index}>
                            <Link className={styles.link} to={`/${currentLink}`}>
                                <Text weight='fw_600' color='p_700'>
                                    {breadcrumb}
                                </Text>
                            </Link>                           

                            {(breadcrumbs.length != index+1) ? <p>&nbsp;&nbsp;/&nbsp;&nbsp;</p> : null}                            
                        </div>                       
                    )
                })
            }            
        </div>
    );
}
