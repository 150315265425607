import dateFormat from "dateformat";

/* Format DateTime */
export const fullDate = (unformattedDate) => {
    return dateFormat(unformattedDate, "yyyy-mm-dd hh:mm:ss")
}

/* Messaging Time */
export const messagingDate = (unformattedDate) => {
    return dateFormat(unformattedDate, "hh:mm")
}

/* Format Date */
export const shortDate = (unformattedDate) => {
    return dateFormat(unformattedDate, "yyyy-mm-dd")
}

/* Format Expiry Date */
export const expiryDate = (unformattedDate) => {
    return dateFormat(unformattedDate, "yyyy-mm")
}

/* Format Expiry Date Check */
export const expiryDateCheck = (unformattedDate) => {
    let date1:any = new Date();
    let date2:any = (new Date(unformattedDate)).getTime();
    if(date1 > date2){
        return true
    }else{
        return false
    }
}