import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { PdfHeaders } from "./Validation";

export const PDFHandler = (exportData, tableName) => {
    const unit = "pt";
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    let doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = tableName;
    const headers = Object.keys(exportData?.[0])
    const newData:any = [];

    const HeadersDisplay:any = [];

    exportData.map(row => {
        newData.push(Object.values(row))
    })
    
    headers.map(row => {
        HeadersDisplay.push(PdfHeaders(row))
    })
    doc.text(title, marginLeft, 20);
   
    autoTable(doc, {
        startY: 40,
        head: [HeadersDisplay],
        body: newData,
    })

    

    doc.save(tableName.replace(/\s/g, '')+".pdf")
}