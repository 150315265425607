import axios from "../apis/axios"
import useAuth from "./useAuth"

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth() as any

    const refresh = async () => {
        const response = await axios.post("/Auth", JSON.stringify({ username: auth?.username, password: auth?.password }), {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        })

        setAuth((prev) => {
            return { ...prev, roles: auth?.roles, accessToken: response?.data?.varJwtToken, loggedIn: true }
        })
        return response?.data?.token
    }
    return refresh
}

export default useRefreshToken
