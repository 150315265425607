import { useState } from 'react';
import { MainNav, Breadcrumb } from '../../components/components'
import TopBar from '../../components/TopBar/TopBar';

import styles from './MainLayout.module.scss'

import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import LocationContext from '../../utils/Context/LocationContext';

const MainLayout = (props: {
    children?
}) => {

    const [selectedLocation, setSelectedLocation] = useState(1)

    return (
        <div style={{display: 'flex'}}>
              <ToastContainer/>
            <MainNav/>     
            <div className={styles.bodyContainer}>
            <LocationContext.Provider value={{  location: selectedLocation }}>
                <TopBar locationOnChange={(e)=>{setSelectedLocation(e)}}/>  
                <Breadcrumb/>
                <Outlet />         
                { props.children }
            </LocationContext.Provider>
            </div>
            
        </div>
    );
}

export default MainLayout;