import { Avatar } from "../components"
import NavLinks from "./NavLinks/NavLinks"
import logo from "../../images/logo.svg"

import styles from "./MainNav.module.scss"
import useAuth from "../../hooks/useAuth"
import { Tooltip } from "@mui/material"
const GOOGLE_MAP_KEY: any = process.env.REACT_VERSION

export const MainNav = (props) => {
    const { auth } = useAuth() as any

    /* TODO: Remoce thos */
    console.log(auth)

    return (
        <div className={styles.mainLayoutContainer}>
            <div className={styles.container}>
                <div className={styles.navItemContainer}>
                    <div className={styles.logoContainer}>
                        <img alt="logo-esypet" src={logo}></img>
                    </div>
                    <NavLinks />
                </div>
                <Tooltip
                    title={
                        <div style={{ padding: "5px" }}>
                            <p style={{ color: "#fff", margin: 0, padding: 0, fontSize: "16px" }}>
                                <span style={{ textTransform: "capitalize" }}>
                                    {auth?.employee?.varEmployeeFirstName} {auth?.employee?.varEmployeeLastName}
                                </span>{" "}
                                ({auth?.username})
                            </p>
                            <p style={{ color: "#fff", margin: 0, padding: 0, fontWeight: 500 }}>Current Location:</p>
                            <p style={{ color: "#fff", margin: 0, padding: 0 }}>{auth?.currentLocation?.label}</p>
                        </div>
                    }
                    placement="right"
                >
                    <div className={styles.profileContainer}>
                        <Avatar
                            className={styles.avatarContainer}
                            img={auth?.employee?.varEmployeeImagePath === null ? "" : `${process.env.REACT_APP_MEDIA_URL}directories/employees/${auth?.employee?.varEmployeeImagePath}`}
                        />
                    </div>
                </Tooltip>
                <div className={styles.box}>
                    <p className={styles.ver}>{process.env.REACT_APP_VERSION}</p>
                </div>
            </div>
        </div>
    )
}
