import { useEffect, useState } from 'react';
import { axiosPrivate } from "../../../../apis/axios";
import { toast } from "react-toastify";
import { Checkbox } from '@mui/material';
import { Button, Input } from '../../../components';

function Skills(props: {
    data?;
    mode?;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [specialization, setSpecialization] = useState<any>([]);

    async function getEmpByID() {
        setIsLoading(true)
        const result:any = await axiosPrivate.get(`Employee/GetByID/${props.data.numEmployeeID}`) 
        return result;
}

    async function getSkills() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Specialization/GetAll`) 
        return result;  
    }
    useEffect(() => {
        getEmpByID()
          .then(response_emp => {
            setIsLoading(false)
            getSkills()
            .then(response => {
                let newSpec = specialization         
                response?.data?.specialization.map(v =>{
                    if(response_emp.data.employee.employeeSpecialization.length > 0 ){

                        let matching = response_emp.data.employee.employeeSpecialization.some(x => x.numSpecializationID === v.numSpecializationID)
                        let index = response_emp.data.employee.employeeSpecialization.findIndex(x => x.numSpecializationID === v.numSpecializationID)
 
                            if(matching){     
                                newSpec.push({
                                    bitActive: v.bitActive,
                                    numEmployeeSpecializationID: v.numEmployeeSpecializationID,
                                    numEmployeeID: v.numEmployeeID,
                                    numSpecializationID: v.numSpecializationID,
                                    varSpecializationName: v.varSpecializationName,
                                    numYearsOfExperinace: response_emp.data.employee.employeeSpecialization[index].numYearsOfExperinace,
                                    numMonthsOfExperinace: response_emp.data.employee.employeeSpecialization[index].numMonthsOfExperinace,
                                    checked: (matching) ? true : false
                                })
                                setSpecialization(newSpec);
                                setIsLoading(false)
                            }else{
                                newSpec.push({
                                    bitActive: v.bitActive,
                                    numEmployeeSpecializationID: v.numEmployeeSpecializationID,
                                    numEmployeeID: v.numEmployeeID,
                                    numSpecializationID: v.numSpecializationID,
                                    varSpecializationName: v.varSpecializationName,
                                    numYearsOfExperinace: 0,
                                    numMonthsOfExperinace: 0,
                                    checked: (matching) ? true : false
                                })
                                setSpecialization(newSpec);
                                setIsLoading(false)
                            }       
                    }else{
                        newSpec.push({
                            bitActive: v.bitActive,
                            numEmployeeSpecializationID: v.numEmployeeSpecializationID,
                            numEmployeeID: v.numEmployeeID,
                            numSpecializationID: v.numSpecializationID,
                            varSpecializationName: v.varSpecializationName,
                            numYearsOfExperinace: 0,
                            numMonthsOfExperinace: 0,
                            checked:  false
                        })
                        setSpecialization(newSpec);
                        setIsLoading(false)
                    }
                })

            })
            .catch(err => {            
                console.log(err)
                toast.error("Something went wrong!");  
                setIsLoading(false)
            })
          })
          .catch(err => {            
              console.log(err)
              toast.error("Something went wrong!");  
              setIsLoading(false)
          })
    }, [])

    const handleSubmit = () =>{
        let arr:any = [];
        specialization.map((item)=>{
            if(item.checked == true){
                let obj = {
                    "numSpecializationID": item.numSpecializationID,
                    "numYearsOfExperinace": (isNaN(parseFloat(item.numYearsOfExperinace)))? 0 : parseFloat(item.numYearsOfExperinace),
                    "numMonthsOfExperinace": (isNaN(parseFloat(item.numMonthsOfExperinace)))? 0 : parseFloat(item.numMonthsOfExperinace)
                }
                  arr.push(obj)
            }
        })
  
        let payload = {
            "numEmployeeID": props.data.numEmployeeID,
            "empSpecialization":  (arr.length > 0)? arr : null,
        }
        saveData(payload)
        .then(res => {
           getEmpByID()
          .then(response_emp => {
            setIsLoading(false)
            getSkills()
            .then(response => {
                let newSpec = specialization         
                response?.data?.specialization.map(v =>{
                    if(response_emp.data.employee.employeeSpecialization.length > 0 ){

                        let matching = response_emp.data.employee.employeeSpecialization.some(x => x.numSpecializationID === v.numSpecializationID)
                        let index = response_emp.data.employee.employeeSpecialization.findIndex(x => x.numSpecializationID === v.numSpecializationID)
 
                            if(matching){     
                                newSpec.push({
                                    bitActive: v.bitActive,
                                    numEmployeeSpecializationID: v.numEmployeeSpecializationID,
                                    numEmployeeID: v.numEmployeeID,
                                    numSpecializationID: v.numSpecializationID,
                                    varSpecializationName: v.varSpecializationName,
                                    numYearsOfExperinace: response_emp.data.employee.employeeSpecialization[index].numYearsOfExperinace,
                                    numMonthsOfExperinace: response_emp.data.employee.employeeSpecialization[index].numMonthsOfExperinace,
                                    checked: (matching) ? true : false
                                })
                                setSpecialization(newSpec);
                                setIsLoading(false)
                            }else{
                                newSpec.push({
                                    bitActive: v.bitActive,
                                    numEmployeeSpecializationID: v.numEmployeeSpecializationID,
                                    numEmployeeID: v.numEmployeeID,
                                    numSpecializationID: v.numSpecializationID,
                                    varSpecializationName: v.varSpecializationName,
                                    numYearsOfExperinace: 0,
                                    numMonthsOfExperinace: 0,
                                    checked: (matching) ? true : false
                                })
                                setSpecialization(newSpec);
                                setIsLoading(false)
                            }       
                    }else{
                        newSpec.push({
                            bitActive: v.bitActive,
                            numEmployeeSpecializationID: v.numEmployeeSpecializationID,
                            numEmployeeID: v.numEmployeeID,
                            numSpecializationID: v.numSpecializationID,
                            varSpecializationName: v.varSpecializationName,
                            numYearsOfExperinace: 0,
                            numMonthsOfExperinace: 0,
                            checked:  false
                        })
                        setSpecialization(newSpec);
                        setIsLoading(false)
                    }
                })
                setSpecialization(newSpec);
                setIsLoading(false)
            })
            .catch(err => {            
                console.log(err)
                toast.error("Something went wrong!");  
                setIsLoading(false)
            })
          })
          .catch(err => {            
              console.log(err)
              toast.error("Something went wrong!");  
              setIsLoading(false)
          })
            toast.success("Employee skills updated successfully!");                                 
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            toast.error("Something went wrong when saving new employee!");   
        }) 
    }
    async function saveData(payload) {
        const result = await axiosPrivate.post('Employee/PostEmployeeSpecialization', JSON.stringify(payload))
        return result
    }
    return (
        <div>
          <p style={{color:"#23262F", fontWeight:"600", fontSize:"14px"}}>Skills</p> 
        <div>
            {(isLoading)? 'Loading': 
               specialization.filter((ele, ind)=> ind === specialization.findIndex(elem => elem.numSpecializationID === ele.numSpecializationID)).map((item,index)=>{
                return(
                    <div key={item.numSpecializationID} >
                        <div style={{display:"flex"}}>
                            <Checkbox style={{ padding: '0 .2rem 0 0'}} checked={item.checked} disabled={!props.mode} onChange={(e)=>{
                                
                                const triggerCheck = [...specialization]
                                triggerCheck[index].checked = !triggerCheck[index].checked
                                setSpecialization(triggerCheck)
                            
                            }}/>   
                        <p>{item.varSpecializationName}</p>
                        {(props.mode)? 
                            null 
                                :
                                (item.numYearsOfExperinace != "" && item.numMonthsOfExperinace != "")? 
                                <>
                                <p style={{marginLeft:"5px", fontWeight:"500"}}><span style={{color:"#1976d2", fontWeight:"600"}}>({item.numYearsOfExperinace})</span>{(item.numYearsOfExperinace > 1)? " Years" : " Year"}</p>
                                <p style={{marginLeft:"3px", fontWeight:"500"}}><span style={{color:"#1976d2", fontWeight:"600"}}>({item.numMonthsOfExperinace})</span> {(item.numMonthsOfExperinace > 1)? "Months" : "Month"} of experience</p>
                                </> 
                                    : 
                                (item.numYearsOfExperinace != "") ?  <p style={{marginLeft:"5px", fontWeight:"500"}}><span style={{color:"#1976d2", fontWeight:"600"}}>({item.numYearsOfExperinace})</span>{(item.numYearsOfExperinace > 1)? " Years" : " Year"}{(item.numMonthsOfExperinace == "")? " of experience": null}</p> : 
                                (item.numMonthsOfExperinace != "")? <p style={{marginLeft:"5px", fontWeight:"500"}}><span style={{color:"#1976d2", fontWeight:"600"}}>({item.numMonthsOfExperinace})</span> {(item.numMonthsOfExperinace > 1)? "Months" : "Month"}{(item.numYearsOfExperinace == "")?  " of experience": null}</p> : null
                        }
                        </div>
                        {(props.mode)?
                   <div style={{display:"flex", gap:"2rem", marginBottom:"1rem"}}>
                   <Input
                           onChange={(val) => {
                               const triggerCheck = [...specialization]
                               triggerCheck[index].numYearsOfExperinace = val
                               setSpecialization(triggerCheck)
                           }}
                           value={item.numYearsOfExperinace}
                           label={"Years of experience"}
                           type={'number'}
                           disabled={(props.mode)? false : true}
                       />
                       <Input
                           onChange={(val) => {
                               const triggerCheck = [...specialization]
                               triggerCheck[index].numMonthsOfExperinace = val
                               setSpecialization(triggerCheck)
                           }}
                           value={item.numMonthsOfExperinace}
                           label={"Months of experience"}
                           type={'number'}
                           disabled={(props.mode)? false : true}
                       /> 
                   </div> : null
                        }
                    </div>
                );
            })
            }
        </div>
        {
            (props.mode)?
                <div style={{textAlign: 'end', width: '100%', backgroundColor:"#EFF5FF", padding:"1rem", marginTop:"3rem"}}>
                <Button variant='contained' color='primary' onClick={handleSubmit}>Save</Button>
            </div>:null
        }
        </div>
    );
}

export default Skills