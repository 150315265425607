import { Button, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import AdvancedDropDown from "../../components/AdvancedDropDown/AdvancedDropDown";

const Messaging = (props) => {

    const [queryType, setQueryType] = useState("")

    const [templateID, setTemplateId] = useState("")
    const [smsBody, setSmsBody] = useState("")
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    return (
        <div style={{ padding: '0 1.5rem' }}>
            <h2>Template Creation</h2>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch", marginTop: "0.6rem", paddingBottom:"1rem"}}>
                
                <div>
                    <TextField
                        style={{ marginBottom: '20px' }}
                        onChange={(e) => setTemplateId(e.target.value)}
                        variant={'filled'}
                        fullWidth
                        label={
                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                Template ID
                                {(true) ?
                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    : null}
                            </div>
                        }
                        required={true}
                        inputProps={{
                            pattern: "^[a-zA-Z ]*$"
                        }}    
                        value={templateID}
                    ></TextField>

                    <TextField
                        onChange={(e) => setTemplateId(e.target.value)}
                        variant={'filled'}
                        fullWidth
                        label={
                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                Subject
                                {(true) ?
                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    : null}
                            </div>
                        }
                        required={true}
                        inputProps={{
                            pattern: "^[a-zA-Z ]*$"
                        }}    
                        value={templateID}
                    ></TextField>
                </div>

                <div></div>
                
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch", marginTop: "0.6rem", paddingBottom:"1rem"}}>
                <div>
                    <h3>Email Body</h3>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '10px' }}>
                      
                        <TextField 
                            onChange={(e) => setQueryType(e.target.value)}
                            select={true}
                            variant={'filled'}
                            fullWidth
                            label={
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    Select Dynamic Parameter
                                    {(false) ?
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                        : null}
                                </div>
                            }
                            required={false}
                            inputProps={{
                                pattern: "^[a-zA-Z ]*$"
                            }}                            
                            value={queryType}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={2}>No</MenuItem>
                        </TextField>
                        <Button variant="contained" >Insert&nbsp;Field</Button>
                    </div>
                  

                    <div style={{ backgroundColor: '#fff', border: '1px solid gray' }}>                    
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={setEditorState}
                        />               
                    </div>
                </div>
                
                {/* <div>
                    <h3>Email Body (HTML View)</h3>
                    <textarea
                        style={{ width: '100%' }}
                        disabled
                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                    />
                </div> */}
                 <div>
                    <h3>SMS Body</h3>
                    <textarea
                        style={{ width: '100%', height: '200px', fontSize: '14px' }}                        
                        value={smsBody}
                        onChange={(e) => setSmsBody(e.target.value)}
                    />
                </div>
            </div>

            {/* <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch", marginTop: "0.6rem", paddingBottom:"1rem"}}>                               
                <div>
                    <h3>SMS Body</h3>
                    <textarea
                        style={{ width: '100%', height: '200px', fontSize: '14px' }}                        
                        value={smsBody}
                        onChange={(e) => setSmsBody(e.target.value)}
                    />
                </div>

                <div>                                    
                </div>
            </div> */}

            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch", marginTop: "0.6rem", paddingBottom:"1rem"}}>
                <div style={{ display: 'flex', flexDirection: 'column' ,gap: '10px' }}>

                    <TextField 
                        onChange={(e) => setQueryType(e.target.value)}
                        select={true}
                        variant={'filled'}
                        fullWidth
                        label={
                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                Response Expected?
                                {(true) ?
                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    : null}
                            </div>
                        }
                        required={true}
                        inputProps={{
                            pattern: "^[a-zA-Z ]*$"
                        }}                            
                        value={queryType}
                    >
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={2}>No</MenuItem>
                    </TextField>
                </div>
                <div></div>
            </div>

            <div>
                <Button variant="contained" >Save Changes</Button>
            </div>
        </div>
    );
}

export default Messaging;