import * as React from "react";
import "./CustomerCard.sass";

import { Stack, Divider, IconButton } from "@mui/material";
import { Avatar, Chip } from "../";
import { Place, OpenInNew, ReportProblem, Vaccines } from "@mui/icons-material";
import { formatPhone } from "../../utils/PhoneFormatter";


export function CustomerCard(props: {
  CustomerImageURL?: string;
  Name?: string;
  Location?: string;
  Mobile?: string;
  Home?: string;
  Warning?: boolean;
  isVIP?: boolean;
  Pets?:
    | [
        {
          PetName?: string;
          PetBreed?: string;
          PetSize?: string;
          PetVaccinationStatus?: boolean;
          PetWarning?: boolean;
        }
      ]
    | any;
  OnOpenInNew?: CallableFunction | any;
}) {
  return (
    <>
      <div className="CustomerCard">
        <Stack direction="column" width="100%">
          <Stack direction="row" spacing={1}>
            <Stack direction="column" justifyContent="center">
              <Avatar
                size="medium"
                img={props.CustomerImageURL}
                className="CustomerImageURL"
              />
            </Stack>
            <Stack direction="column" width="100%">
              <Stack direction="row" justifyContent="space-between">
                <p className="name">{props.Name}</p>
               
              </Stack>
              <br />
              <Stack direction="row" alignItems="center">
                {props.Location === undefined ? null : (
                  <Chip
                    label={props.Location}
                    shape="square"
                    icon={<Place />}
                    customStyle="BlueFilled"
   
                  />
                )}
                {props.Warning ? (
                  <ReportProblem
                    className="ReportProblemIcon"
                    fontSize="large"
                  />
                ) : null}
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ mt: "15px", mb: "10px" }} />
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <div className="dataBlock">
              <label className="Title">Mobile</label>
              <p>
                <b>{formatPhone(props.Mobile)}</b>
              </p>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className="dataBlock">
              <label className="Title">Home</label>
              <p>
                <b>{formatPhone(props.Home)}</b>
              </p>
            </div>
          </Stack>
          {props.Pets === undefined ? null : (
            <>
              <Divider sx={{ mt: "15px", mb: "10px" }} />
              {props.Pets.map((pet) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Chip
                      label={pet?.PetSize}
                      shape="square"
                      customStyle="BlueFilled"
                      sx={{
                        height: "35px",
                        px: "10px",
                      }}
                    />
                    <Stack>
                      <p>
                        <b>{pet?.PetName}</b>
                      </p>
                      <label>{pet?.PetBreed}</label>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    {pet?.PetVaccinationStatus ? (
                      <Vaccines
                        className="ReportProblemIcon"
                        fontSize="large"
                      />
                    ) : null}
                    {pet?.PetWarning ? (
                      <ReportProblem
                        className="ReportProblemIcon"
                        fontSize="large"
                      />
                    ) : null}
                  </Stack>
                </Stack>
              ))}
            </>
          )}
        </Stack>
      </div>
    </>
  );
}
