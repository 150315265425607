import styles from "../../Help.module.scss";


function PetDirectory (){

    return(
        
      <div>
      <h3 style={{ fontWeight: '900', fontSize: '26px' }}> Pet Directory</h3>
         
         <div  id={styles.container}>
                  <p>The Pet Directory is very much similar in its functions to the Customer Directory.</p>
                  
                  <div><img src={require('./assets/pet.png')} alt=""/></div>
                  
                  <p>You could search by the customer’s first name, last name, pet’s name, pets type (Bird / Cat / Dog / Ferret / Guinea Pig / Rabbit) and Breeds (only available for Cats and Dogs). Searches could also be made, by selecting Pet Gender (Male / Female) and Pet Status (Active / Inactive).</p>
                  
                  <p>Like in Customer Directory, there is also a box to search for by using any of the information. at the top right-hand corner of the page. You could also search by column, by clicking the filter icon, which is located next to the search box.</p>
                  
                  <p> The list of customers could be viewed as a Grid or as a List. The Directory information could be downloaded as an Excel file, or as the PDF document.</p>
                  
                  <p> If you are unable to view any of the information you are searching for, click on the Reset Filters button and search again.</p>
                  
                  <p> More pages could be viewed by clicking the left and right arrows, found at the very bottom right-hand corner of the page.</p>
                  
                  
                  <div style={{width:'400px',height:'100px',margin:'auto'}}><img src={require('./assets/pet2.png')} alt=""/></div>
                  
                  
                 
         </div>

   </div>
    );

}

export default PetDirectory;