import { useState, useEffect } from "react"
import { Outlet } from "react-router-dom"
import useRefreshToken from "../hooks/useRefreshToken"
import useAuth from "../hooks/useAuth"
import { CircularProgress } from "@mui/material"

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken()
    const { auth } = useAuth() as any

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh()
            } catch (err) {
                console.error(err)
            } finally {
                setIsLoading(false)
            }
        }
        // !auth?.accessToken && !auth?.loggedIn ? verifyRefreshToken() : setIsLoading(false)
        setIsLoading(false)
    }, [])

    return (
        <>
            {isLoading ? (
                <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "2rem" }}>
                    <CircularProgress />
                </div>
            ) : (
                <Outlet />
            )}
        </>
    )
}

export default PersistLogin
