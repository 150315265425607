/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

export const Breed: {} = {
    tableName: "1.7 - Pure Breeds",
    hiddenColumns: ["numBreedID", "numPetTypeID"],
    pdfTemplate: ["numBreedID", "varBreedName", "varBreedDescription", "varPetTypeName", "bitActive"],
    template: [
        {
            Header: "Name",
            accessor: "varBreedName",
        },
        {
            Header: "Description",
            accessor: "varBreedDescription",
        },
        {
            Header: "Pet Type",
            accessor: "varPetTypeName",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numPetTypeID",
            accessor: "numPetTypeID",
        },
        {
            Header: "numBreedID",
            accessor: "numBreedID",
        },
    ],
    postFields: [
        {
            fieldName: "numBreedID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varBreedName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varBreedDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numPetTypeID",
            type: "advanced",
            inputType: "text",
            objectName: "PetType",
            required: true,
            value: "",
            altName: "numPetType",
        },
        {
            fieldName: "numAltBreedID",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
        {
            fieldName: "type",
            type: "static",
            inputType: "static",
            mainTable: "",
            required: true,
            value: "PURE",
        },
    ],
}
