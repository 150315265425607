/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "numPetColorID": 1,
    "varPetColorName": "Mix",
    "varPetColorDescription": null,
    "bitPetColorActive": null
*/

export const TimeOfDay: {} = {
    tableName: "5.4 - Time Of Day",
    hiddenColumns: ["numID"],
    pdfTemplate: ["varTimeOfDay", "tmFromTime", "tmToTime", "bitActive"],
    template: [
        {
            Header: "Name",
            accessor: "varTimeOfDay",
        },
        {
            Header: "Start Time",
            accessor: "tmFromTime",
        },
        {
            Header: "End Time",
            accessor: "tmToTime",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numID",
            accessor: "numID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varTimeOfDay",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "tmFromTime",
            type: "text",
            inputType: "time",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "tmToTime",
            type: "text",
            inputType: "time",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
