import { useState, useEffect } from "react";
import { Button, CloseButton } from "../../components";
import { Modal } from "../../components/components";
import styles from "./Invoice.module.scss";
import { S3Client } from "@aws-sdk/client-s3";
import axios from "axios";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";

// Set the AWS Region.
const REGION = "us-east-1"; //e.g. "us-east-1"
// Create an Amazon S3 service client object.
const creds = {
  accessKeyId: "AKIAXQN6N4QUDD47RQQB",
  secretAccessKey: "3WAeWPzq0MA5MGBNsHhPz+HfEOmXJSNwJpldxwSN",
};

const s3Client = new S3Client({
  region: REGION,
  credentials: creds,
});

const Report = (props: 
  { 
    isOpenReportInModal; 
    setIsOpenReportInModal: CallableFunction,
    WellnessCheckInID

  }) => {
  const [dataList, setDataList] = useState([]) as any[];

  const downloadTable = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#report" });
    doc.save("wellness_report.pdf");
  };

  useEffect(() => {
    if(props.isOpenReportInModal === true){
      getData();
    }
  }, [props.isOpenReportInModal]);

  const getData = () => {
    console.log("preWID", props.WellnessCheckInID)
    axios
      .get(`http://esypet.test.esyntaxis.com/api/v1/Wellness/GetWellnessByWelnessCheckID?WellnessCheckInID=${props.WellnessCheckInID}`)
      .then((result) => {
        console.log("data", result.data[0]);
        setDataList(result.data[0]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    console.log("d-List", dataList);
  }, [dataList]);

  return (
    <div>
      <Modal open={props.isOpenReportInModal} backgroundColor={"#F9FAFB"}>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "500px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
            <p style={{ fontSize: "28px", fontWeight: "600", color: "#192252" }}>Previous wellness report</p>
            <CloseButton onClick={() => props.setIsOpenReportInModal(false)} />
          </div>

          <div style={{ width: "100%", display: "flex", gap: "1rem", flexDirection: "column" }}>
            <div>
              <div style={{ width: "100%" }}>
                <table className={styles.invTable} id="report">
                  <thead>
                    <tr>
                      <th>BodyPartName</th>
                      <th>Description</th>
                      <th>Concerns</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList !== undefined ? (
                      dataList.Wellness !== undefined && dataList.Wellness.length > 0 ? (
                        dataList.Wellness.map((item, index) => {
                          return (
                            <tr
                              key={index}
                              style={{
                                backgroundColor: item.Concerns.length > 0 ? "#DCFCE7" : "",
                              }}
                            >
                              <td style={{ width: "25%" }}>{item.WellnessCheckBodyPartName}</td>
                              <td style={{ width: "40%" }}>{item.WellnessCheckBodyPartDescription}</td>
                              <td style={{ width: "100%" }}>
                                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                  {item.Concerns.map((concern, concernIndex) => {
                                    return (
                                      <tr key={concernIndex}>
                                        <td style={{ border: "1px solid black" }}>{concern.ConcernName}</td>
                                      </tr>
                                    );
                                  })}
                                </table>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No data</td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td>Loading</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div style={{ textAlign: "end", marginTop: "20px" }}>
                  <Button color="primary" variant="contained" text="Download Table" onClick={downloadTable} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Report;
