import { useEffect, useState } from 'react';
import { Button, CloseButton, Input } from '../../components';
import { Modal } from '../../components/components';
import axios, { axiosPrivate } from "../../apis/axios";
import { toast } from "react-toastify";
import Paper from '@mui/material/Paper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { expiryDateCheck, shortDate } from '../../utils/DateFormatter';
import { FileUploader } from 'react-drag-drop-files';
import { DeleteForever, FileUploadOutlined } from '@mui/icons-material';
import moment from 'moment';

const VaccineModal = (props: {
    isOpenVaccineModal;
    setIsOpenVaccineModal: CallableFunction;
    bookingDetails?
    isSuccess?
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [petVaccinations, setPetVaccinations] = useState<any>([]);
    const [vaccine, setVaccine] = useState<any>([]);
    const [isAddVaccinationOpen, setIsAddVaccinationOpen] = useState(false);
    const [selectedVaccine, setSelectedVaccine] = useState("");
    const [selectedVaccineEdit, setSelectedVaccineEdit] = useState("");
    const [givenDateEdit, setGivenDateEdit] = useState("");
    const [expireDateEdit, setExpireDateEdit] = useState("");
    const [recordFileEdit, setRecordFileEdit] = useState<any>("");
    const [selectedVaccineRecordIndex, setSelectedVaccineRecordIndex] = useState<any>("");
    const [showVaccineEdit, setShowVaccineEdit] = useState(false);
    const fileTypes = ["JPEG", "jpg", "WEBP", "pdf"];
    const [givenDate, setGivenDate] = useState("");
    const [expireDate, setExpireDate] = useState("");
    const [recordFile, setRecordFile] = useState<any>("");

    async function getVaccine() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`Vaccine/GetAll`) 
        return result;
    }
    
    async function getMedicalByPetID() {
        setIsLoading(true)
        const result:any = await axiosPrivate.get(`Pet/GetByID/${props.bookingDetails.Pet.PetID}`) 
        return result;
    }

    const readFile = (file) => {
        setRecordFile(file)
    }
    const readFileEdit = (file) => {
        setRecordFileEdit(file)
    }

    useEffect(()=>{
        console.log('bookingD', props.bookingDetails)
    },[props.bookingDetails])

    useEffect(() => {
        getVaccine()
        .then(response => {
            setVaccine(response.data.vaccine)
        })
        .catch(err => {            
            console.log(err)
            toast.error("Something went wrong!");  
            setIsLoading(false)
        })
        getMedicalByPetID()
        .then(response_pet => {
            setPetVaccinations(response_pet.data.pet.petVaccination)
        })
        .catch(err => {            
            console.log(err)
            toast.error("Something went wrong!");  
            setIsLoading(false)
        })
    }, [props.bookingDetails])

    useEffect(()=>{
        let newSpec = [] as any;       
        vaccine.map(v =>{
            if(petVaccinations.length > 0 ){
                let matching = petVaccinations.some(x => x.numVaccineID === v.numVaccineID)
                let index = petVaccinations.findIndex(x => x.numVaccineID === v.numVaccineID)
                    if(matching){     
                        newSpec.push({
                            bitActive: v.bitActive,
                            numVaccineID: v.numVaccineID,
                            varVaccineName: v.varVaccineName,
                            varVaccineDescription: v.varVaccineDescription,
                            dtVaccineExpireDate: petVaccinations[index].dtVaccineExpireDate,
                            dtVaccineGivenDate: petVaccinations[index].dtVaccineGivenDate,
                            varDocumentPath: petVaccinations[index].varDocumentPath,
                            varDocumentDisplayName: petVaccinations[index].varDocumentDisplayName,
                        })
                        setVaccine(newSpec);
                        setIsLoading(false)
                    }else{
                        newSpec.push({
                            bitActive: v.bitActive,
                            numVaccineID: v.numVaccineID,
                            varVaccineName: v.varVaccineName,
                            varVaccineDescription: v.varVaccineDescription,
                            dtVaccineExpireDate: 'Not administered',
                            dtVaccineGivenDate: "N/A",
                            varDocumentPath: null,
                            varDocumentDisplayName: null,
                        })
                        setVaccine(newSpec);
                        setIsLoading(false)
                    }       
            }else{
                newSpec.push({
                    bitActive: v.bitActive,
                    numVaccineID: v.numVaccineID,
                    varVaccineName: v.varVaccineName,
                    varVaccineDescription: v.varVaccineDescription,
                    dtVaccineExpireDate: 'Not administered',
                    dtVaccineGivenDate: "N/A",
                    varDocumentPath: null,
                    varDocumentDisplayName: null,
                })
                setVaccine(newSpec);
                setIsLoading(false)
            }
        })
    },[petVaccinations])

    const viewEditVaccine = (obj) =>{
        if(vaccine[obj].dtVaccineExpireDate === "Not administered"){
            setIsAddVaccinationOpen(true)
            setSelectedVaccine(vaccine[obj].numVaccineID)
        }else{
            console.log(vaccine[obj].numVaccineID, vaccine[obj].dtVaccineGivenDate)
            setSelectedVaccineEdit(vaccine[obj].numVaccineID)
            setGivenDateEdit(moment(vaccine[obj].dtVaccineGivenDate).format("YYYY-MM-DD"))
            setExpireDateEdit(moment(vaccine[obj].dtVaccineExpireDate).format("YYYY-MM-DD"))
            setSelectedVaccineRecordIndex(obj)
             setShowVaccineEdit(true)
        }
    } 

    const createVaccineRecord = () =>{
        console.log("herereree")
        setIsLoading(true)
        if(recordFile?.name == undefined){
            toast.info('Please select a file to upload!');
            setIsLoading(false)
        }else{
            let petID = props.bookingDetails.Pet.PetID;

            const formData = new FormData();
            formData.append('folderpath', `vaccination_records`);
            formData.append('method', "N");
            formData.append('deletingFileName', "-");
            formData.append('renameFileNames', "0");

            const fileUpload = recordFile;
            const renamedFile = new File([fileUpload], `${petID+'_'+selectedVaccine+'.'+ recordFile.name.split('.').pop()}`, { type: fileUpload.type });
            formData.append('files', renamedFile);
            console.log("re-FIle", renamedFile)

            axios.post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
            .then((response) => {
                console.log("Upload-Res", response);

                    let obj = {
                        "petVaccination": {
                            "bitActive": true,
                            "numCreatedBy": 0,
                            "varCreatedBy": "",
                            "dtCreatedDate": (new Date()).toISOString(),
                            "numEditedBy": 0,
                            "varEditedBy": "",
                            "dtEditedDate": (new Date()).toISOString(),
                            "numDeletedBy": 0,
                            "varDeletedBy": "",
                            "dtDeletedDate": (new Date()).toISOString(),
                            "numPetVaccinationID": 0,
                            "numPetID": petID,
                            "numVaccineID": selectedVaccine,
                            "dtVaccineGivenDate": givenDate,
                            "dtVaccineExpireDate": expireDate,
                            "varDocumentDisplayName": recordFile.name,
                            "varDocumentPath": petID+'_'+selectedVaccine+'.'+ recordFile.name.split('.').pop()
                          }     
                      }
                      saveVaccineRecord(obj)
            })
        }
    }

    async function saveVaccineRecord(data) {
        await axiosPrivate.post(`Pet/PostVaccination`, 
        JSON.stringify(data), 
        {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        ).then((result) => {
            if(result.status == 200){   
                getMedicalByPetID()
                .then(response_pet => {
                      setPetVaccinations(response_pet.data.pet.petVaccination)
                      console.log("vaccine", response_pet)
                      const vaccineExists = checkVaccineExists(response_pet.data.pet.petVaccination, 2);
                      if(vaccineExists === true){
                            props.isSuccess(true)
                      }
                })
                .catch(err => {            
                    console.log(err)
                    toast.error("Something went wrong!");  
                    setIsLoading(false)
                })
                toast.success(`Vaccination details successfully updated!`)
                reset()
                setIsLoading(false)
            }
        }).catch((err)=>{
            console.log(err);
            toast.error('Something went wrong!');
        })        
    }

    function checkVaccineExists(vaccines, id) {
        return vaccines.some(vaccine => vaccine.numVaccineID === id);
    }

    const reset = () =>{
        setRecordFile("")
        setGivenDate("")
        setExpireDate("")
        setSelectedVaccine("")
        setIsLoading(false)
        setIsAddVaccinationOpen(false)
        setSelectedVaccineEdit('')
        setExpireDateEdit('')
        setGivenDateEdit('')
        setRecordFileEdit('')
        setShowVaccineEdit(false)
        setIsAddVaccinationOpen(false)
    }

    const editVaccineRecord = () =>{
        let petID = props.bookingDetails.Pet.PetID;
        setIsLoading(true)
        if(recordFileEdit?.name == undefined){
            const allVaccines = [...vaccine];
            let obj = {
                "petVaccination": {
                    "bitActive": allVaccines[selectedVaccineRecordIndex].bitActive,
                    "numCreatedBy": 0,
                    "varCreatedBy": "",
                    "dtCreatedDate": "2022-09-20T08:00:20.163Z",
                    "numEditedBy": 0,
                    "varEditedBy": "",
                    "dtEditedDate": "2022-09-20T08:00:20.163Z",
                    "numDeletedBy": 0,
                    "varDeletedBy": "",
                    "dtDeletedDate": "2022-09-20T08:00:20.163Z",
                    "numPetVaccinationID": allVaccines[selectedVaccineRecordIndex].numPetVaccinationID,
                    "numPetID": props.bookingDetails.Pet.PetID,
                    "numVaccineID": selectedVaccineEdit,
                    "dtVaccineGivenDate": givenDateEdit,
                    "dtVaccineExpireDate": expireDateEdit,
                    "varDocumentDisplayName": allVaccines[selectedVaccineRecordIndex].varDocumentDisplayName,
                    "varDocumentPath": allVaccines[selectedVaccineRecordIndex].varDocumentPath
                  }     
              }
       
                saveVaccineRecord(obj)
        }else{

            const formData = new FormData();
            formData.append('folderpath', `vaccination_records`);
            formData.append('method', "N");
            formData.append('deletingFileName', "-");
            formData.append('renameFileNames', "0");
                  const renamedFile = new File([recordFileEdit], `${petID+'_'+selectedVaccineEdit+'.'+ recordFileEdit.name.split('.').pop()}`, { type: recordFileEdit.type });
                  formData.append('files', renamedFile);
                  console.log("re-FIle", renamedFile)


                  axios.post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
                  .then((response) => {
                      console.log("Upload-Res", response);
      
           
                      const allVaccines = [...vaccine];
                      let obj = {
                          "petVaccination": {
                              "bitActive": allVaccines[selectedVaccineRecordIndex].bitActive,
                              "numCreatedBy": 0,
                              "varCreatedBy": "",
                              "dtCreatedDate": (new Date()).toISOString(),
                              "numEditedBy": 0,
                              "varEditedBy": "",
                              "dtEditedDate": (new Date()).toISOString(),
                              "numDeletedBy": 0,
                              "varDeletedBy": "",
                              "dtDeletedDate": (new Date()).toISOString(),
                              "numPetVaccinationID": allVaccines[selectedVaccineRecordIndex].numPetVaccinationID,
                              "numPetID": petID,
                              "numVaccineID": selectedVaccineEdit,
                              "dtVaccineGivenDate": givenDateEdit,
                              "dtVaccineExpireDate": expireDateEdit,
                              "varDocumentDisplayName": recordFileEdit.name,
                              "varDocumentPath": petID+'_'+selectedVaccineEdit+'.'+ recordFileEdit.name.split('.').pop()
                            }     
                        }
                        saveVaccineRecord(obj)
                  })
        }
    }


    return (
        <div>
            <Modal open={props.isOpenVaccineModal} >
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem', padding: "0 1rem 0 1rem" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%", alignItems: "center", padding: "1rem" }}>
                        <div style={{ color: "#192252", fontSize: "24", fontWeight: "600" }}>Add vaccine records</div>
                        <CloseButton onClick={() => props.setIsOpenVaccineModal(false)} />
                    </div>

                    <div style={{ display: "flex" }}>

                    {
            (vaccine.length > 0)?
            <div style={{display: 'flex', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '1rem', rowGap: "1rem", marginTop: "0.6rem"}}>
            {
                (vaccine != null && vaccine != undefined)?
                 (vaccine != null && vaccine != undefined)?
                 <TableContainer component={Paper}>
                 <Table sx={{ minWidth: 600 }} aria-label="simple table">
                 <TableHead>
                     <TableRow>
                         <TableCell style={{color:"#002867", fontWeight:"600"}}>Type</TableCell>
                         <TableCell  style={{color:"#002867", fontWeight:"600"}} align="left">Given Date</TableCell>
                         <TableCell  style={{color:"#002867", fontWeight:"600"}} align="left">Expired On</TableCell>
                         <TableCell  style={{color:"#002867", fontWeight:"600"}} align="left">Document</TableCell>
                         <TableCell  style={{color:"#002867", fontWeight:"600"}} align="center">Actions</TableCell>
            
                     </TableRow>
                 </TableHead>
                     <TableBody>        
                         {                  
                            vaccine.map((row, index)=>{
                                 return <TableRow
                                     key={index}
                                     sx={{ '&:last-child td, &:last-child th': { border: 0 },   "& .MuiTableRow-root:hover": {
                                     backgroundColor: "primary.light"
                                     } }}>
                                         <TableCell align="left">{row.varVaccineName}</TableCell>
                                         <TableCell align="left">{(row.dtVaccineGivenDate === "N/A")? <p style={{color:"#CA8208"}}>{row.dtVaccineGivenDate}</p> : shortDate(row.dtVaccineGivenDate)}</TableCell>
                                         <TableCell align="left">{(row.dtVaccineExpireDate  == 'Not administered')? <p style={{color:"#CA8208"}}>Not administered</p> : <p>{(expiryDateCheck(row.dtVaccineExpireDate) == true)? <p style={{color:"red", fontWeight:"500"}}>{shortDate(row.dtVaccineExpireDate)} <span style={{color:"red", fontWeight:"500"}}> Expired</span></p> : <p>{shortDate(row.dtVaccineExpireDate)}</p>}</p>}</TableCell>
                                         <TableCell align="left" onClick={(row.dtVaccineExpireDate == 'Not administered')? ()=>{} : ()=>{window.open(`${process.env.REACT_APP_MEDIA_URL}vaccination_records/${row.varDocumentPath}`)}}><p style={{color:"#1e1eac", fontWeight:"500",cursor:"pointer"}}>{(row.dtVaccineExpireDate  == 'Not administered')? "N/A" : row.varDocumentDisplayName}</p></TableCell>
                                         <TableCell align="center" onClick={()=>{viewEditVaccine(index)}} style={{fontWeight:"600", cursor:"pointer", color:"#2076FF"}}>{(row.dtVaccineExpireDate  == 'Not administered')? "Add" : "Edit"}</TableCell>
                                 </TableRow>    
                             }) 
                         }                       
                     </TableBody>
                 </Table>
             </TableContainer>
             : null
             : <p style={{color: "rgb(119, 126, 144)", fontWeight: "400", fontSize: "12px", width: "600px"}}>No vaccine records found</p>
            }

            </div> : <p style={{fontSize: "12px", fontWeight: "400", color: "rgb(107, 114, 128)"}}>No vaccination records found</p>
            }

                    </div>
                    <div style={{ textAlign: 'end', width: '100%', backgroundColor: "#F3F4F6", padding: "1rem", borderRadius: "0 0 12px 12px" }}>
                        <Button variant='contained' color='primary' onClick={()=>{props.setIsOpenVaccineModal(false)}}>Close</Button>
                    </div>
                </div>

                {
                (isAddVaccinationOpen) ? 
                <Modal open={isAddVaccinationOpen} >
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', gap: '1rem', width:"500px"}}>   
                        <div style={{textAlign: 'end', width: '100%'}}>
                            <CloseButton onClick={()=>{setIsAddVaccinationOpen(false)}}/>
                        </div>            
                        <div style={{backgroundColor: '#fff', display: "flex", flexDirection:"column", width:"100%", gap:"2rem" }}>
                            <p style={{color:"#002867", fontSize:"18px", fontWeight:"600"}}>Add vaccination record</p>
                            <Input  items={vaccine.map((i) => ({ text: i.varVaccineName, value: i.numVaccineID }))} 
                                required={true} value={selectedVaccine} label={<p>Vaccine<span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span></p>} 
                                onChange={(e) => {{setSelectedVaccine(e)}} } 
                            />
                            <TextField                                          
                                style={{ width: '100%'}}
                                label= {
                                    <div style={{display: 'flex', fontSize: '14px'}}>
                                        Given Date
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    </div>
                                }
                                required={true}
                                focused={true}
                                type={'date'}
                                variant="filled"
                                onChange={(e)=>{setGivenDate(e.target.value)}}
                                value={givenDate}
                                inputProps={{
                                    max: shortDate(new Date())
                                }}
                            />
                            <TextField                                          
                                style={{ width: '100%'}}
                                label= {
                                    <div style={{display: 'flex', fontSize: '14px'}}>
                                        Expire Date
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    </div>
                                }
                                required={true}
                                focused={true}
                                type={'date'}
                                variant="filled"
                                onChange={(e)=>{setExpireDate(e.target.value)}}
                                value={expireDate}
                                inputProps={{
                                    min: shortDate(new Date())
                                }}
                            />
                {
                    (recordFile.name == undefined)?
                                <div
                                style={{
                                  border: "2px dashed #5f9cfe",
                                  borderRadius: "8px",
                                  padding: ".8rem",
                                  backgroundColor: "#fff",
                                }}
                              >
                                {
                                  <FileUploader
                                    handleChange={readFile}
                                    onDrop={readFile}
                                    disabled={isLoading ? true : false}
                                    name="file"
                                    types={fileTypes}
                                    multiple={false}
                                    label=""
                                    children={
                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                        <p style={{color:"#2076FF", fontWeight:"500"}}>
                                          Add vaccination proof document here
                                        </p>
                                      </div>
                                    }
                                  />
                                }
                              </div> : 
                              <div style={{display:"flex", alignItems:"center", gap:"0.7rem"}}>
                                <p style={{color:"#2076FF", fontSize:"14px", fontWeight:"400"}}>{recordFile.name}</p>
                                <p onClick={()=>{setRecordFile("")}} style={{margin:"0"}}><DeleteForever style={{color:"#4B5563"}}/></p>
                              </div>
                }

                        </div>
                            
                        <div style={{textAlign: 'end', width: '100%', backgroundColor:"#EFF5FF", padding:"1rem"}}>
                            <Button variant='text' color='transparent' onClick={()=>{reset()}}>Cancel</Button>
                            <Button variant='contained' color='primary' loading={isLoading}  onClick={()=>{createVaccineRecord()}} text={"Save"}/>
                        </div>          
                    </div>                
                </Modal>  : null
                }

{
                (showVaccineEdit) ? 
                <Modal open={showVaccineEdit} >
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', gap: '1rem', width:"500px"}}>   
                        <div style={{textAlign: 'end', width: '100%'}}>
                            <CloseButton onClick={()=>{setShowVaccineEdit(false)}}/>
                        </div>            
                        <div style={{backgroundColor: '#fff', display: "flex", flexDirection:"column", width:"100%", gap:"2rem" }}>
                            <p style={{color:"#002867", fontSize:"18px", fontWeight:"600"}}>Edit vaccination record</p>
                            <Input disabled={true}  items={vaccine.map((i) => ({ text: i.varVaccineName, value: i.numVaccineID }))} 
                                required={true} value={selectedVaccineEdit} label={<p>Vaccine<span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span></p>} 
                                onChange={(e) => {{setSelectedVaccineEdit(e)}} } 
                            />
                            <TextField                                          
                                style={{ width: '100%'}}
                                label= {
                                    <div style={{display: 'flex', fontSize: '14px'}}>
                                        Given Date
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    </div>
                                }
                                required={true}
                                focused={true}
                                type={'date'}
                                variant="filled"
                                onChange={(e)=>{setGivenDateEdit(e.target.value)}}
                                value={givenDateEdit}
                            />
                            <TextField                                          
                                style={{ width: '100%'}}
                                label= {
                                    <div style={{display: 'flex', fontSize: '14px'}}>
                                        Expire Date
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    </div>
                                }
                                required={true}
                                focused={true}
                                type={'date'}
                                variant="filled"
                                onChange={(e)=>{setExpireDateEdit(e.target.value)}}
                                value={expireDateEdit}
                            />
                {
                    (recordFileEdit.name == undefined)?
                                <div
                                style={{
                                  border: "2px dashed #5f9cfe",
                                  borderRadius: "8px",
                                  padding: ".8rem",
                                  backgroundColor: "#fff",
                                }}
                              >
                                {
                                  <FileUploader
                                    handleChange={readFileEdit}
                                    onDrop={readFileEdit}
                                    disabled={isLoading ? true : false}
                                    name="file"
                                    types={fileTypes}
                                    multiple={false}
                                    label=""
                                    children={
                                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                        <p style={{color:"#2076FF", fontWeight:"500"}}>
                                          Add vaccination proof document here
                                        </p>
                                      </div>
                                    }
                                  />
                                }
                              </div> : 
                              <div style={{display:"flex", alignItems:"center", gap:"0.7rem"}}>
                                <p style={{color:"#2076FF", fontSize:"14px", fontWeight:"400"}}>{recordFileEdit.name}</p>
                                <p onClick={()=>{setRecordFileEdit("")}} style={{margin:"0"}}><DeleteForever style={{color:"#4B5563"}}/></p>
                              </div>
                }

                        </div>
                        {
                       
                                <div style={{textAlign: 'end', width: '100%', backgroundColor:"#EFF5FF", padding:"1rem"}}>
                                    <Button variant='text' color='transparent' onClick={()=>{reset()}}>Cancel</Button>
                                    <Button variant='contained' color='primary' loading={isLoading}  onClick={()=>{editVaccineRecord()}} text={"Save Changes"}/>
                                </div> 
                   
                        } 
         
                    </div>                
                </Modal>  : null
                }



            </Modal>
        </div>
    );
}

export default VaccineModal;