import { useEffect, useMemo, useRef, useState } from "react"
import { Modal } from "../../../components/components"
import { Avatar, Button, Chip, CloseButton } from "../../../components"
import { FileUploader } from "react-drag-drop-files"
import { FileUploadOutlined } from "@mui/icons-material"
import { Cropper } from "react-cropper"
import { AdvancedInput } from "../../../components/AdvancedInput/AdvancedInput"
import { toast } from "react-toastify"
import axios, { axiosPrivate } from "../../../apis/axios"
import imageCompression from "browser-image-compression"
import { TextField } from "@mui/material"
import { shortDate } from "../../../utils/DateFormatter"
import InputMask from "react-input-mask"

const NewEmployee = (props: { setShowNewEmployeeModal: CallableFunction, isSuccess? }) => {
    const [isLoading, setIsLoading] = useState(false)

    const [showNewEmployeeModal, setShowNewEmployeeModal] = useState(false)

    const fileTypes = ["JPEG", "jpg", "WEBP", "PNG", "png"]
    const [loading, setLoading] = useState(false)
    const [fileSelected, setFileSelected] = useState(false)
    const [importedData, setImportedData] = useState<any>("")
    const [showCropper, setShowCropper] = useState(false)
    const cropperRef = useRef<HTMLImageElement>(null)
    const [cropper, setCropper] = useState<any>()
    const [imageName, setImageName] = useState<any>("")
    const [newHeaders, setNewHeaders] = useState<any>([])

    const [customerLoading, setCustomerLoading] = useState(false)

    const [newEmployeeObject, setNewEmployeeObject] = useState([
        {
            numEmployeeID: 0,
            varAltEmployeeID: "",
            varEmployeeAcct: "",
            numEmployeeDepartmentID: 0,
            numOccupationID: 0,
            varEmployeePerformanceCategory: "",
            varEmployeeRating: "",
            varEmployeeBC: "",
            varEmployeeRFID: "",
            numSalutationID: 0,
            varEmployeeFirstName: "",
            varEmployeeLastName: "",
            dtEmployeeDOB: "2024-01-04T10:28:37.855Z",
            bitPushNotificationEnabled: true,
            varEmployeeEmail: "",
            bitEmployeeNotificationEmail: true,
            numEmployeeNotificationEmailSequence: 0,
            varEmployeeHomePhone: "",
            bitEmployeeNotificationHomePhone: true,
            numEmployeeNotificationHomePhoneSequence: 0,
            varEmployeeMobilePhone: "",
            bitEmployeeNotificationMobilePhoneCall: true,
            numEmployeeNotificationMobilePhoneCallSequence: 0,
            bitEmployeeNotificationMobilePhoneSMS: true,
            numEmployeeNotificationMobilePhoneSMSSequence: 0,
            varEmployeeWorkPhone: "",
            bitEmployeeNotificationWorkPhone: true,
            numEmployeeNotificationWorkPhoneSequence: 0,
            numAddressTypeID: 0,
            varEmployeeAddress1: "",
            varEmployeeAddress2: "",
            varEmployeeCity: "",
            varEmployeeState: "",
            varEmployeeZip: "",
            varEmployeeCountry: "",
            varEmployeeAddressNote: "",
            numContactPersonID: 0,
            numContactTypeID: 0,
            varEmergContactFirstName: "",
            varEmergContactLastName: "",
            varEmergContactNumber: "",
            varEmployeeImagePath: "",
            varEmployeeCustomText1: "",
            varEmployeeCustomText2: "",
            dtEmployeeCustomDate1: "2024-01-04T10:28:37.855Z",
            dtEmployeeCustomDate2: "2024-01-04T10:28:37.855Z",
            numEmployeeCustomList1ID: 0,
            numEmployeeCustomList2ID: 0,
            bitEmployeeCustomBit1: true,
            bitEmployeeCustomBit2: true,
            bitActive: true,
            numUserID: 0,
            employeeTypeID: 0,
            numLocationID: 0,
        },
    ])

    const readFile = (files) => {
        if (files.size > 1000000) {
            toast.error(files.name + " File too large")
        } else {
            setImportedData(files)
            setShowCropper(true)
            setFileSelected(true)
            setImageName(files.name)
        }
    }

    const blobToFile = (theBlob: Blob, fileName: string) => {
        var b: any = theBlob
        b.lastModifiedDate = new Date()
        b.name = fileName
        return theBlob
    }

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            cropper
                .getCroppedCanvas({
                    imageSmoothingEnabled: false,
                })
                .toBlob((blob) => {
                    let toFile = blobToFile(blob, imageName)
                    let imageFile: any = toFile
                    let options = {
                        maxSizeMB: 0.5,
                        fileType: "image/jpeg",
                        maxWidthOrHeight: 200,
                        useWebWorker: true,
                        onProgress: (progress) => {
                            setLoading(true)
                            if (progress == 100) {
                                setLoading(false)
                            }
                        },
                    }
                    imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            setImportedData(compressedFile)
                            setShowCropper(false)
                        })
                        .catch(function (error) {
                            console.log(error.message)
                        })
                })
        }
    }

    async function getUpdatedHeaders(objectName) {
        const result = await axiosPrivate.get(`Label/${objectName}`)
        return result
    }

    async function saveData() {
        let sendObj = [newEmployeeObject[0]]
        console.log("emp obj", sendObj)
        const result = await axiosPrivate.post("Employee/PopulateEmployee", sendObj)
        return result
    }

    async function saveNewImage(employeeID, imgName) {
        const result = await axiosPrivate.post(`/Employee/UpdateEmployeeImages?EmployeeID=${employeeID}&ImagePath=${imgName}`)
        return result
    }

    async function createUserAccount(employeeID, email) {
        let payload = {
            varCustomerFirstName: "",
            varCustomerLastName: "",
            varCustomerEmail: "",
            varCustomerMobilePhone: "",
            varCustomerZip: "",
            varUserName: email,
            varUserPassword: "x#gyUoILzsD2",
            numEmployeeID: employeeID,
            numCustomerID: null,
        }

        const result = await axiosPrivate.post(`Auth/Register`, payload)
        return result
    }

    const saveChanges = () => {
        setCustomerLoading(true)

        var Form: any = document.getElementById("editForm")

        if (Form.checkValidity() == false) {
            console.log("invalid")
            setCustomerLoading(false)
        } else {
            upload()
            setCustomerLoading(false)
        }
    }

    const upload = () => {
        saveData()
            .then((res) => {
                console.log("adad", res?.data)
                let empLast = res?.data?.LastInsertedIDs[0]?.numEmployeeID
                let empEmail = newEmployeeObject[0]?.varEmployeeEmail

                if (empLast > 0) {
                    createUserAccount(empLast, empEmail)
                        .then((res) => {
                            if (!fileSelected) {
                                toast.success("Employee was updated successfully!")
                                setCustomerLoading(false)
                                props.isSuccess(true)
                            } else {
                                const formData = new FormData()
                                formData.append("folderpath", `directories/employees`)
                                formData.append("method", "N")
                                formData.append("deletingFileName", "-")
                                formData.append("renameFileNames", "0")

                                const file = importedData
                                const fileExtension = file.name.split(".").pop()
                                const renamedFile = new File([file], `${empLast}.${fileExtension}`, { type: file.type })
                                formData.append("files", renamedFile)

                                axios
                                    .post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
                                    .then((response) => {
                                        console.log(response)

                                        if (response.data.files.length > 0) {
                                            saveNewImage(empLast, empLast + "." + imageName.split(".").pop())
                                                .then((res) => {
                                                    toast.success("Employee was updated successfully!")
                                                    setCustomerLoading(false)
                                                    props.isSuccess(true)
                                                })
                                                .catch((error) => {
                                                    console.error(error)
                                                    toast.error("Something went wrong when saving new employee!")
                                                    setCustomerLoading(false)
                                                })
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error)
                                        toast.error("Something went wrong when saving new employee!")
                                        setCustomerLoading(false)
                                    })
                            }
                        })
                        .catch((err) => {
                            toast.error("Something went wrong when creating new employee!")
                        })
                } else {
                    toast.error("Something went wrong when saving new employee!")
                }
            })
            .catch((err) => {
                console.log("errr", err?.response?.data)

                if (err?.response?.data === "email address already exists") {
                    toast.error("Employee's email address is already registered!")
                } else {
                    toast.error("Something went wrong when saving new employee!")
                }
                setCustomerLoading(false)
            })
    }

    useEffect(() => {
        getUpdatedHeaders("Employee")
            .then((response) => {
                const header = {
                    employee: response?.data,
                }

                setNewHeaders(header)
                console.log("hello", response?.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }, [])

    const headingMapper = (dataValueIndex: string, type: string, objectType: string, valueType?: string) => {
        let labelName: string = ""
        let labelIsRequired: boolean = false

        if (newHeaders != undefined) {
            // console.log("heading mapper")
            if (Object.keys(newHeaders).length > 0) {
                let labelIndex = newHeaders[objectType]?.[type][dataValueIndex]?.varLabelValue

                let findIndex = newHeaders[objectType]?.[type].findIndex((value) => {
                    return value.varTblLabelName === dataValueIndex
                })

                labelName = newHeaders[objectType]?.[type][findIndex]?.varLabelValue
                labelIsRequired = newHeaders[objectType]?.[type][findIndex]?.bitRequired
            }
        }

        if (valueType === "isRequired") {
            return labelIsRequired
        } else {
            return labelName
        }
    }

    return (
        <div>
            <Modal open={true}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <p style={{ fontSize: "20px", fontWeight: "600", color: "#192252" }}>New Employee</p>
                        <CloseButton onClick={() => props?.setShowNewEmployeeModal(false)} />
                    </div>

                    <div style={{ backgroundColor: "#fff", width: "700px" }}>
                        <div style={{ display: "flex", gap: "1.5rem", flexDirection: "column", padding: "0 0 .8rem 0" }}>
                            <>
                                <div style={{ backgroundColor: "#F9FAFB" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", color: "#F9FAFB", padding: "16px 22px 16px 22px" }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column" }}>
                                            <div style={{ border: "2px solid #0062FF", borderRadius: "50%" }}>
                                                <Avatar
                                                    img={fileSelected ? URL.createObjectURL(importedData) : `${process.env.REACT_APP_MEDIA_URL}directories/employees/${importedData}`}
                                                    isVip={false}
                                                    className={"large"}
                                                    labelType={"largeLabel"}
                                                />
                                            </div>
                                            {newEmployeeObject[0]?.varEmployeeImagePath !== importedData ? (
                                                <p
                                                    style={{ color: "#005DF1", fontSize: "14px", fontWeight: "500", textAlign: "center", cursor: "pointer" }}
                                                    onClick={() => {
                                                        {
                                                            setImportedData(newEmployeeObject[0]?.varEmployeeImagePath)
                                                        }
                                                        {
                                                            setFileSelected(false)
                                                        }
                                                    }}
                                                >
                                                    Reset photo
                                                </p>
                                            ) : null}
                                        </div>
                                        <div style={{ border: "2px dashed #5f9cfe", borderRadius: "8px", padding: "2rem", backgroundColor: "#fff" }}>
                                            <FileUploader
                                                handleChange={readFile}
                                                onDrop={readFile}
                                                disabled={loading ? true : false}
                                                name="file"
                                                types={fileTypes}
                                                multiple={false}
                                                label=""
                                                children={
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                                        <p>Drag and drop or browse to choose a image</p>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Modal open={showCropper}>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                                        <div style={{ textAlign: "end", width: "100%" }}>
                                            <CloseButton
                                                onClick={() => {
                                                    {
                                                        setShowCropper(false)
                                                    }
                                                    {
                                                        setFileSelected(false)
                                                    }
                                                    setImportedData(newEmployeeObject[0]?.varEmployeeImagePath)
                                                }}
                                            />
                                        </div>

                                        <div style={{ backgroundColor: "#fff" }}>
                                            <Cropper
                                                style={{ maxWidth: "500px", maxHeight: "500px" }}
                                                modal={true}
                                                src={fileSelected ? URL.createObjectURL(importedData) : ""}
                                                cropBoxResizable={true}
                                                initialAspectRatio={1}
                                                aspectRatio={1}
                                                guides={true}
                                                minCropBoxHeight={200}
                                                scalable={false}
                                                minCropBoxWidth={200}
                                                highlight={true}
                                                zoomable={true}
                                                movable={true}
                                                onInitialized={(instance) => {
                                                    setCropper(instance)
                                                }}
                                                ref={cropperRef}
                                            />
                                        </div>
                                        <div style={{ textAlign: "end", width: "100%" }}>
                                            <Button variant="contained" color="primary" onClick={() => getCropData()}>
                                                Crop & Fix
                                            </Button>
                                        </div>
                                    </div>
                                </Modal>
                            </>

                            <div style={{ overflowY: "scroll", height: "400px" }}>
                                <form id="editForm" onSubmit={(e) => e.preventDefault()}>
                                    <p style={{ fontSize: "14px", fontWeight: 600, marginBottom: ".6rem" }}>Basic details</p>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <AdvancedInput
                                            isButton={true}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {headingMapper("numSalutationID", "standard", "employee")}
                                                    {headingMapper("numSalutationID", "standard", "employee", "isRequired") ? (
                                                        <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                            Required
                                                        </span>
                                                    ) : null}
                                                </div>
                                            }
                                            onChange={(e) => {
                                                const newValue = { ...newEmployeeObject }
                                                newValue[0].numSalutationID = e
                                                setNewEmployeeObject(newValue)
                                            }}
                                            ObjectName={"Salutation"}
                                            isCustom={false}
                                            Sequence={""}
                                            selectedValue={newEmployeeObject[0].numSalutationID}
                                        />
                                        <AdvancedInput
                                            isButton={true}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {headingMapper("numOccupationID", "standard", "employee")}
                                                    {headingMapper("numOccupationID", "standard", "employee", "isRequired") ? (
                                                        <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                            Required
                                                        </span>
                                                    ) : null}
                                                </div>
                                            }
                                            onChange={(e) => {
                                                const newValue = { ...newEmployeeObject }
                                                newValue[0].numOccupationID = e
                                                newValue[0].employeeTypeID = e
                                                setNewEmployeeObject(newValue)
                                            }}
                                            ObjectName={"Occupation"}
                                            isCustom={false}
                                            Sequence={""}
                                            selectedValue={newEmployeeObject[0].numOccupationID}
                                        />
                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varEmployeeFirstName", "standard", "employee")}
                                                        {!!headingMapper("varEmployeeFirstName", "standard", "employee", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varEmployeeFirstName", "standard", "employee", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newEmployeeObject }
                                                    newValue[0].varEmployeeFirstName = e.target.value
                                                    setNewEmployeeObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newEmployeeObject[0].varEmployeeFirstName}
                                            />
                                        </div>

                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varEmployeeLastName", "standard", "employee")}
                                                        {!!headingMapper("varEmployeeLastName", "standard", "employee", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={!!headingMapper("varEmployeeLastName", "standard", "employee", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newEmployeeObject }
                                                    newValue[0].varEmployeeLastName = e.target.value
                                                    setNewEmployeeObject(newValue)
                                                }}
                                                type={"advanced"}
                                                value={newEmployeeObject[0].varEmployeeLastName}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <div>
                                            <TextField
                                                inputProps={{
                                                    max: shortDate(new Date()),
                                                }}
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("dtEmployeeDOB", "standard", "employee")}
                                                        {!!headingMapper("dtEmployeeDOB", "standard", "employee", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                required={!!headingMapper("dtEmployeeDOB", "standard", "employee", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newEmployeeObject }
                                                    newValue[0].dtEmployeeDOB = e.target.value
                                                    setNewEmployeeObject(newValue)
                                                }}
                                                type={"date"}
                                                value={newEmployeeObject[0].dtEmployeeDOB}
                                            />
                                        </div>
                                        <div></div>
                                    </div>

                                    <p style={{ fontSize: "14px", fontWeight: 600, marginBottom: ".6rem" }}>Contact details</p>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <InputMask
                                            alwaysShowMask="true"
                                            mask="\(999\)999\-9999"
                                            maskChar="_"
                                            value={newEmployeeObject[0].varEmployeeMobilePhone}
                                            onChange={(e) => {
                                                const newValue = { ...newEmployeeObject }
                                                newValue[0].varEmployeeMobilePhone = e.target.value
                                                setNewEmployeeObject(newValue)
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    inputProps={{
                                                        pattern: !!headingMapper("varEmployeeMobilePhone", "standard", "employee", "isRequired")
                                                            ? "^[0-9()-]+$"
                                                            : newEmployeeObject[0].varEmployeeMobilePhone === "" || newEmployeeObject[0].varEmployeeMobilePhone === "(___)___-____"
                                                            ? null
                                                            : "^[0-9()-]+$",
                                                    }}
                                                    {...inputProps}
                                                    type="tel"
                                                    required={!!headingMapper("varEmployeeMobilePhone", "standard", "employee", "isRequired")}
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {headingMapper("varEmployeeMobilePhone", "standard", "employee")}
                                                            {!!headingMapper("varEmployeeMobilePhone", "standard", "employee", "isRequired") ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    variant="standard"
                                                />
                                            )}
                                        </InputMask>

                                        <InputMask
                                            alwaysShowMask="true"
                                            mask="\(999\)999\-9999"
                                            maskChar="_"
                                            value={newEmployeeObject[0].varEmployeeHomePhone}
                                            onChange={(e) => {
                                                const newValue = { ...newEmployeeObject }
                                                newValue[0].varEmployeeHomePhone = e.target.value
                                                setNewEmployeeObject(newValue)
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    inputProps={{
                                                        pattern: !!headingMapper("varEmployeeHomePhone", "standard", "employee", "isRequired")
                                                            ? "^[0-9()-]+$"
                                                            : newEmployeeObject[0].varEmployeeHomePhone === "" || newEmployeeObject[0].varEmployeeHomePhone === "(___)___-____"
                                                            ? null
                                                            : "^[0-9()-]+$",
                                                    }}
                                                    {...inputProps}
                                                    type="tel"
                                                    required={!!headingMapper("varEmployeeHomePhone", "standard", "employee", "isRequired")}
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {headingMapper("varEmployeeHomePhone", "standard", "employee")}
                                                            {!!headingMapper("varEmployeeHomePhone", "standard", "employee", "isRequired") ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    variant="standard"
                                                />
                                            )}
                                        </InputMask>
                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <InputMask
                                            alwaysShowMask="true"
                                            mask="\(999\)999\-9999"
                                            maskChar="_"
                                            value={newEmployeeObject[0].varEmployeeWorkPhone}
                                            onChange={(e) => {
                                                const newValue = { ...newEmployeeObject }
                                                newValue[0].varEmployeeWorkPhone = e.target.value
                                                setNewEmployeeObject(newValue)
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    inputProps={{
                                                        pattern: !!headingMapper("varEmployeeWorkPhone", "standard", "employee", "isRequired")
                                                            ? "^[0-9()-]+$"
                                                            : newEmployeeObject[0].varEmployeeWorkPhone === "" || newEmployeeObject[0].varEmployeeWorkPhone === "(___)___-____"
                                                            ? null
                                                            : "^[0-9()-]+$",
                                                    }}
                                                    {...inputProps}
                                                    type="tel"
                                                    required={!!headingMapper("varEmployeeWorkPhone", "standard", "employee", "isRequired")}
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {headingMapper("varEmployeeWorkPhone", "standard", "employee")}
                                                            {!!headingMapper("varEmployeeWorkPhone", "standard", "employee", "isRequired") ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    variant="standard"
                                                />
                                            )}
                                        </InputMask>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varEmployeeEmail", "standard", "employee")}
                                                        {!!headingMapper("varEmployeeEmail", "standard", "employee", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                required={!!headingMapper("varEmployeeEmail", "standard", "employee", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newEmployeeObject }
                                                    newValue[0].varEmployeeEmail = e.target.value
                                                    setNewEmployeeObject(newValue)
                                                }}
                                                type={"email"}
                                                value={newEmployeeObject[0].varEmployeeEmail}
                                            />
                                        </div>
                                    </div>

                                    <p style={{ fontSize: "14px", fontWeight: 600, marginBottom: ".6rem" }}>Department</p>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <AdvancedInput
                                            isButton={true}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {headingMapper("numEmployeeDepartmentID", "standard", "employee")}
                                                    {headingMapper("numEmployeeDepartmentID", "standard", "employee", "isRequired") ? (
                                                        <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                            Required
                                                        </span>
                                                    ) : null}
                                                </div>
                                            }
                                            onChange={(e) => {
                                                const newValue = { ...newEmployeeObject }
                                                newValue[0].numEmployeeDepartmentID = e
                                                setNewEmployeeObject(newValue)
                                            }}
                                            ObjectName={"Department"}
                                            isCustom={false}
                                            Sequence={""}
                                            selectedValue={newEmployeeObject[0].numEmployeeDepartmentID}
                                        />
                                    </div>

                                    <p style={{ fontSize: "14px", fontWeight: 600, marginBottom: ".6rem" }}>Default Salon</p>
                                    <p style={{ marginBottom: "15px" }}>Syosset Pet Grooming</p>

                                    <p style={{ fontSize: "14px", fontWeight: 600, marginBottom: ".6rem" }}>Emergency Contact Details</p>
                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <AdvancedInput
                                            isButton={true}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {headingMapper("numContactPersonID", "standard", "employee")}
                                                    {headingMapper("numContactPersonID", "standard", "employee", "isRequired") ? (
                                                        <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                            Required
                                                        </span>
                                                    ) : null}
                                                </div>
                                            }
                                            onChange={(e) => {
                                                const newValue = { ...newEmployeeObject }
                                                newValue[0].numContactPersonID = e
                                                setNewEmployeeObject(newValue)
                                            }}
                                            ObjectName={"ContactPerson"}
                                            isCustom={false}
                                            Sequence={""}
                                            selectedValue={newEmployeeObject[0].numContactPersonID}
                                        />
                                        <div></div>
                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varEmergContactFirstName", "standard", "employee")}
                                                        {!!headingMapper("varEmergContactFirstName", "standard", "employee", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                required={!!headingMapper("varEmergContactFirstName", "standard", "employee", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newEmployeeObject }
                                                    newValue[0].varEmergContactFirstName = e.target.value
                                                    setNewEmployeeObject(newValue)
                                                }}
                                                type={"text"}
                                                value={newEmployeeObject[0].varEmergContactFirstName}
                                            />
                                        </div>

                                        <div>
                                            <TextField
                                                variant="filled"
                                                style={{ width: "100%" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {headingMapper("varEmergContactLastName", "standard", "employee")}
                                                        {!!headingMapper("varEmergContactLastName", "standard", "employee", "isRequired") ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                required={!!headingMapper("varEmergContactLastName", "standard", "employee", "isRequired")}
                                                onChange={(e) => {
                                                    const newValue = { ...newEmployeeObject }
                                                    newValue[0].varEmergContactLastName = e.target.value
                                                    setNewEmployeeObject(newValue)
                                                }}
                                                type={"text"}
                                                value={newEmployeeObject[0].varEmergContactLastName}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridColumnGap: "2rem", rowGap: "2ch", marginBottom: "15px" }}>
                                        <AdvancedInput
                                            isButton={true}
                                            label={
                                                <div style={{ display: "flex", fontSize: "14px" }}>
                                                    {headingMapper("numContactTypeID", "standard", "employee")}
                                                    {headingMapper("numContactTypeID", "standard", "employee", "isRequired") ? (
                                                        <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                            Required
                                                        </span>
                                                    ) : null}
                                                </div>
                                            }
                                            onChange={(e) => {
                                                const newValue = { ...newEmployeeObject }
                                                newValue[0].numContactTypeID = e
                                                setNewEmployeeObject(newValue)
                                            }}
                                            ObjectName={"ContactType"}
                                            isCustom={false}
                                            Sequence={""}
                                            selectedValue={newEmployeeObject[0].numContactTypeID}
                                        />
                                        <InputMask
                                            alwaysShowMask="true"
                                            mask="\(999\)999\-9999"
                                            maskChar="_"
                                            value={newEmployeeObject[0].varEmergContactNumber}
                                            onChange={(e) => {
                                                const newValue = { ...newEmployeeObject }
                                                newValue[0].varEmergContactNumber = e.target.value
                                                setNewEmployeeObject(newValue)
                                            }}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    inputProps={{
                                                        pattern: !!headingMapper("varEmergContactNumber", "standard", "employee", "isRequired")
                                                            ? "^[0-9()-]+$"
                                                            : newEmployeeObject[0].varEmergContactNumber === "" || newEmployeeObject[0].varEmergContactNumber === "(___)___-____"
                                                            ? null
                                                            : "^[0-9()-]+$",
                                                    }}
                                                    {...inputProps}
                                                    type="tel"
                                                    required={!!headingMapper("varEmergContactNumber", "standard", "employee", "isRequired")}
                                                    style={{ width: "100%" }}
                                                    label={
                                                        <div style={{ display: "flex", fontSize: "14px" }}>
                                                            {headingMapper("varEmergContactNumber", "standard", "employee")}
                                                            {!!headingMapper("varEmergContactNumber", "standard", "employee", "isRequired") ? (
                                                                <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                    Required
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    variant="standard"
                                                />
                                            )}
                                        </InputMask>
                                    </div>

                                    <p style={{ fontSize: "14px", fontWeight: 600, marginBottom: ".6rem" }}>Personal Address</p>

                                    <>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
                                            <div style={{ display: "flex", alignItems: "center", gap: ".6rem" }}>
                                                <p>{`Primary Address`}</p>
                                                <Chip color={"success"} label="Default" size="small" variant="filled" />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div style={{ display: "flex", gap: "2rem" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <AdvancedInput
                                                            isButton={true}
                                                            label={"Type"}
                                                            required={true}
                                                            onChange={(e) => {
                                                                const newValue = { ...newEmployeeObject }
                                                                newValue[0].numAddressTypeID = e
                                                                setNewEmployeeObject(newValue)
                                                            }}
                                                            ObjectName={"AddressType"}
                                                            isCustom={false}
                                                            Sequence={""}
                                                            selectedValue={newEmployeeObject[0].numAddressTypeID}
                                                        />
                                                    </div>

                                                    <TextField
                                                        style={{ width: "100%", marginBottom: "1rem" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {"Street address"}
                                                                {true ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        required={true}
                                                        onChange={(e) => {
                                                            const newValue = { ...newEmployeeObject }
                                                            newValue[0].varEmployeeAddress1 = e.target.value
                                                            setNewEmployeeObject(newValue)
                                                        }}
                                                        type={"text"}
                                                        variant="filled"
                                                        value={newEmployeeObject[0].varEmployeeAddress1}
                                                    />
                                                </div>

                                                <div style={{ display: "flex", gap: "2rem" }}>
                                                    <TextField
                                                        style={{ width: "100%", marginBottom: "1rem" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {"Address line 2"}
                                                                {false ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        required={false}
                                                        onChange={(e) => {
                                                            const newValue = { ...newEmployeeObject }
                                                            newValue[0].varEmployeeAddress2 = e.target.value
                                                            setNewEmployeeObject(newValue)
                                                        }}
                                                        type={"text"}
                                                        variant="filled"
                                                        value={newEmployeeObject[0].varEmployeeAddress2}
                                                    />

                                                    <TextField
                                                        style={{ width: "100%", marginBottom: "1rem" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {"City"}
                                                                {true ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        required={true}
                                                        onChange={(e) => {
                                                            const newValue = { ...newEmployeeObject }
                                                            newValue[0].varEmployeeCity = e.target.value
                                                            setNewEmployeeObject(newValue)
                                                        }}
                                                        type={"text"}
                                                        variant="filled"
                                                        value={newEmployeeObject[0].varEmployeeCity}
                                                    />
                                                </div>

                                                <div style={{ display: "flex", gap: "2rem" }}>
                                                    <TextField
                                                        style={{ width: "100%", marginBottom: "1.5rem" }}
                                                        label={
                                                            <div style={{ display: "flex", fontSize: "14px" }}>
                                                                {"State"}
                                                                {true ? (
                                                                    <span
                                                                        style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}
                                                                    >
                                                                        Required
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        }
                                                        required={true}
                                                        onChange={(e) => {
                                                            const newValue = { ...newEmployeeObject }
                                                            newValue[0].varEmployeeState = e.target.value
                                                            setNewEmployeeObject(newValue)
                                                        }}
                                                        type={"text"}
                                                        variant="filled"
                                                        value={newEmployeeObject[0].varEmployeeState}
                                                    />

                                                    <InputMask
                                                        alwaysShowMask="true"
                                                        mask="99999"
                                                        maskChar="_"
                                                        value={newEmployeeObject[0].varEmployeeZip}
                                                        onChange={(e) => {
                                                            const newValue = { ...newEmployeeObject }
                                                            newValue[0].varEmployeeZip = e.target.value
                                                            setNewEmployeeObject(newValue)
                                                        }}
                                                    >
                                                        {(inputProps) => (
                                                            <TextField
                                                                {...inputProps}
                                                                type="tel"
                                                                required={true}
                                                                style={{ width: "100%" }}
                                                                label={
                                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                                        {"Postal/Zip Code"}
                                                                        {true ? (
                                                                            <span
                                                                                style={{
                                                                                    fontWeight: 500,
                                                                                    backgroundColor: "#FDEDED",
                                                                                    color: "#EF4444",
                                                                                    fontSize: "12px",
                                                                                    padding: "0 5px",
                                                                                    marginLeft: "5px",
                                                                                }}
                                                                            >
                                                                                Required
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                }
                                                                variant="standard"
                                                            />
                                                        )}
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <TextField
                                                multiline={true}
                                                style={{ width: "100%", marginBottom: "1.8rem" }}
                                                label={
                                                    <div style={{ display: "flex", fontSize: "14px" }}>
                                                        {"Notes"}
                                                        {false ? (
                                                            <span style={{ fontWeight: 500, backgroundColor: "#FDEDED", color: "#EF4444", fontSize: "12px", padding: "0 5px", marginLeft: "5px" }}>
                                                                Required
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                }
                                                required={false}
                                                onChange={(e) => {
                                                    const newValue = { ...newEmployeeObject }
                                                    newValue[0].varEmployeeAddressNote = e.target.value
                                                    setNewEmployeeObject(newValue)
                                                }}
                                                type={"text"}
                                                variant="filled"
                                                value={newEmployeeObject[0].varEmployeeAddressNote}
                                            />
                                        </div>
                                    </>

                                    <div style={{ textAlign: "end", width: "100%" }}>
                                        <Button onClick={() => saveChanges()} loading={customerLoading} variant="contained" color="primary" text="Create Employee" type={"submit"} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default NewEmployee
