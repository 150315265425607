import styles from "../../Help.module.scss";


function EmployeeDirectory (){

    return(
        
      <div>
      <h3 style={{ fontWeight: '900', fontSize: '26px' }}>Employee Directory</h3>
         
         <div  id={styles.container}>
                  <p>The Employee Directory has a list of all the employees and their job titles.</p>
                  
                  <p>You could search for employees by selecting any one of the job titles, employee specializations listed in this module. In addition, searches could be made, using years of experience and age.</p>
                  
                  <div><img src={require('./assets/employee.png')} alt=""/></div>
                  
                  <p>If you are unable to view any of the information you are searching for, click on the Reset Filters button and search again.</p>
                  
                 
         </div>

   </div>
    );

}

export default EmployeeDirectory;