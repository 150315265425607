import styles from './MedicalConditions.module.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { Modal, Button, Input } from '../../../components';
import { CloseButton } from '../../../CloseButton/CloseButton';
import { toast } from "react-toastify";
import axios, { axiosPrivate } from "../../../../apis/axios";
import { Checkbox, TextField } from '@mui/material';
import { DeleteForever, FileUploadOutlined } from '@mui/icons-material';
import { FileUploader } from "react-drag-drop-files";
import { shortDate, expiryDateCheck } from '../../../../utils/DateFormatter';
import { checkPermission, checkPermissionTabs } from '../../../../utils/permissions';


const MedicalConditions = (props: {
    data?;
    type;
    index?;
    mode?: boolean;
}) => {

    const [isAddMedicalOpen, setIsAddMedicalOpen] = useState(false);
    const [medicalCondition, setMedicalCondition] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [vaccine, setVaccine] = useState<any>([]);
    const [isAddVaccinationOpen, setIsAddVaccinationOpen] = useState(false);
    const fileTypes = ["JPEG", "jpg", "WEBP", "pdf"];
    const [selectedVaccine, setSelectedVaccine] = useState("");
    const [givenDate, setGivenDate] = useState("");
    const [expireDate, setExpireDate] = useState("");
    const [recordFile, setRecordFile] = useState<any>("");
    const [petVaccinations, setPetVaccinations] = useState<any>((props.type === "petProfile") ? props.data.petVaccination : (props.data?.customerPet.length > 0) ? props.data?.customerPet[(props.index === undefined) ? 0 : props.index].petVaccination : []);
    const [medicalConditionData, setMedicalConditionData] = useState<any>([]);
    const [showVaccineEdit, setShowVaccineEdit] = useState(false);

    //Vaccine record edit states
    const [selectedVaccineEdit, setSelectedVaccineEdit] = useState("");
    const [givenDateEdit, setGivenDateEdit] = useState("");
    const [expireDateEdit, setExpireDateEdit] = useState("");
    const [recordFileEdit, setRecordFileEdit] = useState<any>("");
    const [selectedVaccineRecordIndex, setSelectedVaccineRecordIndex] = useState<any>("");

    async function getVaccine() {
            setIsLoading(true)
            const result = await axiosPrivate.get(`Vaccine/GetAll`) 
            return result;
    }

    const createNewMedical = () => {
        if(medicalCondition == ""){
            toast.error("Please enter a medical condition to save!");
        }else{
            let obj = {
                "medicalCondition": {
                    "bitActive": true,
                    "numCreatedBy": 0,
                    "varCreatedBy": "string",
                    "dtCreatedDate": "2022-09-20T06:14:46.186Z",
                    "numEditedBy": 0,
                    "varEditedBy": "string",
                    "dtEditedDate": "2022-09-20T06:14:46.186Z",
                    "numDeletedBy": 0,
                    "varDeletedBy": "string",
                    "dtDeletedDate": "2022-09-20T06:14:46.186Z",
                    "numMedicalConditionID": 0,
                    "varMedicalConditionName": medicalCondition,
                    "varMedicalConditionDescription": ""
                }
              }       
            saveMedical(obj)
        }
    }

    useEffect(()=>{console.log('vacc', vaccine)},[vaccine])

    async function getMedicalByPetID() {
        setIsLoading(true)
        const result:any = await axiosPrivate.get(`Pet/GetByID/${(props.type === "petProfile") ? props.data.numPetID : (props.data?.customerPet.length > 0) ? props.data?.customerPet[(props.index === undefined) ? 0 : props.index].numPetID : ""}`) 
        return result;
    }

    async function saveMedical(data) {
        await axiosPrivate.post(`MedicalCondition`, 
        JSON.stringify(data), 
        {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        ).then((result) => {
            if(result.status == 200){        
                toast.success(`New medical condition successfully saved!`)
                setIsAddMedicalOpen(false)
                setMedicalCondition("")
                getMedicalByPetID()
                .then(response_pet => {
                  getMedical()
                  .then(response => {
                      let newSpec:any = []    
                      response?.data?.medicalCondition.map(v =>{
                          let matching
                          if(response_pet.data.pet.medicalCondition != null && response_pet.data.pet.medicalCondition != undefined){
                              matching = response_pet.data.pet.medicalCondition.some(x => x.numMedicalConditionID === v.numMedicalConditionID)
                          }else{
                              matching = false;
                          }     
                          newSpec.push({
                              bitActive: v.bitActive,
                              numMedicalConditionID: v.numMedicalConditionID,
                              varMedicalConditionName: v.varMedicalConditionName,
                              checked: (matching) ? true : false
                          })
                      })
                      setMedicalConditionData(newSpec);
                      setIsLoading(false)
                  })
                  .catch(err => {            
                      console.log(err)
                      toast.error("Something went wrong!");  
                      setIsLoading(false)
                  })
                })
                .catch(err => {            
                    console.log(err)
                    toast.error("Something went wrong!");  
                    setIsLoading(false)
                }) 
            }
        }).catch((err)=>{
            console.log(err);
            toast.error('Something went wrong!');
        })        
    }
    const readFile = (file) => {
        setRecordFile(file)
    }
    const readFileEdit = (file) => {
        setRecordFileEdit(file)
    }
    
    const createVaccineRecord = () =>{
        setIsLoading(true)
        if(recordFile?.name == undefined){
            toast.info('Please select a file to upload!');
            setIsLoading(false)
        }else{


            const formData = new FormData();
            formData.append('folderpath', `vaccination_records`);
            formData.append('method', "N");
            formData.append('deletingFileName', "-");
            formData.append('renameFileNames', "0");

            let petID = (props.type === "petProfile") ? props.data.numPetID : (props.data?.customerPet.length > 0) ? props.data?.customerPet[(props.index === undefined) ? 0 : props.index].numPetID : "";

            const fileUpload = recordFile;
            const renamedFile = new File([fileUpload], `${petID+'_'+selectedVaccine+'.'+ recordFile.name.split('.').pop()}`, { type: fileUpload.type });
            formData.append('files', renamedFile);
            console.log("re-FIle", renamedFile)

            axios.post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
            .then((response) => {
                console.log("Upload-Res", response);

                    let obj = {
                        "petVaccination": {
                            "bitActive": true,
                            "numCreatedBy": 0,
                            "varCreatedBy": "",
                            "dtCreatedDate": (new Date()).toISOString(),
                            "numEditedBy": 0,
                            "varEditedBy": "",
                            "dtEditedDate": (new Date()).toISOString(),
                            "numDeletedBy": 0,
                            "varDeletedBy": "",
                            "dtDeletedDate": (new Date()).toISOString(),
                            "numPetVaccinationID": 0,
                            "numPetID": petID,
                            "numVaccineID": selectedVaccine,
                            "dtVaccineGivenDate": givenDate,
                            "dtVaccineExpireDate": expireDate,
                            "varDocumentDisplayName": recordFile.name,
                            "varDocumentPath": petID+'_'+selectedVaccine+'.'+ recordFile.name.split('.').pop()
                          }     
                      }
                      saveVaccineRecord(obj)
            })
        }
    }

    async function saveVaccineRecord(data) {
        await axiosPrivate.post(`Pet/PostVaccination`, 
        JSON.stringify(data), 
        {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        ).then((result) => {
            if(result.status == 200){   
                getMedicalByPetID()
                .then(response_pet => {
                      setPetVaccinations(response_pet.data.pet.petVaccination)
                })
                .catch(err => {            
                    console.log(err)
                    toast.error("Something went wrong!");  
                    setIsLoading(false)
                })
                toast.success(`Vaccination details successfully updated!`)
                reset()
                setIsLoading(false)
            }
        }).catch((err)=>{
            console.log(err);
            toast.error('Something went wrong!');
        })        
    }

    const reset = () =>{
        setRecordFile("")
        setGivenDate("")
        setExpireDate("")
        setSelectedVaccine("")
        setIsLoading(false)
        setIsAddVaccinationOpen(false)
        setSelectedVaccineEdit('')
        setExpireDateEdit('')
        setGivenDateEdit('')
        setRecordFileEdit('')
        setShowVaccineEdit(false)
        setIsAddVaccinationOpen(false)
    }

    async function getMedical() {
        setIsLoading(true)
        const result = await axiosPrivate.get(`MedicalCondition/GetAll`) 
        return result;  
    }

    useEffect(() => {
        getVaccine()
        .then(response => {
            setVaccine(response.data.vaccine)
        })
        .catch(err => {            
            console.log(err)
            toast.error("Something went wrong!");  
            setIsLoading(false)
        })
        getMedicalByPetID()
        .then(response_pet => {
          getMedical()
          .then(response => {
              let newSpec:any = []
              setPetVaccinations(response_pet.data.pet.petVaccination)
              response?.data?.medicalCondition.map(v =>{
                  let matching
                  if(response_pet.data.pet.medicalCondition != null && response_pet.data.pet.medicalCondition != undefined){
                      matching = response_pet.data.pet.medicalCondition.some(x => x.numMedicalConditionID === v.numMedicalConditionID)
                  }else{
                      matching = false;
                  }     
                  newSpec.push({
                      bitActive: v.bitActive,
                      numMedicalConditionID: v.numMedicalConditionID,
                      varMedicalConditionName: v.varMedicalConditionName,
                      checked: (matching) ? true : false
                  })
              })
              setMedicalConditionData(newSpec);
              setIsLoading(false)
          })
          .catch(err => {            
              console.log(err)
              toast.error("Something went wrong!");  
              setIsLoading(false)
          })
        })
        .catch(err => {            
            console.log(err)
            toast.error("Something went wrong!");  
            setIsLoading(false)
        })
    }, [props.index, props.data])


    useEffect(()=>{
        let newSpec = [] as any;       
        vaccine.map(v =>{
            if(petVaccinations.length > 0 ){
                let matching = petVaccinations.some(x => x.numVaccineID === v.numVaccineID)
                let index = petVaccinations.findIndex(x => x.numVaccineID === v.numVaccineID)
                    if(matching){     
                        newSpec.push({
                            bitActive: v.bitActive,
                            numVaccineID: v.numVaccineID,
                            varVaccineName: v.varVaccineName,
                            varVaccineDescription: v.varVaccineDescription,
                            dtVaccineExpireDate: petVaccinations[index].dtVaccineExpireDate,
                            dtVaccineGivenDate: petVaccinations[index].dtVaccineGivenDate,
                            varDocumentPath: petVaccinations[index].varDocumentPath,
                            varDocumentDisplayName: petVaccinations[index].varDocumentDisplayName,
                        })
                        setVaccine(newSpec);
                        setIsLoading(false)
                    }else{
                        newSpec.push({
                            bitActive: v.bitActive,
                            numVaccineID: v.numVaccineID,
                            varVaccineName: v.varVaccineName,
                            varVaccineDescription: v.varVaccineDescription,
                            dtVaccineExpireDate: 'Not administered',
                            dtVaccineGivenDate: "N/A",
                            varDocumentPath: null,
                            varDocumentDisplayName: null,
                        })
                        setVaccine(newSpec);
                        setIsLoading(false)
                    }       
            }else{
                newSpec.push({
                    bitActive: v.bitActive,
                    numVaccineID: v.numVaccineID,
                    varVaccineName: v.varVaccineName,
                    varVaccineDescription: v.varVaccineDescription,
                    dtVaccineExpireDate: 'Not administered',
                    dtVaccineGivenDate: "N/A",
                    varDocumentPath: null,
                    varDocumentDisplayName: null,
                })
                setVaccine(newSpec);
                setIsLoading(false)
            }
        })
    },[petVaccinations])
    

    const handleSubmit = () =>{
        let arr:any = [];
        medicalConditionData.map((item)=>{
            if(item.checked == true){
                  arr.push(item.numMedicalConditionID)
            }
        })
  
        let payload = {
            "numPetID": (props.type === "petProfile") ? props.data.numPetID : (props.data?.customerPet.length > 0) ? props.data?.customerPet[(props.index === undefined) ? 0 : props.index].numPetID : "",
            "medicalCondition":  (arr.length > 0)? arr : null,
        }
        saveData(payload)
        
        .then(res => {
            toast.success("Pet medical conditions updated successfully!"); 
            getMedicalByPetID()
            .then(response_pet => {
              getMedical()
              .then(response => {
                  let newSpec:any = []    
                  response?.data?.medicalCondition.map(v =>{
                      let matching
                      if(response_pet.data.pet.medicalCondition != null && response_pet.data.pet.medicalCondition != undefined){
                          matching = response_pet.data.pet.medicalCondition.some(x => x.numMedicalConditionID === v.numMedicalConditionID)
                      }else{
                          matching = false;
                      }     
                      newSpec.push({
                          bitActive: v.bitActive,
                          numMedicalConditionID: v.numMedicalConditionID,
                          varMedicalConditionName: v.varMedicalConditionName,
                          checked: (matching) ? true : false
                      })
                  })
                  setMedicalConditionData(newSpec);
                  setIsLoading(false)
              })
              .catch(err => {            
                  console.log(err)
                  toast.error("Something went wrong!");  
                  setIsLoading(false)
              })
            })
            .catch(err => {            
                console.log(err)
                toast.error("Something went wrong!");  
                setIsLoading(false)
            })              
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            toast.error("Something went wrong when saving new pet details!");   
        }) 
    }

    async function saveData(payload) {
        const result = await axiosPrivate.post('Pet/PostMedicalCondition', JSON.stringify(payload))
        return result
    }

    const viewEditVaccine = (obj) =>{
        if(vaccine[obj].dtVaccineExpireDate === "Not administered"){
            setIsAddVaccinationOpen(true)
            setSelectedVaccine(vaccine[obj].numVaccineID)
        }else{
            setSelectedVaccineEdit(vaccine[obj].numVaccineID)
            setGivenDateEdit(vaccine[obj].dtVaccineGivenDate.split('T')[0])
            setExpireDateEdit(vaccine[obj].dtVaccineExpireDate.split('T')[0])
            setSelectedVaccineRecordIndex(obj)
             setShowVaccineEdit(true)
        }
    } 


    useEffect(()=>{console.log("petvacc", petVaccinations)},[petVaccinations])

    const editVaccineRecord = () =>{
        let petID = (props.type === "petProfile") ? props.data.numPetID : (props.data?.customerPet.length > 0) ? props.data?.customerPet[(props.index === undefined) ? 0 : props.index].numPetID : "";
        setIsLoading(true)
        if(recordFileEdit?.name == undefined){
            const allVaccines = [...vaccine];
            let obj = {
                "petVaccination": {
                    "bitActive": allVaccines[selectedVaccineRecordIndex].bitActive,
                    "numCreatedBy": 0,
                    "varCreatedBy": "",
                    "dtCreatedDate": "2022-09-20T08:00:20.163Z",
                    "numEditedBy": 0,
                    "varEditedBy": "",
                    "dtEditedDate": "2022-09-20T08:00:20.163Z",
                    "numDeletedBy": 0,
                    "varDeletedBy": "",
                    "dtDeletedDate": "2022-09-20T08:00:20.163Z",
                    "numPetVaccinationID": allVaccines[selectedVaccineRecordIndex].numPetVaccinationID,
                    "numPetID": (props.type === "petProfile") ? props.data.numPetID : (props.data?.customerPet.length > 0) ? props.data?.customerPet[(props.index === undefined) ? 0 : props.index].numPetID : "",
                    "numVaccineID": selectedVaccineEdit,
                    "dtVaccineGivenDate": givenDateEdit,
                    "dtVaccineExpireDate": expireDateEdit,
                    "varDocumentDisplayName": allVaccines[selectedVaccineRecordIndex].varDocumentDisplayName,
                    "varDocumentPath": allVaccines[selectedVaccineRecordIndex].varDocumentPath
                  }     
              }
       
                saveVaccineRecord(obj)
        }else{

            const formData = new FormData();
            formData.append('folderpath', `vaccination_records`);
            formData.append('method', "N");
            formData.append('deletingFileName', "-");
            formData.append('renameFileNames', "0");
                  const renamedFile = new File([recordFileEdit], `${petID+'_'+selectedVaccineEdit+'.'+ recordFileEdit.name.split('.').pop()}`, { type: recordFileEdit.type });
                  formData.append('files', renamedFile);
                  console.log("re-FIle", renamedFile)


                  axios.post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
                  .then((response) => {
                      console.log("Upload-Res", response);
      
           
                      const allVaccines = [...vaccine];
                      let obj = {
                          "petVaccination": {
                              "bitActive": allVaccines[selectedVaccineRecordIndex].bitActive,
                              "numCreatedBy": 0,
                              "varCreatedBy": "",
                              "dtCreatedDate": (new Date()).toISOString(),
                              "numEditedBy": 0,
                              "varEditedBy": "",
                              "dtEditedDate": (new Date()).toISOString(),
                              "numDeletedBy": 0,
                              "varDeletedBy": "",
                              "dtDeletedDate": (new Date()).toISOString(),
                              "numPetVaccinationID": allVaccines[selectedVaccineRecordIndex].numPetVaccinationID,
                              "numPetID": petID,
                              "numVaccineID": selectedVaccineEdit,
                              "dtVaccineGivenDate": givenDateEdit,
                              "dtVaccineExpireDate": expireDateEdit,
                              "varDocumentDisplayName": recordFileEdit.name,
                              "varDocumentPath": petID+'_'+selectedVaccineEdit+'.'+ recordFileEdit.name.split('.').pop()
                            }     
                        }
                        saveVaccineRecord(obj)
                  })
        }
    }
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <p className={styles.heading}>Medical conditions</p>
                {
                    (props?.mode === true) ? 
                        checkPermission(<p style={{color:"#2076FF", fontSize:"14px", fontWeight:"500", cursor:"pointer"}} onClick={()=>{setIsAddMedicalOpen(true)}}>Add medical condition</p>, 39)
                    : null
                }

              </div>  
                {
                    (isLoading)? "Loading..." :
                     medicalConditionData.map((item,index)=>{
                            return(
                                <div key={item.numMedicalConditionID} style={{display:"flex"}}>
                                <Checkbox style={{ padding: '0 .2rem 0 0'}} checked={item.checked} disabled={!props.mode} onChange={(e)=>{
                                    
                                    const triggerCheck:any = [...medicalConditionData]
                                    triggerCheck[index].checked = !triggerCheck[index].checked
                                    setMedicalCondition(triggerCheck)
                                }}/>   
                                <p>{item.varMedicalConditionName}</p>  
                                </div> 
                            );
                        })
                }
            {
                (isAddMedicalOpen) ? 
                <Modal open={isAddMedicalOpen} >
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', gap: '1rem', width:"500px"}}>   
                        <div style={{textAlign: 'end', width: '100%'}}>
                            <CloseButton onClick={()=>{setIsAddMedicalOpen(false)}}/>
                        </div>            
                        <div style={{backgroundColor: '#fff', display: "flex", flexDirection:"column", width:"100%", gap:"2rem" }}>
                            <p style={{color:"#002867", fontSize:"18px", fontWeight:"600"}}>Add medical condition</p>
                            <Input     
                                onChange={(val) => {
                                setMedicalCondition(val)
                                }}  
                                className={styles.appInput}
                                label={'Name of the condition'}
                                value={medicalCondition}
                                required={true}
                            />
                        </div>
                            
                        <div style={{textAlign: 'end', width: '100%', backgroundColor:"#EFF5FF", padding:"1rem"}}>
                            <Button variant='text' color='transparent'>Cancel</Button>
                            <Button variant='contained' color='primary' className={styles.buttonText} onClick={()=>{createNewMedical()}}>Create New</Button>
                        </div>          
                    </div>                
                </Modal>  : null
                }

                {
                (isAddVaccinationOpen) ? 
                <Modal open={isAddVaccinationOpen} >
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', gap: '1rem', width:"500px"}}>   
                        <div style={{textAlign: 'end', width: '100%'}}>
                            <CloseButton onClick={()=>{setIsAddVaccinationOpen(false)}}/>
                        </div>            
                        <div style={{backgroundColor: '#fff', display: "flex", flexDirection:"column", width:"100%", gap:"2rem" }}>
                            <p style={{color:"#002867", fontSize:"18px", fontWeight:"600"}}>Add vaccination record</p>
                            <Input  items={vaccine.map((i) => ({ text: i.varVaccineName, value: i.numVaccineID }))} 
                                required={true} value={selectedVaccine} label={<p>Vaccine<span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span></p>} 
                                onChange={(e) => {{setSelectedVaccine(e)}} } 
                            />
                            <TextField                                          
                                style={{ width: '100%'}}
                                label= {
                                    <div style={{display: 'flex', fontSize: '14px'}}>
                                        Given Date
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    </div>
                                }
                                required={true}
                                focused={true}
                                type={'date'}
                                variant="filled"
                                onChange={(e)=>{setGivenDate(e.target.value)}}
                                value={givenDate}
                                inputProps={{
                                    max: shortDate(new Date())
                                }}
                            />
                            <TextField                                          
                                style={{ width: '100%'}}
                                label= {
                                    <div style={{display: 'flex', fontSize: '14px'}}>
                                        Expire Date
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    </div>
                                }
                                required={true}
                                focused={true}
                                type={'date'}
                                variant="filled"
                                onChange={(e)=>{setExpireDate(e.target.value)}}
                                value={expireDate}
                                inputProps={{
                                    min: shortDate(new Date())
                                }}
                            />
                {
                    (recordFile.name == undefined)?
                                <div
                                style={{
                                  border: "2px dashed #5f9cfe",
                                  borderRadius: "8px",
                                  padding: ".8rem",
                                  backgroundColor: "#fff",
                                }}
                              >
                                {
                                  <FileUploader
                                    handleChange={readFile}
                                    onDrop={readFile}
                                    disabled={isLoading ? true : false}
                                    name="file"
                                    types={fileTypes}
                                    multiple={false}
                                    label=""
                                    children={
                                      <div className={styles.flex}>
                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                        <p className={styles.uploadText}>
                                          Add vaccination proof document here
                                        </p>
                                      </div>
                                    }
                                  />
                                }
                              </div> : 
                              <div style={{display:"flex", alignItems:"center", gap:"0.7rem"}}>
                                <p style={{color:"#2076FF", fontSize:"14px", fontWeight:"400"}}>{recordFile.name}</p>
                                <p onClick={()=>{setRecordFile("")}} style={{margin:"0"}}><DeleteForever style={{color:"#4B5563"}}/></p>
                              </div>
                }

                        </div>
                            
                        <div style={{textAlign: 'end', width: '100%', backgroundColor:"#EFF5FF", padding:"1rem"}}>
                            <Button variant='text' color='transparent' onClick={()=>{reset()}}>Cancel</Button>
                            <Button variant='contained' color='primary' loading={isLoading} className={styles.buttonText} onClick={()=>{createVaccineRecord()}}>Save</Button>
                        </div>          
                    </div>                
                </Modal>  : null
                }

{
                (showVaccineEdit) ? 
                <Modal open={showVaccineEdit} >
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center', gap: '1rem', width:"500px"}}>   
                        <div style={{textAlign: 'end', width: '100%'}}>
                            <CloseButton onClick={()=>{setShowVaccineEdit(false)}}/>
                        </div>            
                        <div style={{backgroundColor: '#fff', display: "flex", flexDirection:"column", width:"100%", gap:"2rem" }}>
                            <p style={{color:"#002867", fontSize:"18px", fontWeight:"600"}}>Edit vaccination record</p>
                            <Input disabled={true}  items={vaccine.map((i) => ({ text: i.varVaccineName, value: i.numVaccineID }))} 
                                required={true} value={selectedVaccineEdit} label={<p>Vaccine<span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span></p>} 
                                onChange={(e) => {{setSelectedVaccineEdit(e)}} } 
                            />
                            <TextField                                          
                                style={{ width: '100%'}}
                                label= {
                                    <div style={{display: 'flex', fontSize: '14px'}}>
                                        Given Date
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    </div>
                                }
                                required={true}
                                focused={true}
                                type={'date'}
                                variant="filled"
                                onChange={(e)=>{setGivenDateEdit(e.target.value)}}
                                value={givenDateEdit}
                            />
                            <TextField                                          
                                style={{ width: '100%'}}
                                label= {
                                    <div style={{display: 'flex', fontSize: '14px'}}>
                                        Expire Date
                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                    </div>
                                }
                                required={true}
                                focused={true}
                                type={'date'}
                                variant="filled"
                                onChange={(e)=>{setExpireDateEdit(e.target.value)}}
                                value={expireDateEdit}
                            />
                {
                    (recordFileEdit.name == undefined)?
                                <div
                                style={{
                                  border: "2px dashed #5f9cfe",
                                  borderRadius: "8px",
                                  padding: ".8rem",
                                  backgroundColor: "#fff",
                                }}
                              >
                                {
                                  <FileUploader
                                    handleChange={readFileEdit}
                                    onDrop={readFileEdit}
                                    disabled={isLoading ? true : false}
                                    name="file"
                                    types={fileTypes}
                                    multiple={false}
                                    label=""
                                    children={
                                      <div className={styles.flex}>
                                        <FileUploadOutlined style={{ color: "#2076FF" }} />
                                        <p className={styles.uploadText}>
                                          Add vaccination proof document here
                                        </p>
                                      </div>
                                    }
                                  />
                                }
                              </div> : 
                              <div style={{display:"flex", alignItems:"center", gap:"0.7rem"}}>
                                <p style={{color:"#2076FF", fontSize:"14px", fontWeight:"400"}}>{recordFileEdit.name}</p>
                                <p onClick={()=>{setRecordFileEdit("")}} style={{margin:"0"}}><DeleteForever style={{color:"#4B5563"}}/></p>
                              </div>
                }

                        </div>
                        {
                       
                                <div style={{textAlign: 'end', width: '100%', backgroundColor:"#EFF5FF", padding:"1rem"}}>
                                    <Button variant='text' color='transparent' onClick={()=>{reset()}}>Cancel</Button>
                                    <Button variant='contained' color='primary' loading={isLoading} className={styles.buttonText} onClick={()=>{editVaccineRecord()}}>Save</Button>
                                </div> 
                   
                        } 
         
                    </div>                
                </Modal>  : null
                }


            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <p className={styles.heading}>Vaccination details</p>
                {/* {
                    (props?.mode === true) ? 
                        checkPermission(<p style={{color:"#2076FF", fontSize:"14px", fontWeight:"500", cursor:"pointer"}} onClick={()=>{setIsAddVaccinationOpen(true)}}>
                            Add new record
                        </p>, 40)
                    : null
                } */}
            </div>
        {
            (vaccine.length > 0)?
            <div style={{display: 'flex', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '1rem', rowGap: "1rem", marginTop: "0.6rem"}}>
            {
                (vaccine != null && vaccine != undefined)?
                 (vaccine != null && vaccine != undefined)?
                 <TableContainer component={Paper}>
                 <Table sx={{ minWidth: 600 }} aria-label="simple table">
                 <TableHead>
                     <TableRow>
                         <TableCell style={{color:"#002867", fontWeight:"600"}}>Type</TableCell>
                         <TableCell  style={{color:"#002867", fontWeight:"600"}} align="left">Given Date</TableCell>
                         <TableCell  style={{color:"#002867", fontWeight:"600"}} align="left">Expired On</TableCell>
                         <TableCell  style={{color:"#002867", fontWeight:"600"}} align="left">Document</TableCell>
                         {
                             (props?.mode === true) ? 
                                 checkPermissionTabs(<TableCell  style={{color:"#002867", fontWeight:"600"}} align="center">Actions</TableCell>, 41)
                             : null
                         }               
                     </TableRow>
                 </TableHead>
                     <TableBody>        
                         {                  
                            vaccine.map((row, index)=>{
                                 return <TableRow
                                     key={index}
                                     sx={{ '&:last-child td, &:last-child th': { border: 0 },   "& .MuiTableRow-root:hover": {
                                     backgroundColor: "primary.light"
                                     } }}>
                                         <TableCell align="left">{row.varVaccineName}</TableCell>
                                         <TableCell align="left">{(row.dtVaccineGivenDate === "N/A")? <p style={{color:"#CA8208"}}>{row.dtVaccineGivenDate}</p> : shortDate(row.dtVaccineGivenDate)}</TableCell>
                                         <TableCell align="left">{(row.dtVaccineExpireDate  == 'Not administered')? <p style={{color:"#CA8208"}}>Not administered</p> : <p>{(expiryDateCheck(row.dtVaccineExpireDate) == true)? <p style={{color:"red", fontWeight:"500"}}>{shortDate(row.dtVaccineExpireDate)} <span style={{color:"red", fontWeight:"500"}}> Expired</span></p> : <p>{shortDate(row.dtVaccineExpireDate)}</p>}</p>}</TableCell>
                                         <TableCell align="left" onClick={(row.dtVaccineExpireDate == 'Not administered')? ()=>{} : ()=>{window.open(`${process.env.REACT_APP_MEDIA_URL}vaccination_records/${row.varDocumentPath}`)}}><p style={{color:"#1e1eac", fontWeight:"500",cursor:"pointer"}}>{(row.dtVaccineExpireDate  == 'Not administered')? "N/A" : row.varDocumentDisplayName}</p></TableCell>
                                         {
                                             (props?.mode === true) ? 
                                             checkPermissionTabs(<TableCell align="center" onClick={()=>{viewEditVaccine(index)}} style={{fontWeight:"600", cursor:"pointer", color:"#2076FF"}}>{(row.dtVaccineExpireDate  == 'Not administered')? "Add" : "Edit"}</TableCell>, 41)
                                             : null
                                         }
                                 </TableRow>    
                             }) 
                         }                       
                     </TableBody>
                 </Table>
             </TableContainer>
             : null
             : <p style={{color: "rgb(119, 126, 144)", fontWeight: "400", fontSize: "12px", width: "600px"}}>No vaccine records found</p>
            }

            </div> : <p style={{fontSize: "12px", fontWeight: "400", color: "rgb(107, 114, 128)"}}>No vaccination records found</p>
            }
            
 
            {
            (props.mode)?
            checkPermission(
                <div style={{textAlign: 'end', width: '100%', backgroundColor:"#EFF5FF", padding:"1rem", marginTop:"3rem"}}>
                <Button variant='contained' color='primary' onClick={handleSubmit}>Save</Button>
            </div>, 42)
            :null
        }
        </div>
    );
}

export default MedicalConditions;