/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "varPetTypeName": "Dog",
    "varPetTypeDescription": null,
    "bitPetTypeActive": true
*/

export const ServiceItemsLibrary: {} = {
    tableName: "5.3 - Service Items Library",
    hiddenColumns: [
        "numServiceItemID",
        "btSalesCommissionPercent",
        "numSalesCommissionPercent",
        "numSalesCommissionAmount",
        "btSalesCommissionAmount",
        "bitIsAdditional",
        "dtEffectiveDate",
        "dtExpiryDate",
        "numPetTypeID",
    ],
    pdfTemplate: ["varServiceItemName", "varServiceItemDescription", "numServiceItemCostPrice", "numServiceItemSalesPrice", "varPetType", "varColour", "bitActive"],
    template: [
        {
            Header: "Provider ID",
            accessor: "varServiceItemProviderID",
        },
        {
            Header: "Bar Code",
            accessor: "varServiceItemBarCode",
        },
        {
            Header: "Name",
            accessor: "varServiceItemName",
        },
        {
            Header: "Description",
            accessor: "varServiceItemDescription",
        },
        {
            Header: "Cost Price",
            accessor: "numServiceItemCostPrice",
        },
        {
            Header: "Sales Price",
            accessor: "numServiceItemSalesPrice",
        },
        {
            Header: "bitIsAdditional",
            accessor: "bitIsAdditional",
        },
        {
            Header: "btSalesCommissionPercent",
            accessor: "btSalesCommissionPercent",
        },
        {
            Header: "numSalesCommissionPercent",
            accessor: "numSalesCommissionPercent",
        },
        {
            Header: "numSalesCommissionAmount",
            accessor: "numSalesCommissionAmount",
        },
        {
            Header: "btSalesCommissionAmount",
            accessor: "btSalesCommissionAmount",
        },
        {
            Header: "dtExpiryDate",
            accessor: "dtExpiryDate",
        },
        {
            Header: "dtEffectiveDate",
            accessor: "dtEffectiveDate",
        },
        {
            Header: "numPetTypeID",
            accessor: "numPetTypeID",
        },
        {
            Header: "Service Color",
            accessor: "varColour",
            Cell: (props) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ backgroundColor: `${props?.cell?.value}`, width: "25px", height: "20px", borderRadius: "10px" }}></div>
                </div>
            ),
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "numServiceItemID",
            accessor: "numServiceItemID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numServiceItemID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varServiceItemProviderID",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varServiceItemBarCode",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varServiceItemName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varServiceItemDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numServiceItemCostPrice",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numServiceItemSalesPrice",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitIsAdditional",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value: false,
        },
        {
            fieldName: "btSalesCommissionPercent",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numSalesCommissionPercent",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "btSalesCommissionAmount",
            type: "sub-boolean",
            inputType: "number",
            mainTable: "",
            required: true,
            value: false,
            subFields: {
                fieldName: "numSalesCommissionAmount",
                type: "text",
                inputType: "number",
                mainTable: "",
                required: true,
                value: "",
            },
        },
        {
            fieldName: "dtEffectiveDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "dtExpiryDate",
            type: "date",
            inputType: "date",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numPetTypeID",
            type: "advanced",
            inputType: "text",
            objectName: "PetType",
            required: true,
            value: "",
            altName: "numPetType",
        },
        {
            fieldName: "varColour",
            type: "colorPicker",
            inputType: "colorPicker",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
