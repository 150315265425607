import styles from "../../Help.module.scss"

function General() {
    return (
        <div>
            <h3 style={{ fontWeight: "900", fontSize: "26px" }}> EsyPet General Help File </h3>

            <div id={styles.container}>
                <p>This document describes the functions of the Administrators. Access to this module will be by means of a User Name and Password.</p>
                <p> Access to this module will be by means of a User Name and Password.</p>

                <img src={require("./assets/login.png")} alt="" />
            </div>
        </div>
    )
}

export default General
