/* Format Phone */
export const formatPhone = (unformattedPhone) => {

    if(unformattedPhone === null || unformattedPhone === undefined)
        return "-"

    let cleanString = unformattedPhone

    if(cleanString != undefined && cleanString != null && cleanString != ""){

        /* Remove All characters */ 
        if(cleanString.includes("(")){
            cleanString = cleanString
            .split('(').join('')
        }

        if(cleanString.includes(" ")){
            cleanString = cleanString
            .split(' ').join('')
        }

        if(cleanString.includes(")")){
            cleanString = cleanString
            .split(')').join('')
        }

        if(cleanString.includes("-")){
            cleanString = cleanString
            .split('-').join('')
        }

        if(cleanString.length > 10){
            cleanString = cleanString.slice(0, 10)
        }

        if(cleanString.length < 10){
            let leng = 10 - cleanString.length
            let newString = cleanString.split('')
            for(let i=0;i<leng;i++){
                newString.push("0")
            }
            cleanString = newString.join('')
        }

        if(cleanString.length === 10)
        {
            let chars = cleanString.split('')
            chars.unshift('(')
            
            let bracket = chars.slice(0, 4)
            bracket.push(")")
            
            let hyphen = chars.slice(7, 11)
            hyphen.unshift("-")
            
            chars = [].concat(bracket, chars.slice(4, 7), hyphen)
            
            cleanString = chars.join('')
        }
    }

    return cleanString
}

export const removeFormatPhone = (unformattedPhone) => {
    if(unformattedPhone === null || unformattedPhone === undefined)
        return "-"

    let cleanString = unformattedPhone

    if(cleanString != undefined && cleanString != null && cleanString != ""){

        /* Remove All characters */ 
        if(cleanString.includes("(")){
            cleanString = cleanString
            .split('(').join('')
        }

        if(cleanString.includes(" ")){
            cleanString = cleanString
            .split(' ').join('')
        }

        if(cleanString.includes(")")){
            cleanString = cleanString
            .split(')').join('')
        }

        if(cleanString.includes("-")){
            cleanString = cleanString
            .split('-').join('')
        }

        if(cleanString.length > 10){
            cleanString = cleanString.slice(0, 10)
        }

        if(cleanString.length < 10){
            let leng = 10 - cleanString.length
            let newString = cleanString.split('')
            for(let i=0;i<leng;i++){
                newString.push("0")
            }
            cleanString = newString.join('')
        }

        if(cleanString.length === 10)
        {
            cleanString = cleanString           
        }
    }

    return cleanString
}