/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel";
import { OpenInNew } from '@mui/icons-material'
import { Button } from "../../../components";
import { Chip as LocationChip } from "../../../../components/";
import { ChipGroup } from "../../components/ChipGroup/ChipGroup";
import { formatPhone } from "../../../../utils/PhoneFormatter";
import placeholderImage from '../../../../images/location_placeholder.png'

export const Location = {
    tableName: "Salons",
    hiddenColumns: [ "varLocationPhone","varLocationNeighborhood", "bitGroomingService", "bitBoardingService", "bitDaycareService", "varLocationImagePath", "bitStoreProductService", "bitMobileGroomingService", "bitTrainingService", "bitSittingService", "bitStoreProductService","varLocationLatitude","varLocationLongitude"],
    pdfTemplate: ['varLocationName','bitActive','varLocationZip', 'varLocationCity', 'varLocationPhone', 'varLocationAddress1', 'varLocationNeighborhood'],
    hideSort: true,
    template: [
        {
            Header: 'Salon',
            accessor: 'varLocationName',  
            width: 10,
            disableSortBy: false,
            Cell: props => (
                <AvatarLabel 
                    imageShape='rounded'
                    isVip={false} 
                    img={(props.row.original.varLocationImagePath == null || props.row.original.varLocationImagePath == "") ? placeholderImage : `${process.env.REACT_APP_MEDIA_URL}directories/locations/` + props.row.original.varLocationImagePath} 
                    name={props.row.original.varLocationName } 
                />        
            )            
        },
        {
            Header: 'image',
            disableFilters: true,
            accessor: 'varLocationImagePath'
        },
        {
            Header: 'Zip',
            accessor: 'varLocationZip'
        },
        {
            Header: 'City',
            accessor: 'varLocationCity'
        },
        {
            Header: 'Services',
            accessor: '',
            disableFilters: true,
            Cell: props => (            
                <ChipGroup

                    values={[
                        {
                            label: "Gro",
                            value: props.row.original.bitGroomingService,
                            color: "success"
                        },
                        {
                            label: "Day",
                            value: props.row.original.bitDaycareService,
                            color: "success"
                        },
                        {
                            label: "Bod",
                            value: props.row.original.bitBoardingService,
                            color: "success"
                        },
                        {
                            label: "Sit",
                            value: props.row.original.bitSittingService,
                            color: "success"
                        },
                        {
                            label: "Tra",
                            value: props.row.original.bitTrainingService,
                            color: "success"
                        },
                        {
                            label: "Mob",
                            value: props.row.original.bitMobileGroomingService,
                            color: "success"
                        },
                        {
                            label: "Sto",
                            value: props.row.original.bitStoreProductService,
                            color: "success"
                        }
                    ]}
                    size={'small'}
                />        
            )     
        },
        {
            Header: 'Contact No.',
            accessor: 'varLocationPhone'
        },
        {
            Header: 'bitGroomingService',
            accessor: 'bitGroomingService'
        },
        {
            Header: 'bitBoardingService',
            accessor: 'bitBoardingService'
        },
        {
            Header: 'bitDaycareService',
            accessor: 'bitDaycareService'
        },
        {
            Header: 'bitSittingService',
            accessor: 'bitSittingService'
        },
        {
            Header: 'bitTrainingService',
            accessor: 'bitTrainingService'
        },
        {
            Header: 'bitMobileGroomingService',
            accessor: 'bitMobileGroomingService'
        },
        {
            Header: 'bitStoreProductService',
            accessor: 'bitStoreProductService'
        }, 
        {
            Header: 'Address',
            accessor: 'varLocationAddress1'
        },
        {
            Header: 'varLocationNeighborhood',
            accessor: 'varLocationNeighborhood'
        },
        {
            Header: 'lon',
            accessor: 'varLocationLongitude'
        },
        {
            Header: 'lat',
            accessor: 'varLocationLatitude'
        },
        {
            Header: 'Status',
            accessor: 'bitActive',
            Cell: props => (
                <LocationChip 
                label={(props?.cell?.value == true)? "Active" : "Inactive"}
                shape="round"
                fontColor={"#14532D"}
                customStyle={(props?.cell?.value == true)? "Success" : "Error"}
                fontWeight={400}
                fontSize={"12px"}
            />              
            )      
        },
        {
            Header: '',
            accessor: 'numLocationID',
            disableFilters: true,
            Cell: props => (
                <Button color="transparent" variant="text" onClick={() => console.log(props?.cell?.value)}><OpenInNew style={{color:"#005df1"}}/></Button>                
            )      
        }
    ]
}
