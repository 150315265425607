import styles from '../PersonalDetails.module.scss';
import { Chip } from "../../../../Chip/Chip";

const DisplayCustomer = (props: {
    customerTemplate;
}) => {
    return props.customerTemplate.map(section => {
        return <>
            <p className={styles.heading}>{section.heading}</p>
            
            {
                (section.layout == 'text') ?     
                    <table cellSpacing="0" className={styles.table}>  
                    {
                        section.data.map(pairs => {
                            return <tr>
                                {
                                    (pairs.key === '') ? 
                                        null
                                    :
                                        <td>{pairs.key}</td>
                                }
                                <td>{pairs.value}</td>
                            </tr>
                                
                        }) 
                    }                                                                                           
                    </table>                                                                     
                : (section.layout == 'address') ? 
                    <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch"}}>
                    {
                        section.data.map(address => {
                            return (address?.bitActive) ? <div>
                                <div style={{backgroundColor: '#EFF5FF', padding: '1rem 2rem'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <p style={{margin: 0, color: 'gray'}}>{address?.addressType}</p>                                                
                                        {
                                            (address?.addressIsDefault == true) ?
                                                <p style={{margin: 0}}><Chip size="small" color='success' label="Default" /></p>
                                            : null
                                        }                                                
                                    </div>

                                    <div>
                                        <p style={{margin: 0, color: '#002867'}}>{address?.addressLineOne}</p>
                                        <p style={{margin: 0, color: '#002867'}}>{address?.addressLineTwo}</p>
                                        <p style={{margin: 0, color: '#002867'}}>{`${address?.addressCity}, ${address?.addressState} ${address?.addressZip}`}</p>
                                        <p style={{margin: 0, color: '#002867'}}>USA</p>
                                    </div>                                            
                                </div> 
                                <p>{address?.addressCustomNotes}</p>
                            </div>
                            : null
                        }) 
                    }
                    </div>
                : null
            }    
           
            <div style={{ paddingTop: '1rem', marginBottom: '1.5rem', borderBottom: '1px solid #F3F4F6' }}></div>     
        </>                                                
    })
}

export default DisplayCustomer;