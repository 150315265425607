import styles from '../PersonalDetails.module.scss';
import { Map } from "../../../../Map/Map";

const DisplayLocation = (props:{
    locationTemplate;
}) => {

    return (
       props.locationTemplate.map(section => {
            return <>
                <p className={styles.heading}>{section.heading}</p>
                
                {
                    (section.layout == 'text') ?     
                        <table cellSpacing="0" className={styles.table}>  
                        {
                            section.data.map(pairs => {
                                return <tr>
                                    {
                                        (pairs.key === '') ? 
                                            null
                                        :
                                            <td>{pairs.key}</td>
                                    }
                                    <td>{pairs.value}</td>
                                </tr>
                                    
                            }) 
                        }                                                                                           
                        </table>                                                                     
                    : (section.layout == 'map') ?
                        <div style={{width: '500px', height: '300px'}}>
                            <Map location={section.data[0]} directions={false} showBaseMarker={true} zoom={20} isSub={false}></Map>
                        </div>
                        
                    : null
                }                          
            
                <div style={{ paddingTop: '1rem', marginBottom: '1.5rem', borderBottom: '1px solid #F3F4F6' }}></div>     
            </>                                                
        })
    );
}

export default DisplayLocation;