import styles from '../PersonalDetails.module.scss';
import { Chip } from "../../../../Chip/Chip";
import { Checkbox, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import Switch from '@mui/material/Switch';
import { Button, Avatar, Modal } from '../../../../components';
import { AdvancedInput } from '../../../../AdvancedInput/AdvancedInput';
import { toast } from "react-toastify";
import debouce from "lodash.debounce";
import InputMask from 'react-input-mask';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from 'browser-image-compression';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { FileUploader } from 'react-drag-drop-files';
import { HighlightOff, FileUploadOutlined } from '@mui/icons-material';
import { CloseButton } from '../../../../CloseButton/CloseButton';
import { axiosPrivate } from '../../../../../apis/axios';
import { Map } from '../../../../Map/Map';
import { isJsxFragment } from 'typescript';
import axios from "axios";

const EditLocation = (props: {
    locationTemplate;
    data;
}) => {

    const [editData, setEditData] = useState({
        location: props?.data
    })

    const fileTypes = ["JPEG", "jpg", "WEBP"];
    const [loading, setLoading] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [importedData, setImportedData] = useState<any>(editData.location.varLocationImagePath);
    const [showCropper, setShowCropper] = useState(false);
    const cropperRef = useRef<HTMLImageElement>(null);
    const [cropper, setCropper] = useState<any>();
    const [imageName, setImageName] = useState<any>("");

    const [lng, setLng] = useState(-74.412497);
    const [lat, setLat] = useState(40.554626);
    const [locName, setLocName] = useState("🏡 Somewhere on planet earth");

    const [petType, setPetType] = useState([] as any)

    const center = {
        lat: lat,
        lng: lng,
        name: locName
    };

    const [customerLoading, setCustomerLoading] = useState(false)
    console.log('pettt', petType)
    let arr: any = [];
    petType.map((type) => {
        if (type.bitActive == true) {
            arr.push(type.numPetTypeID)
        }
    })

    async function saveData() {
        const payload = {
            location: editData.location,
            locationPetType: arr
        }
        console.log('locationPay', payload)

        const result = await axiosPrivate.put('Location', JSON.stringify(payload))
        return result
    }

    const saveChanges = () => {
        setCustomerLoading(true)

        var Form: any = document.getElementById('editForm')

        if (Form.checkValidity() == false) {
            console.log('invalid')
            setCustomerLoading(false)
        }
        else {
            upload()
        }

        console.log(editData)
    }

    async function getStateCity(zipCode) {
        const result = await axiosPrivate.get(`ZipCode/GetCityStateByZipCode/${zipCode}`)
        return result;
    }

    const handleZipSearch = (value, index) => {
        getStateCity(value)
            .then(responseLocation => {
                const resultZip = responseLocation?.data;

                const newValue = { ...editData }
                newValue.location.varLocationState = resultZip?.varState
                newValue.location.varLocationCity = resultZip?.varCity
                setEditData(newValue)
            })
            .catch(err => {
                console.log(err)
            })
    }

    async function getPetTypes() {
        const result = await axiosPrivate.get(`PetType/GetAll`)
        return result;
    }

    const getPetsList = () => {
        getPetTypes()
            .then(petTypes => {
                const list = petTypes?.data.petType

                let checkboxList = [] as any

                list.map(petType => {
                    let matching = editData.location.locationPetType.some(t => t.numPetTypeID === petType.numPetTypeID);

                    checkboxList.push({
                        bitActive: (matching) ? true : false,
                        numLocationPetTypeID: (matching) ? true : 0,
                        numLocationID: props.data.numLocationID,
                        numPetTypeID: petType.numPetTypeID,
                        label: petType.varPetTypeName
                    })

                    console.log('ttt', matching)

                })

                setPetType(checkboxList)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getPetsList()
    }, [])

    // Set the AWS Region.
    const REGION = "us-east-1";

    // Create an Amazon S3 service client object.
    const creds = {
        accessKeyId: 'AKIAXQN6N4QUDD47RQQB',
        secretAccessKey: '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'
    };

    const s3Client = new S3Client({
        region: REGION,
        credentials: creds
    });

    //propsID
    const uploadID = editData.location.numLocationID;

    const readFile = (files) => {
        if (files.size > 1000000) {
            toast.error(files.name + ' File too large');
        } else {
            setImportedData(files)
            setShowCropper(true)
            setFileSelected(true)
            setImageName(files.name)
        }
    }

    const blobToFile = (theBlob: Blob, fileName: string) => {
        var b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return theBlob;
    }

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            cropper.getCroppedCanvas({
                imageSmoothingEnabled: false
            }).toBlob((blob) => {
                let toFile = blobToFile(blob, imageName)
                let imageFile: any = toFile;
                let options = {
                    maxSizeMB: 0.5,
                    fileType: "image/jpeg",
                    maxWidthOrHeight: 200,
                    useWebWorker: true,
                    onProgress: (progress) => {
                        setLoading(true)
                        if (progress == 100) {
                            setLoading(false)
                        }
                    }
                }
                imageCompression(imageFile, options)
                    .then(function (compressedFile) {
                        setImportedData(compressedFile)
                        console.log('com', compressedFile)
                        setShowCropper(false)
                    })
                    .catch(function (error) {
                        console.log(error.message);
                    });
            });
        }
    };

    const upload = () => {

        if (!fileSelected) {
            saveData()
                .then(res => {
                    console.log(res)
                    toast.success("Location was updated successfully!");

                    setCustomerLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setCustomerLoading(false)

                    toast.error("Something went wrong when saving new location!");
                })
        } else {

            const formData = new FormData(); 
            formData.append('folderpath', `directories/locations`);
            formData.append('method', "N");
            formData.append('deletingFileName', "-");
            formData.append('renameFileNames', "0");

                const file = importedData;
                const fileExtension = file.name.split('.').pop();
                const renamedFile = new File([file], `${editData.location.numLocationID}.${fileExtension}`, { type: file.type });
                formData.append('files', renamedFile);

                axios.post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
                .then((response) => {
                    console.log(response);
                    if(response.data.files.length > 0){
                        const newValue = { ...editData }
                        newValue.location.varLocationImagePath = newValue.location.numLocationID + "." + imageName.split('.').pop()
                        setEditData(newValue)

                        saveData()
                        .then(res => {
                            console.log(res)
                            toast.success("Location was updated successfully!");

                            setCustomerLoading(false)
                        })
                        .catch(err => {
                            console.log(err)
                            setCustomerLoading(false)

                            toast.error("Something went wrong when saving new location!");
                        })
                    }
                    setCustomerLoading(false)
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    return <form id="editForm" onSubmit={(e) => e.preventDefault()}>
        {
            props.locationTemplate.map(section => {
                return <>

                    {
                        (section.heading != undefined) ?
                            (section.heading === "Basic details") ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p className={styles.heading}>{section.heading}</p>
                                </div>
                                : <p className={styles.heading}>{section.heading}</p>
                            : null
                    }

                    {
                        (section.layout == 'imageUpload') ?
                            <>
                                <div style={{ backgroundColor: '#F9FAFB' }}>
                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", color: "#F9FAFB", padding: "16px 22px 16px 22px" }}>
                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'column' }}>
                                            <div>
                                                <Avatar
                                                    shape={'rounded'}
                                                    img={(fileSelected) ? URL.createObjectURL(importedData) : `${process.env.REACT_APP_MEDIA_URL}directories/locations/${importedData}`}
                                                    isVip={false}
                                                    className={'large'}
                                                    labelType={'largeLabel'}
                                                />
                                            </div>
                                            {
                                                (editData.location.varLocationImagePath != importedData) ?
                                                    <p style={{ color: "#005DF1", fontSize: "14px", fontWeight: "500", textAlign: 'center', cursor: 'pointer' }} onClick={() => { { setImportedData(editData.location.varLocationImagePath) } { setFileSelected(false) } }}>Reset photo</p>
                                                    : null
                                            }

                                        </div>
                                        <div style={{ border: '2px dashed #5f9cfe', borderRadius: '8px', padding: '2rem', backgroundColor: '#fff' }}>
                                            <FileUploader
                                                handleChange={readFile}
                                                onDrop={readFile}
                                                disabled={(loading) ? true : false}
                                                name="file"
                                                types={fileTypes}
                                                multiple={false}
                                                label=""
                                                children={
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                        <FileUploadOutlined style={{ color: '#2076FF' }} />
                                                        <p className={styles.uploadText}>Drag and drop or browse to choose a image</p>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Modal open={showCropper} >
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                                        <div style={{ textAlign: 'end', width: '100%' }}>
                                            <CloseButton onClick={() => { { setShowCropper(false) } { setFileSelected(false) } setImportedData(editData.location.varLocationImagePath) }} />
                                        </div>

                                        <div style={{ backgroundColor: '#fff', }}>
                                            <Cropper
                                                style={{ maxWidth: '500px', maxHeight: '500px' }}
                                                modal={true}
                                                src={(fileSelected) ? URL.createObjectURL(importedData) : ""}
                                                cropBoxResizable={true}
                                                initialAspectRatio={1}
                                                aspectRatio={1}
                                                guides={true}
                                                minCropBoxHeight={200}
                                                scalable={false}
                                                minCropBoxWidth={200}
                                                highlight={true}
                                                zoomable={true}
                                                movable={true}
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                                ref={cropperRef}
                                            />
                                        </div>
                                        <div style={{ textAlign: 'end', width: '100%' }}>
                                            <Button variant='contained' color='primary' onClick={() => getCropData()}>Crop & Fix</Button>
                                        </div>
                                    </div>
                                </Modal>
                            </>

                            : (section.layout == 'basic') ?
                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch" }}>
                                    {
                                        section.data.map(rows => {
                                            return rows.row.map(input => {
                                                return (input.type === "advanced") ?

                                                    <AdvancedInput
                                                        isButton={true}
                                                        label={input.label}
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.location[input.key] = e
                                                            setEditData(newValue)
                                                        }}
                                                        ObjectName={input.objectName}
                                                        isCustom={input.isCustom}
                                                        Sequence={input.sequence}
                                                        selectedValue={input.value}
                                                    />
                                                    : (input.type === "text") ?
                                                        (input.inputType === "tel") ?
                                                            <div>
                                                                <InputMask
                                                                    alwaysShowMask="true"
                                                                    mask="\(999\)999\-9999"
                                                                    maskChar="_"
                                                                    value={editData.location[input.key]}
                                                                    onChange={(e) => {
                                                                        const newValue = { ...editData }
                                                                        newValue.location[input.key] = e.target.value
                                                                        setEditData(newValue)
                                                                    }}
                                                                >
                                                                    {(inputProps) =>
                                                                        <TextField
                                                                            inputProps={{
                                                                                pattern: (input.isRequired) ?
                                                                                    "^[0-9()-]+$"
                                                                                    : ((editData.location[input.key] === "") || (editData.location[input.key] === "(___)___-____")) ? null : "^[0-9()-]+$"
                                                                            }}
                                                                            {...inputProps}
                                                                            type="tel"
                                                                            required={input.isRequired}
                                                                            style={{ width: '100%' }}
                                                                            label={
                                                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                    {input.label}
                                                                                    {(input.isRequired) ?
                                                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                        : null}
                                                                                </div>
                                                                            }
                                                                            variant='standard'
                                                                        />}
                                                                </InputMask>
                                                            </div>
                                                            : (input.inputType === "zip") ?
                                                                <div>
                                                                    <InputMask
                                                                        alwaysShowMask="true"
                                                                        mask="99999"
                                                                        maskChar="_"
                                                                        value={editData.location[input.key]}
                                                                        onChange={(e) => {
                                                                            const newValue = { ...editData }
                                                                            newValue.location[input.key] = e.target.value.replaceAll("_", "")
                                                                            setEditData(newValue)
                                                                        }}
                                                                    >
                                                                        {(inputProps) =>
                                                                            <TextField
                                                                                inputProps={{
                                                                                    pattern: (input.isRequired) ? "^[0-9()-]+$" : null
                                                                                }}
                                                                                {...inputProps}
                                                                                type="tel"
                                                                                required={input.isRequired}
                                                                                style={{ width: '100%' }}
                                                                                label={
                                                                                    <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                        {input.label}
                                                                                        {(input.isRequired) ?
                                                                                            <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                            : null}
                                                                                    </div>
                                                                                }
                                                                                variant='standard'
                                                                            />}
                                                                    </InputMask>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <TextField
                                                                        // inputProps={{
                                                                        //     pattern: "[a-z]{1,15}"
                                                                        // }}  
                                                                        variant="filled"
                                                                        style={{ width: '100%' }}
                                                                        label={
                                                                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                {input.label}
                                                                                {(input.isRequired) ?
                                                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                    : null}
                                                                            </div>
                                                                        }
                                                                        required={input.isRequired}
                                                                        onChange={(e) => {
                                                                            const newValue = { ...editData }
                                                                            newValue.location[input.key] = e.target.value
                                                                            setEditData(newValue)
                                                                        }}
                                                                        type={input.inputType}

                                                                        value={editData.location[input.key]}
                                                                    />
                                                                </div>
                                                        : (input.type === "blank") ?
                                                            <div></div>
                                                            : null
                                            })
                                        })
                                    }
                                </div>
                                : (section.layout == 'mapSelect') ?
                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch" }}>
                                        <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                                            {
                                                section.data[0].column.map(input => {
                                                    return <TextField
                                                        // inputProps={{
                                                        //     pattern: "[a-z]{1,15}"
                                                        // }}                                           
                                                        style={{ width: '100%' }}
                                                        label={
                                                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                {input.label}
                                                                {(input.isRequired) ?
                                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                    : null}
                                                            </div>
                                                        }
                                                        required={input.isRequired}
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.location[input.key] = e.target.value
                                                            setEditData(newValue)
                                                        }}
                                                        type={'text'}
                                                        variant="filled"
                                                        value={editData.location[input.key]}
                                                    />
                                                })
                                            }
                                        </div>

                                        <div>
                                            <Map
                                                location={center}
                                                directions={false}
                                                isPickLocation={true}
                                                zoom={20}
                                                onPickLocation={(e) => {
                                                    const triggerCheck = { ...editData }
                                                    triggerCheck.location.varLocationLatitude = e.latLng.lat()
                                                    triggerCheck.location.varLocationLongitude = e.latLng.lng()

                                                    let latlng: any = e.latLng;
                                                    let geocoder: any = new google.maps.Geocoder();

                                                    geocoder.geocode({ 'latLng': latlng }, (results, status) => {
                                                        if (status !== google.maps.GeocoderStatus.OK) {
                                                            alert(status);
                                                        }

                                                        if (status == google.maps.GeocoderStatus.OK) {
                                                            triggerCheck.location.varLocationGoogleMapAddress = results[0].formatted_address;
                                                        }
                                                    });

                                                    setEditData(triggerCheck)
                                                }}
                                                isSub={false}
                                            >
                                            </Map>
                                        </div>
                                    </div>
                                    : (section.layout == 'checkboxes') ?
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch" }}>
                                            {
                                                section.data.map((column) => {
                                                    return <div>
                                                        {
                                                            column.row.map((rows, ind) => {
                                                                return <div key={ind} style={{ display: "flex" }}>
                                                                    <Checkbox style={{ padding: '0 .2rem 0 0' }} checked={editData.location[rows.key]} onChange={(e) => {
                                                                        const triggerCheck = { ...editData }
                                                                        triggerCheck.location[rows.key] = !triggerCheck.location[rows.key]
                                                                        setEditData(triggerCheck)
                                                                    }} />
                                                                    <p>{rows.label}</p>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                        : (section.layout == 'externalCheckboxes') ?
                                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "0" }}>
                                                {
                                                    petType.map((checkbox, ind) => {
                                                        return <div>
                                                            <div key={ind} style={{ display: "flex" }}>
                                                                <Checkbox
                                                                    style={{ padding: '0 .2rem 0 0' }}
                                                                    checked={checkbox.bitActive}
                                                                    onChange={(e) => {
                                                                        const triggerCheck = [...petType]
                                                                        triggerCheck[ind].bitActive = !triggerCheck[ind].bitActive
                                                                        setPetType(triggerCheck)
                                                                    }}
                                                                />
                                                                <p>{checkbox.label}</p>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            : (section.layout == 'dynamic') ?
                                                <>
                                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch" }}>
                                                        {
                                                            section.data.map(input => {
                                                                return (input.type === "String") ?
                                                                    <>
                                                                        <TextField
                                                                            // inputProps={{
                                                                            //     pattern: "[a-z]{1,15}"
                                                                            // }}                                           
                                                                            style={{ width: '100%' }}
                                                                            label={
                                                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                    {input.key}
                                                                                    {(input.required) ?
                                                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                        : null}
                                                                                </div>
                                                                            }
                                                                            required={input.required}
                                                                            onChange={(e) => {
                                                                                const newValue = { ...editData }
                                                                                newValue.location[input.fieldName] = e.target.value
                                                                                setEditData(newValue)
                                                                            }}
                                                                            type={'text'}
                                                                            variant="filled"
                                                                            value={editData.location[input.fieldName]}
                                                                        />
                                                                    </>
                                                                    : (input.type === "DateTime") ?
                                                                        <>
                                                                            <TextField
                                                                                // inputProps={{
                                                                                //     pattern: "[a-z]{1,15}"
                                                                                // }}                                           
                                                                                style={{ width: '100%' }}
                                                                                label={
                                                                                    <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                        {input.key}
                                                                                        {(input.required) ?
                                                                                            <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                            : null}
                                                                                    </div>
                                                                                }
                                                                                required={input.required}
                                                                                onChange={(e) => {
                                                                                    const newValue = { ...editData }
                                                                                    newValue.location[input.fieldName] = e.target.value
                                                                                    setEditData(newValue)
                                                                                }}
                                                                                focused={true}
                                                                                type={'date'}
                                                                                variant="filled"
                                                                                value={editData.location[input.fieldName]}
                                                                            />
                                                                        </>
                                                                        : (input.type === "Int32") ?
                                                                            <>
                                                                                <AdvancedInput
                                                                                    isButton={true}
                                                                                    label={
                                                                                        <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                            {input.key}
                                                                                            {(input.required) ?
                                                                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                                : null}
                                                                                        </div>
                                                                                    }
                                                                                    required={input.required}
                                                                                    onChange={(e) => {
                                                                                        const newValue = { ...editData }
                                                                                        newValue.location[input.fieldName] = e
                                                                                        setEditData(newValue)
                                                                                    }}
                                                                                    ObjectName={'Customer'}
                                                                                    isCustom={true}
                                                                                    Sequence={''}
                                                                                    selectedValue={editData.location[input.fieldName]}
                                                                                />
                                                                            </>
                                                                            : (input.type === "Boolean") ?
                                                                                <>
                                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                        <p>{input.key}</p>
                                                                                        <Switch
                                                                                            onChange={(e) => {
                                                                                                const newValue = { ...editData }
                                                                                                newValue.location[input.fieldName] = !newValue.location[input.fieldName]
                                                                                                setEditData(newValue)
                                                                                            }}
                                                                                            checked={editData.location[input.fieldName]}
                                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                                : null
                                                            })
                                                        }
                                                    </div>
                                                </>
                                                : null
                    }
                    <div style={{ paddingTop: '1rem', marginBottom: '1.5rem' }}></div>
                </>
            })}

        <div style={{ backgroundColor: '#EFF5FF', padding: '2rem 3rem', textAlign: 'end' }}>
            <div style={{ display: 'flex', gap: '2rem', justifyContent: 'flex-end' }}>
                <Button onClick={() => saveChanges()} loading={customerLoading} variant='contained' color='primary' text='Save' type={'submit'} />
            </div>
        </div>

    </form>
}

export default EditLocation;