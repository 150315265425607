import styles from './Text.module.scss';

export const Text = (props: {
    weight: "fw_400" | "fw_600" | "fw_500";
    color: "p_700" | "n_500" | "black" | "p_900"
    children?;
}) => {
    return (
        <p className={`${styles[props.weight]} ${styles[props.color]}`}>{props.children}</p>
    );
}
