import { Step, StepLabel, Stepper, ThemeProvider, createTheme } from '@mui/material';
import { useEffect, useState } from 'react';

const Steps = (props: {
    statusSummary: any,
    generalType: boolean,
    color?: string,
    orientation?: 'horizontal' | 'vertical',
}) => {

    const [currentStep, setCurrentStep] = useState(1)

    const groomerSteps = [
        'Scheduling',
        'Check In',
        'Grooming',
        'Ready for pickup',
        'Check out'
    ]

    const generalSteps = [
        'Scheduling',
        'Check In',
        'Grooming',
        'Ready for pickup',
        // 'Payment',
        'Check out'
    ]

    useEffect(() => {
        if (props?.statusSummary?.apptStatus === "ONGOING") {

            let statusList = [
                props?.statusSummary?.IsApptCheckedInStatus,
                props?.statusSummary?.IsServiceArea,
                props?.statusSummary?.IsReadyForPickUp,
                // props?.statusSummary?.IsPaymentMade,
                props?.statusSummary?.IsCheckedOut,
            ]

            let statusListMin = [
                props?.statusSummary?.IsApptCheckedInStatus,
                props?.statusSummary?.IsServiceArea,
                props?.statusSummary?.IsReadyForPickUp,
                props?.statusSummary?.IsCheckedOut,
            ]

            if (props?.generalType) {
                statusList.map((stat, index) => {
                    if (stat) {
                        if (index + 2 > statusList.length) {
                            setCurrentStep(statusList.length)
                        }
                        else {
                            setCurrentStep(index + 2)
                        }
                    }
                })
            }
            else {
                statusListMin.map((stat, index) => {
                    if (stat) {
                        if (index + 2 > statusListMin.length) {
                            setCurrentStep(statusListMin.length)
                        }
                        else {
                            setCurrentStep(index + 2)
                        }
                    }
                })
            }
        }else if (props?.statusSummary?.apptStatus === "PICKUP-READY") {

            let statusList = [
                props?.statusSummary?.IsApptCheckedInStatus,
                props?.statusSummary?.IsServiceArea,
                props?.statusSummary?.IsReadyForPickUp,
                // props?.statusSummary?.IsPaymentMade,
                props?.statusSummary?.IsCheckedOut,
            ]

            let statusListMin = [
                props?.statusSummary?.IsApptCheckedInStatus,
                props?.statusSummary?.IsServiceArea,
                props?.statusSummary?.IsReadyForPickUp,
                props?.statusSummary?.IsCheckedOut,
            ]

            if (props?.generalType) {
                statusList.map((stat, index) => {
                    if (stat) {
                        if (index + 2 > statusList.length) {
                            setCurrentStep(statusList.length)
                        }
                        else {
                            setCurrentStep(index + 2)
                        }
                    }
                })
            }
            else {
                statusListMin.map((stat, index) => {
                    if (stat) {
                        if (index + 2 > statusListMin.length) {
                            setCurrentStep(statusListMin.length)
                        }
                        else {
                            setCurrentStep(index + 2)
                        }
                    }
                })
            }
        }
        // else if (props?.statusSummary?.apptStatus === "PAYMENT-MADE") {

        //     let statusList = [
        //         props?.statusSummary?.IsApptCheckedInStatus,
        //         props?.statusSummary?.IsServiceArea,
        //         props?.statusSummary?.IsReadyForPickUp,
        //         props?.statusSummary?.IsPaymentMade,
        //         props?.statusSummary?.IsCheckedOut,
        //     ]

        //     let statusListMin = [
        //         props?.statusSummary?.IsApptCheckedInStatus,
        //         props?.statusSummary?.IsServiceArea,
        //         props?.statusSummary?.IsReadyForPickUp,
        //         props?.statusSummary?.IsCheckedOut,
        //     ]

        //     if (props?.generalType) {
        //         statusList.map((stat, index) => {
        //             if (stat) {
        //                 if (index + 2 > statusList.length) {
        //                     setCurrentStep(statusList.length)
        //                 }
        //                 else {
        //                     setCurrentStep(index + 2)
        //                 }
        //             }
        //         })
        //     }
        //     else {
        //         statusListMin.map((stat, index) => {
        //             if (stat) {
        //                 if (index + 2 > statusListMin.length) {
        //                     setCurrentStep(statusListMin.length)
        //                 }
        //                 else {
        //                     setCurrentStep(index + 2)
        //                 }
        //             }
        //         })
        //     }
        // }
        
        else if (props?.statusSummary?.apptStatus === "CHECKED-OUT") {
            if (props?.generalType) {
                setCurrentStep(6)
            }
            else {
                setCurrentStep(5)
            }
        }
    }, [props?.statusSummary])

    const theme = createTheme({
        palette: {
            primary: {
                main: props.color || '#1976D2', // Default color or the color passed via props
            },
        },
    });

    return (
        <>
        <ThemeProvider theme={theme}>
            <Stepper activeStep={currentStep} orientation={props.orientation || 'horizontal'} alternativeLabel>
                {(props?.generalType) ?
                    generalSteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))
                    :
                    groomerSteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
        </ThemeProvider>
        </>
    );
}

export default Steps;



// import { Step, StepLabel, Stepper, ThemeProvider, createTheme } from '@mui/material';
// import { useEffect, useState } from 'react';

// const Steps = (props: {
//     statusSummary: any,
//     generalType: boolean,
//     color?: string,
//     orientation?: 'horizontal' | 'vertical',
// }) => {

//     const [currentStep, setCurrentStep] = useState(1)

//     const groomerSteps = [
//         'Scheduling',
//         'Check In',
//         'Grooming',
//         'Ready for pickup',
//         'Check out'
//     ]

//     const generalSteps = [
//         'Scheduling',
//         'Check In',
//         'Grooming',
//         'Ready for pickup',
//         'Payment',
//         'Check out'
//     ]

//     useEffect(() => {
//         if (props?.statusSummary?.apptStatus === "ONGOING" ||
//             props?.statusSummary?.apptStatus === "PICKUP-READY" ||
//             props?.statusSummary?.apptStatus === "PAYMENT-MADE") {

//             let statusList = [
//                 props?.statusSummary?.IsApptCheckedInStatus,
//                 props?.statusSummary?.IsServiceArea,
//                 props?.statusSummary?.IsReadyForPickUp,
//                 props?.statusSummary?.IsPaymentMade,
//                 props?.statusSummary?.IsCheckedOut,
//             ]

//             let statusListMin = [
//                 props?.statusSummary?.IsApptCheckedInStatus,
//                 props?.statusSummary?.IsServiceArea,
//                 props?.statusSummary?.IsReadyForPickUp,
//                 props?.statusSummary?.IsCheckedOut,
//             ]

//             if (props?.generalType) {
//                 statusList.forEach((stat, index) => {
//                     if (stat) {
//                         if (index + 2 > statusList.length) {
//                             setCurrentStep(statusList.length)
//                         } else {
//                             setCurrentStep(index + 2)
//                         }
//                     }
//                 })
//             } else {
//                 statusListMin.forEach((stat, index) => {
//                     if (stat) {
//                         if (index + 2 > statusListMin.length) {
//                             setCurrentStep(statusListMin.length)
//                         } else {
//                             setCurrentStep(index + 2)
//                         }
//                     }
//                 })
//             }
//         } else if (props?.statusSummary?.apptStatus === "CHECKED-OUT") {
//             if (props?.generalType) {
//                 setCurrentStep(6)
//             } else {
//                 setCurrentStep(5)
//             }
//         }
//     }, [props?.statusSummary])

//     const theme = createTheme({
//         palette: {
//             primary: {
//                 main: props.color || '#1976D2', // Default color or the color passed via props
//             },
//         },
//     });

//     const renderStep = (label: string, isCompleted: boolean, isDisabled: boolean) => (
//         <Step key={label} completed={isCompleted}>
//             <StepLabel error={isDisabled}>{label}</StepLabel>
//         </Step>
//     );

//     return (
//         <>
//             <ThemeProvider theme={theme}>
//                 <Stepper activeStep={currentStep} orientation={props.orientation || 'horizontal'} alternativeLabel>
//                     {(props?.generalType) ?
//                         generalSteps.map((label, index) => {
//                             const isCompleted = index < currentStep && (label !== 'Payment' || props?.statusSummary?.IsPaymentMade);
//                             const isDisabled = label === 'Payment' && !props?.statusSummary?.IsPaymentMade;
//                             return renderStep(label, isCompleted, isDisabled);
//                         })
//                         :
//                         groomerSteps.map((label, index) => {
//                             const isCompleted = index < currentStep;
//                             return renderStep(label, isCompleted, false);
//                         })
//                     }
//                 </Stepper>
//             </ThemeProvider>
//         </>
//     );
// }

// export default Steps;






