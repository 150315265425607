/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { Chip } from "../../components/Chip/Chip"

/*
    "varPetTypeName": "Dog",
    "varPetTypeDescription": null,
    "bitPetTypeActive": true
*/

export const WaiverDocument: {} = {
    tableName: "6.3 - Waiver Documents",
    hiddenColumns: ["numWaiverDocumentID", "varWaiverDocumentPath", "numPetTypeID", "numValidMonths"],
    pdfTemplate: ["varWaiverDocumentName", "varWaiverDocumentDescription", "varPetType"],
    template: [
        {
            Header: "Waiver Name",
            accessor: "varWaiverDocumentName",
        },
        {
            Header: "Description",
            accessor: "varWaiverDocumentDescription",
        },
        {
            Header: "Waiver Version",
            accessor: "varWaiverDocumentVersion",
        },
        {
            Header: "Pet Type",
            accessor: "varPetType",
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
        {
            Header: "varWaiverDocumentPath",
            accessor: "varWaiverDocumentPath",
        },
        {
            Header: "numPetTypeID",
            accessor: "numPetTypeID",
        },
        {
            Header: "numValidMonths",
            accessor: "numValidMonths",
        },
        {
            Header: "numWaiverDocumentID",
            accessor: "numWaiverDocumentID",
            disableFilters: true,
        },
    ],
    postFields: [
        {
            fieldName: "numWaiverDocumentID",
            type: "id",
            inputType: "hidden",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varWaiverDocumentName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varWaiverDocumentDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varWaiverDocumentVersion",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "varWaiverDocumentPath",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numPetTypeID",
            type: "advanced",
            inputType: "text",
            objectName: "PetType",
            required: true,
            value: "",
            altName: "numPetType",
        },
        {
            fieldName: "numValidMonths",
            type: "text",
            inputType: "number",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
