import { useEffect, useMemo, useRef, useState } from "react"
import AdvancedDropDown from "../../../../../components/AdvancedDropDown/AdvancedDropDown"
import axios, { axiosPrivate } from "../../../../../apis/axios"
import { toast } from "react-toastify"
import { Button, CloseButton } from "../../../../../components"
import { Checkbox, Chip, IconButton, TextField, Tooltip } from "@mui/material"
import { Close, Edit, ErrorOutline, InfoOutlined, Warning, WarningAmber } from "@mui/icons-material"
import styles from "./EventTab.module.scss"
import { Modal } from "../../../../../components/components"
import ColorPicker from "./ColorPicker/ColorPicker"
import { formatCash } from "../../../../../utils/Validation"

const EventTab = (props) => {
    const locationRef = useRef()
    const employeeRef = useRef()

    const [location, setLocation] = useState(0)
    const [employee, setEmployee] = useState(0)

    const locationRefEdit = useRef()
    const employeeRefEdit = useRef()

    const [locationEdit, setLocationEdit] = useState(0)
    const [employeeEdit, setEmployeeEdit] = useState(0)

    const locationRefNew = useRef()
    const employeeRefNew = useRef()

    const [locationNew, setLocationNew] = useState(0)
    const [employeeNew, setEmployeeNew] = useState(0)

    const [locationList, setLocationList] = useState([]) as any
    const [locationListNew, setLocationListNew] = useState([]) as any
    const [locationListEdit, setLocationListEdit] = useState([]) as any

    const [employeeList, setEmployeeList] = useState([]) as any
    const [employeeListNew, setEmployeeListNew] = useState([]) as any
    const [employeeListEdit, setEmployeeListEdit] = useState([]) as any

    /* LISTS */
    const [eventsList, setEventsList] = useState([]) as any
    const [servicesList, setServicesList] = useState([]) as any
    const [petTypesList, setPetTypesList] = useState([]) as any
    const [breedsList, setBreedsList] = useState([]) as any
    const [sizesList, setSizesList] = useState([]) as any

    /* LOADING STATES */
    const [isLoadingList, setIsLoadingList] = useState(false)
    const [eventsLoading, setEventsLoading] = useState(false)
    const [servicesLoading, setServicesLoading] = useState(false)
    const [petTypesLoading, setPetTypesLoading] = useState(false)
    const [breedsLoading, setBreedsLoading] = useState(false)
    const [sizesLoading, setSizesLoading] = useState(false)
    const [employeeLoading, setEmployeeLoading] = useState(false)

    const [isOpenEditEvent, setIsOpenEditEvent] = useState(false)
    const [isOpenNewEvent, setIsOpenNewEvent] = useState(false)

    const [eventColor, setEventColor] = useState("")
    const [eventName, setEventName] = useState("")

    /* EDIT EVENT */
    const [eventNameEdit, setEventNameEdit] = useState("")
    const [eventColorEdit, setEventColorEdit] = useState("")
    const [editEventID, setEditEventID] = useState(0)

    useEffect(() => {
        if (isOpenEditEvent) {
            const filteredEvents = eventsList.filter((event) => event.EventID === editEventID)
            setEventNameEdit(filteredEvents[0]?.EventName)
            setEventColorEdit(filteredEvents[0]?.EventColor)

            setEmployeeEdit(filteredEvents[0]?.EventEmployeeID)
            setLocationEdit(filteredEvents[0]?.numLocationID)
        }
    }, [isOpenEditEvent])

    /* SELECTION STATES */
    const [selectedEvent, setSelectedEvent] = useState({
        numLocationID: 0,
        EventID: 0,
        selectedName: "",
    })
    const [selectedService, setSelectedService] = useState({
        ServiceItemID: 0,
        selectedName: "",
        EventsServicesListID: 0,
    })
    const [selectedPetTypes, setSelectedPetTypes] = useState({
        numPetTypeID: 0,
        selectedName: "",
        EventsPetTypeListID: 0,
    })
    const [selectedBreeds, setSelectedBreeds] = useState({
        numPetBreedID: 0,
        selectedName: "",
        EventsPetBreedListID: 0,
    })
    const [selectedSizes, setSelectedSizes] = useState({
        numPetSizeID: 0,
        selectedName: "",
        EventsPetSizeListID: 0,
    })

    const [selectedAllEvents, setSelectedAllEvent] = useState(false)
    const [selectedAllPetTypes, setSelectedAllPetTypes] = useState(false)
    const [selectedAllBreeds, setSelectedAllBreeds] = useState(false)
    const [selectedAllSizes, setSelectedAllSizes] = useState(false)
    const [eventDuration, setEventDuration] = useState(0)

    /* FILTERS */
    // EVENTS
    const [filterTextEvents, setFilterTextEvents] = useState("")
    const [filteredItemsEvents, setFilteredItemsEvents] = useState([]) as any

    const resetEvents = () => {
        setFilterTextEvents("")
        setFilteredItemsEvents(eventsList)
    }

    const filterItemsEvents = (filterText) => {
        const filteredItems = eventsList.filter((item) => item.EventName.toLowerCase().includes(filterText.toLowerCase()))
        setFilteredItemsEvents(filteredItems)
    }

    const handleFilterChangeEvents = (event) => {
        const filterText = event.target.value
        setFilterTextEvents(filterText)
        filterItemsEvents(filterText)
    }

    // SERVICES
    const [filterTextServices, setFilterTextServices] = useState("")
    const [filteredItemsServices, setFilteredItemsServices] = useState([]) as any

    const resetServices = () => {
        setFilterTextServices("")
        setFilteredItemsServices(servicesList)
    }

    const filterItemsServices = (filterText) => {
        const filteredItems = servicesList.filter((item) => item.varServiceItemName.toLowerCase().includes(filterText.toLowerCase()))
        setFilteredItemsServices(filteredItems)
    }

    const handleFilterChangeServices = (event) => {
        const filterText = event.target.value
        setFilterTextServices(filterText)
        filterItemsServices(filterText)
    }

    const memoizedFilteredItemsServices = useMemo(() => {
        return filteredItemsServices.map((service, index) => {
            return {
                ...service,
                IsSelected: selectedAllEvents || !!service?.IsSelected,
            }
        })
    }, [filteredItemsServices, selectedAllEvents])

    const [filterTextPetTypes, setFilterTextPetTypes] = useState("")
    const [filteredItemsPetTypes, setFilteredItemsPetTypes] = useState([]) as any

    const [filterTextSizes, setFilterTextSizes] = useState("")
    const [filteredItemsSizes, setFilteredItemsSizes] = useState([]) as any

    const resetPetTypes = () => {
        setFilterTextPetTypes("")
        setFilteredItemsPetTypes(petTypesList)
    }

    const filterItemsPetTypes = (filterText) => {
        const filteredItems = petTypesList.filter((item) => item.varPetTypeName.toLowerCase().includes(filterText.toLowerCase()))
        setFilteredItemsPetTypes(filteredItems)
    }

    const handleFilterChangePetTypes = (event) => {
        const filterText = event.target.value
        setFilterTextPetTypes(filterText)
        filterItemsPetTypes(filterText)
    }

    const memoizedFilteredItemsPetType = useMemo(() => {
        return filteredItemsPetTypes.map((petTypes, index) => {
            return {
                ...petTypes,
                IsSelected: selectedAllPetTypes || !!petTypes?.IsSelected,
            }
        })
    }, [filteredItemsPetTypes, selectedAllPetTypes])

    // BREEDS
    const [filterTextBreeds, setFilterTextBreeds] = useState("")
    const [filteredItemsBreeds, setFilteredItemsBreeds] = useState([]) as any

    const resetBreeds = () => {
        setFilterTextBreeds("")
        setFilteredItemsBreeds(breedsList)
    }

    const filterItemsBreeds = (filterText) => {
        const filteredItems = breedsList.filter((item) => item.varPetBreedName.toLowerCase().includes(filterText.toLowerCase()))
        setFilteredItemsBreeds(filteredItems)
    }

    const handleFilterChangeBreeds = (event) => {
        const filterText = event.target.value
        setFilterTextBreeds(filterText)
        filterItemsBreeds(filterText)
    }

    const memoizedFilteredItemsBreeds = useMemo(() => {
        return filteredItemsBreeds.map((breed, index) => {
            return {
                ...breed,
                IsSelected: selectedAllBreeds || !!breed?.IsSelected,
            }
        })
    }, [filteredItemsBreeds, selectedAllBreeds])

    const memoizedFilteredItemsSizes = useMemo(() => {
        return filteredItemsSizes.map((size, index) => {
            return {
                ...size,
                IsSelected: selectedAllSizes || !!size?.IsSelected,
            }
        })
    }, [filteredItemsSizes, selectedAllSizes])

    const resetSizes = () => {
        setFilterTextSizes("")
        setFilteredItemsSizes(sizesList)
    }

    const filterItemsSizes = (filterText) => {
        const filteredItems = sizesList.filter((item) => item.varPetSizeName.toLowerCase().includes(filterText.toLowerCase()))
        setFilteredItemsSizes(filteredItems)
    }

    const handleFilterChangeSizes = (event) => {
        const filterText = event.target.value
        setFilterTextSizes(filterText)
        filterItemsSizes(filterText)
    }

    /* END FILTERS */

    async function getLocations() {
        console.log("getLocations")
        setIsLoadingList(true)
        const result = await axiosPrivate.get(`Location/GetAll`, {})
        return result.data.location
    }

    async function getEventsList() {
        console.log("getEventsList")
        setEventsLoading(true)
        const result = await axiosPrivate.get(`Schedule/GetAllEventsByLocationID?LocationID=${location === 0 ? "" : location}&EmployeeID=${employee === 0 ? "" : employee}`)
        return result.data
    }

    async function getPetTypesList() {
        console.log("getPetTypesList", selectedEvent?.EventID, selectedEvent?.numLocationID)
        setPetTypesLoading(true)
        const result = await axiosPrivate.get(
            `Schedule/GetAllPetTypesListByEventID?
            LocationID=${selectedEvent?.numLocationID === 0 || selectedEvent?.numLocationID === null ? "" : selectedEvent?.numLocationID}
            &EventID=${selectedEvent?.EventID === 0 ? "" : selectedEvent?.EventID}`
        )
        return result.data
    }

    async function getServicesList() {
        console.log("getServicesList - EventsPetTypeListID:", selectedPetTypes?.EventsPetTypeListID)
        setServicesLoading(true)
        const result = await axiosPrivate.get(
            `Schedule/GetServicesListByLocationID?LocationID=${selectedEvent?.numLocationID === 0 || selectedEvent?.numLocationID === null ? "" : selectedEvent?.numLocationID}
            &EventsPetTypeListID=${selectedPetTypes?.EventsPetTypeListID === 0 ? "" : selectedPetTypes?.EventsPetTypeListID}`
        )
        return result.data
    }

    async function getSizes() {
        console.log("getSizes- EventsServicesListID:", selectedService?.EventsServicesListID)
        setSizesLoading(true)
        const result = await axiosPrivate.get(
            `Schedule/GetAllPetSizeListByServicesListID?
            LocationID=${selectedEvent?.numLocationID === 0 || selectedEvent?.numLocationID === null ? "" : selectedEvent?.numLocationID}
            &EventsServicesListID=${selectedService?.EventsServicesListID === 0 ? "" : selectedService?.EventsServicesListID}`
        )
        return result.data
    }

    async function getBreeds() {
        console.log("getBreeds EventsPetSizeListID:", selectedSizes?.EventsPetSizeListID, "EventsServicesListID", selectedService?.EventsServicesListID)
        setBreedsLoading(true)

        const result = await axiosPrivate.get(
            `Schedule/GetAllPetBreedListByPetSizeListID?
            LocationID=${selectedEvent?.numLocationID === 0 || selectedEvent?.numLocationID === null ? "" : selectedEvent?.numLocationID}
            &EventsPetSizeListID=${selectedSizes?.EventsPetSizeListID === 0 ? "" : selectedSizes?.EventsPetSizeListID}
            &EventsServicesListID=${selectedService?.EventsServicesListID === 0 ? "" : selectedService?.EventsServicesListID}`
        )
        return result.data
    }

    async function getEmployeesByLocation() {
        console.log("getEmployeesByLocation")
        const result = await axiosPrivate.get(`Schedule/GetEmployeesByLocationID?LocationID=${location === 0 ? "" : location}`)
        return result.data
    }

    async function getEmployeesByLocationNew() {
        console.log("getEmployeesByLocationNew")
        const result = await axiosPrivate.get(`Schedule/GetEmployeesByLocationID?LocationID=${locationNew === 0 ? "" : locationNew}`)
        return result.data
    }

    async function getEmployeesByLocationEdit() {
        console.log("getEmployeesByLocationEdit")
        const result = await axiosPrivate.get(`Schedule/GetEmployeesByLocationID?LocationID=${locationEdit === 0 ? "" : locationEdit}`)
        return result.data
    }

    async function GetTimeForEmployeeEventSetup(payload) {
        const result = await axiosPrivate.get(`Schedule/GetTimeForEmployeeEventSetup?EventID=${payload?.EventID}&numPetTypeID=${payload?.numPetTypeID}&ServiceItemID=${payload?.ServiceItemID}&numPetSizeID=${payload?.numPetSizeID}&numPetBreedID=${payload?.numPetBreedID}`)
        return result.data
    }

    /* EDIT SERVICES */
    async function updateServicesList(data) {
        console.log("updateServicesList")
        await axiosPrivate
            .post(`Schedule/PopulateServicesList`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)
                    toast.success("Services updated successfully!")

                    getAllServices()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const updateServices = () => {
        const updatedServices = memoizedFilteredItemsServices
            .filter((service) => (service?.EventsServicesListID !== 0 && !service?.IsSelected) || (service?.EventsServicesListID == 0 && service?.IsSelected))
            .map((service) => {
                return {
                    eventsServicesListID: service?.EventsServicesListID,
                    eventsPetTypeListID: selectedPetTypes.EventsPetTypeListID,
                    serviceID: service?.ServiceItemID,
                    isSelected: service?.IsSelected,
                    userID: 0,
                }
            })

        if (updatedServices.length > 0) {
            updateServicesList(updatedServices)
            console.log("services-sent-payload", updatedServices)
        } else {
            toast.info("No changes to update!")
        }
    }

    /* EDIT PET TYPES */
    async function updatePetTypesList(data) {
        console.log("updatePetTypesList")
        await axiosPrivate
            .post(`Schedule/PopulatePetTypeList`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)
                    toast.success("Pet Types updated successfully!")

                    getAllPetTypes()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    /* EDIT PET BREEDS */
    async function updatedPetBreedsList(data) {
        console.log("updatedPetBreedsList")
        await axiosPrivate
            .post(`Schedule/PopulatePetBreedList`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)
                    toast.success("Pet Breeds updated successfully!")

                    getAllBreeds()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const updatePetTypes = () => {
        const updatedPetTypes = memoizedFilteredItemsPetType
            .filter((types) => (types?.EventsPetTypeListID !== 0 && !types?.IsSelected) || (types?.EventsPetTypeListID === 0 && types?.IsSelected))
            .map((types) => {
                console.log(types)
                return {
                    eventsPetTypeListID: types?.EventsPetTypeListID,
                    eventID: selectedEvent?.EventID,
                    petTypeID: types.numPetTypeID,
                    isSelected: types?.IsSelected,
                    userID: 0,
                }
            })

        if (updatedPetTypes.length > 0) {
            updatePetTypesList(updatedPetTypes)
            console.log("petTypes-sent-payload", updatedPetTypes)
        } else {
            toast.info("No changes to update!")
        }
    }

    const updatePetBreeds = () => {
        const updatedPetBreeds = memoizedFilteredItemsBreeds
            .filter((breeds) => (breeds?.EventsPetBreedListID !== 0 && !breeds?.IsSelected) || (breeds?.EventsPetBreedListID === 0 && breeds?.IsSelected))
            .map((breeds) => {
                return {
                    eventsPetBreedListID: breeds?.EventsPetBreedListID,
                    eventsPetSizeListID: selectedSizes?.EventsPetSizeListID,
                    petBreedID: breeds?.numPetBreedID,
                    userID: 1,
                    IsSelected: breeds?.IsSelected,
                }
            })

        if (updatedPetBreeds.length > 0) {
            updatedPetBreedsList(updatedPetBreeds)
            console.log("petBreeds-sent-payload", updatedPetBreeds)
        } else {
            toast.info("No changes to update!")
        }
    }

    async function updatedSizesList(data) {
        console.log("updatedSizesList")
        await axiosPrivate
            .post(`Schedule/PopulatePetSizeList`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)
                    toast.success("Pet Sizes updated successfully!")

                    getAllSizes()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const updateSizes = () => {
        const updatedSizes = memoizedFilteredItemsSizes
            .filter((size) => (size?.EventsPetSizeListID !== 0 && !size?.IsSelected) || (size?.EventsPetSizeListID === 0 && size?.IsSelected))
            .map((size) => {
                return {
                    eventsPetSizeListID: size?.EventsPetSizeListID,
                    eventsServicesListID: selectedService?.EventsServicesListID,
                    petSizeID: size?.numPetSizeID,
                    userID: 1,
                    IsSelected: size?.IsSelected,
                }
            })
        if (updatedSizes.length > 0) {
            updatedSizesList(updatedSizes)
            console.log("petSizes-sent-payload", updatedSizes)
        } else {
            toast.info("No changes to update!")
        }
    }

    const getAllEvents = () => {
        getEventsList()
            .then((response) => {
                let res = response

                console.log(res)
                setEventsList(res)
                setFilteredItemsEvents(res)
                setEventsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setEventsLoading(false)
            })
    }

    const getAllServices = () => {
        setServicesList([])
        setFilteredItemsServices([])
        setSelectedAllEvent(false)
        setSelectedService({
            ServiceItemID: 0,
            selectedName: "",
            EventsServicesListID: 0,
        })
        setSelectedAllPetTypes(false)
        setSizesList([])
        setFilteredItemsSizes([])
        setSelectedAllSizes(false)
        setSelectedSizes({
            numPetSizeID: 0,
            selectedName: "",
            EventsPetSizeListID: 0,
        })
        setSelectedBreeds({
            numPetBreedID: 0,
            selectedName: "",
            EventsPetBreedListID: 0,
        })
        setBreedsList([])
        setFilteredItemsBreeds([])

        getServicesList()
            .then((response) => {
                let res = response
                if (Array.isArray(res)) {
                } else {
                    console.log(res?.Services)
                    setServicesList(res?.Services)
                    setFilteredItemsServices(res?.Services)
                }
                setServicesLoading(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setServicesLoading(false)
            })
    }

    const getAllPetTypes = () => {
        setPetTypesList([])
        setFilteredItemsPetTypes([])
        setSelectedAllPetTypes(false)
        setSizesList([])
        setFilteredItemsSizes([])
        setSelectedAllSizes(false)
        setSelectedSizes({
            numPetSizeID: 0,
            selectedName: "",
            EventsPetSizeListID: 0,
        })
        setSelectedPetTypes({
            numPetTypeID: 0,
            selectedName: "",
            EventsPetTypeListID: 0,
        })
        setSelectedService({
            ServiceItemID: 0,
            selectedName: "",
            EventsServicesListID: 0,
        })
        setSelectedBreeds({
            numPetBreedID: 0,
            selectedName: "",
            EventsPetBreedListID: 0,
        })
        setServicesList([])
        setFilteredItemsServices([])
        setBreedsList([])
        setFilteredItemsBreeds([])

        getPetTypesList()
            .then((response) => {
                let res = response

                console.log(res)
                setPetTypesList(res)
                setFilteredItemsPetTypes(res)

                setPetTypesLoading(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setPetTypesLoading(false)
            })
    }

    const getAllBreeds = () => {
        setBreedsList([])
        setFilteredItemsBreeds([])
        setSelectedAllBreeds(false)

        setSelectedBreeds({
            numPetBreedID: 0,
            selectedName: "",
            EventsPetBreedListID: 0,
        })

        getBreeds()
            .then((response) => {
                let res = response

                console.log(res)
                setBreedsList(res)
                setFilteredItemsBreeds(res)

                setBreedsLoading(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setBreedsLoading(false)
            })
    }

    const getAllSizes = () => {
        setSizesList([])
        setFilteredItemsSizes([])
        setSelectedAllSizes(false)
        setSelectedSizes({
            numPetSizeID: 0,
            selectedName: "",
            EventsPetSizeListID: 0,
        })
        setSelectedBreeds({
            numPetBreedID: 0,
            selectedName: "",
            EventsPetBreedListID: 0,
        })
        setServicesList([])

        setBreedsList([])
        setFilteredItemsBreeds([])

        getSizes()
            .then((response) => {
                let res = response

                console.log(res)
                setSizesList(res)
                setFilteredItemsSizes(res)

                setSizesLoading(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setSizesLoading(false)
            })
    }

    useEffect(() => {
        if (selectedEvent?.EventID !== 0) {
            getAllPetTypes()
        }
    }, [selectedEvent])

    useEffect(() => {
        if (selectedPetTypes?.numPetTypeID !== 0) {
            getAllServices()
        }
    }, [selectedPetTypes])

    useEffect(() => {
        if (selectedService?.ServiceItemID !== 0) {
            getAllSizes()
        }
    }, [selectedService])

    useEffect(() => {
        if (selectedSizes?.numPetSizeID !== 0) {
            getAllBreeds()
        }
    }, [selectedSizes])

    async function newEvent(data, type) {
        console.log("newEvent")
        await axiosPrivate
            .post(`Schedule/PopulateEvent`, JSON.stringify(data), {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((result) => {
                if (result.status === 200) {
                    console.warn(`res`, result)
                    if (type === "new") {
                        toast.success("Event creation successful!")
                        setIsOpenNewEvent(false)
                    } else {
                        toast.success("Event updated successfully!")
                        setIsOpenEditEvent(false)
                    }
                    getAllEvents()
                }
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
    }

    const createNewEvent = () => {
        if (eventName === "" && eventName === null) {
            toast.info("Please enter a valid Event Name")
        } else {
            let data = {
                eventID: 0,
                eventName: eventName,
                eventColor: eventColor,
                eventEmployeeID: employeeNew === 0 ? "" : employeeNew,
                numLocationID: locationNew === 0 ? "" : locationNew,
                userID: 1,
            }

            newEvent(data, "new")
            console.log(data)
        }
    }

    const updateEvent = () => {
        if (eventNameEdit === "" && eventNameEdit === null) {
            toast.info("Please enter a valid Event Name")
        } else {
            let data = {
                eventID: editEventID,
                eventName: eventNameEdit,
                eventColor: eventColorEdit,
                eventEmployeeID: employeeEdit === 0 ? "" : employeeEdit,
                numLocationID: locationEdit === 0 ? "" : locationEdit,
                userID: 1,
            }

            newEvent(data, "edit")
            console.log(data)
        }
    }

    useEffect(() => {
        getLocations()
            .then((response) => {
                let res = response
                res.unshift({
                    varLocationName: "All Locations",
                    numLocationID: 0,
                })
                setLocationList(response)
                setLocationListNew(response)
                setLocationListEdit(response)

                console.info(response)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
                setIsLoadingList(false)
            })
        console.log("location inital")
    }, [])

    useEffect(() => {
        setEmployeeLoading(true)

        getEmployeesByLocation()
            .then((response) => {
                let res = response
                res.unshift({
                    varEmployeeFirstName: "All Employees",
                    varEmployeeLastName: "",
                    numEmployeeID: 0,
                })
                console.log(res)
                setEmployeeList(res)

                setEmployeeLoading(false)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")

                setEmployeeLoading(false)
            })
        console.log("location useeffect")
    }, [location])

    useEffect(() => {
        getEmployeesByLocationNew()
            .then((response) => {
                let res = response
                res.unshift({
                    varEmployeeFirstName: "All Employees",
                    varEmployeeLastName: "",
                    numEmployeeID: 0,
                })
                console.log(res)
                setEmployeeListNew(res)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
        console.log("location useeffect")
    }, [locationNew])

    useEffect(() => {
        getEmployeesByLocationEdit()
            .then((response) => {
                let res = response
                res.unshift({
                    varEmployeeFirstName: "All Employees",
                    varEmployeeLastName: "",
                    numEmployeeID: 0,
                })
                console.log(res)
                setEmployeeListEdit(res)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
        console.log("location useeffect")
    }, [locationEdit])

    useEffect(() => {
        getAllEvents()
        setSelectedEvent({ numLocationID: 0, EventID: 0, selectedName: "" })
        setSelectedService({
            ServiceItemID: 0,
            selectedName: "",
            EventsServicesListID: 0,
        })
        setSelectedPetTypes({
            numPetTypeID: 0,
            selectedName: "",
            EventsPetTypeListID: 0,
        })
        setSelectedBreeds({
            numPetBreedID: 0,
            selectedName: "",
            EventsPetBreedListID: 0,
        })
        setSelectedSizes({
            numPetSizeID: 0,
            selectedName: "",
            EventsPetSizeListID: 0,
        })

        setServicesList([])
        setFilteredItemsServices([])

        setPetTypesList([])
        setFilteredItemsPetTypes([])

        setBreedsList([])
        setFilteredItemsBreeds([])

        setSizesList([])
        setFilteredItemsSizes([])

        console.log("employee useeffect x2")
    }, [employee, location])

    useEffect(() => {
        if (employee !== 0) {
            setEmployee(0)
        }
        console.log("employee list")
    }, [employeeList])

    useEffect(() => {
        if (employeeNew !== 0) {
            setEmployeeNew(0)
        }
        console.log("employee list")
    }, [employeeListNew])

    useEffect(() => {
        if (employeeEdit !== 0) {
            setEmployeeEdit(0)
        }
        console.log("employee list")
    }, [employeeListEdit])


    useEffect(() => {
        if (
          selectedEvent.EventID !== 0 &&
          selectedPetTypes.numPetTypeID !== 0 &&
          selectedService.ServiceItemID !== 0 &&
          selectedSizes.numPetSizeID !== 0 &&
          selectedBreeds.numPetBreedID !== 0
        ) {
          const payload = {
            EventID: selectedEvent.EventID,
            numPetTypeID: selectedPetTypes.numPetTypeID,
            ServiceItemID: selectedService.ServiceItemID,
            numPetSizeID: selectedSizes.numPetSizeID,
            numPetBreedID: selectedBreeds.numPetBreedID,
          };
        console.log("time get called -", payload)
        GetTimeForEmployeeEventSetup(payload)
            .then((response) => {
                let res = response
                console.log("time", res)
                //setEventDuration(res[0]?.Time)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
        }
      }, [selectedEvent, selectedPetTypes, selectedService, selectedSizes, selectedBreeds]);

    return (
        <div style={{ width: "100%", paddingBottom: "10px" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <p style={{ fontWeight: "600", fontSize: "20px", color: "#5e5e5e" }}>Events</p>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        padding: "8px",
                        backgroundColor: "#005df114",
                        borderRadius: "8px",
                    }}
                >
                    <div style={{ width: "400px" }}>
                        <AdvancedDropDown
                            data={locationList.map((i) => ({
                                label: i.varLocationName,
                                value: i.numLocationID,
                            }))}
                            onChange={(e) => {
                                setLocation(e.value)
                            }}
                            passByID={true}
                            value={location}
                            ref={locationRef}
                            placeHolder={"Select Location"}
                        />
                    </div>

                    <div style={{ width: "400px" }}>
                        <AdvancedDropDown
                            data={employeeList.map((i) => ({
                                label: i.varEmployeeFirstName + " " + i.varEmployeeLastName,
                                value: i.numEmployeeID,
                            }))}
                            onChange={(e) => {
                                setEmployee(e.value)
                            }}
                            passByID={true}
                            value={employee}
                            ref={employeeRef}
                            placeHolder={"Select Employee"}
                        />
                    </div>
                </div>
                <div></div>
                {/* <div
                    style={{
                        padding: "8px 15px",
                        backgroundColor: "#00af5c14",
                        borderRadius: "8px",
                        border: "1px solid #9ed3ba",
                    }}
                >
                    <table style={{ borderCollapse: "collapse" }}>
                        <tr>
                            <td style={{ width: "80px" }}>
                                <p style={{ margin: "0", fontWeight: 500 }}>Duration</p>
                            </td>
                            <td>{eventDuration} min</td>
                        </tr>
                    </table>
                </div> */}
            </div>

            {employeeLoading ? (
                <p>Loading..</p>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "15px",
                        marginTop: "20px",
                        height: "564px",
                    }}
                >
                    <div
                        style={{
                            border: "1px solid #8eb4f1",
                            borderRadius: "8px",
                            padding: "8px 15px",
                            width: "100%",
                            height: "100%",
                            boxShadow: "rgb(0 99 255 / 21%) 0px 0 8px 0px",
                        }}
                    >
                        {selectedEvent?.EventID !== 0 ? <Chip label={selectedEvent?.selectedName} size="small" color="primary" style={{ width: "100%", marginBottom: "5px" }} /> : null}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #d5d5d5",
                                paddingBottom: "8px",
                                marginBottom: "4px",
                            }}
                        >
                            <p style={{ fontSize: "16px", fontWeight: "500" }}>Events</p>
                            <Button
                                variant="contained"
                                color="primary"
                                text="New"
                                onClick={() => {
                                    setIsOpenNewEvent(true)
                                }}
                            />
                        </div>

                        <div>
                            <div style={{ width: "100%", marginBottom: "8px" }}>
                                <TextField
                                    InputProps={{
                                        endAdornment:
                                            filterTextEvents.length > 0 ? (
                                                <IconButton
                                                    style={{ borderRadius: "5px" }}
                                                    onClick={(e) => {
                                                        resetEvents()
                                                    }}
                                                >
                                                    <Close style={{ fontSize: "16px", color: "#ff6464" }} />
                                                </IconButton>
                                            ) : (
                                                <IconButton disabled style={{ borderRadius: "5px" }}>
                                                    <Close style={{ fontSize: "16px", color: "#fff" }} />
                                                </IconButton>
                                            ),
                                    }}
                                    value={filterTextEvents}
                                    onChange={handleFilterChangeEvents}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>

                            <div style={{ overflowY: "auto", height: "440px" }}>
                                {eventsLoading ? (
                                    <p className={styles.loading}>Loading Events..</p>
                                ) : (
                                    filteredItemsEvents.map((eventItem) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setSelectedEvent({
                                                        numLocationID: eventItem?.numLocationID,
                                                        EventID: eventItem?.EventID,
                                                        selectedName: eventItem?.EventName,
                                                    })

                                                    setSelectedPetTypes({
                                                        numPetTypeID: 0,
                                                        selectedName: "",
                                                        EventsPetTypeListID: 0,
                                                    })
                                                    setSelectedService({
                                                        ServiceItemID: 0,
                                                        selectedName: "",
                                                        EventsServicesListID: 0,
                                                    })
                                                    setSelectedSizes({
                                                        numPetSizeID: 0,
                                                        selectedName: "",
                                                        EventsPetSizeListID: 0,
                                                    })
                                                
                                                    setSelectedBreeds({
                                                        numPetBreedID: 0,
                                                        selectedName: "",
                                                        EventsPetBreedListID: 0,
                                                    })
                                                    setEventDuration(0)
                                                }}
                                                className={`styles.listItem ${selectedEvent?.EventID === eventItem?.EventID ? styles.selectedHighlight : styles.listItem}`}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                    }}
                                                >
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                <p style={{ color: "#fff", margin: 0 }}>
                                                                    <b>Location: </b>
                                                                    {!!eventItem?.varLocationName ? eventItem?.varLocationName : "Not Set"}
                                                                </p>
                                                                <p style={{ color: "#fff", margin: 0 }}>
                                                                    <b>Employee: </b>
                                                                    {!!eventItem?.varEmployeeFirstName ? eventItem?.varEmployeeFirstName + " " + eventItem?.varEmployeeLastName : "Not Set"}
                                                                </p>
                                                            </div>
                                                        }
                                                    >
                                                        <InfoOutlined style={{ fontSize: "20px", color: "gray" }} />
                                                    </Tooltip>
                                                    <p
                                                        style={{
                                                            background: eventItem?.EventColor,
                                                            height: "12px",
                                                            width: "12px",
                                                            borderRadius: "50px",
                                                        }}
                                                    ></p>
                                                    <p
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            userSelect: "none",
                                                        }}
                                                    >
                                                        {eventItem?.EventName}
                                                    </p>
                                                </div>

                                                <IconButton
                                                    style={{ borderRadius: "5px" }}
                                                    onClick={(e) => {
                                                        setEditEventID(eventItem?.EventID)
                                                        setIsOpenEditEvent(true)
                                                    }}
                                                >
                                                    <Edit style={{ fontSize: "16px", color: "#6B7280" }} />
                                                </IconButton>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            border: "1px solid #d5d5d5",
                            borderRadius: "8px",
                            padding: "8px 15px",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        {selectedPetTypes?.numPetTypeID !== 0 ? <Chip label={selectedPetTypes?.selectedName} size="small" color="primary" style={{ width: "100%", marginBottom: "5px" }} /> : null}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #d5d5d5",
                                paddingBottom: "8px",
                                marginBottom: "4px",
                            }}
                        >
                            <p style={{ fontSize: "16px", fontWeight: "500" }}>Types</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <Tooltip
                                    title={
                                        <div>
                                            <p style={{ color: "#fff", margin: 0 }}>
                                                <b>Note</b>
                                                <br /> Changes made to your list will <u>only</u> be reflected in future appointments.
                                            </p>
                                        </div>
                                    }
                                >
                                    <WarningAmber color="warning" style={{ fontSize: "20px" }} />
                                </Tooltip>
                                <Button variant="outlined" color="info" text="Update" onClick={() => updatePetTypes()} />
                            </div>
                        </div>
                        <div>
                            <div style={{ width: "100%", marginBottom: "2px", display: "flex" }}>
                                <Checkbox
                                    inputProps={{ "aria-label": "controlled" }}
                                    onChange={() => {
                                        setSelectedAllPetTypes(!selectedAllPetTypes)

                                        const updatedItems = filteredItemsPetTypes.map((type) => ({
                                            ...type,
                                            IsSelected: !selectedAllPetTypes,
                                        }))

                                        setFilteredItemsPetTypes(updatedItems)
                                    }}
                                    size="small"
                                    checked={selectedAllPetTypes}
                                />
                                <TextField
                                    InputProps={{
                                        endAdornment:
                                            filterTextPetTypes.length > 0 ? (
                                                <IconButton
                                                    style={{ borderRadius: "5px" }}
                                                    onClick={(e) => {
                                                        resetPetTypes()
                                                    }}
                                                >
                                                    <Close style={{ fontSize: "16px", color: "#ff6464" }} />
                                                </IconButton>
                                            ) : (
                                                <IconButton disabled style={{ borderRadius: "5px" }}>
                                                    <Close style={{ fontSize: "16px", color: "#fff" }} />
                                                </IconButton>
                                            ),
                                    }}
                                    value={filterTextPetTypes}
                                    onChange={handleFilterChangePetTypes}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>
                            <div style={{ overflowY: "auto", height: "440px" }}>
                                {petTypesLoading ? (
                                    <p className={styles.loading}>Loading Pet Types..</p>
                                ) : (
                                    memoizedFilteredItemsPetType.map((type, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setSelectedPetTypes({
                                                        numPetTypeID: type?.numPetTypeID,
                                                        selectedName: type?.varPetTypeName,
                                                        EventsPetTypeListID: type?.EventsPetTypeListID,
                                                    })
                                                    setSelectedService({
                                                        ServiceItemID: 0,
                                                        selectedName: "",
                                                        EventsServicesListID: 0,
                                                    })
                                                    setSelectedSizes({
                                                        numPetSizeID: 0,
                                                        selectedName: "",
                                                        EventsPetSizeListID: 0,
                                                    })
                                                
                                                    setSelectedBreeds({
                                                        numPetBreedID: 0,
                                                        selectedName: "",
                                                        EventsPetBreedListID: 0,
                                                    })
                                                    setEventDuration(0)
                                                }}
                                                className={`styles.listItemSub ${selectedPetTypes?.numPetTypeID === type?.numPetTypeID ? styles.selectedHighlightSub : styles.listItemSub}`}
                                            >
                                                <Checkbox
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        const tempPetTypesList = [...filteredItemsPetTypes]
                                                        tempPetTypesList[index].IsSelected = !tempPetTypesList[index]?.IsSelected
                                                        setFilteredItemsPetTypes(tempPetTypesList)
                                                    }}
                                                    size="small"
                                                    checked={type?.IsSelected}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                    }}
                                                >
                                                    <p>{type?.varPetTypeName}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            border: "1px solid #d5d5d5",
                            borderRadius: "8px",
                            padding: "8px 15px",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        {selectedService?.ServiceItemID !== 0 ? <Chip label={selectedService?.selectedName} size="small" color="primary" style={{ width: "100%", marginBottom: "5px" }} /> : null}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #d5d5d5",
                                paddingBottom: "8px",
                                marginBottom: "4px",
                            }}
                        >
                            <p style={{ fontSize: "16px", fontWeight: "500" }}>Services</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <Tooltip
                                    title={
                                        <div>
                                            <p style={{ color: "#fff", margin: 0 }}>
                                                <b>Note</b>
                                                <br /> Changes made to your list will <u>only</u> be reflected in future appointments.
                                            </p>
                                        </div>
                                    }
                                >
                                    <WarningAmber color="warning" style={{ fontSize: "20px" }} />
                                </Tooltip>
                                <Button variant="outlined" color="info" text="Update" onClick={() => updateServices()} />
                            </div>
                        </div>

                        <div>
                            <div style={{ width: "100%", marginBottom: "2px", display: "flex" }}>
                                <Checkbox
                                    inputProps={{ "aria-label": "controlled" }}
                                    onChange={() => {
                                        setSelectedAllEvent(!selectedAllEvents)

                                        const updatedItems = filteredItemsServices.map((service) => ({
                                            ...service,
                                            IsSelected: !selectedAllEvents,
                                        }))

                                        setFilteredItemsServices(updatedItems)
                                    }}
                                    size="small"
                                    checked={selectedAllEvents}
                                />
                                <TextField
                                    InputProps={{
                                        endAdornment:
                                            filterTextServices.length > 0 ? (
                                                <IconButton
                                                    style={{ borderRadius: "5px" }}
                                                    onClick={(e) => {
                                                        resetServices()
                                                    }}
                                                >
                                                    <Close style={{ fontSize: "16px", color: "#ff6464" }} />
                                                </IconButton>
                                            ) : (
                                                <IconButton disabled style={{ borderRadius: "5px" }}>
                                                    <Close style={{ fontSize: "16px", color: "#fff" }} />
                                                </IconButton>
                                            ),
                                    }}
                                    value={filterTextServices}
                                    onChange={handleFilterChangeServices}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>

                            <div style={{ overflowY: "auto", height: "440px" }}>
                                {servicesLoading ? (
                                    <p className={styles.loading}>Loading Services..</p>
                                ) : (
                                    memoizedFilteredItemsServices.map((service, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setSelectedService({
                                                        ServiceItemID: service?.ServiceItemID,
                                                        selectedName: service?.varServiceItemName,
                                                        EventsServicesListID: service?.EventsServicesListID,
                                                    })
                                                    setSelectedSizes({
                                                        numPetSizeID: 0,
                                                        selectedName: "",
                                                        EventsPetSizeListID: 0,
                                                    })
                                                
                                                    setSelectedBreeds({
                                                        numPetBreedID: 0,
                                                        selectedName: "",
                                                        EventsPetBreedListID: 0,
                                                    })
                                                    setEventDuration(0)
                                                }}
                                                className={`styles.listItemSub ${selectedService?.ServiceItemID === service?.ServiceItemID ? styles.selectedHighlightSub : styles.listItemSub}`}
                                            >
                                                <Checkbox
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        const tempServicesList = [...filteredItemsServices]
                                                        tempServicesList[index].IsSelected = !tempServicesList[index]?.IsSelected
                                                        setFilteredItemsServices(tempServicesList)
                                                    }}
                                                    size="small"
                                                    checked={service?.IsSelected}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        userSelect: "none",
                                                    }}
                                                >
                                                    <p>{service?.varServiceItemName}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            border: "1px solid #d5d5d5",
                            borderRadius: "8px",
                            padding: "8px 15px",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        {selectedSizes?.numPetSizeID !== 0 ? <Chip label={selectedSizes?.selectedName} size="small" color="primary" style={{ width: "100%", marginBottom: "5px" }} /> : null}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #d5d5d5",
                                paddingBottom: "8px",
                                marginBottom: "4px",
                            }}
                        >
                            <p style={{ fontSize: "16px", fontWeight: "500" }}>Sizes</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <Tooltip
                                    title={
                                        <div>
                                            <p style={{ color: "#fff", margin: 0 }}>
                                                <b>Note</b>
                                                <br /> Changes made to your list will <u>only</u> be reflected in future appointments.
                                            </p>
                                        </div>
                                    }
                                >
                                    <WarningAmber color="warning" style={{ fontSize: "20px" }} />
                                </Tooltip>
                                <Button variant="outlined" color="info" text="Update" onClick={() => updateSizes()} />
                            </div>
                        </div>

                        <div>
                            <div style={{ width: "100%", marginBottom: "2px", display: "flex" }}>
                                <Checkbox
                                    inputProps={{ "aria-label": "controlled" }}
                                    onChange={() => {
                                        setSelectedAllSizes(!selectedAllSizes)

                                        const updatedItems = filteredItemsSizes.map((sizes) => ({
                                            ...sizes,
                                            IsSelected: !selectedAllSizes,
                                        }))

                                        setFilteredItemsSizes(updatedItems)
                                    }}
                                    size="small"
                                    checked={selectedAllSizes}
                                />
                                <TextField
                                    InputProps={{
                                        endAdornment:
                                            filterTextSizes.length > 0 ? (
                                                <IconButton
                                                    style={{ borderRadius: "5px" }}
                                                    onClick={(e) => {
                                                        resetSizes()
                                                    }}
                                                >
                                                    <Close style={{ fontSize: "16px", color: "#ff6464" }} />
                                                </IconButton>
                                            ) : (
                                                <IconButton disabled style={{ borderRadius: "5px" }}>
                                                    <Close style={{ fontSize: "16px", color: "#fff" }} />
                                                </IconButton>
                                            ),
                                    }}
                                    value={filterTextSizes}
                                    onChange={handleFilterChangeSizes}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>
                            <div style={{ overflowY: "auto", height: "440px" }}>
                                {sizesLoading ? (
                                    <p className={styles.loading}>Loading Pet Sizes..</p>
                                ) : (
                                    memoizedFilteredItemsSizes.map((size, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setSelectedSizes({
                                                        numPetSizeID: size?.numPetSizeID,
                                                        selectedName: size?.varPetSizeName,
                                                        EventsPetSizeListID: size?.EventsPetSizeListID,
                                                    })         
                                                    setSelectedBreeds({
                                                        numPetBreedID: 0,
                                                        selectedName: "",
                                                        EventsPetBreedListID: 0,
                                                    })
                                                    setEventDuration(0)
                                                }}
                                                className={`styles.listItemSub ${selectedSizes?.numPetSizeID === size?.numPetSizeID ? styles.selectedHighlightSub : styles.listItemSub}`}
                                            >
                                                <Checkbox
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        const tempSizeList = [...filteredItemsSizes]
                                                        tempSizeList[index].IsSelected = !tempSizeList[index]?.IsSelected
                                                        setFilteredItemsSizes(tempSizeList)
                                                    }}
                                                    size="small"
                                                    checked={size?.IsSelected}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                    }}
                                                >
                                                    <p>{size?.varPetSizeName}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            border: "1px solid #d5d5d5",
                            borderRadius: "8px",
                            padding: "8px 15px",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        {selectedBreeds?.numPetBreedID !== 0 ? <Chip label={selectedBreeds?.selectedName} size="small" color="primary" style={{ width: "100%", marginBottom: "5px" }} /> : null}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #d5d5d5",
                                paddingBottom: "8px",
                                marginBottom: "4px",
                            }}
                        >
                            <p style={{ fontSize: "16px", fontWeight: "500" }}>Breeds</p>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <Tooltip
                                    title={
                                        <div>
                                            <p style={{ color: "#fff", margin: 0 }}>
                                                <b>Note</b>
                                                <br /> Changes made to your list will <u>only</u> be reflected in future appointments.
                                            </p>
                                        </div>
                                    }
                                >
                                    <WarningAmber color="warning" style={{ fontSize: "20px" }} />
                                </Tooltip>
                                <Button variant="outlined" color="info" text="Update" onClick={() => updatePetBreeds()} />
                            </div>
                        </div>

                        <div>
                            <div style={{ width: "100%", marginBottom: "2px", display: "flex" }}>
                                <Checkbox
                                    inputProps={{ "aria-label": "controlled" }}
                                    onChange={() => {
                                        setSelectedAllBreeds(!selectedAllBreeds)

                                        const updatedItems = filteredItemsBreeds.map((breed) => ({
                                            ...breed,
                                            IsSelected: !selectedAllBreeds,
                                        }))

                                        setFilteredItemsBreeds(updatedItems)
                                    }}
                                    size="small"
                                    checked={selectedAllBreeds}
                                />
                                <TextField
                                    InputProps={{
                                        endAdornment:
                                            filterTextBreeds.length > 0 ? (
                                                <IconButton
                                                    style={{ borderRadius: "5px" }}
                                                    onClick={(e) => {
                                                        resetBreeds()
                                                    }}
                                                >
                                                    <Close style={{ fontSize: "16px", color: "#ff6464" }} />
                                                </IconButton>
                                            ) : (
                                                <IconButton disabled style={{ borderRadius: "5px" }}>
                                                    <Close style={{ fontSize: "16px", color: "#fff" }} />
                                                </IconButton>
                                            ),
                                    }}
                                    value={filterTextBreeds}
                                    onChange={handleFilterChangeBreeds}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    placeholder="Filter here"
                                    size="small"
                                    variant="standard"
                                    style={{ width: "100%", fontSize: "14px" }}
                                />
                            </div>
                            <div style={{ overflowY: "auto", height: "440px" }}>
                                {breedsLoading ? (
                                    <p className={styles.loading}>Loading Pet Breeds..</p>
                                ) : (
                                    memoizedFilteredItemsBreeds.map((breed, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setSelectedBreeds({
                                                        numPetBreedID: breed?.numPetBreedID,
                                                        selectedName: breed?.varPetBreedName,
                                                        EventsPetBreedListID: breed?.EventsPetBreedListID,
                                                    })
                                                }}
                                                className={`styles.listItemSub ${selectedBreeds?.numPetBreedID === breed?.numPetBreedID ? styles.selectedHighlightSub : styles.listItemSub}`}
                                            >
                                                <Checkbox
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        const tempBreedsList = [...filteredItemsBreeds]
                                                        tempBreedsList[index].IsSelected = !tempBreedsList[index]?.IsSelected
                                                        setFilteredItemsBreeds(tempBreedsList)
                                                    }}
                                                    size="small"
                                                    checked={breed?.IsSelected}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                    }}
                                                >
                                                    <p>{breed?.varPetBreedName}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal open={isOpenEditEvent}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            padding: "16px",
                            marginTop: "-16px",
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                        }}
                    >
                        <p
                            style={{
                                color: "#002867",
                                fontSize: "18px",
                                fontWeight: "600",
                                width: "100%",
                            }}
                        >
                            Edit Event
                        </p>
                        <CloseButton onClick={() => setIsOpenEditEvent(false)} />
                    </div>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                width: 400,
                            }}
                        >
                            <ErrorOutline color="error" style={{ fontSize: "26px" }}></ErrorOutline>
                            <p
                                style={{
                                    margin: 0,
                                    lineHeight: "1.3em",
                                    color: "#d32f2f",
                                    fontWeight: 500,
                                }}
                            >
                                Existing events that have already been assigned will not be moved to the new <u>Location</u> or <u>Employee</u>.
                            </p>
                        </div>
                        <p style={{ fontWeight: "500", color: "#898989" }}>Event Name</p>
                        <form>
                            <TextField fullWidth size="small" onChange={(e) => setEventNameEdit(e.target.value)} value={eventNameEdit} type="text" placeholder="Event Name" />
                            <div>
                                <p style={{ fontWeight: "500", color: "#898989" }}>Event Color</p>
                                <ColorPicker onColorChange={(e) => setEventColorEdit(e)} value={eventColorEdit} />
                            </div>
                            <p style={{ fontWeight: "500", color: "#898989" }}>Location</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={locationListEdit.map((i) => ({
                                        label: i.varLocationName,
                                        value: i.numLocationID,
                                    }))}
                                    onChange={(e) => {
                                        setLocationEdit(e.value)
                                    }}
                                    passByID={true}
                                    value={locationEdit}
                                    ref={locationRefEdit}
                                    placeHolder={"Select Location"}
                                />
                            </div>
                            <p style={{ fontWeight: "500", color: "#898989" }}>Employee</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={employeeListEdit.map((i) => ({
                                        label: i.varEmployeeFirstName + " " + i.varEmployeeLastName,
                                        value: i.numEmployeeID,
                                    }))}
                                    onChange={(e) => {
                                        setEmployeeEdit(e.value)
                                    }}
                                    passByID={true}
                                    value={employeeEdit}
                                    ref={employeeRefEdit}
                                    placeHolder={"Select Employee"}
                                />
                            </div>
                            <div style={{ width: "100%", textAlign: "end", marginTop: "15px" }}>
                                <Button color="primary" variant="contained" onClick={() => updateEvent()}>
                                    Edit Event
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <Modal open={isOpenNewEvent}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            backgroundColor: "#f5f5f5",
                            padding: "16px",
                            marginTop: "-16px",
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                        }}
                    >
                        <p
                            style={{
                                color: "#002867",
                                fontSize: "18px",
                                fontWeight: "600",
                                width: "100%",
                            }}
                        >
                            New Event
                        </p>
                        <CloseButton onClick={() => setIsOpenNewEvent(false)} />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", color: "#898989" }}>Event Name</p>
                        <form>
                            <TextField fullWidth size="small" onChange={(e) => setEventName(e.target.value)} value={eventName} type="text" placeholder="Event Name" />
                            <div>
                                <p style={{ fontWeight: "500", color: "#898989" }}>Event Color</p>
                                <ColorPicker onColorChange={(e) => setEventColor(e)} value={eventColor} />
                            </div>
                            <p style={{ fontWeight: "500", color: "#898989" }}>Location</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={locationListNew.map((i) => ({
                                        label: i.varLocationName,
                                        value: i.numLocationID,
                                    }))}
                                    onChange={(e) => {
                                        setLocationNew(e.value)
                                    }}
                                    passByID={true}
                                    value={locationNew}
                                    ref={locationRefNew}
                                    placeHolder={"Select Location"}
                                />
                            </div>
                            <p style={{ fontWeight: "500", color: "#898989" }}>Employee</p>
                            <div style={{ width: "400px" }}>
                                <AdvancedDropDown
                                    data={employeeListNew.map((i) => ({
                                        label: i.varEmployeeFirstName + " " + i.varEmployeeLastName,
                                        value: i.numEmployeeID,
                                    }))}
                                    onChange={(e) => {
                                        setEmployeeNew(e.value)
                                    }}
                                    passByID={true}
                                    value={employeeNew}
                                    ref={employeeRefNew}
                                    placeHolder={"Select Employee"}
                                />
                            </div>
                            <div style={{ width: "100%", textAlign: "end", marginTop: "15px" }}>
                                <Button color="primary" variant="contained" onClick={() => createNewEvent()}>
                                    Create Event
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default EventTab
