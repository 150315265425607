import styles from '../PersonalDetails.module.scss';
import { Chip } from "../../../../Chip/Chip";
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import Switch from '@mui/material/Switch';
import { Button, Avatar, Modal } from '../../../../components';
import { AdvancedInput } from '../../../../AdvancedInput/AdvancedInput';
import { toast } from "react-toastify";
import InputMask from 'react-input-mask';
import debouce from "lodash.debounce";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from 'browser-image-compression';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { FileUploader } from 'react-drag-drop-files';
import { HighlightOff, FileUploadOutlined } from '@mui/icons-material';
import { CloseButton } from '../../../../CloseButton/CloseButton';
import { axiosPrivate } from '../../../../../apis/axios';
import { shortDate } from '../../../../../utils/DateFormatter';
import axios from "axios";

const EditEmployee = (props: {
    employeeTemplate;
    data;
}) => {

    const [editData, setEditData] = useState({
        employee: props?.data
    })

    const fileTypes = ["JPEG", "jpg", "WEBP"];
    const [loading, setLoading] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [importedData, setImportedData] = useState<any>(editData.employee.varEmployeeImagePath);
    const [showCropper, setShowCropper] = useState(false);
    const cropperRef = useRef<HTMLImageElement>(null);
    const [cropper, setCropper] = useState<any>();
    const [imageName, setImageName] = useState<any>("");

    const [selectedLocation, setSelectedLocation] = useState(0);
    const [selectedLocationObject, setSelectedLocationObject] = useState({} as any);

    const [customerLoading, setCustomerLoading] = useState(false)

    async function saveData() {
        const payload = {
            employee: editData.employee
        }

        const result = await axiosPrivate.put('Employee', JSON.stringify(payload))
        return result
    }


    async function saveLocation() {
        let arr:any = [];
        editData.employee?.employeeLocation?.map((loc, index)=>{
            if(loc?.numLocationID !== undefined && loc?.numLocationID){
                arr.push({
                    "numLocationID": loc?.numLocationID,
                    "bitIsDefault": loc?.bitIsDefault,
                    "bitActive": loc?.bitActive
                  })
            }
        })

        let locationPayload = [{
            "numEmployeeID": editData.employee?.numEmployeeID,
            "numCreatedBy": 1,
            "employeeLocationsData": arr
          }]

          console.log("location-payload", locationPayload)
        
        const result = await axiosPrivate.post('Employee/PopulateEmployeeLocations', JSON.stringify(locationPayload))
        return result
    }

    const saveChanges = () => {
        setCustomerLoading(true)

        var Form: any = document.getElementById('editForm')

        if (Form.checkValidity() == false) {
            console.log('invalid')
            setCustomerLoading(false)
        }
        else {
      
           upload()
        }

        console.log(editData)
    }

    const updateDefaultStatus = (index) => {
        const newValue: any = { ...editData }

        newValue.employee.employeeLocation.map((location, currentIndex) => {
            if (currentIndex === index) {
                newValue.employee.employeeLocation[currentIndex].bitIsDefault = true
                newValue.employee.numEmployeeDefultLocationID = location?.numLocationID
            }
            else {
                newValue.employee.employeeLocation[currentIndex].bitIsDefault = false
                newValue.employee.numEmployeeDefultLocationID = null
            }
        })
  
        setEditData(newValue)
    }

    const addNewLocation = () => {
        const newValue: any = { ...editData }
        let isInAddressList = false

        newValue.employee.employeeLocation.map((location, currentIndex) => {
            if (location.numLocationID === selectedLocation) {
                isInAddressList = true
            }
        })

        if (isInAddressList) {
            toast.error('Location already exists!');
        }
        else {
            console.log('obj', selectedLocationObject)
            const newLocation: any = { ...editData }

            newLocation.employee.employeeLocation.push({
                "numEmployeeLocationID": 0,
                "numEmployeeID": props?.data?.numEmployeeID,
                "numLocationID": selectedLocationObject.value,
                "varLocation": selectedLocationObject.text,
                "bitIsDefault": false,
                "bitActive": true
            })
            console.log('obj', newLocation)
            setEditData(newLocation)
        }
    }

    async function getStateCity(zipCode) {
        const result = await axiosPrivate.get(`ZipCode/GetCityStateByZipCode/${zipCode}`)
        return result;
    }

    const handleZipSearch = (value, index) => {
        getStateCity(value)
            .then(responseEmployee => {
                const resultZip = responseEmployee?.data;

                const newValue = { ...editData }
                newValue.employee.varEmployeeState = resultZip?.varState
                newValue.employee.varEmployeeCity = resultZip?.varCity
                setEditData(newValue)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const debouceSearch = useMemo(() => {
        return debouce(handleZipSearch, 500);
    }, []);

    // Set the AWS Region.
    const REGION = "us-east-1";

    // Create an Amazon S3 service client object.
    const creds = {
        accessKeyId: 'AKIAXQN6N4QUDD47RQQB',
        secretAccessKey: '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'
    };

    const s3Client = new S3Client({
        region: REGION,
        credentials: creds
    });



    //propsID
    const uploadID = editData.employee.numEmployeeID;

    const readFile = (files) => {
        if (files.size > 1000000) {
            toast.error(files.name + ' File too large');
        } else {
            setImportedData(files)
            setShowCropper(true)
            setFileSelected(true)
            setImageName(files.name)
        }
    }

    const blobToFile = (theBlob: Blob, fileName: string) => {
        var b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return theBlob;
    }

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            cropper.getCroppedCanvas({
                imageSmoothingEnabled: false
            }).toBlob((blob) => {
                let toFile = blobToFile(blob, imageName)
                let imageFile: any = toFile;
                let options = {
                    maxSizeMB: 0.5,
                    fileType: "image/jpeg",
                    maxWidthOrHeight: 200,
                    useWebWorker: true,
                    onProgress: (progress) => {
                        setLoading(true)
                        if (progress == 100) {
                            setLoading(false)
                        }
                    }
                }
                imageCompression(imageFile, options)
                    .then(function (compressedFile) {
                        setImportedData(compressedFile)
                        setShowCropper(false)
                    })
                    .catch(function (error) {
                        console.log(error.message);
                    });
            });
        }
    };

    const upload = () => {

        if (!fileSelected) {
            saveData()
                .then(res => {
                    console.log(res)
                    saveLocation()
                        .then(res => {
                            console.log(res)
                            toast.success("Employee was updated successfully!");
        
                            setCustomerLoading(false)
                        })
                        .catch(err => {
                            console.log(err)
                            setCustomerLoading(false)
        
                            toast.error("Something went wrong when saving new employee!");
                        }) 
                })
                .catch(err => {
                    console.log(err)
                    setCustomerLoading(false)

                    toast.error("Something went wrong when saving new employee!");
                })
        } else {


            const formData = new FormData(); 
            formData.append('folderpath', `directories/employees`);
            formData.append('method', "N");
            formData.append('deletingFileName', "-");
            formData.append('renameFileNames', "0");

                const file = importedData;
                const fileExtension = file.name.split('.').pop();
                const renamedFile = new File([file], `${editData.employee.numEmployeeID}.${fileExtension}`, { type: file.type });
                formData.append('files', renamedFile);

                axios.post(`${process.env.REACT_APP_API_URL}FileUpload/UploadFile`, formData)
                .then((response) => {
                    console.log(response);
                    if(response.data.files.length > 0){
                        const newValue = { ...editData }
                        newValue.employee.varEmployeeImagePath = newValue.employee.numEmployeeID + "." + imageName.split('.').pop()
                        setEditData(newValue)

                        saveData()
                            .then(res => {
                                console.log(res)
                                    saveLocation()
                                    .then(res => {
                                        console.log(res)
                                        toast.success("Employee was updated successfully!");
                    
                                        setCustomerLoading(false)
                                    })
                                    .catch(err => {
                                        console.log(err)
                                        setCustomerLoading(false)
                    
                                        toast.error("Something went wrong when saving new employee!");
                                    }) 
                            })
                            .catch(err => {
                                console.log(err)
                                setCustomerLoading(false)

                                toast.error("Something went wrong when saving new employee!");
                            })
                    }
                    setCustomerLoading(false)
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    let numOfList = 1;


    return <form id="editForm" onSubmit={(e) => e.preventDefault()}>
        {
            props.employeeTemplate.map(section => {
                return <>

                    {
                        (section.heading != undefined) ?
                            (section.heading === "Basic details") ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p className={styles.heading}>{section.heading}</p>
                                </div>
                                : <p className={styles.heading}>{section.heading}</p>
                            : null
                    }

                    {
                        (section.layout == 'imageUpload') ?
                            <>
                                <div style={{ backgroundColor: '#F9FAFB' }}>
                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", color: "#F9FAFB", padding: "16px 22px 16px 22px" }}>
                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: 'column' }}>
                                            <div style={{ border: '2px solid #0062FF', borderRadius: '50%' }}>
                                                <Avatar
                                                    img={(fileSelected) ? URL.createObjectURL(importedData) : `${process.env.REACT_APP_MEDIA_URL}directories/employees/${importedData}`}
                                                    isVip={false}
                                                    className={'large'}
                                                    labelType={'largeLabel'}
                                                />
                                            </div>
                                            {
                                                (editData.employee.varEmployeeImagePath != importedData) ?
                                                    <p style={{ color: "#005DF1", fontSize: "14px", fontWeight: "500", textAlign: 'center', cursor: 'pointer' }} onClick={() => { { setImportedData(editData.employee.varEmployeeImagePath) } { setFileSelected(false) } }}>Reset photo</p>
                                                    : null
                                            }

                                        </div>
                                        <div style={{ border: '2px dashed #5f9cfe', borderRadius: '8px', padding: '2rem', backgroundColor: '#fff' }}>
                                            <FileUploader
                                                handleChange={readFile}
                                                onDrop={readFile}
                                                disabled={(loading) ? true : false}
                                                name="file"
                                                types={fileTypes}
                                                multiple={false}
                                                label=""
                                                children={
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                        <FileUploadOutlined style={{ color: '#2076FF' }} />
                                                        <p className={styles.uploadText}>Drag and drop or browse to choose a image</p>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Modal open={showCropper} >
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
                                        <div style={{ textAlign: 'end', width: '100%' }}>
                                            <CloseButton onClick={() => { { setShowCropper(false) } { setFileSelected(false) } setImportedData(editData.employee.varEmployeeImagePath) }} />
                                        </div>

                                        <div style={{ backgroundColor: '#fff', }}>
                                            <Cropper
                                                style={{ maxWidth: '500px', maxHeight: '500px' }}
                                                modal={true}
                                                src={(fileSelected) ? URL.createObjectURL(importedData) : ""}
                                                cropBoxResizable={true}
                                                initialAspectRatio={1}
                                                aspectRatio={1}
                                                guides={true}
                                                minCropBoxHeight={200}
                                                scalable={false}
                                                minCropBoxWidth={200}
                                                highlight={true}
                                                zoomable={true}
                                                movable={true}
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                                ref={cropperRef}
                                            />
                                        </div>
                                        <div style={{ textAlign: 'end', width: '100%' }}>
                                            <Button variant='contained' color='primary' onClick={() => getCropData()}>Crop & Fix</Button>
                                        </div>
                                    </div>
                                </Modal>
                            </>

                            : (section.layout == 'basic') ?

                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch" }}>
                                    {
                                        section.data.map(rows => {
                                            return rows.row.map(input => {

                                              
                                                return (input.type === "advanced") ?

                                                    <AdvancedInput
                                                        isButton={true}
                                                        label={
                                                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                {input.label}
                                                                {(input.isRequired) ?
                                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                    : null}
                                                            </div>
                                                        }
                                                        onChange={(e) => {
                                                            const newValue = { ...editData }
                                                            newValue.employee[input.key] = e
                                                            setEditData(newValue)
                                                        }}
                                                        ObjectName={input.objectName}
                                                        isCustom={input.isCustom}
                                                        Sequence={input.sequence}
                                                        selectedValue={input.value}
                                                    />
                                                    : (input.type === "text") ?
                                                        (input.inputType === "tel") ?
                                                            <InputMask
                                                                alwaysShowMask="true"
                                                                mask="\(999\)999\-9999"
                                                                maskChar="_"
                                                                value={editData.employee[input.key]}
                                                                onChange={(e) => {
                                                                    const newValue = { ...editData }
                                                                    newValue.employee[input.key] = e.target.value
                                                                    setEditData(newValue)
                                                                }}
                                                            >
                                                                {(inputProps) =>
                                                                    <TextField
                                                                        inputProps={{
                                                                            pattern:
                                                                                (input.isRequired) ?
                                                                                    "^[0-9()-]+$"
                                                                                    :
                                                                                    ((editData.employee[input.key] === "") || (editData.employee[input.key] === "(___)___-____")) ? null : "^[0-9()-]+$"
                                                                        }}
                                                                        {...inputProps}
                                                                        type="tel"
                                                                        required={input.isRequired}
                                                                        style={{ width: '100%' }}
                                                                        label={
                                                                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                {input.label}
                                                                                {(input.isRequired) ?
                                                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                    : null}
                                                                            </div>
                                                                        }
                                                                        variant='standard'
                                                                    />}
                                                            </InputMask>
                                                            :
                                                            <div>
                                                                <TextField
                                                                    // inputProps={{
                                                                    //     pattern: "[a-z]{1,15}"
                                                                    // }}  
                                                                    variant="filled"
                                                                    style={{ width: '100%' }}
                                                                    label={
                                                                        <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                            {input.label}
                                                                            {(input.isRequired) ?
                                                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                : null}
                                                                        </div>
                                                                    }
                                                                    required={input.isRequired}
                                                                    onChange={(e) => {
                                                                        const newValue = { ...editData }
                                                                        newValue.employee[input.key] = e.target.value
                                                                        setEditData(newValue)
                                                                    }}
                                                                    type={input.inputType}
                                                                    value={editData.employee[input.key]}
                                                                />
                                                            </div>
                                                        : (input.type === "date") ?
                                                            <div>
                                                                <TextField
                                                                    inputProps={{
                                                                        max: (input.key === "dtEmployeeDOB") ? shortDate(new Date()) : null
                                                                    }}
                                                                    variant="filled"
                                                                    style={{ width: '100%' }}
                                                                    label={
                                                                        <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                            {input.label}
                                                                            {(input.isRequired) ?
                                                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                : null}
                                                                        </div>
                                                                    }
                                                                    InputLabelProps={{ shrink: true }}
                                                                    required={input.isRequired}
                                                                    onChange={(e) => {
                                                                        const newValue = { ...editData }
                                                                        newValue.employee[input.key] = e.target.value
                                                                        setEditData(newValue)
                                                                    }}
                                                                    type={input.inputType}

                                                                    value={new Date(editData.employee[input.key]).toISOString().split('T')[0]}
                                                                />
                                                            </div>
                                                            : (input.type === "blank") ?
                                                                <div></div>
                                                                : null
                                            })
                                        })
                                    }
                                </div>

                                : (section.layout == 'advancedList') ?

                                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gridColumnGap: '1rem', rowGap: "1ch" }}>
                                        {
                                            editData.employee.employeeLocation.map((location, index) => {
                                                return <div>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                                                        <p>{location?.varLocation}</p>
                                                        {
                                                            (location?.bitIsDefault) ?
                                                                <Chip color={'success'} label='Default' size='small' variant='filled' />
                                                                :
                                                                <Chip onClick={() => updateDefaultStatus(index)} color={'info'} label='Set as default' size='small' variant='outlined' />
                                                        }
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <p>Active</p>
                                                            <Switch
                                                                onChange={(e) => {
                                                                    const newValue = { ...editData }
                                                                    newValue.employee.employeeLocation[index].bitActive = !newValue.employee.employeeLocation[index].bitActive                                                          
                                                                    setEditData(newValue)
                                                                }}
                                                                checked={editData.employee.employeeLocation[index].bitActive}
                                                                inputProps={{ 'aria-label': 'controlled' }}


                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }

                                        <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                            <AdvancedInput
                                                isButton={false}
                                                label={'Location'}
                                                onChangeValue={(value) =>
                                                    setSelectedLocationObject(value)
                                                }
                                                onChange={(e) => {
                                                    setSelectedLocation(e)
                                                }}
                                                ObjectName={section.data.objectName}
                                                isCustom={false}
                                                Sequence={''}
                                                selectedValue={selectedLocation}
                                            />
                                            <Button onClick={() => addNewLocation()} color='default' variant='outlined' text='Add New Salon' />
                                        </div>
                                    </div>

                                    : (section.layout == 'address') ?
                                        <>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                                                    <p>{`Primary Address`}</p>
                                                    <Chip color={'success'} label='Default' size='small' variant='filled' />
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <p>Active</p>
                                                </div>
                                            </div>
                                            <div >
                                                <div>
                                                    <div style={{ display: 'flex', gap: '2rem' }}>
                                                        <div style={{ width: '100%' }}>
                                                            <AdvancedInput
                                                                isButton={true}
                                                                label={'Type'}
                                                                required={true}
                                                                onChange={(e) => {
                                                                    const newValue = { ...editData }
                                                                    newValue.employee.numAddressTypeID = e
                                                                    setEditData(newValue)
                                                                }}
                                                                ObjectName={'AddressType'}
                                                                isCustom={false}
                                                                Sequence={''}
                                                                selectedValue={editData.employee.numAddressTypeID}
                                                            />
                                                        </div>

                                                        <TextField
                                                            style={{ width: '100%', marginBottom: '1rem' }}
                                                            label={
                                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                    {'Street address'}
                                                                    {(true) ?
                                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                        : null}
                                                                </div>
                                                            }
                                                            required={true}
                                                            onChange={(e) => {
                                                                const newValue = { ...editData }
                                                                newValue.employee.varEmployeeAddress1 = e.target.value
                                                                setEditData(newValue)
                                                            }}
                                                            type={'text'}
                                                            variant="filled"
                                                            value={editData.employee.varEmployeeAddress1}
                                                        />
                                                    </div>

                                                    <div style={{ display: 'flex', gap: '2rem' }}>
                                                        <TextField
                                                            style={{ width: '100%', marginBottom: '1rem' }}
                                                            label={
                                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                    {'Address line 2'}
                                                                    {(false) ?
                                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                        : null}
                                                                </div>
                                                            }
                                                            required={false}
                                                            onChange={(e) => {
                                                                const newValue = { ...editData }
                                                                newValue.employee.varEmployeeAddress2 = e.target.value
                                                                setEditData(newValue)
                                                            }}
                                                            type={'text'}
                                                            variant="filled"
                                                            value={editData.employee.varEmployeeAddress2}
                                                        />

                                                        <TextField
                                                            style={{ width: '100%', marginBottom: '1rem' }}
                                                            label={
                                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                    {'City'}
                                                                    {(true) ?
                                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                        : null}
                                                                </div>
                                                            }
                                                            required={true}
                                                            onChange={(e) => {
                                                                const newValue = { ...editData }
                                                                newValue.employee.varEmployeeCity = e.target.value
                                                                setEditData(newValue)
                                                            }}
                                                            type={'text'}
                                                            variant="filled"
                                                            value={editData.employee.varEmployeeCity}
                                                        />
                                                    </div>

                                                    <div style={{ display: 'flex', gap: '2rem' }}>
                                                        <TextField
                                                            style={{ width: '100%', marginBottom: '1.5rem' }}
                                                            label={
                                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                    {'State'}
                                                                    {(true) ?
                                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                        : null}
                                                                </div>
                                                            }
                                                            required={true}
                                                            onChange={(e) => {
                                                                const newValue = { ...editData }
                                                                newValue.employee.varEmployeeState = e.target.value
                                                                setEditData(newValue)
                                                            }}
                                                            type={'text'}
                                                            variant="filled"
                                                            value={editData.employee.varEmployeeState}
                                                        />

                                                        <InputMask
                                                            alwaysShowMask="true"
                                                            mask="99999"
                                                            maskChar="_"
                                                            value={editData.employee.varEmployeeZip}
                                                            onChange={(e) => {
                                                                debouceSearch(e.target.value, 0)

                                                                const newValue = { ...editData }
                                                                newValue.employee.varEmployeeZip = e.target.value
                                                                setEditData(newValue)
                                                            }}
                                                        >
                                                            {(inputProps) =>
                                                                <TextField
                                                                    {...inputProps}
                                                                    type="tel"
                                                                    required={true}
                                                                    style={{ width: '100%' }}
                                                                    label={
                                                                        <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                            {'Postal/Zip Code'}
                                                                            {(true) ?
                                                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                : null}
                                                                        </div>
                                                                    }
                                                                    variant='standard'
                                                                />}
                                                        </InputMask>
                                                    </div>

                                                </div>
                                                {/* <TextField                                         
                                        style={{ width: '100%', marginBottom: '1.5rem' }}
                                        label= {
                                            <div style={{display: 'flex', fontSize: '14px'}}>
                                                {'Postal/Zip Code'}
                                                {(true) ? 
                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                : null} 
                                            </div>
                                        }
                                        required={true}
                                        onChange={(e) => {                                                
                                            debouceSearch(e.target.value, 0)        
                                            
                                            const newValue = {...editData}                                                
                                            newValue.employee.varEmployeeZip = e.target.value
                                            setEditData(newValue)  
                                        }}
                                        type={'text'}
                                        variant="filled"
                                        value={editData.employee.varEmployeeZip}
                                    />*/}
                                            </div>


                                            <div>
                                                <TextField
                                                    multiline={true}
                                                    style={{ width: '100%', marginBottom: '1.8rem' }}
                                                    label={
                                                        <div style={{ display: 'flex', fontSize: '14px' }}>
                                                            {'Notes'}
                                                            {(false) ?
                                                                <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                : null}
                                                        </div>
                                                    }
                                                    required={false}
                                                    onChange={(e) => {
                                                        const newValue = { ...editData }
                                                        newValue.employee.varEmployeeAddressNote = e.target.value
                                                        setEditData(newValue)
                                                    }}
                                                    type={'text'}
                                                    variant="filled"
                                                    value={editData.employee.varEmployeeAddressNote}
                                                />
                                            </div>
                                        </>
                                        : (section.layout == 'dynamic') ?
                                            <>
                                                {
                                                    console.log(section.data)
                                                }
                                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '2rem', rowGap: "2ch" }}>
                                                    {
                                                        section.data.map(input => {
                                                            return (input.type === "String") ?
                                                                <>
                                                                    <TextField
                                                                        // inputProps={{
                                                                        //     pattern: "[a-z]{1,15}"
                                                                        // }}                                           
                                                                        style={{ width: '100%' }}
                                                                        label={
                                                                            <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                {input.key}
                                                                                {(input.required) ?
                                                                                    <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                    : null}
                                                                            </div>
                                                                        }
                                                                        required={input.required}
                                                                        onChange={(e) => {
                                                                            const newValue = { ...editData }
                                                                            newValue.employee[input.fieldName] = e.target.value
                                                                            setEditData(newValue)
                                                                        }}
                                                                        type={'text'}
                                                                        variant="filled"
                                                                        value={editData.employee[input.fieldName]}
                                                                    />
                                                                </>
                                                                : (input.type === "DateTime") ?
                                                                    <>
                                                                        <TextField
                                                                            // inputProps={{
                                                                            //     pattern: "[a-z]{1,15}"
                                                                            // }}                                           
                                                                            style={{ width: '100%' }}
                                                                            label={
                                                                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                    {input.key}
                                                                                    {(input.required) ?
                                                                                        <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                        : null}
                                                                                </div>
                                                                            }
                                                                            required={input.required}
                                                                            onChange={(e) => {
                                                                                const newValue = { ...editData }
                                                                                newValue.employee[input.fieldName] = e.target.value
                                                                                setEditData(newValue)
                                                                            }}
                                                                            focused={true}
                                                                            type={'date'}
                                                                            variant="filled"
                                                                            value={editData.employee[input.fieldName]}
                                                                        />
                                                                    </>
                                                                    : (input.type === "Int32" && numOfList++) ?
                                                                        <>
                                                                            <AdvancedInput
                                                                                isButton={true}
                                                                                label={
                                                                                    <div style={{ display: 'flex', fontSize: '14px' }}>
                                                                                        {input.key}
                                                                                        {(input.required) ?
                                                                                            <span style={{ fontWeight: 500, backgroundColor: '#FDEDED', color: '#EF4444', fontSize: '12px', padding: '0 5px', marginLeft: '5px' }}>Required</span>
                                                                                            : null}
                                                                                    </div>
                                                                                }
                                                                                required={input.required}
                                                                                onChange={(e) => {
                                                                                    const newValue = { ...editData }
                                                                                    newValue.employee[input.fieldName] = e
                                                                                    setEditData(newValue)
                                                                                }}
                                                                                ObjectName={'Customer'}
                                                                                isCustom={true}
                                                                                Sequence={numOfList}
                                                                                selectedValue={editData.employee[input.fieldName]}
                                                                            />
                                                                        </>
                                                                        : (input.type === "Boolean") ?
                                                                            <>
                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                    <p>{input.key}</p>
                                                                                    <Switch
                                                                                        onChange={(e) => {
                                                                                            const newValue = { ...editData }
                                                                                            newValue.employee[input.fieldName] = !newValue.employee[input.fieldName]
                                                                                            setEditData(newValue)
                                                                                        }}
                                                                                        checked={editData.employee[input.fieldName]}
                                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                            : null
                                                        })
                                                    }
                                                </div>
                                            </>
                                            : null
                    }
                    <div style={{ paddingTop: '1rem', marginBottom: '1.5rem' }}></div>
                </>
            })}

        <div style={{ backgroundColor: '#EFF5FF', padding: '2rem 3rem', textAlign: 'end' }}>
            <div style={{ display: 'flex', gap: '2rem', justifyContent: 'flex-end' }}>
                <Button onClick={() => saveChanges()} loading={customerLoading} variant='contained' color='primary' text='Save' type={'submit'} />
            </div>
        </div>

    </form>
}

export default EditEmployee;