import { Avatar, Button } from "../components"
import { Chip, Chip as LocationChip } from "../Chip/Chip"
import { ArrowRightAlt, ContentCut, Place, Shield, Star, StarBorder, WarningAmber } from "@mui/icons-material"
import pet_placeholder from "../../images/pet_placeholder.png"
import { useEffect, useState } from "react"

import { generateAge, generateSince } from "../../utils/GenerateJoinedDate"
import { formatPhone } from "../../utils/PhoneFormatter"
import { checkPermission } from "../../utils/permissions"
import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined"
import { Button as MButton, IconButton, Tooltip } from "@mui/material"
import ValidatePermission from "../ValidatePermission/ValidatePermission"
import CustomerAlerts from "../ExtModals/CustomerAlerts"
import PetAlerts from "../ExtModals/PetAlerts"
import DisplayPetServices from "../ExtModals/DisplayPetServices"

export const ProfileHeader = (props: { data; type?; index?; toggleEdit: CallableFunction; name }) => {

    const [editActive, setEditActive] = useState(false)
    const [isOpenAlertsModal, setIsAlertsModal] = useState(false)
    const [isOpenPetAlertsModal, setIsOpenPetAlertsModal] = useState(false)
    const [isOpenPetServicesModal, setIsOpenPetServicesModal] = useState(false)
    const [petPerList, setPetPerList] = useState([]) as any
    const [serviceObject, setServiceObject] = useState([]) as any

    const toggleEditState = () => {
        setEditActive(!editActive)
        props.toggleEdit(editActive)
    }

    function checkCustomerAlerts(props) {
        const data = props.data;
        const {
            bitCustomerAlertAlwaysLate,
            bitCustomerAlertDifficultToHandle,
            bitCustomerAlertDiscountOnFile,
            bitCustomerAlertNoShow,
            bitCustomerAlertOutstandingBill,
            bitCustomerAlertVerbalConfirmationNeeded,
            varCustomerComments
        } = data;
    
        const anyAlertsTrue = bitCustomerAlertAlwaysLate || bitCustomerAlertDifficultToHandle ||
                              bitCustomerAlertDiscountOnFile || bitCustomerAlertNoShow ||
                              bitCustomerAlertOutstandingBill || bitCustomerAlertVerbalConfirmationNeeded;
    
        const commentsNotNull = varCustomerComments !== null;
    
        // Simplified condition logic
        if (anyAlertsTrue || commentsNotNull) {
            return true;
        }
    
        return false;
    }

    useEffect(()=>{
        if(props.data !== undefined){
            if(props.type === "customer"){
                setIsAlertsModal(checkCustomerAlerts(props))
            }
        }

    },[props.data])

    console.log("propType", props.type)

    const showAlert = checkCustomerAlerts(props);

    
    const customerTemplate = {
        avatar: (
            <Avatar
                img={props.data?.varCustomerImagePath === null ? "" : `${process.env.REACT_APP_MEDIA_URL}directories/customers/${props.data?.varCustomerImagePath}`}
                className={"large"}
                isVip={props.data?.bitIsVip}
                labelType={"largeLabel"}
            />
        ),
        name: (<div style={{display:"flex", alignItems:"center", gap:"10px"}}>
                {props.data?.varCustomerFirstName + " " + props.data?.varCustomerLastName} {(showAlert)?  <IconButton onClick={()=>{setIsAlertsModal(true)}} style={{margin:"0"}}><WarningAmber style={{color:"red", fontSize:"20px"}}/></IconButton> : null }
               </div>),
        location: (
            <LocationChip
                label={props.data?.varCustomerDefaultLocation === undefined ? "" : props.data?.varCustomerDefaultLocation}
                shape="square"
                icon={<Place style={{ fontSize: "15px" }} />}
                customStyle="BlueFilled"
            />
        ),
        details: [
            {
                key: "Member since",
                value: generateSince(props.data?.dtCreatedDate),
            },
            {
                key: "Previous service date",
                value: "N/A",
            },
            {
                key: "Emergency contacts",
                value: (
                    <div>
                        <a style={{ fontWeight: "600" }} href={`tel:${props.data?.varEmergContactNumber}`}>
                            {props.data?.varEmergContactNumber}
                        </a>{" "}
                        ({props.data?.varEmergContactFirstName})
                    </div>
                ),
            },
        ],
    }

    

    const staffTemplate = {
        avatar: (
            <Avatar
                img={props.data?.varEmployeeImagePath === null ? "" : `${process.env.REACT_APP_MEDIA_URL}directories/employees/${props.data?.varEmployeeImagePath}`}
                className={"large"}
                isVip={props.data?.bitIsVip}
                labelType={"largeLabel"}
            />
        ),
        name: (props.data?.varSalutation != null ? props.data?.varSalutation + ". " : "") + props.data?.varEmployeeFirstName + " " + props.data?.varEmployeeLastName,
        location: (
            <LocationChip
                label={props.data?.varEmployeeCity === undefined || props.data?.varEmployeeCity === null ? "" : props.data?.varEmployeeCity}
                shape="square"
                icon={<Place style={{ fontSize: "15px" }} />}
                customStyle="BlueFilled"
            />
        ),
        details: [
            {
                key: "",
                value: (
                    <div style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                        <p style={{ margin: "0", fontWeight: "600" }}>{props.data?.varOccupation === undefined || props.data?.varOccupation === null ? "N/A" : props.data?.varOccupation}</p>
                        <Chip
                            color="success"
                            size="small"
                            label={props.data?.varEmployeePerformanceCategory === undefined || props.data?.varEmployeePerformanceCategory === null ? "N/A" : props.data?.varEmployeePerformanceCategory}
                        />
                        <div style={{ display: "flex", alignItems: "center", gap: ".1rem" }}>
                            <Star style={{ color: "#F7AE32" }} />
                            <p style={{ margin: "0", fontWeight: "600" }}>
                                {props.data?.varEmployeeRating === undefined || props.data?.varEmployeeRating === null ? "N/A" : props.data?.varEmployeeRating}
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                key: "Avg. Time",
                value: "N/A",
            },
            {
                key: "Employee since",
                value: props.data?.dtCreatedDate === undefined ? "" : generateSince(props.data?.dtCreatedDate),
            },
        ],
    }

    const petProfileTemplate = {
        avatar:
            props?.type === "petProfile" ? (
                <Avatar
                    img={
                        props.data?.varPetImagePath === null || props.data?.varPetImagePath === ""
                            ? pet_placeholder
                            : `${process.env.REACT_APP_MEDIA_URL}directories/pets/${props.data?.varPetImagePath}`
                    }
                    className={"large"}
                    labelType={"largeLabel"}
                />
            ) : null,
        name: (<div style={{display:"flex", alignItems:"center"}}>
            {props?.type === "petProfile" ? props.data.varPetName : null}
            {props?.type === "petProfile" ? props.data.petPersonality.length > 0 ? <IconButton onClick={()=>{addPetPerList("direct")}} style={{margin:"0"}}><WarningAmber style={{color:"red", fontSize:"20px"}}/></IconButton> : null : null}
        </div>),
        location:
            props?.type === "petProfile" ? (
                <div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <p>{props.data?.varPetBreed}</p>
                        <p>
                            {props.data?.isBathOnlyPet ? (
                                <Tooltip
                                    title={
                                        <>
                                            <p style={{ fontSize: "12px", color: "#fff" }}>{"Bath type dog"}</p>
                                        </>
                                    }
                                >
                                    <ShowerOutlinedIcon />
                                </Tooltip>
                            ) : null}
                        </p>
                        </div>
                         {props?.type === "petProfile" ? <MButton
                                    variant="outlined"
                                    size="small"
                                    endIcon={<ContentCut />}
                                    onClick={()=>{showPetServices("direct")}}
                                    color="success"
                                    >
                                    See Services
                                </MButton> : null}
                                
                    </div>

                    <p>
                        {props.data?.varTemperamentTestStatus === "Passed" ? (
                            <div style={{ display: "flex", alignItems: "center", gap: "5px", backgroundColor: "#F0FDF4", padding: ".2rem .7rem .2rem .5rem", borderRadius: "50px" }}>
                                <Shield style={{ fontSize: "15px", color: "#16A34A" }} />
                                <p style={{ margin: "0", color: "#16A34A" }}>Temperament test passed</p>
                            </div>
                        ) : (
                            <div style={{ display: "flex", alignItems: "center", gap: "5px", backgroundColor: "#fdf0f0", padding: ".2rem .7rem .2rem .5rem", borderRadius: "50px" }}>
                                <Shield style={{ fontSize: "15px", color: "#EB1A1A" }} />
                                <p style={{ margin: "0", color: "#EB1A1A" }}>Temperament test not passed</p>
                            </div>
                        )}
                    </p>
                </div>
            ) : null,
        details:
            props?.type === "petProfile"
                ? [
                      {
                          key: "Gender",
                          value: props.data.varPetSex == "M" ? props.data.varPetType + " | Male" : props.data.varPetType + " | Female",
                      },
                      {
                          key: "Size",
                          value: props.data.numPetWeight === null ? props.data.varPetSize + " | N/A" : props.data.varPetSize + " | " + props.data.numPetWeight + "lbs",
                      },
                      {
                          key: "Age",
                          value: props.data.varPetBirthDate === "" ? "-" : generateAge(props.data.varPetBirthDate),
                      },
                      {
                          key: "Color",
                          value: props.data.varPetAge === "" ? "-" : props.data.varPetColor,
                      },
                      {
                          key: "Owner",
                          value: props.data.varCustomer,
                      },
                  ]
                : [],
    }

    useEffect(()=>{
        if(props.type === "petProfile"){
            setPetPerList(props.data?.petPersonality)
            if(props.data?.petPersonality.length > 0){
                setIsOpenPetAlertsModal(true)
            }
        }else if(props.type === "pet"){
           setPetPerList(props.data?.customerPet[props.index].petPersonality)
           if(props.data?.customerPet[props.index].petPersonality.length > 0){
            setIsOpenPetAlertsModal(true)
        }
        }



    },[props.index])

    const addPetPerList = (type) => {
        console.log("type", type, props.type)
        if(props.type === "petProfile" && type === "direct"){
           
            setPetPerList(props.data?.petPersonality)
        }else{
           setPetPerList(props.data?.customerPet[props.index].petPersonality)
        }
        setIsOpenPetAlertsModal(true)
        }

    const showPetServices = (type) => {
        console.log("type", type, props.type)
        if(props.type === "petProfile" && type === "direct"){
            let dataOBJ = {
                petBreedID: props?.data?.numPetBreedID,
                petSizeID: props?.data?.numPetSizeID,
                employeeID: 1,
                employeeName: "Ravin",
                petID: props?.data?.numPetID,
                petTypeID: props?.data?.numPetTypeID
            }
            setServiceObject(dataOBJ)
        }else{
            let dataOBJ = {
                petBreedID: props.data?.customerPet[props.index]?.numPetBreedID,
                petSizeID: props.data?.customerPet[props.index]?.numPetSizeID,
                employeeID: 1,
                employeeName: "Ravin",
                petID: props.data?.customerPet[props.index]?.numPetID,
                petTypeID: props.data?.customerPet[props.index]?.numPetTypeID
            }
            setServiceObject(dataOBJ)
        }
        setIsOpenPetServicesModal(true)
        }

    const petTemplate = {
        avatar:
            props?.type === "pet" ? (
                <Avatar
                    img={
                        props.data?.customerPet[props.index === undefined ? 0 : props.index]?.varPetImagePath == null ||
                        props.data?.customerPet[props.index === undefined ? 0 : props.index]?.varPetImagePath == ""
                            ? pet_placeholder
                            : `${process.env.REACT_APP_MEDIA_URL}directories/pets/${props.data?.customerPet[props.index === undefined ? 0 : props.index]?.varPetImagePath}`
                    }
                    className={"large"}
                    labelType={"largeLabel"}
                />
            ) : null,
        name: (<div style={{display:"flex", alignItems:"center"}}>
                {props?.type === "pet" ? (props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetName : "") : null}
                {
                   props?.type === "pet" ? (props.data?.customerPet.length > 0 ? (props.data?.customerPet[props.index === undefined ? 0 : props.index].petPersonality.length > 0)?  <IconButton onClick={()=>{addPetPerList("indirect")}} style={{margin:"0"}}><WarningAmber style={{color:"red", fontSize:"20px"}}/></IconButton> : null                   : "") : null

                }
                
               </div>),
        location:
            props?.type === "pet" ? (
                <div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <p>{props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetBreed : ""}</p>
                            <p>
                                {props.data?.customerPet.length > 0 ? (
                                    props.data?.customerPet[props.index === undefined ? 0 : props.index].isBathOnlyPet ? (
                                        <Tooltip
                                            title={
                                                <>
                                                    <p style={{ fontSize: "12px", color: "#fff" }}>{"Bath type dog"}</p>
                                                </>
                                            }
                                        >
                                            <ShowerOutlinedIcon />
                                        </Tooltip>
                                    ) : null
                                ) : (
                                    ""
                                )}
                            </p>
                        </div>
                        <MButton
                                    variant="outlined"
                                    size="small"
                                    endIcon={<ContentCut />}
                                    onClick={()=>{showPetServices("indirect")}}
                                    color="success"
                                    >
                                    See Services
                                </MButton> 
                    </div>

                    <p>
                        {props.data?.customerPet.length > 0 ? (
                            props.data?.customerPet[props.index === undefined ? 0 : props.index].varTemperamentTestStatus === "Passed" ? (
                                <div style={{ display: "flex", alignItems: "center", gap: "5px", backgroundColor: "#F0FDF4", padding: ".2rem .7rem .2rem .5rem", borderRadius: "50px" }}>
                                    <Shield style={{ fontSize: "15px", color: "#16A34A" }} />
                                    <p style={{ margin: "0", color: "#16A34A" }}>Temperament test passed</p>
                                </div>
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", gap: "5px", backgroundColor: "#fdf0f0", padding: ".2rem .7rem .2rem .5rem", borderRadius: "50px" }}>
                                    <Shield style={{ fontSize: "15px", color: "#EB1A1A" }} />
                                    <p style={{ margin: "0", color: "#EB1A1A" }}>Temperament test not passed</p>
                                </div>
                            )
                        ) : (
                            ""
                        )}
                    </p>
                </div>
            ) : null,
        details:
            props?.type === "pet"
                ? [
                      {
                          key: "Gender",
                          value: props.data?.customerPet.length > 0 ? (props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetSex == "M" ? "Male" : "Female") : "",
                      },
                      {
                          key: "Size",
                          value:
                              props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetWeight == null
                                  ? props.data?.customerPet.length > 0
                                      ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetSize + " | N/A"
                                      : ""
                                  : props.data?.customerPet.length > 0
                                  ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetSize +
                                    " | " +
                                    props.data?.customerPet[props.index === undefined ? 0 : props.index].numPetWeight +
                                    "lbs"
                                  : "",
                      },
                      {
                          key: "Age",
                          value: props.data?.customerPet.length > 0 ? generateAge(props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetAge) : "-",
                      },
                      {
                          key: "Color",
                          value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varPetColor : "-",
                      },
                      {
                          key: "Owner",
                          value: props.data?.customerPet.length > 0 ? props.data?.customerPet[props.index === undefined ? 0 : props.index].varCustomer : "-",
                      },
                  ]
                : [],
    }

    const salonTemplate = {
        avatar:
            props?.type === "salon" ? (
                <Avatar
                    shape="rounded"
                    img={props.data?.varPetImagePath === null ? "" : `${process.env.REACT_APP_MEDIA_URL}directories/locations/${props.data?.varLocationImagePath}`}
                    className={"large"}
                    labelType={"largeLabel"}
                />
            ) : null,
        name: props?.type === "salon" ? props.data?.varLocationName : null,
        location:
            props?.type === "salon" ? (
                <div style={{ borderRadius: "5px", width: "fit-content", display: "flex", gap: ".2rem", backgroundColor: "#F0FDF4", padding: ".5rem", alignItems: "center" }}>
                    <div>
                        <Chip
                            size="small"
                            customStyle="GreenFilled"
                            color={"success"}
                            label={props.data?.varLocationStatus === null || props.data?.varLocationStatus === undefined ? "N/A" : props.data?.varLocationStatus}
                        />
                    </div>

                    <ArrowRightAlt style={{ color: "#14532D" }} />

                    <div>
                        <div style={{ display: "flex", alignItems: "center", gap: ".3rem" }}>
                            {props.data?.bitGroomingService ? <Chip size="small" customStyle="GreenFilled" color={"success"} label={"G"} /> : null}

                            {props.data?.bitBoardingService ? <Chip size="small" customStyle="GreenFilled" color={"success"} label={"B"} /> : null}

                            {props.data?.bitDaycareService ? <Chip size="small" customStyle="GreenFilled" color={"success"} label={"D"} /> : null}
                        </div>
                    </div>
                </div>
            ) : null,
        details:
            props?.type === "salon"
                ? [
                      {
                          key: "City",
                          value: props.data?.varLocationCity,
                      },
                      {
                          key: "Phone",
                          value: formatPhone(props.data?.varLocationPhone),
                      },
                      {
                          key: "Email",
                          value: props.data?.varLocationEmail,
                      },
                  ]
                : [],
    }

    return (
        <>
            <div style={{ padding: "2rem", display: "flex", gap: "2rem", backgroundColor: "#fff", boxShadow: "0px 4px 0px #EDF3F9", marginBottom: "2rem" }}>
                <div>{eval(props.type + "Template").avatar}</div>

                <CustomerAlerts isOpenAlertsModal={isOpenAlertsModal}  setIsAlertsModal={(value) => setIsAlertsModal(value)} data={props.data}/> 
                <PetAlerts isOpenPetAlertsModal={isOpenPetAlertsModal}  setIsOpenPetAlertsModal={(value) => setIsOpenPetAlertsModal(value)} data={petPerList} /> 
                <DisplayPetServices isOpenPetServicesModal={isOpenPetServicesModal}  setIsOpenPetServicesModal={(value) => setIsOpenPetServicesModal(value)} data={serviceObject} /> 

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div>
                            <h2 style={{ margin: "0 0 .5rem 0", textTransform: "capitalize" }}>{eval(props.type + "Template").name}</h2>

                            {eval(props.type + "Template").location}
                        </div>
                        {props?.name === "Customer" ? (
                            <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]} allowedSubRoutes={["Edit Customer"]}>
                                <div>
                                    <Button onClick={() => toggleEditState()} variant="outlined" color="default" text={editActive ? "Preview Mode" : `Edit ${props?.name}`} />
                                </div>
                            </ValidatePermission>
                        ) : props?.name === "Pet" ? (
                            <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]} allowedSubRoutes={["Edit Pet"]}>
                                <div>
                                    <Button onClick={() => toggleEditState()} variant="outlined" color="default" text={editActive ? "Preview Mode" : `Edit ${props?.name}`} />
                                </div>
                            </ValidatePermission>
                        ) : props?.name === "Salon" ? (
                            checkPermission(
                                <div>
                                    <Button onClick={() => toggleEditState()} variant="outlined" color="default" text={editActive ? "Preview Mode" : `Edit ${props?.name}`} />
                                </div>,
                                64
                            )
                        ) : props?.name === "Employee" ? (
                            checkPermission(
                                <div>
                                    <Button onClick={() => toggleEditState()} variant="outlined" color="default" text={editActive ? "Preview Mode" : `Edit ${props?.name}`} />
                                </div>,
                                67
                            )
                        ) : null}
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {eval(props.type + "Template").details.map((item) => {
                            return (
                                <div>
                                    <p style={{ margin: "0", color: "gray" }}>{item.key}</p>
                                    <p style={{ margin: "0", fontWeight: "600" }}>{item.value}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}
