import * as XLSX from "xlsx"
import moment from "moment"

/* Download JSON Data --> Excel */
export const downloadWithData = (objectName, data) => {
    let today = new Date()
    let date = moment(today).format("MMM DD YYYY")

    let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(data, { skipHeader: true })

    XLSX.utils.book_append_sheet(wb, ws, objectName)

    XLSX.writeFile(wb, `EsyPet Admin Export(Data) - ${date}  - ${objectName}.xlsx`)
}

/* Download JSON Headers --> Excel */
export const downloadHeaders = (objectName, headers) => {
    let today = new Date()
    let date = moment(today).format("MMM DD YYYY")

    let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(headers)

    XLSX.utils.book_append_sheet(wb, ws, objectName)

    XLSX.writeFile(wb, `EsyPet Admin Export(Headers) - ${date}  - ${objectName}.xlsx`)
}

/* Download JSON Data Directories --> Excel */
export const downloadWithDataDirectories = (objectType, data) => {
    let today = new Date()
    let date = moment(today).format("MMM DD YYYY")

    let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(data, { skipHeader: false })

    XLSX.utils.book_append_sheet(wb, ws, objectType)

    XLSX.writeFile(wb, `EsyPet Admin Export(Data) - ${date}  - ${objectType}.xlsx`)
}
