/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel"
import { MoreHoriz } from "@mui/icons-material"
import { Button } from "../../../components"
import { Chip } from "../../components/Chip/Chip"

/*
    "numPetBreedID": 1,
    "numAltPetBreedID": 2,
    "varPetTypeName": "Dog",
    "varPetBreedDescription": "Airedale",
    "bitPetBreedActive": null,
    "numCreatedBy": null,
    "dtCreatedDate": "2022-07-04T18:47:04.0233333",
    "numEditedBy": null,
    "dtEditedDate": null,
    "numDeletedBy": null,
    "dtDeletedDate": null
*/

export const PetBreed: {} = {
    tableName: "1.8 - All Breeds (Pure + Mixed)",
    hiddenColumns: ["numPetBreedID"],
    pdfTemplate: ["numPetBreedID", "varPetBreedType", "varPetBreedName", "varPetBreedDescription", "varPrimaryPetBreed", "varSecondaryPetBreed", "bitActive"],
    template: [
        {
            Header: "numPetBreedID",
            accessor: "numPetBreedID",
        },
        {
            Header: "Pet Type",
            accessor: "varPetType",
        },
        {
            Header: "Type",
            accessor: "varPetBreedType",
        },
        {
            Header: "Name",
            accessor: "varPetBreedName",
        },
        {
            Header: "Description",
            accessor: "varPetBreedDescription",
        },
        {
            Header: "Primary Breed",
            accessor: "varPrimaryPetBreed",
        },
        {
            Header: "Secondary Breed",
            accessor: "varSecondaryPetBreed",
        },
        {
            Header: "Bath Only Pet",
            accessor: "isBathOnlyPet",
            Cell: (props) => (
                <p style={{ background: "#ededed", width: "max-content", padding: "5px", borderRadius: "8px", color: "black", fontWeight: "700" }}>{props?.cell?.value ? "TRUE" : "FALSE"}</p>
            ),
        },
        {
            Header: "Status",
            accessor: "bitActive",
            Cell: (props) => <Chip label={props?.cell?.value} size={"small"} />,
        },
    ],
    postFields: [
        {
            fieldName: "varPetBreedName",
            type: "text",
            inputType: "text",
            mainTable: "",
            required: true,
            value: 0,
        },
        {
            fieldName: "varPetBreedDescription",
            type: "text-area",
            inputType: "text",
            mainTable: "",
            required: true,
            value: "",
        },
        {
            fieldName: "numPetTypeID",
            type: "advanced",
            inputType: "text",
            objectName: "PetType",
            required: true,
            value: "",
            altName: "varPetType",
        },
        {
            fieldName: "numPrimaryPetBreedID",
            type: "advanced",
            inputType: "text",
            objectName: "Breed",
            required: true,
            value: "",
            altName: "varPrimaryPetBreed",
        },
        {
            fieldName: "numSecondaryPetBreedID",
            type: "advanced",
            inputType: "text",
            objectName: "Breed",
            required: true,
            value: "",
            altName: "varSecondaryPetBreed",
        },
        {
            fieldName: "isBathOnlyPet",
            type: "boolean",
            inputType: "boolean",
            mainTable: "",
            required: true,
            value: false,
        },
        {
            fieldName: "type",
            type: "static",
            inputType: "static",
            mainTable: "",
            required: true,
            value: "MIX",
        },
        {
            fieldName: "bitActive",
            type: "bit",
            inputType: "bit",
            mainTable: "",
            required: true,
            value: true,
        },
    ],
}
