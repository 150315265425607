import React, { useState } from "react"
import { Tab, Tabs as MaterialTabs } from "@mui/material"
import styles from "./UserRoles.module.scss"
import RolesUsers from "./RolesUsers/RolesUsers"
import Permissions from "./Permissions/Permissions"
import AssignUsers from "./AssignUsers/AssignUsers"

const UserRoles = (props) => {
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props

        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && <p>{children}</p>}
            </div>
        )
    }

    const [value, setValue] = useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <div style={{ margin: "1rem 1.5rem" }}>
            <div
                style={{
                    background: "#fff",
                    boxShadow: "2px 2px 16px rgba(0, 40, 103, 0.1)",
                    borderRadius: "8px",
                    padding: "1rem",
                    marginBottom: "1.2rem",
                }}
            >
                <MaterialTabs value={value} onChange={handleChange}>
                    <Tab className={styles.tabStyle} label="Roles + Modules" />
                    {/* <Tab className={styles.tabStyle} label="Permission Setup" /> */}
                    <Tab className={styles.tabStyle} label="Assign Users" />
                </MaterialTabs>
            </div>

            <div style={{ background: "#fff", borderRadius: "8px", padding: "1rem", boxShadow: "rgba(0, 40, 103, 0.1) 2px 2px 16px" }}>
                <TabPanel value={value} index={0}>
                    <RolesUsers />
                </TabPanel>
                {/* 
                <TabPanel value={value} index={1}>
                    <Permissions />
                </TabPanel> */}

                <TabPanel value={value} index={1}>
                    <AssignUsers />
                </TabPanel>
            </div>
        </div>
    )
}

export default UserRoles
