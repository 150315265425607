import { useState } from "react"
import { ProfileHeader, Tabs } from "../components"
import { NotificationTab } from "../NotificationTab/NotificationTab"
import AccountSettings from "./components/AccountSettings/AccountSettings"
import AlertsAndComments from "./components/AlertsAndComments/AlertsAndComments"
import LinkedAccounts from "./components/LinkedAccounts/LinkedAccounts"
import OwnershipTransfer from "./components/OwnershipTransfer/OwnershipTransfer"
import PaymentDetails from "./components/PaymentDetails/PaymentDetails"
import PersonalDetails from "./components/PersonalDetails/PersonalDetails"
import PetMiniCard from "./components/PetMiniCard/PetMiniCard"
import Preferences from "./components/Preferences/Preferences"
import MedicalConditions from "./components/MedicalConditions/MedicalConditions"
import Skills from "./components/Skills/Skills"
import Notifications from "./components/Notifications/Notifications"
import { isActive, checkPermissionTabs } from "../../utils/permissions"
import CustomerInvoicesView from "./components/CustomerInvoices/CustomerInvoicesView"
import ValidatePermission from "../ValidatePermission/ValidatePermission"
import { validatePermission } from "../../utils/validatePermission"
import useAuth from "../../hooks/useAuth"
import TipSetup from "./components/Tip/TipSetup"

export const DetailedView = (props: { type; labels?; data? }) => {
    const [editView, setEditView] = useState(false)
    const [selectedPet, setSelectedPet] = useState(0)

    const { auth } = useAuth() as any

    const CustomerTabLayout = [
        {
            name: "Personal Details",
            component: (
                <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]} allowedSubRoutes={["Personal Details"]}>
                    <PersonalDetails mode={editView} labels={props?.labels} type={props?.type} data={props?.data} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.02 - Schedule / Customer Profile"], ["Personal Details"]),
        },
        {
            name: "Alerts and comments",
            component: (
                <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]} allowedSubRoutes={["Alerts and comments"]}>
                    <AlertsAndComments mode={editView} data={props?.data} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.02 - Schedule / Customer Profile"], ["Alerts and comments"]),
        },
        {
            name: "Linked Accounts",
            component: (
                <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]} allowedSubRoutes={["Linked Accounts"]}>
                    {/* checkPermissionTabs( */}
                    <LinkedAccounts mode={editView} data={props?.data} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.02 - Schedule / Customer Profile"], ["Linked Accounts"]),
        },
        {
            name: "Payment Details",
            component: (
                <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]} allowedSubRoutes={["Payment Details"]}>
                    {/* checkPermissionTabs( */}
                    <PaymentDetails mode={editView} data={props?.data} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.02 - Schedule / Customer Profile"], ["Payment Details"]),
        },
        {
            name: "Account Settings",
            component: (
                <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]} allowedSubRoutes={["Account Settings"]}>
                    {/* checkPermissionTabs( */}
                    <AccountSettings type={props?.type} data={props?.data} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.02 - Schedule / Customer Profile"], ["Account Settings"]),
        },
        {
            name: "Notifications",
            component: (
                <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]} allowedSubRoutes={["Notifications"]}>
                    {/* checkPermissionTabs( */}
                    <Notifications type={props?.type} data={props?.data} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.02 - Schedule / Customer Profile"], ["Notifications"]),
        },
        {
            name: "Invoices",
            component: (
                <ValidatePermission allowedModules={["02.02 - Schedule / Customer Profile"]} allowedSubRoutes={["Invoices"]}>
                    {/* checkPermissionTabs( */}
                    <CustomerInvoicesView type={props?.type} data={props?.data} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.02 - Schedule / Customer Profile"], ["Invoices"]),
        },
    ]

    const PetTabLayout = [
        {
            name: "Personal Details",
            component: (
                <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]} allowedSubRoutes={["Personal Details"]}>
                    <PersonalDetails mode={editView} labels={props?.labels} index={selectedPet} type={props?.type} data={props?.data} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.03 - Schedule / Pet Profile"], ["Personal Details"]),
        },
        {
            name: "Medical / Vaccination",
            component: (
                <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]} allowedSubRoutes={["Medical / Vaccination"]}>
                    <MedicalConditions mode={editView} data={props?.data} type={"pet"} index={selectedPet} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.03 - Schedule / Pet Profile"], ["Medical / Vaccination"]),
        },
        {
            name: "Ownership Transfer",
            component: (
                <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]} allowedSubRoutes={["Ownership Transfer"]}>
                    <OwnershipTransfer mode={editView} data={props?.data} type={"pet"} index={selectedPet} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.03 - Schedule / Pet Profile"], ["Ownership Transfer"]),
        },
        {
            name: "Preferences",
            component: (
                <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]} allowedSubRoutes={["Preferences"]}>
                    <Preferences mode={editView} data={props?.data} type={"pet"} index={selectedPet} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.03 - Schedule / Pet Profile"], ["Preferences"]),
        },
    ]

    const PetProfileTabLayout = [
        {
            name: "Personal Details",
            component: (
                <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]} allowedSubRoutes={["Personal Details"]}>
                    <PersonalDetails mode={editView} labels={props?.labels} index={selectedPet} type={props?.type} data={props?.data} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.03 - Schedule / Pet Profile"], ["Personal Details"]),
        },
        {
            name: "Medical / Vaccination",
            component: (
                <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]} allowedSubRoutes={["Medical / Vaccination"]}>
                    <MedicalConditions mode={editView} index={selectedPet} data={props?.data} type={"petProfile"} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.03 - Schedule / Pet Profile"], ["Medical / Vaccination"]),
        },
        {
            name: "Ownership Transfer",
            component: (
                <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]} allowedSubRoutes={["Ownership Transfer"]}>
                    <OwnershipTransfer mode={editView} data={props?.data} index={selectedPet} type={"petProfile"} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.03 - Schedule / Pet Profile"], ["Ownership Transfer"]),
        },
        {
            name: "Preferences",
            component: (
                <ValidatePermission allowedModules={["02.03 - Schedule / Pet Profile"]} allowedSubRoutes={["Preferences"]}>
                    <Preferences mode={editView} data={props?.data} index={selectedPet} type={"petProfile"} />
                </ValidatePermission>
            ),
            isDisabled: validatePermission(auth, ["02.03 - Schedule / Pet Profile"], ["Preferences"]),
        },
    ]

    const StaffTabLayout = [
        {
            name: "Personal Details",
            component: <PersonalDetails mode={editView} labels={props?.labels} type={props?.type} data={props?.data} />,
            isDisabled: true,
        },
        {
            name: "Skills",
            component: <Skills mode={editView} data={props?.data} />,
            isDisabled: true,
        },
        {
            name: "Account Settings",
            component: <AccountSettings type={props?.type} data={props?.data} />,
            isDisabled: true,
        },
    ]

    const SalonTabLayout = [
        {
            name: "All Details",
            component: <PersonalDetails mode={editView} type={props?.type} labels={props?.labels} data={props?.data} />,
            isDisabled: true,
        },
        {
            name: "Tip",
            component: <TipSetup mode={editView} type={props?.type} labels={props?.labels} data={props?.data} />,
            isDisabled: true,
        },
    ]

    const NewBookingTabLayout = [
        {
            name: "Personal Details",
            component: <p>asdasdas</p>,
        },
        {
            name: "Personal Details 2",
            component: <p>asdasdasyy</p>,
        },
    ]

    return props.type === "customer" ? (
        <div style={{ backgroundColor: "rgb(249, 250, 251)", padding: "2rem 1rem" }}>
            <ProfileHeader toggleEdit={() => setEditView(!editView)} data={props?.data} type={"customer"} name={"Customer"} />
            <Tabs tabs={CustomerTabLayout} />
        </div>
    ) : props.type === "pet" ? (
        <>
            {props?.data?.customerPet != undefined && props?.data?.customerPet != null ? (
                props?.data?.customerPet.length > 0 ? (
                    <>
                        <div style={{ margin: "1rem 1rem 0 1rem", overflowX: "scroll", display: "flex", gap: "1rem" }}>
                            {props?.data?.customerPet.map((pet, index) => {
                                console.log("petSize", pet.varPetSize)
                                return (
                                    <PetMiniCard
                                        size={pet.varPetSize !== null && pet.varPetSize != undefined ? pet.varPetSize.slice(0, 1) : "N/A"}
                                        selectPet={(id) => setSelectedPet(id)}
                                        isSelected={selectedPet}
                                        index={index}
                                        breed={pet.varPetBreed}
                                        name={pet.varPetName}
                                    />
                                )
                            })}
                        </div>

                        <div style={{ backgroundColor: "rgb(249, 250, 251)", padding: "2rem 1rem" }}>
                            <ProfileHeader toggleEdit={() => setEditView(!editView)} data={props?.data} index={selectedPet} type={"pet"} name={"Pet"} />
                            <Tabs tabs={PetTabLayout} />
                        </div>
                    </>
                ) : (
                    <div style={{ textAlign: "center", padding: "2rem 0", background: "#fff" }}>
                        <p>No pets found for the selected customer</p>
                    </div>
                )
            ) : (
                <></>
            )}
        </>
    ) : props.type === "petProfile" ? (
        <>
            <ProfileHeader toggleEdit={() => setEditView(!editView)} data={props?.data} index={selectedPet} type={"petProfile"} name={"Pet"} />
            <Tabs tabs={PetProfileTabLayout} />
        </>
    ) : props.type === "salon" ? (
        <>
            <ProfileHeader toggleEdit={() => setEditView(!editView)} data={props?.data} type={"salon"} name={"Salon"} />
            <Tabs tabs={SalonTabLayout} />
        </>
    ) : props.type === "staff" ? (
        <>
            <ProfileHeader toggleEdit={() => setEditView(!editView)} data={props?.data} type={"staff"} name={"Employee"} />
            <Tabs tabs={StaffTabLayout} />
        </>
    ) : props.type === "new_booking" ? (
        <>
            <ProfileHeader toggleEdit={() => setEditView(!editView)} data={props?.data} name={"Booking"} />
            <Tabs tabs={NewBookingTabLayout} />
        </>
    ) : null
}
