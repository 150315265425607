/*
    -- NOTES --
    Prepend "props.row.original" when using JSON Table props in JSX 
*/

import { AvatarLabel } from "../../components/AvatarLabel/AvatarLabel";
import { OpenInNew } from '@mui/icons-material'
import { Button } from "../../../components";
import { Chip as LocationChip, Avatar } from "../../../../components/";
import { generateAge } from '../../../../utils/GenerateJoinedDate'
import { Chip } from "../../components/Chip/Chip";
import placeholderImage from '../../../../images/pet_placeholder.png'
import ShowerOutlinedIcon from '@mui/icons-material/ShowerOutlined';
import { Tooltip } from "@mui/material";

export const Pet = {
    tableName: "Pet",
    hiddenColumns: ["varPetImagePath"],
    pdfTemplate: ['varPetName','varPetTypeName','varPetBreedName', 'varPetSex', 'varPetSizeName', 'varPetBirthDate', 'varCustomer'],
    hideSort: true,
    template: [
        {
            Header: 'Pet Name',
            accessor: 'varPetName',  
            width: 10,
            disableSortBy: false,
            Cell: props =>  {
                if(props.row.original?.IsBathOnlyPet){
                    return <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                <AvatarLabel 
                                    isVip={false}
                                    img={(props.row.original.varPetImagePath == "" || props.row.original.varPetImagePath == null)? placeholderImage : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + props.row.original.varPetImagePath} 
                                    name={props.row.original.varPetName} 
                                /> 
                                <Tooltip 
                                    title={
                                        <>
                                            <p style={{ fontSize: '12px', color: '#fff' }}>{"Bath type dog"}</p>
                                        </>                                                                                                                                                                                                                                                                   
                                    }
                                    >
                                        <ShowerOutlinedIcon/>
                                </Tooltip> 

                            </div>

                }else{
                    return <>
                    <AvatarLabel 
                        isVip={false}
                        img={(props.row.original.varPetImagePath == "" || props.row.original.varPetImagePath == null)? placeholderImage : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + props.row.original.varPetImagePath} 
                        name={props.row.original.varPetName} 
                    /> 
               </> 
                }

            }

   
            
                        
        },
        {
            Header: 'Type',
            accessor: 'varPetTypeName'
        },
        {
            Header: 'Breed',
            accessor: 'varPetBreedName'
        },
        {
            Header: 'Gender',
            accessor: 'varPetSex',
            Cell: props => (
             <p style={{fontSize:"14px"}}>{(props?.cell?.value == "M")? "Male" : "Female"}</p>           
            ) 

        },
        {
            Header: 'Size',
            accessor: 'varPetSizeName',
            Cell: props => (
                <LocationChip 
                label={props?.cell?.value}
                shape="round"
                fontColor={"#002867"}
                customStyle="BlueOutlined"
                fontWeight={600}
                fontSize={"12px"}
            />            
            )      
        },
        {
            Header: 'Age',
            accessor: 'varPetBirthDate',
            Cell: props => (
                <p style={{fontSize:"14px"}}>{generateAge(props?.cell?.value)}</p>           
               )          
            
        },
        {
            Header: 'Owner',
            accessor: 'varCustomer'
        },  
        {
            Header: 'img',
            accessor: 'varPetImagePath',
            disableFilters: true,
        },
        {
            Header: 'Status',
            accessor: 'bitActive',
            disableFilters: true,
            Cell: props => (
                <LocationChip 
                label={(props?.cell?.value == true)? "Active" : "Inactive"}
                shape="round"
                fontColor={"#14532D"}
                customStyle={(props?.cell?.value == true)? "Success" : "Error"}
                fontWeight={400}
                fontSize={"12px"}
            />             
            )  
        },
        {
            Header: '',
            accessor: 'numPetID',
            disableFilters: true,
            Cell: props => (
                <Button color="transparent" variant="text" onClick={() => console.log(props?.cell?.value)}><OpenInNew style={{color:"#005df1"}}/></Button>                
            )      
        },

    ]
}
