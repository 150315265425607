import { FileUploadOutlined } from '@mui/icons-material';
import styles from './UploadToggler.module.scss';

function UploadToggler(props: {
    onRedirect: CallableFunction | any;
}) {
    return (
        <div className={styles.uploadContainer} onClick={() => props?.onRedirect(true)}>
            <div className={styles.flex}>
                <FileUploadOutlined style={{color: '#002867'}}/>
                <p className={styles.uploadText}>Upload Bulk Images</p>
            </div>
        </div>
    );
}

export default UploadToggler;