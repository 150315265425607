import useAuth from "../../hooks/useAuth"

const ValidatePermission = (props: { allowedModules: string[]; allowedSubRoutes?: string[]; children }) => {
    const { auth } = useAuth() as any
    const subRoutes = props.allowedSubRoutes?.length ?? 0

    if (subRoutes > 0) {
        let checkIfIncludes = Object.keys(auth?.permissions[props?.allowedModules[0]])?.find((role: any) => props?.allowedSubRoutes?.includes(role))
        if (checkIfIncludes) {
            return auth?.permissions[props?.allowedModules[0]][checkIfIncludes]?.IsView ? props?.children : <></>
        } else {
            return null
        }
    } else {
        if (props?.allowedModules?.length > 0) {
            return Object.keys(auth?.permissions)?.find((role: any) => props?.allowedModules?.includes(role)) ? props?.children : null
        } else {
            return null
        }
    }
}

export default ValidatePermission
