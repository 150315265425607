import { ArrowBack, Warning } from '@mui/icons-material';
import { Avatar, Chip } from '../../../components';
import styles from './TabDashboard.module.scss';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "../../../apis/axios";
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Tab, Tabs} from '@mui/material';
import Steps from '../Components/Steps/Steps';
import { Button } from '../../../components';
import { AvatarLabel } from '../../../components/Table/components/AvatarLabel/AvatarLabel';
import CheckInModal from '../../../components/ExtModals/CheckInModal';
import InquiryTab from '../Components/InquiryTab/InquiryTab';
import FileUpload from '../Components/FileUpload/FileUpload';
import WorkingTab from '../Components/WorkingTab/WorkingTab';
import useAuth from '../../../hooks/useAuth';
import { formatDateDisplay } from '../../../utils/TimeHandler';


const TabDashboard = (props: {
    children?
}) => {
    const { auth } = useAuth() as any


    const [dataLoading, setDataLoading] = useState(false)
    const [viewWorking, setViewWorking] = useState(false)
    const [appointmentList, setAppointmentList] = useState([]) as any
    const [viewAppointmentDetails, setViewAppointmentDetails] = useState(false)
    const [selectedAppointmentID, setSelectedAppointmentID] = useState("")
    

    async function getAppointments() {
        setDataLoading(true)

        await axios.get(`${process.env.REACT_APP_API_URL}Appointment/GetAppointmentsByEmployeeIDAndLocID?EmployeeID=${auth?.employee?.numEmployeeID}&LocationID=${auth?.currentLocation?.value}`).then((result) => {
            if (result.status === 200) {
                console.log("allAP",result?.data)
                setAppointmentList(result?.data)
                setDataLoading(false)
            }
        }).catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
            
            setDataLoading(false)
        })
    }

    useEffect(() => {
        getAppointments()
    }, [])


    const navigate = useNavigate();
    const location = useLocation() as any;

    const handleLink = (id) => {
        const from = location.state?.from?.pathname || `working`;
        navigate(from, { state: { appData: id }, replace: true })
    }

    const showDetails = (appointmentID) => {
        setViewAppointmentDetails(true)        
        setSelectedAppointmentID(appointmentID)
    }

    const showWorkingTab = () => {
        setViewWorking(true)
    }

    return (            
        <>  
            <h1 style={{ margin: '20px 0 15px 20px ', padding: '0', fontSize: '24px' }}>Dashboard</h1>
            <div style={{ flex: 1, overflowY: 'scroll', margin: '0 0 20px 0', scrollbarGutter: 'stable both-ends' }} >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>        
                    <div style={{ width: '100%', margin: '0 20px 20px 20px' }}>   
            {/* <FileUpload/> */}
                    {
                        (viewAppointmentDetails) ?                                                                                
                            <InquiryTab bookingID={selectedAppointmentID} setViewAppointmentDetails={(value) => setViewAppointmentDetails(value)} setWorking={() => showWorkingTab()}/> 
                        : 
                                                        
                            (dataLoading) ?
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '50px 0', gap: '20px' }}>
                                    <CircularProgress style={{ fontSize: '50px' }}/>
                                    <p style={{ fontWeight: '600', fontSize: '18px' }}>Loading Appointments...</p>
                                </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            {
                                (appointmentList.length < 1)? <p style={{color:"gray"}}>No appointments found</p> :
                                appointmentList.map(appointment => {
                                    return  <div onClick={() => showDetails(appointment.numAppointmentID)} className={styles.appointment}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "1rem" }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '.6rem' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Avatar shape="rounded" img={`${process.env.REACT_APP_MEDIA_URL}directories/pets/${appointment.varPetImagePath}`} isVip={false} className={'medium'} />
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                                    <div style={{ fontWeight: 600, fontSize: "16px", color: "#002867" }}>#{appointment.numAppointmentID} {appointment.PetName}</div>
                                                    <div style={{ display: "flex", gap: "12px", alignItems: "center", marginTop: ".2rem" }}>
                                                        <div style={{ fontWeight: 400, fontSize: 14, color: "#848FAC" }}>{appointment.petBreed}</div>
                                                    </div>
                                                    <div style={{ display: "flex", marginTop: ".5rem", gap: ".3rem", alignItems: "center" }}>
                                                        {
                                                            (appointment.varPetSizeName !== null && appointment.varPetSizeName !== "") ?
                                                                <Chip size='small' customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={appointment.varPetSizeName} />
                                                            : null
                                                        }
                                                        {
                                                            (appointment.varPetSex !== null && appointment.varPetSex !== "") ?
                                                                <Chip size='small' customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={(appointment.varPetSex === "M") ? 'Male' : 'Female'} />
                                                            : null
                                                        }
                                                        {
                                                            (appointment.PetType !== null && appointment.PetType !== "") ?
                                                                <Chip size='small' customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={appointment.PetType} />
                                                            : null
                                                        }
                                                        {
                                                            (appointment.petColor !== null && appointment.petColor !== "") ?
                                                                <Chip size='small' customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={appointment.petColor} />
                                                            : null
                                                        }
                                                        {
                                                            // (appointment.bitIsRabies !== null && appointment.bitIsRabies !== "") ?
                                                            //     <Warning style={{ color: "#EB1A1A", fontSize: "20px" }} />
                                                            // : null
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", alignItems: "flex-end"}}>
                                                {
                                                    (appointment.apptStatus === "SCHEDULING")?
                                                    <Chip size='small' customBackgroundColor={"#3498db"} customBorder={"#3498db"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"Upcoming"} />
                                                    :(appointment.apptStatus === "ONGOING")?
                                                    <Chip size='small' customBackgroundColor={"#f36b40"} customBorder={"#f36b40"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"Ongoing"}/>
                                                    :(appointment.apptStatus === "PICKUP-READY")? 
                                                    <Chip size='small' customBackgroundColor={"#f39c12"} customBorder={"#f39c12"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"Pickup-Ready"}  />
                                                    :(appointment.apptStatus === "PAYMENT-MADE")?
                                                    <Chip size='small' customBackgroundColor={"#27ae60"} customBorder={"#27ae60"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"Payment-made"}  />
                                                    :(appointment.apptStatus === "CHECKED-OUT")?
                                                    <Chip size='small' customBackgroundColor={"#e74c3c"} customBorder={"#e74c3c"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={"Checked-Out"}  /> 
                                                    : <Chip size='small' customBackgroundColor={"#e74c3c"} customBorder={"#e74c3c"} customBorderRadius={"16px"} fontColor={"#ffffff"} label={appointment.apptStatus} />
                                                }
                                                <p style={{color:"gray", fontSize:"15px"}}> Start: {formatDateDisplay(appointment?.DealerStartTime)} | End: {formatDateDisplay(appointment?.EmployeeEndTime)}</p>
                                            </div>
                                        </div>                                       
                                        {/* <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                                            <div style={{ color: "#848FAC", fontSize: "12px", fontWeight: "400", marginTop: "10px" }}>SERVICES</div>
                                            <div style={{ display: "flex", marginTop: "10px", gap: ".3rem", alignItems: "center" }}>
                                                <Chip size='small' customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={"Grooming"} />
                                                <Chip size='small' customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={"Facial"} />
                                                <Chip size='small' customBackgroundColor={"#EFF3FA"} customBorder={"#EFF3FA"} customBorderRadius={"16px"} fontColor={"#192252"} label={"+4 More"} />
                                            </div>
                                        </div> */}
                                        {/* <div style={{ borderBottom: "1px solid #EFF3FA", width: "100%", marginTop: "10px" }}></div> */}                                      
                                    </div>
                                })
                            }                        
                        </div>               
                    }                                       
                    </div>
                </div>
            </div>
        </>
    );    
}

export default TabDashboard; 