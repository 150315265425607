import styles from "../../Help.module.scss";

function Basics_to_the_System() {
  return (
    <div>
      <h3 style={{ fontWeight: "600", fontSize: "26px" }}> Basics to the System</h3>
      <div id={styles.container}>
                     <p>This document describes the functions of the Administrators. Access to this module will be by means of a User Name and Password.</p>
                     <p> Access to this module will be by means of a User Name and Password.</p>

                     <img src={require('./assets/login.png')} alt=""/>
      </div>
    </div>
  );
}

export default Basics_to_the_System;
