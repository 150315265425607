import { useEffect, useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Help.module.scss';



const HelpItems = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  let menuRef = useRef(document.createElement("div"))

  // let menuRef = useRef();
  


  useEffect(() =>{
    let handler = (e) =>{

      if(!menuRef.current.contains(e.target)){
         setSubnav(false);
      }
    }
    document.addEventListener("mouseup", handler);

    return() => {
      document.removeEventListener("mouseup", handler);
    }
  });


  return (
    

    <div ref={menuRef}>
        <Link id={styles.sidebarLink} to={item.to} onClick={item.subNav && showSubnav}>
            
            <div id={styles.title} >
            <span>{item.title}</span>
            </div>
            
            
        </Link>
        
          {subnav && 
            item.subNav.map((item, index) => {
            return (
                
                <Link id={styles.dropdownLink} to={item.to} key={index} >
                <div id={styles.sublink} >
                    <span>{item.title}</span>
                    
                </div>
                </Link>             
            );
            })}
        </div>
    
  );
}

export default HelpItems;