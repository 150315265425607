import { OpenInNew, Place } from "@mui/icons-material"

import styles from "./Pet.module.scss"
import placeholderImage from "../../../../../images/pet_placeholder.png"
import { Chip as LocationChip, Avatar } from "../../../../../components/"
import { generateAge } from "../../../../../utils/GenerateJoinedDate"

const Employee = (props: { page; prepareRow; onSelect: CallableFunction }) => {
    return (
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", marginTop: "0.8rem" }}>
            {props.page.map((row, i) => {
                props.prepareRow(row)

                return (
                    <div className={styles.salon_card}>
                        <div
                            className={row.allCells[8].value == true ? undefined : styles.deceasedBorder}
                            style={
                                row.allCells[8].value == true
                                    ? { border: "1px solid #E5E7EB", borderRadius: 8, padding: "12px", backgroundColor: "#ffffff", paddingTop: "10px" }
                                    : {
                                          border: "1px solid #E5E7EB",
                                          borderBottomLeftRadius: 8,
                                          borderBottomRightRadius: 8,
                                          padding: "0 12px 12px 12px",
                                          backgroundColor: "#ffffff",
                                          paddingTop: "10px",
                                      }
                            }
                        >
                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: ".6rem" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Avatar
                                            shape="rounded"
                                            img={
                                                row.allCells[7].value == "" || row.allCells[7].value == null
                                                    ? placeholderImage
                                                    : `${process.env.REACT_APP_MEDIA_URL}directories/pets/` + row.allCells[7].value
                                            }
                                            isVip={false}
                                            className={"medium"}
                                        />
                                    </div>

                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                                        <div style={{ fontWeight: 500 }}>{row.allCells[0].value}</div>
                                        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                                            <div style={{ fontWeight: 400, fontSize: 14, color: "#6B7280" }}>{row.allCells[2].value}</div>
                                            <LocationChip label={row.allCells[4].value} shape="round" color={"primary"} size={"small"} fontWeight={400} fontSize={"12px"} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", gap: ".2rem" }}>
                                    <div onClick={() => props.onSelect(row?.allCells[row?.allCells.length - 1]?.value)} className={styles.viewButton}>
                                        <OpenInNew style={{ color: "#005df1" }} />
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{ padding: "15px 0", display: "flex", justifyContent: "space-between", borderTop: "1px solid #F3F4F6", borderBottom: "1px solid #F3F4F6", marginBottom: "1rem" }}
                            >
                                <div>
                                    <div style={{ fontWeight: 400, fontSize: 14, color: "#9CA3AF" }}>Type</div>
                                    <div style={{ fontWeight: 600, fontSize: 14, color: "#111827" }}>{row.allCells[1].value}</div>
                                </div>
                                <div style={{ borderRight: "2px solid #F3F4F6" }}></div>
                                <div>
                                    <div style={{ fontWeight: 400, fontSize: 14, color: "#9CA3AF" }}>Gender11111</div>
                                    <div style={{ fontWeight: 600, fontSize: 14, color: "#111827" }}>{row.allCells[3].value == "M" ? "Male" : "Female"}</div>
                                </div>
                                <div style={{ borderLeft: "2px solid #F3F4F6" }}></div>
                                <div>
                                    <div style={{ fontWeight: 400, fontSize: 14, color: "#9CA3AF" }}>Age</div>
                                    <div style={{ fontWeight: 600, fontSize: 14, color: "#111827" }}>{generateAge(row.allCells[5].value)}</div>
                                </div>
                            </div>

                            <div style={{ padding: "5px 0", display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <div style={{ fontWeight: 400, fontSize: 14, color: "#9CA3AF" }}>Owner</div>
                                    <div style={{ fontWeight: 600, fontSize: 16, color: "#005DF1" }}>{row.allCells[6].value}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Employee
